import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Layout from ".";
import { getDashboardInfo } from "../actions/user";
import { LastModified } from "../utils/dateconvetor";
import { useSelectionAction } from "./customstate/context";
import axios from "axios";
import SearchBar from "../components/input/searchbar";
import { FilterComponent } from "../components/FilterComponent";
import LoadContentpop from "../components/load";
import { categoryData } from "../content/category";
import { Crudopration } from "../components/Crudopration";


import { Clickouter } from "../utils/clickouter";
import { DeleteCampaign } from "../components/dashboard/DeleteProject";
import { LoadingSpinner } from "../components/Loading";
import PrimaryButton from "../components/Button1/primarybutton";
import { SimpleCopy } from "../components/popup/SimpleCopy";
import { SimpleDelete } from "../components/popup/SimpleDelete";
import toast from "react-hot-toast";
import { element, isEmpty } from "../utils/helpers";
import { Tutorial } from "../components/Tutorial";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../store";




const Dashboard = () => {

    const [status = false, setStatus] = useState(Boolean);
    const Dispatch = useDispatch()
    const { completedTutorial, engage_apptour  } = useSelector((state) => ({ completedTutorial: state.completedTutorial, engage_apptour:state.engage_apptour }))
    const {  ShowPageMainContent } = useSelectionAction();

    



    const [campaignCount, setCampaignCount] = useState(0);
    const [views, setviews] = useState(0);
    const [clicks, setclicks] = useState(0);
    const [Respcount, setRespcount] = useState(0);
    const [campaigns, setCampaigns] = useState([]);
    const [lastJsonObj, setLastObj] = useState({})
    const [loadingText, setloadingText] = useState("Fetching Your Data")
    const [showCpy, setshoWCpy] = useState(false)
    const [showDel, setshoWDel] = useState(false)
    const [monthly, setMonthly] = useState({})
    const [date, setDate] = useState({})
    const [Type, setType] = useState('flex-row')
    const [startTutorial, setStartTutorial] = useState(false)



// useEffect(() => {
//     let a=true
//     const myFunction = () => { setMoveUp(!a)   };
//     const timeoutId = setInterval(myFunction, 8000); 
//     return () => clearInterval(timeoutId);
//   }, []);


    const CAteDAta = categoryData()

    const [name, setName] = useState("")

    const [num, setNum] = useState(-1)


    //   show pop up 

    const [popNum, setPopNum] = useState()


    // set loading for Delete

    const [loadingForDelet, setLoadingForDelte] = useState(false)


    //  use ref 

 


    const Navigate = useNavigate()

    const SortByName = () => {
        const data = [...campaigns].sort((a, b) => b.name < a.name ? 1 : -1)
        setCampaigns(data)

    }

    const SortByDate = () => {
        const data = [...campaigns].sort((a, b) => a.createdAt < b.created_at ? 1 : -1)
        setCampaigns(data)

    }

    const SortByLive = () => {
        const data = [...campaigns].sort((a) => a.status == "active" ? -1 : 1)
        setCampaigns(data)
    }

    const SortByPaused = () => {
        const data = [...campaigns].sort((a) => a.status == "paused" ? -1 : 1)
        setCampaigns(data)
    }




    useEffect(() => {
        loadDashboard()
    }, [loadingForDelet])

    useEffect(() => {
        setTimeout(()=>{ setStartTutorial((!completedTutorial?.dashboard) && engage_apptour && ShowPageMainContent) },300)
        return(()=>{
            setStartTutorial(false)
        })
    }, [engage_apptour]) 


        useEffect(() => {
        
        let a = window.addEventListener("click", function (e) {
            const dotPlace = e?.target?.getAttribute("dotPlace")
            if (dotPlace !== "MainDot") {
                setPopNum(null)
            }
        });
        return (() => {
            window.removeEventListener("click", a)
        })
    }, [])


    function setDropDown(index) {
        setLastObj(data[index])
        if (popNum === index) setPopNum(null)
        else setPopNum(index)
    }


    const data = campaigns.filter((a) => String(a.name).toLowerCase().includes(name))
    const CURDdata = [
        {
            name: "Publish",
            icon: ""
        },
        {
            name: "Pause",
            icon: ""
        },
        {
            name: "Copy Link",
            icon: ""
        },
        {
            name: "Delete",
            icon: ""
        },
        {
            name: "Edit",
            icon: ""
        }
    ]
    const renderEngineurl = process.env.REACT_APP_ENV === "development" ? `http://localhost:3400/${lastJsonObj?.campaign_id}` : process.env.REACT_APP_RENDER_ENGINE_URL + lastJsonObj?.campaign_id;


    const copy = () => {
        navigator.clipboard.writeText(renderEngineurl);
        toast.success("Copied!");
        setshoWCpy(false)

    }

    const deleteCamp = async () => {
        setshoWDel(false)
        setLoadingForDelte(true)
        await DeleteCampaign(lastJsonObj?.campaign_id)
        await loadDashboard()
        setLoadingForDelte(false)
    }


    const ShowData = ({ loading }) => {

        if (loading) return <LoadingSpinner text={loadingText} />
        if (data.length === 0) return (
            <div className="flex flex-col space-y-2">
                <img src="/asset/images/dashboardempty.png" alt="" className="mx-auto h-[250px]" />
                <PrimaryButton text={'Create New Campaign'} width="w-fit px-4" margin="mx-auto" OnClick={() => Navigate('/template')} />
            </div>
        )
        return data.length > 0 ? data.map((list, index) => {
            if (index < 4) return (
                <div key={index} className="transition-all relative bottom-0 hover:border-primary hover:bottom-1 cursor-pointer duration-500 hover:shadow-card-sm rounded-16px border border-border-light pl-12px pr-32px py-12px flex flex-row mt-12px animate-fadeIn" >

                    <div className="flex-1 flex justify-between" onClick={() => Navigate(`/campaign/edit/${list?.campaign_id}`)}>
                        <div className="flex w-1/2">
                            <div className="overflow-hidden flex w-60px h-60px">
                                <img className="rounded-8px max-h-full object-cover m-auto" src={CAteDAta[list?.category]?.src ?? "/asset/images/template/gallery/1.png"} />
                            </div>
                            <div className="flex flex-col items-left ml-4 w-full">
                                <span className="text-primary-bold mb-6px text-16px font-medium hover:underline"  >{list.name}</span>
                                <span className="text-primary-gray font-normal text-14px">{LastModified(list.created_at)}</span>
                            </div>
                        </div>
                        <div className="flex flex-row justify-between items-center w-1/2">
                            <div className="flex w-fit space-x-1">
                                <span className={`w-2 h-2 border-1 rounded-2xl my-auto ${(list.status == 'paused') ? 'bg-yellow-400' : 'bg-green-600 '}`}></span>
                                <span className="min-w-[60px]">{list.status == 'active' ? 'Live' : list.status}</span>
                            </div>
                            {recentcontent('views', list?.total_views ?? 0)}
                            {recentcontent('clicks', list?.total_clicks ?? 0)}
                            {recentcontent('responses', list?.total_sub_count ?? 0)}
                        </div>
                    </div>
                    {/* <div className="flex items-center justify-center  align-middle m-auto pl-4 relative"onClick={() => setPopNum(index)}> */}
                    <div className="flex items-center justify-center  align-middle m-auto pl-4 relative" onClick={() => setDropDown(index)} dotPlace={"MainDot"}>

                        <div className="my-auto flex justify-center items-center" dotPlace={"MainDot"}>
                            <div className="text-[30px] rotate-90 hover:bg-gray-100 w-[40px] h-[40px] rounded-full flex pb-4 items-center justify-center noselect" dotPlace={"MainDot"} >...</div>
                        </div>
                        <Crudopration setshoWDel={setshoWDel} setshoWCpy={setshoWCpy} setloadingText={setloadingText} loadDashboard={loadDashboard} setLoading={setLoadingForDelte} setPopNum={setPopNum} status={list?.status} show={popNum === index} camId={list?.campaign_id} setLoadingForDelte={setLoadingForDelte} data={CURDdata} index={index} />
                    </div>
                </div>

            )
        }) : <LoadingSpinner height="h-full" text="loading..." />
    }



    const loadDashboard = async () => {

        const DashboardDetail = await getDashboardInfo();
        setCampaignCount(DashboardDetail.data.Eventcount.Eventcount.total_popups)
        setCampaigns(DashboardDetail.data.campaign)
        setviews(DashboardDetail.data.Eventcount.Eventcount.total_views)
        setclicks(DashboardDetail.data.Eventcount.Eventcount.total_clicks)
        setRespcount(DashboardDetail.data.Eventcount.Eventcount.total_subscribe)
        setMonthly(DashboardDetail.data.Eventcount?.analytics?.monthly)
        setDate(DashboardDetail.data.Eventcount?.analytics?.daily)
    }

    const topblock = [
        {
            label: "Total Campaigns",
            icon: (<svg width="92" height="44" viewBox="0 0 92 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.5 41.5C2.5 41.5 8.77975 -6.00537 24.5 16C40.2203 38.0054 46.5 36.9946 52.5 20C59.9168 -1.0075 87.258 17.0806 90 2" stroke="url(#paint0_linear_210_1570)" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <defs>
                    <linearGradient id="paint0_linear_210_1570" x1="-1.00002" y1="46.5" x2="71.0778" y2="-16.9144" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="white" stopOpacity="0" />
                    </linearGradient>
                </defs>
            </svg>
            ),
            backgroundcolor: "bg-dashboard-campaigns",
            count: campaignCount,
            diff: isNaN(monthly?.total_popups) || isEmpty(monthly?.total_popups)  ? "0.00" :  monthly?.total_popups,
            dayDiff: isNaN(date?.total_popups) || isEmpty(date?.total_popups)  ?  "0.00" : date?.total_popups
        },
        {
            label: "Total Views",
            icon: (<svg width="92" height="44" viewBox="0 0 92 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.5 41.5C2.5 41.5 8.77975 -6.00537 24.5 16C40.2203 38.0054 46.5 36.9946 52.5 20C59.9168 -1.0075 87.258 17.0806 90 2" stroke="url(#paint0_linear_210_1570)" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <defs>
                    <linearGradient id="paint0_linear_210_1570" x1="-1.00002" y1="46.5" x2="71.0778" y2="-16.9144" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="white" stopOpacity="0" />
                    </linearGradient>
                </defs>
            </svg>
            ),
            backgroundcolor: "bg-dashboard-views",
            count: views,
            diff: isNaN(monthly?.total_views) || isEmpty(monthly?.total_views)  ? "0.00" : monthly?.total_views,
            dayDiff : isNaN(date?.total_views) || isEmpty(date?.total_views)  ?  "0.00" : date?.total_views 

        },
        {
            label: "Total Clicks",
            icon: (<svg width="92" height="44" viewBox="0 0 92 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.5 41.5C2.5 41.5 8.77975 -6.00537 24.5 16C40.2203 38.0054 46.5 36.9946 52.5 20C59.9168 -1.0075 87.258 17.0806 90 2" stroke="url(#paint0_linear_210_1570)" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <defs>
                    <linearGradient id="paint0_linear_210_1570" x1="-1.00002" y1="46.5" x2="71.0778" y2="-16.9144" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="white" stopOpacity="0" />
                    </linearGradient>
                </defs>
            </svg>
            ),
            backgroundcolor: "bg-dashboard-clicks",
            count: clicks,
            diff:  isNaN(monthly?.total_clicks) || isEmpty(monthly?.total_clicks)  ? "0.00" :  monthly?.total_clicks,
            dayDiff: isNaN(date?.total_clicks) || isEmpty(date?.total_clicks)  ?  "0.00" : date?.total_clicks
        },
        {
            label: "Total Responses",
            icon: (<svg width="92" height="44" viewBox="0 0 92 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.5 41.5C2.5 41.5 8.77975 -6.00537 24.5 16C40.2203 38.0054 46.5 36.9946 52.5 20C59.9168 -1.0075 87.258 17.0806 90 2" stroke="url(#paint0_linear_210_1570)" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <defs>
                    <linearGradient id="paint0_linear_210_1570" x1="-1.00002" y1="46.5" x2="71.0778" y2="-16.9144" gradientUnits="userSpaceOnUse">
                        <stop stopColor="white" />
                        <stop offset="1" stopColor="white" stopOpacity="0" />
                    </linearGradient>
                </defs>
            </svg>
            ),
            backgroundcolor: "bg-dashboard-response",
            count: Respcount,
            diff:  isNaN(monthly?.total_subscribe) || isEmpty(monthly?.total_subscribe)  ? "0.00" : monthly?.total_subscribe,
            dayDiff: isNaN(date?.total_subscribe) || isEmpty(date?.total_subscribe)  ?  "0.00" : date?.total_subscribe 
        },
    ];

    const recentaction = [
        {
            title: "test",
            createdAt: "Created at 27 oct 2021",
            image: "",
            livestatus: 'Puslished',
            views: 0,
            clicks: 0,
            responses: 0

        }
    ]

    const recentcontent = (title, count) => {
        return (

            <div className="flex flex-col items-center w-80px">
                <span className="text-primary-bold text-15px font-bold leading-22px mb-6px" >{count}</span>
                <span className="leading-16px text-14px font-normal text-primary-gray">{title}</span>
            </div>
        )
    }


    useEffect(() => {
        reOrderAnalytics()
        window.addEventListener('resize', reOrderAnalytics);
        return () => {
        window.removeEventListener('resize', reOrderAnalytics);
        };
    }, []);



    function reOrderAnalytics(){
        setTimeout(()=>{
            let a=element("DashboardValues0")?.clientWidth
            let b=element("DashboardValues1")?.clientWidth
            let c=element("DashboardValues2")?.clientWidth
            let d=element("DashboardValues3")?.clientWidth

            if(a<=110 || b<=110 || c<=110 || d<=110) 
                setType("flex-col")
            else 
                setType("flex-row")
        },700)
    }
    
    return (
        <>
        {startTutorial && <Tutorial page="dashboard"/>}
            <Layout isactive={0}>

                <div className="w-full">
                    <div><h2 className="mb-4px font-bold text-22px text-primary-bold  capitalize">Dashboard</h2></div>

                    <div className="flex justify-between my-6 ">
                        {
                            topblock.map((list, index) => {
                                return (
                                    <>
                                        <div id={index===0 ? "step-add-question" : "jhufhu"} key={index} className={` md:w-1/4  mx-3 ${list.backgroundcolor} rounded-16px shadow-card-rate border border-border-light flex flex-row justify-between text-left p-22px`}>
                                            {/* <div className="space-y-2">
                                            <p className="font-normal text-white ">{list.label}</p>
                                            <p className="text-2xl font-medium text-white">{list.count ?  list.count : 0}</p>
                                        </div>
                                        <div className="my-auto text-white">
                                            <span className="">{list.icon}</span>
                                        </div> */}
                                            <div className="w-full flex flex-col space-y-1 ">
                                                <div>
                                                    <p className="font-normal text-white ">{list.label}</p>
                                                </div>
                                                <div className={`flex justify-between items-center `}>
                                                    <div className={`w-full flex space-x-1 duration-700 ${Type}`} id={"DashboardValues"+index} >
                                                        <p className="text-2xl font-medium text-white" title={list.count}>{list.count ? (list.count>1000 ? (list.count/1000).toFixed(2)+"K" : list.count ) : 0}</p>
                                                      
                                                        <div className={`w-full  h-full  my-auto relative overflow-hidden ${Type==="flex-col" ? "ml-[-3px!important]" : ""}`} >


                                                        <div className="text-sm opacity-0 font-medium text-white noselect" >00.00</div>

                                                        <div className={` top-[3%] text-sm absolute font-medium text-white flex flex-row items-center`} >
                                                                 
                                                              <div className={`text-2xl transform ${(parseInt(list?.diff)>0)?"":"rotate-180"}  my-auto font-medium text-white`}>
                                                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_3795_17416)">
                                                                <path d="M6.15112 10.5L9.90112 6.75L13.6511 10.5H6.15112Z" fill="white" fill-opacity="0.8" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_3795_17416">
                                                                    <rect width="18" height="18" fill="white" transform="translate(0.901123)" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        </div>
                                                        <div>{list?.diff}%</div>
                                                        
                                                        </div>



                                                        {/* <div className={` ${!isMoveUp ? "toUpData" : "toDownData"} text-sm absolute font-medium text-white flex items-center`}>  
                                                        <div className={`text-2xl transform ${(parseInt(list?.dayDiff)>0)?"":"rotate-180"}  my-auto font-medium text-white`}>
                                                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_3795_17416)">
                                                                <path d="M6.15112 10.5L9.90112 6.75L13.6511 10.5H6.15112Z" fill="white" fill-opacity="0.8" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_3795_17416">
                                                                    <rect width="18" height="18" fill="white" transform="translate(0.901123)" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        </div>
                                                        
                                                        <div>{list?.dayDiff}%</div>

                                                        </div> */}

                                                        </div>
                                                    </div>
                                                    <div className="  flex justify-end items-end ">
                                                        <span className="">{list.icon}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="flex justify-between w-full">
                                            <div>
                                            <p className="font-normal text-white ">{list.label}</p>
                                            <p className="text-2xl font-medium text-white">{list.count ?  list.count : 0}</p>
                                            </div>
                                            <div className=" ">
                                            <span className="">{list.icon}</span>
                                            </div>
                                        </div> */}
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
                <div className={loadingForDelet ? "w-full h-1/2" : ""} >
                    <div className="flex justify-between sticky top-0 z-50 py-2 ">
                        <div>
                            <h2 className="mb-4px font-bold text-22px text-primary-bold  capitalize">Recent Activities</h2>
                        </div>

                        <div className=" hidden"  >
                            <FilterComponent SortByDate={SortByDate} SortByName={SortByName} SortByLive={SortByLive} SortByPaused={SortByPaused} />
                            <SearchBar iconposition="left" border="border-2" setName={setName} />

                        </div>
                    </div>
                    {/* <h2 className="mb-4px font-bold text-22px text-primary-bold  capitalize">Recent Activities</h2>
                    <div className="flex w-full justify-end ">
                        <SearchBar iconposition="left" border="" />
                    </div> */}
                    <div className="p-4 py-6 px-6 bg-white rounded-xl shadow-bgshadow" >
                        <ShowData loading={loadingForDelet} />
                    </div>

                </div>
                {showCpy && <SimpleCopy setshoWCpy={setshoWCpy} copy={copy} renderEngineurl={renderEngineurl}></SimpleCopy>}
                {showDel && <SimpleDelete setshoWDel={setshoWDel} delete={deleteCamp} />}
            </Layout>
        </>
    )
}

export default Dashboard