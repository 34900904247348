import React, { useEffect, useState } from "react";
import { AddProduct } from "./common/addprodctIntro";
import { AddProductManual } from "./common/manualproduct";
import { useDispatch, useSelector } from "react-redux";
import { ProductMainBlock } from "./common/ProductBlock";
import { GetApiKey } from "./ProductsIntrgation/GetApiKey";
import { useParams } from "react-router-dom";
import { Actions } from "../../store";
import { Tutorial } from "../Tutorial";
import { useSelectionAction } from "../../pages/customstate/context";

// import { useDispatch, useSelector } from "react-redux";


export const Ecommmerceproduct = () => {
    const Params = useParams();
    const [startTutorial, setStartTutorial] = useState(false)



    const { fields,fieldsOrder,EcommerceFieldID } = useSelector((state) => ({ fields: state.fields,fieldsOrder: state.fieldsOrder,EcommerceFieldID:state.EcommerceFieldID }))
    const { completedTutorial, engage_apptour  } = useSelector((state) => ({ completedTutorial: state.completedTutorial, engage_apptour:state.engage_apptour }))

    let productSD = fields[EcommerceFieldID]?.filedContent?.game_resultcreen_json?.productSelectList ?? []

    const [Stage, SetStage] = useState(productSD.length == 0 ? 0: 2)
    

    const campaign_info = useSelector((state) => state.Campaign_info);
    const {  ShowPageMainContent } = useSelectionAction();

    
    useEffect(()=>{
        setTimeout(()=>{ setStartTutorial((!completedTutorial?.campaignEdit?.product) && engage_apptour && ShowPageMainContent) },100)
        return(()=>{
            setStartTutorial(false)
        })
    },[engage_apptour])


    return (
        <section className={`flex flex-col w-full h-full`} >
 {startTutorial && <Tutorial  page="campaignEdit"/>}

            {

                
                Stage === 0 ? <AddProduct setstage={SetStage} fieldId={EcommerceFieldID}  /> :
                Stage === 1 ? <AddProductManual SetStage={SetStage} fieldId={EcommerceFieldID} />:
                Stage === 2 ? <ProductMainBlock SetStage={SetStage} fieldId={EcommerceFieldID} campaign_ID={Params?.campaign_id} /> :
                 null
            }
        </section>
    )
}