import React from "react";

export const EventBlockCount =({EventBlocks = []}) =>{
    return(
        <div className="flex mx-auto w-fit space-x-6 py-2">
        {
            EventBlocks.map((list,index) => {
                return (
                    <div key={index} className="flex space-x-4 py-3 justify-between px-4 min-w-[170px] border-1 rounded-lg " >
                        <p className="flex flex-col ">
                            <p className="text-lg font-semibold my-auto">{list?.count}</p>
                            <span className=" font-medium">{list?.label}</span>
                        </p>
                        <span className="my-auto">
                            {list?.icon}
                        </span>
                    </div>
                )
            })
        }
    </div>
    )
}