import React, { useEffect, useRef, useState } from "react";
import PrimaryButton from "../Button1/primarybutton";
import { MediaTest, categoryData } from "../../content/category";
import { DrogAndDrop } from "../Editor/common/DrogAndDrop";
import { getAssetFile } from "../../actions/user";
import { LoadingSpinner } from "../Loading";
import toast from "react-hot-toast";

export const MediaPopup = ({ cancel = null,position='fixed', fieldType = '', srctype='image',OnSelect = null, innerArr = false, ineerIndex = 0, className, filedIdstate =false,fieldId='' }) => {

    const mediaDataDemo = MediaTest()

    const [ CurrentState, setCurrentState ] = useState(0);
    const [ mediaState, setMediaState ] = useState(0);
    const [ bulkmediaData, setbulkMediaData ] = useState(mediaDataDemo);
    const [ mediaData, setMediaData ] = useState([]);
    const [ mediaImageData, setMediaImageData ] = useState([]);
    const [ mediaAudioData, setMediaAudioData ] = useState([]);
    const [ currentType , setCurrentType ] = useState("image")
    // const []

    const [loading,setLoading] = useState(false);
    const imagePattern = /image/i;
    const audioPattern = /audio/i;
    const [fileType, setfileType] = useState("image");

    useEffect(() => {

        let imageArr = []
        let audioArr = []

        bulkmediaData.map(list => {
            if (imagePattern.test(list?.mimeType)) imageArr.push(list)
            if (audioPattern.test(list?.mimeType)) audioArr.push(list)
        })

        setMediaImageData(imageArr.reverse())
        setMediaAudioData(audioArr.reverse());

        setMediaData( currentType !== 'image' ? audioArr :imageArr)

    }, [ bulkmediaData ])

    const triggerToChange = async (index) => {

        setLoading(true)
        setMediaState(index)

        if(index == 1) {
            
            const Rerp = await getAssetFile(); 

            setbulkMediaData(Rerp?.data.reverse() ?? [])

        }

        else setbulkMediaData(mediaDataDemo)

        setLoading(false)
        
    }

    const data = [
        "Media",
        "upload",
    ]



    const CustomEvent = (image,type = 'image') => {


        const checktype = (srctype == 'image') ?  imagePattern.test(type) : audioPattern.test(type);
 
        if(!checktype) return toast.error(`Please choose ${srctype} file....`)

        if(!innerArr&&!filedIdstate)OnSelect(image,fieldType)
        else if(filedIdstate){OnSelect(image,fieldType,fieldId)}
        else {OnSelect(image,fieldType,ineerIndex)}

        cancel(false);


    }

    const headleDragOver = (e) => {
        e.preventDefault()
    }
    const handleDrop = (e) => {
        e.preventDefault();
    } 

    const menuREf = useRef(null);
    
    useEffect(()=>{

        let handleAction = (event) => {
            if (menuREf.current && !menuREf.current?.contains(event.target)) {
               

                cancel(false);
            }
        };
    
        document.addEventListener("mousedown", handleAction);
    },[])
    return (
        <div className={`${position} flex bg-black bg-opacity-5 w-full h-full shadow-lg top-0 left-0 overflow-hidden list-industry ${className}`} style={{ zIndex: '170' }}>

            <div className="relative bg-white w-4/5 h-4/5 flex flex-col border-1 p-3 rounded-lg m-auto" ref={menuREf} >
                
                <span className="absolute right-5 top-4 cursor-pointer bg-white" onClick={()=>cancel(false)}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.825 6.18562C19.4025 5.76312 18.72 5.76312 18.2975 6.18562L13 11.4723L7.70253 6.17479C7.28003 5.75229 6.59753 5.75229 6.17503 6.17479C5.75253 6.59729 5.75253 7.27979 6.17503 7.70229L11.4725 12.9998L6.17503 18.2973C5.75253 18.7198 5.75253 19.4023 6.17503 19.8248C6.59753 20.2473 7.28003 20.2473 7.70253 19.8248L13 14.5273L18.2975 19.8248C18.72 20.2473 19.4025 20.2473 19.825 19.8248C20.2475 19.4023 20.2475 18.7198 19.825 18.2973L14.5275 12.9998L19.825 7.70229C20.2367 7.29062 20.2367 6.59729 19.825 6.18562Z" fill="#586474" />
                    </svg>
                </span>

                <div className="flex border-b-2 px-4 space-x-4">
                    {
                        data.map((i, k) => <span className={`py-2 font-medium cursor-pointer ${CurrentState === k ? 'border-b-2 text-primary border-primary' : ''}`} onClick={() => setCurrentState(k)} key={k}>{i}</span>)
                    }
                    {/* <span className={`py-2 font-medium cursor-pointer ${CurrentState === 'media' ? 'border-b-2 text-primary border-primary' : ''}`} onClick={() => setCurrentState('upload')} >Media</span>
                    <span className={`py-2 font-medium cursor-pointer ${CurrentState !== 'media' ? 'border-b-2 text-primary border-primary' : ''}`} onClick={() => setCurrentState('media')} >Upload</span> */}
                </div>
                {CurrentState === 0 ? 
                <div className="flex w-full h-full overflow-y-auto">
                    <div className="flex flex-col my-2 space-y-4 pr-2 border-r-1">
                        <p className={`min-w-[150px] py-2 px-4 ${mediaState === 0 ? "bg-primary-lightblue text-primary":""} hover:bg-primary-lightblue cursor-pointer rounded-md`} onClick={()=>triggerToChange(0)} >Local</p>
                        <p className={`min-w-[150px] py-2 px-4 ${mediaState === 1 ? "bg-primary-lightblue text-primary":""} hover:bg-primary-lightblue cursor-pointer rounded-md`} onClick={()=>triggerToChange(1)} >Your Gallery</p>
                    </div>
                <div className="w-full relative h-full flex flex-col overflow-y-auto list-industry">
                    <div className="flex top-0 absolute w-full px-5 autocapitalize space-x-4 border-b-1 z-10 bg-white" >
                        <p className={`${ currentType !== 'image' ? "" :"text-primary border-b-2 border-primary" } py-4 cursor-pointer font-medium`} onClick={()=>{setMediaData(mediaImageData);setCurrentType('image')}} >Image</p>
                        <p className={`${ currentType !== 'audio' ? "" :"text-primary border-b-2 border-primary" } py-4  font-medium`} onClick={()=>{setMediaData(mediaAudioData);setCurrentType('audio')}} >Audio</p>
                    </div>
                    <div className={`"w-full h-full grid ${ loading ? "grid-cols-1" :"grid-cols-5 " } gap-4 pt-16 px-4 overflow-y-auto list-industry`} >
                    {
                        !loading ? mediaData.map((list,inx) => {
                            return (
                                <div className="flex flex-col space-y-2 text-center">
                                <div key={inx} className="relative transition-all duration-500 w-full h-[150px] cursor-pointer  flex bg-gray-100 p-2 rounded-lg hover:shadow-md" onClick={() => mediaState === 0 ? CustomEvent(list?.src ?? list?.image, list?.mimeType ?? 'image') : CustomEvent(list?.src,list?.mimeType )} >
                                    <img src={ (mediaState === 0) ?( currentType == 'audio' ? "/asset/images/audioicon.png" : (list?.image ?? list?.src) ): currentType == 'audio' ? "/asset/images/audioicon.png" : list?.src } className={`w-auto h-auto max-h-full m-auto rounded-lg object-cover cursor-pointer`} alt="" />
                                </div>
                                <p className="w-[100%] px-2 text-center text-ellipsis overflow-hidden whitespace-nowrap " >{list?.name}</p>
                                </div>
                            )
                        })
                        : <LoadingSpinner text={"please Wait..."} className="w-full h-full " />
                    }
                    </div>
                </div> </div>: <div className="w-full h-full flex">
                    <DrogAndDrop CustomEvent={CustomEvent} />
                </div>}
            </div>


        </div>
    )
}