import React, { useState, useEffect, useImperativeHandle } from "react";
import { mixArray, isEqual, element, isNotEmpty, filterJson, removeSvgTag, fadeOut, fadeIn, hide, exceptJson, uniqueArray, notIncludes, isInArray } from "../../../utils/helpers";
import MatchSvg from "../../MatchSvg";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../store";




export const MatchingPair = ({ obj = {}, fieldID = '', StartTime = false, prview = false, setActionCount = null, GameFinsh = null,setScore=null,position=0, setLoadContent=null}) => {

    const { Fields, device } = useSelector((state) => ({ Fields: state.fields, device: state.device }));

    // const { mainPageContent, setActionCount, setStartTime, setresultSce, GameFinsh, setLoadContent, DIVID } = data

    const [iconSize, seticonSize] = useState({ width: 100, height: 100 });
    const [puzzleX, setPuzzleX] = useState([]);
    const [puzzleY, setPuzzleY] = useState([]); 
    const [selected, setSelect] = useState([[], []]);
    const [selectCount, setselectCount] = useState(0); 
    const DisPatch = useDispatch()

    let templateData = Fields[fieldID];
    let Settings = templateData?.filedContent
    let mainPageContent = Settings.game_mainpage_json;
    const [trigger, settrigger] = useState(false)
    const NoOfpair = [...new Array((mainPageContent?.pairItems?.length) / 2)]


    useEffect(() => { 
        if (StartTime) {
            settrigger(!trigger)
            gameSetup(); 
        }
    }, [StartTime])

    useEffect(() => { 
        setTimeout(()=>{ 
            fadeIn("gamePanel"+fieldID)
         gameSetup();  },450) 
    }, [ device]) 

    useEffect(() => {    gameSetup();   }, [mainPageContent,  trigger]) 

    // useEffect(() => { if (mainPageContent?.pairItems?.length > 0) gameSetup(); }, []);

    useEffect(() => {
        if (selected[0].length > 0 && selected[1].length > 0) {
            if (puzzleX[selected[0][1]]?.selected === false && puzzleY[selected[1][1]]?.selected === false) {
                puzzleX[selected[0][1]].selected = true
                puzzleY[selected[1][1]].selected = true
                checkAnswer()
            }
            setSelect([[], []])
        }
    }, [selected, selectCount])


    const gameSetup = () => {   
        let x = [], y = [], temp=[] 
        removeSvgTag("matching_svg_"+fieldID,"line")
    
        mainPageContent?.pairItems?.map((val, inx) => { 
            if(!isInArray(val?.pairValue, temp)){
            let obj = filterJson(mainPageContent?.pairItems, "pairValue", val?.pairValue)
            if (isNotEmpty(obj)) {
                obj[0]=Object.assign({},obj[0], {selected:false,isCorrect:false})
                obj[1]=Object.assign({},obj[1], {selected:false,isCorrect:false})

                x.push(obj[0]);
                y.push(obj[1]);
                            
                temp.push(val?.pairValue)
                temp=uniqueArray(temp)
                }
            }
        })
         
        
        setActionCount(0)
        setPuzzleX(mixArray(x)); 
        setPuzzleY(mixArray(y));
        setselectCount(0); setScore(0);
        seticonSize({
            width: (element("matching_svg_" + fieldID)?.clientWidth) / 3 || 100,
            height: (element("matching_svg_" + fieldID)?.clientHeight) / ((mainPageContent?.pairItems?.length) / 2) || 100
        })
        setLoadContent(false);
    }


    const checkAnswer = () => {
        let elmA = element(selected[0][0])
        let elmB = element(selected[1][0])
        let isCorrect = isEqual(puzzleX[selected[0][1]].pairValue, puzzleY[selected[1][1]].pairValue)
        if (isCorrect) setScore(obj => obj + 1)
        puzzleX[selected[0][1]].isCorrect = isCorrect
        puzzleY[selected[1][1]].isCorrect = isCorrect

        let svgElement = element("matching_svg_" + fieldID);
        let newLine = document.createElementNS("http://www.w3.org/2000/svg", "line");
        newLine.setAttribute("stroke", (isCorrect) ? "rgb(172,255,179,1)" : "rgb(255,124,124,1)");
        newLine.setAttribute("className", "path");
        newLine.setAttribute("stroke-width", "4");
        newLine.setAttribute("x1", elmA.getAttribute("cx"));
        newLine.setAttribute("y1", elmA.getAttribute("cy"));
        newLine.setAttribute("x2", elmB.getAttribute("cx"));
        newLine.setAttribute("y2", elmB.getAttribute("cy"));
        svgElement.appendChild(newLine);

        setActionCount((obj) => {
            obj = obj + 1;
            if ((obj >= (mainPageContent?.pairItems?.length) / 2)) GameFinsh(true)
            return obj;
        })


    }


    async function onOptionSelect(id, cardB, type) {
        let temp = selected; temp[type] = [id, cardB, type]; setSelect(temp)
        setselectCount(o => o + 1)
    }


    return (
        <div className="flex flex-col h-full my-auto" onClick={(e)=>{e.stopPropagation();DisPatch(Actions.setActiveFieldside({filedId:fieldID,index:position,ActiveScreen:"main"}))}} >
            <div className="flex flex-col m-auto  w-[70%] h-[85%] inner-frame rounded-xl smMax:p-5 bg-white relative"  id={"gamePanel"+fieldID} 
            // style={{ background: Settings?.background?.type == "color" ? Settings?.background?.color ?? "#7ED321" :`url(${ Settings?.background?.src ??"/asset/images/quizzes/background.png"}) 0% 0% / 100% 100% no-repeat` }} 
            >
                <MatchSvg DIVID={fieldID} iconSize={iconSize} puzzleX={puzzleX} puzzleY={puzzleY} selected={selected} onOptionSelect={onOptionSelect} device={device}></MatchSvg>
            </div>

        </div>
    );
};

// export default React.forwardRef(Matching);