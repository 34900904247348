import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../../Button1/primarybutton'
import { Planslist } from './Planslist'
import { CheckoutPage } from './checkout'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteUserPLan, planCalculation } from '../../../../actions/Billing'
import toast from 'react-hot-toast'
import { LoadingSpinner } from '../../../Loading'
import { Actions } from '../../../../store'
import { AlertPopup } from '../../../popup/alertPop'



export const Plans = ({ userBillingDetails = {} }) => {

  const { userPlan } = useSelector(state => ({ userPlan: state?.userPlan }))

  const [checkoutPlanDetail, setCheckoutPlanDetail] = useState({})
  const [checkout, setCheckout] = useState(false)
  const [loading, setloading] = useState(false)
  const [alertPopState, setAlertPopState] = useState(false)

  const [ AcceptTheRequest , setAcceptTheRequest ] = useState(false)


  const Dispatch = useDispatch()

  useEffect( ()=> {
    if( AcceptTheRequest ) CancelSubScription()
    else setAlertPopState(false)
  },[AcceptTheRequest])

  const CancelSubScription = async () => {

    try {

      const PlanDetail = await DeleteUserPLan() 
      if (PlanDetail?.success) {
         Dispatch(Actions?.PlaUpdateTrigger({plan:'free',type:'monthly'}))
         setAlertPopState(false)

      }
      else toast.error(PlanDetail?.message)

    } catch (err) {

      toast.error('Can\'t Cancel your current plan')
      setAlertPopState(false)

    }

  } 

  const TriggerCheckOut = async (Checkout, plantype, plan) => {

    if( plan == "free" ) return setAlertPopState(true)

    if (Checkout && (plan !== "free") ) {
  
      setloading(true)

      const PlanDetail = await planCalculation(plan, plantype)

      if (PlanDetail?.success) {
        setCheckoutPlanDetail({ ...PlanDetail.data, changePlan: plan, changetype: plantype })
        Dispatch(Actions?.setPlanCalculation({ ...PlanDetail.data, changePlan: plan, changetype: plantype }))
        setCheckout(true)
      }

      else toast.error(PlanDetail?.message)
    }
    setloading(false)

  }

  return (

    <dvi className="flex w-full h-full">

      { alertPopState && <AlertPopup title={'Do you want cancel your current plan?'} Accecpt={setAcceptTheRequest} Cancel={setAcceptTheRequest} /> }

      {loading ? <LoadingSpinner /> :
        <>

          {

            !checkout ? <Planslist setCheckout={TriggerCheckOut} /> : <CheckoutPage cardInfo={userBillingDetails?.card} setCheckout={setCheckout} checkoutPlanDetail={checkoutPlanDetail} />

          }
        </>
      }

    </dvi>
  )
}
