import React, {  useEffect } from "react";
import Sidenavbar from "../components/nav/siderbar";
import DefaultHeader from "../components/header/defaultheader";
import {  useSelectionAction } from "./customstate/context";
import { getUser } from "../actions/user";
import { useDispatch } from "react-redux";
import { Actions } from "../store";
import { LoadingSpinner } from "../components/Loading";
import { useNavigate } from "react-router-dom";
import { getProjects } from "../actions/projects";
import { getSharedprojectforUser } from "../actions/sharedproject";
import { InterCom } from "../components/intercom";
import { NewIntroScreem } from "./newintro";





const Layout = ({ children, padding = "px-6 pt-4", small = false, preview = false, setediter = false, isactive = 0, activeSidetap = false, sidebarView = false, Ecommerce = false, bgcolor = "" }) => {

    const Navigate = useNavigate();

    const { LoadContent, setLoadContent, setSidebarTrigger, ShowPageMainContent, setShowPageMainContent } = useSelectionAction();

    const Dispatch = useDispatch();

    const token = localStorage.getItem('access_token');

    if (!token?.length) Navigate('/login');

    useEffect(() => {

        getUserInfo();
        fetchUserData();

    }, [])


    const fetchUserData = async () => {

        try {

            const ProjectList = await getProjects()
            const sharedProjectList = await getSharedprojectforUser()

            Dispatch(Actions?.setProjects(ProjectList?.data ?? []))
            Dispatch(Actions?.setSharedProjects(sharedProjectList?.data ?? []))

        } catch (err) {
            Dispatch(Actions?.setProjects([]))
            Dispatch(Actions?.setSharedProjects([]))
        }

    }

    const getUserInfo = async () => {

        const data = await getUser(); 

        Dispatch(Actions.setengage_apptour(data?.data?.engage_apptour==="false"))

        let userDetail = data.data;

        userDetail['profile'] = data.data?.engage_user_profile ? data.data?.engage_user_profile : localStorage.getItem('avatar');

        Dispatch(Actions.setUserDetail(userDetail))

        if (data.data?.setup != 'true') {

            setLoadContent(true)
            setShowPageMainContent(false);
            setSidebarTrigger(true)

        }

        else {

            setLoadContent(true);
            setShowPageMainContent(true);

        }

    }

    return (
        <>


            {!LoadContent ? <LoadingSpinner height="h-screen" className="top-0 bottom-0 right-0" /> :

                <div className="h-screen w-screen overflow-hidden">

                    { !ShowPageMainContent ? <NewIntroScreem /> : <>

                        {!preview && <DefaultHeader editer={setediter} Ecommerce={Ecommerce} />}

                        {<div className={`flex absolute bottom-0 ${preview ? "top-0" : "top-[77px]"}  left-0 right-0 `}>

                            {!sidebarView && <Sidenavbar active={ShowPageMainContent ? false : isactive} sidetab={activeSidetap} ></Sidenavbar>}

                            <div className={`w-full overflow-x-hidden list-industry ${bgcolor}`} style={{ zIndex: '1' }} >

                                <div className={`${padding} h-full`}  >

                                    {children}

                                </div>

                            </div>

                        </div>}
                    </>
                    }

                </div>
            }

        </>
    )
}

export default Layout;