import React, { useEffect, useState } from "react";
import PrimaryButton from "../Button1/primarybutton";
import { useNavigate } from "react-router-dom";
import { isEmpty, isNotEmpty } from "../../utils/helpers";
import { CalculaterResult } from "../../utils/Calculater";
import toast from "react-hot-toast";
import { EcomProductFilter } from "../../utils/EcomProductFilter";
import { NonGameScratchCard } from "./NonGameScratchCard";
import { QuizResultBlock } from "./ResultSceenBlocks/QuizResultblock";
import { PollResultBlock } from "./ResultSceenBlocks/PollResultBlock";
import { CalculatorResultBlock } from "./ResultSceenBlocks/CalculatorResultBlock";
import { EcommereceResultBlock } from "./ResultSceenBlocks/EcommereceResultblock";
import { useDispatch, useSelector } from "react-redux";


export const ResultPage = ({
    restartAction = null,
    voteCount = [],
    ArrQuestion = [],
    UseSubmitData = {},
    type = '',
    values = [],
    formula = [""],
    Score = 0,
    question,
    primaryColor = 'white',
    secondaryColor = "black",
    productlist = [],
    ThankYouPageObj = {},
    background = {},
    UserAns = []
}) => {

    // const ResultPage = new CampaignResponseEvent({category:""})
 
    const Navigator = useNavigate()

    const { campaignSettings } = useSelector( state => ({ campaignSettings: state.campaignSettings }) );

    const { fontSize } = campaignSettings;

    const TitleSize = {
        "small": "text-[1.5rem] font-medium",
        "medium": "text-[1.75rem] font-medium",
        "large": "text-[1.90rem] font-medium"
    }

    const DescriptionSize = {
        "small": "text-[12px] md:text-sm",
        "medium": "text-sm md:text-base",
        "large": "text-base md:text-[20px] font-medium"
    }

    const ButtonSize = {
        "small": "text-[14px]",
        "medium": "text-[16px]",
        "large": "text-lg"
    }

    const redirectAction = () => { if (isNotEmpty(ThankYouPageObj?.reDirUrl)) window.location.href = ThankYouPageObj?.reDirUrl }

    return (
        <div className=" flex w-full h-full top-10 " style={{ background: background?.type == "color" ? background?.color ?? "#7ED321" : `url(${background?.src ?? "/asset/images/quizzes/background.png"}) 0% 0% / 100% 100% no-repeat`, backgroundSize: "cover" }}>

            <div className="relative w-full h-full bg-[rgba(0,0,0,0.3)] flex overflow-y-auto" >

                <div className="w-[90%]  max-w-[95%]  flex flex-col text-white rounded-2xl px-8 py-10 space-y-6 my-auto mx-auto justify-center" style={{ color: primaryColor }}>

                    <h3 className={` ${TitleSize[fontSize]} text-center `}   >{ThankYouPageObj?.titleContent}</h3>

                    {(ThankYouPageObj?.descriptionContent !== "") && <p className={`mx-auto ${DescriptionSize[fontSize]}`}>{ThankYouPageObj?.descriptionContent}</p>}

                    {

                        isNotEmpty(ThankYouPageObj?.imageUrl) && <div className="w-[360px] mx-auto rounded-xl">

                            <img src={ThankYouPageObj?.imageUrl} alt={"thumbnail"} className="w-full rounded-xl" />

                        </div>

                    }

                    <NonGameScratchCard ThankYouPageObj={ThankYouPageObj} />

                    {(type !== 'poll' && type !== 'ecommerce' && type !== 'calculater' && type !== 'surveys') && <h3 className={` ${TitleSize[fontSize]} text-center `}>{Score}</h3>}

                    {(type === "ecommerce") && <EcommereceResultBlock pageContent={ThankYouPageObj} productList={productlist} userAnswer={UserAns} />}

                    {(type === "calculater") && <CalculatorResultBlock setting={ {primaryColor,secondaryColor} } pageContent={ThankYouPageObj} Type={type} Question={question} Formula={formula} val={values} /> }

                    {type == 'poll' && <PollResultBlock questions={ArrQuestion} usesvoteCount={voteCount} />}

                    {
                        ThankYouPageObj?.reStart ?

                            <PrimaryButton style={{ color: secondaryColor, background: primaryColor, borderColor: primaryColor }} text={ThankYouPageObj?.restartbutton} width={'min-w-28 max-w-[240px]'} padding_y="p-2" height="h-fit" classNames={"border-1 border-white"} margin={'mx-auto'} textcolor="text-black" textsize={ButtonSize[fontSize]} backgroundColor="bg-white" OnClick={restartAction} /> :

                            <PrimaryButton text={ThankYouPageObj?.restartbutton} style={{ color: secondaryColor, background: primaryColor, borderColor: primaryColor }} width={'min-w-28 max-w-[240px]'} padding_y="p-2" height="h-fit" margin={'mx-auto'} textsize={ButtonSize[fontSize]}  OnClick={() => redirectAction()} />
                    }

                    {type == 'quiz' && <QuizResultBlock questions={ArrQuestion} SubmitData={UseSubmitData} />}

                </div>
            </div>
        </div>

    )
}