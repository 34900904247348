import React from 'react'
import PropTypes from "prop-types";

const ListItemTitle = function ({ text, textColor = "text-primary-bold", margin = "", textSize = "16px", fontStyle = "font-medium" }) {
    return (
        <div className={` ${margin}`}>
            <p className={`${fontStyle} text-${textSize} ${textColor} `}>{text}</p>
        </div>
    )
}



ListItemTitle.prototype = {
    text: PropTypes.string.isRequired,
    textColor: PropTypes.string,
    margin: PropTypes.string,
    textSize: PropTypes.string
}

export { ListItemTitle }