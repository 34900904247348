import React, { useState } from "react";
import InputWithLabel from "./input/inputWithLabel";
import { Imageinput } from "./input/imageinput";
import InputRadiocheck from "./input/radiocheck";
import PrimaryButton from "./Button1/primarybutton";
import Input from "./input/input";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../store";
import DropMenu from "./input/dropmenu";
import DefaultDropMenu from "./input/droplist";
import { MediaPopup } from "./popup/Mediapopup";
import SwicthInput from "./input/swicth";
import TextareaInput from "./input/textarea";
import { MultiSelectInput } from "./input/multiSelectOption";
import { TooltipData } from "../content/toolTip";
import { listOfIcon } from "../content/iconList";
import { template } from "lodash";

export const PersonalizationQue = ({
    Index,
    fieldId = "",
    question = "",
    optionType = "yerorno",
    options = ["Options", "Options", "Options", "Options"],
    imgSrc = "",
    answer = "",
    answerDescription = "",
    required = false,

}) => {

    const { Fields } = useSelector((state) => ({ Fields: state.fields, }));

    const templateData = Fields[fieldId];
    const Settings = templateData?.filedContent

    const resultScreenContent = Settings?.game_resultcreen_json;

    const resultList = resultScreenContent.results?.map( list => list?.titleContent )


    const DisPatch = useDispatch()

    const DefaultCC = (value, FID = fieldId, eage, optionIndex = 0) => {

        const setContent = {
            key: FID,
            node: 'game_mainpage_json',
            child: 'questions',
            edge: eage,
            QueIndex: Index,
            optionIndex: optionIndex,
            value: value
        }

        DisPatch(Actions.setQuizQuestionCC(setContent))
    }

    const DefaultCCoption = (value, eage, optionIndex = 0) => {

        const setContent = {
            key: fieldId,
            node: 'game_mainpage_json',
            child: 'questions',
            templateType:'personalize-quiz',
            edge: eage,
            QueIndex: Index,
            optionIndex: optionIndex,
            value: value
        }

        DisPatch(Actions.setQuizQuestionCC(setContent))
    }

    const DirectContentChange = (value, type, optionIndex = 0) => {


        const setContent = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_mainpage_json',
            child: "questions",
            edge: "image",
            QueIndex: Index,
            optionIndex: optionIndex,
            value: value
        }
        DisPatch(Actions.setQuizQuestionCC(setContent))

        if (ShowMedia) setShowMidia(false)

    }

    const QuesRangechange = (type) => {

        const setContent = {
            key: fieldId,
            node: 'game_mainpage_json',
            child: 'questions',
            position: Index,
            type: type
        }

        DisPatch(Actions.setQuizQuestionRA(setContent))
    }

    const addOption = (remove=false,optionIndex=0) => {
        const setContent = {
            key: fieldId,
            node: 'game_mainpage_json',
            child: 'questions',
            edge: 'options',
            QueIndex: Index,
            optionIndex: optionIndex,
            value:{
                "option":"option A",
                "results":[]
            },
            remove:remove
        }
        DisPatch(Actions.setAddOption(setContent))
    }

    const toolTip = TooltipData();

    const [ShowMedia, setShowMidia] = useState(false);

    const deleteIcon = listOfIcon('delete',20,20,'red');

    const multiSelectIcon = listOfIcon('multiSelect',20,20,'#586474');
    
    
    return (
        <>
            {ShowMedia && <MediaPopup cancel={setShowMidia} fieldType={'image'} OnSelect={DirectContentChange} />}

            <div key={Index} className={`w-full relative flex  flex-col space-y-2 border-2  h-fit delay-400 duration-700 top-0 p-4 rounded-xl hover:shadow-md hover:-top-0 hover:border-primary`}  >

                <div className="flex justify-between">

                    <p className="text-base font-medium" >Question - {Index + 1}</p>

                    <div className="flex space-x-2">
                        {
                            toolTip.map((list, index) => {
                                return (
                                    <p key={index} className="p-2 rounded-md border-1 hover:bg-gray-200 cursor-pointer" onClick={() => QuesRangechange(list.type)} >{list.icon}</p>
                                )
                            })
                        }
                    </div>

                </div>


                {/* Question Editor */}
                <div className="flex flex-col space-y-4">

                    {/* Question */}
                    <InputWithLabel label="Question" textvalue={question} onchange={(e) => DefaultCC(e.target.value, fieldId, 'question')} />

                    {/* Question Image */}
                    <Imageinput label="Cover image" src={imgSrc} chanaeAction={() => setShowMidia(true)} deleteSrc={() => DefaultCC('', fieldId, 'image')} />

                    {/* Question option */}
                    <div className="space-y-3">

                        <label className="capitalize" >Answer options</label>


                        {options.map((list, index) => {

                            return (

                                <div key={index} className="w-full flex space-x-2 relative">

                                    <Input margin={''} textvalue={list?.option} onchange={(e) => DefaultCCoption( e.target.value, 'option', index )} />

                                    <MultiSelectInput Children={multiSelectIcon} optionList={resultList} selectOption={list?.results ?? []} onClickOption={ ( ind ) => DefaultCCoption( resultList[ind], 'results', index ) } />

                                    <span className="p-2 rounded-md border-1 cursor-pointer" onClick={()=>addOption( true, index )} >{ deleteIcon }</span>

                                </div>
                            )
                        })}


                    </div>

                    < PrimaryButton buttontype="secondarybutton" text={'Add option'}  OnClick={()=>addOption()} />

                </div>
            </div>
        </>
    )
}