import React, { useState } from "react";
import IndustryData from "../../../content/industrydata";
import { categoryData, industrycategoryData } from "../../../content/category";
import PrimaryButton from "../../Button1/primarybutton";
import { templatelist, updateUser } from "../../../actions/user";
import { useDispatch, useSelector } from "react-redux";
import { CgSpinner } from "react-icons/cg";

export const CategoryChooseIntro = ({
    forWardAction=null,
    indactive=0,
}) => {

    const  { introUserInfoGS } = useSelector(state => ({introUserInfoGS:state.introUserInfoGS}))

    let {full_name,company_name,site_owned} = introUserInfoGS

    const CategoryData = industrycategoryData();
    const industryData = IndustryData();

    const [loadingLive,setloadingLive] = useState(false)

    const [active, setActive] = useState(0);

    const Templatelist = async (indusrty, category,payload) => {

        const updateUserDetail = await updateUser({...payload, setup: "true" });
        const data = await templatelist(indusrty, category);

        if (data.status == true) window.location.href = '/template';

    }

    const statechange = (index) => {

        try{
            setloadingLive(true)

            setActive(index);

            Templatelist(industryData[indactive].title,CategoryData[active].title,{full_name,company_name,site_owned})
            
            forWardAction(5)
        }catch(err) {
            setloadingLive(false)
        }
        

        // updateValue.industry=industryData[index].title;
        // stateChangefunc (updateValue);
        // statuschange(true)
        
    }

    return (
        <section className="flex flex-col w-full h-full overflow-y-scroll list-industry" >

            <div className="w-[75%] flex flex-col mx-auto justify-center my-auto space-y-8 py-10 ">
                <h3 className="text-xl font-semibold text-center capitalize" >Choose category</h3>

                <div className="w-full grid grid-cols-4 gap-5 p-4 bg-[#FAFCFE] rounded-xl  " >
                    {CategoryData.map((industry, index) => {

                        return (
                            <>
                                <div key={index} className={`  flex shadow-card-rate w-full flex-col justify-center ${(active == index) ? "bg-primary" : "bg-white"}   rounded-xl  mx-auto  px-2 py-6 cursor-pointer`} onClick={() => statechange(index)} >
                                    <div className="mx-auto scale-75">
                                        <img src={`${industry.src}`} alt="Category icon" />
                                    </div>
                                    <p className="mx-auto font-medium text-sm capitalize text-center ">{industry.title}</p>
                                </div>
                            </>

                        )
                    })}
                </div>

                        
                <PrimaryButton OnClick={() => statechange(0)} text={'Submit'} icon={
                    loadingLive ? <CgSpinner className="w-5 h-5 animate-spin text-white"  /> : null
                } disabled={loadingLive} />

            </div>

        </section>
    )
}