import { map } from "lodash";
import { list } from "postcss";
import { toast } from "react-hot-toast"

export class CalculaterResult {

    constructor(prop) {
        this.campaign_id = prop.campaign_id || ""
        this.rusults = prop.results || ""
        this.question = prop.question || []
    }

    SumResult(formula, Payload, QusObj) {

        let setFormulaStr = ""

        let splitFormula = this.userInputFormula(formula)

    }

    userInputFormula(Value, answerArr = [], resultScreen = false) {
        // // console.log({Value,answerArr,resultScreen})
        let result = [];
        let status = true;
        let message = ''
        const ConfigParrten = /q\d+|sum|ave|min|max|pow|[0-9]+/g
        const ConfigFunParrten = /sum|ave|min|max|pow/g
        const operatotParrten = /[-/x\+\|\(|\|)]/
        const inVaildSum = /undefined|null|nan/g

        const SplitbyOP = Value.split(/[-\,/x\+\|\(|\|)]/).filter(obj => obj !== "")

        const expression = Value.replace(ConfigParrten, "#").replace(/[\(|\|)]/g, "");

        const ParrtenBarket = Value.split(/[\(|\|)]/g).filter(obj => obj !== "")
        const ParrtenOP = expression.split("#").filter(obj => obj != "");
        const QuesList = Value.split(/[^q\d|sum|ave|min|max|pow]+/).filter(obj => obj !== "")
            // // console.log({QuesList})

        QuesList.map((obj, index) => {
            if (ConfigFunParrten.test(obj)) {
                if (index > -1) {
                    let isOperator = /[-\/x\+\|\(|\|)]/.test(ParrtenOP[index - 1])

                    if ((!isOperator || ParrtenOP[index - 1] == ',') && index !== 0) { ParrtenOP.splice(index - 1, 0, '*') }
                    ParrtenOP.splice(index, 0, '')
                }
            }
        })

        QuesList.map((list, index) => {

            if ((QuesList[index].search(ConfigParrten) < 0)) { status = false;
                message = "inVaild Formula"; }
            if (/q/.test(list)) {
                let TempObj = String(list).toLowerCase().split('q')
                let QueIndex = TempObj[1] - 1
                if (QueIndex < 0 || QueIndex >= this.question.length) { status = false;
                    message = "inVaild question Index"; }
                // QueIndex
            }



            result.push(QuesList[index]);

            if (index < ParrtenOP.length) result.push(ParrtenOP[index]);

        })

        let issumFunPB = {
            issumFunPBInds: [],
            issumFunRAInds: [],
            total: 0
        }
        let isaveFunPB = {
            issumFunPBInds: [],
            issumFunRAInds: [],
            total: 0
        };
        let isminFunPB = {
            issumFunPBInds: [],
            issumFunRAInds: [],
            total: 0
        };
        let ismaxFunPB = {
            issumFunPBInds: [],
            issumFunRAInds: [],
            total: 0
        };



        ParrtenBarket.map((obj, index) => {
            if (/sum/g.test(obj)) issumFunPB.issumFunPBInds.push(index)
            if (/ave/g.test(obj)) isaveFunPB.issumFunPBInds.push(index)
            if (/min/g.test(obj)) isminFunPB.issumFunPBInds.push(index)
            if (/max/g.test(obj)) ismaxFunPB.issumFunPBInds.push(index)
        })

        // result.map((obj, index) => {
        //     if (/sum/g.test(obj)) issumFunPB.issumFunRAInds.push(index)
        //     if (/ave/g.test(obj)) isaveFunPB.issumFunRAInds.push(index)
        //     if (/min/g.test(obj)) isminFunPB.issumFunRAInds.push(index)
        //     if (/max/g.test(obj)) ismaxFunPB.issumFunRAInds.push(index)
        // })

        issumFunPB.issumFunPBInds.map((list, index) => {

            let sumElem = ParrtenBarket[list + 1].split(',')


            let LastVal = (sumElem.length + (sumElem.length - 1))

            let issumFunRAInds = []

            result.map((obj, index) => { if (/sum/g.test(obj)) issumFunRAInds.push(index) })
                // // console.log({list,issumFunPB,sumElem,result})
            if (result[issumFunRAInds[index] + LastVal + 2 + index] === ",") { status = false;
                message = "inVaild sum" }

            // if (/q\d+/g.test(result[issumFunPB.issumFunRAInds[index] + LastVal + addvale + index])) 

            result.splice(issumFunRAInds[index] + LastVal + 2, 0, '')
                // // console.log({list,issumFunPB,sumElem,result})

            issumFunPB.total += 1
        })



        isaveFunPB.issumFunPBInds.map((list, index) => {
            let sumElem = ParrtenBarket[list + 1].split(',')
            let LastVal = (sumElem.length + (sumElem.length - 1))
            let issumFunRAInds = []

            result.map((obj, index) => { if (/ave/g.test(obj)) issumFunRAInds.push(index) })

            if (result[issumFunRAInds[index] + LastVal + 2 + index] === ",") { status = false;
                message = "inVaild sum" }
            // if (/q\d+/g.test(result[issumFunPB.issumFunRAInds[index] + LastVal + addvale + index])) 
            result.splice(issumFunRAInds[index] + LastVal + 2, 0, '')
            issumFunPB.total += 1


        })


        isminFunPB.issumFunPBInds.map((list, index) => {

            let sumElem = ParrtenBarket[list + 1].split(',')
            let LastVal = (sumElem.length + (sumElem.length - 1))

            let issumFunRAInds = []
            result.map((obj, index) => { if (/min/g.test(obj)) issumFunRAInds.push(index) })

            if (result[issumFunRAInds[index] + LastVal + 2 + index] === ",") { status = false;
                message = "inVaild sum" }
            // if (/q\d+/g.test(result[issumFunPB.issumFunRAInds[index] + LastVal + addvale + index])) 
            result.splice(issumFunRAInds[index] + LastVal + 2, 0, '')
            issumFunPB.total += 1

        })


        ismaxFunPB.issumFunPBInds.map((list, index) => {

            let sumElem = ParrtenBarket[list + 1].split(',')
            let LastVal = (sumElem.length + (sumElem.length - 1))

            let issumFunRAInds = []
            result.map((obj, index) => { if (/max/g.test(obj)) issumFunRAInds.push(index) })

            if (result[issumFunRAInds[index] + LastVal + 2 + index] === ",") { status = false;
                message = "inVaild sum" }
            // if (/q\d+/g.test(result[issumFunPB.issumFunRAInds[index] + LastVal + addvale + index])) 
            result.splice(issumFunRAInds[index] + LastVal + 2, 0, '')
            issumFunPB.total += 1
        })

        let IsOP = ParrtenOP.findIndex(obj => obj === result[result.length - 1] && obj !== "")
        if (IsOP >= 0 || result[result.length - 1] === ',') { status = false;
            message = "inVaild operator" }


        let replacwQue = Value.replace(ConfigParrten, "#")

        let CountArr = 0
        let TempArr = resultScreen ? answerArr : this.question.map(list => Math.round(Math.random() * 10))
        let setSum = ""
        let CustomFun = false
        var CustomFunStage = ''

        let sum = {
            sumVal: 0,
            Total: 0,
            status: false
        }
        let ave = {
            sumVal: 0,
            Total: 0,
            status: false
        }
        let min = {
            sumVal: 0,
            Total: 0,
            status: false
        }
        let max = {
            sumVal: 0,
            Total: 0,
            status: false
        }

        let pow = {
            open: 0,
            Total: "",
            status: false
        }
        // console.log(replacwQue, "replacwQue");
        for (let i = 0; i < replacwQue.length; i++) {
            let ispow = false;

            if( pow.status && replacwQue[i] == ")"  ) {
                // console.log(pow.open,"--------------->e6278  ------>99")

                pow.open -= 1;
                if(pow.open  < 1) pow.status =false;

            }

            //if is not a "()" barkets
            if (replacwQue[i] == "#" || /[-\,\*/x\+]/.test(replacwQue[i]) || result[CountArr] === "") {
                // // console.log(setSum,replacwQue[i] ,"----------->replacwQue[i] == 3123------->",result[CountArr])

                let QueIndex = 0
                // console.log(result[CountArr],"result[CountArr]  ------>2");

                //If is Qestion type and not a CustomFun
                if ((replacwQue[i] == "#") && (result[CountArr] !== "") && !ConfigFunParrten.test(result[CountArr]) && !CustomFun) {
                    // console.log(result[CountArr],"result[CountArr]  ------>8");

                    if (/^[0-9]+/g.test(result[CountArr])) { setSum = setSum + result[CountArr] } else {
                        // console.log({ CustomFun }, "1521")
                        let TempObj = String(result[CountArr]).toLowerCase().split('q')
                        QueIndex = TempObj[1] - 1
                        setSum = setSum + TempArr[QueIndex]
                    }
                }

                else if (/pow/g.test(result[CountArr] )) {
                    // console.log(pow.sumVal,"pow.sumVal  ------>10")
                    setSum = setSum + "Math.pow(" ;
                    pow.status = true;
                    pow.open +=1;
                    
                }

                //If is Custom Function type 
                else if (/sum|ave|min|max/g.test(result[CountArr])) {

                    if (!CustomFun) {
                        // console.log(pow.sumVal,"pow.sumVal  ------>9")
                        CustomFun = true;
                        CustomFunStage = result[CountArr]
                    } else { status = false;
                        message = "inVaild Operation" }

                }
                // // console.log({result})
                else if (result[CountArr] === "") {
                    // // console.log("fwefwe------=======>",!sum.status)
                    if (CustomFunStage == "sum") { // console.log(pow.sumVal,sum.status,"sum.sumVal  ------>12")
                        if (!sum.status) sum.status = true;
                        else { sum.status = false;
                            CustomFun = false;
                            setSum = setSum + sum.sumVal } }
                    if (CustomFunStage == "ave") { if (!ave.status) ave.status = true;
                        else { ave.status = false;
                            CustomFun = false;
                            setSum = setSum + ave.sumVal } }
                    if (CustomFunStage == "min") { if (!min.status) min.status = true;
                        else { min.status = false;
                            CustomFun = false;
                            setSum = setSum + min.sumVal } }
                    if (CustomFunStage == "max") { if (!max.status) max.status = true;
                        else { max.status = false;
                            CustomFun = false;
                            setSum = setSum + max.sumVal } }
                    if (CustomFunStage == "pow") { 
                        // console.log(pow.sumVal,"pow.sumVal  ------>3")
                        // CustomFun = false;
                        // setSum = setSum + "Math.pow" 
                        ;if (!pow.status) {pow.status = true; pow.Total += "Math.pow("}
                        else { // console.log(pow.sumVal,"pow.sumVal--->");pow.status = false;
                            // console.log(pow.sumVal,"pow.sumVal  ------>11")
                            CustomFun = false;
                            setSum = setSum + pow.Total } 
                        }

                }
                //If is Qestion type and  CustomFun
                else if ((CustomFun && replacwQue[i] == "#") && !ConfigFunParrten.test(result[CountArr])) {

                    let TempObj = String(result[CountArr]).toLowerCase().split('q')
                    QueIndex = TempObj[1] - 1

                    if (sum.status) {
                        sum.sumVal += `+${parseInt(TempArr[QueIndex])}`
                        sum.Total += 1
                    }

                    // if (pow.status) {

                    //     if( replacwQue[i] )
                    //     pow.Total += `${parseInt(TempArr[QueIndex])}`
                    //     // console.log(pow.Total,"----->pow.sumVal");
                    //     // console.log("pow------------->3", replacwQue[i], "---->", i,TempArr[QueIndex], TempArr,replacwQue)
                    // }

                    if (ave.status) {
                        ave.Total += 1
                        ave.sumVal = (ave.sumVal + TempArr[QueIndex]) / ave.Total
                    }
                    if (min.status) { min.sumVal = (min.Total == 0) ? TempArr[QueIndex] : min.sumVal < TempArr[QueIndex] ? min.sumVal : TempArr[QueIndex];
                        min.Total += 1 }
                    if (max.status) { max.sumVal = (max.Total == 0) ? TempArr[QueIndex] : max.sumVal > TempArr[QueIndex] ? min.sumVal : TempArr[QueIndex];
                        max.Total += 1 }
                } else {

                    // console.log(setSum,"------setSum----->  ------>4",replacwQue[i])
                    setSum = ( !pow.status && ((replacwQue[i] == ",") || (result[CountArr] === ""))) ? setSum : (setSum + replacwQue[i])
                    // console.log(setSum);
                }

                // CountArr = (/pow/g.test(result[CountArr]) )? CountArr : CountArr + 1;
                CountArr += 1;

            } else {
                if (replacwQue[i] == '.') {
                    // console.log("Dot------------------>  ------>5")
                    CountArr += 1;
                }

                if( pow?.status && (replacwQue[i] == "(")){
                     pow.open +=1;
                    // console.log(pow.open,"--------------->e6278  ------>90")
                     
                }

                if (replacwQue[i] == ")" && replacwQue[i + 1] == "(") {

                    setSum = setSum + replacwQue[i] + "*";
                    if (result[CountArr]) CountArr += 1;
                    // console.log(result[CountArr],"--------------->e6278  ------>6")
                } else {
                    setSum = setSum + replacwQue[i]
                    // console.log(setSum,"----------->3123  ------>7",replacwQue[i])

                }
            }


        }

        let isInvalidSum = inVaildSum.test(setSum)

        if (isInvalidSum) return ({ status: false, message: "InvalidSum", data: result, sample: setSum })

        return result ?.length == 0 ? ({ status: false, message: "Empty Value", data: result, sample: setSum }) : ({ status, message, data: status ? result : [], sample: setSum })

    }
}