import React, {  useState } from "react"; 
import { useDispatch } from "react-redux";
import { Actions } from "../../../../store";
import { MediaPopup } from "../../../popup/Mediapopup";
import { ColorPixerInput } from "../../../input/colorpixerInput"; 
import { Imageinput } from "../../../input/imageinput";
import InputWithLabel from "../../../input/inputWithLabel";

export const SpinWheelSettings = ({ field, fieldId }) => {

    const DisPatch = useDispatch()

    const [primColor, setPrimcolor] = useState(field?.colorArray[0])
    const [secColor, setSeccolor] = useState(field?.colorArray[1])
    const [PtextColor, setPTextcolor] = useState(field?.wheelTextColor || "#333333" )
    const [StextColor, setSTextcolor] = useState(field?.wheelSecondaryTextColor || "#333333")
    const [pegColor, setPegcolor] = useState(field?.pegFill) 
    const [strokeColor, setStrokeColor] = useState(field?.wheelStrokeColor) 

    const [ShowMedia, setShowMidia] = useState(false);

   

    const DefaultCC = (value,  fieldId, child) => { 
        const payload = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_mainpage_json',
            child: child,   
            value: value
        }
       DisPatch(Actions.setWheelMainCC(payload))
    }


    const PrimaryColorchage = (color, type, filedId) => {  
        setPrimcolor(color) 
        DefaultCC([color,secColor], filedId,  type)
    } 
    const SecondColorchage = (color, type, filedId) => {  
        setSeccolor(color) 
        DefaultCC([primColor,color], filedId,  type)
    }
    const PTextColorchage = (color, type, filedId) => {  
        setPTextcolor(color)  
        DefaultCC(color, filedId,  type)
    }

    const STextColorchage = (color, type, filedId) => {  
        setSTextcolor(color)  
        DefaultCC(color, filedId,  type)
    }
    const PegColorchage = (color, type, filedId) => {  
        setPegcolor(color) 
        DefaultCC(color, filedId,  type)  
        DefaultCC(color, filedId,  "centerCircleFillColor")  
    }
    const StrokeColorchage = (color, type, filedId) => {  
        setStrokeColor(color)
        DefaultCC(color, filedId,  type)  
        DefaultCC(color, filedId,  "centerCircleStrokeColor")  
    }
    
    const DirectContentChange = (value, type, optionIndex = 0) => { 
        const setContent = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_mainpage_json',
            child: "centerLogo",   
            value: value
        }
    DisPatch(Actions.setWheelMainCC(setContent))

        if (ShowMedia) setShowMidia(false)
 
    } 
    return(  
        <> {ShowMedia && <MediaPopup cancel={setShowMidia} fieldType={'image'} OnSelect={DirectContentChange} />}

        <div className="w-full space-y-4 " key={ShowMedia} >
        <Imageinput label="Center logo"  src={field?.centerLogo} chanaeAction={() => setShowMidia(true)} deleteSrc={()=>DirectContentChange('',"","")}/>

        <InputWithLabel label="Segment font size"  labelTextsize=" text-base font-medium" textvalue={field?.wheelTextSize} onchange={(e) => DefaultCC(e.target.value, fieldId, 'wheelTextSize')} viewiconState={true} labelTextWeight="flex justify-between"   />


       <div> <label className="capitalize text-base font-medium" >Spin wheel colors </label></div>
<ColorPixerInput label={"Primary Color"} labelTextsize={'text-sm my-auto'} fieldType={"colorArray"} fieldId={fieldId} Onchange={PrimaryColorchage} colorHex={primColor} /> 
<ColorPixerInput label={"Secondary Color"} labelTextsize={'text-sm my-auto'} fieldType={"colorArray"} fieldId={fieldId} Onchange={SecondColorchage} colorHex={secColor} /> 

<ColorPixerInput label={"Primary Text Color"} labelTextsize={'text-sm my-auto'} fieldType={"wheelTextColor"} fieldId={fieldId} Onchange={PTextColorchage} colorHex={PtextColor} /> 
<ColorPixerInput label={"Secondary Text Color"} labelTextsize={'text-sm my-auto'} fieldType={"wheelSecondaryTextColor"} fieldId={fieldId} Onchange={STextColorchage} colorHex={StextColor} /> 

<ColorPixerInput label={"Peg Color"} labelTextsize={'text-sm my-auto'} fieldType={"pegFill"} fieldId={fieldId} Onchange={PegColorchage} colorHex={pegColor} /> 
<ColorPixerInput label={"Stroke Color"} labelTextsize={'text-sm my-auto'} fieldType={"wheelStrokeColor"} fieldId={fieldId} Onchange={StrokeColorchage} colorHex={strokeColor} /> 
       

        </div>
</>
    )
    
}