import React from 'react'
import { TeamMembers } from './Team/TeamMembers'
import { ShowTeamList } from './Team/ShowTeamList'
// import { TeamMembers } from './Team/TeamMembers'


export const Team = () => {
  return (
    <TeamMembers />
    // <div> hello</div>
  )
}
