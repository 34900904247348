import React from "react";
import { useDispatch, useSelector } from "react-redux";


export const QuizResultBlock = ({ questions = [], SubmitData={} }) => {

    const { campaignSettings } = useSelector( state => ({ campaignSettings: state.campaignSettings }) );

    const { fontSize } = campaignSettings;

    const ParSize = {
        "small":"text-sm",
        "medium":"text-base",
        "large":"text-[18px]"
    }

    return (
        <section className="W-full rounded-xl bg-white p-6  space-y-8" >

            {
                questions?.map((que, index) => {

                    const { question, options, optionType, answer } = que;

                    let optionsData = optionType == 'yesorno' ? ['Yes', 'No'] : options;

                    return (

                        <>

                            <div className="flex flex-col space-y-3" >

                                <p className={`text-black ${ ParSize [ fontSize ] } `} >{index + 1}. {question}</p>

                                { (( optionType == 'yesorno' ) || ( optionType == 'multioption')) &&

                                    <div className=" w-full grid grid-cols-2 gap-3" >

                                        {

                                            optionsData?.map((option) => {

                                                let isCorrectAnswer = String(answer).toLowerCase() == String(option).toLowerCase();

                                                const IsThisUserSelectOption =  String( SubmitData[ question ] ).toLowerCase() == String(option).toLowerCase()  ;//String(answer) !== ( String( SubmitData[ question ] ).toLowerCase() == String(option).toLowerCase() )

                                                return ( <p className={` ${ ParSize [ fontSize ] } py-2 px-3 text-black rounded-lg border-1  overflow-hidden  ${ IsThisUserSelectOption ? (IsThisUserSelectOption == isCorrectAnswer) ? "bg-[#05c27020] border-[#05c270]" : " bg-red-50 border-red-500" : "" } ${ isCorrectAnswer ? "bg-[#05c27020] border-[#05c270]":"" }`} > {option} </p>)


                                            })
                                        }

                                    </div>}

                                {((optionType == 'yesorno') || (optionType !== 'multioption')) && <p className={`${ ParSize [ fontSize ] } text-black`} > Answer: {SubmitData[ question ]}</p> }

                            </div>

                            <hr className="border-2" />

                        </>
                    )
                })

            }

        </section>
    )

}