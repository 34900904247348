import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditorTooltip } from "../Editor/common/editortooltip";
import { isEmpty } from "../../utils/helpers";
import { Actions } from "../../store";
import { Rangeinput } from "../input/rangeInput";

export const AudioREBlock = ({ fieldID = '', position, priview = false }) => {

  const { Fields } = useSelector((state) => ({ Fields: state.fields }));
  const DisPatch = useDispatch()

  const AudioContent = Fields[fieldID];
  const ACfiledContent = AudioContent['filedContent'];

  let audioSource = ACfiledContent?.audioSrc ?? 'https://ia800905.us.archive.org/19/items/FREE_background_music_dhalius/backsound.mp3'

  const audioRef = useRef(null);
  const [Duration, setDuration] = useState('00:00')
  const [CurrentTime, setCurrentTime] = useState('00:00')
  const [processBar, setProcessBar] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [restartCnt, setrestartCnt] = useState(0)




  useEffect(() => {

    audioRef.current = new Audio(audioSource);
    audioRef.current.addEventListener("ended", () => {
      setIsPlaying(false);
      if (ACfiledContent?.audioLoop) {
        setDuration('00:00')
        setCurrentTime('00:00')
        setProcessBar(0);
        setrestartCnt(obj => obj + 1)
      }
    });
    // audio.src = 
    audioRef.current.addEventListener("loadeddata", () => {
      let audioDurationmin = Math.floor(audioRef.current.duration / 60)
      let audioDurationSec = Math.floor(audioRef.current.duration % 60)
      setDuration((audioDurationmin < 10 ? `0${audioDurationmin}` : audioDurationmin) + ":" + (audioDurationSec < 10 ? `0${audioDurationSec}` : audioDurationSec))
      audioRef.current.volume = .75;
      if (ACfiledContent.audioAutoPlay) {
        setTimeout(() => {
          audioRef.current.currentTime = 0;
          setIsPlaying(true)
          audioRef.current.play().catch((error) => {

            setIsPlaying(false)
          })
        }, 200)
      }
    }, false)

    return () => {
      setIsPlaying(false);
      setDuration('00:00')
      setCurrentTime('00:00')
      setProcessBar(0);
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    };
  }, [audioSource, restartCnt]);

  useEffect(() => {
    function updateTime() {
      let audioCurrentTimemin = Math.floor(audioRef?.current?.currentTime / 60)
      let audioCurrentTimeSec = Math.floor(audioRef?.current?.currentTime % 60)
      let ProcessBarset = Math.floor((audioRef?.current?.currentTime / audioRef.current.duration) * 100)
      setProcessBar(ProcessBarset)
      setCurrentTime((audioCurrentTimemin < 10 ? `0${audioCurrentTimemin}` : audioCurrentTimemin) + ":" + (audioCurrentTimeSec < 10 ? `0${audioCurrentTimeSec}` : audioCurrentTimeSec))
    }
    const intervalId = setInterval(() => { updateTime(); }, 1000);
    updateTime();

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };


  const toggleMute = () => {
    audioRef.current.muted = !audioRef.current.muted;
    setIsMuted(audioRef.current.muted);
  };




  return (
    <div id={"gameFrameBorder" + fieldID} className={`relative flex flex-col py-6 group px-4 `} onClick={() => { DisPatch(Actions.setActiveFieldside({ filedId: fieldID, index: position, })) }}>
      {!priview && <EditorTooltip position={position} />}

      <div className={`flex space-x-2 h-fit max-w-full mx-auto p-4 rounded-2xl w-full border-1 bg-white`} >

        <button className="py-4 px-6 rounded-lg border-1 cursor-pointer" onClick={togglePlay} >
          {!isPlaying ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.99609 4.77466V19.2245C5.99609 20.3264 7.20955 20.9958 8.14405 20.3961L19.4975 13.1712C20.3623 12.6272 20.3623 11.3719 19.4975 10.814L8.14405 3.60305C7.20955 3.0033 5.99609 3.67279 5.99609 4.77466Z" fill="#2563EB" />
          </svg> :
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.06295 20.62C8.41753 20.62 9.52581 19.5117 9.52581 18.1571V5.84286C9.52581 4.48829 8.41753 3.38 7.06295 3.38C5.70838 3.38 4.6001 4.48829 4.6001 5.84286V18.1571C4.6001 19.5117 5.70838 20.62 7.06295 20.62ZM14.4515 5.84286V18.1571C14.4515 19.5117 15.5598 20.62 16.9144 20.62C18.269 20.62 19.3772 19.5117 19.3772 18.1571V5.84286C19.3772 4.48829 18.269 3.38 16.9144 3.38C15.5598 3.38 14.4515 4.48829 14.4515 5.84286Z" fill="#2563EB" />
            </svg>}

        </button>
        <div className="space-x-2 flex w-full">
          <p className="my-auto min-w-[40px] text-sm">{CurrentTime}</p>
          <div className="flex  w-full my-auto">
            {/* <input type="range" min={0} max={100} className="w-full transition-all duration-900" value={processBar} /> */}
            <div className={`w-full relative h-1 bg-[#EDEFF1] rounded-full transition-all duration-900 grid justify-items-start`}  >
              <div className={`h-1 w-auto transition-all delay-200 duration-500 bg-primary rounded-full `} style={{ width: `${processBar}%` }} ></div>
              <div className={`h-3 w-3 absolute border-2 delay-200 border-primary transition-all duration-500 bg-white rounded-full -top-1`} style={{ left: `${processBar - 1}%` }} ></div>
            </div>
          </div>
          <span className="flex my-auto cursor-pointer" onClick={toggleMute}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.25 3.38291C11.25 2.26957 9.90334 1.71207 9.11667 2.49957L5.36667 6.24957H3.75667C2.80584 6.24957 1.82501 6.80291 1.54001 7.83707C1.34665 8.54161 1.24911 9.26899 1.25001 9.99957C1.25001 10.7479 1.35084 11.4729 1.54167 12.1621C1.82584 13.1954 2.80667 13.7496 3.75751 13.7496H5.36584L9.11584 17.4996C9.90334 18.2871 11.25 17.7296 11.25 16.6162V3.38291ZM15.4867 4.25457C15.6039 4.13753 15.7627 4.07179 15.9283 4.07179C16.094 4.07179 16.2528 4.13753 16.37 4.25457C19.5433 7.42707 19.5433 12.5712 16.37 15.7446C16.3128 15.806 16.2438 15.8552 16.1671 15.8894C16.0905 15.9236 16.0077 15.9419 15.9238 15.9434C15.8399 15.9449 15.7565 15.9294 15.6787 15.898C15.6009 15.8666 15.5302 15.8198 15.4708 15.7604C15.4115 15.7011 15.3647 15.6304 15.3332 15.5526C15.3018 15.4747 15.2864 15.3914 15.2878 15.3075C15.2893 15.2236 15.3077 15.1408 15.3419 15.0641C15.376 14.9875 15.4253 14.9185 15.4867 14.8612C16.1252 14.2228 16.6317 13.4649 16.9772 12.6307C17.3228 11.7966 17.5006 10.9025 17.5006 9.99957C17.5006 9.09666 17.3228 8.20259 16.9772 7.36842C16.6317 6.53425 16.1252 5.77632 15.4867 5.13791C15.3696 5.02072 15.3039 4.86187 15.3039 4.69624C15.3039 4.53061 15.3696 4.37176 15.4867 4.25457Z" fill={!isMuted ? "#2563EB" : "black"} />
              <path d="M13.2757 6.46445C13.3337 6.40637 13.4027 6.3603 13.4785 6.32886C13.5544 6.29743 13.6357 6.28125 13.7178 6.28125C13.7999 6.28125 13.8812 6.29743 13.9571 6.32886C14.0329 6.3603 14.1018 6.40637 14.1599 6.46445C14.6242 6.92875 14.9926 7.47999 15.2439 8.08667C15.4953 8.69335 15.6246 9.3436 15.6246 10.0003C15.6246 10.657 15.4953 11.3072 15.2439 11.9139C14.9926 12.5206 14.6242 13.0718 14.1599 13.5361C14.0419 13.6499 13.884 13.7128 13.7202 13.7113C13.5563 13.7098 13.3996 13.644 13.2837 13.5281C13.1679 13.4122 13.1022 13.2554 13.1009 13.0915C13.0996 12.9276 13.1626 12.7698 13.2765 12.6519C13.6248 12.3037 13.901 11.8903 14.0894 11.4354C14.2779 10.9804 14.3749 10.4927 14.3749 10.0003C14.3749 9.50782 14.2779 9.02018 14.0894 8.56521C13.901 8.11024 13.6248 7.69684 13.2765 7.34862C13.1595 7.23143 13.0938 7.07258 13.0938 6.90695C13.0938 6.74132 13.1595 6.58247 13.2765 6.46528L13.2757 6.46445Z" fill={!isMuted ? "#2563EB" : "black"} />
            </svg>

          </span>
          <p className="my-auto min-w-[40px] text-sm">{Duration}</p>
        </div>
      </div>
    </div>

  )
}