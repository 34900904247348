import React, { useState } from "react";
import Header from "../components/header/header";
import { UserinfoIntro } from "../components/renderComponents/intro/userINFO";
import { AddSiteIntro } from "../components/renderComponents/intro/addsite";
import { ScriptcodeInit } from "../components/renderComponents/intro/scriptcodeinit";
import { IndustryChooseIntro } from "../components/renderComponents/intro/industryIntro";
import { CategoryChooseIntro } from "../components/renderComponents/intro/categoryintro";

export const NewIntroScreem = () => {

    const [stage,setStage] = useState('0%')

    const [width,setwidth] = useState('10%')

    const [indactive, setIndActive] = useState(0);

    const forWardAction = (index) => {

        if(index == 0) {

            setwidth('0%')

            setStage('10%')

            return true
        }

        let sum = `-${(index*100)}%`

        let sumwidth = `${(index/5)*100}%`

        setwidth(sumwidth)

        if(index !== 5) setStage(sum)

    }

    return (

        <section className="absolute flex flex-col w-full h-full top-0 left-0 right-0 bottom-0 overflow-hidden bg-white" style={{ zIndex: "200" }} >

            <div className="flex flex-col backdrop:" >

                <Header />

                <div className="w-full flex h-2 " >

                    <hr className=" bg-primary h-[3px] transform duration-1000  relative top-[-3px] m-0 rounded-r-lg" style={{width:width}} />

                </div>

            </div>

            <div className={`absolute transform duration-700 top-[78px] bottom-0 flex w-[500%] overflow-hidden `} style={{left:stage}} >

                <UserinfoIntro forWardAction={forWardAction} />

                <AddSiteIntro forWardAction={forWardAction} />

                <ScriptcodeInit forWardAction={forWardAction} />

                <IndustryChooseIntro forWardAction={forWardAction} indactive={indactive} setIndActive={setIndActive} />

                <CategoryChooseIntro forWardAction={forWardAction} indactive={indactive} />
                
            </div>

        </section>
    )
}