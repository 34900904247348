import React from 'react'
import { BillingLayout } from './BillingLayout'

export const CurrentPlan = ({ planInfo, setActive }) => {


    const PlanPrice = {
        free:0.00,
        starter:9.00,
        premium:24.00,
        professional:49.00,
        advanced:99.00,

    }

  return (
    <BillingLayout className="relative w-1/2 h-fit px-6 py-5 bg-primary space-y-3 border-primary border-1">

    <div className="absolute top-7 right-7">
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle opacity="0.2" cx="25" cy="25" r="25" fill="white"></circle><g clip-path="url(#clip0_3088_17938)"><path d="M36.9374 22.2069C36.7803 21.721 36.3493 21.3758 35.8393 21.3299L28.9123 20.7009L26.1731 14.2896C25.9712 13.8198 25.5112 13.5156 25.0001 13.5156C24.4891 13.5156 24.0291 13.8198 23.8271 14.2907L21.088 20.7009L14.1598 21.3299C13.6508 21.3769 13.2208 21.721 13.0628 22.2069C12.9048 22.6929 13.0507 23.226 13.4358 23.562L18.6718 28.154L17.1278 34.9553C17.0149 35.4553 17.209 35.9723 17.6239 36.2722C17.8469 36.4333 18.1079 36.5154 18.371 36.5154C18.5979 36.5154 18.8229 36.4542 19.0249 36.3333L25.0001 32.7621L30.9732 36.3333C31.4103 36.5963 31.9612 36.5723 32.3752 36.2722C32.7904 35.9713 32.9843 35.4542 32.8713 34.9553L31.3273 28.154L36.5633 23.5629C36.9484 23.226 37.0955 22.6938 36.9374 22.2069Z" fill="white"></path></g><defs><clipPath id="clip0_3088_17938"><rect width="24" height="24" fill="white" transform="translate(13 13)"></rect></clipPath></defs></svg>
    </div>
    
    <div className="text-white text-sm font-medium">
        Your current subscription plan.
    </div>
    
    
    <h1 className="text-white text-4xl font-bold">
        <span className="text-sm"> $ </span> { PlanPrice[planInfo?.plan ?? 'free'] }
    </h1>
    
    <div className="text-white font-medium capitalize tracking-wide">
   {planInfo?.plan ?? 'free'}
    </div>
    
    <div
        className={`
            bg-white text-primary text-sm font-normal px-4 py-2 rounded-lg inline-block 
            hover:bg-gray-200 cursor-pointer
        `}
        onClick={() => setActive('Plans') }
    > 
        Change Plan
    </div>
    
          </BillingLayout>
  )
}
