import { useSelector } from "react-redux"

import Intercom from 'react-intercom'
// import { InterComAppId } from "../../../config"

export const InterCom = () => { 
    const InterComAppId = process.env?.INTERCOM_APP_ID ?? "u1bjeqk3"

    const {user,userPlan} = useSelector((state) => ({userPlan:state.userPlan,user:state.userDetail})) 

    const userData = { 
        user_id: user.user_id,
        email: user.email,
        product:"GozenEngage",
        name: user?.firstName ? `${user?.firstName} ${user?.lastName}`:user.email,
        plan: userPlan.plan,
        type: userPlan.type,
        gateway: userPlan.gateway,
    }
    // console.log("user",user)
    if (!user.email) return null

    return <Intercom appID="u1bjeqk3" {...userData} />
}