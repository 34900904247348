import React from 'react'
import PropTypes from "prop-types";

const PageTitle = function ({ text, textColor = "text-primary-bold", margin = "", lineHeight = "", textSize = "text-22px", fontStyle = "font-bold", ...props }) {
    return (
        <h1 className={`${margin} ${fontStyle} ${textSize} ${textColor} ${lineHeight} capitalize`}>
            {text}
            {props.children}
        </h1>
    )
}



PageTitle.prototype = {
    text: PropTypes.string.isRequired,
    textColor: PropTypes.string,
    margin: PropTypes.string,
    textSize: PropTypes.string
}

export { PageTitle }