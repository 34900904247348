import { useState, useEffect } from "react";
// import PrimaryButton from "../Button1/primarybutton";
import { Description, Title } from "../Texts";
import { GetAllIntergations } from "../../actions/integrations";

import { IntergationPop } from "../popup/IntergationPop";
import { PopupCreate } from "../popup/popupcreate";
import Axios from "../../axios";
import { LoadingSpinner } from "../Loading";
import LoadContentpop from "../load";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../store";
import PrimaryButton from "../Button1/primarybutton";
import { useNavigate } from "react-router-dom";

import { IntegrationsCard } from "../integration/IntegrationsCard";
import { SelectEdIntegration } from "../integration/selectedIntegration";


export const IntegateBlock = ({ campaignId = "" }) => {

    const [data, setData] = useState([])
    const [show, setShow] = useState(false)
    const [select, setSelect] = useState()
    const [keys, setKeys] = useState({apiKey:""})
    const [validate, setValidate] = useState(false)
    const [create, setCreate] = useState()
    const [list, setList] = useState([])
    const [selectList, setSelectList] = useState(0)

    const [intergationStage, setIntergationStage] = useState(0)
    const [integrationType, setIntegrationType] = useState('')
    const [loading,setloading] = useState(false);

    const Dispatch = useDispatch()

    useEffect(() => {
        if (intergationStage === 2 && (integrationType === 'app')) IntergationFroweord(intergationStage)
        if(intergationStage === 2){
            setValidate(true)
            // setCreate(false)
        }

        return () => undefined
    }, [intergationStage])

    useEffect(() => {
        Dispatch( Actions.clearCacheCampaign_integration() )
        FetchDAta(campaignId)
    }, [campaignId])

    const close = () => {
        setSelect("")
        setShow(false)
        setData([])
        setCreate(false)
        setList([])
        setKeys({apiKey:''})
        setValidate(false)
        setIntergationStage(0)
        FetchDAta(campaignId)
    }

    const FetchDAta = async (campId) => {
        const Resp = await GetAllIntergations(campId)
        setData(Resp.data)
    }

    const IntergationFroweord = async (index) => {
  

        const data = {
            "credentials": keys,
            "mailer": select['mailer'],
        }



        if (!validate && index === 1 && list?.length === 0) {

            setIntergationStage(1)

            await Axios.post('/integration/validate', data).then(res => {


                if (res["data"]["success"]) {

                    if (!res['data']['data'].length && res?.data?.data?.type == "app") {
                        setIntegrationType(res?.data?.data?.type)
                        setValidate(true)
                        setCreate(false)

                        setIntergationStage(2)
                        return;

                    }
                    if (res["data"]["data"].length) {

                        setList(res['data']['data'] ?? [])
                        setValidate(true)
                        setCreate(false)
                        setIntergationStage(2)
                        return;
                    }

                }
                // if (!res['data']["success"]) console.log(res["data"])
            }).catch((err) => {
                setIntergationStage(0)
                toast.error(err.response.data.data.errors[0].msg ?? "Something  when to wrong, please Contact Support")
            }
            )
        }

        let dataCreate = {
            "integration": {
                "credentials": keys,
                "mailer": select["mailer"]
            }
        }





        //if is not app show list - stage-2


        if (index === 2 && validate && !create) {
            setCreate(true)
            let selectedList = { ...list[selectList], "isAdded": true }
            if (list && (integrationType !== 'app')) dataCreate.integration['list'] = selectedList

            // if (list && selectList) {


            await Axios.post(`/integration/create?campaign_id=${window.location.href.split('/').slice(-1)[0]}`, dataCreate).then(res => {
                if (res['data']['success']) {
                    toast.success('Integration list added on your campaign')

                    setIntergationStage(3)
                }
                else {
                    setIntergationStage(3)
                }

            })
            // }

            if (!list) {
                await Axios.post(`/integration/create?campaign_id=${window.location.href.split('/').slice(-1)[0]}`, dataCreate).then(res => {
                    if (res['data']['success']) {

                        toast.success('Integration list added on your campaign')
                        setIntergationStage(3)
                    }
                    else {
                        toast.success('Integration list added on your campaign - f')
                        setIntergationStage(3)
                    }

                })
            }

            setCreate(false)
        }

    }

    const updateIntergationStage = () => {
        setIntergationStage(state => state === 3 ? 3 : state += 1)
    }


    return ( 
        <section className={`flex  flex-col w-11/12 h-full mx-auto`}>

            {loading && <LoadingSpinner style={{background:"rgba(250,250,250,0.4)"}} display="absolute left-0 right-0 bottom-0 flex"  />}
            <div className="w-[90%]  mx-auto mt-4">
                <Title text="Integrations" textSize="text-xl" fontStyle="font-bold" margin="mb-3" />
                <Description text="Connect GoZen Engage games to your favorite apps" margin="mb-3" textSize="text-md" />
            </div>

            {data.length !== 0 ? <IntegrationsCard data={data} reRenderlist ={FetchDAta} setloading={setloading} setIntergationStage={setIntergationStage} setKeys={setKeys} setList={setList} show={setShow} select={setSelect} /> : <LoadingSpinner />}
            <div className="">

                { (select?.newVersion && show) && <SelectEdIntegration selectobj={select} setShow={setShow} closed ={ close } /> }
                {
                    ( !select?.newVersion && show ) ?
                        // <PopupCreate title="api key" Placeholder="pls enter your kay" backwardAction={setShow} select={setSelect}/> : <></>
                        
                        <IntergationPop
                            title="api key"
                            forwardAction={IntergationFroweord}
                            Placeholder="pls enter your kay"
                            backwardAction={close}

                            select={select}
                            changeDefaultVaule={setKeys}
                            keys={keys}
                            validate={validate}
                            create={create}
                            setIntegrationType={setIntegrationType}
                            setIntergationStage={setIntergationStage}
                            intergationStage={intergationStage}
                            list={list}
                            setSelectList={setSelectList}
                            selectList={selectList
                            } /> 
                            : <></>
                }

            </div>

        </section>
    )
}