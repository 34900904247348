import React, { useState } from "react";

import { industrycategoryData } from "../content/category";
import { templatelist, updateUser } from "../actions/user";

const Choose = ({ stateChangefunc, statecontent = {} }) => {

    const updateValue = statecontent;

    const CategoryData = industrycategoryData();
    const [active, setActive] = useState(0);

    const Templatelist = async (indusrty, category) => {

        const updateUserDetail = await updateUser({ setup: "true" });
        const data = await templatelist(indusrty, category);

        if (data.status == true) window.location.href = '/template';

    }

    const isActive = (index) => {
        setActive(index)
        updateValue.Category = CategoryData[index].title;
        Templatelist(updateValue.industry, updateValue.Category)
        stateChangefunc(updateValue);

    }


    return (
        <>

            {
                CategoryData.map((industry, index) => {

                    return (
                        <>
                            <div key={index} className={` w-full flex shadow-card-rate flex-col space-y-6 justify-center bg-white  rounded-xl   mx-auto ${(active == index) ? " border-2 border-primary" : "border-2 border-white"}  px-2 py-6 cursor-pointer`} onClick={() => isActive(index)} >
                                <div className="mx-auto  ">
                                    <img src={`${industry.image}`} alt={industry?.title + " icon"}/>
                                </div>
                                <p className="mx-auto font-medium text-sm capitalize text-center ">{industry.title}</p>
                            </div>
                        </>

                    )
                })
            }

        </>
    )
}

export default Choose;