import React from "react";
import GzLogo from "../logo/GzLogo";
import PrimaryButton from "../Button1/primarybutton";
import { useDispatch, useSelector } from "react-redux";
import { ProfileIcon } from "./profile";



const Header = ({text,width})=>{
const { userDetail, campaign, fileds, order, SharedWS } = useSelector((state) => ({ SharedWS: state.SharedWS, userDetail: state.userDetail, campaign: state.Campaign_info, order: state.fieldsOrder, fileds: state.fields }));

    return(
        <>
            <section className="w-full h-[77px] bg-white border-b-3">
                <div className="flex justify-between h-full  ml-8 mr-10">
                    <GzLogo classNamecontainer="w-52 my-auto " classNameimg="" />
                    <ProfileIcon firstname={userDetail?.first_name} email={userDetail?.email} lastname={userDetail?.last_name} userProfilePic={userDetail?.profile} />

                </div>
            </section>

        </>
    )
}

export default Header;