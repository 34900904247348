
export const categoryData = () => {
    // return [
    //     {
    //         image:"/asset/images/template/gallery/1.png",
    //         title:"memory"
    //     },
    //     {
    //         image:"/asset/images/templateCollect/Match_the_Animal_Names.png",
    //         title:"math"
    //     },
    //     {
    //         image:"/asset/images/template/gallery/3.png",
    //         title:"puzzels"
    //     },
    //     {
    //         image:"/asset/images/template/gallery/4.png",
    //         title:"quizs"
    //     },
    //     {
    //         image:"/asset/images/template/gallery/5.png",
    //         title:"find pair"
    //     },
    //     {
    //         image:"/asset/images/template/gallery/6.png",
    //         title:"difference"
    //     },
    //     {
    //         image:"/asset/images/template/gallery/7.png",
    //         title:"spin wheel"
    //     },
    //     {
    //         image:"/asset/images/templateCollect/Match_the_Animal_Names.png",
    //         title:"spin wheel"
    //     },
    //     {
    //         image:"/asset/images/templateCollect/Match_the_Animal_Names.png",
    //         title:"spin wheel"
    //     },
    //     {
    //         image:"/asset/images/templateCollect/Match_the_Animal_Names.png",
    //         title:"spin wheel"
    //     },
    //     {
    //         image:"/asset/images/templateCollect/Arts_and_culture_Quiz.png",
    //         title:"spin wheel"
    //     },
        

    // ]
    return {
        "games":{
            "lable": "games",
            "title": "Games",
            "src": "/asset/images/template/gallery/1.png"
        },
        quizzes:{
            "lable": "quizzes",
            "title": "Quizzes",
            "src": "/asset/images/template/gallery/2.png"
        },
        spinWheel:{
            "lable": "spinWheel",
            "title": "Spin Wheel",
            "src": "/asset/images/template/gallery/3.png"
        },
        ecommerce:{
            "lable": "ecommerce",
            "title": "Ecommerce",
            "src": "/asset/images/template/gallery/4.png"
        },
        poll:{
            "lable": "poll",
            "title": "Poll",
            "src": "/asset/images/template/gallery/5.png"
        },
        calculater:{
            "lable": "calculater",
            "title": "Calculater",
            "src": "/asset/images/template/gallery/6.png"
        },
        surveys:{
            "lable": "surveys",
            "title": "Surveys",
            "src": "/asset/images/template/gallery/7.png"
        },
        giveaway:{
            "lable": "giveaway",
            "title": "Giveaway",
            "src": "/asset/images/template/gallery/8.png"
        }
    }
    
}

export const MediaTest = () => {
        return [

            {
                name: 'bgm-3.mp3',
                id: 7493,
                src: 'https://asserts.engage.gozen.io/v1/public/bgm-3.mp3',
                mimeType: 'audio/mpeg'
            },            {
                name: 'bgm-4.mp3',
                id: 7492,
                src: 'https://asserts.engage.gozen.io/v1/public/bgm-4.mp3',
                mimeType: 'audio/mpeg'
            },            {
                name: 'bgm-5.mp3',
                id: 7492,
                src: 'https://asserts.engage.gozen.io/v1/public/bgm-5.mp3',
                mimeType: 'audio/mpeg'
            },            {
                name: 'bgm-6.mp3',
                id: 7434,
                src: 'https://asserts.engage.gozen.io/v1/public/bgm-6.mp3',
                mimeType: 'audio/mpeg'
            },            {
                name: 'bgm-7.mp3',
                id: 7489,
                src: 'https://asserts.engage.gozen.io/v1/public/bgm-7.mp3',
                mimeType: 'audio/mpeg'
            },
            

            {
                name: 'cloud-storage-data-information-concept.jpg',
                id: 7498,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/5622349d57f27c6326d856232527301a.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'cloud-storage-data-information-concept.jpg',
                id: 7498,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/1792ee4bfc56ee6e4d0b4d720fd712ff.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'young-businesswoman-sitting-yellow-sofa-showing-graph.jpg',
                id: 7499,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/1fa9a61b7375b6584da2c262348afb0c.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'network-connection-graphic-overlay-background-computer-screen.jpg',
                id: 7500,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/847011637ddd30e35cf745fc4a6b2229.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'low-angle-shot-high-rise-building-clear-blue-sky-with-white-clouds.jpg',
                id: 7501,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/37df03c09b5a9f6ad9b9cd89896b40ee.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'low-angle-tall-residential-building-city-with-copy-space.jpg',
                id: 7502,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/847d569d488607c5c2dde03bb6ecdfda.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'crane-building-construction-site.jpg',
                id: 7503,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/b52c9b6b514aa8c66983011c1b64504d.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'beautiful-wooden-house-with-dark-roof.jpg',
                id: 7504,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/afca5d4d24183a75f5afabf029153102.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'apartment-building-structures-city.jpg',
                id: 7505,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/bf1059f763c8c9c2846df4cdd24f85d6.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'excavator-digging-ground-day-light.jpg',
                id: 7506,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/5f9647bdc1dd1ea570840e15ff301ec8.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'exterior-home.jpg',
                id: 7507,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/416348f2a317122b4d018c179b681207.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'house-isolated-field.jpg',
                id: 7508,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/db6c90a4886bf88e5d8b2a6e7a84b9c4.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'white-cubes-assorment-purple-background-high-angle.jpg',
                id: 7509,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/f618d3e8c266880e4cca77f6491b935d.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'grayscale-piled-racks.jpg',
                id: 7510,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/2a78015ad6e17d66b32611c2ea0e9eeb.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'c1.png',
                id: 7511,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/9449bd812fc28df4e35c7a0448f0817c.png',
                mimeType: 'image/png'
            },
            {
                name: 'c2.png',
                id: 7512,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/14bace399cabac09be7315245db676a6.png',
                mimeType: 'image/png'
            },
            {
                name: 'apartment-building-city-with-clear-sky.jpg',
                id: 7513,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/799b8d6724f6869e518dcf2d92c020b0.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'beautiful-woman-with-golden-skin-cosmetic-touch-face-isolated-white-wall-beauty-skincare-treatment.jpg',
                id: 7514,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/a25cfaa42e98f27717278982e871ce65.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'medium-shot-relaxed-woman-home.jpg',
                id: 7515,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/645f03545539600702ae770c9ae22aee.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'smiling-young-woman-straightening-her-hair-with-white.jpg',
                id: 7516,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/7dc21b0843489e81fd7147e27ac20a79.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'high-angle-contact-lenses-with-case-tweezers.jpg',
                id: 7517,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/f8cd6358d8fe89230c7123c2bf92eb33.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'woman-getting-hair-loss-treatment-clinic.jpg',
                id: 7518,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/2b7de3cb018b69d1e6b04ebf561f918a.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'c3.png',
                id: 7519,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/a8941386b0b3f7c9ff922cee00abc53b.png',
                mimeType: 'image/png'
            },
            {
                name: 'yellow-grey-nail-design-manicured-female-hand-white-fabric-background.jpg',
                id: 7520,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/839c8c989460806f6d0b23c1fd0232c1.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'hairdresser-colored-hair-her-client-hair-salon.jpg',
                id: 7521,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/8ff1b5f4726275feb22926a04bc8e7a4.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'c4.png',
                id: 7522,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/5e04307ffb323cb71c47d3157b1708be.png',
                mimeType: 'image/png'
            },
            {
                name: 'portrait-young-woman-having-problem-skin-pimple-her-cheek-wearing-towel-her-head-having-sad-expression-pointing.jpg',
                id: 7523,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/242e72a0f44e468a8ebda8823f75085a.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'c5.png',
                id: 7524,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/7a1f2592eef9b3c2f7735db58432b906.png',
                mimeType: 'image/png'
            },
            {
                name: 'top-view-eyelashes-eye-patches-arrangement.jpg',
                id: 7525,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/fb518b3eb34dc3fc62c6ee7781ad02d7.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'view-eyeshadow-palette-with-brush-applicator.jpg',
                id: 7526,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/5abc06132e4eb6607b487b19a2bbc3f3.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'arrangement-professional-photographer-equipment.jpg',
                id: 7527,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/5403040dd290e3b2e79c1be119dbd9ca.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'c6.png',
                id: 7528,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/4279e95ac85f8c0e579bc46caf4a5c3f.png',
                mimeType: 'image/png'
            },
            {
                name: 'lens-camera.jpg',
                id: 7529,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/12e4fd1a6ce73489f407a03b7f7bcb62.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'lens-camera.jpg',
                id: 7530,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/12e4fd1a6ce73489f407a03b7f7bcb62.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'attractive-brunette-aims-her-camera-composing-photograph-studio-isolated-gray-wall.jpg',
                id: 7531,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/7cd824edb82e4905e54d4cfacbe4dcc4.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'c7.png',
                id: 7532,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/3f8a7ebed86d5718bc0422f97aa95e30.png',
                mimeType: 'image/png'
            },
            {
                name: 'spyglass-smile-lady-look-far.jpg',
                id: 7533,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/d399748df527eab8109c0da53bd293e7.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'photo-camera-balancing-with-yellow-background.jpg',
                id: 7534,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/5aa8ef0e7503aa42d1648489bf542975.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'professional-digital-camera-accessories-arranged-circle-yellow-background.jpg',
                id: 7535,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/630d6f96e18265d237899fd90a1a2f2c.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'photograph-asking-customer-smile-as-he-is-taking-photos.jpg',
                id: 7536,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/501d928ca9271443629c22e79355cffb.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'photo-camera-still-life.jpg',
                id: 7537,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/1ce40ea423085c2a0092dc592e828ab6.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'high-angle-photo-camera-still-life.jpg',
                id: 7538,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/05d2cb5a2349458363e192e11ce13bef.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'young-woman-black-t-shirt-with-camera.jpg',
                id: 7539,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/aa1277f5a12e43ac89eeb4a13e500513.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'c8.png',
                id: 7540,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/031a8758f81788a3291ae5b7e517b95c.png',
                mimeType: 'image/png'
            },
            {
                name: 'c8.png',
                id: 7541,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/031a8758f81788a3291ae5b7e517b95c.png',
                mimeType: 'image/png'
            },
            {
                name: 'c9.png',
                id: 7542,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/d688166bf9269ad5065a3e11f9ca3210.png',
                mimeType: 'image/png'
            },
            {
                name: 'pair-separated-objectives-camera.jpg',
                id: 7543,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/44e2ec9c488326cbdf1f101d066bce19.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'c10.png',
                id: 7544,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/569165f8fe23226ccd9c555fb519e459.png',
                mimeType: 'image/png'
            },
            {
                name: 'c11.png',
                id: 7545,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/3562e41b2d9eaacaaa210d1f6e5541e5.png',
                mimeType: 'image/png'
            },
            {
                name: 'red-delivery-car-deliver-express-shipping-fast-delivery-background-3d-rendering-illustration.jpg',
                id: 7546,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/36d8aaf5319ff1feb0979a424134a813.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'close-up-old-motorcycle-speedometer.jpg',
                id: 7547,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/12d13234417df22c7f76a93897c2033e.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'company-packer-employee-desk-with-laptop.jpg',
                id: 7548,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/7ca594c8d4c5815ca38444496a2c8703.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'female-courier-yellow-uniform-holding-cardboard-box-alarm-clock-meaning-express-delivery-time.jpg',
                id: 7549,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/710b34e26d1f519372c22988eb4e498c.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'receiving-packages.jpg',
                id: 7550,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/b4745412f72f14a0c56b364ce28f6a31.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'person-spending-time-gas-station.jpg',
                id: 7551,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/06983ae03cb666cee74c06f6e7d225c7.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'courier-with-clipboard-stacks-parcels.jpg',
                id: 7552,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/7a622b080ecf6d9a6caa15e3f1ef0863.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'people-working-together-warehouse.jpg',
                id: 7553,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/d47e774020f6998fabd5c2125f9afff5.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'courier-signing-parcels.jpg',
                id: 7554,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/4f5bc1c4e4b4c04f216700731b85d81a.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'stylish-woman-car-salon.jpg',
                id: 7555,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/49f94b6b2db21626e5ac47447105d422.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'heart rate .jpg',
                id: 7556,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/c80da4e6c062132381e7194dbdd80776.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'Absolute Neutrophil Count .jpg',
                id: 7557,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/2787f29a6924eb9e58b87ae1f710a257.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'Corrected QT Interval .jpg',
                id: 7558,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/0cee63679b5f1395f9508c2972670d57.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'Mean Arterial Pressure.jpg',
                id: 7559,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/d534c6091aff1f2b85bb09a9da9074c3.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'Anion Gap.jpg',
                id: 7560,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/ff80323bf2b74a1235bdba02fd161630.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'Child-Pugh Score.jpg',
                id: 7561,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/2b92aeab874fe453a10399edaefe432c.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'oxygen concentration.jpg',
                id: 7562,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/53e56417928e4922d90999448dd7b5ed.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'Anion Gap.jpg',
                id: 7563,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/ff80323bf2b74a1235bdba02fd161630.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'Estimated Glomerular Filtration Rate.jpg',
                id: 7564,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/b369522d372ca192f039e3542ca5029b.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'Body Mass Index .jpg',
                id: 7565,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/f2e97ca89178155408ec53595737b53b.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'Benefit-Cost Ratio .jpg',
                id: 7566,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/14c15e42db593b95f0bff49c6ca8dec7.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'Revenue Per Employee.jpg',
                id: 7567,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/f60823f19e880dbe8c7c2f208b8d2c49.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'Cost Per Hire.jpg',
                id: 7568,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/d046ee0ede68a74d4502277e21061fcf.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'Training Investment Per Employee.jpg',
                id: 7569,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/d370c4e1f5ace8ad5bac0b922f45d95b.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'Employee Productivity.jpg',
                id: 7570,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/09c32c68c1f4a31153b3af3bced171c9.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'human capital roi.jpg',
                id: 7571,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/1c866e6638aea3df15a58395a90ae6b2.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'employee absentism rate.jpg',
                id: 7572,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/313e9d5a509bbf973125948fca763036.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'time to fill.jpg',
                id: 7573,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/50d9ca92c227acd5da670d494d61e0d5.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'peron-doing-presentation-her-colleagues.jpg',
                id: 7574,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/b63b2b52c03ed684adb72a22654b9dd3.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'brunette-woman-sitting-desk-surrounded-with-gadgets-papers.jpg',
                id: 7575,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/834b30990fb47072abc55707b1767964.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'brunette-woman-sitting-desk-surrounded-with-gadgets-papers.jpg',
                id: 7576,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/834b30990fb47072abc55707b1767964.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'Net Present Value.jpg',
                id: 7577,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/72e961d250c632815c93d35cae1edf55.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'papers-money-table.jpg',
                id: 7578,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/66084f3a5554e4fb7daf14ec02286125.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'man-casual-clothes-hold-fan-dollar-bills.jpg',
                id: 7579,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/a6e6680d08834f0f5869fea8e44b045b.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'high-angle-money-stacks-near-gifts.jpg',
                id: 7580,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/7d862788e3a5d0481b112fbd27a5ac60.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'business-woman-working-office-desktop.jpg',
                id: 7581,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/ceb0a49986f4a0625c3ffb8f341be0c2.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'piles-coin-money-with-drawn-arrow.jpg',
                id: 7582,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/f2fe505d3ded59475c2b9ce3902d636b.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'coins-calculator-pen.jpg',
                id: 7583,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/417137a431428dbf0cd85cf10b85f2df.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'coins-banknotes-with-growth-arrow.jpg',
                id: 7584,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/e54536fb92115b335061479f2a8d5d3d.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'coins-scattered-from-jar-with-paper-money-table.jpg',
                id: 7585,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/0dd81f652c5dec71865c7f46949d541a.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'arrangement-with-banknotes-wooden-cubes.jpg',
                id: 7586,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/320b489dc2a6a67e4282c77ea8965aa5.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'teenager-boy-plays-with-vr-glasses-smartphone.jpg',
                id: 7587,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/393fa525f16461bf2f666a8105e39464.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'beautiful-blonde-woman-with-joystick-headphones-red-wall.jpg',
                id: 7588,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/debf1d60d03c88efd32acb538e3ce1c8.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'beautiful-blonde-woman-with-joystick-headphones-red-wall.jpg',
                id: 7589,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/debf1d60d03c88efd32acb538e3ce1c8.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'portrait-serious-man-playing-video-game-with-joystick-home.jpg',
                id: 7590,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/4d0064e9b661d3308a9ef2a4b71fa929.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'woman-playing-game-using-vr-goggles-console-controller-trying-win-difficult-level-studio-gamer-girl-having-intense-experience-with-virtual-reality-looking-3d-surrounding-enviroment.jpg',
                id: 7591,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/c93b9da464e6eebe6e8978f0f2947e5a.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'front-view-male-gamer-with-gamepad-headphones-playing-video-game-blue-wall.jpg',
                id: 7592,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/6f925c02f57e8aee0d98c8cdec168257.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'young-man-playing-using-virtual-reality-headset-isolated-white-studio-wall.jpg',
                id: 7593,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/fb244e1364e5797325fe7582d7486843.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'front-view-male-gamer-with-gamepad-playing-video-game-blue-wall.jpg',
                id: 7594,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/877ddcf858b0c1b5e99e05953c5d9fb9.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'young-fit-woman-sport-wear-jacket-virtual-reality-glasses-playing-boxing-fight-games-pink.jpg',
                id: 7595,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/e0e12adbef7d62e449bd29a74a20b226.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'excited-casual-young-woman-playing-video-games-having-fun-red.jpg',
                id: 7596,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/0e63d83208b97999342d9716a830d641.jpg',
                mimeType: 'image/jpeg'
            },
            {
                name: 'portrait-young-man-playing-vr-with-battle-axe-dark-wall.jpg',
                id: 7597,
                src: 'https://asserts-engage.dev.optinly.gozen.io/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/9488997e44c29bc55df869bf35b645da.jpg',
                mimeType: 'image/jpeg'
            },


        {
            image:"/asset/images/template/gallery/1.png",
            title:"memory"
        },
        {
            image:"/asset/images/templateCollect/Match_the_Animal_Names.png",
            title:"math"
        },
        {
            image:"/asset/images/template/gallery/3.png",
            title:"puzzels"
        },
        {
            image:"/asset/images/template/gallery/4.png",
            title:"quizs"
        },
        {
            image:"/asset/images/template/gallery/5.png",
            title:"find pair"
        },
        {
            image:"/asset/images/template/gallery/6.png",
            title:"difference"
        },
        {
            image:"/asset/images/template/gallery/7.png",
            title:"spin wheel"
        },
        {
            image:"/asset/images/templateCollect/Match_the_Animal_Names.png",
            title:"spin wheel"
        },
        {
            image:"/asset/images/templateCollect/Match_the_Animal_Names.png",
            title:"spin wheel"
        },
        {
            image:"/asset/images/templateCollect/Match_the_Animal_Names.png",
            title:"spin wheel"
        },
        {
            image:"/asset/images/templateCollect/Arts_and_culture_Quiz.png",
            title:"spin wheel"
        },
        

    ]
}
//asset/images/templateCollect/Match_the_Animal_Names.png"

export const industrycategoryData = () => {
    return [
        {
            lable: "games",
            title: "Games",
            src: "asset/images/template/gallery/1.png",
        },
        {
            lable: "quizzes",
            title: "Quizzes",
            src: "asset/images/template/gallery/2.png",
        },
        {
            lable: "spinWheel",
            title: "Spin Wheel",
            src: "asset/images/template/gallery/3.png",
        },
        {
            lable: "ecommerce",
            title: "Ecommerce",
            src: "asset/images/template/gallery/4.png",
        },
        {
            lable: "poll",
            title: "Poll",
            src: "asset/images/template/gallery/5.png",
        },
        {
            lable: "calculater",
            title: "Calculater",
            src: "asset/images/template/gallery/6.png",
        },
        {
            lable: "surveys",
            title: "Surveys",
            src: "asset/images/template/gallery/7.png",
        },

    ]
}

