import React, { useEffect,  useRef,  useState } from "react"; 
import { isNotEmpty,isEmpty, fadeIn,fadeOut, hide, classElement, element } from "../../../utils/helpers";
import "./style.css" 
import Iframe from "react-iframe";

export const SharePreview = (data) => {  

    var {CampaignId,popupSize,RenderUrl,dataType,runPreviewCount, 
    btnText="Launch",decivestate="desktop", btnBgColor, btnTextColor,   
     toShow,settoShow, Side="right"} = data 

    const [cssClass,setCssClass] = useState("") 
    const [cssChildClass,setCssChildClass] = useState("") 
    const [loadFrameSize, setloadFrameSize] = useState(["100%","100%"])
    const [tabBtnStyle, settabBtnStyle]=useState({})
    const [isSideTabOpen, setisSideTabOpen] = useState(false)
    const [opacity, setopacity] = useState(1)
    const [transition, settransition] = useState("0s")
    const sideTabBtn = useRef()



useEffect(()=>{
    if(toShow){
    let tmpElm=element("engageLoadDiv")
    if(isNotEmpty(tmpElm)) tmpElm.style.display="flex"
    }
    
},[toShow,runPreviewCount,decivestate]) 

useEffect(()=>{
    SideTabToggle(true)
    if(dataType==="sideTab"){
        settransition("0s")
        setopacity(0)
        setTimeout(()=>{
            setopacity(1)
        settransition("1s")
        initBtnPos()

        },300)
    }
},[Side, dataType])

    useEffect(()=>{ 
    setCssClass(""); setCssChildClass("")  
   

    if (dataType === "popup") { 
        setCssClass("w-full h-full flex justify-center items-center overflow-hidden"); 
        if(popupSize==="medium") {setCssChildClass("w-[65%] h-[70%] engage-popup"); setloadFrameSize(["65%", "70%"]) }
        else if(popupSize==="small"){ setCssChildClass("w-[55%] h-[60%] engage-popup"); setloadFrameSize(["55%","60%"])}
        else if(popupSize==="large"){ setCssChildClass("w-[75%] h-[80%] engage-popup"); setloadFrameSize(["75%", "80%"]) } 
    }

    if (dataType==="sideTab"){  
        // setCssChildClass("engage-sideTab");
        setloadFrameSize(["100%","100%"])
        
        if(Side==="right"){
            settabBtnStyle({right:"100%",transform: "rotate(-90deg)"})
            

        setCssClass("h-full left-[100%] ");
        } else{
            settabBtnStyle({left:"100%", transform: "rotate(90deg)"})
        setCssClass(`h-full ${decivestate ==="mobile" ? "left-[-75%]" : "left-[-60%]"}`);

        }
        initBtnPos()
    } 

    if (dataType === "popover") {
        let style=""
        style+=(decivestate==="mobile" ? "w-[100%]" : "w-[43%]") 
        style+=" "+(decivestate==="mobile" ? "h-[100%]" : "h-[80%]")
        style+=" "+(decivestate==="mobile" ? "top-[3%]" : "top-[10%]")
        style+=" "+(Side==="right" ? "right-[2%]" : "left-[2%]")
        setCssClass("w-full h-full overflow-hidden");  setCssChildClass(`${style} engage-popover `)
        setloadFrameSize(["100%","100%"])

    }

    if (dataType === "fullPage") {  
        setCssClass("w-full h-full flex justify-center items-center overflow-hidden");  
        setCssChildClass("w-[95%] h-[95%] engage-fullPage")
        setloadFrameSize(["95%","95%"])
    }

    if (dataType === "inlineEmbed") { 
        setCssClass("w-full h-full overflow-hidden"); 
        setCssChildClass("w-full h-full") 
        setloadFrameSize(["100%","100%"])

    }

    if (dataType === "sidePanel") { 
        setCssClass("w-full h-full grid justify-items-end overflow-hidden");
        setCssChildClass(`${decivestate==="mobile" ? "w-[100%]" : (decivestate==="desktop" ? "w-[43%]" : "w-[55%]") } h-full`)  
       let tempWidth=(decivestate==="mobile" ? "100%" : (decivestate==="desktop" ? "43%" : "55%"))
        setloadFrameSize([tempWidth,"100%"])

    }
    },[runPreviewCount]) 

    function initBtnPos(){
        if(sideTabBtn?.current){
            let btnHeight=sideTabBtn?.current?.clientWidth/2
            let btnWidth=sideTabBtn?.current?.clientHeight/2
            
            btnHeight=Math.abs(btnHeight || 20)
            btnWidth=Math.abs(btnWidth || 20)
            
            btnWidth=(btnWidth-btnHeight)
            if(Side==="right") sideTabBtn.current.style.marginRight=btnWidth+"px";
            else sideTabBtn.current.style.marginLeft=btnWidth+"px"
        }
    }

    const SideTabToggle =(isOpen)=>{ 
        setisSideTabOpen(!isOpen);
        if(isOpen){
            if(Side==="right")    setCssClass("h-full left-[100%] ");
            else   setCssClass(`h-full ${decivestate ==="mobile" ? "left-[-75%]" : "left-[-60%]"}`);
        }else{
            if(Side==="right") setCssClass(`h-full ${decivestate==="mobile" ? "left-[25%]" : "left-[40%]"}`);   
            else setCssClass("h-full left-[0%] ");
        }
    }

    const PopoverToggle =()=>{
    let temp=cssChildClass; 
    if(temp.includes("open")) temp=temp.replace("open","close"); else
    if(temp.includes("close")) {  temp=temp.replace("close","open")   } else
    temp+=" open"
    setCssChildClass(temp);  
    }

    const close = ()=>{ 
    if(dataType==="popover"){ PopoverToggle() }
    if(dataType==="fullPage" || dataType==="popup"){  /* settoShow(false)  */  }
    }

    const hideLoader = () =>{
        setTimeout(()=>{

            let tempelm=element("engageLoadDiv")
            if(isNotEmpty(tempelm)) tempelm.style.display="none" 
            if(dataType==="sideTab")  initBtnPos()
        },1500)}

    return (    
    <div id="previewFrame" className={"relative  "+cssClass} style={{    transition:dataType==="sideTab" ?  transition : "0s", opacity:dataType==="sideTab" ? opacity: 1}}> 
        
        {(dataType==="sideTab")?
          

            
        <div className={`engage-sideTab-parent ${decivestate==="mobile" ? "w-[75%]" : "w-[60%]"}`} >

             
          {/* <div className={`${cssChildClass}  `}>
            <div className="engage-sideTab-vertical"><button onClick={()=>SideTabToggle()} className={`engage-sideTab-btn `}  style={{background:btnBgColor, color:btnTextColor}}><span style={{writingMode: "vertical-lr"}}>{btnText}</span></button>
        </div>
            <div className={`engage-sideTab-Frame`}>  
            <div class="engageLoadingDiv" style={{width:loadFrameSize[0],height:loadFrameSize[1]}}   id={`engageLoadDiv`}><div class="engageLoading"></div><div style={{maxWidth:"265px",width:"60%"}} ><img src="https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/GoZenEngage.png" alt="Engage Logo" /></div> </div>
        <Iframe onLoad={hideLoader} key={decivestate+"-"+runPreviewCount} src={RenderUrl}   title="preview" width="50%" height="100%" ></Iframe>
            </div>
        </div> 
         */}

        <div class="engageLoadingDiv" style={{width:loadFrameSize[0],height:loadFrameSize[1]}}   id={`engageLoadDiv`}><div class="engageLoading"></div><div style={{maxWidth:"265px",width:"60%"}} ><img src="https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/GoZenEngage.png" alt="Engage Logo" /></div> </div>
        <Iframe onLoad={hideLoader} key={decivestate+"-"+runPreviewCount} src={RenderUrl}   title="preview" width="100%" height="100%" ></Iframe>
        <button ref={sideTabBtn} onClick={()=>SideTabToggle(isSideTabOpen)} data-gz-eg-sideType={Side} id="engage-btn" class="noselect engage-sideTab-btn" style={{...tabBtnStyle,color:btnTextColor,backgroundColor:btnBgColor}}>{isSideTabOpen ? "Close" : btnText}</button>
        </div> 
        
        
        
        
        
        
        
        
        
        :

        <>
        {(dataType==="popover")&&
        <div className={`relative left-[45.2%]  top-[45%]`} onClick={()=>{PopoverToggle()}}>
        <p className={"engage-popover-btn"} style={{background:btnBgColor, color:btnTextColor}}>{btnText}</p> </div>}

        { toShow && 
        <div className={cssChildClass} onClick={()=>{close()}} >
        {(dataType==="fullPage" || dataType==="popup"|| dataType==="popover") &&
        <svg class="engage-close-btn"  width="26"  height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.825 6.18562C19.4025 5.76312 18.72 5.76312 18.2975 6.18562L13 11.4723L7.70253 6.17479C7.28003 5.75229 6.59753 5.75229 6.17503 6.17479C5.75253 6.59729 5.75253 7.27979 6.17503 7.70229L11.4725 12.9998L6.17503 18.2973C5.75253 18.7198 5.75253 19.4023 6.17503 19.8248C6.59753 20.2473 7.28003 20.2473 7.70253 19.8248L13 14.5273L18.2975 19.8248C18.72 20.2473 19.4025 20.2473 19.825 19.8248C20.2475 19.4023 20.2475 18.7198 19.825 18.2973L14.5275 12.9998L19.825 7.70229C20.2367 7.29062 20.2367 6.59729 19.825 6.18562Z" fill="#586474"></path></svg>}

        <div class="engageLoadingDiv" style={{width:loadFrameSize[0],height:loadFrameSize[1]}} id={`engageLoadDiv`}><div class="engageLoading"></div><div style={{maxWidth:"265px",width:"60%"}} ><img src="https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/GoZenEngage.png" alt="Engage Logo" /></div> </div>
        <Iframe onLoad={hideLoader} key={decivestate+"-"+runPreviewCount} src={RenderUrl}   title="preview" width="100%" height="100%" ></Iframe>
        
        </div>
    }
    
    </>
    }


        {/* <div class="container">
        <div class="vertical">Vertical Text Here</div>
        <div>
        <div>Horizontal Text Here</div>
        ...
        <div>Horizontal Text Here</div>
        </div>
        </div> */}
        {/* <div className={`h-full ${slideDriection} `+cssChildClass}>
        <button className={`engage-sideTab-btn `}   style={{background:btnBgColor, color:btnTextColor}}
        onClick={()=>{SideTabToggle()}}>{btnText}</button>
        <Iframe onLoad={hideLoader} key={decivestate+"-"+runPreviewCount} src={RenderUrl}   title="preview" width="100%" height="100%" ></Iframe>
        </div>  */}
    
    </div>
    )
    }