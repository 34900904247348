import React, { useEffect } from "react";
import Layout from ".";
import { EditerMainBlock } from "../components/Editor/common/priview";
import { useDispatch } from "react-redux";
import { Actions } from "../store";

export const EditorPreView = ({ SharedWS = false }) => {

    const Dispatch = useDispatch()

    useEffect(()=>{
        Dispatch(Actions.setSharedWS( SharedWS ))
    },[])

    return (
        <>
            <Layout isactive={1} padding="" small={true} activeSidetap={true} setediter={true} preview={true} sidebarView={true} >
                <div className="w-full h-full  list-industry">

                    <EditerMainBlock preview={true} integration={false} />

                </div>
            </Layout>
        </>
    )
} 