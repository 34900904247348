import React, { useEffect, useState } from "react";
import InputWithLabel from "./input/inputWithLabel";
import { Imageinput } from "./input/imageinput";
import InputRadiocheck from "./input/radiocheck";
import PrimaryButton from "./Button1/primarybutton";
import Input from "./input/input";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../store";
import DropMenu from "./input/dropmenu";
import DefaultDropMenu from "./input/droplist";
import { MediaPopup } from "./popup/Mediapopup";
import { TooltipData } from "../content/toolTip";
import { LogicJumpRule } from "./rule";
import SwicthInput from "./input/swicth";
import { Tutorial } from "./Tutorial";
import { useSelectionAction } from "../pages/customstate/context";
import { listOfIcon } from "../content/iconList";

export const EcommerceBlock = ({
    Index,
    fieldId = "",
    question = "",
    InPutType = "",
    IsOPtionType = false,
    options = [],
    jumpToLeft = [],
    rules = {},
    isAcitve=0,
    setQctiveQue=null,
    required = false


}) => {

    const DisPatch = useDispatch();
    const toolTip = TooltipData();
    const deleteIcon= listOfIcon('delete',20,20,'red')
    const { completedTutorial, engage_apptour  } = useSelector((state) => ({ completedTutorial: state.completedTutorial, engage_apptour:state.engage_apptour }))

    const dropmenuList = ['text', 'email', 'number', 'range', 'multioption',"rating","date","longText","starrating",'dropMenu',"phone-number","file-upload",'multi-select','yesorno']

    const ConditionalOperator = ['equal to', 'not equal to', 'contains', 'Does not contains']
    const [ShowCondeition, SetShowCondeition] = useState('')
    const Conditions = rules?.conditions ? Object.values(rules?.conditions) : [];
    const ConditionsKey = rules?.conditions ? Object.keys(rules?.conditions) : [];

    const [activeSate, setactiveState] = useState('number')

    const [activeSateInd, setactiveStateInd] = useState(dropmenuList.findIndex(resData => resData === InPutType))
    const [IsOPtionTypedata, setIsOPtionType] = useState((InPutType === 'multioption' || String(InPutType).toLocaleLowerCase() === 'dropmenu' || String(InPutType).toLocaleLowerCase() ==="multi-select") ? true : false)
    const [ShowMedia, setShowMidia] = useState(false);
    const [startTutorial, setStartTutorial] = useState(false)
    const {  ShowPageMainContent } = useSelectionAction();



    useEffect(()=>{
        setTimeout(()=>{ setStartTutorial((!completedTutorial?.logicJumpBtn) && engage_apptour && ShowPageMainContent) },100)
        return(()=>{
            setStartTutorial(false)
        })
    },[engage_apptour])

    const DefaultCC = (value, FID = fieldId, eage, optionIndex = 0, optionKey = '') => {
        const setContent = {
            key: FID,
            node: 'game_mainpage_json',
            child: 'questions',
            edge: eage,
            QueIndex: Index,
            optionIndex: optionIndex,
            optionKey: optionKey,
            value: value
        }
        DisPatch(Actions.setQuizQuestionCC(setContent))
    }

    const addOption = (remove=false,optionIndex=0) => {
        const setContent = {
            key: fieldId,
            node: 'game_mainpage_json',
            child: 'questions',
            edge: 'options',
            QueIndex: Index,
            optionIndex: optionIndex,
            value: 'option',
            remove:remove
        }
        DisPatch(Actions.setAddOption(setContent))
    }

    const DirectContentChange = (value, type, optionIndex = 0) => {


        const setContent = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_mainpage_json',
            child: "questions",
            edge: "options",
            QueIndex: Index,
            optionIndex: optionIndex,
            value: value
        }

        DisPatch(Actions.setQuizQuestionCC(setContent))

        if (ShowMedia) setShowMidia(false)

    }

    const RulesOption = (rulesKey, ConditionKeys = '', value) => {
        const setContent = {
            key: fieldId,
            node: 'game_mainpage_json',
            QueIndex: Index,
            child: 'questions',
            edge: 'rules',
            rulesKey: rulesKey,
            ConditionKeys: ConditionKeys,
            value: value
        }
        DisPatch(Actions.setRuleAddOptionCC(setContent))
    }

    const RuleConditionAdd = (rulesKey, ConditionKeys = '', value) => {

        const setContent = {
            key: fieldId,
            node: 'game_mainpage_json',
            QueIndex: Index,
            child: 'questions',
            edge: 'rules',
            rulesKey: rulesKey,
            ConditionKeys: ConditionKeys,
            value: value

        }

        DisPatch(Actions.setRuleAddOption(setContent))
    }


    const QuesRangechange = (type) => {

        const setContent = {
            key: fieldId,
            node: 'game_mainpage_json',
            child: 'questions',
            position: Index,
            type: type
        }

        DisPatch(Actions.setQuizQuestionRA(setContent))
    }


    const Test = (state, id) => {
        setactiveState(state)
        const findIndex = dropmenuList.findIndex(resData => resData === state)
        setactiveStateInd(findIndex)
        let tst = String(state).toLocaleLowerCase()
        setIsOPtionType((tst === 'multioption' || String(tst).toLocaleLowerCase() === 'dropmenu' || String(tst).toLocaleLowerCase() ==="multi-select") ? true : false)
        // else setIsOPtionType(false)
        DefaultCC(tst, fieldId, 'InPutType')

    }

    const setactiveScreen = (ind) => {

        let setContent = {
            screen: "main",
            index:ind
        }
        setQctiveQue(ind)
        DisPatch(Actions.setActiveQuestions(setContent))
    }



    const ChangeQuestiontype = () => {
        // settoggleButton(toggleButton ? false : true)
        // DefaultCC(toggleButton ? "yerorno" : "multiChoice", fieldId, 'optionType')
    }


    const AnsOption = ['Yes', "No"]

    return (
        <>
            {ShowMedia && <MediaPopup cancel={setShowMidia} fieldType={'image'} OnSelect={DirectContentChange} />}
            {startTutorial && <Tutorial page="logicJumpBtn"/>}

                                                                           
            <div key={Index} className={`w-full transition-all relative flex  flex-col space-y-2 border-2  h-fit delay-400 duration-700 top-0 p-4 rounded-xl hover:shadow-md hover:-top-0 ${isAcitve === Index ?"border-primary":"cursor-pointer"} `} onClick={()=>setactiveScreen(Index)} >

                <div className="flex justify-between">
                    <p className="text-base font-medium" >Question - {Index + 1}</p>
                    <div className="flex space-x-2">
                        {
                            toolTip.map((list, index) => {
                                return (
                                    <p key={index} className="p-2 rounded-md border-1 hover:bg-gray-200 cursor-pointer" onClick={() => QuesRangechange(list.type)} >{list.icon}</p>
                                )
                            })
                        }
                    </div>
                </div>


                {/* Question Editor */}
                { (isAcitve === Index)&&<div className="flex flex-col space-y-3 ">

                    {/* Question */}
                    <InputWithLabel label="Question" textvalue={question} onchange={(e) => DefaultCC(e.target.value, fieldId, 'question')} />

                    <DefaultDropMenu label={'Type'} mainclass="flex space-x-4" labelTextWeight="my-auto" list={dropmenuList} active={activeSateInd < 0 ? 0 : activeSateInd} onchange={Test} fieldType={'answer'} />

                    {/* Question option */}
                    {IsOPtionTypedata && <>
                        <div className="space-y-3">

                            <label className="capitalize" >Answer options </label>


                            {

                                options.map((list, index) => {
                                    return (
                                        <div className="w-full flex space-x-2">
                                            <Input margin={''} Placeholder="key" textvalue={list} onchange={(e) => DefaultCC(e.target.value, fieldId, 'options', index)} />
                                            <span className="p-2 rounded-md border-1 border-red-500 cursor-pointer" onClick={()=>addOption(true,index)} >{deleteIcon} </span>
                                        </div>
                                    )
                                })

                            }
                        </div>
                        <PrimaryButton text={'Add Option'} width="w-28" height="h-10" backgroundColor="bg-primary-lightblue" border="border-1 border-primary" textcolor="text-primary" OnClick={()=>addOption()} />
                        {/* Condition */}

                    </>
                    }
                    <SwicthInput  label={"Required"} labelTextsize={'text-sm'} fieldType={'required'}  defaultOPtion={true} status={required} onchange={(e) => DefaultCC(e, fieldId, 'required')}   />

                        <PrimaryButton  textsize='text-14px step-edit-logicJumpBtn'  text='Logic Jump' OnClick={() => ShowCondeition === '' ? SetShowCondeition(`${Index}`) : SetShowCondeition(``)} />
                        {(ShowCondeition === `${Index}`)&&<LogicJumpRule 
                        showBlock={ShowCondeition === `${Index}`? true : false} 
                        ConditionalOperator={ConditionalOperator} 
                        Conditions={Conditions} 
                        ConditionsKey={ConditionsKey} 
                        RuleConditionAdd={RuleConditionAdd} 
                        RulesOption={RulesOption} 
                        jumpToLeft={jumpToLeft} 
                        queINdex= {Index} rules={rules} 
                        />}



                </div> }

            </div>
        </>
    )
}