import React from "react";
import PrimaryButton from "../Button1/primarybutton";
import { listOfIcon } from "../../content/iconList";


export const Imageinput = ({ label = 'Change Image', type = 'image', maxSize = "3mb", src = '/asset/images/emptyimage.png', chanaeAction = null, deleteSrc = null, showDel = true }) => {

    const imgIcon = listOfIcon('img', 14, 14, '#2563EB');

    const deleteIcon = listOfIcon('delete2', 18, 18, '#586474', 'white');

    return (
        
        <div className="flex w-full space-x-3 max-w-full">

            <div className="w-[275px]  h-[170px] flex bg-white rounded-lg border-1">

                <img src={src == "" ? "/asset/images/emptyimage.png" : type === "image" ? src : "/asset/images/empyaudio.png"} alt={``} title={``} className={'w-auto h-auto max-h-full object-cover m-auto'} />
            
            </div>
            
            <div className="space-y-2 my-auto">
                
                <p className="font-medium capitalize">{label}</p>
                
                <p className="text-sm">Max image size <span>{maxSize}</span></p>
                
                <div className="flex space-x-2">

                    <PrimaryButton text={'change'} backgroundColor={'bg-dim-blue-ft'} OnClick={chanaeAction} textcolor={'text-primary'} icon={imgIcon} />

                    {showDel && <span className="p-3 rounded-md border-1 cursor-pointer" onClick={deleteSrc} > {deleteIcon}  </span>}
               
                </div>
            
            </div>
        
        </div>

    )
}