// import { Input } from "postcss";
import React, { Component, useEffect, useRef } from "react";
import Layout from ".";
import { useSelectionAction } from "./customstate/context";
import { PopupCreate } from "../components/popup/popupcreate";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getCampaign, getCreateTemp, getFields } from "../actions/templates";
import { DesignBlock } from "../components/Editor/design";
import { IntegateBlock } from "../components/Editor/integration";
import { ShareBlock } from "../components/Editor/share";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../store";
import { ResultBlock } from "../components/Editor/result";
import { Ecommmerceproduct } from "../components/Editor/product";
import { AiPopupGC } from "../components/popup/GenerateAiContentPopup";


const EditGrenaraeAiCreateTempalte = () => {

    const Params = useParams();
    const Dispatch = useDispatch() //setclearEditor

    const  {campaigninfo,isEcommerceFields} = useSelector((state)=> ({campaigninfo:state.Campaign_info,isEcommerceFields:state.isEcommerceFields}))

    const fileds = ["title", "description", "sub description", "image", "email input", "terms & conditions"];
    const tigger = ["Show on page load", "Show on exit", "Click to trigger"];

    const Cancelbut = () => {
        setShowPopup(false)
    }

    

    const { editerstate, setSidebarTrigger, setSidebarState,setEditerState } = useSelectionAction()
    const [defaultvalue, setDefaultvalue] = useState('My Project-1')
    const [showPopup, setShowPopup] = useState(true)
    const [showEcommerce, setEcommerce] = useState(false)
    const [allFileds, setallFileds] = useState([])


    const campaigninochange = (e,type)=>{
        const payload = { 
            type:type,
            value:e.target.value
        }
        Dispatch(Actions.setCampaignUpdate(payload))
    }

    const DoneAction = () =>{
        Dispatch(Actions.setUpdateCampaign())
        setShowPopup(false)
    }

    

    useEffect(() => {
        setEditerState("design");
        Dispatch(Actions.setclearEditor())
        // fetchCampaign(Params.template_id);
    }, [Params.template_id])


    const fetchCampaign = async (Campaign) => {
        setloading(true)
        // const Campaign = await getCreateTemp(template_id);
        const allField = await getFields();
        Dispatch(Actions.setCreateTEmplateState(true))

        
        if (Campaign.success) {
            let Filed = Campaign.data.fileds ?? {}
            setDefaultvalue(Campaign.data?.name);
            setallFileds(allField.data?.allfields)
            setCommonFileds(allField.data?.commonFileds)
            Dispatch(Actions.setCampaign({...Campaign.data,Campaign_id:Params.campaign_id,additional_info:"{}"}));
            Dispatch(Actions.setFields(JSON.parse(Campaign.data.fileds)))
            Dispatch(Actions.setFieldsOrder(JSON.parse(Campaign.data.order)))
            Dispatch(Actions.setEditorFieldsFormSever(allField.data?.commonFileds))
            Dispatch(Actions.SetAddSites(Campaign.data?.Sites))
            
            const fieldType = JSON.parse(Campaign.data.fileds)[JSON.parse(Campaign.data.order)[0]]?.type

            if (fieldType === "ecommerce") {
                // setEcommerceFieldID
                // let ProductSl = Filed[JSON.parse(Campaign.data.order)[0]].filedContent?.productSelectList ?? []
                Dispatch(Actions.setisEcommerceFields(true))
                Dispatch(Actions.setEcommerceFieldID(JSON.parse(Campaign.data.order)[0]))
                // ProductSl.length == 0 ? setEditerState("product") : setEditerState("design")
                setEditerState("product");
            }

        }

        else alert(Campaign.message + ' .')
        setloading(false)
        setShowpop(false)
    }
    
    // const [defaultvalue, setDefaultvalue] = useState('My Project-1');
    // const [allFileds, setallFileds] = useState([]);
    const [commonFileds, setCommonFileds] = useState({});
    const [triggerField, setTriggerField] = useState(false);
    const [loading, setloading] = useState(false);
    const [Showpop,setShowpop] = useState(true)

    setSidebarState(0)

    return (

        <>
            
            <Layout isactive={1} padding="" small={true} activeSidetap={true} setediter={true} sidebarView={true} Ecommerce={isEcommerceFields} >
                {Showpop ? <AiPopupGC Cancel={setShowpop} CreateTEmp={true} template_id={Params.template_id} SetData={fetchCampaign} /> : <>
                {showPopup ? <PopupCreate title="Campaign Name" type={'name'} defaultVaule={campaigninfo?.name} changeDefaultVaule={campaigninochange} forwardAction={DoneAction} backwardAction={setShowPopup} /> : null}
                
                {editerstate == "product" && <Ecommmerceproduct />}

                {editerstate == "design" && <DesignBlock campaignId={Params.campaign_id} allFileds={allFileds} commonFileds={commonFileds} loading={loading} editName={()=>setShowPopup(true)} />}

                {editerstate == "integration" && <IntegateBlock campaignId={Params.campaign_id}/>}

                {editerstate == "result" && <ResultBlock/>}
                
                {editerstate == "share" && <ShareBlock/>}
                </>
                }
            </Layout>

        </>

    )

}

export default EditGrenaraeAiCreateTempalte;