import React, { useEffect, useState } from "react";
import { EventBlockCount } from "../../../../eventcountblock";
import { EVentCountLineChart } from "./EventCountChart";
import { RenderdDevices } from "./Renderdevice";
import { RenderedCountryChart } from "./mapchart";
import { useSelector } from "react-redux";
import { campaignAnalytics } from "../../../../../actions/templates";
import { HorBarChart } from "./barchart";
import { RenderedPages } from "./RenderedSite";

export const AnalyticsSection = ({ isActive =true}) => {

    const {CampaignDetail} = useSelector((state) => ({CampaignDetail:state.Campaign_info}))

    const [EventCount,SetEventCount] = useState({ "td_view_count": 0, "td_click_count": 0, "td_subscribe_count": 0 })
    const [RenderDevice,SetRenderDevice] = useState({})
    const [RenderCountry,SetRenderCountry] = useState({})
    const [DailyEC,SetDailyEC] = useState({})
    const [RenderSite,SetRenderSite] = useState([])

    const [ Month, Setmonth ] = useState(new Date().getMonth())
    const [ Year, SetYear ] = useState(new Date().getFullYear())
    const [ type, SetType ] = useState('monthly')

    useEffect ( () => {
        const CurrentDate = new Date();

        fetchResult(CampaignDetail.Campaign_id,Month,Year,type)
        
    },[Month])

    const fetchResult = async ( camp_id, mon=1, year=2023, type='monthly' ) => {
        const ResDate = await campaignAnalytics(camp_id,mon+1,year,type='monthly')

        SetEventCount(ResDate?.data?.EventCount)
        SetRenderDevice(ResDate?.data?.Device)
        SetRenderCountry(ResDate?.data?.Country)
        SetDailyEC(ResDate?.data?.graphResponse)
        SetRenderSite(ResDate?.data?.rendededSite ?? ResDate?.data?.RendededSite)

    }

    const EventBlocks = [
        {
            label:'Views',
            icon:(<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.0001 34.0212C20.0209 34.0212 15.9792 29.9795 15.9792 25.0003C15.9792 20.0212 20.0209 15.9795 25.0001 15.9795C29.9792 15.9795 34.0209 20.0212 34.0209 25.0003C34.0209 29.9795 29.9792 34.0212 25.0001 34.0212ZM25.0001 19.1045C21.7501 19.1045 19.1042 21.7503 19.1042 25.0003C19.1042 28.2503 21.7501 30.8962 25.0001 30.8962C28.2501 30.8962 30.8959 28.2503 30.8959 25.0003C30.8959 21.7503 28.2501 19.1045 25.0001 19.1045Z" fill="#2563EB"/>
            <path d="M25 43.7913C17.1667 43.7913 9.77083 39.208 4.6875 31.2497C2.47917 27.8122 2.47917 22.208 4.6875 18.7497C9.79167 10.7913 17.1875 6.20801 25 6.20801C32.8125 6.20801 40.2083 10.7913 45.2917 18.7497C47.5 22.1872 47.5 27.7913 45.2917 31.2497C40.2083 39.208 32.8125 43.7913 25 43.7913ZM25 9.33301C18.2708 9.33301 11.8333 13.3747 7.33333 20.4372C5.77083 22.8747 5.77083 27.1247 7.33333 29.5622C11.8333 36.6247 18.2708 40.6663 25 40.6663C31.7292 40.6663 38.1667 36.6247 42.6667 29.5622C44.2292 27.1247 44.2292 22.8747 42.6667 20.4372C38.1667 13.3747 31.7292 9.33301 25 9.33301Z" fill="#2563EB"/>
            </svg>
            ),
            count:EventCount?.td_view_count ?? 0,
        },
        {
            label:'Started',
            icon:(<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.9999 47.4167C22.7291 47.4167 20.4791 46.75 18.7083 45.4375L9.74992 38.75C7.37492 36.9792 5.52075 33.2708 5.52075 30.3333V14.8333C5.52075 11.625 7.87492 8.20833 10.8958 7.08333L21.2916 3.1875C23.3541 2.41667 26.6041 2.41667 28.6666 3.1875L39.0624 7.08333C42.0833 8.20833 44.4374 11.625 44.4374 14.8333V30.3125C44.4374 33.2708 42.5832 36.9583 40.2082 38.7292L31.2499 45.4167C29.5208 46.75 27.2708 47.4167 24.9999 47.4167ZM22.3958 6.125L11.9999 10.0208C10.2291 10.6875 8.66658 12.9375 8.66658 14.8542V30.3333C8.66658 32.3125 10.0624 35.0833 11.6249 36.25L20.5833 42.9375C22.9791 44.7292 27.0208 44.7292 29.4374 42.9375L38.3958 36.25C39.9791 35.0625 41.3541 32.3125 41.3541 30.3333V14.8333C41.3541 12.9375 39.7916 10.6875 38.0208 10L27.6249 6.10417C26.2083 5.60417 23.7916 5.60417 22.3958 6.125Z" fill="#2563EB"/>
            <path d="M22.2083 29.6455C21.8125 29.6455 21.4167 29.4997 21.1042 29.1872L17.75 25.833C17.1458 25.2288 17.1458 24.2288 17.75 23.6247C18.3542 23.0205 19.3542 23.0205 19.9583 23.6247L22.2083 25.8747L30.0625 18.0205C30.6667 17.4163 31.6667 17.4163 32.2708 18.0205C32.875 18.6247 32.875 19.6247 32.2708 20.2288L23.3125 29.1872C23 29.4997 22.6042 29.6455 22.2083 29.6455Z" fill="#2563EB"/>
            </svg>
            ),
            count:EventCount?.td_click_count ?? 0,
        },
        {
            label:'Response',
            icon:(<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.0001 47.3962C12.6459 47.3962 2.60425 37.3545 2.60425 25.0003C2.60425 12.6462 12.6459 2.60449 25.0001 2.60449C37.3542 2.60449 47.3959 12.6462 47.3959 25.0003C47.3959 37.3545 37.3542 47.3962 25.0001 47.3962ZM25.0001 5.72949C14.3751 5.72949 5.72925 14.3753 5.72925 25.0003C5.72925 35.6253 14.3751 44.2712 25.0001 44.2712C35.6251 44.2712 44.2709 35.6253 44.2709 25.0003C44.2709 14.3753 35.6251 5.72949 25.0001 5.72949Z" fill="#2563EB"/>
            <path d="M22.0417 32.4587C21.6251 32.4587 21.2292 32.292 20.9376 32.0003L15.0417 26.1045C14.4376 25.5003 14.4376 24.5003 15.0417 23.8962C15.6459 23.292 16.6459 23.292 17.2501 23.8962L22.0417 28.6878L32.7501 17.9795C33.3542 17.3753 34.3543 17.3753 34.9584 17.9795C35.5626 18.5837 35.5626 19.5837 34.9584 20.1878L23.1459 32.0003C22.8542 32.292 22.4584 32.4587 22.0417 32.4587Z" fill="#2563EB"/>
            </svg>
            ),
            count:EventCount?.td_subscribe_count ?? 0,
        },
        {
            label:'Finished',
            icon:(<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M36.7917 47.2292C35.6876 47.2292 34.2709 46.875 32.5001 45.8333L26.2709 42.1458C25.6251 41.7708 24.3751 41.7708 23.7501 42.1458L17.5001 45.8333C13.8126 48.0208 11.6459 47.1458 10.6667 46.4375C9.70842 45.7292 8.20842 43.9167 9.18758 39.75L10.6667 33.3542C10.8334 32.6875 10.5001 31.5417 10.0001 31.0417L4.83342 25.875C2.25008 23.2917 2.45842 21.0833 2.81258 20C3.16675 18.9167 4.29175 17 7.87508 16.3958L14.5209 15.2917C15.1459 15.1875 16.0417 14.5208 16.3126 13.9583L20.0001 6.60417C21.6667 3.25 23.8542 2.75 25.0001 2.75C26.1459 2.75 28.3334 3.25 30.0001 6.60417L33.6667 13.9375C33.9584 14.5 34.8543 15.1667 35.4793 15.2708L42.1251 16.375C45.7292 16.9792 46.8542 18.8958 47.1876 19.9792C47.5209 21.0625 47.7292 23.2708 45.1667 25.8542L40.0001 31.0417C39.5001 31.5417 39.1876 32.6667 39.3334 33.3542L40.8126 39.75C41.7709 43.9167 40.2917 45.7292 39.3334 46.4375C38.8126 46.8125 37.9792 47.2292 36.7917 47.2292ZM25.0001 38.7292C26.0209 38.7292 27.0417 38.9792 27.8542 39.4583L34.0834 43.1458C35.8959 44.2292 37.0417 44.2292 37.4792 43.9167C37.9167 43.6042 38.2292 42.5 37.7709 40.4583L36.2917 34.0625C35.8959 32.3333 36.5417 30.1042 37.7917 28.8333L42.9584 23.6667C43.9793 22.6458 44.4376 21.6458 44.2292 20.9583C44.0001 20.2708 43.0417 19.7083 41.6251 19.4792L34.9793 18.375C33.3751 18.1042 31.6251 16.8125 30.8959 15.3542L27.2292 8.02083C26.5626 6.6875 25.7292 5.89583 25.0001 5.89583C24.2709 5.89583 23.4376 6.6875 22.7917 8.02083L19.1042 15.3542C18.3751 16.8125 16.6251 18.1042 15.0209 18.375L8.39592 19.4792C6.97925 19.7083 6.02092 20.2708 5.79175 20.9583C5.56258 21.6458 6.04175 22.6667 7.06258 23.6667L12.2292 28.8333C13.4792 30.0833 14.1251 32.3333 13.7292 34.0625L12.2501 40.4583C11.7709 42.5208 12.1042 43.6042 12.5417 43.9167C12.9792 44.2292 14.1042 44.2083 15.9376 43.1458L22.1667 39.4583C22.9584 38.9792 23.9792 38.7292 25.0001 38.7292Z" fill="#2563EB"/>
            </svg>
            ),
            count:EventCount?.td_subscribe_count ?? 0,
        },
    ]

    return (
        <section className={`w-full transition-all duration-500 ${isActive ? 'opacity-100':'opacity-0'} space-y-6 flex flex-col pb-20 pt-4 h-full overflow-y-auto px-16`}>
            <EventBlockCount EventBlocks={EventBlocks} />
            <div className="w-full flex space-x-6">
                <RenderdDevices data={RenderDevice} />
                <HorBarChart data={RenderSite} />
            </div>
            <RenderedCountryChart data={RenderCountry} />
            <EVentCountLineChart data={DailyEC} Setmonth={Setmonth} SetYear={SetYear} SetType={SetType} />
            <RenderedPages sitedata={RenderSite} />
        </section>
    )
}