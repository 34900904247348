import React, { useState } from "react";
import InputWithLabel from "./input/inputWithLabel"; 
import PrimaryButton from "./Button1/primarybutton";
import Input from "./input/input";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../store"; 
import { MediaPopup } from "./popup/Mediapopup";
import { ConnectionImage } from "./connectionimage"; 
import SwicthInput from "./input/swicth";
import { toast, Toaster } from "react-hot-toast";
import { isEmpty, isNotEmpty } from "../utils/helpers";
import { Imageinput } from "./input/imageinput";
import { ColorPixerInput } from "./input/colorpixerInput";
import CheckBox from "./input/checkbox";
import { TooltipData } from "../content/toolTip";

export const SpinWheelSegment = ({ Index, fieldId = "",     list={}, totalSeg=0 }) => {

    const DisPatch = useDispatch()
    
    const { Fields } = useSelector((state) => ({ Fields: state.fields}));

    const [toggleButton, settoggleButton] = useState(list?.type === "string" ? true : false) 
    const [couponBgToggle, setCouponBgToggle] = useState(list?.couponBgType === "image" ? true : false) 

    const [ShowMedia, setShowMidia] = useState(false);  
    const [isWin, setIsWin] = useState(list?.win);
    // const [ShowInner, setShowInner] = useState(0);
    // const [RowInner, setRowInner] = useState(0);
    const [curFieldType, setcurFieldType] = useState("value") 

 
 const validateProb = (target,  fieldId, edge)=>{ 
    let value=target?.value
    let allSegArr=Fields[fieldId]?.filedContent?.game_mainpage_json?.segmentValuesArray 
  let prevTotalProb=0; allSegArr?.map((val,inx)=>{ 
    if(inx!==Index && isNotEmpty(val?.probability)) prevTotalProb+=parseInt(val?.probability) })

  if( (prevTotalProb+parseInt(value?value:0))<=100) DefaultCC(value,fieldId,edge)
  else{
    target.value=allSegArr[Index].probability;
    //target.value=0;
    toast.error("Probability exceeds 100% !")
  }
 
     
 }

    const DefaultCC = (value,  fieldId, edge) => {

    if(edge==="couponBgType") setCouponBgToggle( value === "image" ? true : false)
    
        const payload = {
            key: fieldId,
            node: 'game_mainpage_json',
            child: 'segmentValuesArray',
            QueIndex: Index, 
            edge: edge,
            value: value
        }
       DisPatch(Actions.setWheelSegCC(payload))
    }

    const DirectContentChange = (value, type, optionIndex = 0, add = false) => { 
        const setContent = { 
              key: fieldId,
            node: 'game_mainpage_json',
            child: 'segmentValuesArray',
            QueIndex: Index, 
            edge: curFieldType,
            value: value
        } 
       DisPatch(Actions.setWheelSegCC(setContent))

        if (ShowMedia) setShowMidia(false)

    }

 
    const WheelSegRangechange = (type) => {
 
        const setContent = {
            key: fieldId,
            node: 'game_mainpage_json',
            child: 'segmentValuesArray',
            position: Index,
            type: type
        }

        DisPatch(Actions.setWheelSegRA(setContent))
    }



    const changeCouponBgColor = (value,  edge, fieldId) => {

            const payload = {
                key: fieldId,
                node: 'game_mainpage_json',
                child: 'segmentValuesArray',
                QueIndex: Index, 
                edge: edge,
                value: value
            }
           DisPatch(Actions.setWheelSegCC(payload))
        }


    const toolTip = TooltipData();



    const ChangeQuestiontype = () => {
        let temp=(toggleButton?  false : true)
        settoggleButton(temp)
        DefaultCC(temp ? "string" : "image", fieldId, 'type')
    }
 

    const OnClickCI = (index,Rindex) => {
        // setShowInner(index)
        // setRowInner(Rindex)

        setcurFieldType("value")
        setShowMidia(true)
    }

    const winSwitch = () =>{
let temp=isWin?false:true
        setIsWin(temp)
        DefaultCC(temp, fieldId, 'win')
    }

 

    return (
        <>
            {ShowMedia && <MediaPopup  fieldType={curFieldType} cancel={setShowMidia}  OnSelect={DirectContentChange} />}

            <div key={Index} className={`w-full relative flex  flex-col space-y-2 border-2  h-fit delay-400 duration-700 top-0 p-4 rounded-xl hover:shadow-md hover:-top-0 hover:border-primary`}  >

                <div className="flex justify-between">
                    <p className="text-base font-medium" >Segment - {Index + 1}</p>
                    { totalSeg>2 && <div className="flex space-x-2">
                        {
                            toolTip.map((list, index) => {
                                if((list.type==="delete"))  return (
                                     <p className="p-2 rounded-md border-1 hover:bg-gray-200 cursor-pointer" onClick={() => WheelSegRangechange(list.type)} >{list.icon}</p>
                                )
                            })
                        }
                    </div> }
                </div> 

                {/* Question Editor */}
                <div className="flex flex-col space-y-3">

                 
                    {/* Question type */}
                    <div className="w-full space-y-2">
                        <label className="capitalize" >Segment type</label>
                        <div className="flex w-full 0">
                            <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={toggleButton ? 'text-gray-500' : 'text-primary'} border={toggleButton ? 'border-2 border-gray-500' : 'border-2 border-primary'} rounded="rounded-l-2" height="h-14" text={'Text'} OnClick={ChangeQuestiontype} />
                            <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={!toggleButton ? 'text-gray-500' : 'text-primary'} border={!toggleButton ? 'border-2 border-gray-500' : 'border-2 border-primary'} height="h-14" text={'Image'} rounded='rounded-none' OnClick={ChangeQuestiontype} />
                        </div>
                    </div>

                    {/* Question option */}
                    <div className="space-y-3">
                    <label className="capitalize" >Segment {toggleButton? "Text" : "Image"}</label>
                    {toggleButton? <Input margin={''}  Placeholder="Segment Text" textvalue={list?.value?list?.value:""} onchange={(e) => DefaultCC(e.target.value, fieldId, 'value')} /> :
                    <ConnectionImage imagSrc={list?.value} onClickEVent={() => OnClickCI(Index,list)} deleteEvent={()=> {DefaultCC("", fieldId, 'value')}}/>} 
                    </div>

                    {/* Discount Code */} 
                    <InputWithLabel label="Discount Code" Placeholder="Discount Code" textvalue={list?.resultText} onchange={(e) => DefaultCC(e.target.value, fieldId, 'resultText')} />

                    {/* Win */}
                    <SwicthInput label={"Win"} labelTextsize={'text-sm'} fieldId={fieldId} status={isWin}  onchange={(e) => {winSwitch()}} />
                    
                     {/* Probability */}
                     <InputWithLabel label="Probability" Placeholder="Probability" type={"number"} textvalue={list?.probability} onchange={(e) => validateProb(e.target, fieldId, 'probability')} />

                     
                    {/* Result Title */} 
                    <InputWithLabel label="Result Title" Placeholder="Result Text" textvalue={list?.prizetext} onchange={(e) => DefaultCC(e.target.value, fieldId, 'prizetext')} />

                    {/* Redirect Text */} 
                    <InputWithLabel label="Redirect Button Text" Placeholder="Redirect Button Text" textvalue={list?.redirBtnTxt} onchange={(e) => DefaultCC(e.target.value, fieldId, 'redirBtnTxt')} />
  
                    {/* Redirect URL */} 
                    <InputWithLabel label="Redirect URL" Placeholder="Redirect URL" textvalue={list?.redirURL} onchange={(e) => DefaultCC(e.target.value, fieldId, 'redirURL')} />


                    <CheckBox label="Scratch Card" isChecked={list?.couponStauts }  onchange={(e) => DefaultCC(list?.couponStauts ? false : true, fieldId, 'couponStauts')}  />


                    {
                                        list?.couponStauts && <div className="w-full space-y-4 ">


                                            <InputWithLabel label="Result Description" textvalue={list?.couponDescription} onchange={(e) => DefaultCC(e.target.value, fieldId, 'couponDescription')} viewiconState={true} labelTextWeight="flex justify-between"   />



                                            <div className="w-full space-y-4">
                                                <label className="capitalize" >Scratch Background type</label>
                                                <div className="flex w-full 0">
                                                    <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={couponBgToggle ? 'text-gray-500' : 'text-primary'} border={couponBgToggle ? 'border-2 border-gray-500' : 'border-2 border-primary'} height="h-14" text={'Color'} rounded={'rounded-none'}  OnClick={() => DefaultCC('color',fieldId,"couponBgType")} />
                                                    <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={!couponBgToggle ? 'text-gray-500' : 'text-primary'} border={!couponBgToggle ? 'border-2 border-gray-500' : 'border-2 border-primary'} height="h-14" text={'Image'} rounded='rounded-none' OnClick={() => DefaultCC('image',fieldId,"couponBgType")} />
                                                </div>
                                            </div>

                                        {couponBgToggle ? <Imageinput label="Background Image" src={list?.couponBgImage}  chanaeAction={() => {setcurFieldType("couponBgImage");setShowMidia(true);  }} deleteSrc={()=>DefaultCC('',fieldId, 'couponBgImage')} />: 
                                        <ColorPixerInput label={"Background Color"} Onchange={changeCouponBgColor} fieldId={fieldId} fieldType="couponBgColor"   colorHex={list?.couponBgColor} labelTextWeight="flex justify-between" />}
                            
                                            <Imageinput label="Scratch Image" src={list?.couponScratchImage} chanaeAction={() => {setcurFieldType("couponScratchImage");setShowMidia(true); }} deleteSrc={()=>DefaultCC('',fieldId, 'couponScratchImage')} />


                                        </div>
                                    }
                    </div>
            </div>
        </>
    )
}