import React, { useState } from "react";
import { Imageinput } from "../../input/imageinput";
import InputWithLabel from "../../input/inputWithLabel";
import TextareaInput from "../../input/textarea";
import { useDispatch } from "react-redux";
import { Actions } from "../../../store";
import { MediaPopup } from "../../popup/Mediapopup";
import { ColorPixerInput } from "../../input/colorpixerInput";
import SwicthInput from "../../input/swicth";

export const StartScreen = ({ field, fieldId,category }) => { 

    const Dispatch = useDispatch();
    const [ShowMedia, setShowMidia] = useState(false);


    const ContentChange = (e, type) => {

        const setContent = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_startscreen_json',
            child: type,
            value: e.target.value
        }



        Dispatch(Actions.setFieldTemplateContentChange(setContent))

    }
    const DirectContentChange = (value, type) => {


        const setContent = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_startscreen_json',
            child: type,
            value: value
        }
        Dispatch(Actions.setFieldTemplateContentChange(setContent))
        if (ShowMedia) setShowMidia(false)

    }
    
    return (

        <div className="w-full space-y-2 ">

            {ShowMedia && <MediaPopup cancel={setShowMidia} fieldType={'imageUrl'} OnSelect={DirectContentChange} />}

            { ( category !== 'games' ) && <SwicthInput parentClass={'flex-row-reverse justify-between '} label={"Show Start Screen"}  fieldType={'required'}  defaultOPtion={true} status={field?.view ?? true } onchange={(e) => DirectContentChange(e, 'view')}   />}

            <InputWithLabel label="Header" textvalue={field?.titleContent} onchange={(e) => ContentChange(e, 'titleContent')}  />

            <TextareaInput label="Description" textvalue={field?.descriptionContent} onchange={(e) => ContentChange(e, 'descriptionContent')} />

            <Imageinput label="Cover image" src={field?.imageUrl} chanaeAction={()=>setShowMidia(true)} deleteSrc={()=>{DirectContentChange("",'imageUrl')}} />

            <InputWithLabel label="Button text" textvalue={field?.buttonText} onchange={(e) => ContentChange(e, 'buttonText')} />

           {category!=="quizzes" && category!=="ecommerce" && category!=="calculater" &&  category!=="surveys" &&  category!=="poll"
             && <ColorPixerInput label={"Button color"} Onchange={DirectContentChange} fieldType="buttonBgcolor" colorHex={field?.buttonBgcolor ?? "#7ED321"} labelTextsize={'text-sm my-auto'} fieldId={fieldId} />}
        </div>
    )
}