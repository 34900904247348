export var share_flow=[  

    {
        element: ".step-share-linkToShare",
        title: "Link to share",
        intro: "Regarding the 'Link to Share' field, it simplifies sharing campaigns with a single, easily distributable link.",
    },  

    {
        element: ".step-share-targetRules",
        title: "Target Rules",
        intro: "Target rules define specific criteria for customizing campaigns, ensuring personalized experiences for users.",
    },  

    {
        element: ".step-share-position",
        title: "Customize Popups",
        intro: "Page position options allow you to choose the ideal display style for your content.",
    }, 

    {
        element: ".step-share-domainAndGetCode",
        title: "Domain & Get Code",
        intro: "Choose your domain, generate embedded code, and easily install it onto your website to render the popup.",
    },  


        
    ]