import React, { useState } from "react";
import { SpinWheelSegment } from "../../spinWheelSegment";
import PrimaryButton from "../../Button1/primarybutton";
import { Actions } from "../../../store";
import { useDispatch } from "react-redux";


export const SpinResultScreen = ({ field, fieldId, templatetype = "", setActiveSubMenu = null ,category}) => {

    const DisPatch = useDispatch()

    const AddSeg = () => {
        const payload = {
            key:fieldId,
            node:'game_mainpage_json',
            child:'segmentValuesArray',
            value:{
                "resultText": "",
                "prizedescription": "",
                "prizetext": "Better luck next time!",
                "probability": "0",
                "slicenumber": 1,
                "type": "string",
                "value": "You lost this time!",
                "win": true,
                "couponStauts":false,
                "couponBgType": "image",
                "couponBgImage": "https://gz-engage-assets.s3.amazonaws.com/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/2648cf55d009d368d474fc49a88957dc.png",
                "couponScratchImage": "https://gz-engage-assets.s3.amazonaws.com/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/ceea686268c4bd7460d07bcacd310e0d.png",        
                "couponBgColor": "#f5f5dc",
                "couponDescription": "You've just claimed your unique coupon code. Please copy your code above."
            } 
        }
        DisPatch(Actions.setWheelSegmentAdd(payload))
    }

    return (

        <div className="relative w-full space-y-4 ">
                     
            {
                field?.segmentValuesArray?.map((list,index) =>{ 
                    return(  <SpinWheelSegment   Index={index} fieldId={fieldId} list={list} totalSeg={field?.segmentValuesArray?.length}  />   )
                })
            }

            {field?.segmentValuesArray?.length<10 && <PrimaryButton text={'Add Segment'} width="w-full" height="h-12" backgroundColor="bg-primary-lightblue" border="border-1 border-primary" textcolor="text-primary" OnClick={AddSeg} /> }

         
        </div>
    )
}