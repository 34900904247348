// const { default: axios } = require("axios"); 

export const element = (name) => { return document.getElementById(name); }
export const classElement = (name) => { return document.getElementsByClassName(name); }
export const allElement = (name) => { return document.querySelectorAll(name); }
export const getDivId = () => { return "_" + Date.now().toString(36) + Math.random().toString(36).substr(2); }

export const isNotEmpty = (string) => {
    if (string !== "" && string !== null && string !== undefined) return true;
    else return false;
}
export const notIncludes = (arr, val) => arr.indexOf(val) === -1;
export const isInArray = (value, array) => { return array.indexOf(value) > -1; }

export const isEqual = (string_1, string_2) => {
    if (isNotEmpty(string_1) && isNotEmpty(string_2)) {
        string_1 = string_1.toString();
        string_2 = string_2.toString();
        if (string_1.toLowerCase() === (string_2).toLowerCase()) return true;
        else return false;
    } else return -1
}


export const isNotEqual = (string_1, string_2) => {
    if (isNotEmpty(string_1) && isNotEmpty(string_2)) {
        string_1 = string_1.toString();
        string_2 = string_2.toString();
        if (string_1.toLowerCase() === (string_2).toLowerCase()) return false;
        else return true;
    } else return true;
}





export const uniqueArray = (a) => {
    var seen = {};
    var out = [];
    var len = a.length;
    var j = 0;
    for (var i = 0; i < len; i++) {
        var item = a[i];
        if (seen[item] !== 1) {
            seen[item] = 1;
            out[j++] = item;
        }
    }
    return removeUndefinedFrmArray(out);
}

export const isEmpty = (string) => {
    if (string === "" || string === null || string === undefined || string === "NaN") return true;
    else return false;
}

export const randomNumber = (min, max) => { return Math.floor(Math.random() * (max - min) + min); }

export const randomChar = () => {
    const charArray = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    return charArray[randomNumber(0, charArray.length)];
}

export const occurrences = (array) => {
    let counts = {};
    for (let num of array) { counts[num] = counts[num] ? counts[num] + 1 : 1; }
    return counts;
}

export const sleep = (ms) => { return new Promise(resolve => setTimeout(resolve, ms)); }

export const mixArray = (array) => { // Fisher–Yates shuffle algorithm to mix the array 
    if (array === undefined || array === null) return []
    let currentPosition = array.length, randomPosition;
    while (currentPosition !== 0) {
        randomPosition = Math.floor(Math.random() * currentPosition);
        currentPosition--;
        [array[currentPosition], array[randomPosition]] = [
            array[randomPosition], array[currentPosition]];
    }
    return array;
}

export const get2DnullMatrix = (row, col, val) => { return new Array(row).fill(val).map(() => new Array(col).fill(val)); }

export const filterJson = (arr, key, string) => {
    if(arr?.length>0)  return arr.filter(obj => obj?.[key] === string);
    else return []
} 

export const removeUndefinedFrmArray = (array) => {
    return array.filter(function (element) { return element !== undefined; });
}

export const removeValFrmArray = (array,value) => {
    if(array?.length>0)  return array.filter(function (element) { return element !== value; });
    else return []
}

export const longestWordLengthInArray = (arr) => {
    let longest = arr.reduce(function (a, b) { return a.length > b.length ? a : b; });
    return longest?.length || 0
}


export const removeSvgTag = (svgId, tagName) => {
    let elm = element(svgId)
    let children = elm.children;
    for (let i = 0; i < children.length;) {
        let el = children[i];
        if (el.tagName === tagName) {
            el.remove();
        } else (i++);
    }
}


export const  fadeIn = (id) => {
    let op = 0.1, elm=element(id)    
    if(elm) {
    elm.style.display = 'block';
    let timer = setInterval(function () {
        if (op >= 1)    clearInterval(timer);
        elm.style.opacity = op;
        elm.style.filter = 'alpha(opacity=' + op * 100 + ")";
        op += op * 0.1;
    }, 10);}
}


export const  fadeOut = (id) => {
    let op = 1, elm=element(id) 
    if(elm) {
    let timer = setInterval(function () {
        if (op <= 0.1){
            clearInterval(timer);
            elm.style.display = 'none';
        }
        elm.style.opacity = op;
        elm.style.filter = 'alpha(opacity=' + op * 100 + ")";
        op -= op * 0.1;
    }, 50);}
}


export const show = (id) =>{  element(id).style.display="block" }
export const hide = (id) =>{  element(id).style.display="none" }

export const percentageToVal = (tolalValue, percentage) => {  return (percentage / 100) * tolalValue }

export const hasWhiteSpace = (s)=> { return s.indexOf(' ') >= 0; }


export const filterFromArray = (input, array) => {
    try {
        let returnArray = [], i;
        input = input?.toUpperCase();
        for (i = 0; i < array.length; i++) {
            if (array[i]?.toUpperCase().indexOf(input) > -1) returnArray.push(array[i])
        }
        return returnArray
    } catch (e) {
        return []
    }
}


export const arrayCaptilize =(array) =>{
    let returnArray=[]
    for (let word of array) {
        word = word.charAt(0).toUpperCase() + word.substr(1);
        returnArray.push(word)
    }
    return returnArray;
}

export function capitalizeFirstLetter(string) {
    if(isEmpty(string)) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
}


export const isTrue = (string) => {
    if(isEmpty(string)) return false
    if (typeof string == "boolean") return string
    string=string?.toLowerCase()
    if(string==="true") return true
    else return false
  }

// export const getImageBase64 = (imageUrl, width, height)=>{
//     return new Promise(async (resolve, reject) => {
//         try {
//             axios.get(imageUrl, { responseType: "blob" }).then(function (response) {
//                 var reader = new window.FileReader();
//                 reader.readAsDataURL(response.data);
//                 reader.onload = function () {
//                     let image = new Image(); 
//                     image.crossOrigin = "anonymous";
//                     image.onload = (async () => {
//                         let canvas = document.createElement('canvas'), ctx = canvas.getContext('2d');
//                         canvas.width = width; canvas.height = height;
//                         ctx.drawImage(image, 0, 0, width, height); 
//                         resolve(canvas.toDataURL('image/png'))
//                     });
//                     image.src = reader.result;
//                 }
//             });
//         } catch (err) {
//             console.log(err);
//             reject(err);
//         }
//     })
// }

export function trimCommas(inputString) {
    // Remove leading commas
    inputString = inputString.replace(/^,+/g, '');
  
    // Remove trailing commas
    inputString = inputString.replace(/,+$/g, '');
  
    return inputString;
  }



export const TestBase = () =>{

  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };
  
  fetch("https://asserts.engage.gozen.io/v1/users/assets/0e0f5896-fb65-4195-a5cf-2d5895ef2066/image/5583dfaff97fa6c39340446a2db141a8.jpg", requestOptions)
    .then(response => response.blob())
    .then(result => {
      // console.log(result)
    })
    .catch(error => console.log('error', error));

}
export const getImageBase64 = (imageUrl, width, height)=>{
    return new Promise(async (resolve,reject)=>{
        try{
          let params= {  method: 'GET',  headers: { "Cache-Control": 'no-cache' } };
          fetch(imageUrl,params).then( response => response.blob() ).then( blob =>{
            var reader = new FileReader() ;
            reader.onload = function(){
              let image = new Image(); 
              image.crossOrigin = "anonymous";
              image.onload = (async () => {
                let canvas = document.createElement('canvas'), ctx = canvas.getContext('2d');
                canvas.width = width; canvas.height = height;
                ctx.drawImage(image, 0, 0, width, height); 
                resolve(canvas.toDataURL('image/png'))
              });
              image.src = reader.result;
            } ; 
            reader.readAsDataURL(blob) ;
          }) ;
        } catch (err) {
        reject(err);
        } 
    })
  }

export const getImageOrignalSize = (imageUrl) =>
  new Promise(async (resolve,reject)=>{
     try{
       let params= {  method: 'GET',  headers: { "Cache-Control": 'no-cache' } }
       fetch(imageUrl,params).then( response => response.blob() ).then( blob =>{
         var reader = new FileReader() ;
         reader.onload = function(){
           let image = new Image(); 
           image.crossOrigin = "anonymous";
           image.onload = (async () => {
            resolve([image.naturalWidth,image.naturalHeight])
           });
           image.src = reader.result;
         } ; 
         reader.readAsDataURL(blob) ;
       }) ;
     } catch (err) {
     reject(err);
     } 
 })
 
  export const getSymbolToTxt = (symbol) =>{
    let symbolResult="";
    switch(symbol){
        case ">": symbolResult="graterthen"; break;
        case "<": symbolResult="lessthen"; break;
        case "=": symbolResult="equal to"; break;
        default: symbolResult=symbol; break;
    } 
    return symbolResult
}

export const onImageError = (e) => { e.target.style.display="none";  }

export function strToInt (mixedString) {
    if(isEmpty(mixedString))return 0;
        const numbers = String(mixedString).match(/[\d.]+/g);
        if (numbers) {
            const concatenatedInt = parseFloat(numbers.join(''), 10);
            return concatenatedInt
        } else {
            return 0 
        }
  }

  export function findMaxCountElementsIn2DArray(array2D) {
    const flattenedArray = array2D.flat();
    const counts = {};
  
    // Count the occurrences of each element in the flattened array
    flattenedArray.forEach(element => {
      counts[element] = (counts[element] || 0) + 1;
    });
  
    let maxCount = -1;
  
    // Find the maximum count
    for (const element in counts) {
      if (counts[element] > maxCount) {
        maxCount = counts[element];
      }
    }
  
    // Find elements with the maximum count
    const maxElements = Object.keys(counts).filter(element => counts[element] === maxCount);
  
    return maxElements;
  }
  
  export function is2DArrayEmpty(arr) {
    if (Array.isArray(arr) && arr.length > 0) {
      for (const subArray of arr) {
        if (Array.isArray(subArray) && subArray.length > 0) return false; 
      }
      return true; 
    }
    return true; 
  }
  

  export function getVisiblePercentage(element) {
    var rect = element.getBoundingClientRect();
    var windowHeight = window.innerHeight || document.documentElement.clientHeight;
    var visibleHeight = Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);
    return ((visibleHeight / rect.height) * 100) || 0;
}


export function getUniqueObjects(array) {
    const uniqueObjects = array.filter((obj, index, self) => {
      const stringifiedObj = JSON.stringify(obj);
      return index === self.findIndex((o) => JSON.stringify(o) === stringifiedObj);
    });
  
    return uniqueObjects;
  }



  export function isValidUrl(url) {
    var urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;
    return urlPattern.test(url);
  }
  