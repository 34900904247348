import React, { useEffect, useRef } from "react";
import PrimaryButton from "../Button1/primarybutton";
import Input from "../input/input";
import { Clickouter } from "../../utils/clickouter";
import { CgSpinner } from "react-icons/cg";


export const ProjectCreate = ({
    title = '',
    defaultVaule = '',
    onProfile=null,
    defaultSrc,
    loadingLive=false,
    project_id,
    projectData,
    type = '',
    changeDefaultVaule = null,
    forwardAction = null,
    backwardAction = null,
    className = "",
}) => {

    const menuREf = useRef(null);

    useEffect(() => {

        let handleAction = (event) => {

            if (menuREf.current && !menuREf.current?.contains(event.target)) {
                backwardAction(false);
            }
        };

        document.addEventListener("mousedown", handleAction);

    }, [])

    return (
        <div className="fixed top-0 left-0 flex w-full h-full bg-backgroundShadow" style={{ zIndex: '75' }}>
            <div className={`p-4 w-[450px] rounded-lg bg-white m-auto space-y-4 ${className} `} ref={menuREf} >

                <p className="capitalize text-lg font-medium">{title}</p>
                <div className="flex flex-col space-y-2" >
                    <label className="capitalize font-medium" >Project icon</label>
                    <div className="w-full h-[175px] relative group hover:shadow-md" >

                        <img src={ (defaultSrc && defaultSrc !== "" ) ? defaultSrc : 'https://gz-engage-assets.s3.amazonaws.com/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/c7a655060994272bcf9a99a5afde2eaf.jpg'} className="w-full h-full object-fill rounded-lg" alt="" />
                        
                        <div className="absolute transition-all duration-400 ease-in-out top-0 flex flex-col justify-center left-0 h-full w-full rounded-lg bg-black/60 opacity-0 group-hover:opacity-100" style={{ zIndex: "150" }} >

                            <PrimaryButton id="change-profile" text={"change profile"} buttontype={'defaultbutton'} backgroundColor={"bg-white"} textcolor={"text-black"} padding={"py-6"} height={"h-14"} width={"w-1/2"} margin={"mx-auto mb-2"} OnClick={() =>  onProfile(true)  } />

                        </div>
                    </div>

                </div>
                <div className="flex flex-col space-y-2" >
                    <label className="capitalize font-medium" >Project name</label>
                    <Input textvalue={defaultVaule} onchange={(e) => changeDefaultVaule(e.target.value)} />
                </div>

                <div className="flex justify-end space-x-2">
                    <PrimaryButton buttontype={'secondarybutton'} width={'w-24'} text={'cancel'} OnClick={() => backwardAction(false)} />
                    <PrimaryButton icon={
                        loadingLive ? <CgSpinner className="w-5 h-5 animate-spin text-white"  /> : null
                    }  text={'Done'} disabled={loadingLive} width={'w-24'} OnClick={forwardAction} />
                </div>

            </div>
        </div>
    )
}