export var threeScreenEditTab_flow =[

    {
        element: ".step-edit-startTab",
        title: "Start Screen",
        intro: "On this start screen, you can include a header, description, cover image, button text, and button colour.",
    },
    {
        element: ".step-edit-questionTab",
        title: "Question Screen",
        intro: "On the question screen, effortlessly add limitless questions and options, toggle the required setting as needed, and include a cover image for enhanced customization.",
    },   
     {
        element: ".step-edit-thankTab",
        title: "Tailor the result screen",
        intro: "Setting header/description, upload a <3MB cover image, add restart/coupon login and define button text/URL",
    }
    
]

export var twoScreenEditTab_flow =[
    {
        element: ".step-edit-questionTab",
        title: "Wheel Design",
        intro: "Customise wheel design with a cover image (under 3MB) segment font size, and personalise spin wheel colours, including Primary/Secondary Colors, Primary/Secondary Text Colors, Peg, and Stroke Color.",
    } ,
     {
        element: ".step-edit-wheelSeg",
        title: "Result Settings",
        intro: "In result settings, create unlimited segments with text or image, including segment text, discount code, win toggle, and features like Probability, result title, and scratch card.",
    }

    
]

export var calFormulaBtn_flow =[
     {
        element: ".step-edit-calFormulaBtn",
        title: "Calculation Formulas",
        intro: "Add results with headers, descriptions, cover images, button text, links, and calculation formulas.",
    }


]


export var logicJumpBtn_flow =[
    {
       element: ".step-edit-logicJumpBtn",
       title: "Logic jump",
       intro: "Logic jump lets you seamlessly navigate between questions based on conditions like equals, not equals, contains, or doesn't contain, with added options for logical conjunctions (and) and disjunctions (or).",
   }


]



export var filterBtn_flow =[
    {
       element: ".step-edit-filterBtn",
       title: "Filter Product",
       intro: "The filter product field in ecommerce recommendation allows users to refine product suggestions based on specific criteria, enhancing personalized shopping experiences.",
   }


]




