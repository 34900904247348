import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditorTooltip } from "../Editor/common/editortooltip";   
import { StringToHtml } from "../StringToHtml";
import { Actions } from "../../store";
import Iframe from "react-iframe";

export const VideorenderBlock = ({ fieldID = '', position ,priview=false }) => {

    const { Fields } = useSelector((state)=>({Fields:state.fields}));
    const DisPatch = useDispatch()

    const VideoContent = Fields[fieldID]; 
    const filedContent = VideoContent?.filedContent
   

    return (
        <div id={"gameFrameBorder"+fieldID}  onClick={()=>{DisPatch(Actions.setActiveFieldside({filedId:fieldID,index:position,}))}} className={`relative flex flex-col py-4 group `}>
            {!priview &&<EditorTooltip position={position}/>}
           

            <div className="flex w-full h-auto max-w-full mx-auto py-4" > 
                {filedContent?.VideoUrl !== "" && <Iframe className="w-full h-auto min-h-[460px]" style={{width:"100%"}} autoPlay={true} title="youTube_Embed"   src={"https://www.youtube.com/embed/"+filedContent?.VideoUrl} >
                </Iframe>  }            
            </div>
        </div>

    )
}