import { Steps } from "intro.js-react";
import { useEffect, useState } from "react";
import 'intro.js/introjs.css';
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../store";
import { TUTORIAL_STORAGE, checkAndGetTutorial } from "../../utils/tutorialStorage";
import { useSelectionAction } from "../../pages/customstate/context";
import { getUniqueObjects, isEqual, isNotEmpty } from "../../utils/helpers";
import { getTutorialFlow, isTopNave } from "../../content/tutorial_flow";
import { complete_apptour } from "../../actions/user";


export const Tutorial = ({page=""}) => {

  

    const { completedTutorial, engage_apptour, isEcommerceFields  } = useSelector((state) => ({ completedTutorial: state.completedTutorial,  engage_apptour:state.engage_apptour, isEcommerceFields:state.isEcommerceFields }))
    const { campaignEdit,generateAiBtn, dashboard,templatePreview, templateGalary, logicJumpBtn, filterBtn, calFormulaBtn, threeScreenEditTab, twoScreenEditTab } = completedTutorial || {};  
    const { topnave, product, design,share } = campaignEdit || {};
    let { overAll, device, backGroundImage,  addNewField, editDesignBtn} = design
    const [scrollToElement, setscrollToElement] = useState(true)
   

    function generateBtnExist(){
        let url = window.location.search
        const quertData = new URLSearchParams(url)
        const categoryState = quertData.get('category') ?? 'games'
        const categorylist = ['quizzes', 'ecommerce', 'calculater', 'poll', 'surveys']
        let isExist = categorylist.findIndex(list => list === categoryState)
        return  isExist >= 0
    }

    useEffect(()=>{
        if(engage_apptour && completedTutorial?.dashboard===true  ){ 
            complete_apptour()
        }
     },[completedTutorial, engage_apptour])
 
    const Dispatch = useDispatch()

    const [curSteps, setCurSteps] = useState([])
    const [toshow, setToShow] = useState(false)
    const { editerstate,  } = useSelectionAction()

    function setNewSteps(temp){
        setTimeout(()=>{
            setCurSteps(getUniqueObjects(temp))
        },1300)
    }


// ************** WHILE LOADING THE TOUR ****************
  useEffect(()=>{
    setscrollToElement(true) 
    setToShow(false)

    setTimeout(()=>{

    switch (page){
        case "dashboard": 
            if(isNotEmpty(dashboard) && dashboard !== true){
                 setNewSteps(getTutorialFlow("dashboard")) 
                 setToShow(true)
            }
        break;

        case "generateAiBtn":
            if(isNotEmpty(generateAiBtn) && generateAiBtn !== true){
                 setNewSteps(getTutorialFlow("generateAiBtn"))
                 setscrollToElement(false) 
                 setToShow(true)
            }
        break;

        case "templateGalary":
            let galJson=[];
            if(isNotEmpty(templateGalary) && templateGalary !== true){
                galJson=getTutorialFlow("templateGalary")
            }

            if(isNotEmpty(generateAiBtn) && generateAiBtn !== true && generateBtnExist()){
                galJson=[...galJson,{
                    element: ".step-tempGal-generateAiBtn",
                    title: "AI Generator",
                    intro: "Add/select products, including manual ones, and customise display options for product placement (left/right).",
                } ]   
            }


            if(galJson?.length>0){
                setNewSteps(galJson) 
                setToShow(true)
            }else {
                setNewSteps([])
                setToShow(false)
            }

        break;

        case "templatePreview":
            if(isNotEmpty(templatePreview) && templatePreview !== true){
                setNewSteps(getTutorialFlow("templatePreview"))
                setToShow(true)
            }
        break;

        case "share":
            if( editerstate==="share" && isNotEmpty(share) && share !== true){
                setNewSteps(getTutorialFlow("share"))
                setToShow(true)
            }
        break;
        
        
        case "threeScreenEditTab":
            if(isNotEmpty(threeScreenEditTab) && threeScreenEditTab !== true){
                 setNewSteps(getTutorialFlow("threeScreenEditTab")) 
                 setToShow(true)
            }
        break;    

        case "twoScreenEditTab":
        if(isNotEmpty(twoScreenEditTab) && twoScreenEditTab !== true){
             setNewSteps(getTutorialFlow("twoScreenEditTab")) 
             setToShow(true)
        }
        break;

        case "calFormulaBtn":
        if(isNotEmpty(calFormulaBtn) && calFormulaBtn !== true){
             setNewSteps(getTutorialFlow("calFormulaBtn")) 
             setToShow(true)
        }
        break;

        case "logicJumpBtn":
        if(isNotEmpty(logicJumpBtn) && logicJumpBtn !== true){
             setNewSteps(getTutorialFlow("logicJumpBtn")) 
             setToShow(true)
        }
        break;

        case "filterBtn":
        if(isNotEmpty(filterBtn) && filterBtn !== true){
             setNewSteps(getTutorialFlow("filterBtn")) 
             setToShow(true)
        }
        break;
 


        

        case "campaignEdit":

            let curArr=[], tempToShow=false

            if((overAll!==true && topnave!==true && editerstate==="design" )  || (topnave!==true && editerstate!=="share" )){
                curArr=[...curArr,...getTutorialFlow("topnave")]
                tempToShow=true
            }

            if(topnave!==true && isEcommerceFields && editerstate!=="share"){
                curArr=[ {
                    element: ".step-tab-product",
                    title: "Product",
                    intro: "Add/select products, including manual ones, and customise display options for product placement (left/right).",
                },...curArr] 
                tempToShow=true
            }

            if(editerstate!=="integration" && editerstate!=="result" && editerstate!=="share") {
                
                    if(product !==true  && ( editerstate==="product")  ){         
                            curArr=[...curArr,...getTutorialFlow("product")]
                            tempToShow=true
                        } 

                        if(  overAll!==true && editerstate==="design"){
                                if(device!==true) { curArr=[...curArr,...getTutorialFlow("device")];  tempToShow=true}
                                // if(backGroundImage!==true) { curArr=[...curArr,...getTutorialFlow("backGroundImage")];  tempToShow=true}
                                // if(editDesignBtn!==true){ curArr=[...curArr,...getTutorialFlow("editDesignBtn")];  tempToShow=true}
                                if(addNewField!==true) { curArr=[...curArr,...getTutorialFlow("addNewField")];  tempToShow=true}

                        }
                } 

            setNewSteps(curArr)
            setToShow(tempToShow)    
        break;

        default:
            setNewSteps([]) 
            setToShow(false)

    }},50)
    },[page, editerstate])
 




// ****************** WHILE EXIT THE TOUR ************************

    const onExit = async (e) => {
        if(e<=-1   ) return
        let temp = Object.assign({}, completedTutorial)
    
        
        switch (page){
            case "dashboard":                
                temp["dashboard"]=true
                saveData(temp)
                setToShow(false)
            break;
            
            case "generateAiBtn":
                temp["generateAiBtn"]=true

                saveData(temp)
                setToShow(false)
            break;

            case "templateGalary":
                temp["templateGalary"]=true

            if(generateAiBtn!==true && generateBtnExist()){
                temp["generateAiBtn"]=true
            }
                saveData(temp)
                setToShow(false)
            break;

            case "templatePreview":
                temp["templatePreview"]=true
                saveData(temp)
                setToShow(false)
            break;

               
        case "threeScreenEditTab":
            temp["threeScreenEditTab"]=true
            saveData(temp)
            setToShow(false)
        break;    

        case "twoScreenEditTab":
            temp["twoScreenEditTab"]=true
            saveData(temp)
            setToShow(false)
        break;


        case "calFormulaBtn":
            temp["calFormulaBtn"]=true
            saveData(temp)
            setToShow(false)
        break;

        case "logicJumpBtn":
            temp["logicJumpBtn"]=true
            saveData(temp)
            setToShow(false)
        break;


        case "filterBtn":
            temp["filterBtn"]=true
            saveData(temp)
            setToShow(false)
        break;
        
        

            case "share":
                temp = {
                    ...temp,
                    "campaignEdit":{
                        ...temp["campaignEdit"],
                        "share":true
                    }
                }
                saveData(temp)
                setToShow(false)
            break;

            case "campaignEdit":
                let curTempStep =curSteps,elm=curTempStep[e]?.element

                if(isTopNave(elm)){
                    temp = {
                        ...temp,
                        "campaignEdit":{
                        ...temp["campaignEdit"],
                            "topnave":true
                        }
                    }
                    saveData(temp)  
                }

                if( product!==true && isEcommerceFields && editerstate==="product"){
                    temp = {
                        ...temp,
                        "campaignEdit":{
                        ...temp["campaignEdit"],
                            "product":true
                        }
                    }


                    saveData(temp)
                    setToShow(false)
                } 


                if( editerstate==="design"){
                    temp = {
                        ...temp,
                        "campaignEdit":{
                        ...temp["campaignEdit"],
                            "design":{
                            ...temp["campaignEdit"]["design"],
                                "overAll":true
                            }
                        }
                    }
                    saveData(temp)
                    setToShow(false)   
                }
            break;

            default:
                if(e>=0) onChange(e)
                return;

        }



// if(engage_apptour && isAllTourCompleted()){ 
    // await complete_apptour()
    // Dispatch(Actions.setengage_apptour(false))
// }
 
    };




    // ******************* WHILE CLICKING THE NEXT TOUR *************
const onChange = (e) =>{
    if(e<=-1   ) return
    let curTempStep =curSteps, elm=curTempStep[e]?.element
    let temp = Object.assign({}, completedTutorial)


    if(isEqual(elm,".step-tab-publishBtn")){
        temp = {
            ...temp,
            "campaignEdit":{
            ...temp["campaignEdit"],
                "topnave":true
            }
        }
        saveData(temp)  
    }
    if(isEqual(elm, ".step-design-device")){
        temp = {
            ...temp,
            "campaignEdit":{
            ...temp["campaignEdit"],
                "design":{
                    ...temp["campaignEdit"]["design"],
                    "device":true
                }
            }
        }
        saveData(temp)  
    }
    if(isEqual(elm, ".step-design-backGroundImage")){
        temp = {
            ...temp,
            "campaignEdit":{
            ...temp["campaignEdit"],
                "design":{
                    ...temp["campaignEdit"]["design"],
                    "backGroundImage":true
                }
            }
        }
        saveData(temp)  
    }
    if(isEqual(elm, ".step-design-addNewField")){
        temp = {
            ...temp,
            "campaignEdit":{
            ...temp["campaignEdit"],
                "design":{
                    ...temp["campaignEdit"]["design"],
                    "addNewField":true
                }
            }
        }
        saveData(temp) 
    }
    if(isEqual(elm, ".step-design-editDesignBtn")){
        temp = {
            ...temp,
            "campaignEdit":{
            ...temp["campaignEdit"],
                "design":{
                    ...temp["campaignEdit"]["design"],
                    "editDesignBtn":true
                }
            }
        }
        saveData(temp)
    }

}




    function saveData(temp){
    Dispatch(Actions.setcompletedTutorial({ data:temp}))
    localStorage.setItem(TUTORIAL_STORAGE, JSON.stringify(temp))
    }


    return (
        <Steps
            enabled={toshow}
            steps={curSteps}
            initialStep={0}
            onComplete={onExit }
            onExit={onExit}
            onChange={onChange}
            scrollToElement={scrollToElement}
            options={
                {scrollToElement:scrollToElement}
            }
            
        />
    );
};