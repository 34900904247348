import toast from "react-hot-toast";
import axios from "../axios";


export const GetAllIntergations = async (campaignId) => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.get(`/integrations/list?campaign_id=${campaignId}`);
            
            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")

            reject(err);
        }
    })
}

export const getOauthURL = async ( campaignId, mailer ) => {

    return new Promise( async ( resolve, reject ) =>{

        try{

            const res = await axios.get(`/integration/v2/oauth?campaign_id${campaignId}&mailer=${mailer}`);
            
            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")

            reject(err);
        }
    

    })

}

export const CreateOauthToken = async ( campaignId , mailer, code ) => {

    return new Promise( async ( resolve, reject ) =>{

        try{

            const res = await axios.post(`/integration/v2/oauth/gettoken?campaign_id${campaignId}&mailer=${mailer}`, { code });
            
            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")

            reject(err);
        }
    

    })

}

export const CreateOauthList = async ( campaignId, mailer, payload ) => {

    return new Promise( async ( resolve, reject ) =>{

        try{

            const res = await axios.post(`/integration/v2/oauth/create?campaign_id${campaignId}&mailer=${mailer}`, { integration_data: payload });
            
            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")

            reject(err);
        }
    

    })

}

export const getIntegrationByChennal = async (campaignId,mailer) => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.get(`/integration?campaign_id=${campaignId}&mailer=${mailer}`);
            
            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")

            reject(err);
        }
    })
}

export const getIntegrationByChennalv2 = async (campaignId,mailer) => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.get(`/integration/v2/get?campaign_id=${campaignId}&mailer=${mailer}`);
            
            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")

            reject(err);
        }
    })
}

export const DeleteIntegration = async (campaignId,mailer) => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.delete(`/integration/?campaign_id=${campaignId}&mailer=${mailer}`);
            
            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")

            reject(err);
        }
    })
}

export const createIntegration = async (campaignId,payload) => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.post(`/integration/create?campaign_id=${campaignId}`,payload);
            
            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")

            reject(err);
        }
    })
}

export const createIntegrationv2 = async (campaignId,payload) => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.post(`/integration/v2/create?campaign_id=${campaignId}`,payload);
            
            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")

            reject(err);
        }
    })
}