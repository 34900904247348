import React, { useEffect } from "react";
import  { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../store";


export const OauthCallBackPage = () => {


    const { mailer } =  useParams();
    const dispatch =  useDispatch()
    

    const code  = new URLSearchParams(window.location.search).get("code");


    useEffect( () => {

        // eslint-disable-next-line default-case
        switch( mailer ){

            case 'googlesheet':

                localStorage.setItem("googleSheet_code",code)

                break;
        
        }

        dispatch(Actions.triggerOauthStatus({ mailer, code,status: "success" }))

    return window.top.close()

    } )


    return (
        <div>HI There.</div>
    )

}