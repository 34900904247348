export const IndustryTypesData = () => [
    'abrasives and nonmetallic minerals manufacturing',
    'accessible architecture and design',
    'accessible hardware manufacturing',
    'accounting',
    'administration of justice',
    'advertising services',
    'air, water, and waste program management',
    'alternative dispute resolution',
    'alternative fuel vehicle manufacturing',
    'appliances, electrical, and electronics manufacturing',
    'architecture and planning',
    'armed forces',
    'artists and writers',
    'audio and video equipment manufacturing',
    'aviation and aerospace component manufacturing',
    'banking',
    'bars, taverns, and nightclubs',
    'bed-and-breakfasts, hostels, homestays',
    'beverage manufacturing',
    'biotechnology research',
    'blockchain services',
    'blogs',
    'book and periodical publishing',
    'broadcast media production and distribution',
    'business consulting and services',
    'business content',
    'business intelligence platforms',
    'chemical manufacturing',
    'civic and social organizations',
    'civil engineering',
    'climate data and analytics',
    'climate technology product manufacturing',
    'community development and urban planning',
    'computer and network security',
    'computer games',
    'computer hardware manufacturing',
    'computer networking products',
    'computers and electronics manufacturing',
    'construction',
    'consumer goods rental',
    'consumer services',
    'cosmetology and barber schools',
    'courts of law',
    'dairy product manufacturing',
    'data infrastructure and analytics',
    'data security software products',
    'defense and space manufacturing',
    'design services',
    'desktop computing software products',
    'digital accessibility services',
    'e-learning providers',
    'education administration programs',
    'entertainment providers',
    'environmental quality programs',
    'environmental services',
    'events services',
    'executive offices',
    'facilities services',
    'farming',
    'financial services',
    'fisheries',
    'food and beverage manufacturing',
    'food and beverage services',
    'footwear and leather goods repair',
    'freight and package transportation',
    'fruit and vegetable preserves manufacturing',
    'fuel cell manufacturing',
    'fundraising',
    'furniture and home furnishings manufacturing',
    'gambling facilities and casinos',
    'glass, ceramics and concrete manufacturing',
    'golf courses and country clubs',
    'government administration',
    'government relations services',
    'graphic design',
    'ground passenger transportation',
    'health and human services',
    'higher education',
    'historical sites',
    'home health care services',
    'hospitality',
    'hospitals',
    'hospitals and health care',
    'hotels and motels',
    'household services',
    'housing and community development',
    'housing programs',
    'human resources services',
    'individual and family services',
    'industrial machinery manufacturing',
    'information services',
    'insurance',
    'insurance and employee benefit funds',
    'interior design',
    'international affairs',
    'international trade and development',
    'internet news',
    'internet publishing',
    'investment banking',
    'investment management',
    'it services and it consulting',
    'it system custom software development',
    'it system data services',
    'it system installation and disposal',
    'it system operations and maintenance',
    'it system testing and evaluation',
    'it system training and support',
    'janitorial services',
    'landscaping services',
    'language schools',
    'laundry and drycleaning services',
    'law enforcement',
    'law practice',
    'leasing non-residential real estate',
    'leasing residential real estate',
    'legal services',
    'legislative offices',
    'libraries',
    'loan brokers',
    'machinery manufacturing',
    'manufacturing',
    'maritime transportation',
    'market research',
    'media and telecommunications',
    'media production',
    'medical and diagnostic laboratories',
    'medical equipment manufacturing',
    'medical practices',
    'mental health care',
    'metal valve, ball, and roller manufacturing',
    'mining',
    'mobile gaming apps',
    'motor vehicle manufacturing',
    'motor vehicle parts manufacturing',
    'movies, videos, and sound',
    'museums, historical sites, and zoos',
    'musicians',
    'nanotechnology research',
    'natural gas distribution',
    'natural gas extraction',
    'newspaper publishing',
    'non-profit organizations',
    'nonmetallic mineral mining',
    'nonresidential building construction',
    'nuclear electric power generation',
    'nursing homes and residential care facilities',
    'office administration',
    'oil and gas',
    'oil extraction',
    'online audio and video media',
    'operations consulting',
    'optometrists',
    'outpatient care centers',
    'outsourcing and offshoring consulting',
    'packaging and containers manufacturing',
    'paper and forest product manufacturing',
    'personal and laundry services',
    'personal care product manufacturing',
    'pharmaceutical manufacturing',
    'philanthropic fundraising services',
    'photography',
    'physical, occupational and speech therapists',
    'political organizations',
    'primary and secondary education',
    'printing services',
    'professional organizations',
    'professional training and coaching',
    'public health',
    'public policy offices',
    'public relations and communications services',
    'ranching',
    'real estate',
    'real estate agents and brokers',
    'recreational facilities',
    'regenerative design',
    'religious institutions',
    'renewable energy power generation',
    'renewable energy semiconductor manufacturing',
    'research services',
    'restaurants',
    'retail',
    'retail apparel and fashion',
    'retail art dealers',
    'retail art supplies',
    'retail books and printed news',
    'retail groceries',
    'retail luxury goods and jewelry',
    'retail motor vehicles',
    'retail office equipment',
    'retail recyclable materials & used merchandise',
    'security and investigations',
    'security guards and patrol services',
    'services for renewable energy',
    'shuttles and special needs transportation services',
    'social networking platforms',
    'software development',
    'space research and technology',
    'spectator sports',
    'sporting goods manufacturing',
    'staffing and recruiting',
    'taxi and limousine services',
    'technical and vocational training',
    'technology, information and internet',
    'telecommunications',
    'textile manufacturing',
    'think tanks',
    'tobacco manufacturing',
    'translation and localization',
    'transportation programs',
    'transportation, logistics, supply chain and storage',
    'travel arrangements',
    'truck transportation',
    'urban transit services',
    'utilities',
    'utilities administration',
    'utility system construction',
    'vehicle repair and maintenance',
    'venture capital and private equity principals',
    'veterinary services',
    'vocational rehabilitation services',
    'warehousing and storage',
    'waste collection',
    'waste treatment and disposal',
    'wellness and fitness services',
    'wholesale',
    'wholesale alcoholic beverages',
    'wholesale apparel and sewing supplies',
    'wholesale building materials',
    'wholesale chemical and allied products',
    'wholesale food and beverage',
    'wholesale footwear',
    'wholesale import and export',
    'wholesale luxury goods and jewelry',
    'wholesale motor vehicles and parts',
    'wholesale petroleum and petroleum products',
    'wholesale raw farm products',
    'wind electric power generation',
    'wineries',
    'wireless services',
    'writing and editing',
    'zoos and botanical gardens',
];
