import React from "react";
import InputWithLabel from "../input/inputWithLabel";
import PrimaryButton from "../Button1/primarybutton";
import { CampaignEvents } from "../../actions/campainEvent";
import { useSelector } from "react-redux";
import TextareaInput from "../input/textarea";
import CheckBox from "../input/checkbox";
import DefaultDropMenu from "../input/droplist";
import { isNotEmpty, trimCommas } from "../../utils/helpers";


export const LeadFormBlock = ({ Fields = {}, submit = null, device }) => {

    const { Campaign_info, userInfo } = useSelector((state) => ({ Campaign_info: state.Campaign_info, userInfo: state.userDetail }))

    let formobject = {}

    const OnSSubmitForm = async (e) => {

        e.preventDefault()

        // const CampaignEvent = new CampaignEvents({ campaignId: Campaign_info.Campaign_id, site_id: userInfo.primary_Site_id })
        // await CampaignEvent.subcripe(formobject, encodeURIComponent(e))

        submit()
    }

    const Onchange = (e, name) => {

        let lowercasename = String(name).toLocaleLowerCase()

        formobject[lowercasename] = e.target.value;

    }
    const onSelect = (val,name) => {

        let lowercasename = String(name).toLocaleLowerCase()
  
        formobject[lowercasename] = val;
        
    }  

    return (



        <div className="absolute overflow-auto flex left-0 h-full top-0   w-full  z-[2] " style={{ background: `rgba(250,250,250,0.4)` }}>

            <div className={`
            ${(device==="mobile"? "w-[80%] " : "w-[55%]")}
            max-w-[500px]
            h-fit flex flex-col bg-white rounded-2xl mx-auto px-8 py-10 space-y-6 my-auto justify-center shadow-lg border-2`}>
                <div className="w-full space-y-4 flex flex-col">
                    {
                        (Fields?.imageStatus !== "" && Fields?.imageSrc !== "" ) && <div className="w-fit mx-auto max-w-full">
                            <img src={Fields?.imageSrc} alt="WinnigPrice" className="w-auto" />
                        </div>
                    }
                    {Fields?.titleStatus && <p className="w-fit text-xl font-semibold mx-auto capitalize"  style={{color:Fields?.titleColor || "#000000"}}>{Fields?.title}</p>}
                </div>
                <form className="flex w-4/5 mx-auto flex-col space-y-4" onSubmit={OnSSubmitForm} >
                    {
                        Fields?.field?.map((list,key) => {

                            let opts={}
                            if(list?.required) opts["required"]="required"

                            let tempList=[], textAreaVal=list?.textAreaVal
                            if(isNotEmpty(textAreaVal) && textAreaVal.includes(",")){
                                let temp=textAreaVal.split(",")
                                temp?.map((val)=>{
                                    val=val.trim()
                                    val=trimCommas(val)
                                    tempList.push(val)
                                })
                            }
                            

                            return <>
                            { list?.inputType === 'checkbox'  ?
                            <CheckBox lableColor={Fields?.formTitleColor} key={"InputWithLabel_"+(key+=1)} Placeholder={list?.Label} label={list?.Label} name={list?.Label} onchange={(e)=>Onchange(e,list?.Label)} type={list?.inputType}   {...opts} isUrl={isNotEmpty(list?.checkBoxRedirUrl)} redirUrl={list?.checkBoxRedirUrl}/> :
                            list?.inputType === 'textarea' ?
                            <TextareaInput lableColor={Fields?.formTitleColor} key={"InputWithLabel_"+(key+=1)} Placeholder={list?.Label} label={list?.Label} name={list?.Label} onchange={(e)=>Onchange(e,list?.Label)} type={list?.inputType}   {...opts} /> :
                            list?.inputType ==="select" && tempList?.length>0?
                            <DefaultDropMenu lableColor={Fields?.formTitleColor} label={list?.Label} margin="mb-0 caret-transparent"
                            list={tempList}
                            SelectIndex={true}
                            onchange={(index) => onSelect(tempList[index],list?.Label)}
                          />
                          
                          
                          
                          
                          
                          :
                            <InputWithLabel lableColor={Fields?.formTitleColor}  key={"InputWithLabel_"+(key+=1)} Placeholder={list?.Label} label={list?.Label} name={list?.Label} onchange={(e)=>Onchange(e,list?.Label)} type={list?.inputType}   {...opts} /> 
                        
                            
                            }

                            </>
                        })
                    }
                    <PrimaryButton style={{background:(Fields?.btnBgColor || "#2563EB"), color:(Fields?.btnTxtColor || "#FFFFFF")}} text={Fields?.buttenText} type="submit"  />
                </form>
            </div>
        </div>
    )
}