import React, { useEffect, useState } from  "react"
import { FloatBarCategroyIcon } from "../content/floaatbar"

export const FloatTemplateHeader = ({
    ShowHeader=false,
    category=[],
    changeactive=null,
    active=0
}) => {
    const [zIndex,setzIndex] = useState(-1000)
    const [blockHeight,setBlockHeight] = useState(205)
    const TemplateIcon = FloatBarCategroyIcon(active)
    useEffect(()=>{
        
        const height = document.getElementById('floattempblock').clientHeight
        setBlockHeight(height)
        if(ShowHeader) setzIndex(1000)
        else setzIndex(-1000)
    },[ShowHeader])
    return(
        <div className={`transition-all delay-200 duration-900  right-0 absolute w-full bg-white ${ShowHeader ? "opacity-100 top-0" :`opacity-100 top-[-220px]`}`} style={{zIndex:'9'}}  >
            <div className={`transition-all space-y-4 duration-900  fixed px-4 py-4 border-b-2 w-full  bg-white `} id="floattempblock" >
                <div className="flex">
                    <h2 className="mb-4px font-bold text-22px text-primary-bold  capitalize mr-4">Templates</h2>
                </div>
                <div className="flex space-x-4">
                    {
                        category.map((list,index) => {
                            return(
                                <p className={`transition-all flex duration-500 space-x-2 justify-center py-2 px-3 border-2 w-auto rounded-lg min-w-[150px] font-medium cursor-pointer hover:shadow-lg text-center ${active == index ? "text-primary border-primary":""}`} onClick={()=>changeactive(index)}>
                                    <span>
                                    {TemplateIcon[index].icon}

                                    </span>
                                    <span>
                                    {(list?.title == "Calculater")?"Calculator":list?.title}

                                    </span>
                                    </p>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}