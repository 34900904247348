import React, { useState } from 'react'
import DefaultDropMenu from '../../../input/droplist'
import Input from '../../../input/input';
import PrimaryButton from '../../../Button1/primarybutton';
import { ColorPixerInput } from '../../../input/colorpixerInput';
import { MediaPopup } from '../../../popup/Mediapopup';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../../../../store';
import InputWithLabel from '../../../input/inputWithLabel';
import { isNotEmpty, hasWhiteSpace } from '../../../../utils/helpers';
import { toast } from "react-hot-toast";

export const ShuffleWordSettings = ({ field, fieldId }) => {
    
    const { UpdatedMainJson } = useSelector((state) => ({ UpdatedMainJson: state.fields[fieldId]["filedContent"]["game_mainpage_json"] }));
 

    const EditIcon = (color = 'white') => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.75 16.5H2.25C1.9425 16.5 1.6875 16.245 1.6875 15.9375C1.6875 15.63 1.9425 15.375 2.25 15.375H15.75C16.0575 15.375 16.3125 15.63 16.3125 15.9375C16.3125 16.245 16.0575 16.5 15.75 16.5Z" fill={color} />
        <path d="M14.2649 2.61024C12.8099 1.15524 11.3849 1.11774 9.89243 2.61024L8.98493 3.51774C8.90993 3.59274 8.87993 3.71274 8.90993 3.81774C9.47993 5.80524 11.0699 7.39524 13.0574 7.96524C13.0874 7.97274 13.1174 7.98024 13.1474 7.98024C13.2299 7.98024 13.3049 7.95024 13.3649 7.89024L14.2649 6.98274C15.0074 6.24774 15.3674 5.53524 15.3674 4.81524C15.3749 4.07274 15.0149 3.35274 14.2649 2.61024Z" fill={color} />
        <path d="M11.7082 8.6476C11.4907 8.5426 11.2807 8.4376 11.0782 8.3176C10.9132 8.2201 10.7557 8.1151 10.5982 8.0026C10.4707 7.9201 10.3207 7.8001 10.1782 7.6801C10.1632 7.6726 10.1107 7.6276 10.0507 7.5676C9.80323 7.3576 9.52573 7.0876 9.27823 6.7876C9.25573 6.7726 9.21823 6.7201 9.16573 6.6526C9.09073 6.5626 8.96323 6.4126 8.85073 6.2401C8.76073 6.1276 8.65573 5.9626 8.55823 5.7976C8.43823 5.5951 8.33323 5.3926 8.22823 5.1826V5.1826C8.09056 4.88759 7.70337 4.79996 7.47317 5.03015L3.25573 9.2476C3.15823 9.3451 3.06823 9.5326 3.04573 9.6601L2.64073 12.5326C2.56573 13.0426 2.70823 13.5226 3.02323 13.8451C3.29323 14.1076 3.66823 14.2501 4.07323 14.2501C4.16323 14.2501 4.25323 14.2426 4.34323 14.2276L7.22323 13.8226C7.35823 13.8001 7.54573 13.7101 7.63573 13.6126L11.8601 9.38819C12.0857 9.1626 12.001 8.77445 11.7082 8.6476V8.6476Z" fill={color} />
    </svg>)

    const DelIcon = (color = '#586474') => (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1376_9229)">
                <path d="M4.5 14.25C4.5 15.075 5.175 15.75 6 15.75H12C12.825 15.75 13.5 15.075 13.5 14.25V5.25H4.5V14.25ZM6.8775 9.4425C6.585 9.15 6.585 8.6775 6.8775 8.385C7.17 8.0925 7.6425 8.0925 7.935 8.385L9 9.4425L10.0575 8.385C10.35 8.0925 10.8225 8.0925 11.115 8.385C11.4075 8.6775 11.4075 9.15 11.115 9.4425L10.0575 10.5L11.115 11.5575C11.4075 11.85 11.4075 12.3225 11.115 12.615C10.8225 12.9075 10.35 12.9075 10.0575 12.615L9 11.5575L7.9425 12.615C7.65 12.9075 7.1775 12.9075 6.885 12.615C6.5925 12.3225 6.5925 11.85 6.885 11.5575L7.9425 10.5L6.8775 9.4425ZM13.5 3H11.625L11.0925 2.4675C10.9575 2.3325 10.7625 2.25 10.5675 2.25H7.4325C7.2375 2.25 7.0425 2.3325 6.9075 2.4675L6.375 3H4.5C4.0875 3 3.75 3.3375 3.75 3.75C3.75 4.1625 4.0875 4.5 4.5 4.5H13.5C13.9125 4.5 14.25 4.1625 14.25 3.75C14.25 3.3375 13.9125 3 13.5 3Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_1376_9229">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )

    const puzzletype = field?.puzzletype
    const screenMode = field?.screenMode
    const Dispatch = useDispatch()

    const [bgColor, setBgColor] = useState(field?.cardbColor);
    const [textColor, setTextColor] = useState(field?.textColor); 
    const [ShowMedia, setShowMidia] = useState(false);
    const [Contentadd, SetContentAdd] = useState('')
    const [listUpdate, setlistUpdate] = useState(0);

    // const [addContent, setAddContent] = useState(field?.wordlist ?? [])
    const COLUMNS = ['3','4','5','6','7']
    const ROWS = ['3','4','5','6','7']
    const MATERIX=(field?.layoutrange?.toLowerCase())?.split("x")

    const findIndexOfColumn = COLUMNS.findIndex(list => list === MATERIX[0])
    const findIndexOfRow = ROWS.findIndex(list => list === MATERIX[1])

    const DirectContentChange = (value, type) => {


        if(type==="cardbColor") setBgColor(value)
        if(type==="textColor") setTextColor(value)

        const setContent = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_mainpage_json',
            child: type,
            value: value
        }
        // Dispatch(Actions.setFieldTemplatePairChange(setContent))
        Dispatch(Actions.setFieldTemplateContentChange(setContent))
        if(ShowMedia) setShowMidia(false)

    }
const DirectLayoutChange = (value, type) => { 

    const setContent = {
        isCommonSetting: false,
        key: fieldId,
        node: 'game_mainpage_json',
        child:type, 
        value: value
    } 

    Dispatch(Actions.setCharDropLayout(setContent))
    if(ShowMedia) setShowMidia(false)

}

    const DirectWordContentChange = (value, type,fieldId) => {  
        const setContent = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_mainpage_json',
            child: type,
            value: value
        }
        // Dispatch(Actions.setFieldTemplatePairChange(setContent))
        Dispatch(Actions.setPuzzelAddWord(setContent)) 
        Dispatch(Actions.upadatRowLayout(setContent)) 
    }

    const addContentArr = () => {
        if (Contentadd !== '') {  
let layout=(UpdatedMainJson?.layoutrange?.toLowerCase())?.split("x"), alertStr="" 
if(layout[1]>=6) alertStr="Don't add more than 6 words!"; else
if(hasWhiteSpace(Contentadd)) alertStr="Don't use blank spaces in the word!"; else
if(Contentadd?.length<layout[0]) alertStr="Word length is less  than column count!"; else
if(Contentadd?.length>layout[0]) alertStr="Word length is greater than column count!"; else
{
    DirectWordContentChange(Contentadd,'wordlist',fieldId)
    SetContentAdd("")
    setlistUpdate(obj => obj+1)
}
if(isNotEmpty(alertStr))    toast.error(alertStr)


        }

    }

       
    const DirectWordDelete = (value, type, fieldId) => {  
        const setContent = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_mainpage_json',
            child: type,
            value: value
        } 
        Dispatch(Actions.setPuzzelDeleteWord(setContent)) 
        Dispatch(Actions.upadatRowLayout(setContent)) 
    }
 
    const deleteWord = (word)=>{
          DirectWordDelete(word, 'wordlist', fieldId); setlistUpdate(obj => obj+1)}
    const editWord =(word) =>{
        SetContentAdd(word); DirectWordDelete(word, 'wordlist', fieldId); setlistUpdate(obj => obj+1)  }

    return (
        <div className="w-full space-y-4 "  key={listUpdate}>

        {ShowMedia && <MediaPopup cancel={setShowMidia} fieldType={'image_src'} OnSelect={DirectContentChange} />}


        <InputWithLabel label="Puzzle Hint :" textvalue={field?.puzzleHint} onchange={(e) => DirectContentChange(e.target.value,'puzzleHint')} />

             <DefaultDropMenu label={'Column Count :'} list={COLUMNS} active={findIndexOfColumn < 0 ? 0 : findIndexOfColumn} fieldType={'layoutrange'} onchange={DirectLayoutChange} />
            
            {
                // field?.puzzletype === 'image' ?
                //     <Imageinput label="card Background" src={field?.image_src} chanaeAction={() => setShowMidia(true)} />:

                    <>
                        <ColorPixerInput label='Card background' fieldType={"cardbColor"} fieldId={fieldId} colorHex={bgColor || "#7ED321"} Onchange={DirectContentChange} />
                        <ColorPixerInput label='Text color' fieldType={"textColor"} fieldId={fieldId} colorHex={textColor || "#FFFFFF"} Onchange={DirectContentChange} />

                        <div className="w-full flex space-x-2">
                            <Input textvalue={Contentadd} margin={''}  onchange={e => SetContentAdd(e.target.value)} />
                            <PrimaryButton buttontype='secondarybutton' text={'ADD'} OnClick={() => addContentArr()} />
                        </div>
 
                        {
                            field?.wordlist?.map((list, inx)=> {
                                return (
                                    <p className="flex space-x-4 border-2 py-1 px-2 text-gray-500 w-fit rounded-md border-gray-300 bg-gray-300">
                                        <span className={`my-auto capitalize`}>{list}</span>
                                        <i class="fa fa-eye" aria-hidden="true"></i>
                                        <p className="flex space-x-2">
                                        {field?.wordlist?.length>2 &&  <span className={'bg-gray my-auto cursor-pointer'}  onClick={()=>{deleteWord(list)}} >{DelIcon('gray')}</span>}
                                            <span className={'bg-gray my-auto cursor-pointer'} onClick={()=>{editWord(list) }} >{EditIcon('gray')}</span>
                                        </p>

                                    </p>
                                )
                            })
                        }
                    </>}
        </div>
    )

}