import React, { useState } from "react";
import toast from 'react-hot-toast';
import { Imageinput } from "../../input/imageinput";
import InputWithLabel from "../../input/inputWithLabel";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../Button1/primarybutton";
import { InputPopup } from "../../popup/InputPopup";
import { Actions } from "../../../store";
import { MediaPopup } from "../../popup/Mediapopup";
import { isEmpty } from "lodash";
import { ColorPixerInput } from "../../input/colorpixerInput";
import { isNotEqual } from "../../../utils/helpers";
import { listOfIcon } from "../../../content/iconList";
export const LeadFormEditor = ({ fieldId }) => {

  const { field } = useSelector((state) => ({ field: state.fields }))
  const Dispatch = useDispatch();
  const [ShowMedia, setShowMidia] = useState(false)

  const ContentChange = (e, type) => {

    const setContent = {
      isCommonSetting: false,
      key: fieldId,
      node: 'game_leadForm_json',
      child: type,
      value: e.target.value
    }



    Dispatch(Actions.setFieldTemplateContentChange(setContent))

  }
  const DirectContentChange = (value, type) => {


    const setContent = {
      isCommonSetting: false,
      key: fieldId,
      node: 'game_leadForm_json',
      child: type,
      value: value
    }
    Dispatch(Actions.setFieldTemplateContentChange(setContent))
    if (ShowMedia) setShowMidia(false)

  }

  const LeadFormjson = field[fieldId]?.filedContent?.game_leadForm_json
  const category = field[fieldId]?.category
  const spinWheelType = field[fieldId]?.filedContent.game_mainpage_json?.spinWheelType;

  const [showPop, setShowPop] = useState(false);
  const [vaule, setValue] = useState("");
  const [checkBoxRedirUrl, setCheckBoxRedirUrl] = useState("")
  const [textAreaVal, setTextAreaVal] = useState("");
  const [filedRequired, setRequired] = useState(false)

  const [select, setSelect] = useState(0);

  const [listOfData, setListOfData] = useState([]);

  const InputType = ["text", "textarea", "number", "checkbox", "email", "date", "select"];

  const RemoveData = (index) => {

    Dispatch(
      Actions.setRemoveConatent({
        fieldId,
        index,
      })
    );
    Dispatch(Actions.setUpdateCampaign());
  };

  const updateData = () => {
    if (isEmpty(InputType[select]) || isEmpty(vaule) || (InputType[select] === "select" && isEmpty(textAreaVal))) {
      toast.error("Input field must not be empty!")
      return;
    }

    let key = Math.random().toString(16).slice(8) + Date.now().toString(36);


    let tempList = {
      Label: vaule,
      inputType: InputType[select],
      delectable: true,
      questionId:key,
      editable: true,
      required: filedRequired
    }

    if (InputType[select] === "select") {
      tempList = { ...tempList, textAreaVal: textAreaVal }
    }

    if (InputType[select] === "checkbox") {
      tempList = { ...tempList, checkBoxRedirUrl: checkBoxRedirUrl }
    }

    Dispatch(
      Actions.setUpdateLeadConatent({
        fieldId,
        listOfData: tempList
      })
    );
    // Dispatch(Actions.setUpdateCampaign());

    setShowPop(false);
    setValue("");
    setSelect(0);
  };


  const DelIcon = (color = '#586474') => listOfIcon('delete2',18,18,color,'white');

  return (
    <div className="w-full flex flex-col space-y-4">

      {ShowMedia && <MediaPopup cancel={setShowMidia} fieldType={'imageSrc'} OnSelect={DirectContentChange} />}

      <Imageinput src={LeadFormjson?.imageSrc} chanaeAction={() => setShowMidia(true)} deleteSrc={() => DirectContentChange("", 'imageSrc')} label="Popup Banner" />

      <InputWithLabel label="Popup header" textvalue={LeadFormjson?.title}  onchange={(e) => ContentChange(e, 'title')} labelTextWeight="flex justify-between"  />

      <label className="">Popup form fields</label>

      <div className="py-2  space-y-2 flex flex-wrap items-baseline">

        {

          LeadFormjson?.field?.map((list, index) => {

            if(!list?.questionId)     Dispatch(
              Actions.selAutoQuestionUpdate({
                fieldId,
                FieldInd: index
              })
            );

            return (

              <div key={index} className="flex h-fit ml-2 space-x-4 border-2 py-1 px-2 text-gray-500 w-fit rounded-md border-gray-300 bg-gray-300">

                <span className={`my-auto capitalize`}>{list?.Label}</span>

                <i class="fa fa-eye" aria-hidden="true"></i>

                { (list.delectable) && <p className="flex space-x-2">

                  <span className={'bg-gray my-auto'} onClick={() => { if (list.delectable) RemoveData(index) }} >{DelIcon('gray')}</span>

                  {/* <span className={'bg-gray my-auto'} onClick={()=> console.log("edpt")} >{EditIcon('gray')}</span> */}

                </p>}

              </div>
            )
          })
        }
      </div>

      <PrimaryButton buttontype={'secondarybutton'} classNames={"noselect"} text={'Add new form field'} width="w-full" padding_y="px-3" OnClick={() => setShowPop(true)} />

      <InputWithLabel label="Button Text" textvalue={LeadFormjson?.buttenText} labelTextWeight="flex justify-between" onchange={(e) => ContentChange(e, 'buttenText')} />

      {showPop && (
        <InputPopup
          close={() => setShowPop(false)}
          setValue={setValue}
          setCheckBoxRedirUrl={setCheckBoxRedirUrl}
          setSelect={setSelect}
          InputType={InputType}
          updateData={updateData}
          showList={listOfData}
          filedRequired={filedRequired}
          setRequired={setRequired}
          curVal={InputType[select]}
          setTextAreaVal={setTextAreaVal}
        />
      )}

      <ColorPixerInput label={"Title Color"} labelTextsize={'text-sm my-auto'} fieldType={"titleColor"} fieldId={fieldId} Onchange={DirectContentChange} colorHex={LeadFormjson?.titleColor || "#000000"} />

      <ColorPixerInput label={"Form Title Color"} labelTextsize={'text-sm my-auto'} fieldType={"formTitleColor"} fieldId={fieldId} Onchange={DirectContentChange} colorHex={LeadFormjson?.formTitleColor || "#000000"} />

      {(category === "spinWheel" && spinWheelType === "sideTab") ? null : <ColorPixerInput label={"Button Background Color"} labelTextsize={'text-sm my-auto'} fieldType={"btnBgColor"} fieldId={fieldId} Onchange={DirectContentChange} colorHex={LeadFormjson?.btnBgColor || "#2563eb"} />}

      <ColorPixerInput label={"Button Text Color"} labelTextsize={'text-sm my-auto'} fieldType={"btnTxtColor"} fieldId={fieldId} Onchange={DirectContentChange} colorHex={LeadFormjson?.btnTxtColor || "#ffffff"} />


    </div>
  )
}