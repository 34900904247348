import React, { useState } from "react";
import InputWithLabel from "../../input/inputWithLabel";
import DefaultDropMenu from "../../input/droplist";
import { PhoneInputFiled, RePhoneFiled } from "../../input/PhoneFiled";
import PrimaryButton from "../../Button1/primarybutton";
import { useDispatch, useSelector } from "react-redux";
import { IndustryTypesData } from "../../../content/indutrytype";
import { Actions } from "../../../store";
 import toast from 'react-hot-toast'; 

export const UserinfoIntro = ({
    forWardAction=null,
}) =>{

    const { userInfo } =  useSelector(state => ({userInfo:state?.userDetail}))

    const Dispatch = useDispatch();

    const IndType =IndustryTypesData()
    const rolearr = ['Personal','Company','Agency','Develpoer','admin','others','CEO'];
    const [ RoleInd, setRoleInd ] = useState(0);
    const [ industInd, setindustInd ] = useState(0);

    const  [errorState, setErrorState ] = useState(false);
    const  [errorMsg, setErrorMsg ] = useState('');
    const  [full_name,setfull_name] = useState('')
    const  [company_name,setcompany_name] = useState('')
    const  [phone_number,setphone_number] = useState('')
    const  [countryName,setCountryName] = useState('Afghanistan')
    const  [role,setrole] = useState(rolearr[RoleInd]);
    const  [industry,setindustry] = useState(IndType[industInd])




    let payload = {
        "email":userInfo?.email,
        full_name,
        company_name,
        phone_number,
        role:rolearr[RoleInd],
        industry:IndType[industInd],
        countryName
    } 

    const onError = (err) =>{
        setErrorState(err.errstatus)
        setErrorMsg(err.msg)
    }

    const onSubmet = () => {


        if((full_name == "") || (company_name == "") || (phone_number == "") ) return toast?.error ("Please fill Requied fileds,")

        Dispatch(Actions.setIntroUserInfoGS(payload))
        forWardAction(1)
    }


    return ( 
        <section className="flex flex-col w-full h-full py-8 overflow-y-scroll list-industry" >

            <div className="flex flex-col mx-auto justify-center my-auto space-y-4">

                <h3 className="font-semibold text-xl" >Please tell us a bit more for better assistance</h3>

                <InputWithLabel label="Email" className="bg-gray-300" Placeholder="viswa@gozen.io" labelTextWeight="font-semibold" value={userInfo?.email} disable={true} />
                <InputWithLabel label="Full name*" Placeholder="Josh" labelTextWeight="font-semibold" onchange={(e) => {setfull_name(e.target.value)} } />
                <InputWithLabel label="company Name*" Placeholder="Company name" labelTextWeight="font-semibold" onchange={(e) => {setcompany_name(e.target.value)} } />
                <PhoneInputFiled error={onError} onchange={ (val) => {setphone_number( val); } } setCountryName={setCountryName}  />
                <p className="text-12px ">( Please fill the phone number without "-" )</p>
                <DefaultDropMenu label="choose a role" list={['Personal','Company','Agency','Develpoer','admin','others','CEO']} active={RoleInd} SelectIndex={true} onchange={ (val) => setRoleInd (val) } labelTextWeight="font-semibold" />
                <DefaultDropMenu label="choose a industry type" list={IndType} SelectIndex={true} active={industInd} onchange={ (val) => setindustInd(val) } labelTextWeight="font-semibold" />
                <PrimaryButton text={"continue"} margin="mt-4" OnClick={onSubmet} />

            </div>
            
        </section>
    )

} 