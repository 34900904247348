import axios from "axios";
import { get, split } from "lodash";
import Auth from './Auth0/';

let baseURL = process.env.REACT_APP_ENV == "development" ?  
            //   "https://api.dev.optinly.gozen.io/v1"
                "http://localhost:8080/v1" 
: process.env.REACT_APP_API_ENDPOINT ;

let Axios = axios.create( { baseURL } );
// const currentExecutingRequests = {};

Axios.interceptors.request.use(config => {

    const accessToken = localStorage.getItem("access_token");

    if (accessToken) {

        config.headers["Authorization"] = "Bearer "+accessToken;
        config.headers["x-gz-platform"] = "engage";

    } 
    return config;
})

Axios.interceptors.response.use(response => response, error => {

    if(error.response.status ==  401) Auth.login();
    
    return Promise.reject(error)
})

export default Axios;

