import React from 'react'
import { FindpairSettings } from './findpair'
import { GameQuizSettings } from './gameQuiz'
import { ShuffleImageSettings } from './shuffleImage'
import { FindPathSetting } from './findpath'
import { FindFlowSettings } from './FindFlow' 
import { ConnectionSetting } from './connection'
import { RearrageSettings } from './Rearrage'
import { ShuffleWordSettings } from './shuffleword'
import { FindWord } from './findWord'
import { FindOddOne } from './findOddOne'
import { Calculator } from './calculator'
import { Ecommerce } from './ecommerce'
import { SpinWheelSettings } from './spinWheel'
import { Survey } from './survey' 
import { QuizSettings } from './quizs'
import { PersonalizationSettings } from './personalization'

export const MainSettingSlipter = ({ templateType = '', field, fieldId }) => {
    return(        
    <div className="w-full">
        {/* <QuillInputEditor/> */}
        {
            templateType === 'findpair' ? <FindpairSettings fieldId ={ fieldId } field ={ field } /> : 
            (templateType === 'quiz'|| templateType === 'poll') ? <QuizSettings templateType={templateType} fieldId ={ fieldId }  field ={ field } /> : 
            (templateType === 'Q&A' ) ? <GameQuizSettings templateType={templateType} fieldId ={ fieldId } field ={ field } /> :
            (templateType === 'personalize-quiz' ) ? <PersonalizationSettings templateType={templateType} fieldId ={ fieldId } field ={ field } /> : 
            templateType === 'puzzle-shuffleimage' ? <ShuffleImageSettings fieldId ={ fieldId } field ={ field } /> : 
            templateType === 'puzzle-word' ? <ShuffleWordSettings fieldId ={ fieldId } field ={ field } /> : 
            templateType === 'findpath' ? <FindPathSetting fieldId ={ fieldId } field ={ field } /> : 
            templateType === 'findFlow' ? <FindFlowSettings fieldId ={ fieldId } field ={ field } /> : 
            templateType === 'connection' ? <ConnectionSetting fieldId ={ fieldId } field ={ field } /> : 
            templateType === 'rearrange' ? <RearrageSettings fieldId ={ fieldId } field ={ field } /> : 
            templateType === 'findWord' ? <FindWord fieldId ={ fieldId } field ={ field } /> : 
            templateType === 'findOddOne' ? <FindOddOne fieldId ={ fieldId } field ={ field } /> : 
            templateType === 'calculater' ? <Calculator fieldId ={ fieldId } field ={ field } /> : 
            templateType === 'ecommerce' ? <Ecommerce fieldId ={ fieldId } field ={ field } /> : 
            templateType === 'spinWheel' ? <SpinWheelSettings fieldId ={ fieldId } field ={ field } /> : 
            templateType === 'surveys' ? <Survey fieldId ={ fieldId } field ={ field } /> : 
            null
        }
    </div>
    )
}