import React, { useEffect, useState } from "react";
import PrimaryButton from "../../Button1/primarybutton";
import { isEmpty } from "lodash";
import { EcomProductFilter } from "../../../utils/EcomProductFilter";

export const EcommereceResultBlock = ({ pageContent = {}, productList = [], userAnswer = {} }) => {

    const [filteredProduct, setFilterProduct] = useState([])

    async function EcommerceFilter() {
        let FilterCondition = pageContent?.FilterCondition;
        if (isEmpty(FilterCondition) || productList?.length === 0) { setFilterProduct(productList); return }
        let temp = await EcomProductFilter({ productList, userAnswer, FilterCondition })
        setFilterProduct(temp)
    }

    useEffect(() => {  EcommerceFilter() }, [userAnswer])

    return (
        <div className="w-full  flex flex-wrap">

            {
                filteredProduct.map(list => {
                    return (
                        <div className="w-[30%] min-w-[180px] mb-6 space-y-3 rounded-xl p-4 bg-white mx-auto">
                            <div className="h-48 flex rounded-xl border-1">
                                <div className="flex m-auto max-h-full">
                                    <img src={list?.productImageSrc} alt="productimage" />
                                </div>
                                {/* <img src={list?.productImageSrc} alt="" /> */}
                            </div>
                            <p className="text-black text-base font-medium">{list?.productName}</p>
                            <p className="text-black text-lg font-bold">{list?.price == "" ? "0" : list?.price}</p>
                            <PrimaryButton text={list?.buttonText == "" ? "Buy now" : list?.buttonText} />
                        </div>)
                })
            }

        </div>
    )

}