import React from "react";
import { Personalization } from "../components/renderComponents/tempates/Personalization";

const TestPage = () => {

    return ( 
    <div className="w-full h-screen" >
        <Personalization/>        
    </div> 
    )

}

export default TestPage