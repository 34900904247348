import React, { useEffect, useState, useImperativeHandle } from "react";
import { element, mixArray, allElement, sleep, getImageOrignalSize, getImageBase64, isNotEmpty } from "../../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../store";
import { isEmpty } from "lodash";


export const FindFlowTemp = ({ obj = {}, fieldID = '', StartTime = false, prview = false, setActionCount = null, GameFinsh = null, setScore=null,position=0, setLoadContent=null }) => {

    const { Fields, device } = useSelector((state) => ({ Fields: state.fields, device: state.device }));

    const templateData = Fields[fieldID];
    const Settings = templateData?.filedContent
    const mainPageContent = Settings.game_mainpage_json;

    const LayoutRange = mainPageContent?.layoutrange.split("x"); 
    const [gameStage, setGameStage] = useState(0);
    const [Action, setAction] = useState(0);
    const [answerFlow, setAnswerFlow] = useState([]);
    const [userAnswer, setUserAnswer] = useState([]);
    const [isBlinking, setIsBlinking] = useState(false);
    const [curGameState,setcurGameState] = useState(false) 
    const [trigger, settrigger] = useState(false)
  const [base64, setBase64] = useState("")
  const [isStarted,setStarted] = useState(false) 


    const DisPatch = useDispatch()


    let CuurentGameFrame = mainPageContent?.gameStage[gameStage];

    let flowSpeed = CuurentGameFrame?.flowSpeed === 'easy' ? 800 :
        CuurentGameFrame?.flowSpeed === 'normal' ? 500 :
            CuurentGameFrame?.flowSpeed === 'medium' ? 400 :
                CuurentGameFrame?.flowSpeed === 'hard' ? 150 : 1000;

    const NoOfCard = [...new Array(LayoutRange[0] * LayoutRange[1])]

    useEffect(() => {
        if (StartTime) {
            settrigger(!trigger)
            blinkCard()
            gameSetup();
        }
    }, [StartTime, gameStage])

    async function preloadBase64(){  

        
        if(isEmpty(base64) && isNotEmpty(mainPageContent?.imageSrc) ) {
        let size = await getImageOrignalSize(mainPageContent?.imageSrc)
        let bas=await getImageBase64(mainPageContent?.imageSrc,size[0],size[1]) 
        setBase64(bas) 
      }}

    useEffect(() => {  setBase64("");gameSetup(); }, [mainPageContent])
    useEffect(() => {  gameSetup(); },  [device, trigger])

    useEffect(() => {  if (mainPageContent?.gameStage.length > 0) gameSetup();   }, []);

    const gameSetup = () => { setStarted(false); preloadBase64(); setLoadContent(false); setcurGameState(false) };

    const blinkCard = async () => {
        setIsBlinking(true);
        setUserAnswer([])
        let inx = 0
        let card = [];
        while (card.length < CuurentGameFrame?.flowCount) {
            let RandomNumber = Math.round(Math.random() * (LayoutRange[0] * LayoutRange[1]));
            if (card.indexOf(RandomNumber) < 0 && RandomNumber != CuurentGameFrame?.flowCount) {
            card.push(RandomNumber);
            }
        }
        await sleep(300)
        setStarted(true);

        while (inx < card.length) {
            setAction(card[inx])
            await sleep(flowSpeed)
            inx++
        }
        setAction(null)
        setAnswerFlow(card);
        setIsBlinking(false);
    }

    const flowClick = async (val) => {
        if (isBlinking) return;
        if(!curGameState) setcurGameState((answerFlow[+userAnswer.length] === val) ? false : true)

        setUserAnswer(obj => [...obj, val])
        let tempUserAnswer=[...userAnswer, val]

        if(answerFlow.length === userAnswer.length + 1 ) {
            await sleep(flowSpeed)
            if(tempUserAnswer.toString() === answerFlow.toString()) setScore(obj => obj+1);
            if(!curGameState) setActionCount(obj => obj +1)
            if(mainPageContent?.gameStage?.length  == gameStage + 1 ) {GameFinsh(true);setGameStage(0)}
            else setGameStage(obj => obj + 1)
        }
    }
    
    return ( 
            <div className={`          ${ device==="mobile" && ("w-[90%] h-[60%]") }  ${ device==="tab" && ("w-[85%] h-[75%]") }   ${ device==="desktop" && ("w-[75%] h-[85%]") } 
            mx-auto my-auto  flex  `}  id="gamepanel" onClick={(e)=>{e.stopPropagation();DisPatch(Actions.setActiveFieldside({filedId:fieldID,index:position,ActiveScreen:"main"}))}} >
                <div className={((device==="mobile")?"gap-2":((device==="desktop")? "gap-8" : "gap-3"))+` w-full h-full grid  gap-10  mx-auto my-auto   mt-5  `} style={{ gridTemplateColumns: `repeat(` + LayoutRange[0] + `,minmax(0,1fr)`, gridTemplateRows: `repeat(` + LayoutRange[1] + `,minmax(0,1fr)`, }}>
                {
                    NoOfCard.map((val, inx) => {
                        let BorderColor
                        let bgback = mainPageContent?.cardtype === "image" ? `url(` + (isEmpty(base64)? mainPageContent?.imageSrc : base64) + `) 0% 0% / 50% 63%, white` : (mainPageContent?.color || "#568AFC")
                        if(!isStarted) bgback="white"

                        if(userAnswer.length !== answerFlow.length){
                            BorderColor =  ((userAnswer.indexOf(inx) > -1) || Action === inx) ? mainPageContent?.color : "white" ;
                        }
                        if((userAnswer.length === answerFlow.length) && curGameState) {
                            //userAnswer.length === answerFlow.length ? (userAnswer.indexOf(inx) > -1)  ? curGameState ? "border-red-600" : "border-green-600" :"" :""
                            // BorderColor =  (userAnswer.indexOf(inx) > -1)  ? curGameState ? "border-red-600" : "border-green-600" :"" 
                        }
                        return (
                            <div
                                key={inx}
                                style={{ background: ((userAnswer.indexOf(inx) > -1) || Action === inx) ? bgback : "white", duration: flowSpeed, iterations: 1 ,borderColor:BorderColor}}
                                className={` bg-no-repeat fitImage transition-all bg-center border-2 rounded-[10px] 
                                 ${userAnswer.length === answerFlow.length ? (userAnswer.indexOf(inx) > -1)  ? curGameState ? "border-red-600" : "border-green-600" :"" :"border-["+mainPageContent?.color+"]" }   sm:rounded-[14px] FindFlowgamecard transition-all  `}
                                data-active="0"
                                onClick={(e) => flowClick(inx)}  >
                            </div>
                        );
                    })
                }
                </div>
            </div> 
    );
};


