import React from "react";

export const Polloption = ({
    Answed = false,
    isAnswed = false,
    OptionContent = "",
    progress = 0,
    pollPer = 0,
    OnClick = null,
    textSize="text-base",
    primaryColor = "white",
    secondaryColor = 'black'
}) => {

    return (
        <div className={`cursor-pointer transition-all duration-500 flex flex-col px-4 py-3 border-1 min-w-[150px] rounded-xl ${textSize} ${(Answed) ? "bg-white" : "bg-[rgba(250,250,250,0.2)]"}  text-white font-medium ${(isAnswed) ? "space-y-5" : ""} `}
            style={{ background: Answed ? primaryColor ?? "white" : primaryColor + "30" ?? "#FFFFFF30", borderColor: primaryColor ?? "white", color: primaryColor }}

            onClick={OnClick} >
            <div className="flex w-full justify-between">
                <div className="flex space-x-3">
                    <div className={`my-auto flex cursor-pointer ${(Answed) ? "border-white bg-black" : ""} rounded-full w-5  h-5 border-1`} style={{ borderColor: primaryColor, background: Answed ? secondaryColor : primaryColor + "30" }} >
                        <div className={` rounded-full w-2.5 h-2.5 m-1 ${(Answed) ? "bg-white" : ""}`} style={{ background: Answed ? primaryColor : "" }} ></div>
                    </div>
                    <p className={`cursor-pointer my-auto ${(Answed) ? "text-black" : ""}  `} style={{ color: Answed ? secondaryColor : "" }}  >{OptionContent}</p>
                </div>
                <p className={`cursor-pointer my-auto ${(isAnswed) ? "" : " hidden"} ${(Answed) ? "text-black" : ""}`} style={{ color: Answed ? secondaryColor : "" }}  >{pollPer}%</p>
            </div>
            <div className={`w-full ${isAnswed ? "h-2" : ""}  ${(Answed) ? "bg-[rgba(0,0,0,0.3)]" : "bg-[rgba(250,250,250,0.4)] "} rounded-full  grid justify-items-start `} style={{ background: Answed ? secondaryColor + "40" : primaryColor + "50" }} >
                <div className={`${isAnswed ? "h-2" : ""} w-[${pollPer}%] transition-all duration-500 ${(Answed) ? "bg-black" : "bg-white"}  rounded-full `} style={{ background: Answed ? secondaryColor : primaryColor, width: `${pollPer}%` }} ></div>
            </div>

        </div>
    )
}