import React, { useEffect, useState } from "react";
import DefaultDropMenu from "../../../../input/droplist";

export const RenderedPages = ({ sitedata = [] }) => {

    const [PagePath, setPagePath] = useState([])
    const [Domain, SetDomain] = useState(['all'])
    const [active, Setactive] = useState('all')

    const SelectData = (index) => Setactive(Domain[index])


    let SerialNumber = 0

    useEffect(() => {
        let MapData = []
        let domainlist = ['all']
         
        sitedata.map((listObj) => {
            
            MapData = [...MapData, { domain: listObj.domain, PagePath: JSON.parse(listObj?.CampAnalyticsbySite) }]
            domainlist = [...domainlist, listObj.domain]
        })
        setPagePath(MapData)
        SetDomain(domainlist)

    }, [sitedata])

    const CurrentDomain = active == 'all' ? PagePath : PagePath.filter(findlist => findlist.domain == active)

    return (
        <div className="w-full border-1 rounded-lg bg-white shadow-bgshadow p-6 space-y-4" >
            <div className="flex justify-between">
                <h4 className="font-medium text-xl" >Campaign rendered paths</h4>
                <DefaultDropMenu SelectIndex={true} onchange={SelectData} list={Domain} mainclass="flex " spaceY="space-x-3" label="Domains :" labelTextWeight="my-auto hidden" iscapitalize="" />
            </div>
            <table className="w-full rounded-lg">
                <tr className="w-full py-2 bg-[#F4F6F8] rounded-md">
                    <th className={`px-3 py-4 min-w-[75px]`}>S.No</th>
                    <th className={`px-3 py-4 min-w-[150px]`}>Domain</th>
                    <th className={`px-3 py-4 min-w-[150px]`}>Campaing rendered path </th>
                    <th className={`px-3 py-4 min-w-[150px]`}>Views</th>
                    <th className={`px-3 py-4 min-w-[150px]`}>Clicks</th>
                    <th className={`px-3 py-4 min-w-[150px]`}>Responses</th>
                </tr>
                <>
                    {
                        CurrentDomain.map(renderDomain => {


                            return (
                                <>
                                    {
                                        renderDomain?.PagePath?.map((renderPath, index) => {
                                            SerialNumber += 1
                                            return (
                                                <tr className="transition-all duration-300 hover:bg-gray-100 rounded-lg cursor-pointer  justify-center text-center ">
                                                    <td className={`px-3 py-4 min-w-[75px]`}>{SerialNumber}</td>
                                                    <td className={`px-3 py-4 min-w-[150px]`}>{renderDomain?.domain}</td>
                                                    <td className={`px-3 py-4 min-w-[150px]`}>{renderPath?.render_path}</td>
                                                    <td className={`px-3 py-4 min-w-[150px]`}>{renderPath?.view_count}</td>
                                                    <td className={`px-3 py-4 min-w-[150px]`}>{renderPath?.click_count}</td>
                                                    <td className={`px-3 py-4 min-w-[150px]`}>{renderPath?.subscribe_count}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </>
                            )
                        })
                    }
                </>
            </table>
        </div>
    )
}