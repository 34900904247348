import React from "react";
import DefaultDropMenu from "./input/droplist";
import InputWithLabel from "./input/inputWithLabel";
import { Input } from "postcss";
import { listOfIcon } from "../content/iconList";

export const LogicJumpRule = ({
    queINdex,
    rules,
    showBlock = true, //${ShowCondeition === `${Index}`,
    Conditions = [],
    jumpToLeft=[],
    ConditionalOperator=[],
    ConditionsKey=[],
    RulesOption= null,
    RuleConditionAdd=null,
    RemoveCondition = null
}) =>{

    const defalutTo = rules?.DefaultAction ? rules?.DefaultAction : "";
    const actionTo = rules?.action?.type ? rules?.action?.type  : "Jump To";
    const actionTovalue = rules?.action?.value ? rules?.action?.value  : "";
    const isDefalutActive = defalutTo == "" ? queINdex+1 : jumpToLeft.findIndex(obj => obj == defalutTo)
    const isactionTovalue= actionTovalue == "" ? 0 : jumpToLeft.findIndex(obj => obj == actionTovalue)
    const IsactionTo = ['Jump To', 'Redirect'].findIndex(obj => obj == actionTo)
    const deleteIcon = listOfIcon('delete',20,20,'red');

    console.log({ConditionsKey,"te":"et"})

    return(
        <div className={`w-full flex flex-col space-y-2 ${showBlock ? "" : "hidden"}`}>
        {/* <label className="capitalize text-primary " >Logic Jump</label> */}

        <label className="capitalize" >if Jump to :</label>
        {
            Conditions?.map((list, ind) => {
                let Left = jumpToLeft.findIndex(listObj => listObj === list.Que)
                let Cond = ConditionalOperator.findIndex(listObj => listObj === list.opertator)
                return (
                    <>
                        <div className="flex space-x-2">

                            <DefaultDropMenu labelTextWeight="hidden" active={Left < 0 ? 0 : Left} IsRule={true} fieldType="conditions" ConditionKey={ConditionsKey[ind]} SubKey={'Que'} onchange={RulesOption} list={jumpToLeft} />
                            <DefaultDropMenu labelTextWeight="hidden" active={Cond < 0 ? 0 : Cond} IsRule={true} fieldType="conditions" ConditionKey={ConditionsKey[ind]} SubKey={'opertator'} onchange={RulesOption} list={ConditionalOperator} />
                            <InputWithLabel labelTextWeight="hidden" textvalue={list?.value} onchange={(e)=>RulesOption("conditions",ConditionsKey[ind],{key:"value",value:e.target.value})} />
                            <span className="p-2 rounded-md border-1 h-fit mt-auto cursor-pointer" onClick={ ()=> RemoveCondition("conditions",ConditionsKey[ind], ( ind == 0 ) ? "" : ConditionsKey[ind-1] ) } >{ deleteIcon } </span>

                        </div>
                        {(list?.pairQue !== "") && <DefaultDropMenu labelTextWeight="hidden" mainclass="w-32" IsRule={true} fieldType="conditions" ConditionKey={ConditionsKey[ind]} SubKey={'condition'} onchange={RulesOption} list={['And', 'or']} />}

                    </>
                )
            })
        }
        <p className="w-full flex justify-end"><span className="text-primary text-sm cursor-pointer mr-2" onClick={() => {RuleConditionAdd("conditions",Conditions.length !== 0? ConditionsKey[Conditions.length - 1]:"", {
            opertator: 'equal to',
            condition: 'and',
            pairQue: '',
            type: 'Que-2',
            value: 10
        })}}>+ Add rule</span></p>
        {(Conditions.length !== 0) && <div className="flex w-full space-x-2">
            <DefaultDropMenu labelTextWeight="hidden" mainclass="w-full" list={['Jump To', 'Redirect']}  active={IsactionTo} IsRule={true} fieldType="action" SubKey={'type'} onchange={RulesOption} />
            {
            actionTo == "Jump To" ? 
            <DefaultDropMenu labelTextWeight="hidden" mainclass="w-full" list={jumpToLeft} IsRule={true} active={isactionTovalue > 0 ? 0: isactionTovalue} fieldType="action" SubKey={'value'} onchange={RulesOption} />: 
            <InputWithLabel labelTextWeight="hidden" textvalue={actionTovalue} width="w-full" mainclass="w-full" />
            }
        </div>}

        <label className="capitalize" >In all other cases Jump to :</label>
        <div className="flex space-x-2">
            <DefaultDropMenu labelTextWeight="hidden" mainclass="w-full" list={jumpToLeft} active={isDefalutActive} IsRule={true} fieldType="DefaultAction" onchange={RulesOption} />
        </div>

    </div>
    )
}