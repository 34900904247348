import React, { useEffect, useState } from "react"; 
import { QuizContainerGame } from "../../../quizQueGame";
import PrimaryButton from "../../../Button1/primarybutton";
import { useDispatch } from "react-redux";
import { Actions } from "../../../../store";
import { MediaPopup } from "../../../popup/Mediapopup";

export const GameQuizSettings = ({ field, fieldId,templateType }) => {

    const DisPatch = useDispatch()


    const AddQues = () => {
        const setContent = {
            key:fieldId,
            node:'game_mainpage_json',
            child:'questions',
            value:{
                "questionType": "Default",
                "answer": "no",
                "questionId": Math.random().toString(16).slice(8) + Date.now().toString(36),
                "question": "Is 10 a prime number?",
                "image": "",
                "optionType": "yerorno",
                "options": ["Options", "Options", "Options", "Options"]
            } 
        }
        // DisPatch(Actions.setCampaignAdditional_info({
        //     isTypechange:true,
        //     key:fieldId,
        //     node:"pollResp",
        //     value:{}

        // }))
        DisPatch(Actions.setQuizQuestionAdd(setContent))
    }

    const addQuestionId = ( childInd ) => {

        let payload = {
  
          key:fieldId,
          node:'game_mainpage_json',
          child:'questions',
          childInd
  
        }
  
        DisPatch(Actions.questionIdAdd( payload ))
  
      }

   
    return(
        <div className="w-full space-y-4 ">


            {
                field?.questions?.map((list,index) =>{

                    if(!list?.questionId) addQuestionId( index )
                    return(
                        <QuizContainerGame 
                            Index={index}
                            templateType={templateType}
                            answer={list?.answer} 
                            question={list?.question ?? list?.quistion}
                            fieldId={fieldId} 
                            imgSrc={list?.image} 
                            optionType={list?.optionType} 
                            options={list?.options}  
                            answerDescription={list?.answerDescription} 
                        />

                    )
                })
            }

            <PrimaryButton text={'Add Question'} width="w-full" height="h-12" backgroundColor="bg-primary-lightblue" border="border-1 border-primary" textcolor="text-primary" OnClick={AddQues} />

        </div>

    )
    
}