import React from "react";
import { useState } from "react";
import { CardBlock } from "./cardblock";
import { MediaPopup } from "./popup/Mediapopup";
import { useDispatch } from "react-redux";
import { Actions } from "../store";

export const PairCardBlock = ({ pairItems = [], fieldId='',TriggerMedia=null,selectcard=0,pairValue,setpairValue=null,setValuecontent=null,setselectcard=null }) => {

    // const [pairValue, setpairValue] = useState('');
    // const [selectcard, setselectcard] = useState(0);
    const [triggerBut, settriggerBut] = useState('');

    const Dispatch = useDispatch()

    // const TriggerMedia = (pairval,index,value,type) => {
    //     if (type) {
    //         setShowMidia(true)
    //         setpairValue(pairval)
    //         setselectcard(index)
    //     }
    // }

    // const setValuecontent = (value='',type = 'image',indexcard=selectcard,pair=pairValue) => {

    //     const setContent = {
    //         isCommonSetting:false,
    //         key:fieldId,
    //         node:'game_mainpage_json',
    //         child:'pairItems',
    //         type:type,
    //         pairIndex:indexcard,
    //         pairvalue:pair,
    //         value:value
    //     }
    //     alert(setContent)

    //     Dispatch(Actions.setpairContentChange(setContent))
    //     setpairValue('')
    //     setselectcard(0);
    //     setShowMidia(false)
    // }

    const [ShowMedia, setShowMidia] = useState(false)

    return (

        pairItems.map((pairlist, index) => {
            return (
                <> 
                    {/* {ShowMedia && <MediaPopup cancel={setShowMidia} fieldType={'image'} OnSelect={setValuecontent} />} */}

                    <div key={index} className={`w-full relative flex  flex-col space-y-2 border-2  h-fit delay-400 duration-700 top-0 p-4 rounded-xl hover:shadow-md hover:-top-0 hover:border-primary`}  >

                        <p className="text-base font-medium" >Pair - {index + 1}</p>

                        <div className="flex flex-row space-x-3">

                            {
                                pairlist?.map((card, cardindex) => {

                                    return (

                                        <CardBlock key={cardindex} pairval={`${card?.pairValue}`} obj={card} inDex={cardindex} toggletype = {setValuecontent} setvalue={settriggerBut} OnClick={TriggerMedia} />

                                    )
                                })
                            }

                        </div>

                    </div>
                </>
            )
        })



    )
}

