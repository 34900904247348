import React, { useState } from 'react'
import DefaultDropMenu from '../../input/droplist'
import { useSelector } from 'react-redux'
import PrimaryButton from '../../Button1/primarybutton'
import toast from 'react-hot-toast'
import { CopyClipContent } from '../../copyClip'

export const ApiKey = () => {

    const { sites, domains, user } = useSelector(state => ({ sites: state?.sitesInfo, domains: state?.domain, user: state.userDetail }))

    const [showKeys, SetShowKeys] = useState(false);
    const [key, SetKeys] = useState(user?.primary_Site_id)
    const [secret, Setsecret] = useState('')
    const [active, Setactive] = useState(0)

    const getAccessKey = (index) => {

        if (index === 0) {
            SetShowKeys(false)
            return toast.error("Please Choose Site");
        }

        else {
            if (sites[index - 1]?.domain !== domains[index]) return toast.error("Choose Site")
            Setsecret(sites[index - 1]?.site_id)
            return SetShowKeys(true)
        };

    }

    return (
        <div className="h-[85%] w-full p-5 select-none fade-in bg-white py-6 px-8 space-y-3 rounded-xl">

            <p className="text-xl font-bold">  API Key </p>

            <div className='flex flex-col space-y-3' >
            <p className="font-bold">Steps to install.</p>
            <p className="">1. Download the <b>engage wordpress plugin</b> on wordpress. <a className='text-primary underline' href='https://wordpress.org/plugins/gozen-engage/' alt="GozenEngagePlugin" title='GozenEngagePlugin' >Click here</a>.</p>
            <p className="">2. Select the domain below and get your wordpress key.</p>
            <p className="">3. Paste the access key and secret key in the <b>engage wordpress plugin</b>.</p>


                <DefaultDropMenu list={domains} label='Choose your Site' labelTextWeight='font-bold' SelectIndex={true} active={active} onchange={(ind)=>Setactive(ind)} />
                

                <PrimaryButton text={"Get Wordpress Access Key"} OnClick={()=>getAccessKey(active)} />

            </div>

            {showKeys && <div className='flex flex-col space-y-4 ' >


                <CopyClipContent constent={key} labelName='plugin Access Key' />
                <CopyClipContent constent={secret} labelName='plugin Access Secret' />

            </div> }



        </div>
    )
}
