import toast from 'react-hot-toast';
import axios from '../axios';
import { get, isObject } from 'lodash';



export const getUserSites = () => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.get('/sites');
            

            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response?.data.message ?? "Can't get the Site list, please Contact Support")
            reject(err);
        }
    })
}

export const getCustomDomain = () => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.get('/customdomain/getall');
            

            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response?.data.message ?? "Can't get Custom Domain, please Contact Support")
            reject(err);
        }
    })
}

export const CreateCustomDomain = ( payload ) => {

    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.post('/customdomain/create',payload);
            

            if(res?.status == 200) resolve(res.data);
            
    

        }catch(err){
            toast.error(err.response?.data.message ?? "Can't Create Custom Domain, please Contact Support")
            reject(err);
        }
    })
}

export const VerifyCustomDomain = ( payload, id ) => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.post(`/customdomain/${id}`, payload);
            

            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response?.data.message ?? "Can't get the Site list, please Contact Support")
            reject(err);
        }
    })
}
export const DeleteCustomDomain = ( payload, id ) => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.delete(`/customdomain/${id}`);
            

            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response?.data.message ?? "Can't get the Site list, please Contact Support")
            reject(err);
        }
    })
}

export const CreateSite = (Domain) => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.post(`/sites/create?domain=${Domain}`);
            

            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response?.data.message ?? "Can't Create the Site , please Contact Support")
            reject(err);
        }
    })
}
export const DeleteSite = (Site_id) => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.delete(`/site/?site_id=${Site_id}`);
            

            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response?.data.message ?? "Can't Delete the Site , please Contact Support")
            reject(err);
        }
    })
}
