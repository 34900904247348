import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditorTooltip } from "../Editor/common/editortooltip";
import { isNotEmpty } from "../../utils/helpers";
import WebFont from 'webfontloader' 
import { useEffect } from "react";
import { Actions } from "../../store";



export const EditorDescription = ({ fieldID = '',position ,priview=false}) => {

    const { Fields, campaignSettings } = useSelector((state) => ({ Fields: state.fields, campaignSettings:state?.campaignSettings }));

    const DisPatch = useDispatch();

    const { fontSize } = campaignSettings;

   
    const DescriptionSize = {
        "small": "text-[12px] md:text-sm",
        "medium": "text-sm md:text-base",
        "large": "text-base md:text-[20px] font-medium"
    }

    const titleContent = Fields[fieldID];

    const font=isNotEmpty(titleContent?.filedContent?.FontFamily)? titleContent?.filedContent?.FontFamily : "ABeeZee"

    useEffect(()=>{
        WebFont.load({ google : { families : font }})  
    },[])

    return (

        <div id={"gameFrameBorder"+fieldID}  className="relative group flex flex-col py-4" onClick={()=>{DisPatch(Actions.setActiveFieldside({filedId:fieldID,index:position,}))}} >

            {!priview &&<EditorTooltip position={position}/>}


            <p className={`w-4/5 mx-auto font-medium ${ DescriptionSize[fontSize] } capitalize text-center`} 
            style={{color:titleContent?.filedContent?.TextColor,fontFamily:font}} >
            {titleContent?.filedContent?.Content === '' ? 'Grow your business organically without Ads by creating SEO friendly content, building audience, automating engagement and sales' : titleContent?.filedContent?.Content}  </p>

        </div>

    )
}