import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getImageBase64, getImageOrignalSize, isEmpty, isEqual, isNotEmpty } from "../../../utils/helpers";
import { QuizOptionBtn, YesOrNoOptionBtn } from "../OptionBtn";
import "./commonStyle.css"
import { Actions } from "../../../store";
import { AnswerPopup } from "../AnswerPopup";


export const QuizGamesTemplate = ({ obj = {}, fieldID = '', StartTime = false, prview = false, setActionCount = null, GameFinsh = null, setScore = null, score = 0, position = 0, setLoadContent = null }) => {

  const { Fields, device, campaignSettings } = useSelector((state) => ({ Fields: state.fields, device: state.device, campaignSettings: state.campaignSettings }));

  const { fontSize } = campaignSettings;

  const QueSizeM = {
    "small": "text-[14px]",
    "medium": "text-lg",
    "large": "text-[22px]",
  }

  const QueSizeT = {
    "small": "text-[18px]",
    "medium": "text-xl",
    "large": "text-xl",
  }

  const QueSize = {
    "small": "text-lg",
    "medium": "text-2xl",
    "large": "text-[26px]",
  }

  const parSizeM = {
    "small": "text-sm",
    "medium": "text-base",
    "large": "text-[18px]",
  }

  const parSize = {
    "small": "text-base",
    "medium": "text-lg",
    "large": "text-xl",
  }

  const templateData = Fields[fieldID];
  const Settings = templateData?.filedContent
  const startScreenContent = Settings?.game_startscreen_json;
  const mainPageContent = Settings?.game_mainpage_json;
  const resultScreenContent = Settings?.game_resultcreen_json;
  const leadFormScreenContent = Settings?.game_leadForm_json;
  const DisPatch = useDispatch()


  const [curQuizIndex, setCurQuizIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [questions, setQuestions] = useState([]);
  // const [questionBase64, setQuestionsBase64] = useState([])
  const progressStep = (100 / questions.length).toFixed(2);
  const [trigger, settrigger] = useState(false)
  const [UserAns, setUserAns] = useState({});
  const [fideout, setFideOut] = useState(false)

  const [showAnswerbanner, SetShowAnswerBanner] = useState(false)

  let PayLoad = {};


  useEffect(() => {
    if (StartTime && mainPageContent?.questions.length > 0) {
      settrigger(!trigger);
      setCurQuizIndex(0);
      setActionCount(0);
      gameSetup();
    }
  }, [StartTime])

  useEffect(() => {
    if (mainPageContent?.questions.length > 0) gameSetup();
  }, [mainPageContent, device, trigger])


  useEffect(() => {
    if (mainPageContent?.questions.length > 0) gameSetup();
  }, [])


  useEffect(() => { if (questions.length !== 0 && curQuizIndex >= questions.length) { GameFinsh(true) } }, [curQuizIndex]);

  async function preLoad() {
    setLoadContent(true)
    let temp = mainPageContent?.questions, newList = []
    setQuestions(temp);

    for (let i = 0; i < temp?.length; i++) {
      // let size = isEmpty(temp[i].image) ? [300, 300] : await getImageOrignalSize(temp[i].image);
      // let base64 = isEmpty(temp[i].image) ? "" : await getImageBase64(temp[i].image, size[0], size[1]);
      // newList.push({ ...temp[i], base64 })
      // setQuestionsBase64(newList)
      setLoadContent(false);
    }
  }

  const gameSetup = async () => {
    setQuestions([]);
    // setQuestionsBase64([]);
    setCurQuizIndex(0);
    setActionCount(0);
    setQuestions([]);
    setCurQuizIndex(0);
    setScore(0);
    setProgress(0);
    preLoad()
  }


  const checkAnswer = async (userAnswer) => {

    setProgress(parseInt(progress) + parseInt(progressStep));

    UserAction(userAnswer, questions[curQuizIndex]?.question, questions[curQuizIndex]?.optionType ?? "")

    if (isEqual(userAnswer.toUpperCase(), (questions[curQuizIndex]?.answer).toUpperCase())) setScore(score + 1);

    SetShowAnswerBanner(true)

    setActionCount(curQuizIndex)

  };

  const UserAction = (value, type, quetype = "") => {

    PayLoad = Object.assign(PayLoad, UserAns)

    if (quetype === "email") PayLoad["email"] = value

    PayLoad[type] = value

    setUserAns(PayLoad)

  }

  const nextAction = () => {

    setFideOut(true)

    setTimeout(() => {
      setCurQuizIndex(curQuizIndex + 1);
      SetShowAnswerBanner(false);
      setFideOut(false)
    }, [700])


  }

  // let imageUrl = (isNotEmpty(questionBase64[curQuizIndex]?.base64) ? questionBase64[curQuizIndex]?.base64 : questions[curQuizIndex]?.image)
  let imageUrl = questions[curQuizIndex]?.image

  return (
    <>
      {showAnswerbanner && <AnswerPopup questionObj={questions[curQuizIndex]} AnswerPayload={UserAns} scoreVal={score} totalQuestion={questions?.length ?? 5} forwardAction={nextAction} />}

      <div className=" w-full flex flex-col  inner-frame p-6 h-full smMax:p-4 my-auto space-y-4 relative" onClick={(e) => { e.stopPropagation(); DisPatch(Actions.setActiveFieldside({ filedId: fieldID, index: position, ActiveScreen: "main" })) }} id={"gamePanel" + fieldID} >

        <div className=" w-full h-6 smMax:h-4 bg-gray-200 rounded-full dark:bg-gray-700 grid justify-items-start  smMax:mb-9"> <div className="h-6 smMax:h-4 bg-blue-600 rounded-full dark:bg-blue-500" style={{ width: progress + "%", transition: "width 2s", background: Settings?.primaryColor ?? "white" }} ></div></div>

        <div className="flex flex-col h-[95%] w-4/5 m-auto  overflow-y-auto">

          <div className={` transition-all duration-500 ${fideout ? "opacity-0" : "opacity-100"} flex flex-col w-full h-fit space-y-4 my-auto`} >

            {(imageUrl !== '') && <div className={`flex mx-auto`}>  <img src={imageUrl} alt="" className=" w-  rounded-xl max-h-[150px]" /> </div>}

            <div className={(device === "mobile" ? QueSizeM[fontSize] : device === 'desktop' ? QueSize[fontSize] : QueSizeT[fontSize]) + ` question   
            font-bold
            lg:font-bold
             
             mb-5 smMax:mb-8 flex  justify-center `} style={{ color: Settings?.primaryColor ?? "white" }} >  {questions[curQuizIndex]?.question ?? questions[curQuizIndex]?.quistion}  </div>

            {questions[curQuizIndex]?.optionType === "yerorno" ? <YesOrNoOptionBtn className=" inset-x-0 bottom-0 h-16 mt-[3rem]" style={{ marginBottom: "17em" }} checkAnswer={checkAnswer}></YesOrNoOptionBtn> :

              <QuizOptionBtn className=" inset-x-0 bottom-0 h-16" textSize={(device == "mobile") ? parSizeM[fontSize] : parSize[fontSize]} style={{ marginBottom: "17em" }} textColor={Settings?.secondaryColor ?? "black"} BgColor={Settings?.primaryColor ?? "white"} device={device} checkAnswer={checkAnswer} options={questions[curQuizIndex]?.options}></QuizOptionBtn>
            }</div>
        </div>
      </div>
    </>
  );
};
