import { defaults } from "autoprefixer";
import React from "react";

const IndustryData = () =>{

    return [
        {
            icon:"asset/images/industry/1.png",
            title:"Food Industry",
        },
        {
            icon:"asset/images/industry/2.png",
            title:"Ecommerce",
        },
        {
            icon:"asset/images/industry/3.png",
            title:"Software",
        },
        {
            icon:"asset/images/industry/4.png",
            title:"Construction",
        },
        {
            icon:"asset/images/industry/5.png",
            title:"Beauty & Cosmetics",
        },
        {
            icon:"asset/images/industry/6.png",
            title:"Photography",
        },
        {
            icon:"asset/images/industry/7.png",
            title:"Logistics & Transportation",
        },
        {
            icon:"asset/images/industry/8.png",
            title:"Accounting",
        },
        {
            icon:"asset/images/industry/9.png",
            title:"Human Resources",
        },
        {
            icon:"asset/images/industry/10.png",
            title:"Banking & Finance",
        },
        {
            icon:"asset/images/industry/11.png",
            title:"Gaming",
        },
        {
            icon:"asset/images/industry/12.png",
            title:"Aviation",
        },

    ] 

}

export default IndustryData;