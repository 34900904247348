import React, { useRef } from "react";
import { AiOutlineEye,AiOutlineEyeInvisible } from "react-icons/ai";

const InputWithLabel = ({
    id= "",
    mainclass='',
    viewiconState=false,
    eyeStatus=true,
    name ="",
    label='Label name',
    labelTextsize = '',
    labelTextWeight = '',
    type="text",
    Placeholder ="",
    fontweight = "",
    margin = "mb-3",
    spaceY = 'space-y-2',
    padding = "",
    textvalue =null,
    disabledinput = false,
    width = "w-full",
    onchange = null,
    height='h-10',
    value,
    className="",
    isAutocomplete=false,
    required=false,
    min="",
    lableColor="#000000",
    ...prop
}) => {

        const Ref = useRef(textvalue);

     return (

         <div className={`${spaceY} ${mainclass}`}>
            <label htmlFor={label} className={` ${labelTextsize} ${labelTextWeight} capitalize`}><span style={{color:lableColor}}>{label}</span>{viewiconState &&<span>{eyeStatus?<AiOutlineEye/>:<AiOutlineEyeInvisible/>}</span>}</label>
            <input ref={Ref}  type={type} required={required} className={`gz-basic-input ${height} block ${padding} text-14px text-black appearance-none bg-white  focus:border-primary  text-primary-gray  placeholder-gray-600 border rounded-8px ${width} px-14px leading-5 placeholder-opacity-40 ${margin} border-secondar ${className}`} id={id} name={label} placeholder={Placeholder} disabled={disabledinput} onChange = {onchange} value={value} defaultValue ={Ref.current} autoComplete={isAutocomplete} min={min} />
         </div>
     )
}

export default InputWithLabel;