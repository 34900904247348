import React, { useState } from "react";
import IndustryData from "../../../content/industrydata";

export const IndustryChooseIntro = ({
    forWardAction=null,
    indactive=0,
    setIndActive = null
}) => {


    const industryData = IndustryData();


    const statechange = (index) => {
        setIndActive(index);
        forWardAction(4)        
    }

    return (
        <section className="flex flex-col w-full h-full overflow-y-scroll list-industry" >

            <div className="w-[75%] flex flex-col mx-auto justify-center my-auto space-y-4 py-10 ">
                <h3 className="text-xl font-semibold text-center" >Choose industry</h3>

                <div className="w-full grid grid-cols-4 gap-5 p-4 bg-[#FAFCFE] rounded-xl  " >
                    {industryData.map((industry, index) => {

                        return (
                            <>
                                <div key={index} className={`  flex shadow-card-rate w-full flex-col justify-center ${(indactive == index) ? "bg-primary" : "bg-white"}   rounded-xl  mx-auto  px-2 py-6 cursor-pointer`} onClick={() => statechange(index)} >
                                    <div className="mx-auto scale-75">
                                        <img src={`${industry.icon}`} />
                                    </div>
                                    <p className="mx-auto font-medium text-sm capitalize text-center ">{industry.title}</p>
                                </div>
                            </>

                        )
                    })}
                </div>

            </div>

        </section>
    )
}