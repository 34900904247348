import React, { useState } from "react";
import { listOfIcon } from "../content/iconList";


export const CardBlock = ({ pairval = '', obj = {}, setvalue = null ,inDex = 0, toggletype=null, OnClick=null ,IsRearrage= false}) => {

   
    


    const AdjustSize = (e) => {

        if (e.target.scrollHeight < 150) e.target.style.height = `${e.target.scrollHeight}px`;
        if (e.target.value == '') e.target.style.height = '2.5rem'
    }

    const TextChange = (e) => {
        toggletype(e.target.value,'text',inDex,pairval)
    }

    return (
        <div className={`relative ${!IsRearrage ? "w-1/2":"w-full"} mx-auto`}>
            <span class={`w-full flex ${obj?.type !== 'image' ? "h-[150px]" : "h-[150px]"}   ${ obj?.type !== 'image' ? "bg-white " : "bg-slate-50 cursor-pointer"} border-1 my-auto rounded-md overflow-hidden`} onClick={()=>{if(obj?.type === 'image') OnClick(pairval,inDex,obj?.type === 'image'?obj.src:obj.content,obj?.type)}} >

                {
                      obj?.type == 'image'?

                        <img src={obj?.src ? obj?.src : '/asset/images/templateCollect/Flying_Dice.png'} alt={``} title={``} className={'w-auto h-auto p-3 m-auto object-cover max-h-[100%] cursor-pointer'} />

                        : <textarea className="w-fit max-w-full max-h-full  h-[2.5rem] text-center m-auto outline-none resize-none overflow-hidden text-lg font-medium" onKeyUp={(e) => AdjustSize(e)} defaultValue={obj?.content ? obj?.content :"Text"} onChange={TextChange}  maxLength={52} ></textarea>
                }

            </span>
            <div className="absolute bottom-2 right-2 w-full flex justify-end space-x-2">

                <span className={`my-auto bg-primary-lightblue p-2 ${(obj?.type == 'image') ? 'border-primary' : ''} border-1 cursor-pointer rounded-lg`} onClick={() => { toggletype(obj.src,'image',inDex,pairval) }} >

                    {
                        listOfIcon( 'img', 14, 14, '#2563EB')
                    }

                </span>

                <span className={`my-auto bg-white px-2.5 py-0.5 ${(obj?.type !== 'image') ? 'border-primary' : ''} border-1 cursor-pointer rounded-lg`} onClick={() => { toggletype(obj.content == "" ? "Text" : obj.content ,'text',inDex,pairval) }}>T</span>

            </div>
        </div>
    )
}