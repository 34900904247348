import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditorTooltip } from "../Editor/common/editortooltip";
import { isEmpty } from "../../utils/helpers";
import { Actions } from "../../store";

export const LogoBlock = ({ fieldID = '', position ,priview=false }) => {

    const { Fields } = useSelector((state)=>({Fields:state.fields}));
    const DisPatch = useDispatch()

    const LogoContent = Fields[fieldID]; 
    const ImageREf = useRef(null);

    const [imgWidth,setImgWidth] = useState(160)
    const [imgheight,setImgheight] = useState(160)
    const LogoPosition = String(LogoContent?.filedContent?.Position).toLowerCase()
    // useEffect(()=>{
    //     if(ImageREf.current.offsetWidth > 160) {

    //         setImgWidth(ImageREf.current.offsetWidth)
    //         setImgheight(ImageREf.current.offsetheight)
    //     }
    // },[LogoContent])

    return (
        <div id={"gameFrameBorder"+fieldID}  className={`relative  flex flex-col py-4 group `} onClick={()=>{DisPatch(Actions.setActiveFieldside({filedId:fieldID,index:position,}))}}>
            {!priview &&<EditorTooltip position={position}/>}

            <div className={`flex w-fit h-fit max-w-full mx-auto py-4 ${LogoPosition === 'right' ? "justify-end" : LogoPosition === 'left' ? 'justify-start' :"justify-center" }`} >
                <img ref={ImageREf}  alt="Logo"
                    src={isEmpty(LogoContent?.filedContent?.imageSrc) ? "/asset/images/templateCollect/Match_the_Animal_Names.png" : LogoContent?.filedContent?.imageSrc} 
                    className={'w-auto h-auto flex object-cover'} style={{width:LogoContent?.filedContent?.Scale+'%',borderRadius:LogoContent?.filedContent?.Radius+"px"}}
                    />
            </div>
        </div>

    )
}