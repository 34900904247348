export const commonFileds = {
    "title": {
      "label": "Title",
      "type": "title",
      "sidePanel": false,
      "fieldProps": [
        {
          "label": "Change Title",
          "inputType": "text",
          "type": "Content",
          "defaultVaule": "Create Title"
        },
        {
          "label": "Font Family",
          "inputType": "dropDown",
          "type": "FontFamily",
          "defaultVaule": ""
        },
        {
          "label": "Font Colour",
          "inputType": "color",
          "type": "TextColor",
          "defaultVaule": "#06152D"
        },
        {
          "label": "Hide",
          "inputType": "swicth",
          "type": "DisplayView",
          "defaultVaule": false
        }
      ]
    },
    "description": {
      "label": "Description",
      "type": "description",
      "sidePanel": false,
      "fieldProps": [
        {
          "label": "Change Description",
          "inputType": "textarea",
          "type": "Content",
          "defaultVaule": "Create Description"
        },
        {
          "label": "Font Family",
          "inputType": "dropDown",
          "type": "FontFamily",
          "defaultVaule": ""
        },
        {
          "label": "Font Colour",
          "inputType": "color",
          "type": "TextColor",
          "defaultVaule": "#06152D"
        },
        {
          "label": "Hide",
          "inputType": "swicth",
          "type": "DisplayView",
          "defaultVaule": false
        }
      ]
    },
    "button": {
      "label": "Button",
      "type": "button",
      "sidePanel": false,
      "fieldProps": [
        {
          "label": "Link",
          "inputType": "text",
          "type": "RedirUrl",
          "defaultVaule": ""
        },
        {
          "label": "Button Text",
          "inputType": "text",
          "type": "text",
          "defaultVaule": "Visit"
        },
        {
          "label": "Corner Radius",
          "inputType": "text",
          "type": "Radius",
          "defaultVaule": "10px"
        },
        {
          "label": "Button Colour",
          "inputType": "color",
          "type": "BgColor",
          "defaultVaule": "#06152D"
        },
        {
          "label": "Button Border Colour",
          "inputType": "color",
          "type": "BorderColor",
          "defaultVaule": "#06152D"
        },
        {
          "label": "Button Text Colour",
          "inputType": "color",
          "type": "TextColor",
          "defaultVaule": "#FFFFFF"
        },
        {
          "label": "Hide",
          "inputType": "swicth",
          "type": "DisplayView",
          "defaultVaule": false
        }
      ]
    },
    "image": {
      "label": "Image",
      "type": "image",
      "sidePanel": false,
      "fieldProps": [
        {
          "label": "Upload",
          "inputType": "Upload",
          "type": "imageSrc",
          "defaultVaule": "https://gz-engage-assets.s3.amazonaws.com/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/7db86ff95d341d99faa9a3ec88df8ff9.png"
        },
        {
          "label": "Hide",
          "inputType": "swicth",
          "type": "DisplayView",
          "defaultVaule": false
        }
      ]
    },
    "logo": {
      "label": "Logo",
      "type": "logo",
      "sidePanel": false,
      "fieldProps": [
        {
          "label": "Upload",
          "inputType": "Upload",
          "type": "imageSrc",
          "defaultVaule": "https://gz-engage-assets.s3.amazonaws.com/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/967805528197cce3f5749de4363c492d.jpg"
        },
        {
          "label": "Scale (%)",
          "inputType": "number",
          "type": "Scale",
          "defaultVaule": 30
        },
        {
          "label": "Corner Radius (px)",
          "inputType": "number",
          "type": "Radius",
          "defaultVaule": 10
        },
        {
          "label": "Position",
          "inputType": "radio",
          "type": "Position",
          "defaultVaule": "left"
        },
        {
          "label": "Hide",
          "inputType": "swicth",
          "type": "DisplayView",
          "defaultVaule": false
        }
      ]
    },
    "email": {
      "label": "Email Input",
      "type": "email",
      "sidePanel": false,
      "fieldProps": [
        {
          "label": "Input Placeholder",
          "inputType": "text",
          "type": "Placeholder",
          "defaultVaule": "Example.gmail.com"
        },
        {
          "label": "Required",
          "inputType": "checkBox",
          "type": "Required",
          "defaultVaule": true
        },
        {
          "label": "Required Message",
          "inputType": "text",
          "type": "RequiredMessage",
          "defaultVaule": ""
        },
        {
          "label": "Button Content",
          "inputType": "text",
          "type": "buttonText",
          "defaultVaule": "Submit"
        },
        {
          "label": "Button Colour",
          "inputType": "color",
          "type": "BgColor",
          "defaultVaule": "#06152D"
        },
        {
          "label": "Button Text Colour",
          "inputType": "color",
          "type": "TextColor",
          "defaultVaule": "#FFFFFF"
        },
        {
          "label": "Hide",
          "inputType": "swicth",
          "type": "DisplayView",
          "defaultVaule": false
        }
      ]
    },
    "video": {
      "label": "Youtube Video",
      "type": "video",
      "sidePanel": false,
      "fieldProps": [
        {
          "label": "Video URL Link",
          "inputType": "text",
          "type": "VideoUrl",
          "defaultVaule": "bBqzSBoIlK0"
        },
        {
          "label": "Hide",
          "inputType": "swicth",
          "type": "DisplayView",
          "defaultVaule": false
        }
      ]
    },
    "audio": {
      "label": "Audio",
      "type": "audio",
      "sidePanel": false,
      "fieldProps": [
        {
          "label": "Upload",
          "inputType": "Upload",
          "type": "audioSrc",
          "defaultVaule": "https://gz-engage-assets.s3.amazonaws.com/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/audio/b834238ca631712e12a104bdf432ed3e.mp3"
        },
        {
          "label": "Loop",
          "inputType": "checkBox",
          "type": "audioLoop",
          "defaultVaule": true
        },
        {
          "label": "Auto Play",
          "inputType": "checkBox",
          "type": "audioAutoPlay",
          "defaultVaule": true
        },
        {
          "label": "Hide",
          "inputType": "swicth",
          "type": "DisplayView",
          "defaultVaule": false
        }
      ]
    },
    "embed": {
      "label": "Embed Code",
      "type": "embed",
      "sidePanel": false,
      "fieldProps": [
        {
          "label": "Embed Code",
          "inputType": "textarea",
          "type": "EmbedCode",
          "defaultVaule": ""
        },
        {
          "label": "Hide",
          "inputType": "swicth",
          "type": "DisplayView",
          "defaultVaule": false
        }
      ]
    }
  }