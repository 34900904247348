export var topnave_flow=[    {
    element: ".step-tab-design",
    title: "Design",
    intro: "Design is the process of coming up with creative visual solutions that combine functionality and creativity to improve user experience visually.",
},    {
    element: ".step-tab-integration",
    title: "Integrations",
    intro: "Integrations enable seamless connections between systems, enhancing efficiency and collaboration across diverse platforms.",
},    {
    element: ".step-tab-result",
    title: "Result",
    intro: "Summarise results with views, started, responses, and finished metrics for a comprehensive overview.",
},    {
    element: ".step-tab-share",
    title: "Share",
    intro: "The share field allows users to distribute content, facilitating easy sharing of information across platforms, enhancing accessibility and engagement.",
}, 
{
    element: ".step-tab-previewBtn",
    title: "Preview",
    intro: "The preview button provides a glimpse of the design before finalising, ensuring accuracy and visual appeal in real-time.",
}, 
{
    element: ".step-tab-publishBtn",
    title: "Publish",
    intro: "The publish button initiates the process of making content accessible to the public, enabling widespread visibility and interaction.",
}, 
]