export const editDeviceIcon = () => {
    return [
        {
            icon:(<svg width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.9997 0.714294H2.99967C1.53301 0.714294 0.333008 1.91429 0.333008 3.38096V19.381C0.333008 20.8476 1.53301 22.0476 2.99967 22.0476H12.333V24.7143H10.9997C10.2663 24.7143 9.66634 25.3143 9.66634 26.0476C9.66634 26.781 10.2663 27.381 10.9997 27.381H18.9997C19.733 27.381 20.333 26.781 20.333 26.0476C20.333 25.3143 19.733 24.7143 18.9997 24.7143H17.6663V22.0476H26.9997C28.4663 22.0476 29.6663 20.8476 29.6663 19.381V3.38096C29.6663 1.91429 28.4663 0.714294 26.9997 0.714294ZM25.6663 19.381H4.33301C3.59967 19.381 2.99967 18.781 2.99967 18.0476V4.71429C2.99967 3.98096 3.59967 3.38096 4.33301 3.38096H25.6663C26.3997 3.38096 26.9997 3.98096 26.9997 4.71429V18.0476C26.9997 18.781 26.3997 19.381 25.6663 19.381Z" fill="#06152D"/>
            </svg>),
            activeicon:(<svg width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.9997 0.714294H2.99967C1.53301 0.714294 0.333008 1.91429 0.333008 3.38096V19.381C0.333008 20.8476 1.53301 22.0476 2.99967 22.0476H12.333V24.7143H10.9997C10.2663 24.7143 9.66634 25.3143 9.66634 26.0476C9.66634 26.781 10.2663 27.381 10.9997 27.381H18.9997C19.733 27.381 20.333 26.781 20.333 26.0476C20.333 25.3143 19.733 24.7143 18.9997 24.7143H17.6663V22.0476H26.9997C28.4663 22.0476 29.6663 20.8476 29.6663 19.381V3.38096C29.6663 1.91429 28.4663 0.714294 26.9997 0.714294ZM25.6663 19.381H4.33301C3.59967 19.381 2.99967 18.781 2.99967 18.0476V4.71429C2.99967 3.98096 3.59967 3.38096 4.33301 3.38096H25.6663C26.3997 3.38096 26.9997 3.98096 26.9997 4.71429V18.0476C26.9997 18.781 26.3997 19.381 25.6663 19.381Z" fill="#2563EB"/>
            </svg>),
        },
        {
            icon:(<svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.667 6.10352e-05H4.00033C2.16033 6.10352e-05 0.666992 1.49339 0.666992 3.33339V28.6667C0.666992 30.5067 2.16033 32.0001 4.00033 32.0001H22.667C24.507 32.0001 26.0003 30.5067 26.0003 28.6667V3.33339C26.0003 1.49339 24.507 6.10352e-05 22.667 6.10352e-05ZM13.3337 30.6667C12.227 30.6667 11.3337 29.7734 11.3337 28.6667C11.3337 27.5601 12.227 26.6667 13.3337 26.6667C14.4403 26.6667 15.3337 27.5601 15.3337 28.6667C15.3337 29.7734 14.4403 30.6667 13.3337 30.6667ZM23.3337 25.3334H3.33366V4.00006H23.3337V25.3334Z" fill="#06152D"/>
            </svg>),
            activeicon:(<svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.667 6.10352e-05H4.00033C2.16033 6.10352e-05 0.666992 1.49339 0.666992 3.33339V28.6667C0.666992 30.5067 2.16033 32.0001 4.00033 32.0001H22.667C24.507 32.0001 26.0003 30.5067 26.0003 28.6667V3.33339C26.0003 1.49339 24.507 6.10352e-05 22.667 6.10352e-05ZM13.3337 30.6667C12.227 30.6667 11.3337 29.7734 11.3337 28.6667C11.3337 27.5601 12.227 26.6667 13.3337 26.6667C14.4403 26.6667 15.3337 27.5601 15.3337 28.6667C15.3337 29.7734 14.4403 30.6667 13.3337 30.6667ZM23.3337 25.3334H3.33366V4.00006H23.3337V25.3334Z" fill="#2563EB"/>
            </svg>),
        },
        {
            icon:(<svg width="20" height="30" viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3337 0.285828H4.66699C2.45366 0.285828 0.666992 2.07249 0.666992 4.28583V25.6192C0.666992 27.8325 2.45366 29.6192 4.66699 29.6192H15.3337C17.547 29.6192 19.3337 27.8325 19.3337 25.6192V4.28583C19.3337 2.07249 17.547 0.285828 15.3337 0.285828ZM12.0003 26.9525H8.00033C7.62699 26.9525 7.33366 26.6592 7.33366 26.2858C7.33366 25.9125 7.62699 25.6192 8.00033 25.6192H12.0003C12.3737 25.6192 12.667 25.9125 12.667 26.2858C12.667 26.6592 12.3737 26.9525 12.0003 26.9525ZM16.667 22.9525H3.33366V4.28583H16.667V22.9525Z" fill="#06152D"/>
            </svg>),
            activeicon:(<svg width="20" height="30" viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.3337 0.285828H4.66699C2.45366 0.285828 0.666992 2.07249 0.666992 4.28583V25.6192C0.666992 27.8325 2.45366 29.6192 4.66699 29.6192H15.3337C17.547 29.6192 19.3337 27.8325 19.3337 25.6192V4.28583C19.3337 2.07249 17.547 0.285828 15.3337 0.285828ZM12.0003 26.9525H8.00033C7.62699 26.9525 7.33366 26.6592 7.33366 26.2858C7.33366 25.9125 7.62699 25.6192 8.00033 25.6192H12.0003C12.3737 25.6192 12.667 25.9125 12.667 26.2858C12.667 26.6592 12.3737 26.9525 12.0003 26.9525ZM16.667 22.9525H3.33366V4.28583H16.667V22.9525Z" fill="#2563EB"/>
            </svg>),
        },
    ];
}

export const editericon = () => {

    return [
        {
            preview:[
                {
                    icon:(<svg width="18" height="8" viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.41699 0.666748C7.20866 0.666748 5.20866 1.49175 3.66699 2.83342L2.09199 1.25841C1.56699 0.733415 0.666992 1.10008 0.666992 1.84175V6.50008C0.666992 6.95842 1.04199 7.33342 1.50033 7.33342H6.15866C6.90033 7.33342 7.27533 6.43341 6.75033 5.90841L5.15866 4.31675C6.31699 3.35008 7.79199 2.75008 9.42533 2.75008C12.0587 2.75008 14.3337 4.28341 15.417 6.50008C15.642 6.96675 16.1753 7.20008 16.667 7.03342C17.2587 6.84175 17.5587 6.16675 17.292 5.60008C15.8587 2.68341 12.8753 0.666748 9.41699 0.666748Z" fill="#06152D"/>
                    </svg>
                    ),
                },
                {
                    icon:(<svg width="18" height="8" viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.3329 2.83342C12.7912 1.49175 10.7912 0.666748 8.58288 0.666748C5.11621 0.666748 2.13288 2.68342 0.716211 5.60842C0.449545 6.16675 0.749545 6.83342 1.34121 7.03342C1.83288 7.20008 2.36621 6.96675 2.59121 6.50008C3.67455 4.28341 5.94955 2.75008 8.58288 2.75008C10.2079 2.75008 11.6912 3.35008 12.8495 4.31675L11.2579 5.90841C10.7329 6.43341 11.0995 7.33342 11.8412 7.33342H16.4995C16.9579 7.33342 17.3329 6.95842 17.3329 6.50008V1.84175C17.3329 1.10008 16.4329 0.725081 15.9079 1.25008L14.3329 2.83342Z" fill="#06152D"/>
                    </svg>
                    ),
                },
            ],
            fontfamily:[
                {
                    icon:(<svg width="6" height="5" viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.258783 1.75825L2.41712 3.91659C2.74212 4.24159 3.26712 4.24159 3.59212 3.91659L5.75045 1.75825C6.27545 1.23325 5.90045 0.333252 5.15878 0.333252H0.842116C0.10045 0.333252 -0.266217 1.23325 0.258783 1.75825Z" fill="#06152D"/>
                    </svg>
                    ),
                },
                {
                    icon:(<svg width="6" height="5" viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.258783 1.75825L2.41712 3.91659C2.74212 4.24159 3.26712 4.24159 3.59212 3.91659L5.75045 1.75825C6.27545 1.23325 5.90045 0.333252 5.15878 0.333252H0.842116C0.10045 0.333252 -0.266217 1.23325 0.258783 1.75825Z" fill="#06152D"/>
                    </svg>
                    ),
                },
            ],
            // fontstyle:[
            //     {
            //         icon:(),
            //     },
            //     {
            //         icon:(),
            //     },
            //     {
            //         icon:(),
            //     },
            //     {
            //         icon:(),
            //     },
            //     {
            //         icon:(),
            //     },

            // ],
            // paragraph:[
            //     {
            //         icon:(),
            //     },
            //     {
            //         icon:(),
            //     },
            // ],
            // liststyle:[
            //     {
            //         icon:(),
            //     },
            //     {
            //         icon:(),
            //     },
            // ],
        }
    ]
}