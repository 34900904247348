import React, { useEffect, useRef } from "react";
import PrimaryButton from "../Button1/primarybutton";

export const AlertPopup = ({ Accecpt, Cancel, title, className }) => {

    const AiPopupGCREF = useRef(null);

    useEffect(() => {
        let handleAction = (event) => {

            if (AiPopupGCREF.current && !AiPopupGCREF.current?.contains(event.target)) {
                // alert("test-out")
                Cancel(false);
            }
        };

        document.addEventListener("mousedown", handleAction);
    }, [])


    return (
        <div className="fixed top-0 left-0 flex w-full h-full justify-center  bg-backgroundShadow overflow-y-auto " style={{ zIndex: '120' }}>

            <div className={`relative p-8 w-[300px] rounded-xl bg-white m-auto space-y-4 ${className} `} ref={AiPopupGCREF}  >

                <p className="capitalize  text-center font-medium">{title}</p>

                <div className="flex justify-center space-x-2">
                    <PrimaryButton buttontype={'secondarybutton'} border="border-1 border-slate-300" textcolor="text-black" width={'w-24'} text={'cancel'} OnClick={()=>Accecpt(false)} />
                    <PrimaryButton text={'Done'} width={'w-24'} OnClick={()=>Accecpt(true)} />
                </div>

            </div>

        </div>
    )
}