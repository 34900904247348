import React from 'react'

export const BillingLayout = ({className, children }) => {
  return (
    <div className={` rounded-2xl  shadow-lg ${className}`}>
        {children}

    </div>
  )
}
