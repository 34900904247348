import React, { useEffect, useRef } from "react";
import DefaultDropMenu from "../input/droplist";
import InputWithLabel from "../input/inputWithLabel";
import PrimaryButton from "../Button1/primarybutton";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../store";
import Input from "../input/input";

export const ProductFilter = ({
    className,
    questionlist = [],
    cancel = null,
    ResultINdex=0,
    filedId = ''
}) => {

    const { filedList } = useSelector((state) => ({ filedList: state.fields[filedId] }))

    const TempType = filedList?.type
    const Settings = filedList?.filedContent?.game_resultcreen_json?.results[ResultINdex]
    const FCObjectData = Settings?.FilterCondition ?? {}

    const FilterObjectArr = Object.values(FCObjectData) ?? []
    const FilterObjectkeyArr = Object.keys(FCObjectData) ?? []

    const menuREf = useRef(null);
    const Dispatch = useDispatch() 

    const FilterQuestion = questionlist.map((list) => list.question)
    const ConditionalOperator = ['Select the operator', 'Equal to', 'Not equal to', "Contains", "Not contains", "Less than", "Greater than"]
    const Product = ['title', 'price', 'tag']

    const changeAction = (value, filterKey = '', edge) => {
        const setData = {
            fieldId: filedId,
            node: 'game_resultcreen_json',
            child: "FilterCondition",
            filterKey: filterKey,
            resultIndex:ResultINdex,
            edge: edge,
            value: value
        }
        Dispatch(Actions.setProductCondition(setData))

    }

    const appProdutFilter = (filterKey = '', edge) => {
        let product = {
            "question": FilterQuestion[0],
            "operator": ConditionalOperator[0],
            "Target": Product[0],
            "condition": "",
            "pairQue": ""
        }

        const setData = {
            Isadd: true,
            fieldId: filedId,
            node: 'game_resultcreen_json',
            child: "FilterCondition",
            filterKey: filterKey,
            resultIndex:ResultINdex,
            edge: edge,
            value: product
        }

        Dispatch(Actions.setProductCondition(setData))
    }

    const deleteFilter = (filterKey) => {
        const setData = {
            IsDelete: true,
            fieldId: filedId,
            node: 'game_resultcreen_json',
            resultIndex:ResultINdex,
            child: "FilterCondition",
            filterKey: filterKey,
        }
        Dispatch(Actions.setProductCondition(setData))

    }

    useEffect(() => {

        let handleAction = (event) => {
            if (menuREf.current && !menuREf.current?.contains(event.target)) {

                // cancel(false);
            }
        };

        document.addEventListener("mousedown", handleAction);

    }, [])

    return (
        <div className={`fixed flex bg-black bg-opacity-5 w-full h-full shadow-lg top-0 left-0 ${className}`} style={{ zIndex: '170' }}>

            <div className="relative bg-white w-4/5 h-4/5  flex flex-col border-1 p-3 rounded-lg m-auto" ref={menuREf} >
                <span className="absolute right-5 top-4 cursor-pointer bg-white" onClick={() => cancel(false)}>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.825 6.18562C19.4025 5.76312 18.72 5.76312 18.2975 6.18562L13 11.4723L7.70253 6.17479C7.28003 5.75229 6.59753 5.75229 6.17503 6.17479C5.75253 6.59729 5.75253 7.27979 6.17503 7.70229L11.4725 12.9998L6.17503 18.2973C5.75253 18.7198 5.75253 19.4023 6.17503 19.8248C6.59753 20.2473 7.28003 20.2473 7.70253 19.8248L13 14.5273L18.2975 19.8248C18.72 20.2473 19.4025 20.2473 19.825 19.8248C20.2475 19.4023 20.2475 18.7198 19.825 18.2973L14.5275 12.9998L19.825 7.70229C20.2367 7.29062 20.2367 6.59729 19.825 6.18562Z" fill="#586474" />
                    </svg>
                </span>
                <div className="flex border-b-2 px-4 space-x-4">
                    <p className="py-2 font-semibold text-lg noselect" >Product Filter Config</p>
                </div>
                <div className="flex w-full h-full overflow-y-auto">
                    {/* <div className="flex flex-col my-2 max-w-[250px] space-y-4 pr-2 border-r-1">

                        {
                            questionlist.map(list => {
                                return (

                                    <p className={`w-[200px] whitespace-nowrap overflow-hidden text-ellipsis border-1 rounded-lg py-3 px-4 cursor-pointer `} >{list?.question}</p>
                                )
                            })
                        }

                    </div> */}
                    <div className={`w-full h-full grid grid-cols-1 mx-auto px-4 overflow-y-auto list-industry`} >
                        <div className="flex flex-col  w-full mx-auto h-fit py-4 space-y-2">

                            <div className="flex  flex-col h-fit mx-auto w-full space-y-3">
                                {
                                    (FilterObjectArr.length > 0) ?
                                        FilterObjectArr.map((list,index) => {
                                            let CheckProductList =  Product.findIndex(listobj => listobj === list.Target )
                                            let CheckOpertorList =  ConditionalOperator.findIndex(listobj => listobj === list.operator )
                                            let CheckQuestionList =  FilterQuestion.findIndex(listobj => listobj === list.question )
                                            return (
                                                <div className="flex w-full flex-row">

                                                    <div className="flex justify-around items-center w-[85%]">
                                                    <DefaultDropMenu  mainclass="w-[46%]" labelTextWeight="hidden" spaceY="" IsRule={true} active={CheckQuestionList > 0 ? CheckQuestionList : 0} fieldType="conditions" SelectIndex={true} onchange={(ind) =>  changeAction(FilterQuestion[ind],FilterObjectkeyArr[index],"question")} list={FilterQuestion} />
                                                    <DefaultDropMenu mainclass="w-[25%]" labelTextWeight="hidden" spaceY="" IsRule={true} active={CheckProductList > 0 ? CheckProductList : 0} fieldType="conditions" SelectIndex={true} onchange={(ind) =>  changeAction(Product[ind],FilterObjectkeyArr[index],"Target")} list={Product}  />
                                                    <DefaultDropMenu mainclass="w-[25%]"  labelTextWeight="hidden" spaceY="" IsRule={true} active={CheckOpertorList > 0 ? CheckOpertorList : 0} fieldType="conditions" SelectIndex={true} onchange={(ind) =>  changeAction(ConditionalOperator[ind],FilterObjectkeyArr[index],"operator")} list={ConditionalOperator} />
                                                  
                                                  
                 
                                                    </div>

                                                    <div className=" flex justify-center w-[15%]">
                                                    <div className="flex w-[40%] justify-center items-center">
                                                    <div className="flex border-1 justify-center items-center rounded-md cursor-pointer px-2 py-2 my-auto  h-[95%] aspect-square" onClick={()=>deleteFilter(FilterObjectkeyArr[index])} >
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M15.7506 5.04785C15.7356 5.04785 15.7131 5.04785 15.6906 5.04785C11.7231 4.65035 7.76318 4.50035 3.84071 4.89785L2.31072 5.04785C1.99573 5.07785 1.71823 4.85285 1.68823 4.53785C1.65823 4.22285 1.88323 3.95285 2.19073 3.92285L3.72071 3.77285C7.71068 3.36785 11.7531 3.52535 15.8031 3.92285C16.1106 3.95285 16.3356 4.23035 16.3056 4.53785C16.2831 4.83035 16.0356 5.04785 15.7506 5.04785Z" fill="#586474" />
                                                            <path d="M6.37555 4.29C6.34555 4.29 6.31555 4.29 6.27805 4.2825C5.97805 4.23 5.76806 3.9375 5.82056 3.6375L5.98555 2.655C6.10555 1.935 6.27055 0.9375 8.01804 0.9375H9.98302C11.738 0.9375 11.903 1.9725 12.0155 2.6625L12.1805 3.6375C12.233 3.945 12.023 4.2375 11.723 4.2825C11.4155 4.335 11.123 4.125 11.078 3.825L10.913 2.85C10.808 2.1975 10.7855 2.07 9.99052 2.07H8.02554C7.23054 2.07 7.21554 2.175 7.10305 2.8425L6.93055 3.8175C6.88555 4.095 6.64555 4.29 6.37555 4.29Z" fill="#586474" />
                                                            <path d="M11.4077 17.0627H6.59276C3.97528 17.0627 3.87029 15.6152 3.78779 14.4452L3.30029 6.89268C3.27779 6.58518 3.51779 6.31518 3.82529 6.29268C4.14028 6.27768 4.40278 6.51018 4.42528 6.81768L4.91278 14.3702C4.99528 15.5102 5.02527 15.9377 6.59276 15.9377H11.4077C12.9827 15.9377 13.0127 15.5102 13.0877 14.3702L13.5752 6.81768C13.5977 6.51018 13.8677 6.27768 14.1752 6.29268C14.4827 6.31518 14.7227 6.57768 14.7002 6.89268L14.2127 14.4452C14.1302 15.6152 14.0252 17.0627 11.4077 17.0627Z" fill="#586474" />
                                                            <path d="M10.2455 12.9375H7.74804C7.44054 12.9375 7.18555 12.6825 7.18555 12.375C7.18555 12.0675 7.44054 11.8125 7.74804 11.8125H10.2455C10.553 11.8125 10.808 12.0675 10.808 12.375C10.808 12.6825 10.553 12.9375 10.2455 12.9375Z" fill="#586474" />
                                                            <path d="M10.875 9.9375H7.125C6.8175 9.9375 6.5625 9.6825 6.5625 9.375C6.5625 9.0675 6.8175 8.8125 7.125 8.8125H10.875C11.1825 8.8125 11.4375 9.0675 11.4375 9.375C11.4375 9.6825 11.1825 9.9375 10.875 9.9375Z" fill="#586474" />
                                                        </svg>
                                                    </div>
                                                    </div>
                                                    
                                                    <div className="flex w-[60%] justify-center items-center">
                                                    { FilterObjectArr.length-1 === index && <PrimaryButton text={"Add"} backgroundColor="bg-white" classNames={'border-1 border-primary'} margin=" my-auto" height="h-auto" textcolor="text-primary" padding_y="py-2" OnClick={()=>appProdutFilter()} />}
                                                    </div>
                                                    </div>
                                                 
                                                </div>
                                            )
                                        }): <>

                                        <center><PrimaryButton text={"Add Filter Condition"} width="w-[300px]"  backgroundColor="bg-white" classNames={'border-1 border-primary'} margin="my-auto" height="h-auto" textcolor="text-primary" padding_y="py-2" OnClick={()=>appProdutFilter()} /></center>
                                        </>

                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}