import React, { useEffect } from "react";
import { useState } from "react";
import { editDeviceIcon } from "../../content/icon";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../store";

const HandleIcon = ({activeState=null}) => {

    const {  decivestate } = useSelector((state) => ({  decivestate: state.device }))
    
    
    const Deviceicon = editDeviceIcon();
    const Dispatch = useDispatch()
    const [IsActive,setActive] = useState(0);

    // let deviceArray=["desktop","tab","mobile" ]
    // useEffect(()=>{activeDevice( deviceArray.indexOf(decivestate)) },[])


    const activeDevice = (index)=>{
        setActive(index)
        let device = index === 0 ? 'desktop' : index === 1 ? 'tab' : 'mobile'
        activeState(device)
        Dispatch(Actions.setChangeDevice(device))
    }


    return (
        <>
        <div className="flex space-x-5">
            {Deviceicon.map((icons,index)=>{
                return(<span className="my-auto cursor-pointer" key={index} onClick ={()=>activeDevice(index)} >{(IsActive == index)?icons.activeicon:icons.icon}</span>)
            })}
        </div>
        </>
    )
}

export default HandleIcon;