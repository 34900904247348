import React, { useEffect, useRef, useState } from "react";
import { Clickouter } from "../../utils/clickouter";

const DropMenu = ({
    id = "",
    mainclass = '',
    active=0,
    fieldType='',
    list = ['left', 'right'],
    name = "",
    label = 'Label name',
    labelTextsize = '',
    labelTextWeight = '',
    type = "text",
    Placeholder = "",
    fontweight = "",
    margin = "mb-0",
    spaceY = 'space-y-2',
    padding = "",
    textvalue = null,
    disabledinput = false,
    width = "w-full",
    onchange = null,
    height = 'h-10',
    ...prop
}) => {

    const [selectoption, setSelectoption] = useState(list[active])
    const [DropMenuState, setDropMenuState] = useState(false)

    const triggerClick = (state) => {
        setSelectoption(state)
        setDropMenuState(false)
        // alert(state+' '+fieldType)
        
        onchange(state,fieldType)
    }
    const menuref = useRef()
    useEffect(()=>{
        let handleAction = (event) => {
  
            if (menuref.current && !menuref.current?.contains(event.target)) {
                // alert("test-out")
                setDropMenuState(false);
            }
        };
    
        document.addEventListener("mousedown", handleAction);
    },[])

    return (
        <div className={`${spaceY} ${mainclass}`}>

            <label className={` ${labelTextsize} ${labelTextWeight} capitalize`}>{label}</label>
            <div className="relative">
                <input type={type} className={`gz-basic-input caret-transparent ${height} block ${padding} text-14px text-black appearance-none bg-white ${DropMenuState?'rounded-b-none':''}  focus:border-primary  placeholder-gray-600 border rounded-8px ${width} px-14px leading-5 placeholder-opacity-40 ${margin} cursor-pointer border-secondar`} id={id} name={name} onClick={() => setDropMenuState(true)} placeholder={Placeholder} disabled={disabledinput}  value={selectoption} />
                {DropMenuState && <div className=" w-full border-1 top-0 rounded-b-md border-t-0  max-h-[200px] overflow-y-auto" ref={menuref} >
                    {/* {ref={menuref}} */}
                    {
                        list.map((list, index) => {
                            return (
                                <p key={index} className="w-full px-4 py-3 hover:bg-gray-100 cursor-pointer noselect" onClick={() => triggerClick(list)}>{list}</p>
                            )
                        })
                    }
                </div>}
            </div>
        </div>
    )
}

export default DropMenu;