import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  getOffset } from "../../../utils/canvas";
import { EllerRow } from "../../../utils/EllerRow";
import {  isEmpty } from "../../../utils/helpers";
import { Stage as stageKonva } from "konva/lib/Stage";
import { Line as LineKonva } from "konva/lib/shapes/Line";
import { Layer as LayerKonva } from "konva/lib/Layer";
import { Image as ImageKonva } from "konva/lib/shapes/Image";
import { Actions } from "../../../store";

export const FindPathTemp = ({ obj = {}, fieldID = '', StartTime = false, prview = false, setActionCount = null, GameFinsh = null,position=0, setLoadContent=null }) => {
    const { Fields, device } = useSelector((state) => ({ Fields: state.fields, device: state.device }));

    const templateData = Fields[fieldID];
    const Settings = templateData?.filedContent
    const mainPageContent = Settings.game_mainpage_json;
    const strokColor = (mainPageContent?.strokeColor || "white")

    let levels = { "easy": 5, "normal": 7, "medium": 10, "hard": 13 }
    const BOX = levels[mainPageContent?.gameLevel];
    const [score, setScore] = useState(0);
    const [trigger, settrigger] = useState(false)
    const [drawCount, setdrawCount] = useState(0)
    const DisPatch = useDispatch()


    let width, height;

    useEffect(() => {
        if (StartTime) {
            settrigger(!trigger)

            var a;

            async function abc() {
                gameSetup();
                a = await drawMaze();
            }

            abc();
            return () => {

                destroyAll(a);
            }



        }
    }, [StartTime])


    useEffect(() => {

        document.querySelector("#gamePanel" + fieldID).style.display="hide"

        var a;
        setTimeout(async () => {
        document.querySelector("#gamePanel" + fieldID).style.display="show"

            gameSetup();
            a = await drawMaze();
        }, 450)

        return () => {
            destroyAll(a);
        }
    }, [device])



    useEffect(() => {
        var a;
        async function abc() {
            gameSetup();
            a = await drawMaze()
        }
        abc();
        return () => {
            destroyAll(a);
        }

    }, [mainPageContent, trigger])



    const gameSetup = async () => {
        setActionCount(0); setScore(0);
        setdrawCount(o => o + 1)
        //  await drawMaze();


        //      var pause = false;
        //   async  function loop(){
        //         await drawMaze();
        //             if(pause) return;
        //             window.requestionAnimationFrame(loop);
        //     }
        //    loop(); //to start it off
        //    pause = true; //to stop it 
    };


    const destroyAll = (data) => {
        if (isEmpty(data)) return;
        // data.anim.stop() 
        for (let i = 0; i < data?.blackLine?.length; i++) {
            data.blackLine[i].destroy()
        }
        data.layer.destroy()
        data.stage.destroy()

    }

    const drawMaze = () => {
        return new Promise((resolve, reject) => {
            let elm = document.querySelector("#gamePanel" + fieldID);
            width = elm.clientWidth; height = elm.clientHeight;
            let lessSize=150
            if( width < height) lessSize = width
            else lessSize = height

            document.querySelector("#gamePanel"+fieldID).style.width=lessSize+"px"
            document.querySelector("#gamePanel"+fieldID).style.height=lessSize+"px"
            
            height=lessSize
            width=lessSize
                
            // if( width > 100) elm.style.width = `${width}px`
            const DELTA_WIDTH = width / BOX, DELTA_HEIGHT = height / BOX;
            var stage = new stageKonva({ container: "gamePanel" + fieldID, width: width, height: height });

            // stage.getContainer().style.backgroundColor = '#FAFCFE';
             stage.getContainer().style.margin = 'auto';
            var layer = new LayerKonva(), oldMazeRow = null; stage.add(layer);

            var imageObj1 = new Image(); imageObj1.onload = function () {
                let square = new ImageKonva({ x: 0, y: 0, height: DELTA_HEIGHT*0.7, width: DELTA_WIDTH*0.7, image: imageObj1,offsetX:-DELTA_WIDTH*0.15,offsetY:-DELTA_HEIGHT*0.15 });


                var imageObj = new Image(); imageObj.onload = function () { let target = new ImageKonva({ x: (BOX - 1) * DELTA_WIDTH, y: (BOX - 1) * DELTA_HEIGHT, image: imageObj, width: DELTA_WIDTH, height: DELTA_HEIGHT,scaleX:0.75,scaleY:0.75,offsetX:-DELTA_WIDTH*0.25,offsetY:-DELTA_HEIGHT*0.25 }); layer.add(target); }; imageObj.src = mainPageContent?.targeticon;
                let lineStyle = { stroke: '#fffff', strokeWidth: 3, lineCap: 'round', lineJoin: 'round' };
                let curCell = { row: 0, column: 0 }, temp = [], movePathArray = [];

                var blackLine = [], kk = 0

                // GENERATE AND DRAW THE MAZE
                for (var i = 0; i < BOX; i++) {
                    let storage = {};
                    storage.mazeRow = new EllerRow({ length: BOX, previousRow: oldMazeRow, closeMaze: (i + 1 === BOX) })
                    let object = storage.mazeRow.exportToObj()
                    oldMazeRow = storage.mazeRow;
                    temp.push(object.walls)
                    let lineStyleOuter = { stroke: strokColor, strokeWidth: 5, lineCap: 'round', lineJoin: 'round' };

                    for (var j = 0; j < BOX; j++) {
                        let cellData = object.walls[j]

                        if (cellData.right) {
                            let points = { points: [(j * DELTA_WIDTH) + DELTA_WIDTH, (i * DELTA_HEIGHT), (j * DELTA_WIDTH) + DELTA_WIDTH, ((i + 1) * DELTA_HEIGHT)] }
                            blackLine[kk] = new LineKonva(Object.assign({}, lineStyleOuter, points));
                            layer.add(blackLine[kk]);
                            kk += 1;
                        }
                        if (cellData.left) {
                            let points = { points: [(j * DELTA_WIDTH) , (i * DELTA_HEIGHT), (j * DELTA_WIDTH) , ((i + 1) * DELTA_HEIGHT)] }
                            blackLine[kk] = new LineKonva(Object.assign({}, lineStyleOuter, points));
                            layer.add(blackLine[kk]);
                            kk += 1;
                        }
                        if (cellData.up) {
                            let points = { points: [(j * DELTA_WIDTH), (i * DELTA_HEIGHT) , (j * DELTA_WIDTH) + DELTA_WIDTH, (i * DELTA_HEIGHT) ] }
                            blackLine[kk] = new LineKonva(Object.assign({}, lineStyleOuter, points));
                            layer.add(blackLine[kk]);
                            kk += 1;
                        }
                        if (cellData.down) {
                            let points = { points: [(j * DELTA_WIDTH), (i * DELTA_HEIGHT) + DELTA_HEIGHT, (j * DELTA_WIDTH) + DELTA_WIDTH, (i * DELTA_HEIGHT) + DELTA_HEIGHT,] }
                            blackLine[kk] = new LineKonva(Object.assign({}, lineStyleOuter, points));
                            layer.add(blackLine[kk]);
                            kk += 1;
                        }
                    }
                    delete storage.mazeRow;
                    setLoadContent(false)

                }


                // MOVE FUNCTIONS
                const moveLeft = (type) => {
                    if (curCell.column <= 0) return;
                    if (temp[curCell.row][curCell.column - 1].right === false) {
                        // square.x(square.x() - DELTA_WIDTH);  
                        // movePathArray.push("L");
                        animateMove("L")
                        curCell.column -= 1;
                          if(type==="mouse") setActionCount(obj=>obj+1)

                    }
                }
                const moveRigth = (type) => {
                    if ((curCell.column + 1) >= BOX) return;
                    if (temp[curCell.row][curCell.column].right === false) {
                        // square.x(square.x() + DELTA_WIDTH);   
                        // movePathArray.push("R");
                        animateMove("R")
                        curCell.column += 1; 
                          if(type==="mouse") setActionCount(obj=>obj+1)

                    }
                }
                const moveUp = (type) => {
                    if (curCell.row <= 0) return;
                    if (temp[curCell.row - 1][curCell.column].down === false) {
                        // square.y(square.y() - DELTA_HEIGHT);  
                        // movePathArray.push("T");
                        animateMove("T")
                        curCell.row -= 1; 
                          if(type==="mouse") setActionCount(obj=>obj+1)

                    }
                }
                const moveDown = (type) => {
                    if ((curCell.row + 1) >= BOX) return;
                    if (temp[curCell.row][curCell.column].down === false) {
                        // square.y(square.y() + DELTA_HEIGHT);  
                        // movePathArray.push("B");
                        animateMove("B")
                        curCell.row += 1; 
                          if(type==="mouse") setActionCount(obj=>obj+1)

                    }
                }



                async function moveAt(layerX, layerY) {
                    layerX = Math.floor(layerX)
                    layerY = Math.floor(layerY)

                    if ((layerY > (curCell.row * DELTA_HEIGHT) && layerY < (curCell.row + 1) * DELTA_HEIGHT)) {
                        if (layerX > (curCell.column * (DELTA_WIDTH) - DELTA_WIDTH) && layerX < (curCell.column * DELTA_WIDTH) - DELTA_WIDTH / 20) { moveLeft("mouse") } //LEFT  
                        if (layerX > ((curCell.column + 1) * DELTA_WIDTH) + DELTA_WIDTH / 20 && layerX < ((curCell.column + 1) * DELTA_WIDTH + DELTA_WIDTH)) { moveRigth("mouse") } // RIGHT
                    }
                    if ((layerX > (curCell.column * DELTA_WIDTH) && layerX < (curCell.column + 1) * DELTA_WIDTH)) {
                        if (layerY > (curCell.row * (DELTA_HEIGHT) - DELTA_HEIGHT) && layerY < (curCell.row * DELTA_HEIGHT) - DELTA_HEIGHT / 20) { moveUp("mouse") } //TOP
                        if (layerY > ((curCell.row + 1) * DELTA_HEIGHT) + DELTA_HEIGHT / 20 && layerY < ((curCell.row + 1) * DELTA_HEIGHT + DELTA_HEIGHT)) { moveDown("mouse") } //BOTTOM 
                    }
                    if (curCell.row === (BOX - 1) && curCell.column === (BOX - 1)) {
                        curCell = { row: 0, column: 0 };
                        GameFinsh(true)
                    }
                }


                var fired = false;
                // KEYBOARD EVENT
                var container = stage.container(); container.tabIndex = 1;
                container.focus({preventScroll: true});
                container.addEventListener('keydown', function (e) {
                  e.preventDefault();
                  if (e.repeat) { return }
                  if(!fired) {
                    fired = true;
                    if (e.keyCode === 37 ) moveLeft("key")
                    else if (e.keyCode === 39)  moveRigth("key")      
                    else if (e.keyCode === 38) moveUp("key")
                    else if (e.keyCode === 40) moveDown("key")
                    else return;
                
                    if(curCell.row===(BOX-1) && curCell.column===(BOX-1) ) {
                    curCell={row:0,column:0};
                    GameFinsh(true)
                  }}
                });
                
                container.onkeyup = function() {
                fired = false;
                };

                // MOUSE EVENT  
                square.on('mousedown', function (event) {
                    stage.on('mousemove', function (e) { moveAt(e.evt.layerX, e.evt.layerY) });
                    stage.on('mouseup', function () { stage.off("mousemove"); });
                });
                square.on('mouseenter', function () { stage.container().style.cursor = 'pointer'; });
                square.on('mouseleave', function () { stage.container().style.cursor = 'default'; });


                // MOBILE EVENT
                square.addEventListener("touchstart", function (event) {
                    stage.addEventListener("touchmove", function (e) {
                        let offset = getOffset(document.querySelector("#gamePanel" + fieldID));
                        moveAt(e.changedTouches[0].pageX - offset.left, e.changedTouches[0].pageY - offset.top)
                    });
                    stage.addEventListener("touchend", function () { stage.removeEventListener('touchmove'); });
                });


                function animateMove(direction) {
                    switch (direction) {
                        case "L":
                            square.x(square.x() - DELTA_WIDTH);
                            break;
                        case "R":
                            square.x(square.x() + DELTA_WIDTH);
                            break;
                        case "T":
                            square.y(square.y() - DELTA_HEIGHT);
                            break;
                        case "B":
                            square.y(square.y() + DELTA_HEIGHT);
                            break;
                        default: break;
                    }
                }

                // var anim = new AnimationKonva(async function (frame) {
                //     if (movePathArray.length > 0) {
                //         switch (movePathArray.shift()) {
                //             case "L":
                //                 square.x(square.x() - DELTA_WIDTH);
                //                 break;
                //             case "R":
                //                 square.x(square.x() + DELTA_WIDTH);
                //                 break;
                //             case "T":
                //                 square.y(square.y() - DELTA_HEIGHT);
                //                 break;
                //             case "B":
                //                 square.y(square.y() + DELTA_HEIGHT);
                //                 break;
                //             default: break;
                //         }
                //     } else { return false; }
                // }, layer);


                // anim.start();


                layer.add(square);




                let data = {
                    stage: stage,
                    layer: layer,
                    // anim:anim,
                    blackLine: blackLine
                }


                resolve(data);

            }; imageObj1.src = mainPageContent?.pointericon;



            //         let resizeObserver = new ResizeObserver(() => {

            //             let locWidth = elm.clientWidth, locHeight = elm.clientHeight;

            //             const scaleX = locWidth / width;
            // const scaleY = locHeight / height;
            // const scale = Math.max(scaleX, scaleY);

            //             stage.width(locWidth)
            //             stage.height(locHeight) 
            //             stage.scaleX(0.6)

            //                 console.log("The element was resized");
            //             });

            //             resizeObserver.observe(elm);

        })
    }


    // funccount.add(drawMaze);
    // console.log(drawCount,"drawCount")

 
    function actionCnt (e){
        e.preventDefault();
        if (e.repeat) { return }
        if (e.keyCode === 37 || e.keyCode === 39 ||e.keyCode === 38 || e.keyCode === 40) setActionCount( obj => obj+1)
      }

    return (
        <div className="w-full h-full flex flex-col" onClick={(e)=>{e.stopPropagation();DisPatch(Actions.setActiveFieldside({filedId:fieldID,index:position,ActiveScreen:"main"}))}} ><div
        // className="flex h-[80%] w-4/5 m-auto " id={"gamePanel" + fieldID} style={{ border: " 1px solid #204056", borderRadius: "5px" }}>

             className="flex w-[95%] h-[90%] m-auto outline-none " id={"gamePanel" + fieldID} onKeyDown={actionCnt} style={{ border: " ", borderRadius: "5px" }}> 
        </div> </div>

    );
};


// export default React.forwardRef(FindPath);