import React from "react"
import PrimaryButton from "../components/Button1/primarybutton"
import { useNavigate } from "react-router-dom"

export const NotFoundPage = () => {

    const Nevigator = useNavigate()

    return(
        <section className="h-screen w-screen flex justify-center" >
            <div className="flex my-auto flex-col justify-center space-y-2">
                <div className="w-4/5 flex mx-auto">
                    <img src="/asset/images/notfound.png" alt="not Found" />
                </div>
                <p className="text-[16px] capitalize font-medium mx-auto flex" >Not Found</p>
                <PrimaryButton text="DashBoard" width="w-fit px-4 mx-auto" OnClick={()=>Nevigator("/dashboard")} />
            </div>
        </section>
    )
}