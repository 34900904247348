export var product_flow=[  
    {
        element: ".step-product-addProductBtn",
        title: "Add Product",
        intro: "Effortlessly enhance your recommendations by adding new products with just a few clicks.",
    },
    {
        element: ".step-product-editProductBasic",
        title: "Product Details",
        intro: "Provide product details by entering a name, description, uploading an image (under 3MB) and add tags.",
    },
    {
        element: ".step-product-editProductSKU",
        title: "Specifying SKU",
        intro: "Here you can specify the Stock Keeping Unit (SKU).",
    },
    {
        element: ".step-product-editProductBtn",
        title: "Button",
        intro: "Defining button text with its corresponding link.",
    }

]