import React from 'react'
import { BillingLayout } from './Billing/BillingLayout'
import { Main } from './Billing/Main'
import { AppsumoBillingFeature } from './Billing/Appsumobillinfo'
import { BundleBillingFeature } from './Billing/Bundlebillinfo'

export const BundleBilling = () => {
  return (
    <div className='flex flex-col w-full h-full  bg-white py-6 px-8 rounded-xl' id='billing' >
      <>
        <div className='flex space-x-4 w-full px-2  rounded-xl'>
          <span>
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" class="w-5 h-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M527.9 32H48.1C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48.1 48h479.8c26.6 0 48.1-21.5 48.1-48V80c0-26.5-21.5-48-48.1-48zM54.1 80h467.8c3.3 0 6 2.7 6 6v42H48.1V86c0-3.3 2.7-6 6-6zm467.8 352H54.1c-3.3 0-6-2.7-6-6V256h479.8v170c0 3.3-2.7 6-6 6zM192 332v40c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v40c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12z"></path></svg>

          </span>
          <h3 className='text-lg font-semibold flex justify-center items-center '>Billing & Plan Info</h3>
        </div>
        <hr />
      </>
      <BundleBillingFeature />
    </div>
  )
}
