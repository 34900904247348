import React from "react";
import GzLogo from "../logo/GzLogo";
import PrimaryButton from "../Button1/primarybutton";
import { useNavigate } from "react-router-dom";


const LogHeader = ({text,width})=>{

    const navigate = useNavigate()

    return(
        <>
            <section className="w-full h-[77px] bg-white border-b-3">
                <div className="flex justify-between h-full mx-10">
                    <GzLogo classNamecontainer="w-52 my-auto" classNameimg="" />
                    <PrimaryButton text={text}  margin={"my-auto "} width={width} OnClick={()=>navigate('/login')} />
                </div>
            </section>

        </>
    )
}

export default LogHeader;