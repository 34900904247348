import React from "react";

const HeroImage = () => {
    return(
        <>
        <section className="w-1/2 flex">
            <div className="w-4/5 m-auto">
                
                <img src ="/asset/images/log/hero.png" alt="GoZenEngage" title="GoZenEngage" className="mx-auto my-4" />
                
                <div className="w-3/5 text-center mx-auto my-10">
                        <h2 className="text-primary-bold text-18px font-medium mb-12px">Generate leads with AI-powered interactive gamification.</h2>
                        <p className="font-normal text-14px text-primary-gray">Engage your website traffic, generate leads, and increase sales using Interactive content like quizzes, games, surveys, and assessments inside your website.</p>
                </div>

            </div>
        </section>
        </>
    )
}

export default HeroImage;