import React, { useEffect, useState } from "react";
import PrimaryButton from "../../Button1/primarybutton";
import { isEmpty, isNotEmpty } from "../../../utils/helpers";
import { CalculaterResult } from "../../../utils/Calculater";
import toast from 'react-hot-toast'
import { CalCulatorQueRender } from "../calculator/calculatorQUe";

export const CalculatorResultBlock = ({ setting = {}, pageContent = {}, Type = "calculater", val = [], Formula = ["sum(q1)"], Question }) => {

    const [Message, setMessage] = useState("")

    const [SumVAlue, setsumValue] = useState([0])

    const [Isvaild, setIsvaild] = useState(0);

    const [CalValue, setCalValue] = useState(val);

    let temp = {}

    const Calculation = () => {
        try {

            if (Type === "calculater") {

                let temp = [];

                Formula.map(formula => {

                    const CalculaterData = new CalculaterResult({ question: Question });

                    const IsValidINput = CalculaterData.userInputFormula(String(formula).toLowerCase(), CalValue, true);

                    if (!IsValidINput.status) toast.error(IsValidINput.message);

                    temp.push(IsValidINput.status ? eval(IsValidINput.sample) : 0)

                })


                setsumValue(temp);

            }

        } catch (err) {

            console.log({ err })

            toast.error("Invalid Input!")

            setIsvaild(false)

            setMessage("Invalid Input!")

            setsumValue(0)
        }

    }

    const ChnageVal = (value, ind) => {

        let temp = [...CalValue];

        temp[ind] = value;

        setCalValue(temp)

    }

    useEffect(() => {
        // console.log(Formula)
        Calculation()

    }, [CalValue])

    return (

        <div className="w-full flex flex-col space-y-10">

            <div className="w-full justify-center flex space-x-2 flex-wrap " >

                {
                    pageContent?.Calresults?.map((CalDAta, index) => {


                        // const finalVal = Calculation(Formula[index])


                        let FinalResult = (isEmpty(SumVAlue[index]) || isNaN(SumVAlue[index])) ? 0 : SumVAlue[index];

                        let FinalResultStr = ``;


                        if (CalDAta?.finalResult) {


                            const SplitData = CalDAta?.finalResult?.split(`{Final_Calculation}`);

                            if (SplitData?.length == 0) FinalResultStr += FinalResult;

                            else {

                                SplitData?.map((listobj, ind) => {

                                    if (((ind + 1) % 2) == 0) {

                                        let temp = FinalResult + `${listobj}`;

                                        FinalResultStr += temp;
                                    }

                                    else FinalResultStr = `${listobj}`;

                                })
                            }


                        }

                        else FinalResultStr += FinalResult?.toFixed(3);

                        return (

                            <div className="w-[30%]  min-w-[180px]  space-y-3 rounded-xl p-4 bg-white  mt-2">

                                <div className="h-48 flex rounded-xl border-1 max-h-48">

                                    <div className="flex m-auto max-h-full">

                                        <img src={CalDAta?.imagesrc !== "" ? CalDAta?.imagesrc : "/asset/images/emptyimage.png"} alt="productimage" className="flex m-auto max-h-48" />

                                    </div>

                                    {/* <img src={list?.productImageSrc} alt="" /> */}

                                </div>

                                <p className="text-black text-base font-medium">{CalDAta?.title !== "" ? CalDAta?.title : "Title"}</p>

                                <p className="text-black text-sm">{CalDAta?.description !== "" ? CalDAta?.description : "Description"}</p>

                                {/* <p className="text-black text-lg font-bold overflow-hidden truncate">{CalDAta?.finalResult}{(isEmpty(SumVAlue) || isNaN(SumVAlue)) ? 0 : SumVAlue}</p> */}

                                <p className="text-black text-lg font-bold overflow-hidden truncate">{FinalResultStr}</p>

                                <PrimaryButton text={CalDAta?.button !== "" ? CalDAta?.button : "vist"} OnClick={() => { if (CalDAta?.buttonlink !== "") Navigator(CalDAta?.buttonLink) }} />

                            </div>
                        )
                    })
                }
            </div>
            <div className="w-full flex flex-col space-y-6 p-6 border-2 rounded-xl py-8 bg-[#ffffff32] mb-6" >

                {
                    Question.map((list, ind) => {

                        temp[list?.question] = CalValue[ind]

                        return (
                            <CalCulatorQueRender Settings={setting} questionObj={list} UserAns={temp} UserAction={(value, field) => ChnageVal(value, ind)} />

                        )
                    })
                }


            </div>
        </div>
    )

}