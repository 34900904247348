import React from 'react'
import PropTypes from "prop-types";
import { Label } from '../Texts';

const DeFaultCheckbox = function ({ id, ClassName='',isRequired = false, disabled = false, requiredText = "", attributes = {}, containerwidth = "", name, Label: label, labelComponent = null, isChecked, error, margin = "", className, textColor = "text-gray-800", fontSize = "text-13px", onChange = null }) {

    return (
        <div className={`flex flex-row justify-start ${containerwidth} items-center ${margin}`}>
            <input
                type="checkbox"
                disabled={disabled}
                required={isRequired}
                className={`form-checkbox form-tick appearance-none h-4 w-4 border border-gray-dim rounded-sm checked:border-transparent focus:outline-none ${ClassName}`}
                checked={isChecked}
                onInvalid={(e) => {
                    e.target.setCustomValidity(requiredText);
                }}
                onChange={(e) => {
                    if (disabled) return;
                    if (!isRequired) return onChange && onChange(!isChecked)
                    const th = e.target;
                    th.setCustomValidity(" ")
                    setTimeout(() => th.setCustomValidity(""), 200)
                    onChange && onChange(!isChecked)
                }}
            />

            {
                label &&
                <Label onClick={_ => {
                     if (disabled) return;
                    if (!isRequired) return onChange && onChange(!isChecked)
                    onChange && onChange(!isChecked)
                }} text={label} fontSize={fontSize} textColor={textColor} padding="capitalize" htmlFor={id} margin={`ml-sm ${!disabled && "cursor-pointer"}`} />
            }
            {
                labelComponent && labelComponent
            }
        </div>
    )
}


export default DeFaultCheckbox ;
