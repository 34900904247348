import React from 'react'
import { DefaultDateTime, LastModified, setDateTime } from '../../../../utils/dateconvetor'


export const PaymentHistory = ({ invoices }) => {
    return (
        <div className='w-full flex flex-col space-y-4 '>
            <span>Payment history</span>
            <table className={`w-full bg-gray-100 rounded-lg `}>

                <thead>
                    <tr className="flex flex-row items-start bg-gray-200 rounded-t-lg text-center">

                        {/* <th className="w-1/3 px-5 py-2 font-normal rounded-l-lg">
       Order Id
   </th> */}

                        <th className="w-1/3 px-5 py-2 font-normal">
                            Date
                        </th>

                        <th className="w-1/3 px-5 py-2 font-normal rounded-r-lg">
                            Invoice
                        </th>

                    </tr>
                </thead>
                <tbody className=''>
                    {
                        (invoices?.length > 0) && invoices?.map((invoice, k) => <tr key={k} className='flex gap-y-4 py-2'>
                            {/* <p className="w-1/3 px-5  text-font_primary font-normal text-sm">
                                    { invoice.id }
                                </p> */}

                            <p className="w-1/3 px-5 text-font_primary font-normal text-sm">
                                {LastModified(invoice.created)}

                            </p>

                            <a
                                className="w-[30%] px-5 text-primary font-normal text-sm flex items-center space-x-2 group cursor-pointer"
                                href={invoice.url}
                                target="blank"
                            >

                                <span>View and download</span>

                                {/* <LinkIcon className="w-5 h-4" /> */}

                            </a>
                        </tr>)
                    }
                </tbody>
            </table>

        </div>
    )
}
