import React from 'react'
import PropTypes from "prop-types";

const Label = function ({ text, fontSize = "text-15px", lineHeight = "leading-normal", textColor = "text-primary-gray", margin = "", fontWeight = "font-normal", padding = "", onClick = null, ...props }) {
    return (
        <span className={`${textColor} ${fontSize} ${lineHeight} ${fontWeight} ${padding} ${margin}`} onClick={onClick}>{text || props.children}</span>
    )
}



Label.prototype = {
    text: PropTypes.string.isRequired,
    textColor: PropTypes.string,
    fontWeight: PropTypes.string,
    padding: PropTypes.string,
    fontSize: PropTypes.string
}

export { Label }