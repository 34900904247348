import react from 'react'

export const TemplateCategoryBlock = ({src,title,index,active,onclick=null}) => {

    if(title==="Calculater") title="Calculator"

let stepsClass={
    "games":"step-icons-games",
    "quizzes":"step-icons-quizzes",
    "spin wheel":"step-icons-spinwheel",
    "ecommerce":"step-icons-ecommerce",
    "poll":"step-icons-poll",
    "calculator":"step-icons-calculator",
    "surveys":"step-icons-survays"
}

let finalStepClass=stepsClass[title?.toLowerCase()]


    return (
        <>
            <div className={`${finalStepClass} flex relative transition-all delay-150 duration-700 flex-col w-32 h-32 mx-auto cursor-pointer justify-conter hover:shadow-bgshadow hover:bg-white hover:-top-1 rounded-xl ${(active === index) ? "border-2 border-primary bg-white -top-1" : "border-2 border-transparent top-0"}`} onClick={onclick}>
                <div className="mx-auto mt-[30px] my-7">
                    <div className="mb-4">
                        <img src={src} className="mx-auto " alt="" />
                    </div>
                    <p className="mx-auto font-medium text-sm capitalize text-center">{title}</p>
                </div>
            </div>
        </>
    )
}