import React, { useState } from "react";
import InputWithLabel from "./input/inputWithLabel";
import { Imageinput } from "./input/imageinput";
import InputRadiocheck from "./input/radiocheck";
import PrimaryButton from "./Button1/primarybutton";
import Input from "./input/input";
import { useDispatch } from "react-redux";
import { Actions } from "../store";
import DropMenu from "./input/dropmenu";
import DefaultDropMenu from "./input/droplist";
import { MediaPopup } from "./popup/Mediapopup";
import { ConnectionImage } from "./connectionimage";
import { listOfIcon } from "../content/iconList";

export const FindOddOneContainer = ({
    Index,
    keyVal=0,
    fieldId = "", 
    frameObj = [], 
    frameKey="Frame1"
}) => {

    const DisPatch = useDispatch();

    const deleteIcon = listOfIcon('delete',18,18,'#586474');

    
    const toolTip = [ {
            type: "delete",
            icon: deleteIcon,
        },
    ]


 
    const DirectContentChange = (value, type, optionIndex = 0, add = false) => {
        const setContent = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_mainpage_json',
            child: "gameFrames",
            edge: "imageUrl",
            QueIndex: frameKey,
            optionIndex: optionIndex,
            innerINdex: RowInner,
            value: value,
            add: add

        }

        DisPatch(Actions.setConnetionCC(setContent))

        if (ShowMedia) setShowMidia(false)

    }
 
    

    const deleteFrame = (type) => {
        const setContent = {
            key: fieldId,
            node: 'game_mainpage_json',             
            child: 'gameFrames',
            edge: frameKey, 
            type:type
        }
        // console.log(setContent,"frameKey")
                DisPatch(Actions.setOddOneFrameAdd(setContent))
    }



    const [ShowMedia, setShowMidia] = useState(false);
    const [ShowInner, setShowInner] = useState(0);
    const [RowInner, setRowInner] = useState(0);

    const OnClickCI = (index,Rindex) => {
        setShowInner(index)
        setRowInner(Rindex)
        setShowMidia(true)
    }


    return (
        <>
            {ShowMedia && <MediaPopup cancel={setShowMidia} innerArr={true} ineerIndex={ShowInner} fieldType={'imageUrl'} OnSelect={DirectContentChange} />}

            <div key={Index+"-"+keyVal} className={`w-full relative flex  flex-col space-y-2 border-2  h-fit delay-400 duration-700 top-0 p-4 rounded-xl hover:shadow-md hover:-top-0 hover:border-primary`}  >

                <div className="flex justify-between">
                    <p className="text-base font-medium" >Frame - {Index + 1}</p>
                    <div className="flex space-x-2">
                        {
                            toolTip.map((list, index) => {
                                return (
                                    <p className="p-2 rounded-md border-1 hover:bg-gray-200 cursor-pointer" onClick={() => deleteFrame(list.type)} >{list.icon}</p>
                                )
                            })
                        }
                    </div>
                </div>


                {/* Question Editor */}
                <div className="flex flex-col space-y-3"> 

                    {
                        frameObj?.map((listing, index) => {
                            return (
                               <div key={index}>
                                    <label className="capitalize " >Row - {index + 1}</label><br/>
                                    {
                                        listing?.map((listObj,ObjIndex) => {
                                            if (listObj?.type === "oddElem") return (
                                                <>
                                                    <label className="capitalize text-sm" >Odd image</label>

                                                    <ConnectionImage showDel={false} imagSrc={listObj?.imageUrl} onClickEVent={() => OnClickCI(index,ObjIndex)} />
                                                </>
                                            )
                                            else return (
                                                <>
                                                    <label className="capitalize text-sm" >Even image</label>

                                                    <ConnectionImage showDel={false}  imagSrc={listObj?.imageUrl} onClickEVent={() => OnClickCI(index,ObjIndex)} />
                                                </>
                                            )
                                        })}
                            </div>
                            )
                        })
                    }


                </div>

           
            </div>
          
        </>
    )
}