import React, { useState, useImperativeHandle, useEffect, useRef } from "react";
import Konva from 'konva';
import { mixArray, element, sleep, isEqual, isEmpty, get2DnullMatrix, fadeIn, isNotEmpty } from "../../../utils/helpers";
import { getSquareXY, getOffset } from "../../../utils/canvas";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../store";


export const CharDragAndDrop = ({ obj = {}, fieldID = '', StartTime = false, prview = false, setActionCount = null, GameFinsh = null,setScore=null, position=0 , ActionCount=0, setLoadContent=null}) => {

    const { Fields, device } = useSelector((state) => ({ Fields: state.fields, device: state.device }));

    const templateData = Fields[fieldID];
    const DIVID="_"+fieldID
    const Settings = templateData?.filedContent
    const mainPageContent = Settings.game_mainpage_json;
    const [trigger, settrigger] = useState(false)
    
    var BOX = 0, wordlist = mainPageContent?.wordlist
    wordlist = wordlist?.map(f => { if(f.length>BOX)  BOX=f.length; return  f.toUpperCase(); });
    const  rows= parseInt(wordlist?.length); 
    let tempCols=(mainPageContent?.layoutrange?.toLowerCase())?.split("x")
   var cols = parseInt(BOX);  //  MAX LEN OF WORD
    var tempCol = parseInt(tempCols[0]);
if(tempCol>cols) cols=tempCol

    const LeftRef = useRef(null)
    const [boxSize, setBoxSize] = useState([])
    const [dragObj, setDragObj] = useState([])
    const [LeftBase64, setLeftBase64] = useState(new Array(rows*cols).fill(null))
    const [RightBase64, setRightBase64] = useState(new Array(rows*cols).fill(null))

    const DisPatch = useDispatch()
  
     const [iconSize, setIconSize] = useState([100,100])
     
    useEffect(()=>{  gameSetUp() },[]) 
    useEffect(() => {
      if (StartTime) {
          settrigger(!trigger)
          gameSetUp();
      }
  }, [StartTime])
    
    useEffect(()=>{  gameSetUp() },[device, mainPageContent, trigger]) 

    useEffect(()=>{ 
   let  newScore=0;
   
   LeftBase64.map((obj,i)=>{ 
    if(isNotEmpty(obj)){
       let userWord=obj?.join("")
       if(isEqual(userWord.length,cols)){ 
           wordlist?.map((jval,j)=>{  if(isEqual(userWord,jval) )  newScore+=1;   })
       } 
      }
     }) 

   setScore(newScore)

   
   if(newScore===wordlist?.length ) {
  //  console.log(LeftBase64)
   
   GameFinsh(true); } 
  
  return () =>{
     if(newScore===wordlist?.length ) {
     setScore(0)
     setLeftBase64(get2DnullMatrix(rows,cols,null))
     setRightBase64(get2DnullMatrix(rows,cols,null))
     }
  }
  
  
   },[ActionCount,LeftBase64]) 
   
   
   function clearStyle(icon){
     if(isEmpty(icon)) return
     icon.remove()
     setDragObj([])
     }
   
   const gameSetUp = () => {
    setLoadContent(true);
   setScore(0)
   setLeftBase64(get2DnullMatrix(rows,cols,null))
   setRightBase64(get2DnullMatrix(rows,cols,null))

   element("gamePanel"+DIVID).style.opacity=0.02
   setBoxSize(["80vh", "80vh"]);

     
     setTimeout(() => {
      let tempWidth = LeftRef.current.clientWidth,
       tempHeight = LeftRef.current.clientHeight,
       tempSize = tempHeight;
     if (tempHeight > tempWidth) tempSize = tempWidth;
     setBoxSize([tempSize+"px", tempSize+"px"]);
      //  element("gamePanel" + DIVID).style.width = "fit-content";
    
   element("gamePanel" + DIVID).style.height = "fit-content";

element("gamePanel"+DIVID).style.opacity=1
   
loadBase64(tempSize);
     }, 500);
   };
    
   
   async function loadBase64(localSize) {
   
     let DELTA_WIDTH = localSize / cols,
       DELTA_HEIGHT = localSize / rows;
     setIconSize([DELTA_WIDTH, DELTA_HEIGHT]);
   
   
   
     let newArr = get2DnullMatrix(rows,cols,null)
    
for(let i=0;i<rows;i++){
  let Word=mixArray(wordlist[i]?.split(""))
for(let j=0;j<cols;j++){
  newArr[i][j]=Word[j]
}
}
    //  [Array(cols)].map((obj, inx) => {  newArr.push(mixArray(wordlist[inx]?.split(""))); });
   
     setRightBase64(mixArray(newArr))
     setLeftBase64(get2DnullMatrix(rows,cols,null))
   
   
     setLoadContent(false);

   }
    
   
   const onDown = function(event, inx,key,side) {
   
   var icon=element(key+"x"+inx+"_dragImg_"+side+DIVID)
   if(isEmpty(icon)) return
   
   let singleObj=(side==="Right")? RightBase64[key][inx] : LeftBase64[key][inx]
   setDragObj([ singleObj, key+"x"+inx+"_dragImg_"+side+DIVID])
   
     let shiftX = event.clientX - icon?.getBoundingClientRect().left;
     let shiftY = event.clientY - icon?.getBoundingClientRect().top;
   
     icon.style.position = 'absolute';
     icon.style.zIndex = 10;
      document.body.append(icon);
   
     moveAt( event.pageX, event.pageY);
   
   
     function moveAt(pageX, pageY) {
       icon.style.left = pageX - shiftX + 'px';
       icon.style.top = pageY - shiftY + 'px';
   
     }
   
    
     function onMouseMove(movEvent) {
       moveAt(movEvent.pageX, movEvent.pageY);
     }
     function onTouchMove(movEvent) {
       moveAt(movEvent.changedTouches[0].pageX, movEvent.changedTouches[0].pageY);
     }
     
   
     document.addEventListener('mousemove', onMouseMove);
     document.addEventListener('touchmove', onTouchMove);
   
   
     icon.onmouseup = function(movEvent) {
       document.removeEventListener('mousemove', onMouseMove);
       clearStyle(icon)
       icon.hidden = true;
       let elemBelow = document.elementFromPoint(movEvent.clientX, movEvent.clientY);
       icon.hidden = false;
       dropCard(elemBelow)
     };
   
     icon.ontouchend = function(movEvent) {
       document.removeEventListener('touchmove', onTouchMove);
       clearStyle(icon)
       icon.hidden = true;
       let elemBelow = document.elementFromPoint( movEvent.changedTouches[0].clientX, movEvent.changedTouches[0].clientY );
       icon.hidden = false;
       dropCard(elemBelow)
     };
   
   
     function dropCard(elemBelow){
   
       if ((!elemBelow)|| isEmpty(elemBelow?.id)) return;
       
       let targetElm=(elemBelow?.id).split("_")
       if(targetElm?.length===1) return
       let NewSide=targetElm[2], tempPos=targetElm[0].split("x"), NewElmDIVID=targetElm[3], NewInx=parseInt(tempPos[1]), NewKey=parseInt(tempPos[0])
       if( isEmpty(NewSide) || isEmpty(NewInx) || isEmpty(NewElmDIVID) || isEmpty(NewKey) || ("_"+NewElmDIVID)!==DIVID    ) return
    
     
    
   
       let localLeft=LeftBase64, localRight=RightBase64
    
       if(NewSide===side){
   if(NewSide==="Left"){
     if(isNotEmpty(localLeft[NewKey][NewInx])) return
     setActionCount(o => o+1)
     localLeft[NewKey][NewInx]=localLeft[key][inx]
     localLeft[key][inx]=null
   }
   if(NewSide==="Right"){
     if(isNotEmpty(localRight[NewKey][NewInx])) return
     setActionCount(o => o+1)
     localRight[NewKey][NewInx]=localRight[key][inx]
     localRight[key][inx]=null
   }
       }
       else{
   
       if(NewSide==="Left") {
       if(isNotEmpty(localLeft[NewKey][NewInx])) return
       setActionCount(o => o+1)
        localLeft[NewKey][NewInx]=localRight[key][inx]
        localRight[key][inx]=null
       }
   
       if(NewSide==="Right") {
         if(isNotEmpty(localRight[NewKey][NewInx])) return
         setActionCount(o => o+1)
        localRight[NewKey][NewInx]=localLeft[key][inx]
        localLeft[key][inx]=null
       }}
       
       setLeftBase64(localLeft)
       setRightBase64(localRight)
       
     }
     
   
   
   };
   
   const ondragstart = function() {
     return false;
   };
    
return (
  <div   className="flex w-full flex-col justify-center">

<center><div className={` puzzleTips flex flex-row text-[#0A2551] break-all font-bold ${device==="mobile" ? "text-lg" : "text-2xl" }     ${device==="mobile" ? "mt-[1rem]" : "mt-3" }   justify-center`}>
        {mainPageContent?.puzzleHint}</div></center>
<div draggable={false} className={`flex  ${device==="mobile" ? "flex-col" : "flex-row" }    h-[75vh] items-center relative w-full m-auto noselect`}   id={"gamePanel"+DIVID}  style={{touchAction: "none"}} 
 >

<div draggable={false}  className={` ${device==="mobile" ? "w-[100%]" : "w-[50%]" }  ${device==="mobile" ? "p-2" : "p-3" }  ${device==="mobile" ? "justify-center" : "justify-end" }   h-full flex  items-center max-w-[100%] overflow-hidden noselect`}> 
  <div  draggable={false} className={`w-full h-full bg-white rounded-md  ${device==="mobile" ? "p-1" : "p-2" }   noselect`} style={{width:boxSize[0], height:boxSize[1]}} ref={LeftRef}>
  <div draggable={false} id={"DNDLeft"+DIVID} className="grid   h-full noselect"   style={{     gridTemplateRows: `repeat(` + rows + `,minmax(0,1fr)`,  }}  >
  {
  LeftBase64?.map((word,key)=>{
 return <div key={key} className="grid   h-full noselect" style={{ gridTemplateColumns: `repeat(` + cols + `,minmax(0,1fr)`}}>
  {
    word?.map((char,inx)=>{
      let id=key+"x"+inx+"_dragImg_Left"+DIVID, par_id=key+"x"+inx+"_dragPar_Left"+DIVID

    return <div  key={inx+(dragObj[1] || 0)} id={par_id}   className={` flex justify-center items-center border-1 rounded-sm shadow-sm border-gray-200 noselect h-full w-full `}
   >
    {
     (isNotEmpty(char))    &&   (dragObj?.length>0 ? dragObj[1]!==id : true) &&
      <div onDragStart={ondragstart} onPointerDown ={(e)=>onDown(e,inx,key,"Left")}  id={id}  alt="Puzzle"  className={`  w-full h-full shadow-md rounded-md cursor-pointer noselect p-[0.5rem] flex justify-center items-center   ${device==="mobile" ? "text-lg" : "text-xl" }  font-bold`} style={{color:(mainPageContent?.textColor || "white"), backgroundColor:(mainPageContent?.cardbColor), maxWidth:iconSize[0]+"px", maxHeight:iconSize[1]+"px"}} draggable={false} 
     >{char}</div>
    }
    </div>
    })
  }
  </div>
  })
}

</div>
</div>
</div>
<div  draggable={false} className={`  ${device==="mobile" ? "justify-center" : "justify-start" }  ${device==="mobile" ? "p-2" : "p-3" }  ${device==="mobile" ? "w-[100%]" : "w-[50%]" }     h-full flex   items-center noselect`}> 
  <div draggable={false} className={`w-full h-full  ${device==="mobile" ? "p-1" : "p-2" } bg-white rounded-md  noselect`} style={{width:boxSize[0], height:boxSize[1]}}>
  <div  draggable={false} id={"DNDRight"+DIVID}  className="grid   h-full noselect"     style={{      gridTemplateRows: `repeat(` + rows + `,minmax(0,1fr)`,  }} >
    
  {
  
  RightBase64?.map((word,key)=>{ 
    return <div key={key} className="grid   h-full noselect" style={{ gridTemplateColumns: `repeat(` + cols + `,minmax(0,1fr)`}}>
    {
      word?.map((char,inx)=>{
let id=key+"x"+inx+"_dragImg_Right"+DIVID, par_id=key+"x"+inx+"_dragPar_Right"+DIVID

      return <div key={inx+(dragObj[1] || 0)} id={par_id} className=" flex justify-center items-center noselect border-[2px] border-gray-200  h-full w-full "   draggable={false} 
      >
      {
        (isNotEmpty(char))  &&  (dragObj?.length>0 ? dragObj[1]!==id : true) &&
        <div onDragStart={ondragstart} onPointerDown ={(e)=>onDown(e,inx,key,"Right")}   id={id}  className={`  w-full h-full shadow-md rounded-md cursor-pointer noselect p-[0.5rem] flex justify-center items-center   ${device==="mobile" ? "text-lg" : "text-xl" } font-bold`} style={{color:(mainPageContent?.textColor || "white"), backgroundColor:(mainPageContent?.cardbColor), maxWidth:iconSize[0]+"px", maxHeight:iconSize[1]+"px"}} draggable={false} 
        >{char}</div>
        
    }</div>
    })
    }
    </div>
  
  })
}
  </div>
  </div>
</div>
</div>
</div>
);
};

