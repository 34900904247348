import React, { useEffect, useState } from "react";
import PrimaryButton from "../../../Button1/primarybutton";
import { CreateCustomDomain, DeleteCustomDomain, VerifyCustomDomain, getCustomDomain } from "../../../../actions/site";
import { PopupCreate } from "../../../popup/popupcreate";
import toast from 'react-hot-toast';
import { LastModified } from "../../../../utils/dateconvetor";
import { CustomDomainRecord } from "../../../popup/customDominaRecord";
import { LoadingSpinner } from "../../../Loading";
import { CgSpinner } from "react-icons/cg";


export const CustomDomain = () => {

    useEffect(() => { FetchCustomDomain() }, [])

    const [loading, setLoading] = useState(false);
    const [refreshloading, setRefreshLoading] = useState(false);
    const [poploading, setPopLoading] = useState(false);
    const [deleteloading, setdeleteLoading] = useState(null);
    const [showRecord, setshowRecord] = useState(false);
    const [ListDomain, setListDoamin] = useState([]);
    const [ShowPopup, setPopup] = useState(false);
    const [customDomain, SetCustomDomain] = useState('')
    const [setcustomDomain, SetsetCustomDomain] = useState('')


    const CreateCD = async () => {

        try {

            setPopLoading(true)

            if (customDomain?.length < 5) return toast.error('invalid Domain');

            const payload = {
                "Domain": customDomain,
                "type": "custom"
            }

            const resp = await CreateCustomDomain(payload);

            if (resp.status) toast.success('Sub Created');

            setPopLoading(false);
            setPopup(false)

            SetsetCustomDomain(customDomain);
            setshowRecord(true);
            FetchCustomDomain() 



        } catch (Err) {
            setPopLoading(false)
        }
    };

    const refreshTrigger = async (obj) => {

        try {

            setRefreshLoading(true)

            const { CD_id, Domain, type } = obj;

            const Resp = await VerifyCustomDomain( { Domain, type } , CD_id );
            if(Resp.status){
                if(Resp.data.verifystatus) return FetchCustomDomain() ;
                return toast.error("Not Verified!.")
            }
            setRefreshLoading(false)
        }

        catch(Err){
            toast.error('E');
            setRefreshLoading(false)
        }


    }

    const DeleteCD = async ( obj, inx ) => {

        setdeleteLoading(inx);

        const { CD_id, Domain, type } = obj;

        const Resp = await DeleteCustomDomain( { Domain, type } , CD_id );

        if(Resp.data.status) toast.success("Custom Deleted.")

        setdeleteLoading(null);

        FetchCustomDomain()




    }



    const FetchCustomDomain = async () => {
        try {
            setLoading(true)
            const SiteResp = await getCustomDomain();
            setListDoamin(SiteResp.data);
            setLoading(false);

        } catch (err) {
            setLoading(false)
        }
    }

    const notVerifiedIcon = () => {
        return(<svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#DC2626"/>
        <path d="M13.0601 12L15.3601 9.69998C15.6501 9.40998 15.6501 8.92999 15.3601 8.63999C15.0701 8.34999 14.5901 8.34999 14.3001 8.63999L12.0001 10.94L9.70011 8.63999C9.41011 8.34999 8.93011 8.34999 8.64011 8.63999C8.35011 8.92999 8.35011 9.40998 8.64011 9.69998L10.9401 12L8.64011 14.3C8.35011 14.59 8.35011 15.07 8.64011 15.36C8.79011 15.51 8.98011 15.58 9.17011 15.58C9.36011 15.58 9.55011 15.51 9.70011 15.36L12.0001 13.06L14.3001 15.36C14.4501 15.51 14.6401 15.58 14.8301 15.58C15.0201 15.58 15.2101 15.51 15.3601 15.36C15.6501 15.07 15.6501 14.59 15.3601 14.3L13.0601 12Z" fill="white"/>
        </svg>
        )
    }

    const verifiedIcon = () => {
        return(<svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z" fill="#2563EB"/>
        </svg>
        
        )
    }

    return (
        <>
            
            { showRecord && <CustomDomainRecord backwardAction={setshowRecord} Domain={setcustomDomain}  /> }
            { ShowPopup && <PopupCreate position="fixed" title="Create Sub Domain" loadingLive={poploading} Placeholder="user" backwardAction={setPopup} forwardAction={CreateCD} changeDefaultVaule={(e, type) => SetCustomDomain(e.target.value)} />}
            { loading ? <LoadingSpinner text={'loading...'} /> :
            <div className="flex flex-col space-y-2" >

                <div className="flex space-x-2 justify-end" >
                    <PrimaryButton
                        icon={<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="mr-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path></svg>}
                        text="Add Custom Domain"
                        OnClick={() => setPopup(true)}

                    />
                </div>

                <div className='w-full  h-full space-y-3 overflow-y-auto'>
                    {
                        ListDomain.length > 0 && ListDomain.map((list,ind) => {

                            if( list?.type == 'sub' ) return null;

                            return (
                                <div className='flex transition-all duration-500 justify-between py-3 px-5 border-1 bg-white hover:shadow-md rounded-xl'>
                                    <div className='flex space-x-4' >
                                        <span className='flex my-auto' >
                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" class="w-5 h-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"></path></svg>
                                        </span>
                                        <div>
                                            <p className='my-auto font-medium text-primary' >{list.Domain}</p>
                                            <p className='text-sm text-black' >{LastModified(list.created_at)}</p>
                                        </div>
                                    </div>

                                    <div className="flex space-x-2" >

                                        <p className={` capitalize flex ${list?.verify_status !=='true'? "text-red-400" : "" } my-auto h-fit font-medium space-x-1`} >
                                            <span className="flex my-auto" >{ list?.verify_status == 'true' ? verifiedIcon() : notVerifiedIcon() }</span>
                                            <span className="flex my-auto" >{ list?.verify_status == 'true' ? 'Verified' : "not Verified" }</span>
                                        </p>

                                        { (list?.verify_status !== 'true') && 
                                        <>

                                        <PrimaryButton text='Refresh' icon={(!refreshloading ?
                                            <svg width="14" height="14" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11 1.75C5.85617 1.75 1.75 5.89226 1.75 11C1.75 11.4142 1.41421 11.75 1 11.75C0.585786 11.75 0.25 11.4142 0.25 11C0.25 5.06774 5.02383 0.25 11 0.25C14.5577 0.25 17.2218 1.736 18.9817 3.20408C19.4731 3.61401 19.8957 4.02385 20.25 4.40201V1.56C20.25 1.14579 20.5858 0.81 21 0.81C21.4142 0.81 21.75 1.14579 21.75 1.56V6.56C21.75 6.97421 21.4142 7.31 21 7.31H16.56C16.1458 7.31 15.81 6.97421 15.81 6.56C15.81 6.14579 16.1458 5.81 16.56 5.81H19.4993C19.126 5.37739 18.6329 4.86656 18.0208 4.35592C16.4482 3.044 14.1123 1.75 11 1.75ZM21 10.25C21.4142 10.25 21.75 10.5858 21.75 11C21.75 16.9342 16.9342 21.75 11 21.75C7.97954 21.75 5.58449 20.2298 3.98113 18.7755C3.55554 18.3895 3.18095 18.004 2.86 17.6455V20.44C2.86 20.8542 2.52421 21.19 2.11 21.19C1.69579 21.19 1.36 20.8542 1.36 20.44V15.44C1.36 15.0258 1.69579 14.69 2.11 14.69H6.63C7.04421 14.69 7.38 15.0258 7.38 15.44C7.38 15.8542 7.04421 16.19 6.63 16.19H3.5871C3.94789 16.6286 4.41842 17.1471 4.98887 17.6645C6.45051 18.9902 8.50046 20.25 11 20.25C16.1058 20.25 20.25 16.1058 20.25 11C20.25 10.5858 20.5858 10.25 21 10.25Z" fill="#000000"/>
                                            </svg> :
                                             <CgSpinner className="w-4 h-4 animate-spin text-primary" />   

                                            
                                        )} backgroundColor="white" textcolor="text-black" OnClick={()=>refreshTrigger(list)} /> 

                                        <PrimaryButton text='get Record' icon={(
                                            <svg width="14" height="14" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.15533 3.15533C2.2398 4.07086 1.75 5.57397 1.75 8V14C1.75 16.426 2.2398 17.9291 3.15533 18.8447C4.07086 19.7602 5.57397 20.25 8 20.25H14C16.426 20.25 17.9291 19.7602 18.8447 18.8447C19.7602 17.9291 20.25 16.426 20.25 14V9C20.25 8.58579 20.5858 8.25 21 8.25C21.4142 8.25 21.75 8.58579 21.75 9V14C21.75 16.574 21.2398 18.5709 19.9053 19.9053C18.5709 21.2398 16.574 21.75 14 21.75H8C5.42603 21.75 3.42914 21.2398 2.09467 19.9053C0.760201 18.5709 0.25 16.574 0.25 14V8C0.25 5.42603 0.760201 3.42914 2.09467 2.09467C3.42914 0.760201 5.42603 0.25 8 0.25H13C13.4142 0.25 13.75 0.585786 13.75 1C13.75 1.41421 13.4142 1.75 13 1.75H8C5.57397 1.75 4.07086 2.2398 3.15533 3.15533Z" fill="#2563EB"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.713 0.307112C12.9932 0.191027 13.3158 0.255193 13.5303 0.469692L21.5303 8.46969C21.7448 8.68419 21.809 9.00678 21.6929 9.28703C21.5768 9.56729 21.3033 9.75002 21 9.75002H17C15.4585 9.75002 14.1921 9.50277 13.3447 8.65535C12.4972 7.80793 12.25 6.54157 12.25 5.00002V1.00002C12.25 0.696675 12.4327 0.423198 12.713 0.307112ZM13.75 2.81068V5.00002C13.75 6.45847 14.0028 7.19211 14.4053 7.59469C14.8079 7.99727 15.5415 8.25002 17 8.25002H19.1893L13.75 2.81068Z" fill="#2563EB"/>
                                            </svg>

                                            
                                        )} backgroundColor="white border-primary" textcolor="text-primary" OnClick={()=>{
                                            SetsetCustomDomain(list?.Domain);
                                            setshowRecord(true)
                                        }} /> 
                                        </>
                                        }

                                        <PrimaryButton text={ (deleteloading == ind) ? '' :'Remove'} style={{ background: "white", color: 'red', borderColor: 'red' }} OnClick={()=>DeleteCD(list,ind)} 
                                        icon={ (deleteloading == ind)  ? (<CgSpinner /> ) : null}
                                        disabled={(deleteloading == ind) } />

                                    </div>

                                </div>
                            )
                        })
                    }
                </div>

            </div>
            }
        </>
    )

}