import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import DefaultDropMenu from '../../../../input/droplist';
import { useEffect, useState } from 'react';


export const RenderdDevices = ({data={}}) => {


    ChartJS.register(ArcElement, Tooltip, Legend);
    const [ActiveType,setActiveType] = useState(0)
    const [GraphValue,setGraphValue] = useState([0, 0, 0])
    const [Datatype,setDatatype] = useState(['overall','views','click','resp'])

    useEffect (()=>{
        if(Object.keys(data).length !== 0 ){
            setDatatype(Object.keys(data))
            const DataTypeValue = Object.values(data) ?? [{tablet:0,desktop:0,mobile:0}] 
            setGraphValue(Object.values(DataTypeValue[0]))
            
        }
    },[data])

 

    const OnchangeType = (index,type) => {
        let Setindex = Datatype.indexOf(String(index).toLocaleLowerCase())
        setActiveType(Setindex)
        const DataTypeValue = Object.values(data) ?? [{tablet:0,desktop:0,mobile:0}] 
        setGraphValue(Object.values(DataTypeValue[Setindex]))
    }

    const testData = {
        labels: [ "tablet","desktop", "mobile" ],
        datasets: [
            {
                data: GraphValue,
                backgroundColor: ["#2563EB", "#FDC500", "#8AC926"],
                borderWidth: 0,
            },
        ]
    }


    return (
        <div className="h-full w-[38%] p-3 rounded-lg bg-white relative shadow-lg border">

            <div className='flex w-full justify-between' >
            <p className="z-30 text-lg font-medium py-4 px-5">
                Device
            </p>
                <DefaultDropMenu list={Datatype} active={ActiveType} onchange={OnchangeType} labelTextWeight='hidden' />
            </div>



            <div className="w-full py-2 h-[80%] mt-1">


                <Doughnut
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: true,
                                position: "bottom"
                            },
                        },
                    }}
                    data={testData}
                />
                

            </div>

        </div>
    )

}