import React, { useState } from "react";
import PrimaryButton from "../../../Button1/primarybutton";
import { Actions } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { EcommerceBlock } from "../../../ecommerceBlock";
import { useEffect } from "react";


export const Ecommerce = ({ field, fieldId }) => {

    const Dispath = useDispatch()

    const { Fields, device } = useSelector((state) => ({ Fields: state.fields, device: state.device }));

    const templateData = Fields[fieldId];
    const Settings = templateData?.filedContent
    const resultScreenContent = Settings.game_resultcreen_json;

    const [activeQue, setQctiveQue] = useState(0)
    const [arrAction, setarrAction] = useState([])
    const AddQues = () => {
        const setContent = {
            key: fieldId,
            node: 'game_mainpage_json',
            child: 'questions',
            value: {
                options: [
                    'option',
                    'option',
                    'option',
                    'option'
                ],
                question: '1. Test header........',
                InPutType: 'number',
                "questionId": Math.random().toString(16).slice(8) + Date.now().toString(36),
                IsOPtionType: false
            }
        }
        Dispath(Actions.setQuizQuestionAdd(setContent))
    }

    const addQuestionId = ( childInd ) => {

        let payload = {
  
          key:fieldId,
          node:'game_mainpage_json',
          child:'questions',
          childInd
  
        }
  
        Dispath(Actions.questionIdAdd( payload ))
  
      }

    useEffect(() => {
        let arr = []
        field?.questions?.map((list, index) => arr.push(list?.question))
        if ("results" in resultScreenContent) resultScreenContent?.results.map((list, index) => arr.push(list?.titleContent))
        else arr.push(resultScreenContent?.titleContent)
        setarrAction(arr)
    }, [])

    return (

        <div className="w-full space-y-4 ">
            {
                field?.questions?.map((list, index) => {

                    if(!list?.questionId) addQuestionId( index )
                    
                    return (<EcommerceBlock Index={index} required={list?.required ?? false} isAcitve={activeQue} setQctiveQue={setQctiveQue} InPutType={list?.InPutType} jumpToLeft={arrAction} rules={list?.rules} question={list?.question} IsOPtionType={list?.IsOPtionType} fieldId={fieldId} options={list?.options} />)
                })
            }
            <PrimaryButton text={'Add Question'} width="w-full" height="h-12" backgroundColor="bg-primary-lightblue" border="border-1 border-primary" textcolor="text-primary" OnClick={AddQues} />

        </div>
    )
}