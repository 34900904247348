


export var device_flow=[  

    {
        element: ".step-design-device",
        title: "Views Include",
        intro: "Different views include desktop, tablet, and mobile, each tailored for optimal user experience and display.",
    }
]

export var backGroundImage_flow=[  

    {
        element: ".step-design-backGroundImage",
        title: "Background Type",
        intro: "The background type section includes options for custom backgrounds colours and images (with a max file size of 3MB).",
        scrollToElement:false,
        options:{
             scrollToElement:false
        }
    }
]
export var addNewField_flow=[  

    {
        element: ".step-design-addNewField",
        title: "Add New Field",
        intro: "Configure static fields such as title, description, button, image, logo, email input, YouTube video, and audio, as well as gamification field including a list of games.",
    }
]
export var editDesignBtn_flow=[  

    {
        element: ".step-design-editDesignBtn",
        title: "Editing",
        intro: "Modify the existing template, adjust features such as the start screen, question screen, and result screen.",
        scrollToElement:false,
        options:{
             scrollToElement:false
        }
    }
]