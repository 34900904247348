import React, { useState } from "react";
import PrimaryButton from "../../Button1/primarybutton";
import InputWithLabel from "../../input/inputWithLabel";
import TextareaInput from "../../input/textarea";
import { MediaPopup } from "../../popup/Mediapopup";
import { useDispatch } from "react-redux";
import { Actions } from "../../../store";
import toast from "react-hot-toast";
import { listOfIcon } from "../../../content/iconList";

export const AddProductManual = ({ SetStage = null, fieldId = '' }) => {
    const Dispatch = useDispatch()
    const [ShowMedia, setShowMedia] = useState(false)
    const[productDetail,setProductDetail] = useState( {
        id:"manual_"+Math.random().toString(32).slice(4) + Date.now().toString(36),
        productName: "",
        description: "",
        productImageSrc: "",
        sku: "",
        price: "",
        tag: "",
        buttonText: "Buy Now",
        buttonLink: "",
        buttonColor: "",
        backgroundColor: ""
    })
        const setContent = {
            isAdded: true,
            key: fieldId,
            node: 'game_mainpage_json',
            child: "ManualList",
            value: productDetail
        }

    const DirectContentChange = (value, type) => {

        let product = Object.assign({},productDetail)
        product[type] = value
        setProductDetail(product)
        if (ShowMedia) setShowMedia(false)

    }

    const checkFiled = () =>{
        if ((productDetail?.productName === "")  ||(productDetail?.sku === "") ||(productDetail?.price == "") ||(productDetail?.buttonText == "") ||(productDetail?.tag == "")  ) { toast.error('Please Fill the Required Fileds');}
       else {
        Dispatch(Actions.setProductDetail(setContent))
        Dispatch(Actions.setUpdateCampaign())
        SetStage(2)
       }
    }

    return (

        <div className="w-full h-full flex flex-col">
            {ShowMedia && <MediaPopup cancel={setShowMedia} fieldType={'productImageSrc'} OnSelect={DirectContentChange} />}

            <div className="flex justify-between py-3 px-6">
                <p className="my-auto font-medium flex space-x-4  ">
                    
                    <span className="py-2 px-3 border-2 rounded-md cursor-pointer" onClick={() => SetStage(0)} > { listOfIcon('leftarrow',14,14,'#586474') } </span>
                    <span className="my-auto text-lg">Add your Products</span>
                </p>
                <div className="flex space-x-4 my-auto">
                    <PrimaryButton
                        buttontype="secondarybutton"
                        text={"Add Another Product"}
                        margin="my-auto"
                        OnClick={()=>SetStage(0)}
                    />
                    <PrimaryButton text={"Add Product"} margin="my-auto" OnClick={()=>{
                         checkFiled();


                        }} />
                </div>
            </div>
            <div className="w-full flex h-full bg-[#EFEFEF]">
                <div className="w-3/5 flex my-auto space-x-4 p-6 rounded-lg mx-auto bg-white h-fit">
                    <div className="w-1/2 flex flex-col space-y-3 h-fit ">
                        {productDetail?.productImageSrc !== "" ? (
                            <div className="flex flex-col border-2 border-dashed min-h-[157px] border-primary p-3 rounded-lg cursor-pointer  justify-center bg-primary bg-opacity-10" onClick={() => setShowMedia(true)} >
                                <div className="flex max-w-full m-auto max-h-full">
                                    <img src={productDetail?.productImageSrc} alt="productimage" />
                                </div>
                            </div>
                        ) : (
                            <div className="flex flex-col border-2 border-dashed min-h-[157px] border-primary p-3 rounded-lg  justify-center bg-primary bg-opacity-10 cursor-pointer" onClick={() => setShowMedia(true)}>
                                <span className="mx-auto">
                                    <svg
                                        width="62"
                                        height="62"
                                        viewBox="0 0 62 62"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M40.0191 8.90405C33.3556 8.76492 26.4797 12.5873 24.5978 21.1913C16.9238 18.5771 9.99667 23.7395 10.7143 31.992C8.69086 32.8091 6.95824 34.2129 5.73938 36.0229C4.52053 37.8329 3.87122 39.9663 3.87502 42.1484C3.87502 48.2188 8.76648 53.1029 14.8369 53.1029H28.1493L28.1346 40.6107L25.0152 41.7457C22.7452 42.7196 21.0757 39.8638 23.0162 38.0844L28.9255 32.3508C30.2728 31.0035 31.8838 31.1573 33.0773 32.3508L38.9866 38.0917C40.9271 39.8638 39.2502 42.7196 36.9876 41.7457L33.8682 40.6107V53.1029H47.1586C53.229 53.1029 58.1131 48.2188 58.1131 42.1484C58.1131 37.6817 55.4624 33.8593 51.6473 32.1531C59.0431 17.7277 49.7581 9.09444 40.0191 8.90405Z"
                                            fill="#588AF7"
                                        />
                                        <path
                                            d="M40.0172 8.89697C39.6511 8.89697 39.241 8.9043 38.8676 8.91894C30.3588 22.3339 43.5101 32.1461 51.6527 32.1461C59.0485 17.7206 49.7562 9.09468 40.0172 8.89697Z"
                                            fill="#2563EB"
                                        />
                                    </svg>
                                </span>
                                <span className="mx-auto">Upload Image</span>
                            </div>
                        )}
                        <InputWithLabel label="Product Name*" required={true} textvalue={productDetail?.productName}  onchange={(e) => DirectContentChange(e.target.value, "productName")} />
                        <TextareaInput label="Description" textvalue={productDetail?.description} onchange={(e) => DirectContentChange(e.target.value, "description")} />
                    </div>
                    <div className="w-1/2 flex flex-col space-y-3 h-fit ">
                        <InputWithLabel label="SKU*" textvalue={productDetail?.sku} onchange={(e) => DirectContentChange(e.target.value, "sku")} />
                        <InputWithLabel label="Price*" textvalue={productDetail?.price} onchange={(e) => DirectContentChange(e.target.value, "price")} />
                        <InputWithLabel label="Button*" textvalue={productDetail?.buttonText} onchange={(e) => DirectContentChange(e.target.value, "button")} />
                        <InputWithLabel label="Button Link" textvalue={productDetail?.buttonLink} onchange={(e) => DirectContentChange(e.target.value, "buttonLink")} />
                        <InputWithLabel label="Tag*" textvalue={productDetail?.tag} onchange={(e) => DirectContentChange(e.target.value, "tag")} />
                    </div>
                </div>
            </div>
        </div>
    );
};
