import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditorTooltip } from "../Editor/common/editortooltip";
import { isNotEmpty } from "../../utils/helpers";
import WebFont from 'webfontloader' 
import { useEffect } from "react";
import { Actions } from "../../store";


export const EditorTitle = ({fieldID='',position,priview = false, setTriggerField=null,toShowTips=false}) => {

    const { Fields, campaignSettings } = useSelector((state)=>({ Fields:state.fields, campaignSettings:state.campaignSettings }));

    const DisPatch = useDispatch();

    const { fontSize } = campaignSettings;

   
    const TitleSize = {
        "small": "text-[1.5rem] font-medium ",
        "medium": "text-[2rem] font-semibold",
        "large": "text-[2.25rem] "
    }
    
    const titleContent = Fields[fieldID];

    const font=isNotEmpty(titleContent?.filedContent?.FontFamily)? titleContent?.filedContent?.FontFamily : "ABeeZee";
    
    useEffect(()=>{

        WebFont.load({ google : { families : font }})  

    },[])  

    return (

        <div id={"gameFrameBorder"+fieldID} onClick={()=>{DisPatch(Actions.setActiveFieldside({filedId:fieldID,index:position,}))}} className={` relative flex flex-col py-4 group `}>

            {!priview && toShowTips &&<EditorTooltip position={position} setTriggerField={setTriggerField} />}

            <h2 className={`w-4/5 mx-auto  capitalize ${TitleSize[fontSize]} text-center`} 
            style={{color:titleContent?.filedContent?.TextColor,fontFamily:font}} >
                { titleContent?.filedContent?.Content === '' ? '10X Your Sales And Revenue With an Organic Growth Platform Powered by AI' : titleContent?.filedContent?.Content}  </h2>

        </div>

    )

}