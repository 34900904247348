import React, { useEffect, useRef, useState } from "react";
import { SketchPicker, SwatchesPicker } from "react-color";
import { Clickouter } from "../../utils/clickouter";


export const ColorPixerInput = ({
    label = 'Label name',
    labelTextsize = '',
    labelTextWeight = '',
    fieldId = '',
    fieldType = '',
    colorHex = '#544f6f',
    Onchange = null,
    mainclass=""
}) => {


    const [color, setcolor] = useState(colorHex)
    const menuRef = useRef();
    const [showPixer, setshowPixerPiker] = useState(false)

    
    
    useEffect(()=>{
        let handleAction = (event) => {

            if (menuRef.current && !menuRef.current?.contains(event.target)) {
                // alert("test-out")
                setshowPixerPiker(false);
            }
        };
    
        document.addEventListener("mousedown", handleAction);
    },[])
    
    // useEffect(Clickouter(menuRef,setshowPixerPiker),[])

    return (
        <div className={"relative justify-between flex space-x-4 "+mainclass} >
            <label className={` ${labelTextsize} ${labelTextWeight} capitalize min-w-[130px]`}>{label}</label>
            <div className="flex space-x-2 border-1 p-1 rounded-lg" onClick={() => setshowPixerPiker(showPixer ? false : true)}>
                <div className="w-7 h-7 rounded-md border-border border-1" style={{ background: color }} ></div>
                <span className="my-auto">
                    <svg
                        className="text-font_secondary"
                        width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.76693 0.741797L5.00026 3.97513L8.23359 0.741797C8.55859 0.416797 9.0836 0.416797 9.4086 0.741797C9.7336 1.0668 9.7336 1.5918 9.4086 1.9168L5.58359 5.7418C5.25859 6.0668 4.73359 6.0668 4.40859 5.7418L0.583594 1.9168C0.258594 1.5918 0.258594 1.0668 0.583594 0.741797C0.908594 0.42513 1.44193 0.416797 1.76693 0.741797Z" fill="currentColor" />
                    </svg>
                </span>
            </div>
            {
                showPixer && <div className="absolute top-5 right-16" style={{ zIndex: '60' }} ref={menuRef} >
                    <SketchPicker disableAlpha={true} className="cursor-pointer" color={colorHex} onChange={(color) => { Onchange(color.hex, fieldType, fieldId); setcolor(color.hex) }} />
                </div>
            }
        </div>
    )
}