import React, {  useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { getCampaign, liveOrPauseCampaign } from "../actions/templates";
// import { useDispatch } from "react-redux";
// import { Actions } from "../store";

export const Crudopration = ({ projects, setshoWDel, setshoWMov, show, setloadingText, setshoWCpy, loadDashboard, setLoading = null, setPopNum = null,  status = "paused",  index,  data,  camId, setLoadingForDelte = null, SharedWS=false}) => {
  const menuref = useRef();
  const Navigate = useNavigate();
//   const Dispatch = useDispatch();

  const evnet = async (e) => {
    if(e=== "Move") { if(projects?.length<=1) toast.error("Kindly create two or more workpaces to move the campaign!"); else setshoWMov(true)}
    if (e === "Pause" || e === "Publish") LiveOrPause();
    if (e === "Delete")  setshoWDel(true);
    if (e === "Edit") await Navigate(`/campaign/edit/${camId}`);
    if (e === "Copy Link") {
            setshoWCpy(false);
            if (status === "paused") {
                toast.error("Kindly publish your campaign!");
            } else {
                setshoWCpy(true);
            }
        }
  };

  const LiveOrPause = async () => {

    setloadingText("Updating campaign");
    setLoading(true);
    let campaign = await getCampaign(camId);
    if (!campaign.success) return;

    campaign = campaign.data;
    let order = campaign.order, fields = campaign.fileds;

    const paytload = {
      name: campaign.name,
      status: campaign.status,
      campaign_update: campaign.campaign_update,
      order: order,
      fileds: fields,
    };

    const respData = await liveOrPauseCampaign(
      paytload,
      campaign.campaign_id,
      campaign.status === "active" ? "disable" : "enable"
    );

    if (respData?.success) toast.success( campaign.status !== "active" ? "Campaign published!" : "Campaign paused!" );
    else toast.error(respData?.message);

    // const payload = {
    //   type: "status",
    //   value: campaign.status === "active" ? "paused" : "active",
    // };

    // const cppayload = {
    //   type: "CPstatus",
    //   value: true,
    // };
    
    // Dispatch(Actions.setCampaignUpdate(payload));
    // if (campaign.status !== "active")  Dispatch(Actions.setCampaignUpdate(cppayload));

    await loadDashboard();
    setloadingText("Fetching Your Data");
    setLoading(false);
  };
  
  return (
    <div className={`${ show ? "flex" : "hidden" } flex-col  absolute bg-white border-1  rounded-lg ${  data?.length === 5 || SharedWS ? "bottom-[-160px]" : "bottom-[-196px]" } right-[-15px] z-20  w-[135px] duration-200`} ref={menuref} >
      {data.map((i, k) => {
        if(SharedWS && i?.name==="Move") return null;
        if (i?.name === "Pause" && status === "paused") return null;
        else if (i?.name === "Publish" && status === "active") return null;
        else return (
            <span key={k}  className="noselect hover:bg-gray-100 px-6 py-2 rounded-lg" onClick={() => evnet(i?.name)} > {i["name"]} </span>
          );
      })}
    </div>
  );
};
