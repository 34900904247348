import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EditorDescription } from "../../renderComponents/description";
import { EditorTitle } from "../../renderComponents/title";
import HandleIcon from "../../templatepreview/handleicon";
import { ImagerenderBlock } from "../../renderComponents/image";
import { QuizTemplate } from "../../renderComponents/quiz/quiz";
import { PollTemplate } from "../../renderComponents/poll/poll";
import { TemlpateRC } from "../../renderComponents/tempates";
import { EcommerceTemplate } from "../../renderComponents/ecommerce";
import { CalculatorTemplate } from "../../renderComponents/calculator";
import { SpinWheelTemp } from "../../renderComponents/tempates/spinwheel";
import { SurveyTemplate } from "../../renderComponents/survey";
import { Actions } from "../../../store";
import { SpinWheelSideTab } from "../../renderComponents/tempates/spinWheelSideTap";
import { ButtonBlock } from "../../renderComponents/buttonComp";
import { EmailBlock } from "../../renderComponents/EmailBlock";
import { EmbedBlock } from "../../renderComponents/embedBlock";
import { VideorenderBlock } from "../../renderComponents/video";
import { LogoBlock } from "../../renderComponents/logoBlock";
import { AudioREBlock } from "../../renderComponents/AudioRe";
import { isNotEmpty } from "../../../utils/helpers";
import WebFont from "webfontloader";
import { listOfIcon } from "../../../content/iconList";


export const EditerMainBlock = ({ editName = null, preview = true, integration= true }) => {

    const Navigate = useNavigate();
    const Dispatch = useDispatch()
    useEffect(() => { Dispatch(Actions.setChangeDevice("desktop")) }, [])

    const [ActiveDevice, setActiveDevice] = useState('desktop')

    const [GameMode, setGameMode] = useState(false)

    // const { Order, field, campaign, decivestate } = useSelector((state) => ({ Order: state.fieldsOrder, field: state.fields, campaign: state.Campaign_info, decivestate: state.device }))
    const { Order, field, campaign, decivestate, campaignSettings } = useSelector((state) => ({ Order: state.fieldsOrder, field: state.fields, campaign: state.Campaign_info, decivestate: state.device, campaignSettings:state?.campaignSettings }))
    
    const { fontFamily, backgroundType, spaceBetweenTheBlock, image, color } = campaignSettings;

    useEffect(()=>{

        WebFont.load({ google : { families : [ fontFamily ] }})  

    },[fontFamily])  

    const spaceBetween = [ "space-y-0", "space-y-1", "space-y-2", "space-y-3", "space-y-4", "space-y-5", "space-y-6", "space-y-7", "space-y-8", "space-y-9", "space-y-10", ];

    const leftArrow = listOfIcon('leftarrow',14,14,'#586474');
    
    return (

        <div className="h-full">

            <div className="flex flex-col w-full border-1 h-full ">

                { !integration && <div className="flex justify-between py-3 px-4 border-b-1">

                    <p className="my-auto font-medium flex space-x-2 ml-2 ">

                        <span className="py-2 px-3 border-2 rounded-md cursor-pointer" onClick={() => Navigate(-1)}> {leftArrow} </span>
                        
                        <span className="my-auto " >{campaign?.name}</span>

                    </p>

                    <HandleIcon activeState={setActiveDevice} />

                </div> }


                <div className={` ${decivestate === 'mobile' ? 'w-[460px]' : decivestate === 'tab' ? 'w-[770px]' : 'w-full'} scroll-smooth transition-all duration-500 mx-auto h-full ${spaceBetween[spaceBetweenTheBlock]} bg-primary-lightblue ${GameMode ? "" : "py-0"}  overflow-y-auto list-industry`} style={{ background: ( backgroundType == "color") ? (color ?? "#7ED321") : `url(${ image ?? "/asset/images/quizzes/background.png"}) 0% 0% / 100% 100% no-repeat`, backgroundSize: "cover",fontFamily:fontFamily }} >

                    {


                        Order.map((list, index) => {
                            let data = { "fieldID": list, "position": index, "toShowTips": false }
                            if (field[list]?.templatetype === 'untemplate') return (
                                field[list].type === 'title' ? <EditorTitle key={index} {...data} /> :
                                field[list].type === 'description' ? <EditorDescription key={index}  {...data} /> :
                                field[list].type === 'image' ? <ImagerenderBlock key={index}  {...data} /> :
                                field[list].type === 'button' && field[list]?.filedContent?.DisplayView===false ? <ButtonBlock {...data} /> :
                                field[list].type === 'email' && field[list]?.filedContent?.DisplayView===false ? <EmailBlock {...data} /> :
                                field[list].type === 'embed' && field[list]?.filedContent?.DisplayView===false && isNotEmpty (field[list]?.filedContent?.EmbedCode) ? <EmbedBlock {...data} /> :
                                field[list].type === 'video' && field[list]?.filedContent?.DisplayView===false ?  <VideorenderBlock  {...data} /> :
                                field[list].type === 'logo' && field[list]?.filedContent?.DisplayView===false?  <LogoBlock {...data} />  :
                                field[list].type === 'audio' && field[list]?.filedContent?.DisplayView===false ?  <AudioREBlock {...data} />  :

                                null
                            )
                            if (field[list]?.category === 'games' && field[list].templatetype === 'template') return (
                                <TemlpateRC key={index}  {...data} />
                            )
                            if (field[list]?.category === 'quizzes') return (
                                <QuizTemplate key={index}  {...data} />
                            )
                            if (field[list]?.category === 'poll') return (
                                <PollTemplate key={index}  {...data} />
                            )
                            if (field[list]?.category === 'spinWheel' && field[list]?.filedContent?.game_mainpage_json?.spinWheelType === "default") return (
                                <SpinWheelTemp key={index} {...data} />
                            )
                            if (field[list]?.category === 'spinWheel' && field[list]?.filedContent?.game_mainpage_json?.spinWheelType === "sideTab") return (
                                <SpinWheelSideTab key={index} {...data} />
                            )
                            if (field[list]?.category === 'calculater') return (
                                <CalculatorTemplate key={index}  {...data} />
                            )
                            if (field[list]?.category === 'ecommerce') return (
                                <EcommerceTemplate key={index}  {...data} />
                            )
                            if (field[list]?.category === 'surveys') return (
                                <SurveyTemplate key={index}  {...data} />
                            )
                        })
                    }


                </div>

            </div>

        </div>
    )
}