import React, { useEffect, useState } from "react";

export const TimerCount = ({
    countDown = false,
    gamestart = false,
    timeCount = 30,
    second = 0,
    min = 0,
    timerStop = null,
    setSecond = null,
    setMin = null,
    primaryColor = 'white',
    secondaryColor = 'black',
    parentClass=""
}) => {

    // const [second, setSecond] = useState(0);
    // const [min, setMin] = useState(0);
    const [timercheck, settimercheck] = useState();



    useEffect(() => {
        if (countDown) {
            setMin(Math.floor(timeCount / 60))
            setSecond(Math.floor(timeCount % 60))
        }
        Starttimer(gamestart);
        if (!gamestart) clearInterval(timercheck)
    }, [gamestart]);

    const Starttimer = (state) => {

        if (state) settimercheck(setInterval(() => {
            setSecond(obj => {
                if (countDown) {
                    if (obj == 0) {
                        setMin(
                            objmin => {
                                if (objmin === 0) return timerStop()
                                else return objmin - 1
                            }
                        )
                        return 0
                    }
                    else return obj - 1
                }
                else {
                    if (obj == 60) {
                        setMin(objmin => objmin + 1)
                        return 0
                    }
                    else return obj + 1
                }

            })

        }, 1100));

    }



    return (
        <div className={` w-fit  flex flex-col justify-center  font-medium  rounded-lg ArrQuestion text-white my-auto ${parentClass}`} style={{color:primaryColor,borderColor:primaryColor}} >

            <div className="flex w-fit flex-col my-auto mx-auto text-white text-md bg-[rgba(250,250,250,0)] justify-between font-medium rounded-full " style={{color:primaryColor,borderColor:primaryColor ,}} >

                <p className="flex flex-row space-y-1 capitalize">

                    <span className="flex space-x-1 mx-auto">

                        <span className="flex my-auto" >
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill={primaryColor} />
                                <path d="M15.7096 15.9298C15.5796 15.9298 15.4496 15.8998 15.3296 15.8198L12.2296 13.9698C11.4596 13.5098 10.8896 12.4998 10.8896 11.6098V7.50977C10.8896 7.09977 11.2296 6.75977 11.6396 6.75977C12.0496 6.75977 12.3896 7.09977 12.3896 7.50977V11.6098C12.3896 11.9698 12.6896 12.4998 12.9996 12.6798L16.0996 14.5298C16.4596 14.7398 16.5696 15.1998 16.3596 15.5598C16.2096 15.7998 15.9596 15.9298 15.7096 15.9298Z" fill={primaryColor} />
                            </svg>

                        </span>

                        <span className="w-fit  flex flex-col ">
                            <span>{min < 10 ? `0${min}` : min}</span>
                        </span>

                        <span>:</span>

                        <span className="w-fit flex flex-col">
                            <span>{second < 10 ? `0${second}` : second}</span>
                        </span>

                    </span>
                </p>
            </div>
        </div>
    )
}