
import React from 'react'
import { useNavigate } from 'react-router-dom';

const GzLogo = function ({ classNamecontainer = "", classNameimg = "", onClick, disabled = false, }) {
    const navigate = useNavigate()

    return (
        <div className={` ${classNamecontainer} cursor-pointer`} onClick={()=>navigate('/dashboard')} >
            <img src="/asset/images/GoZenEngage.png" title='GoZenEngage' alt="GoZenEngage" className={` ${classNameimg}`}/>



       </div>
    )
} 


export default GzLogo;



