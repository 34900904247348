
import { attempt, get, isError, isFunction, isNumber, toFinite, isString, map, parseInt, reduce, replace, size, split, toArray, trim } from 'lodash';
import queryString from 'query-string';
import DateFormat from 'dateformat'
import _replace from 'lodash/replace';
import _isUndefined from 'lodash/isUndefined';
import _isNaN from 'lodash/isNaN';
import _axios from '../axios';

export const isValidEmail = (email) => {
	return /[^\s@]+@[^\s@]+\.[^\s@]+/.test(email);
}

export const validateSearch = (str) => {
	str = trim(str);
	const reg_ = new RegExp("^[^<>$!%^*\";?/{}]+$")
	return reg_.test(str);
}

export const validateString = (str) => {
	str = trim(str);
	const reg_ = new RegExp("[0-9a-zA-Z][^#&<>\"~;$^%{}?]{0,400}$")
	return reg_.test(str);
}

export const validateName = (str) => {
	str = trim(str);
	const reg_ = new RegExp("[a-zA-Z][^#&<>\"~;$^%{}?]{0,20}$")
	return reg_.test(str);
}


export const validateNumber = (mb) => {
	return /^(0|[1-9]\d*)(e-?(0|[1-9]\d*))?$/i.test(mb)
}

export const isValidPhoneNumber = (phone) => {
	phone = trim(phone);
	const reg_ = new RegExp("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$")
	const ValidReg = reg_.test(phone);
	const ValidLength = size(phone) >= 6 && size(phone) <= 15;

	return ValidReg && ValidLength
}

export const getAxiosErrorObject = (err, dm) => {
	return get(err, "response.data.error", dm) || dm
}

export const getAxiosErrorMessage = (err) => {

	const field_error = get(err, "response.data.error.details.0.message")
	const path = replace(get(err, "response.data.error.details.0.path"), "/", "");

	let message = get(err, "response.data.error.message");

	if (field_error) message = `${path ? path + " : " : " "}${field_error}`;

	return message
}

export const getAxiosError = (err, dm) => {

	let result = {};

	const field_error = get(err, "response.data.error.details.0.message")
	const path = replace(get(err, "response.data.error.details.0.path"), "/", "");

	const message = get(err, "response.data.error.message");
	const name = get(err, "response.data.error.name");
	const code = get(err, "response.data.error.statusCode");

	if (field_error) result.message = `${path ? path + " : " : " "}${field_error}`;
	else if (message) result.message = isString(message) ? message : isString(name) ? name : "Failed";
	else result.message = dm;

	result['code'] = code;
	return result
}

export const getAxiosResponse = (data, param) => {
	return param ? get(data, `data.${param}`) : get(data, "data")
}

export const getAxiosErrorObjectAndFields = (err) => {
	const details = get(err, "error.details")
	const results = [];
	map(details, it => {
		const k = replace(get(it, "path"), "/", '');
		results.push({ [k]: get(it, "message") })
	});
	return results;
}

export const loadCaptcha = () => {
	try {
		const script = document.createElement('script');

		script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`;
		script.id = "retainful-referral-captcha";
		document.body.appendChild(script);
	}
	catch (er) {
	}
}

export const removeCaptchaContainer = (cb) => {
	try {
		const elem = document.getElementsByClassName('grecaptcha-badge')[0]
		elem && elem.remove()
	}
	catch (er) {

	}
}


export const generateCaptchaToken = (cb) => {

	try {
		if (!process.env.REACT_APP_RECAPTCHA_KEY) throw "Invalid key";

		window.grecaptcha.ready(() => {
			window.grecaptcha
				.execute(process.env.REACT_APP_RECAPTCHA_KEY, {
					action: 'submit',
				})
				.then(token => {
					cb && cb(token)
				})
		});

	}
	catch (er) {
		alert("Captcha validation failed test");
		return false;
	}

}

export const OrderObjectKeys = (obj, order) => {

	order.forEach((k) => {
		const v = obj[k]
		delete obj[k]
		obj[k] = v
	})

	return obj

}
export const getAnId = () => '_' + Math.random().toString(36).substr(2, 9);

export const getQueryParams = (atr) => {
	try {
		return queryString.parse(window.location.search);
	}
	catch (er) {
		return {}
	}
}

export const getQueryParam = (atr) => {
	try {
		const parsed = getQueryParams()
		return get(parsed, atr)
	}
	catch (er) {
		return null
	}
}

export const getHashParam = (atr) => {
	var url = new URL(window.location.href.replace(/#/g, "?"));
	return url.searchParams.get(atr);
}

export const doRedirect = (history, payload) => {

	if (isFunction(get(history, "push"))) {

		const action = get(payload, "action");

		if (action === "campaign_to_editor")
			history.push(`/campaign/design/editor/${payload.campaign_uuid}`);

		if (action === "campaign_to_campaigns")
			history.push(`/campaigns`)

	}

	else window.location.href = '/'
}

export const parseJSON = (str, default_value = {}) => {

	var data = attempt(JSON.parse, str);

	if (!isError(data))
		return data
	else
		return default_value || str;
}

export const defaultPagination = (obj = {}) => Object.assign({}, {
	current_page: 1,
	total: 0,
	last_page: 1,
	orderBy: 1,
	sortBy: "created_at"
}, obj)

export const trimPayload = (obj) => {
	try {
		return reduce(obj, (src, val, key) => {
			src[key] = isNumber(val) ? val : trim(val)
			return src;
		}, {})
	}
	catch (er) {
		return obj
	}
}


export const disPatchAppError = ({ message, code }) => {
	return { type: "error", title: message, code }
}

export const disPatchAppSuccess = (message, duration, position = "top-center") => {
	return { type: "success", title: message, duration, position }
}


export const checkPasswordLength = (password) => {
	return password.length >= 8;
}

export const checkLowercase = (lowercase) => {
	const pattern = /[a-z]/;
	if (pattern.test(lowercase)) {
		return true
	} else {
		return false
	}
}

export const checkSpecialCharacters = (password) => {
	const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
	if (pattern.test(password)) {
		return true
	} else {
		return false
	}
}

export const checkUppercase = (password) => {
	const pattern = /[A-Z]/;
	if (pattern.test(password)) {
		return true
	} else {
		return false
	}
}

export const checkNumber = (password) => {
	const pattern = /[0-9]/;
	if (pattern.test(password)) {
		return true
	} else {
		return false
	}
}

export const requestWithTimeOut = (axios, timeout = 7000) => {
	return Promise.race([
		axios,
		new Promise((_, reject) =>
			setTimeout(() => reject({ error: new Error('timeout'), response: { status: 408 } }), timeout)
		)
	]);
}

export const getDigit = (val = "") => {
	return String(val).replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
}

export const formatDate = (d = Date.now() / 1000, fm = "dd mmmm yyyy") => DateFormat((d * 1000), fm)


export const isUUid = (u = "") => /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(u);

export const validateDomain = (domain = "") => {
	var re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/);
	return domain.match(re);
}

export const clearSession = function () {
	localStorage.clear();
	sessionStorage.clear();
}
export const sortByName = (arr, k = "title") => Array.isArray(arr) ? arr.sort(function (a, b) {
	if (a[k] < b[k]) { return -1; }
	if (a[k] > b[k]) { return 1; }
	return 0;
}) : arr

export const sortByIndex = (arr) => Array.isArray(arr) ? arr.sort((v1, v2) => v1.index - v2.index) : arr


export const toFixed = function (val, _symbol) {

	if (!val)
		return '';

	try {
		let symbol = !_isUndefined(_symbol) ? _symbol : '$';
		let value = (+_replace(val, symbol, ''));

		if (_isNaN(value))
			return val;

		let isNegative = value < 0;

		return isNegative ? ('-' + symbol + (value * -1).toFixed(2)) : (symbol + value.toFixed(2));
	} catch (error) {
		return val;
	}
}

export const appendCurrency = function (currency, code, withoutNegative = false) {
	let currencyValue = (+currency).toFixed(2);
	return currencyValue < 0 ? (withoutNegative ? '' : '-') + code + (currencyValue * -1) : code + currencyValue;
}

export const getFirstName = function (user = {}) {
	return get(user, "first_name") || get(split(get(user, "email_address"), "@"), "0") || ""
}
export const percentage = function (total, value = 0) {
	const result = total ? ((parseInt(value) / parseInt(total)) * 100).toFixed(2) : 0;
	return (isNaN(result) ? 0 : result) + "%"
}

export const getValueFromPercentage = function (total, per = 100) {
	const result = total ? (parseInt(total) / parseInt(per)) : 0;
	return (isNaN(result) ? 0 : result)
}
export const getPercentageFromValue = function (value = 1, total = 1) {
	const result = total ? (parseInt(value) / parseInt(total)) * 100 : 0;
	return (isNaN(result) ? 0 : result)
}
export const getPlanDetail = function (plan_info = {}, params = {}) {
	try {

		plan_info = JSON.parse(sessionStorage.getItem("plan"))

		const paidPlans = ['starter', 'business', 'professional', 'starter-appsumo', 'starter-pitchground']
		const featurePlans = ['business', 'professional']
		const plan = plan_info.plan;
		const isAppSumoStarter = plan === 'starter-appsumo' || plan === 'starter-pitchground';

		const isPaidPlan = paidPlans.indexOf(plan) > -1;

		return {
			is_paid_user: isPaidPlan,
			can_schedule_campaign: isPaidPlan,
			can_choose_sg_form_premium_template: isAppSumoStarter || featurePlans.indexOf(plan) > -1,
			can_show_add_team_member: isPaidPlan &&
				(
					plan.indexOf('starter') > -1 ? get(params, "total_members") < 4 :
						plan === 'business' ? get(params, "total_members") < 6 : true
				),
		}
	}
	catch (er) {
		return {}
	}
}

export const getGreetingTime = (m) => {
	try {
		const currentHour = new Date().getHours();

		const greetingMessage =
			currentHour >= 4 && currentHour < 12 ? // after 4:00AM and before 12:00PM
				'Good morning' :
				currentHour >= 12 && currentHour <= 17 ? // after 12:00PM and before 6:00pm
					'Good afternoon' :
					currentHour > 17 || currentHour < 4 ? // after 5:59pm or before 4:00AM (to accommodate night owls)
						'Good evening' : // if for some reason the calculation didn't work
						'Welcome'
		return greetingMessage;
	}
	catch (e) {
		return false
	}
}

export const setCookie = function (name, value, exp) {
	var date = new Date;
	date.setTime(date.getTime() + 24 * exp * 60 * 60 * 1e3);
	var expires = "expires=" + date.toGMTString();
	document.cookie = name + "=" + value + "; " + expires + "; path=/"
}

export const getCookie = function (e) {
	for (var t = e + "=", i = document.cookie.split(";"), n = 0; n < i.length; n++) {
		for (var o = i[n]; " " == o.charAt(0);)
			o = o.substring(1);
		if (-1 != o.indexOf(t))
			return o.substring(t.length, o.length)
	}
	return ""
}

export const getMaxMailingLimit = (plan) => {

	const is_free = plan === 'free';
	const starter = plan === 'starter';
	const AppSumoStarter = plan === 'starter-appsumo' || plan === 'starter-pitchground';
	const business = plan === 'business';
	const professional = plan === 'professional';

	if (is_free) {
		return 11000
	}
	else if (starter) {
		return 15000
	}
	else if (business) {
		return 18000
	}
	else if (professional) {
		return 20000
	}
	else if (AppSumoStarter) {
		return 5000
	}
}
export const uuidv4 = () => {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
		(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
	);
}
export function seo(data = {}) {
	data.title = (data.title ? (data.title + " - GoZen Growth") : "GoZenGrowth");
	data.metaDescription = data.desc || 'GoZen Growth';

	document.title = data.title;
	document.querySelector('meta[name="description"]').setAttribute('content', data.desc);
}

export const injectScript = (url, cb) => {
	const sc = document.createElement("script");
	sc.src = url;
	sc.type = "text/javascript";
	document.body.appendChild(sc);
	sc.onload = cb
};

export const response = (suc, result, msg) => {
	return {
		suc, result, msg
	}
}

export const callApi = ({ url, method = 'GET', data }) =>
	_axios({
		url, data, method
	})
		.then(r => r.data).then(r => response(true, r, ""))
		.catch(er => response(false, null, getAxiosErrorMessage(er)));

export const isDev = () => process.env.NODE_ENV === 'development';
export const isDevOrBeta = () => process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'beta';
export const ResetDocTitle = () => document.title = 'GoZen Growth';
export const SetDocTitle = (t) => document.title = t || 'GoZen Growth';
