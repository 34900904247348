export const PasswordValid = ({ 
    charNumberValid, 
    uppercaseValid, 
    lowercaseValid, 
    specialCharValid,
    passwordMatch = null
}) => {

    const getClass = (t) => {
        return ('text-sm relative items-center break-words mb-3.5 '+ (t===true ? ' text-green-600 ': ' text-red-600'))
    }
    

    if (charNumberValid && uppercaseValid && lowercaseValid && charNumberValid && specialCharValid) { 
        return null
    }

    return (
        <div className="border border-gray-300 p-3 rounded-md mb-3 w-full">
            
            <ul className="list-disc list-inside">

                <li className={getClass(charNumberValid)}>
                    <span>
                        Minimum 8 characters
                    </span>
                </li>

                <li className={getClass(uppercaseValid)}>
                    <span >
                        One uppercase character
                    </span>
                </li>

                <li className={getClass(lowercaseValid)}>
                    <span >
                        One lowercase character
                    </span>
                </li>

                { passwordMatch ? 
                    <li className={getClass(passwordMatch)}>
                        <span > 
                            Password does not match
                        </span>
                    </li> : null
                }

                <li className={getClass(specialCharValid)}>
                    <span >
                        One digit and one special character <br/>
                        <span className=" ml-5"> 
                            (Ex: !@#$%^&*")
                        </span>
                    </span>
                </li>

            </ul>

        </div>
    )
}