import  React, { useState } from 'react';
import PrimaryButton from '../../Button1/primarybutton';
import { useDispatch, useSelector } from "react-redux";
import { Actions } from '../../../store';
import toast from 'react-hot-toast'; 
import { isEmpty, isValidUrl } from '../../../utils/helpers';
import { CreateSite } from '../../../actions/site';

export const AddSiteIntro = ({
    forWardAction=null
}) => {

    const  { introUserInfoGS } = useSelector(state => ({introUserInfoGS:state.introUserInfoGS}))
    const Dispatch = useDispatch();

    const [site_owned,setsite_owned] = useState('')

    // const ConfigFunParrten = /http:\/\/|https:\/\/|/g

    const getUserServiceAPI = ()=> {

        // if (process.env.REACT_APP_ENV === "development") return "http://localhost:8888/user/custom-info";
         if (process.env.REACT_APP_ENV === "development") return "https://service.dev.app.gozen.io/user/custom-info";
        else return "https://service.app.gozen.io/user/custom-info";

    }

    const onSubmet = async () => {

        try { 


            // if((site_owned == "") ||  !ConfigFunParrten.test(site_owned)) return toast.error("Invaild site")
        if(isEmpty(site_owned) || (!isValidUrl(site_owned))) { toast.error("Please enter the valid site!"); return}

            const { role, industry, phone_number, countryName } = introUserInfoGS

            const  data = JSON.stringify({

                website:site_owned,
                industry,
                phoneNumber: phone_number,
                country:countryName,
                title:role,
                plan:"free",
                productName: "engage"
            })
            const token = localStorage.getItem("access_token") ?? null;
            const URLLInk = getUserServiceAPI()
    
             fetch(URLLInk, {
                method: "POST",
                body:data,
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                }
            });
            Dispatch(Actions.setIntroUserInfoGS({...introUserInfoGS,site_owned}))

  
            const Resp =  CreateSite(site_owned)

          forWardAction(2)


        }catch(Err){
            // console.log(false)
        }


    }
    

    return (
        <section className="flex flex-col w-full h-full">
            <div className="flex flex-col mx-auto justify-center my-auto space-y-5" >

                <div className='fex flex-col space-y-4' >
                    <label className='text-xl font-semibold capitalize'>Enter your site</label>
                    <div className='flex border border-[#DCE0E4] rounded-lg' >
                        <span className='flex bg-gray-200 py-3 px-4 rounded-l-lg noselect' >https://</span>
                        <input type='text' className='flex py-3 px-4  rounded-r-lg min-w-[350px]' placeholder={'example.com'} onChange={(e)=>setsite_owned( e.target.value) } required={true} />
                    </div>
                </div>
                <PrimaryButton text={"continue"} OnClick={onSubmet} />

            </div>
        </section>
    )
}