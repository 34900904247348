import React from 'react'
import { BillingLayout } from './BillingLayout'

export const Invoice = ({upComingInvoice}) => {


  return (
    <BillingLayout className="min-w-[300px] w-fit h-[80%] py-2 border ">
    <div className="border-b px-5 py-3">

<p className="text-lg font-medium "> 
    Upcoming invoice 
</p>

<p className="text-xs font-normal text-font_secondary mt-1"> 
    {upComingInvoice?.next_payment_attempt ?? ''} 
</p>

</div>

<div className="py-3 px-5 border-b">

<p className="text-xs py-2"> Billed items </p>

{ upComingInvoice?.line_items && upComingInvoice?.line_items.map(item => { 
    return (
        <div className="my-2 px-4">

            <p className="text-sm font-normal"> 
                {item?.description ??""} 
            </p>

        </div>
    )
}) }

</div>

<div className="py-3 px-7 my-4">

<div className="flex justify-between">

    <p className="text-sm font-normal"> 
        Sub total 
    </p>

    <p className="text-sm font-medium"> 
        $ {((upComingInvoice?.subtotal ?? 0 )/100).toFixed(2)}
    </p>

</div>

<div className="flex justify-between my-3 ">
    
    <p className="text-sm font-normal"> 
        Discount 
    </p>

    <p className="text-sm font-medium"> 
        $ {((upComingInvoice?.discount ?? 0) /100).toFixed(2)}
    </p>

</div>

<div className="flex justify-between border-t pt-3 ">

    <p className="text-sm font-normal"> 
        Total 
    </p>

    <p className="text-sm font-medium"> 
        $ {((upComingInvoice?.total ?? 0 )/100).toFixed(2)}
    </p>
</div>

</div>

    </BillingLayout>
  )
}
