import React from "react";
import { ImSpinner8 } from "react-icons/im";

const PrimaryButton = ({
    backgroundColor = "bg-primary",
    text,
    textsize='text-14px',
    buttontype= "",
    classNames,
    id="",
    OnClick = null,
    height = "h-40px",
    margin = "",
    disabled = false,
    position = "",
    style = {},
    type = "button",
    icon = null,
    attributes = {},
    width = "",
    fontStyle = "font-medium",
    shadow = "shadow-sm",
    loading = false,
    rounded = "rounded-8px",
    ring = "focus:ring-2 focus:ring-offset-1 focus:ring-primary",
    textcolor = "text-white",
    border ="",
    padding_y ="py-2",

}) => {
    disabled = loading || disabled;
    
    switch(buttontype){
        case "roundedbutton":
            rounded = "rounded-full"
            break;
        case "secondarybutton":
            backgroundColor = "bg-white"
            textcolor = textcolor !== "text-white" ? textcolor : "text-primary"
            border= border !== "" ? border :"border-2 border-primary"
            break;
        case "defaultbutton":
            backgroundColor = "bg-white"
            // textcolor = "text-black"
            break;
    }

    return (
        <>
        <button
            type={type}
            id={id}
            disabled={disabled}
            style={{ ...style, }}
            onClick={(e) => OnClick && !disabled && OnClick(e)}
            {...attributes}
            className={`${icon ? "flex flex-row  items-center justify-center" : ""} outline-none  ${classNames} ${rounded} border border-border-light  ${textsize} ${fontStyle} ${textcolor}
                focus:outline-none   sm:text-sm
                ${backgroundColor} ${height}  ${width ? width + " " : " px-26px "} ${margin} ${border} ${padding_y}
                ${shadow}
                ${disabled ? " bg-opacity-60 " : " hover:opacity-90  "} 
                ${loading && "flex items-center justify-center"}
                `}
        >

            {loading &&
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                }

            {icon && icon}
            <span className={`${icon?"ml-3":""} ${textsize} ${textcolor} capitalize`} style={{ ...style, }}>{text}</span>
        </button>
        </>
    )
}

export default PrimaryButton;