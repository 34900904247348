import React from "react";
import { Link } from 'react-router-dom';
import { useState } from "react";
import { useSelectionAction } from "../../pages/customstate/context";
import { sideBarList } from "../../content/sidebar";
const  Sidenavbar = ({active,sidetab = false})=>{

    const [ isActive , setActive] = useState (active);

    const listofnav = sideBarList();

    const[sidebartrigger,setSidebarTrigger] = useState(sidetab)

    const {sidebarstate,setSidebarState} = useSelectionAction();

    const changestatus = () => {
        if(sidebartrigger === true){
            setSidebarTrigger(false);
        }
        else{
            setSidebarTrigger(true);
        }
    }  


    const chageactive = (index) => {
        setActive(index);
        setSidebarState(index)
    }

    return(
        <>
        <nav className="flex flex-col h-full border-r-1">

            <div className="flex flex-col h-full justify-between">
                <div className="flex flex-col space-y-1">
                {
                    listofnav.map( (list,index) => {
                        return(

                            <Link to={list.url} className={` ${list?.stepClass} pl-4 relative flex flex-row items-center h-16 py-4  focus:outline-none  border-primary border-r-4 border-opacity-0 ${(sidebarstate === index )? ' bg-hv-blue text-primary border-opacity-100 ' : 'text-black hover:bg-hv-blue hover:text-primary '} `} onClick={()=>chageactive(index)} >
                                {/* <Link className="relative flex flex-row items-center h-16 py-4  focus:outline-none  border-primary border-r-4 border-opacity-0"> */}
                                    <span className={` translate delay-900 duration-500 ${sidebartrigger ? "mr-6":""} inline-flex justify-center items-center ml-4`}>{(sidebarstate === index)?list.activeicon:list.icon}</span>
                                    <span className={` translate  delay-900 duration-500 whitespace-nowrap ${sidebartrigger ? "opacity-0 w-0":"ml-md w-[180px] text-sm tracking-wide truncate pl-2 pr-10 opacity-100"}`}>{list.label}</span>
                                

                            </Link>

                        )
                    } )
                }
                </div>
                <div className="flex flex-col space-y-1 mb-4">

                    {/* <div className={` pl-4 relative flex flex-row items-center h-10 py-2  focus:outline-none  border-primary border-r-4 border-opacity-0  text-black hover:bg-hv-blue hover:text-primary cursor-pointer  `}>
                    <span className={`${sidebartrigger ? "mr-6":""} inline-flex justify-center items-center ml-4`}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.6667 0.666626H2.33341C1.41675 0.666626 0.666748 1.41663 0.666748 2.33329V17.3333L4.00008 14H15.6667C16.5834 14 17.3334 13.25 17.3334 12.3333V2.33329C17.3334 1.41663 16.5834 0.666626 15.6667 0.666626ZM15.6667 12.3333H4.00008L2.33341 14V2.33329H15.6667V12.3333Z" fill="#586474"/>
                        </svg>
                        </span>
                        <span className={`${sidebartrigger ? "":"ml-md text-sm tracking-wide truncate pl-2 pr-10"}`}>{sidebartrigger?"":"Contact"}</span>
                    </div> */}
                    <div className={` pl-4 relative flex flex-row items-center h-10 py-2  focus:outline-none  border-primary border-r-4 border-opacity-0 text-black hover:bg-hv-blue hover:text-primary cursor-pointer `} onClick={()=>{window.open("https://docs.gozen.io/gozen-engage-knowledge-base/","_blank")}}>
                    <span className={`${sidebartrigger ? "mr-6":""} inline-flex justify-center items-center ml-4`}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.16675 14H9.83342V12.3333H8.16675V14ZM9.00008 0.666626C4.40008 0.666626 0.666748 4.39996 0.666748 8.99996C0.666748 13.6 4.40008 17.3333 9.00008 17.3333C13.6001 17.3333 17.3334 13.6 17.3334 8.99996C17.3334 4.39996 13.6001 0.666626 9.00008 0.666626ZM9.00008 15.6666C5.32508 15.6666 2.33341 12.675 2.33341 8.99996C2.33341 5.32496 5.32508 2.33329 9.00008 2.33329C12.6751 2.33329 15.6667 5.32496 15.6667 8.99996C15.6667 12.675 12.6751 15.6666 9.00008 15.6666ZM9.00008 3.99996C7.15841 3.99996 5.66675 5.49163 5.66675 7.33329H7.33342C7.33342 6.41663 8.08342 5.66663 9.00008 5.66663C9.91675 5.66663 10.6667 6.41663 10.6667 7.33329C10.6667 8.99996 8.16675 8.79163 8.16675 11.5H9.83342C9.83342 9.62496 12.3334 9.41663 12.3334 7.33329C12.3334 5.49163 10.8417 3.99996 9.00008 3.99996Z" fill="#586474"/>
                            </svg>
                        </span>
                        <span className={`${sidebartrigger ? "":"ml-md text-sm tracking-wide truncate pl-2 pr-10"}`}>{sidebartrigger?"":"Docs"}</span>
                    </div>
                </div>

            </div>
            <div className={`relative -top-16 ${sidebartrigger ? "left-[64px] rotate-180 delay-900 duration-500" : "left-[230px] duration-500"} rounded-full bg-primary w-7 h-7 px-2.5 py-2 cursor-pointer`} style={{zIndex:'2'}} onClick={changestatus}>
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.57539 11.35C5.33789 11.35 5.10039 11.2625 4.91289 11.075L0.500391 6.66245C0.137891 6.29995 0.137891 5.69995 0.500391 5.33745L4.91289 0.924951C5.27539 0.562451 5.87539 0.562451 6.23789 0.924951C6.60039 1.28745 6.60039 1.88745 6.23789 2.24995L2.48789 5.99995L6.23789 9.74995C6.60039 10.1125 6.60039 10.7125 6.23789 11.075C6.06289 11.2625 5.82539 11.35 5.57539 11.35Z" fill="white"/>
            </svg>
        </div>
        </nav>

        </>
    )
}

export default Sidenavbar;