
import _get from 'lodash/get';
import _map from 'lodash/map';
import DeepBot from './deepbot/index.js';
import React from 'react';
import { useSelector } from 'react-redux';
const parseAnalyticsData = () => {
    return _get(this, "props.user.intercom_data", {});
}

export const DeepBotReact = () => {

    // const {user} = useSelector((state: RootState) => ({ user : state.default.userDetail , billings: state.default.userDetail.email})) 
    const {user,userPlan} = useSelector((state) => ({userPlan:state.userPlan,user:state.userDetail})) 

    const userData = { 
        user_id: user.user_id,
        email: user.email,
        product:"GozenEngage",
        name: user?.firstName ? `${user?.firstName} ${user?.lastName}`:user.email,
        plan: userPlan.plan,
        type: userPlan.type,
        gateway: userPlan.gateway,
    }
    // console.log("user",user)
    if (!user.email) return null

    return <DeepBot chatbotId="66f107058a60e75f11f93f9a" {...userData} />

}