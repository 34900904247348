import React, { useEffect, useRef, useState } from "react";
import { Clickouter } from "../../utils/clickouter";

const IntergationDropDonw = ({
    id = "",
    mainclass = '',
    active = 0,
    fieldType = '',
    IndexValue=0,
    edge="",
    list = ['left', 'right'],
    name = "",
    label = 'Label name',
    labelTextsize = '',
    labelTextWeight = '',
    type = "text",
    Placeholder = "",
    fontweight = "",
    margin = "mb-0",
    spaceY = 'space-y-2',
    padding = "",
    textvalue = null,
    disabledinput = false,
    width = "w-full",
    onchange = null,
    height = 'h-10',
    setSelectList,
    selectList,
    ...prop
}) => {
    const [selectoption, setSelectoption] = useState(list[active])
    const [DropMenuState, setDropMenuState] = useState(false)

    const triggerClick = (state) => {
        setSelectoption(state)
        
        setDropMenuState(false)
        if(edge == "")onchange(state,fieldType)
        else onchange(state,fieldType,IndexValue,edge)

    }
    const menuref = useRef()
    useEffect(()=>{
        let handleAction = (event) => {
  
            if (menuref.current && !menuref.current?.contains(event.target)) {
                // alert("test-out")
                setDropMenuState(false);
            }
        };
    
        document.addEventListener("mousedown", handleAction);
    },[])

    return (
        <div className={`${spaceY} ${mainclass}`}>

            <label className={` ${labelTextsize} ${labelTextWeight} capitalize`}>{label}</label>
            <div className="relative">
                <input type={type} className={`capitalize gz-basic-input ${height} block ${padding} text-14px text-black appearance-none bg-white ${DropMenuState ? 'rounded-b-none' : ''}  focus:border-primary  placeholder-gray-600 border rounded-8px ${width} px-14px leading-5 placeholder-opacity-40 ${margin} cursor-pointer border-secondar`} id={id} name={name} onClick={() => setDropMenuState(true)} placeholder={Placeholder} disabled={disabledinput} value={selectoption} />
                <span className="absolute top-[10px] right-3 inline-block">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2097_16399)">
                        <path d="M7.98417 10.7345L10.3583 13.1087C10.7158 13.4662 11.2933 13.4662 11.6508 13.1087L14.025 10.7345C14.6025 10.157 14.19 9.16699 13.3742 9.16699H8.62584C7.81001 9.16699 7.40667 10.157 7.98417 10.7345Z" fill="#586474" />
                    </g>
                    <defs>
                        <clipPath id="clip0_2097_16399">
                            <rect width="22" height="22" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                </span>
                {DropMenuState && <div className="absolute top-10 w-full border-1 top-0 bg-white rounded-b-md border-t-0  max-h-[200px] overflow-y-auto" style={{zIndex:'10'}} ref={menuref} >
                    {/* {ref={menuref}} */}
                    {
                        list.map((listobj, index) => {
                            return (
                                <p key={index} className="w-full px-4 py-2 capitalize text-base hover:bg-gray-100 cursor-pointer" onClick={() => {

                                   
                                    setSelectoption(list[index])
                                    setSelectList(index)
                                    setDropMenuState(false)

                                }
                                }>{listobj}</p>
                            )
                        })
                    }
                </div>}
            </div>
        </div>
    )
}

export default IntergationDropDonw;


