import React from "react";
import { ConnectionContainer } from "../../../connectionsCard";
import PrimaryButton from "../../../Button1/primarybutton";
import { useDispatch } from "react-redux";
import { Actions } from "../../../../store";

export const ConnectionSetting = ({ field, fieldId }) => {

    const Ques = field?.connectionList
    const Dispath = useDispatch()


    const AddQues = () => {
        const setContent = {
            key:fieldId,
            node:'game_mainpage_json',
            child:'connectionList',
            value:{
                "question": '',
                "questionId": Math.random().toString(16).slice(8) + Date.now().toString(36),
                "answer": 'Seahorse',
                "imageUrl": [
                  'https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/food_pan.jpg',
                  'https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/food_cake.jpg'
                ]
              }

        }
        Dispath(Actions.setQuizQuestionAdd(setContent))
    }

    const addQuestionId = ( childInd ) => {

        let payload = {
  
          key:fieldId,
          node:'game_mainpage_json',
          child:'connectionList',
          childInd
  
        }
  
        Dispath(Actions.questionIdAdd( payload ))
  
      }

    return (
        <div className="w-full space-y-4 ">

            {
                Ques.map((list,index) => {

                    if(!list?.questionId) addQuestionId( index )

                    return(

                        <ConnectionContainer question={list?.question} answer={list?.answer} Index={index} fieldId={fieldId} imgSrc={list?.imageUrl} answerImg={list?.answerImg} invalidAnswerImg={list?.invalidAnswerImg} invalidanswerContent={list?.invalidanswerContent} />
                        
                    )
                })
            }

        <PrimaryButton text={'Add Game Level'} width="w-full" height="h-12" backgroundColor="bg-primary-lightblue" border="border-1 border-primary" textcolor="text-primary" OnClick={AddQues}/>

        </div>
    )
}