import React, { useRef, useState } from "react";
import TextareaInput from "../../input/textarea";
import PrimaryButton from "../../Button1/primarybutton";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import Input from "../../input/input";
import CheckBox from "../../input/checkbox";
import { getScriptCode } from "../../../content/scriptCode";

export const EmbedPopup = ({
    title = 'Get your Embed code',
    backwardAction = null,

    type='inlineEmbed', 
    CampaignId ="5c8eb971-0786-4627-895b-06001d7eb32e",
    siteId="5c8eb971-0786-4627-895b-06001d7eb32e",
    btnText="Launch",
    btnTextColor="#FFFFFF",
    btnBgColor="#0063EB", 
    popupSize="medium",
    Side="right",
    inlineEmbedSize=["100%","450px"],
    sidePanelSize=["500px","600px"]
}) => { 
  const { primary_Site_id } = useSelector(state => ({ primary_Site_id: state?.userDetail?.primary_Site_id }))

const [scriptStatus, setscriptStatus] = useState(false)
const popUpDiv = useRef()

    var defaultVaule = ``
    var codeInfo = ` class="engage-block" data-gz-eng-type="${type}" data-gz-eng-cp-id="${CampaignId}" data-gz-eng-d-id="${siteId}" data-gz-eng-ps-id="${primary_Site_id}" `

if(type==="popover"){
    defaultVaule+=`<button `+codeInfo
    defaultVaule+=`style="cursor:pointer; border:none; padding: 8px 20px 8px 20px; border-radius:5px; color:`+btnTextColor+`; background-color:`+btnBgColor+`;" data-gz-eng-side="${Side}">`
    defaultVaule+=btnText+`</button>`
}else{
    defaultVaule=`<div `+codeInfo  
if(type==="popup") defaultVaule+=`data-gz-eng-popup-size="${popupSize}" ` 
if(type==="inlineEmbed") defaultVaule+=`style="width:${inlineEmbedSize[0] || "100%"}; height:${inlineEmbedSize[1] || "450px"};" ` 
if(type==="sidePanel") defaultVaule+=`style="width:${sidePanelSize[0] || "500px"}; height:${sidePanelSize[1] || "600px"};" ` 
if( type==="sideTab") defaultVaule+=`data-gz-eng-btnText="${btnText}" data-gz-eng-btnTxtColor="${btnTextColor}" data-gz-eng-btnBgColor="${btnBgColor}" data-gz-eng-side="${Side}" ` 
defaultVaule+=`></div>\n`
}
 
let scriptcode =`<script id="engage_script" async="true" src="${getScriptCode()}" type="text/javascript"></script>`

    // defaultVaule+=scriptcode

    const copy = (type) => {
        navigator.clipboard.writeText(type==="script"? scriptcode : defaultVaule);
        toast.success("Copied!");
        backwardAction(false)
    }


    function closeDiv(event){
        var isClickInside = popUpDiv?.current?.contains(event?.target);
        if (!isClickInside) backwardAction(false) 
    }


    return (
        <div className="absolute top-0 left-0 flex w-full h-full  bg-backgroundShadow duration-500" style={{ zIndex: '55' }} onClick={closeDiv}>
            <div className="p-5 w-[680px] rounded-lg bg-white m-auto space-y-4 duration-500" ref={popUpDiv}>
            
            <div className="w-full flex flex-row justify-between items-center duration-500">
                <p className="capitalize text-lg font-medium">{title}</p>
                
                <svg className="cursor-pointer" onClick={() => backwardAction(false)} width="40" height="34" viewBox="0 0 40 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="39" height="33" rx="7.5" fill="white"/>
                    <rect x="0.5" y="0.5" width="39" height="33" rx="7.5" stroke="#EDEFF1"/>
                    <g clip-path="url(#clip0_4585_31153)">
                    <path d="M23.6749 13.3308C23.4474 13.1033 23.0799 13.1033 22.8524 13.3308L19.9999 16.1774L17.1474 13.3249C16.9199 13.0974 16.5524 13.0974 16.3249 13.3249C16.0974 13.5524 16.0974 13.9199 16.3249 14.1474L19.1774 16.9999L16.3249 19.8524C16.0974 20.0799 16.0974 20.4474 16.3249 20.6749C16.5524 20.9024 16.9199 20.9024 17.1474 20.6749L19.9999 17.8224L22.8524 20.6749C23.0799 20.9024 23.4474 20.9024 23.6749 20.6749C23.9024 20.4474 23.9024 20.0799 23.6749 19.8524L20.8224 16.9999L23.6749 14.1474C23.8966 13.9258 23.8966 13.5524 23.6749 13.3308Z" fill="#586474"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_4585_31153">
                    <rect width="14" height="14" fill="white" transform="translate(13 10)"/>
                    </clipPath>
                    </defs>
                    </svg>
                </div>

            
                <div className="flex flex-col space-y-0">
                    <div className="flex justify-between ">
                        <p className="text-[14px] ml-1" >Paste this code on the body tag (Paste as much as you need)</p>
                    </div>
                    <div className="relative w-full h-full">
                    <TextareaInput spellcheck={"false"} textvalue={defaultVaule} labelTextsize="hidden" CustomClass="h-[170px] text-[#06152D] text-[14px]" bgColor="bg-[#F9FAFB]" />


                    <button onClick={()=>{copy("embedCode")}} className=" absolute left-[12px] bottom-[12px] text-12px bg-white rounded-md my-auto text-primary border-1 border-primary font-semibold py-2 px-3" >Copy Embed Code</button>
                    </div>

                </div>

                <CheckBox label="Show Install Code" labelTextsize={"text-[14px]"} isChecked={scriptStatus }  onchange={(e) => {setscriptStatus(o => !o)}}  />

             {scriptStatus &&  
                 <div className="flex flex-col space-y-2 duration-500">
                    <p className="text-[14px] ml-1" >Paste this code on the header tag (Only one per page)</p>
                    <div className="flex justify-between space-x-2">

                    <div className="relative w-full h-full">
                    <TextareaInput spellcheck={"false"} textvalue={scriptcode} labelTextsize="hidden" CustomClass="h-[115px] text-[#06152D] text-[14px]" bgColor="bg-[#F9FAFB]" />


                    <button  onClick={()=>{copy("script")}} className=" absolute left-[12px] bottom-[12px] text-12px bg-white rounded-md my-auto text-primary border-1 border-primary font-semibold py-2 px-3" >Copy Embed Code</button>
                    </div>
                    </div>

                </div>}

                {/* <div className="flex justify-end space-x-2">
                    <PrimaryButton buttontype={'secondarybutton'} width={'w-24'} text={'Cancel'} OnClick={() => backwardAction(false)} />
                    <PrimaryButton text={'Copy'} classNames={"cursor-pointer"} width={'w-24'} OnClick={copy} />
                </div> */}

            </div>
        </div>
    )
} 