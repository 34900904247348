import React, { useEffect, useRef, useState } from "react";
import PrimaryButton from "../Button1/primarybutton";
import Input from "../input/input";
import { Clickouter } from "../../utils/clickouter";
import InputWithLabel from "../input/inputWithLabel";
import TextareaInput from "../input/textarea";
import DefaultDropMenu from "../input/droplist";
import { CreateGenerateForm, CreateGenerateFormTEmp } from "../../actions/generate";
import { toast } from "react-hot-toast";
import { LoadingSpinner } from "../Loading";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


export const AiPopupGC = ({
    category = "",
    Cancel = null,
    className = "",
    CreateTEmp = false,
    template_id="",
    SetData=null
}) => {
    const categorylist = ['quizzes','ecommerce','calculater','poll','surveys']
    const Industrylist = ['food_industry', "ecommerce", "software", "construction", "beauty&cosmetics", "photography", "logistics&transportation", "medical", "human_resources", "banking&finance", "gaming", "e-learning"]
    const [ActiveIndustry, setActiveIndustry] = useState(0)
    const [ActiveCategory, setActiveCategory] = useState(0)
    const [ActiveProject, setActiveProject] = useState(0)
    const [name, setName] = useState("")
    const [description, setdescription] = useState("")
    const [Forcalculator, setForcalculator] = useState("")
    const [noOfQuestions, setnoOfQuestions] = useState(5)
    const [loading, setloading] = useState(false)

    const Navigate = useNavigate()
    const { projects } = useSelector(state => ({ projects:state?.projects })) 

    const projectName = projects?.map( list => list?.project_name )

    const AiPopupGCREF = useRef(null);
    
    useEffect(()=>{
        let handleAction = (event) => {

            if (AiPopupGCREF.current && !AiPopupGCREF.current?.contains(event.target)) {
                // alert("test-out")
                Cancel(false);
            }
        };
    
        document.addEventListener("mousedown", handleAction);
    },[])

    const GenarateCampaign = async () => {

        try {
            let payload = {
                "name": name,
                "category": category == "" ? categorylist[ActiveCategory] : category,
                "industry": Industrylist[ActiveIndustry],
                "project_id": projects[ActiveProject]?.project_id,
                "noOfQuestions": parseInt(noOfQuestions),
                "description": description,
                "Forcalculator": Forcalculator
            }
            setloading(true)
    
            const Response = CreateTEmp ? await CreateGenerateFormTEmp(payload,template_id) : await CreateGenerateForm(payload)
    
            if (Response.success) {
    
                toast.success('Campaign Created')
    
                CreateTEmp ? SetData(Response) :Navigate( `/campaign/edit/${Response.data.campaignId}`);
    
            }
            else toast.error(Response.message)
            setloading(false)
        }catch(err){
            setloading(false)
        }


    }

    return (
        <div className="absolute top-0 left-0 flex w-full h-full justify-center bg-backgroundShadow overflow-y-auto "  style={{ zIndex: '120' }}>
            
            <div className={`relative p-8 w-2/5 rounded-xl min-w-[700px] bg-white m-auto space-y-4 ${className} `} ref={AiPopupGCREF}  >
                <div className="w-full flex justify-between" >
                    <h4 className="text-lg font-medium capitalize">Create <span>{category}</span> Using AI</h4>
                    <button className="border-1 p-3 my-auto rounded-lg" onClick={() => Cancel(false)}>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_3687_3175)">
                                <path d="M10.6749 3.33076C10.4474 3.10326 10.0799 3.10326 9.85242 3.33076L6.99992 6.17742L4.14742 3.32492C3.91992 3.09742 3.55242 3.09742 3.32492 3.32492C3.09742 3.55242 3.09742 3.91992 3.32492 4.14742L6.17742 6.99992L3.32492 9.85242C3.09742 10.0799 3.09742 10.4474 3.32492 10.6749C3.55242 10.9024 3.91992 10.9024 4.14742 10.6749L6.99992 7.82242L9.85242 10.6749C10.0799 10.9024 10.4474 10.9024 10.6749 10.6749C10.9024 10.4474 10.9024 10.0799 10.6749 9.85242L7.82242 6.99992L10.6749 4.14742C10.8966 3.92576 10.8966 3.55242 10.6749 3.33076Z" fill="#586474" />
                            </g>
                            <defs>
                                <clipPath id="clip0_3687_3175">
                                    <rect width="14" height="14" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>

                    </button>
                </div>

                <div className="flex flex-col w-full space-y-5">
                    <InputWithLabel label="Campaign name" height="h-14" labelTextWeight="font-medium" Placeholder="Campaign name" width="w-full" onchange={(e) => setName(e.target.value)} />
                    <DefaultDropMenu top={'top-[18px]'} label="Choose Workspace" height="h-14" active={ActiveProject}  SelectIndex={true} onchange={setActiveProject}  list = { projectName } />
                    {((category === "calculater")||(categorylist[ActiveCategory] === "calculater")) && <InputWithLabel height="h-14" label="What type calculator you want?" labelTextWeight="font-medium" Placeholder="Ex: Discount Calculator" width="w-2/5" onchange={(e) => setForcalculator(e.target.value)} />}
                    <TextareaInput label="Description" labelTextWeight="font-medium" Placeholder="describe your requirements" onchange={(e) => setdescription(e.target.value)} />
                    <div className="w-full flex space-x-2">
                        <InputWithLabel height="h-14"  label="Number of Question" type="number" labelTextWeight="font-medium" Placeholder="5" width="w-full" mainclass="w-1/2" onchange={(e) => setnoOfQuestions(e.target.value)} />
                        <DefaultDropMenu height="h-14" top={'top-[18px]'} label="Choose Your Industry" active={ActiveIndustry} list={Industrylist} SelectIndex={true} onchange={setActiveIndustry} mainclass="w-1/2" />
                        {category ==="" && <DefaultDropMenu height="h-14" top={'top-[18px]'} label="Choose Your Category" active={ActiveCategory} list={categorylist} SelectIndex={true} onchange={setActiveCategory} mainclass="w-1/2" />}
                    </div>
                </div>  
                <div className="flex justify-end space-x-2">
                    <PrimaryButton buttontype={'secondarybutton'} padding_y="py-3" height="h-auto" textcolor="text-black" border={'border-1'} width={'w-28'} text={'cancel'} OnClick={() => Cancel(false)} />
                    <PrimaryButton text={'Create'} width={'w-28'} padding_y="py-3" height="h-auto"  OnClick={GenarateCampaign} />
                </div>
            {loading && <LoadingSpinner  />}

            </div>
        </div>
    )
}