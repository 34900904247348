import PrimaryButton from "../Button1/primarybutton";
import { Description, Title } from '../Texts';
import { ImageCenter } from "../Image/ImageCenter";
import InputWithLabel from "../input/inputWithLabel";
import { LoadingSpinner } from "../Loading";
import { useEffect, useState } from "react";

import IntergationDropDonw from "../input/IntergationDopDonw";



const ShowData = ({ index, select, changeDefaultVaule, keys, setIntergationStage = null, setIntegrationType = null, list = [], create, setSelectList, selectList }) => {

    selectList = list.findIndex(listObjData => listObjData?.isAdded)

    const TriggerSelect = (index) => {
        setSelectList(index)
        setIntergationStage(2)
        setIntegrationType('')
    }

    if (index === 1) return <LoadingSpinner text="Validate" />

    if (index === 2 && create) return <LoadingSpinner text="Create" />

    return select["input_credentials"].map((i, k) => {
        // console.log(i['key'])

        if (i["key"] != "is_double_optin_enabled") {

            return <div>

                <InputWithLabel
                    key={k}
                    label={i["key"]}
                    Placeholder=" "
                    textvalue={keys?.apiKey ?? ""}
                    onchange={(e) => changeDefaultVaule(obj => ({ ...obj, 'apiKey': e.target.value }))} />

                {
                    list.length ? <IntergationDropDonw list={list.map(i => i["name"])} active={selectList > 0 ? selectList : 0} setSelectList={TriggerSelect} selectList={selectList} /> : <></>

                }
            </div>
        }
    })
}

export const IntergationPop = ({
    title = '',
    reftype = null,
    defaultVaule = '',
    type = '',
    setIntergationStage = null,
    setIntegrationType = null,
    changeDefaultVaule = null,
    forwardAction = null,
    backwardAction = null,
    select = {},
    keys = [],
    validate,
    create,
    intergationStage = 0,
    list = [],
    setSelectList = null,
    selectList = 0
}) => {




    return (
        <div className="absolute top-0 left-0 flex w-full h-full bg-backgroundShadow" style={{ zIndex: '55' }}>
            <div className=" w-full h-full flex bg-white m-auto space-y-4 overflow-hidden">


                <div className="w-[30%] h-full bg-[#F9FAFB] flex flex-col  pt-8 mx-atuo">
                    <div className="w-full space-y-4">

                        <Title className="capitalize font-bold text-center text-2xl" text={`${select["mailer"].split('_').join(" ")}  Integration setups`} />
                        <div className="w-[90%] mx-auto space-y-4">
                            <div className=" flex justify-between w-4/5 mx-auto ">

                                <ImageCenter src="/asset/images/log/logo.png" className="w-[40px] h-[40px]" />
                                <div className="my-auto ">
                                    <svg width="27" height="19" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.95339 9.29599L0.899219 13.3647C0.622135 13.6564 0.622135 14.1085 0.899219 14.4002L4.95339 18.4689C5.40547 18.9356 6.19297 18.6002 6.19297 17.9585V15.3335H14.9576C15.7596 15.3335 16.4159 14.6772 16.4159 13.8752C16.4159 13.0731 15.7596 12.4168 14.9576 12.4168H6.19297V9.80641C6.19297 9.15016 5.40547 8.82932 4.95339 9.29599ZM26.1138 4.61474L22.0596 0.545992C21.6076 0.0793255 20.8201 0.414742 20.8201 1.05641V3.66683H12.0409C11.2388 3.66683 10.5826 4.32307 10.5826 5.12516C10.5826 5.92724 11.2388 6.58349 12.0409 6.58349H20.8055V9.19391C20.8055 9.85016 21.593 10.171 22.0451 9.70432L26.0992 5.63558C26.3909 5.35849 26.3909 4.89182 26.1138 4.61474Z" fill="#8A929E" />
                                    </svg>

                                </div>
                                <ImageCenter src={select['preview_url']} />
   
                            </div>
                            <div className="flex flex-col space-y-4">
                                <Title text="Integration Steps" className="text-lg" />
                                <Description text={select['description'] ?? ""} className="font-normal text-sm text-[#586474]" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-[70%] h-full  ">
                    <div className="w-[90%]  h-[80%]   mx-auto flex flex-col space-y-4">
                        <div className="pt-6 flex flex-col space-y-2">
                            <Title text={`Click the authentication button to link your ${select["mailer"].split('_').join(" ")} account.`} className="font-bold text-base" />
                        </div>

                        <ShowData keys={keys} setIntergationStage={setIntergationStage} setIntegrationType={setIntegrationType} changeDefaultVaule={changeDefaultVaule} index={intergationStage} select={select} list={list} create={create} setSelectList={setSelectList} selectList={selectList} />


                        {/* {
                        list.length ? <CustomDropDown list={list} active={0} label="List"/> : <></>
                        } */}

                        <div className="flex flex-1 items-end justify-center ">
                            {
                                [1, 2, 3].map((i, k) =>
                                    <div className="flex w-[80px]  items-center">
                                        <div className={`h-[40px] w-[40px]   flex justify-center items-center rounded-full border-2 ${i < intergationStage + 1 ? "bg-primary text-[#fff]" : ""} `}>{i}</div>
                                        <div className={`flex-1 border-1 ${i === 3 ? "hidden" : "block"}`} />
                                    </div>
                                )
                            }
                        </div>
                        <div>

                        </div>
                    </div>
                    <div className="w-[90%]  mx-auto h-[20%] flex flex-col space-y-4 justify-end pb-6">
                        <div className="w-full border-1 mx-auto"></div>
                        <div className="w-full flex justify-end space-x-2">
                            <PrimaryButton text={"Cancel"} textcolor="#586474" backgroundColor="#fff" OnClick={() => backwardAction()} />
                            <PrimaryButton text={"Next"} OnClick={() => { forwardAction(validate && !create ? 2 : 1) }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

