import React, { useState } from "react";
import GzLogo from "../logo/GzLogo";
import PrimaryButton from "../Button1/primarybutton";
import { useSelectionAction } from "../../pages/customstate/context";
import { ProfileIcon } from "./profile";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {  liveOrPauseCampaign } from "../../actions/templates";
import { Actions } from "../../store";
import { toast } from "react-hot-toast";
import { AiChooseGC } from "../popup/GenerateAiCreateCamp";
import { AiPopupGC } from "../popup/GenerateAiContentPopup";
import { AppDrawer } from "./appDrawer";
import { AppNotification } from "./notification";
import { CgSpinner } from "react-icons/cg";

const DefaultHeader = ({ isSmall = false, Createcamp = true, editer = false, Ecommerce = false }) => {

    const Navigate = useNavigate();
    const Dispatch = useDispatch()
    const [showAppsPopup, setShowAppsPopup] = useState(false)
    const [loadingLive, setliveLoading] = useState(false)
    const { userDetail, campaign, fileds, order, SharedWS } = useSelector((state) => ({ SharedWS: state.SharedWS, userDetail: state.userDetail, campaign: state.Campaign_info, order: state.fieldsOrder, fileds: state.fields }));

    const { setEditerState, sidebartrigger, setSidebarTrigger, editerstate } = useSelectionAction();
    const [isActice, setActive] = useState(editerstate);

    const changestate = (index) => {
        setEditerState(index);
        setActive(index);
    }

    const Preview = () => {

        const CurrentHref = String(window.location.href).indexOf(campaign.Campaign_id)

        if (CurrentHref > -1) Navigate(`preview`)
    }

    const LiveOrPause = async () => {
        try {


            setliveLoading(true)
            const paytload = {
                "name": campaign.name,
                "status": campaign.status,
                "project_id": campaign.project_id,
                "campaign_update": campaign.campaign_update,
                "order": JSON.stringify(order),
                "fileds": JSON.stringify(fileds)
            }
            const respData = await liveOrPauseCampaign(paytload, campaign.Campaign_id, campaign.status === 'active' ? 'disable' : 'enable', SharedWS)
            if (respData?.success) toast.success(campaign.status !== 'active' ? 'Game Published' : 'Game Unpublished')
            else toast.error(respData?.message)

            const payload = {
                type: 'status',
                value: campaign.status === 'active' ? 'paused' : 'active'
            }
            const cppayload = {
                type: 'CPstatus',
                value: true
            }
            Dispatch(Actions.setCampaignUpdate(payload))
            if (campaign.status !== 'active') Dispatch(Actions.setCampaignUpdate(cppayload))
            setliveLoading(false)
        } catch (err) {
            setliveLoading(false)
        }
    }

    const Createcampaigntrigger = async () => {
        setChoose(true)
        // const payload = {
        //     "campaign": {
        //         "title": "My Project",s
        //         "type": "inline_popup",
        //         "category": '',
        //         "intent": "",
        //         "industry": '',
        //         "templateId": ''
        //     }
        // }

        // const data = await createcCampaign(payload);

        // if (data?.success) window.location.href = `/campaign/edit/${data.data.campaignId}`;
        // else {
        //     alert(data?.message);
        //     // setSoftload(false)
        // }
    }
    const forWardAction = (index) => {
        if (index == 1) {
            setChoose(false)
            setShowpop(true)
        }
        else {
            Dispatch(Actions.setChooseTemplate(true))
            Navigate('/template')
            setChoose(false)

        }

    }

    const editprop = ["product", "design", "integration", "result", "share"];
    const editStepClassprop = ["step-tab-product", "step-tab-design", "step-tab-integration", "step-tab-result", "step-tab-share"];

    const [ShowChoose, setChoose] = useState(false)
    const [ShowPop, setShowpop] = useState(false)

    return (
        <>

            {ShowChoose && <AiChooseGC Cancel={setChoose} forWardAction={forWardAction} />}
            {ShowPop && <AiPopupGC Cancel={setShowpop} />}
            <section className="w-full h-[77px] bg-white shadow-md relative z-100" style={{ zIndex: '2' }}>

                <div className="flex justify-between h-full ml-4 mr-7">

                    {/* Logo */}
                    {/* {sidebartrigger ? <GzIcon classNamecontainer="w-52 h-full my-auto px-2" classNameimg=" h-full py-5" /> : <GzLogo classNamecontainer="w-52 my-auto pr-8 " classNameimg="ml-3" />} */}
                    <GzLogo classNamecontainer="w-52 my-auto pr-8 " classNameimg="ml-3" />
                    {/* Editer Optintion */}
                    <div className="flex space-x-6 ">
                        {editer && editprop.map((prop, index) => {
                            if (Ecommerce) return (
                                <p key={index} className={` ${editStepClassprop[index]} flex ${(isActice === prop) ? "border-b-2 border-primary text-primary" : "text-primary-gray border-b-2 border-white"} cursor-pointer my-1`} onClick={() => changestate(prop)}><span className={`my-auto capitalize font-medium`}>{prop}</span></p>
                            )
                            else {
                                if (prop !== "product") return (
                                <p key={index} className={` ${editStepClassprop[index]} flex ${(isActice === prop) ? "border-b-2 border-primary text-primary" : "text-primary-gray border-b-2 border-white"} cursor-pointer my-1`} onClick={() => changestate(prop)}><span className={`my-auto capitalize font-medium`}>{prop}</span></p>
                                )
                            }
                        })}

                    </div>

                    <div className="flex space-x-3">

                        {/* Campaign Create / live & preview Button  */}

                        {!editer ?

                            <>{Createcamp && <PrimaryButton text={"Create Campaign"} margin="my-auto step-leftPanel-createCampaign " OnClick={Createcampaigntrigger} icon={
                                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.0003 6.83335H6.83366V11C6.83366 11.4584 6.45866 11.8334 6.00033 11.8334C5.54199 11.8334 5.16699 11.4584 5.16699 11V6.83335H1.00033C0.541992 6.83335 0.166992 6.45835 0.166992 6.00002C0.166992 5.54169 0.541992 5.16669 1.00033 5.16669H5.16699V1.00002C5.16699 0.541687 5.54199 0.166687 6.00033 0.166687C6.45866 0.166687 6.83366 0.541687 6.83366 1.00002V5.16669H11.0003C11.4587 5.16669 11.8337 5.54169 11.8337 6.00002C11.8337 6.45835 11.4587 6.83335 11.0003 6.83335Z" fill="white" />
                                </svg>
                            } />}</>
                            :
                            <div className="flex space-x-4">
                                <PrimaryButton buttontype="secondarybutton" text={"Preview"} margin="my-auto step-tab-previewBtn" OnClick={Preview} />
                                <PrimaryButton icon={
                                    loadingLive ? <CgSpinner className="w-5 h-5 animate-spin text-white" /> : null
                                } text={campaign.status == 'active' ? "Unpublish" : "Publish"} margin="my-auto step-tab-publishBtn" disabled={loadingLive} OnClick={LiveOrPause} />
                            </div>
                        }
                        <AppNotification />
                        <AppDrawer />
                        <ProfileIcon firstname={userDetail?.first_name} email={userDetail?.email} lastname={userDetail?.last_name} userProfilePic={userDetail?.profile} />

                    </div>
                </div>
            </section>


        </>
    )
}

export default DefaultHeader;