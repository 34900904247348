import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from "react-chartjs-2";
import DefaultDropMenu from '../../../../input/droplist';

export const EVentCountLineChart = ({ data = {}, Setmonth = null, SetYear = null, SetType = null }) => {

    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

    const currentDate = new Date().getMonth()
    const type = ["monlty",'yearly']
    const monthLenght = data?.maxLoop ?? 30;
    let monthlyLabel = [];
    const yearlyLabel = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    for (let i = 0; i < monthLenght; i++) {
        monthlyLabel.push(i + 1)
    }

    const DataSet = {
        labels: monthlyLabel,
        datasets: [
            {
            label:"views",
            fill: true,
            data: data?.view_count ?? [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0
            ],
            borderColor: "rgb(37, 99, 235)",
            backgroundColor: 'rgba(37, 99, 235, 0.1)',

        },{
            label:"clicks",
        fill: true,
        data:  data?.click_count ?? [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        borderColor: '#8AC926',
        backgroundColor: 'rgba(138, 201, 38, 0.1)',

    },{
        label:"Response",
        fill: true,
        data: data?.subscribe_count ?? [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0
        ],
        borderColor: '#FDC500',
        backgroundColor: 'rgba(253, 198, 0, 0.1)',

    },
        ]
    }


    return (
        <div className="relative border rounded-lg shadow-lg  p-6 pb-12  w-full h-[500px] bg-white">

            <div className="text-lg py-1 pb-3 flex justify-between font-medium bg-white">
                <p>Responses</p>
                <div className='flex space-x-4'>
                    <DefaultDropMenu mainclass={'w-28'} spaceY='' labelTextWeight='hidden' active={0} list={type} SelectIndex={true} onchange={(ind)=>SetType(type[ind])} />
                    <DefaultDropMenu mainclass={'w-28'} spaceY='' labelTextWeight='hidden' active={currentDate} list={yearlyLabel} SelectIndex={true} onchange={(ind)=>Setmonth(ind+1)}  />
                </div>
            </div>

            <div className="w-full h-[93%] mt-2 my-1">


                <Line
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        spanGaps: true ,
                        plugins: {
                            legend: {
                                display: false
                            },
                        },
                        scales: {
                            x: {
                                grid: {
                                    display: false
                                },
                                title: {
                                    color: "black",
                                    display: true,
                                    text: "Dates",
                                    padding: {
                                        top: 10
                                    },
                                    font: {
                                        size: 14,
                                    }
                                }
                            },
                            y: {
                                grid: {
                                    display: false
                                },
                                title: {
                                    color: "black",
                                    display: true,
                                    text: "Events", padding: {
                                        bottom: 10,
                                    },
                                    font: {
                                        size: 14,
                                    }
                                }
                            },
                        },
                        elements: {
                            line: {
                                pointRadius:100,
                                tension: 0.24
                            },
                        },
                    }}
                    data={DataSet}
                />

            </div>

        </div>
    )
}