export const TooltipData = () => {
    return  [
        {
            type: "up",
            icon: (<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5523 7.74008C13.4098 7.74008 13.2673 7.68758 13.1548 7.57508L8.9998 3.42008L4.84484 7.57508C4.62734 7.79258 4.26734 7.79258 4.04984 7.57508C3.83234 7.35758 3.83234 6.99758 4.04984 6.78008L8.6023 2.22758C8.8198 2.01008 9.1798 2.01008 9.39729 2.22758L13.9498 6.78008C14.1673 6.99758 14.1673 7.35758 13.9498 7.57508C13.8448 7.68758 13.6948 7.74008 13.5523 7.74008Z" fill="#586474" />
                <path d="M9 15.9379C8.6925 15.9379 8.4375 15.6829 8.4375 15.3754V2.75293C8.4375 2.44543 8.6925 2.19043 9 2.19043C9.30749 2.19043 9.56249 2.44543 9.56249 2.75293V15.3754C9.56249 15.6829 9.30749 15.9379 9 15.9379Z" fill="#586474" />
            </svg>
            )
        },
        {
            type: "down",
            icon: (<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.9998 15.9373C8.8573 15.9373 8.7148 15.8848 8.6023 15.7723L4.04984 11.2198C3.83234 11.0023 3.83234 10.6423 4.04984 10.4248C4.26734 10.2073 4.62734 10.2073 4.84484 10.4248L8.9998 14.5798L13.1548 10.4248C13.3723 10.2073 13.7323 10.2073 13.9498 10.4248C14.1673 10.6423 14.1673 11.0023 13.9498 11.2198L9.39729 15.7723C9.2848 15.8848 9.1423 15.9373 8.9998 15.9373Z" fill="#586474" />
                <path d="M9 15.81C8.6925 15.81 8.4375 15.555 8.4375 15.2475V2.625C8.4375 2.3175 8.6925 2.0625 9 2.0625C9.30749 2.0625 9.56249 2.3175 9.56249 2.625V15.2475C9.56249 15.555 9.30749 15.81 9 15.81Z" fill="#586474" />
            </svg>
            )
        },
        {
            type: "duplicate",
            icon: (<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.32494 17.0625H5.17496C2.24249 17.0625 0.9375 15.7575 0.9375 12.825V9.675C0.9375 6.7425 2.24249 5.4375 5.17496 5.4375H8.32494C11.2574 5.4375 12.5624 6.7425 12.5624 9.675V12.825C12.5624 15.7575 11.2574 17.0625 8.32494 17.0625ZM5.17496 6.5625C2.84998 6.5625 2.06249 7.35 2.06249 9.675V12.825C2.06249 15.15 2.84998 15.9375 5.17496 15.9375H8.32494C10.6499 15.9375 11.4374 15.15 11.4374 12.825V9.675C11.4374 7.35 10.6499 6.5625 8.32494 6.5625H5.17496Z" fill="#586474" />
                <path d="M12.8249 12.5625H11.9999C11.6924 12.5625 11.4374 12.3075 11.4374 12V9.675C11.4374 7.35 10.65 6.5625 8.32497 6.5625H6C5.6925 6.5625 5.4375 6.3075 5.4375 6V5.175C5.4375 2.2425 6.74249 0.9375 9.67496 0.9375H12.8249C15.7574 0.9375 17.0624 2.2425 17.0624 5.175V8.325C17.0624 11.2575 15.7574 12.5625 12.8249 12.5625ZM12.5624 11.4375H12.8249C15.1499 11.4375 15.9374 10.65 15.9374 8.325V5.175C15.9374 2.85 15.1499 2.0625 12.8249 2.0625H9.67496C7.34998 2.0625 6.56249 2.85 6.56249 5.175V5.4375H8.32497C11.2574 5.4375 12.5624 6.7425 12.5624 9.675V11.4375Z" fill="#586474" />
            </svg>
            )
        },
        {
            type: "delete",
            icon: (<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.7506 5.04785C15.7356 5.04785 15.7131 5.04785 15.6906 5.04785C11.7231 4.65035 7.76318 4.50035 3.84071 4.89785L2.31072 5.04785C1.99573 5.07785 1.71823 4.85285 1.68823 4.53785C1.65823 4.22285 1.88323 3.95285 2.19073 3.92285L3.72071 3.77285C7.71068 3.36785 11.7531 3.52535 15.8031 3.92285C16.1106 3.95285 16.3356 4.23035 16.3056 4.53785C16.2831 4.83035 16.0356 5.04785 15.7506 5.04785Z" fill="#586474" />
                <path d="M6.37555 4.29C6.34555 4.29 6.31555 4.29 6.27805 4.2825C5.97805 4.23 5.76806 3.9375 5.82056 3.6375L5.98555 2.655C6.10555 1.935 6.27055 0.9375 8.01804 0.9375H9.98302C11.738 0.9375 11.903 1.9725 12.0155 2.6625L12.1805 3.6375C12.233 3.945 12.023 4.2375 11.723 4.2825C11.4155 4.335 11.123 4.125 11.078 3.825L10.913 2.85C10.808 2.1975 10.7855 2.07 9.99052 2.07H8.02554C7.23054 2.07 7.21554 2.175 7.10305 2.8425L6.93055 3.8175C6.88555 4.095 6.64555 4.29 6.37555 4.29Z" fill="#586474" />
                <path d="M11.4077 17.0627H6.59276C3.97528 17.0627 3.87029 15.6152 3.78779 14.4452L3.30029 6.89268C3.27779 6.58518 3.51779 6.31518 3.82529 6.29268C4.14028 6.27768 4.40278 6.51018 4.42528 6.81768L4.91278 14.3702C4.99528 15.5102 5.02527 15.9377 6.59276 15.9377H11.4077C12.9827 15.9377 13.0127 15.5102 13.0877 14.3702L13.5752 6.81768C13.5977 6.51018 13.8677 6.27768 14.1752 6.29268C14.4827 6.31518 14.7227 6.57768 14.7002 6.89268L14.2127 14.4452C14.1302 15.6152 14.0252 17.0627 11.4077 17.0627Z" fill="#586474" />
                <path d="M10.2455 12.9375H7.74804C7.44054 12.9375 7.18555 12.6825 7.18555 12.375C7.18555 12.0675 7.44054 11.8125 7.74804 11.8125H10.2455C10.553 11.8125 10.808 12.0675 10.808 12.375C10.808 12.6825 10.553 12.9375 10.2455 12.9375Z" fill="#586474" />
                <path d="M10.875 9.9375H7.125C6.8175 9.9375 6.5625 9.6825 6.5625 9.375C6.5625 9.0675 6.8175 8.8125 7.125 8.8125H10.875C11.1825 8.8125 11.4375 9.0675 11.4375 9.375C11.4375 9.6825 11.1825 9.9375 10.875 9.9375Z" fill="#586474" />
            </svg>
            )
        },
    ]
}