import React, { useEffect } from "react";
import { useState } from "react";
import PrimaryButton from "../../Button1/primarybutton";
import CheckBox from "../../input/checkbox";
import SwicthInput from "../../input/swicth";
import ListContainer from "./listcontainer";
import { EditTemplateSidetap } from "../templateEditor";
import { EditerSetting } from "./editerSetting";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../store";
import { useSelectionAction } from "../../../pages/customstate/context";
import { useParams, } from "react-router-dom";
import { EditorSetiting } from "./EditorSettings";
import { DefaultMessageEditor } from "../../popup/DefaultMessageEditor";

export const EditerSideTap = ({ EditorFields = [], commonFileds = {}, AddFieldaction = null }) => {

    const Params = useParams();


    const { createTemplate, order, ActiveFieldSide } = useSelector(state => ({ createTemplate: state.createTemplate, order: state.fieldsOrder, ActiveFieldSide: state.ActiveFieldSide }))

    const [EditTemplate, setEditTemplate] = useState(false);

    const [EditTemplateField, setEditTemplateField] = useState(false);

    const [EditTemplateFieldScr, setEditTemplateFieldScr] = useState(0);

    const [CurrentPage, setActivePage] = useState(0);

    const [showSetting, setShowSetting] = useState(false);

    const [ShowDefaultMsgEditor, setShowDefaultMsgEditor] = useState(false);

    useEffect(() => {

        if (ActiveFieldSide?.ActiveScreen !== "common") {

            setEditTemplate(true);

            setEditTemplateField(ActiveFieldSide?.filedId);

            setEditTemplateFieldScr(ActiveFieldSide?.ActiveScreen == "result" ? 2 : ActiveFieldSide?.ActiveScreen == "main" ? 1 : 0);

        }

    }, [ActiveFieldSide])

    const Dispatch = useDispatch()

    return (
        <>

            <div className="relative flex flex-col w-2/5 " >

                <div className="flex w-full bg-white h-[56px]" style={{ zIndex: '10' }}>

                    <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={(CurrentPage == 1) ? 'text-gray-500' : 'text-primary'} border={`border-0 ${(CurrentPage == 1) ? 'border-b-1 border-gray-500 border-r-1' : ' border-b-2 border-primary '}`} height="h-14" text={'Block'} rounded={'rounded-none'} OnClick={() => setActivePage(0)} />

                    <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={(CurrentPage == 0) ? 'text-gray-500' : 'text-primary'} border={`border-0 ${(CurrentPage == 0) ? 'border-b-1 border-gray-500  border-l-1' : 'border-b-2 border-primary'}`} height="h-14" text={'Settings'} rounded='rounded-none' OnClick={() => setActivePage(1)} />

                </div>

                {ShowDefaultMsgEditor && <DefaultMessageEditor close={ setShowDefaultMsgEditor } />}

                {EditTemplate && <EditTemplateSidetap cancel={() => setEditTemplate(false)} templateFiledId={EditTemplateField} EditTemplateFieldScr={EditTemplateFieldScr} />}


                <div className="absolute flex flex-col w-full top-[56px] bottom-0 space-y-8   ">

                    {/* px-6 */}


                    {showSetting && <EditerSetting cancel={() => setShowSetting(false)} />}

                    {/* {showSetting && <EditerSetting />} */}


                    <div className={showSetting ? "hidden" : "transition-all duration-500 my-4 flex pb-20 h-full flex-col w-full space-y-2 overflow-y-auto list-industry px-6 py-6 pt-2 scroll-smooth"}>

                        {
                            (CurrentPage == 0) ?

                                <ListContainer commonFileds={commonFileds} filed={EditorFields} setEditTemplate={setEditTemplate} setEditTemplateField={setEditTemplateField} /> :

                                <EditorSetiting ContentEditor={setShowDefaultMsgEditor} />
                        }

                    </div>


                    {(CurrentPage == 0) && <div className={'  absolute flex bottom-0 w-full left-0 right-0 px-2 py-2'}>

                        {!createTemplate ? <PrimaryButton
                            text={"Add New Field"}
                            height="h-60px step-design-addNewField"
                            padding="py-10 "
                            rounded="rounded-xl"
                            margin="mt-auto "
                            classNames={'w-full'}
                            OnClick={AddFieldaction}
                        /> : <div className="w-full flex justify-end space-x-3 bg-white">
                            <PrimaryButton
                                text={"update Template"}
                                height="h-60px"
                                padding="py-10"
                                rounded="rounded-xl"
                                margin="mt-auto "
                                OnClick={() => Dispatch(Actions.setTriggerToTemp(order.length == 1 ? { key: order[0], update: true, template_id: Params.template_id } : { key: order[1], update: true, template_id: Params.template_id }))} />
                            <PrimaryButton
                                text={"Create Template"}
                                height="h-60px"
                                padding="py-10"
                                rounded="rounded-xl"
                                margin="mt-auto "
                                OnClick={() => Dispatch(Actions.setTriggerToTemp(order.length == 1 ? { key: order[0], update: false } : { key: order[1], update: false }))}
                            /></div>}
                    </div>}


                </div>

            </div>

        </>
    )
}