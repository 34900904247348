import React from "react";
import { useDispatch } from "react-redux";
import { EditorIcons } from "../../../content/editoricon";
import { Actions } from "../../../store";
 


export const EditorTooltip = ({position, setTriggerField=null}) => {

    const IconData = EditorIcons();

    const Dispatch = useDispatch()

    const triggerAction = (type) => {
        if(type==="create"){
            setTriggerField(true)
        }else Dispatch(Actions.setFieldRearrange({position:position,type:type}))
    }

    return (
        <div className="hidden group-hover:flex absolute  black space-x-1 top-3 right-4 z-40">
            <span className="rounded-full transform bg-white rotate-90 border-primary border-1 w-7 h-7 px-2.5 py-2 cursor-pointer" style={{ padding: '0.45rem 0.5rem' }} onClick={()=>triggerAction('create')} >{IconData.plus}</span>
            <span className="rounded-full transform bg-white rotate-90 border-primary border-1 w-7 h-7 px-2.5 py-2 cursor-pointer" style={{ padding: '0.45rem 0.5rem' }} onClick={()=>triggerAction('up')} >{IconData.rightArrow}</span>
            <span className="rounded-full transform bg-white border-primary border-1 w-7 h-7 px-2.5 py-2 cursor-pointer" style={{ padding: '0.45rem 0.5rem', transform: 'rotate(270deg)' }} onClick={()=>triggerAction('down')} >{IconData.rightArrow}</span>
            <span className="rounded-full bg-white border-primary border-1 w-7 h-7 px-2 py-2 cursor-pointer" style={{ padding: '0.3rem 0.4rem' }} onClick={()=>triggerAction('delete')} >{IconData.delete}</span>
        </div>
    )
}