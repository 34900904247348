import React from 'react'
import { Description, Title } from '../Texts';
import PrimaryButton from '../Button1/primarybutton';
import { DeleteIntegration, getIntegrationByChennal, getIntegrationByChennalv2 } from '../../actions/integrations';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { capitalizeFirstLetter } from '../../utils/helpers';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Actions } from "../../store";
import { listOfIcon } from '../../content/iconList';

export const IntegrationsCard = ({ data, show = null,setIntergationStage=null, reRenderlist,setloading = null ,select = null, setList=null ,setKeys=null }) => {

    const Params = useParams();

    const { userInfo } =  useSelector(state => ({userInfo:state?.userDetail}));

    const Dispatch = useDispatch();
    
    const Navigate = useNavigate();

    const edit = async (index) => {
        try {
            setloading(true)

            if (data[index]?.newVersion) {

                const resp = await getIntegrationByChennalv2( Params.campaign_id, data[index]?.mailer );

                if (resp.status) {

                    const { mailer, integration_list, credentials, list, field } = resp.data
                    Dispatch(Actions?.pushcampaingIntegration({ mailer, integration_list, credentials }))
                    Dispatch(Actions.setIntegrationmailerObj({mailer,...resp.data }));
                    select(data[index])
                    show(true);
                }

            } else {

                const RespData = await getIntegrationByChennal(Params.campaign_id, data[index]?.mailer)

                if (RespData.success) {
                    if (RespData.data?.list) setList(RespData.data?.list)
                    setKeys(RespData?.data?.credentials)
                }

                select(data[index])
                show(true)
                setIntergationStage(3)
            }
            setloading(false)
        }
        catch (err) {
            toast.error('Failed to get the integration')
            setloading(false)

    }
    }

    const Delete = async (index) => {
        try{

            setloading(true)
            const RespData =  await DeleteIntegration(Params.campaign_id,data[index]?.mailer)
            
            if(RespData.success){
                toast.success(`${capitalizeFirstLetter(data[index]?.mailer)} disconnected from the integration list.`)
            }
            reRenderlist(Params.campaign_id)
            setloading(false)
        }catch(err){
            toast.error('Failed to disconnect the integration')
            setloading(false) 
        }
    }

    const connectIcon = listOfIcon('connect',18,18,'white');
    const EditIcon2 = listOfIcon('edit2',18,18,'#2563EB');
    const disconnectIcon = listOfIcon('disconnect',18,18,'black');

    return (

        <>

{ ( userInfo?.plan === 'free') && <div className="w-full h-full fixed top-0 bottom-0 flex left-0 bg-[#FFFFFF80] z-10" > 
                <div className="flex m-auto flex-col w-fit space-y-3" >
                    <h3 className="font-medium">Please upgrade your plan to access integrations</h3>
                    <PrimaryButton text="Upgrade Plan" OnClick={()=>Navigate('/settings')} />
                </div>  
            </div>}
        
        <div>

            {
                data.map((i, k) => (
                    <div className="w-[90%] mx-auto  mt-4 " key={k}>
                        <div className="shadow-card-sm border-1 rounded-16px  justify-between  p-5 flex flex-row mt-12px animate-fadeIn">
                            <div className="flex my-auto">
                                <div className="overflow-hidden w-60px h-60px  rounded-8px my-auto flex">
                                    <img className="rounded-8px my-auto  object-cover" src={i.preview_url} />
                                </div>
                                <div className="ml-4">
                                    <Title className="capitalize" text={i.mailer.split('_').join(" ")} />
                                    <Description text={`Connect engage with ${i.mailer} to send new leads, customers, and subscribers to your mailing lists automatically.`} />
                                    { ( i?.newVersion ) && <p className='p-1 mt-1 rounded-md bg-primary text-white' >New Integration Version Update: Bulk Action Available on <span className='capitalize' >{i.mailer.split('_').join(" ")}</span> </p>}
                                </div>
                            </div>

                            <div className="my-auto flex space-x-3">
                                {
                                    !i?.isAdded ?
                                        <PrimaryButton
                                            buttontype={'primary'}
                                            textcolor={"text-white"}
                                            text={(i.mailer !== "google_sheet") ? "Connect" : "sign in with Google"}
                                            OnClick={() => {
                                                show(true)
                                                select(i)
                                            }}
                                            padding_y={(i.mailer !== "google_sheet") ?"py-3":"py-2"}
                                            height='35px'
                                            icon={ (i.mailer !== "google_sheet") ? connectIcon :  <span className='bg-white flex rounded-md p-0.5' ><img src="https://form-assets.dev.forms.gozen.io/cdn/login/google-logo.png" width="26" height="26" /> </span>
                                             }
                                        /> : <>
                                            <PrimaryButton
                                                width='w-28'
                                                backgroundColor='bg-[#2563EB1A]'
                                                textcolor={"text-primary"}
                                                text={"Edit"}
                                                OnClick={() => {
                                                    edit(k)
                                                }}
                                                icon={EditIcon2}
                                            />
                                            <PrimaryButton
                                                buttontype={'primary'}
                                                backgroundColor='bg-white'
                                                textcolor={"text-black"}
                                                text={"Disconnect"}
                                                OnClick={() => {
                                                    Delete(k)
                                                }}
                                                icon={disconnectIcon}
                                            />

                                        </>
                                }
                            </div>

                        </div>
                    </div>
                ))
            }
        </div>
</>
    )
}
