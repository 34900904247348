import React from 'react'
import PropTypes from "prop-types";
import { NavLink } from 'react-router-dom';

const Link = function ({ text,
    href = "/", onClick,
    textColor = "text-primary",
    fontWeight = "font-medium",
    underline = "",
    padding = "",
    fontSize = "text-14px",
    margin = "",
    target = "_blank",
    id = ""
}) {
    return (
        <NavLink
            to={href}
            id={id}
            target={target}
            onClick={(e) => {
                if (onClick && !e.ctrlKey) { e.preventDefault(); onClick(e) }
                else return null
            }}
            className={`${textColor} ${margin} ${fontWeight} ${padding} ${underline} ${fontSize} hover:underline`}>
            {text}
        </NavLink>
    )
}



Link.prototype = {
    text: PropTypes.string.isRequired,
    textColor: PropTypes.string,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    padding: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
}

export default Link