// import re from 'react'

import { useSelector, useDispatch } from "react-redux";
import PrimaryButton from "../../Button1/primarybutton";
import { Actions } from "../../../store";

export const SelectList = ({ IsActive = 0, setisActive = null, fieldId = '',trigger=null }) => {
  const Dispatch = useDispatch();

  const fields = useSelector((state) => state.fields);

  // const { fieldId } = useSelector((state) => state.EditorFields[0]);

  const productSelectList = fields[fieldId]?.filedContent?.game_resultcreen_json?.productSelectList ?? [];


  const selectedFilter = (index) => {
    setisActive(index)
    // Dispatch(Actions.setRemoveSelectList({ index, fieldId }));

    // Dispatch(Actions.setUpdateCampaign());
  };

  const removeFilter = (index) => {
    Dispatch(Actions.setRemoveSelectList({ index, fieldId }));

    Dispatch(Actions.setUpdateCampaign());
  };



  return (
    <div id="Select_Products">
      <div className="w-full flex flex-col border-b-1 py-2" onClick={()=>trigger(true)}>
        <p className="flex w-full justify-between p-2 px-4">
          <span className="font-bold capitalize">Select Products</span>
          <div className="flex space-x-6">
            <span>
              <span className="text-primary text-lg">
                {productSelectList?.length}
              </span>
            </span>
            <span className="flex items-center">
              <svg width="19" height="10" viewBox="0 0 19 10" fill="none">
                <path
                  d="M1.13235 0.559233C1.28008 0.559035 1.4264 0.588093 1.56285 0.644733C1.6993 0.701372 1.82318 0.78447 1.92735 0.889233L7.6821 6.64323C7.85621 6.81739 8.06293 6.95555 8.29045 7.04981C8.51797 7.14407 8.76183 7.19258 9.0081 7.19258C9.25437 7.19258 9.49822 7.14407 9.72574 7.04981C9.95326 6.95555 10.16 6.81739 10.3341 6.64323L16.0798 0.896733C16.1836 0.789284 16.3078 0.703579 16.445 0.644619C16.5823 0.585658 16.7299 0.554624 16.8793 0.553326C17.0286 0.552028 17.1768 0.580492 17.315 0.637058C17.4533 0.693624 17.5789 0.777159 17.6845 0.882788C17.7902 0.988418 17.8737 1.11403 17.9303 1.25228C17.9868 1.39054 18.0153 1.53868 18.014 1.68806C18.0127 1.83744 17.9817 1.98506 17.9227 2.12231C17.8638 2.25957 17.778 2.3837 17.6706 2.48748L11.9248 8.23398C11.1508 9.00661 10.1018 9.44055 9.0081 9.44055C7.91441 9.44055 6.86542 9.00661 6.09135 8.23398L0.336596 2.47998C0.179159 2.32265 0.0719315 2.12215 0.0284809 1.90385C-0.0149697 1.68555 0.00730993 1.45927 0.0925005 1.25364C0.177691 1.04802 0.321964 0.872276 0.507063 0.748667C0.692162 0.625057 0.909768 0.559132 1.13235 0.559233Z"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#586474"
                />
              </svg>
            </span>
          </div>
        </p>
        <div className="relative grid grid-cols-3 w-full py-3 px-4 gap-4">
          {productSelectList.map((selectProduct, key) => (
            <div className="relative">
              <div
                key={key}
                onClick={() => selectedFilter(key)}
                className={`relative transition-all duration-500 space-y-3 rounded-xl p-4 bg-white border-1 cursor-pointer ${IsActive === key ? "border-primary -top-4 shadow-lg" : "top-0"}`}>
                <div className="h-48 flex rounded-xl border-1">
                  <div className="flex m-auto max-h-full">
                    <img
                      src={selectProduct?.productImageSrc}
                      alt="productimage"
                      className="max-w-full max-h-full object-contain "

                    />
                  </div>
                  {/* <img src={list?.productImageSrc} alt="" /> */}
                </div>
                <p className="text-black w-[250px] overflow-hidden text-ellipsis whitespace-nowrap text-base">
                  {selectProduct?.productName}
                </p>
                <p className="text-black text-lg font-bold">
                  {selectProduct?.price || 0}
                </p>
                <PrimaryButton text={selectProduct?.buttonText || "Buy Now"} />
              </div>
              <div className={`absolute transition-all duration-500 -top-3 -right-3 cursor-pointer hover:shadow-lg ${IsActive === key ? " -top-7 shadow-lg" : ""}`} onClick={() => removeFilter(key)}>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="14" cy="14" r="14" fill="#d62020" />
                  <g clip-path="url(#clip0_2986_16125)">
                    <path d="M20.3 7.70973C19.91 7.31973 19.28 7.31973 18.89 7.70973L14 12.5897L9.10997 7.69973C8.71997 7.30973 8.08997 7.30973 7.69997 7.69973C7.30997 8.08973 7.30997 8.71973 7.69997 9.10973L12.59 13.9997L7.69997 18.8897C7.30997 19.2797 7.30997 19.9097 7.69997 20.2997C8.08997 20.6897 8.71997 20.6897 9.10997 20.2997L14 15.4097L18.89 20.2997C19.28 20.6897 19.91 20.6897 20.3 20.2997C20.69 19.9097 20.69 19.2797 20.3 18.8897L15.41 13.9997L20.3 9.10973C20.68 8.72973 20.68 8.08973 20.3 7.70973Z" fill="white" />
                  </g>
                  <defs>
                    <clipPath id="clip0_2986_16125">
                      <rect width="24" height="24" fill="white" transform="translate(2 2)" />
                    </clipPath>
                  </defs>
                </svg>


              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
// d62020
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_193_1049)">
    <path d="M18.3 5.71C17.91 5.32 17.28 5.32 16.89 5.71L12 10.59L7.10997 5.7C6.71997 5.31 6.08997 5.31 5.69997 5.7C5.30997 6.09 5.30997 6.72 5.69997 7.11L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.11C18.68 6.73 18.68 6.09 18.3 5.71Z" fill="#586474" />
  </g>
  <defs>
    <clipPath id="clip0_193_1049">
      <rect width="24" height="24" fill="white" />
    </clipPath>
  </defs>
</svg>
