import { CountryJSON } from "../../../content/country.js";
import { listOfIcon } from "../../../content/iconList.js";
import { TimezoneJSON } from "../../../content/timezone";
import { isEqual, isNotEmpty } from "../../../utils/helpers.js";
import PrimaryButton from "../../Button1/primarybutton";
import DefaultDropMenu from "../../input/droplist";
import InputWithLabel from "../../input/inputWithLabel";

export const TargetRules = ({
    Rules = [],
    AddRules = null,
    DeleteRule = null,
    contentCC = null

}) => {

    const ruleTypeList = ["Url", "Page Views", "Cookie", "Country", "Timezone"]
    const ruleTRList = [ 'url','pagecount','cookie','country','timezone' ]
    const CountryData = CountryJSON
    const TimezoneData = TimezoneJSON

    const ConditionObj = [
        {
            label:'Home Page',
            type:'homepage'
        },
        {
            label:"Contains",
            type:"contains",
        },
        {
            label:"Doesn't Contains",
            type:"notContains",
        },{
            label:"Exactly On",
            type:"Exactly",
        },
        {
            label:"Query Contains",
            type:"querycontains",
        },
        {
            label:"Query Doesn't Contains",
            type:"querynotcontains",
        },{
            label:"Don't Show on Home page",
            type:"notHomepage",
        },
    ]

    

    const condictionNunobj = [
        {
            label:'Exactly',
            type:'exactly',
        },
        {
            label:'Less Then',
            type:'lessthen',
        },
        {
            label:'More Then',
            type:'morethen',
        },
    ]

let cookieCondition=[
    {
        label:'Exactly',
        type:'exactly',
    },
    {
    label:"Contains",
    type:"contains"
    }
]

    let cookieLables =  cookieCondition.map( list => list?.label) 
    let cookieValues =  cookieCondition.map( list => list?.type) 



    const condistionLabel = ConditionObj.map( list => list?.label) 
    const condistionnumLabel = condictionNunobj.map( list => list?.label) 


    const condistionType = ConditionObj.map( list => list?.type) 
    const condistionnumType = condictionNunobj.map( list => list?.type) 


    const timezoneList =  TimezoneData.map( list => list?.text) 
    const CountryList =  CountryData.map( list => list?.name) 

    // ruleType :"url" ,  // [ 'url','country','pagecount','cookie','timezone' ],
    // condiction : "",
   // value:"",
    // cookieName:'',
    // cookieNameCondiction:'',
    // cookieValue:'',
    // cookieValueConvaluediction:'',

    //isCookie:null //|| {
    //     condiction : "",
    //     value:"",
    // }

    // const ONContentChange = () => contentCC('targetRules',index)
    

    return (<>
        {
            Rules?.map((list, index) => {

                const isRuleTypeIndex = ruleTRList.findIndex(RT => RT == list?.ruleType )
                
                let mapArr = (list?.ruleType === 'url') ?  condistionLabel : condistionnumLabel
                let mapArrType = (list?.ruleType === 'url') ?  condistionType : condistionnumType


                let NonConditionmapArr = (list?.ruleType !== 'timezone') ?  CountryList : timezoneList

                const iscondtionIndex = mapArrType.findIndex(RT => RT?.toLowerCase() === list?.condiction?.toLowerCase() )



                let timezoneValue=list?.value

                if(list?.ruleType == 'timezone'){
                TimezoneData.map((obj, inx)=>{
                    let temp=obj.utc.join(", ")
                    if(isEqual(temp,list?.value)){
                        timezoneValue=obj?.text
                    }
                })
            }


                const isnoncondtionIndex1 = timezoneList.findIndex(RT => RT === timezoneValue )
                const isnoncondtionIndex2 = CountryData.findIndex(RT => RT?.code === list?.value )

                const cookiename = cookieValues.findIndex(RT => RT === list?.cookieNameCondiction )
                const cookievalue = condistionnumType.findIndex(RT => RT === list?.cookieValueConvaluediction )


                const condition1 = (list?.ruleType === 'url') || (list?.ruleType == 'pagecount') 
                const condition3 = (list?.ruleType == 'cookie')  

                return (
                    <div className="w-full flex flex-row  items-center">

                        <DefaultDropMenu label={''} mainclass="flex mr-4" labelTextWeight="hidden" list={ ruleTypeList } active={isRuleTypeIndex} SelectIndex={true} onchange={(ind)=>contentCC('targetRules',index,'ruleType',ruleTRList[ind])} />
                        
                        { condition1 && <DefaultDropMenu label={''} mainclass="flex mr-4" labelTextWeight="hidden" list={ mapArr } active={ iscondtionIndex<0 ? 0 : iscondtionIndex } SelectIndex={true} onchange={(ind)=>{contentCC('targetRules',index,'condiction',mapArrType[ind]);}} /> }
                        
                        { (list?.ruleType == 'timezone') && <DefaultDropMenu label={''} mainclass="flex mr-4" labelTextWeight="hidden" list={ timezoneList } active={ isnoncondtionIndex1  } SelectIndex={true} onchange={(ind)=>contentCC('targetRules',index,'value',timezoneList[ind])} /> }
                        { (list?.ruleType == 'country') && <DefaultDropMenu label={''} mainclass="flex mr-4" labelTextWeight="hidden" list={ CountryList } active={ isnoncondtionIndex2  } SelectIndex={true} onchange={(ind)=>contentCC('targetRules',index,'value',CountryData[ind]?.code)} /> }
                        
                        { condition3 && <DefaultDropMenu label={''} mainclass="flex mr-4" labelTextWeight="hidden" list={ cookieLables } active={ cookiename<0 ? 0 : cookiename } SelectIndex={true} onchange={(ind)=>contentCC('targetRules',index,'cookieNameCondiction',cookieValues[ind])} /> }
                        { condition3 && <InputWithLabel  textvalue={list?.cookieName || ""} label="" mainclass="flex flex-row mr-4 items-center " labelTextWeight="hidden" Placeholder="Key"  width={"w-[100%]"} onchange={(e)=>contentCC('targetRules',index,'cookieName',e.target.value)} /> }
                        { condition3 && <DefaultDropMenu label={''} mainclass="flex mr-4" labelTextWeight="hidden" list={ mapArr } active={ cookievalue < 0 ? 0 : cookievalue } SelectIndex={true} onchange={(ind)=>contentCC('targetRules',index,'cookieValueConvaluediction',mapArrType[ind])} /> }
                        { condition3 && <InputWithLabel  textvalue={list?.cookieValue || ""} label="" mainclass="flex flex-row  items-center " labelTextWeight="hidden" Placeholder="Value"  width={"w-[100%]"} onchange={(e)=>contentCC('targetRules',index,'cookieValue',e.target.value)} /> }

                        { condition1   && <InputWithLabel textvalue={list?.value || ""}  label="" mainclass="flex flex-row  items-center " labelTextWeight="hidden" Placeholder={list?.ruleType === 'url'? "URL" : "0"}  width={"w-[100%]"} onchange={(e)=>contentCC('targetRules',index,'value',e.target.value)} /> } 

                        <div className="ml-3 mt-1 cursor-pointer rounded-md h-[37px] bg-[#ffffff] border-[#fb3c3c] border-1 aspect-square flex justify-center items-center " onClick={()=>DeleteRule(index)} >
                            
                            { listOfIcon('delete',20,20,'#fb3c3c') }
      
                        </div>


                    </div>
                )
            })
        }




        <PrimaryButton text={'Add'} width={"w-[80px] mt-2"} OnClick={AddRules} />
    </>);
}