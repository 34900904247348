// import { Input } from "postcss";
import React, { Component, useEffect, useRef } from "react";
import Layout from ".";
import { useSelectionAction } from "./customstate/context";
import { PopupCreate } from "../components/popup/popupcreate";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getCampaign, getFields } from "../actions/templates";
import { DesignBlock } from "../components/Editor/design";
import { IntegateBlock } from "../components/Editor/integration";
import { ShareBlock } from "../components/Editor/share";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../store";
import { ResultBlock } from "../components/Editor/result";
import { Ecommmerceproduct } from "../components/Editor/product";
import { LiveCamp } from "../components/popup/livelinkPopup";
import { commonFileds as CommonFileds } from "../content/commenfileds";
import { AutoSavingPopup } from "../components/popup/autoSaving";
import { Tutorial } from "../components/Tutorial";
import { getCustomDomain } from "../actions/site";

const EditTempalte = ({ SharedWS = false }) => {

    const Params = useParams();
    const Dispatch = useDispatch() //setclearEditor

    const  {campaigninfo,isEcommerceFields,autosaving} = useSelector((state)=> ({autosaving:state.autosaving,campaigninfo:state.Campaign_info,isEcommerceFields:state.isEcommerceFields}))

    const fileds = ["title", "description", "sub description", "image", "email input", "terms & conditions"];
    const tigger = ["Show on page load", "Show on exit", "Click to trigger"];

    const Cancelbut = () => {
        setShowPopup(false)
    }

    

    const { editerstate, setSidebarTrigger, setSidebarState,setEditerState } = useSelectionAction()
    const [defaultvalue, setDefaultvalue] = useState('My Project-1')
    const [showPopup, setShowPopup] = useState(false)
    const [showEcommerce, setEcommerce] = useState(false)
    const [allFileds, setallFileds] = useState([])



    const campaigninochange = (e,type)=>{
        const payload = { 
            type:type,
            value:e.target.value
        }
        Dispatch(Actions.setCampaignUpdate(payload))
    }

    const DoneAction = () =>{
        Dispatch(Actions.setUpdateCampaign())
        setShowPopup(false)
    }

 

    useEffect(() => {

        setEditerState("design");
        Dispatch(Actions.setclearEditor())
        fetchCampaign(Params.campaign_id);
        FetchFiledData()
        FetctCD()
        Dispatch(Actions.setSharedWS( SharedWS ))
        Dispatch(Actions.setSharedWSID( Params.project_id ))
        
    }, [Params.campaign_id])

    const FetchFiledData = async () => {

        try {
            const allField = await getFields();
            setallFileds(allField.data?.allfields)
            setCommonFileds(allField.data?.commonFileds)
            Dispatch(Actions.setEditorFieldsFormSever(allField.data?.commonFileds))
        }catch(err) {
            console.log("Failed to Get FieldData")
        }

    }

    const FetctCD = async () =>  {

        const SiteResp = await getCustomDomain(); 

        Dispatch(Actions.fetchCustomDomainFromStore(SiteResp?.data));

    }               
    

    const fetchCampaign = async (campaign_id) => {
        setloading(true)

        const Campaign = await getCampaign(campaign_id,Params.project_id,SharedWS);


        if (Campaign.success) {

            let Filed = Campaign.data.fileds ?? {}
            setDefaultvalue(Campaign.data?.name);

            Dispatch(Actions.setCampaign({...Campaign.data,Campaign_id:Params.campaign_id}));
            Dispatch(Actions.setFields(JSON.parse(Campaign.data.fileds)))
            Dispatch(Actions.setFieldsOrder(JSON.parse(Campaign.data.order)))
            Dispatch(Actions.SetAddSites(Campaign.data?.Sites))
            Dispatch(Actions.setEditorFieldsFormSever(CommonFileds))
            Dispatch(Actions.putCampaignSettings( JSON.parse(Campaign.data?.settings ?? "") ))
            const fieldType = JSON.parse(Campaign.data.fileds)[JSON.parse(Campaign.data.order)[0]]?.type

            if (fieldType === "ecommerce") {
                Dispatch(Actions.setisEcommerceFields(true))
                Dispatch(Actions.setEcommerceFieldID(JSON.parse(Campaign.data.order)[0]))
                setEditerState("product");
            }

        }

        else alert(Campaign.message + ' .')
        setloading(false)

    }
    

    const [commonFileds, setCommonFileds] = useState({});
    const [triggerField, setTriggerField] = useState(false);
    const [loading, setloading] = useState(false);

    setSidebarState(0)

    return (

        <>

            <Layout isactive={1} padding="" small={true} activeSidetap={true} setediter={true} sidebarView={true} Ecommerce={isEcommerceFields} >
            
                {showPopup  ? <PopupCreate title="Campaign Name" type={'name'} defaultVaule={campaigninfo?.name} changeDefaultVaule={campaigninochange} forwardAction={DoneAction} backwardAction={setShowPopup} /> : null}

                <AutoSavingPopup autosaving={autosaving} />
                
                { campaigninfo?.CPstatus && <LiveCamp title="Your campaign is published!" />}
                
                {editerstate == "product" && <Ecommmerceproduct />}

                {editerstate == "design" && <DesignBlock campaignId={Params.campaign_id} allFileds={allFileds} commonFileds={commonFileds} loading={loading} editName={()=>setShowPopup(true)} />}

                {editerstate == "integration" && <IntegateBlock campaignId={Params.campaign_id}/>}

                {editerstate == "result" && <ResultBlock/>}
                
                {editerstate == "share" && <ShareBlock/>}

            </Layout>

        </>

    )

}

export default EditTempalte;