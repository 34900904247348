import React, { useEffect, useState } from "react";
import { QuizContainer } from "../../../quizQue";
import PrimaryButton from "../../../Button1/primarybutton";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../../store";
import { MediaPopup } from "../../../popup/Mediapopup";

export const QuizSettings = ({ templateType='', field, fieldId }) => {

    const Dispath = useDispatch()
    const [activeQue,setQctiveQue] = useState(0)

    const { Fields, device } = useSelector((state) => ({ Fields: state.fields, device: state.device }));


    const templateData = Fields[fieldId];
    const Settings = templateData?.filedContent
    const resultScreenContent = Settings.game_resultcreen_json;

    const [arrAction, setarrAction] = useState([])

    const AddQues = () => {
        const setContent = {
            key:fieldId,
            node:'game_mainpage_json',
            child:'questions',
            value:{
                "questionType": "Default",
                "answer": "no",
                "question": "Is 10 a prime number?",
                "image": "",
                "questionId": Math.random().toString(16).slice(8) + Date.now().toString(36),
                "optionType": "yesorno",
                "options": ["option-1", "option-2", "option-3", "option-4"]
            } 
        }
        Dispath(Actions.setQuizQuestionAdd(setContent))
    }

    const addQuestionId = ( childInd ) => {

        let payload = {
  
          key:fieldId,
          node:'game_mainpage_json',
          child:'questions',
          childInd
  
        }
  
        Dispath(Actions.questionIdAdd( payload ))
  
      }

      useEffect(() => {
        let arr = []
        field?.questions?.map((list, index) => arr.push(list?.question))
        if ("results" in resultScreenContent) resultScreenContent?.results.map((list, index) => arr.push(list?.titleContent))
        else arr.push(resultScreenContent?.titleContent);
        arr.push('score');
        setarrAction(arr)
    }, [])
    return(
        <div className="w-full space-y-4 ">


            {
                field?.questions?.map((list,index) =>{

                    if(!list?.questionId) addQuestionId( index )
                    
                    return(
                        <QuizContainer 
                        templateType={templateType}
                            Index={index}
                            required={list?.required ?? false}
                            answer={list?.answer} 
                            question={list?.question}
                            isAcitve={activeQue}
                            fieldId={fieldId} 
                            setQctiveQue={setQctiveQue}
                            imgSrc={list?.image} 
                            optionType={list?.optionType} 
                            options={list?.options}  
                            jumpToLeft={arrAction} 
                            rules={list?.rules ?? {}}
                        />

                    )
                })
            }

            <PrimaryButton text={'Add Question'} width="w-full" height="h-12" backgroundColor="bg-primary-lightblue" border="border-1 border-primary" textcolor="text-primary" OnClick={AddQues} />

        </div>

    )
    
}