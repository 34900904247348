import React, { useState } from 'react'
import DefaultDropMenu from '../../../input/droplist'
import { Imageinput } from '../../../input/imageinput'
import Input from '../../../input/input';
import PrimaryButton from '../../../Button1/primarybutton';
import { ColorPixerInput } from '../../../input/colorpixerInput';
import { MediaPopup } from '../../../popup/Mediapopup';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../../../../store';
import { FindOddOneContainer } from '../../../findoddOneCard';

export const FindOddOne = ({ field, fieldId }) => {
    
    const { Fields } = useSelector((state) => ({ Fields: state.fields, })), updatedFields=Fields[fieldId]['filedContent']["game_mainpage_json"]
  
    const FrameList =field?.gameFrames
    const FrameListkey = (Object.keys(field?.gameFrames)).sort() 
    const Dispatch = useDispatch()
    const [ShowMedia, setShowMidia] = useState(false);
    const dropmenuList = ['4x3', '4x4', "3x3", '5x2', '5x4', '6x5', '6x4']
    const findIndex = dropmenuList.findIndex(list => list === field?.layoutrange)
    const [keyInx, setkeyInx]=useState(0)

    const DirectContentChange = (value, type) => {

        const setContent = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_mainpage_json',
            child: type,
            value: value
        }
        // Dispatch(Actions.setFieldTemplatePairChange(setContent))
        Dispatch(Actions.setFieldTemplateContentChange(setContent))
        if (ShowMedia) setShowMidia(false)

    }

    const DirectLayoutChange = (value, type) => {

        let newValue=value.split("x");
        let oldValue= (updatedFields["layoutrange"]).split("x")
        let rowDiff=newValue[1]-oldValue[1];
        let colDiff=newValue[0]-oldValue[0];
        let frameArray=updatedFields["gameFrames"]        
        let totalFrames= Object.keys(frameArray)?.length || 0
        let obj=[{
                "type": "oddElem",
                "imageUrl": "https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/apple.png"
            }]  

        for(let i=1;i<newValue[0];i++){
            obj.push({
                  "type": "evenElem",
                  "imageUrl": "https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/christmas_toy.png"
              });
        } 


        
            const setContent = {
                isCommonSetting: false,
                key: fieldId,
                node: 'game_mainpage_json',
                child: type,
                newLayout: value,
                rowDiff:rowDiff,
                colDiff:colDiff,
                totalFrames:totalFrames,
                obj:obj,
                row:newValue[1],
                col:newValue[0]
            }
            Dispatch(Actions.setOddOneLayout(setContent))
            setkeyInx(obj => obj+1)

} 
 

    
  const AddFrame = () => {
        let nextFrameId=parseInt((FrameListkey[FrameListkey?.length-1]).replace("Frame",""))
        let oldValue= (updatedFields["layoutrange"]).split("x")
        let oneRow=[{
          "type": "oddElem",
          "imageUrl": "https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/apple.png"
      }]  

    for(let i=1;i<oldValue[0];i++){
        oneRow.push({
              "type": "evenElem",
              "imageUrl": "https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/christmas_toy.png"
          });
    } 

    let manyRow=[];  for(let i=0;i<oldValue[1];i++){  manyRow.push(oneRow);  }

    const setContent = {
        key:fieldId,
        node:'game_mainpage_json',
        child:'gameFrames',
        edge: "Frame"+(nextFrameId+1),
        type:"add",
        value:manyRow
    } 
    Dispatch(Actions.setOddOneFrameAdd(setContent))
    }
 


    return (
        <div className="w-full space-y-4 ">

            {ShowMedia && <MediaPopup cancel={setShowMidia} fieldType={'image_src'} OnSelect={DirectContentChange} />}

            <DefaultDropMenu label={'choose layout :'} list={dropmenuList} active={findIndex < 0 ? 0 : findIndex} fieldType={'layoutrange'} onchange={DirectLayoutChange} />

            {
                FrameListkey?.map((frameKey,index)=>{  
                    return(
                        <FindOddOneContainer keyVal={keyInx} Index={index} fieldId={fieldId} frameObj={FrameList[frameKey]} frameKey={frameKey} />
                    )
                })
            }
              
               <PrimaryButton text={'Add Frame'} width="w-full" height="h-12" backgroundColor="bg-primary-lightblue" border="border-1 border-primary" textcolor="text-primary" OnClick={AddFrame} />
             






        </div>
    )

}