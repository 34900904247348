import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EditorDescription } from "../../renderComponents/description";
import { EditorTitle } from "../../renderComponents/title";
import HandleIcon from "../../templatepreview/handleicon";
import { ImagerenderBlock } from "../../renderComponents/image";
import { TemlpateRC } from "../../renderComponents/tempates";
import { QuizTemplate } from "../../renderComponents/quiz/quiz";
import { PollTemplate } from "../../renderComponents/poll/poll";
import { SpinWheelTemp } from "../../renderComponents/tempates/spinwheel";
import { CalculatorTemplate } from "../../renderComponents/calculator";
import { EcommerceTemplate } from "../../renderComponents/ecommerce";
import { Actions } from "../../../store";
import { SurveyTemplate } from "../../renderComponents/survey"; 
import { isNotEmpty } from "../../../utils/helpers";
import { VideorenderBlock } from "../../renderComponents/video";
import { ButtonBlock } from "../../renderComponents/buttonComp";
import { EmailBlock } from "../../renderComponents/EmailBlock";
import { EmbedBlock } from "../../renderComponents/embedBlock";
import { LogoBlock } from "../../renderComponents/logoBlock";
import { AudioREBlock } from "../../renderComponents/AudioRe";
import { SpinWheelSideTab } from "../../renderComponents/tempates/spinWheelSideTap";
import { Tutorial } from "../../Tutorial";
import { listOfIcon } from "../../../content/iconList";


export const EditerMainBlock = ({ editName = null, setTriggerField=null }) => {

    const Navigate = useNavigate();
    const Dispatch = useDispatch()

    const [ActiveDevice,setActiveDevice] = useState('desktop')

    useEffect(()=>{  Dispatch(Actions.setChangeDevice("desktop"))},[])

    const MainBlockRef = useRef(null)

    const { Order, field, campaign, decivestate, ActiveField, campaignSettings } = useSelector((state) => ({ Order: state.fieldsOrder, field: state.fields, campaign: state.Campaign_info, decivestate: state.device,ActiveField:state.ActiveField, campaignSettings:state?.campaignSettings }))
    
    const setpoll = (PollQue=[],key)=>{
        let voteCount = [];
        let Payload = {type:"additional_info",value:{...campaign?.additional_info}}
        PollQue.map(list => {
            if(list?.optionType== 'multioption'){
                return voteCount.push({
                    total: 0,
                    option: list?.options.map((list)=>{return 0}) ,
                })

            }
            return voteCount.push({
                total: 0,
                option: [0, 0],
            })

        })
        Payload.value[key] = {pollResp:voteCount}
        Dispatch(Actions.setCampaignUpdate(Payload)) 
    }

    useEffect(()=>{

        let id = `gameFrameBorder${ActiveField.filedId}`

        if(ActiveField.filedId !== ""){

            let elem = document.getElementById(id)

            if(elem) document.getElementById(id).scrollIntoView()

        }

    },[ActiveField]);

    const { fontFamily, backgroundType, spaceBetweenTheBlock, image, color } = campaignSettings;

    const spaceBetween = [ "space-y-0", "space-y-1", "space-y-2", "space-y-3", "space-y-4", "space-y-5", "space-y-6", "space-y-7", "space-y-8", "space-y-9", "space-y-10", ];

    const leftArrow = listOfIcon('leftarrow',14,14,'#586474');
    const editIcon2 = listOfIcon('edit2',18,18,'#06152D');

    return (

        <div className="h-full">


            <div className="flex flex-col w-full border-1 h-full ">

                <div className=" flex justify-between py-3 px-4 border-b-1">
                    <p className="step-design-rename my-auto font-medium flex space-x-2 ml-2 ">
                        <span className="py-2 px-3 border-2 rounded-md cursor-pointer" onClick={() => Navigate(-1)}>{leftArrow}</span>
                        <span className="my-auto " >{campaign?.name}</span>
                        <span className=" my-auto cursor-pointer" onClick={editName} >{editIcon2}</span>
                    </p>

                    <span className="step-design-device"><HandleIcon activeState={setActiveDevice} /></span>

                </div>


                <div ref={MainBlockRef} className={` ${decivestate === 'mobile' ? 'w-[460px]': decivestate === 'tab' ? 'w-[770px]' : 'w-full' } scroll-smooth transition-all duration-500 mx-auto h-full ${spaceBetween[spaceBetweenTheBlock]} bg-primary-lightblue py-3  overflow-y-auto list-industry`} style={{ background: ( backgroundType == "color") ? (color ?? "#7ED321") : `url(${ image ?? "/asset/images/quizzes/background.png"}) 0% 0% / 100% 100% no-repeat`, backgroundSize: "cover",fontFamily:fontFamily }} id="EditorMainblock">

                    {
                        Order.map((list, index) => { 
                            let data={"fieldID":list, "position":index, "setTriggerField":setTriggerField,"toShowTips":true}
                            
                            if(field[list]?.templatetype === 'untemplate' ) return (
                                <> 
                                    {

                                        field[list].type === 'title' && field[list]?.filedContent?.DisplayView===false ?  <EditorTitle {...data} /> :
                                        field[list].type === 'description' && field[list]?.filedContent?.DisplayView===false ? <EditorDescription {...data} /> :
                                        field[list].type === 'image' && field[list]?.filedContent?.DisplayView===false ? <ImagerenderBlock {...data} /> :
                                        field[list].type === 'button' && field[list]?.filedContent?.DisplayView===false ? <ButtonBlock {...data} /> :
                                        field[list].type === 'email' && field[list]?.filedContent?.DisplayView===false ? <EmailBlock {...data} /> :
                                        field[list].type === 'embed' && field[list]?.filedContent?.DisplayView===false && isNotEmpty(field[list]?.filedContent?.EmbedCode) ? <EmbedBlock {...data} /> :
                                        field[list].type === 'video' && field[list]?.filedContent?.DisplayView===false ?  <VideorenderBlock  {...data} /> :
                                        field[list].type === 'logo' && field[list]?.filedContent?.DisplayView===false?  <LogoBlock {...data} />  :
                                        field[list].type === 'audio' && field[list]?.filedContent?.DisplayView===false ?  <AudioREBlock {...data} />  :

                                        null

                                    }
                                </>
                            )

                            if (field[list]?.category === 'games') return (

                                <>
                                    {

                                        // field[list].type === 'findpair' ? <FindPair {...data} /> :
                                        // field[list].type === 'Q&A' ? <QuizGamesTemplate {...data}  />:
                                        // field[list].type === 'connection' ? <ConnectionTemp {...data}  />:
                                        field[list].templatetype === 'template' ? <TemlpateRC {...data} /> :
                                            null

                                    }
                                </>
                            )

                            if (field[list]?.category === 'quizzes') return (
                                <QuizTemplate {...data} />
                            )
                            if (field[list]?.category === 'poll') {
                                let pollRespData = campaign?.additional_info[list]?.pollResp ?? []


                                if(pollRespData?.length === 0) {
                                    setpoll(field[list]?.filedContent?.game_mainpage_json?.questions,list)
                                }
                                return (
                                    <PollTemplate {...data} />
                                )
                            }
                            if (field[list]?.category === 'spinWheel' && field[list]?.filedContent?.game_mainpage_json?.spinWheelType==="default" ) return (
                                <SpinWheelTemp {...data} />
                            )

                            if (field[list]?.category === 'spinWheel' && field[list]?.filedContent?.game_mainpage_json?.spinWheelType==="sideTab" ) return (
                                <SpinWheelSideTab {...data} />
                            )

                            if (field[list]?.category === 'calculater') return (
                                <CalculatorTemplate {...data} />
                            )
                            if (field[list]?.category === 'ecommerce') return (
                                <EcommerceTemplate {...data} />
                            )
                            if (field[list]?.category === 'surveys') return (
                                <SurveyTemplate {...data} />
                            )

                        })


                    }

                </div>

            </div>

        </div>
    )
}