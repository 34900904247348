import React, { useRef, useState } from "react";
import { GetApiKey, GetApiKeyk } from "../ProductsIntrgation/GetApiKey";
import * as Xlsx from "xlsx"
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "../../../store";
import { EcommerceIntegrationData } from "../../../content/ecommerceIntegration";
import { listOfIcon } from "../../../content/iconList";

export const AddProduct = ({ setstage = null, fieldId = '' }) => {


    //  dispatch 

    const dispatch = useDispatch()


    const [selectList, setSelectList] = useState(-1)

    // keys 

    const [ApiKey, setApiKey] = useState({})

    const data = EcommerceIntegrationData;

    const RefFile = useRef(null)
    const TriggertoRef = () => { RefFile.current.click(); setstage(0); }
    const onChnageFile = (e) => {

        const Reader = new FileReader()
        Reader.readAsBinaryString(e.target.files[0]);
        Reader.onload = e => {
            const EventData = e.target.result;
            const Webhook = Xlsx.read(EventData, { type: "binary" })
            const SheetName = Webhook.SheetNames[0]; //  get the Sheetname
            const Sheet = Webhook.Sheets[SheetName]


            let GetDataFrommSheet = [...Xlsx.utils.sheet_to_json(Sheet)]
            for (let i = 0; i < GetDataFrommSheet.length; i++) {
                GetDataFrommSheet[i]['id'] = "manual_" + GetDataFrommSheet[i]['id']
                const setContent = {
                    isAdded: true,
                    key: fieldId,
                    node: 'game_mainpage_json',
                    child: "ManualList",
                    value: GetDataFrommSheet[i]
                }
                dispatch(Actions.setProductDetail(setContent))

            }
        }
        dispatch(Actions.setUpdateCampaign())
        setstage(2)
    }

    const leftArrow = listOfIcon('listarrow', 14, 14, '#586474')

    return (
        <div className="w-full h-full flex flex-col ">

            <div className="w-full flex flex-row items-center justify-center py-4">

                <div className="w-[100px] flex">   <div className=" font-medium flex h-fit ml-4 w-full justify-center items-center ">

                    <span className="py-2 px-3 border-2 rounded-md cursor-pointer" onClick={() => setstage(2)} >{leftArrow}</span>


                </div>
                </div>

                <div className="w-full flex justify-center items-center mr-[100px] text-xl capitalize font-medium">Add your Products</div>

            </div>



            <div className="w-full h-full bg-[#EFEFEF]" >
                <div className="flex flex-wrap h-full w-4/5 mx-auto py-8 justify-center ">
                    {
                        data.map((list, index) => {
                            return (
                                <div key={index} className=" transition-all flex flex-col w-[30%] mb-4 mx-2 cursor-pointer bg-white rounded-lg p-6 space-y-4 hover:shadow-lg" onClick={() => {


                                    list.type == "manually" ? setstage(1) : list.type == "importExcel" ? TriggertoRef() : setSelectList(index)

                                }} >
                                    <span className="flex mx-auto my-auto" >{list?.icon}</span>
                                    <span className="flex mx-auto my-auto" >{list?.label}</span>
                                    <input type='file' ref={RefFile} onChange={onChnageFile} className="hidden" accept=".xlsx, .xls" />
                                </div>
                            )
                        })
                    }
                </div>

            </div>
            <div>
                {
                    data[selectList] && <GetApiKey backwardAction={() => setSelectList(-1)} fieldId={fieldId} data={data[selectList]} giveKey={ApiKey} setApiKey={setApiKey} setStage={setstage} />
                }
            </div>
        </div>
    )

}