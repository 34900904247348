import React from "react";
import PrimaryButton from "../../Button1/primarybutton";
import { ShowInput } from "./ShowInput";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../store";
import { validateproduct } from "../../../actions/templates";

export const GetApiKey = ({
  title = "",
  reftype = null,
  defaultVaule = "",
  type = "",
  changeDefaultVaule = null,
  forwardAction = null,
  backwardAction = null,
  select = {},
  keys = [],
  validate,
  fieldId="",
  create,
  intergationStage = 0,
  list = [],
  setSelectList = 0,
  selectList,
  data = {},
  print = {},
  giveKey = {},
  setApiKey = null,
  setStage = null
}) => {
  // const { fieldId } = useSelector((state) => state.EditorFields[0]);

  const dispatch = useDispatch();

  const { fields, campaign_info } = useSelector((state) => ({ fields: state.fields, fieldsOrder: state.fieldsOrder, EcommerceFieldID: state.EcommerceFieldID, campaign_info: state.Campaign_info }))


  // const campaign_info = useSelector((state) => state.Campaign_info;
  const templateData = fields[fieldId];
  const Settings = templateData?.filedContent
  const manualList = Settings?.game_mainpage_json?.ManualList ?? [];
  const productSL = Settings?.game_resultcreen_json?.productSelectList ?? [];



  const updateCompain = async () => {

    let  add_info = {...campaign_info?.additional_info};
       
    if (!add_info[fieldId]) {
      add_info[fieldId] = {};
    }
    

    add_info[fieldId] = { product_integration: ( "product_integration" in add_info[fieldId]) ? [...add_info[fieldId]?.product_integration] : [] }

  
    
    // cons
    let isExistData = add_info[fieldId]?.product_integration.findIndex(list => list == String(data?.label).toLowerCase() );
    add_info[fieldId].product_integration = (isExistData < 0) ? [...add_info[fieldId].product_integration,String(data?.label).toLowerCase()] : add_info[fieldId].product_integration;
    
    let payload = {
      campaign_id:campaign_info?.Campaign_id,
      fieldId:fieldId,
      type:String(data?.label).toLowerCase(),
      credentials:giveKey,

    }

    const dataresp = await validateproduct(payload)

    dispatch( Actions.setAdditionalInfo({ additional_info: add_info}) );

    if(productSL.length === 0 ) {
      let dataObj = dataresp.data.products[0]
      dataObj.id  = String(data?.label).toLowerCase()+"_"+dataObj.id 
      dispatch(Actions.setAddProductSelectList({fieldId:fieldId,productData:dataresp.data.products[0]}));
      dispatch(Actions.setUpdateCampaign())

    }

    setApiKey({});
    setStage(2)
  };

  return (
    <div className="fixed top-0 left-0 flex w-full h-full bg-backgroundShadow" style={{ zIndex: "55" }} >
      <div className="relative w-2/5 h-fit flex space-y-4 bg-white m-auto  overflow-hidden rounded-2xl py-4 flex-col">
        <div
          className="w-full h-fit flex absolute justify-end top-4 right-4 cursor-pointer"
          onClick={() => backwardAction()}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3 0.709971C12.91 0.319971 12.28 0.319971 11.89 0.709971L6.99997 5.58997L2.10997 0.699971C1.71997 0.309971 1.08997 0.309971 0.699971 0.699971C0.309971 1.08997 0.309971 1.71997 0.699971 2.10997L5.58997 6.99997L0.699971 11.89C0.309971 12.28 0.309971 12.91 0.699971 13.3C1.08997 13.69 1.71997 13.69 2.10997 13.3L6.99997 8.40997L11.89 13.3C12.28 13.69 12.91 13.69 13.3 13.3C13.69 12.91 13.69 12.28 13.3 11.89L8.40997 6.99997L13.3 2.10997C13.68 1.72997 13.68 1.08997 13.3 0.709971Z"
              fill="#586474"
            />
          </svg>
        </div>
        <div className=" w-4/5 h-fit mx-auto space-y-4">
          <div className="space-y-4">
            <div className=" flex justify-center">{data?.icon}</div>
            <h2 className="text-xl text-center"> Connect with {data?.label}</h2>
          </div>
          <ShowInput data={data?.value} giveKey={giveKey} updateCompain={() => updateCompain()} />
        </div>
      </div>
    </div>
  );
};
