import React, { useEffect } from 'react'
import InputWithLabel from '../input/inputWithLabel'
import PrimaryButton from '../Button1/primarybutton'
import { ActivateAppsumoAccount, sendResetpasswordMail } from '../../actions/user'
import toast from 'react-hot-toast'
import { CgSpinner } from 'react-icons/cg'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PasswordValid } from './PasswordValid'
import { REGX } from '../../content/regx'

export const AppsumoResetpasswordEmail = () => {

    const UseParam = useParams();

    const navigate = useNavigate()

    const [EmailData, setEmailData] = useState('')

    const [password, setPassword] = useState('')

    const [loading, setloading] = useState(false)

    const [passwordCheckList, setPasswordCheckList] = useState({
        charNumberValid: false,
        lowercaseValid: false,
        numberValid: false,
        specialCharValid: false,
        uppercaseValid: false
    })

    const onPasswordChange = (val) => { 
        setPassword(val)
        setPasswordCheckList({
            uppercaseValid: REGX.upperCase.test(val) ? true : false,
            lowercaseValid: REGX.lowerCase.test(val) ? true : false,
            charNumberValid: val.length >= 8 ? true : false,
            numberValid: REGX.number.test(val) ? true : false,
            specialCharValid: REGX.specialCharacters.test(val) ? true : false,
        })
    }

    useEffect(() => {
        // console.log(UseParam.email)
        if (UseParam?.email) setEmailData(UseParam.email)
    }, [UseParam])

    const ActivateAccount = async () => {


        try {


            if ((password?.length >= 0) && (password?.length < 8)) return toast.error('Invalid Password');

            if ((password?.length == 0)) return toast.error('Please Enter Password')

            setloading(true)

            const ResetEmail = await ActivateAppsumoAccount(password, UseParam?.token)

            if (ResetEmail) toast?.success("Plan Activated")

            navigate('/login')

            setloading(false)
        } catch (err) {
            setloading(false)

        }

    }

    return (

        <section className='w-1/2 flex h-full' >

            <div className="w-9/12 flex flex-col space-y-4 p-11 py-14 2xl:py-18 bg-white rounded-xl my-auto" >

                <h3 className='font-medium' >Appsumo Account Activation</h3>

                <div className={`flex flex-col space-y-2`}>
                    <label htmlFor={'Email'} className={`  capitalize`}>Email</label>
                    <input type={'email'} required={true} className={`gz-basic-input mb-3 h-10 w-full block text-14px text-black appearance-none bg-white  focus:border-primary  text-primary-gray  placeholder-gray-600 border rounded-8px  px-14px leading-5 placeholder-opacity-40  border-secondar `} name={'Email'} placeholder={'Email'} disabled={true} onChange={onchange} value={EmailData} />
                </div>

                {/* <InputWithLabel Placeholder='Email' label='Email' textvalue={EmailData} onchange={(e)=>setEmailData(e.target.value)} /> */}

                <InputWithLabel Placeholder='password' label='password' type='password' onchange={(e) => onPasswordChange(e.target.value)} />

                { password.length>0 && <PasswordValid {...passwordCheckList} ></PasswordValid> }


                <PrimaryButton text={'Activate your Account'} icon={
                    loading ? <CgSpinner className="w-5 h-5 animate-spin text-white" /> : null
                } disabled={loading} OnClick={ActivateAccount} />

            </div>

        </section>
    )

}