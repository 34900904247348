import React, { useState } from "react";
import InputWithLabel from "./input/inputWithLabel";
import { Imageinput } from "./input/imageinput";
import InputRadiocheck from "./input/radiocheck";
import PrimaryButton from "./Button1/primarybutton";
import Input from "./input/input";
import { useDispatch } from "react-redux";
import { Actions } from "../store";
import DropMenu from "./input/dropmenu";
import DefaultDropMenu from "./input/droplist";
import { MediaPopup } from "./popup/Mediapopup";
import { ConnectionImage } from "./connectionimage";
import TextareaInput from "./input/textarea";
import { TooltipData } from "../content/toolTip";

export const ConnectionContainer = ({
    Index,
    fieldId = "",
    question = "",
    imgSrc = [],
    answer = "",
    answerImg,
    invalidAnswerImg,
    invalidanswerContent = "ooops!. Wrong Answer. Try once again."
}) => {

    const DisPatch = useDispatch()

    const [ShowMedia, setShowMidia] = useState(false);
    const [ShowInner, setShowInner] = useState(0);
    const [isInnerind, setIsInnerInd] = useState(false);
    const [mediaCCType, setMediaCCType] = useState("imageUrl");

    // const findIndex = options.findIndex(resData => resData === answer)

    // const dropmenuList = optionType === "yerorno" ? ['Yes', 'No'] : options

    const DefaultCC = (value, FID = fieldId, eage, optionIndex = 0) => {
        const setContent = {
            key: FID,
            node: 'game_mainpage_json',
            child: 'connectionList',
            edge: eage,
            QueIndex: Index,
            optionIndex: optionIndex,
            value: value,
        }
        DisPatch(Actions.setQuizQuestionCC(setContent))
    }

    const DefaultimgCC = (value, type, FID = fieldId, optionIndex = 0) => {
        const setContent = {
            key: FID,
            node: 'game_mainpage_json',
            child: 'connectionList',
            edge: type,
            QueIndex: Index,
            optionIndex: optionIndex,
            value: value,
        }
        DisPatch(Actions.setQuizQuestionCC(setContent))
    }

    const DirectContentChange = (image, fieldType, ineerIndex, add = false, remove = false) => {
        const setContent = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_mainpage_json',
            child: "connectionList",
            edge: "imageUrl",
            QueIndex: Index,
            optionIndex: ineerIndex,
            value: image,
            add: add, remove: remove
        }

        DisPatch(Actions.setConnetionCC(setContent))

        if (ShowMedia) setShowMidia(false)

    }

    const QuesRangechange = (type) => {


        const setContent = {
            key: fieldId,
            node: 'game_mainpage_json',
            child: 'connectionList',
            position: Index,
            type: type
        }

        DisPatch(Actions.setQuizQuestionRA(setContent))
    }

    const TriggetMedia = ( type, innerindex, index ) =>{

        
        if(innerindex) setShowInner(index)

        setIsInnerInd(innerindex);
        setMediaCCType(type);
        setShowMidia(true)

    }

    const toolTip = TooltipData()

    const OnClickCI = (index) => {
        setShowInner(index)
        setShowMidia(true)
    }


    const removeList = (e, index) => {
        e.stopPropagation()
        DirectContentChange('', fieldId, index, false, true)
    }


    return (
        <>
            {ShowMedia && <MediaPopup cancel={setShowMidia} innerArr={isInnerind} ineerIndex={ShowInner} fieldType={mediaCCType} OnSelect={ isInnerind ? DirectContentChange : DefaultimgCC } />}

            <div key={Index} className={`w-full relative flex  flex-col space-y-2 border-2  h-fit delay-400 duration-700 top-0 p-4 rounded-xl hover:shadow-md hover:-top-0 hover:border-primary`}  >

                <div className="flex justify-between">
                    <p className="text-base font-medium" >Question - {Index + 1}</p>
                    <div className="flex space-x-2">
                        {
                            toolTip.map((list, index) => {
                                return (
                                    <p key={index} className="p-2 rounded-md border-1 hover:bg-gray-200 cursor-pointer" onClick={() => QuesRangechange(list.type)} >{list.icon}</p>
                                )
                            })
                        }
                    </div>
                </div>


                {/* Question Editor */}
                <div className="flex flex-col space-y-3">

                    {/* Question */}
                    <InputWithLabel label="Question" textvalue={question} onchange={(e) => DefaultCC(e.target.value, fieldId, 'question')} />

                    <label className="capitalize" >Connection images</label>

                    {
                        imgSrc.map((listimg, index) => {
                            return (<ConnectionImage Index={Index} key={Index} imagSrc={listimg} onClickEVent={() => TriggetMedia('imagSrc', true, index) } deleteEvent={(e) => removeList(e, index)} />)

                        })
                    }

                    <PrimaryButton text={'Add image'} width="w-32" height="h-12" backgroundColor="bg-primary-lightblue" border="border-1 border-primary" textcolor="text-primary" OnClick={() => DirectContentChange('https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/carrot.png', fieldId, 0, true)} />

                    {/* Question type */}

                    <div className="space-y-2" >

                        <label className="capitalize" >Connection Answer and Banner image</label>

                        <div className="flex space-x-2 border-1 rounded-lg " >

                            <TextareaInput height="h-20" labelTextWeight="hidden" spaceY="w-full" margin="" border="" textvalue={answer} onchange={(e) => DefaultCC(e.target.value, fieldId, 'answer')} />

                            <div className="w-20 h-20 transition-all duration-300 rounded-md border-1 p-1 cursor-pointer hover:bg-gray-200" onClick={()=>TriggetMedia('answerImg',false)} >
                                <img src={answerImg? answerImg : "/asset/images/emptyimage.png"} art="BannerImg" title="BannerImg" className="w-full h-full rounded-md object-cover" />
                            </div>

                        </div>

                    </div>

                    <div className="space-y-2" >

                        <label className="capitalize" >Invalid Answer notify content</label>

                        <div className="flex space-x-2 border-1 rounded-lg " >

                            <TextareaInput height="h-20" labelTextWeight="hidden" spaceY="w-full" margin="" border="" textvalue={invalidanswerContent} onchange={(e) => DefaultCC(e.target.value, fieldId, 'invalidanswerContent')} />

                            <div className="w-20 h-20 transition-all duration-300 rounded-md border-1 p-1 cursor-pointer hover:bg-gray-200" onClick={() => TriggetMedia('invalidAnswerImg',false)} >
                                <img src={invalidAnswerImg ? invalidAnswerImg :"/asset/images/close-circle.png"} art="NotifyBanner" title="Notify Banner" className="w-full h-full rounded-md object-cover" />
                            </div>

                        </div>

                    </div>

                    {/* <TextareaInput label="Invalid Answer notify content" textvalue={invalidanswerContent} onchange={(e) => DefaultCC(e.target.value, fieldId, 'invalidanswerContent')} /> */}

                </div>
            </div>
        </>
    )
}