import React from "react";
import { useState } from "react";

const InputRadiocheck = ({ listarray = [], display = 'flex-col space-y-4', label = 'Label name',
    labelTextsize = '',
    labelTextWeight = '',
    textValue='center',
    onchange=null,
    fieldType='',
    fieldId='',
    roundStyle="",
    textStyle=""
 }) => {
    const activeIndex = listarray?.findIndex(list => list === textValue );
    const [checked, SetChecked] = useState(activeIndex < -1 ? 0 : activeIndex);

    const Statechange = index => {
        
        SetChecked(index);
        onchange(listarray[index],fieldType,fieldId)
    }

    return (
        <div className="space-y-2">
            <label className={` ${labelTextsize} ${labelTextWeight} capitalize`}>{label}</label>
            <div className={`w-full flex ${display}`}>
                {listarray.map((list, index) => {
                    return (

                        <div key={index} className="flex w-full space-x-2 " onClick={() => Statechange(index)} >
                            <div className={`my-auto cursor-pointer  ${(checked === index) ? "border-primary" : roundStyle} rounded-full w-5  h-5 border-1`}  >
                                <div className={` rounded-full w-2.5 h-2.5 m-1  ${(checked === index) ? "bg-primary" : ""}`}></div>
                            </div>
                            <p className={`cursor-pointer ${textStyle}`}  >{list}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )

}

export default InputRadiocheck;