import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingSpinner } from "../../Loading";
import { Actions } from "../../../store";



export const FindPairV2 = ({ obj = {}, fieldID = '' , StartTime=false, prview = false, setActionCount =null ,GameFinsh=null,setScore=null,position=0,  setLoadContent=null}) => {

    const { Fields, device } = useSelector((state) => ({ Fields: state.fields, device: state.device }));

    const templateData = Fields[fieldID];
    const Settings = templateData?.filedContent
    const mainPageContent = Settings.game_mainpage_json;
    const DisPatch = useDispatch()

    const Layout = mainPageContent.layputRange;
    const LayoutRange = Layout.split('x')
    const noOfCards = LayoutRange[0] * LayoutRange[1];

    const [cardArrangement, setCardArrangement] = useState([]);
    const [checkPair, setCheckPair] = useState('');
    const [active, setActive] = useState([]);
    const [matchedPair, setMatchedPair] = useState([]);
    const [trigger, settrigger] = useState(false)

    useEffect(() => {
        if (mainPageContent.pairItems.length == noOfCards) gameSetup();
    }, [mainPageContent.pairItems, trigger])


    useEffect (() => {
        if(StartTime){
            setMatchedPair([])
            setActive([])
            settrigger(!trigger)
        }
    },[StartTime])


    // Init GAme
    const gameSetup = () => {

        setLoadContent(true)

        let card = [];
        while (card.length < noOfCards) {
            let RandomNumber = Math.round(Math.random() * noOfCards);
            if (card.indexOf(RandomNumber) < 0 && RandomNumber != noOfCards) card.push(RandomNumber);
        }

        setCardArrangement(card);
        setLoadContent(false);

    }

    //Game card Active Or deactive trigger

    const flidcard = (e, value, index) => {
        
        setActive(obj => [...obj, index]);
        setActionCount(obj => obj + 1);

        if (checkPair === '') setCheckPair(value)

        else {
            if (checkPair === value && active.indexOf(index) < 0) {   setMatchedPair(obj => [...obj, value]); setScore(obj => obj+1); }

            setTimeout(() => {
                if ((matchedPair.length + 1) === noOfCards / 2) { GameFinsh(true); }
                setCheckPair('')
                setActive([])
            }, 700);
        }

    }
 

    return (


        <div className={((device==="mobile")?"gap-[10px]":"gap-6")+`  m-auto flex  flex-col ${ device==="mobile" && ("w-[90%] h-[60%]") }  ${ device==="tab" && ("w-[85%] h-[75%]") }   ${ device==="desktop" && ("w-[75%] h-[85%]") } `} onClick={(e)=>{e.stopPropagation();DisPatch(Actions.setActiveFieldside({filedId:fieldID,index:position,ActiveScreen:"main"}))}}  >
            <div className={((device === "mobile") ? "gap-[1rem] " : "gap-[2rem] ") + `grid min-h-[250px] min-w-[250px] w-full h-full m-auto`} id="gamepanel" style={{ gridTemplateColumns: `repeat(` + LayoutRange[0] + `,minmax(0,1fr)`, gridTemplateRows: `repeat(` + LayoutRange[1] + `,minmax(0,1fr)` }} >
            {
                cardArrangement.map((list, index) => {

                    let CardObj = mainPageContent.pairItems[list]
                    // gamecard
                    if (CardObj) return (
                        <div key={index} style={{backgroundColor:Settings?.game_mainpage_json?.filpCardBgColor || "#DCE0E4",transformStyle:"preserve-3d"}} class={`relative transform  scale-100 ${device === 'mobile' ? "gamecardmobile" : ""} newgamecard transition-all bg-red-400 duration-700  ${!mainPageContent?.filpstatus ? 'activecard' : ''}  ${(matchedPair.indexOf(CardObj.pairValue) > -1 || active.indexOf(index) > -1) ? !mainPageContent?.filpstatus ? 'border-4 border-green-400' : 'activecard ' : 'border-0'} `} data-active="0" onClick={(e) => flidcard(e, CardObj.pairValue, index)}>
                            <div className={`gamecardfront  ${device === 'mobile' ? "gamecardFBmodile" : ""}
                               ${!Settings?.filpstatus && (matchedPair.indexOf(CardObj.pairValue) > -1 || active.indexOf(index) > -1) ? "  " : ""} `} >
                                {/* <span class="gamecardinner m-auto "> */}
                                <span className={`flex m-auto ${device==="mobile" ? "p-2" : "p-3"}  max-w-full max-h-full`}>
                                    <img src={mainPageContent?.filpCardBgImage === '' ? '/asset/images/gameblock/icon.png' : mainPageContent?.filpCardBgImage} alt="test" className="w-full h-auto max-h-full p-2 rounded-[20px]" />
                                </span>
                            </div>
                            <div className={`gamecardback w-full ${device === 'mobile' ? "gamecardFBmodile" : ""}  `}>
                                {/* <span class="gamecardinner m-auto"> */}
                                <span className={`flex m-auto max-h-full ${device==="mobile" ? "p-2" : "p-3"} max-w-full overflow-hidden`}>
                                    {CardObj.type === 'image' ? <img src={CardObj.src} alt="test" className="" /> :
                                        <p className={((device==="mobile")?"text-sm":"text-base ")+' w-fit max-w-full max-h-full  text-center m-auto outline-none resize-none overflow-hidden   font-medium text-ellipsis'} >{CardObj.content}</p>}
                                </span>
                            </div>
                        </div>
                    )

                })
            }

            </div>
        </div>


    )
}