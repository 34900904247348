import React from "react"; 
import { useSelector } from "react-redux";
import { MobileWeelSideTab } from "./MobileWeelSideTab";
import { DesktopWheelSideTab } from "./DesktopWheelSideTab";
import "./style.css"

export const SpinWheelSideTab = ({ obj = {}, fieldID = '', StartTime = false, prview = false, setActionCount = null, GameFinsh = null ,position=0, toShowTips=false, setTriggerField=null}) => { 

const {  device } = useSelector((state) => ({  device: state.device }));

  return ( <div className="w-full h-full relative" id={`gameFrameBorder${fieldID}`}>
      {device==="mobile" ? 
            <MobileWeelSideTab   obj={obj} fieldID = {fieldID} StartTime = {StartTime} prview = {prview} setActionCount = {setActionCount} GameFinsh = {GameFinsh} position={position} toShowTips={toShowTips} setTriggerField={setTriggerField}/> :
            <DesktopWheelSideTab    obj={obj} fieldID = {fieldID} StartTime = {StartTime} prview = {prview} setActionCount = {setActionCount} GameFinsh = {GameFinsh} position={position} toShowTips={toShowTips} setTriggerField={setTriggerField}/>
      }</div>);
  }; 
  