import PrimaryButton from "../Button1/primarybutton";
import Input from "../input/input";

export function SimpleCopy(props) {
  return (
    <div
      className="fixed top-0 left-0 flex w-full h-full bg-backgroundShadow"
      style={{
        zIndex: "55",
      }}
    >
      <div className={`p-4 w-[350px] rounded-lg bg-white m-auto space-y-4  `}>
        <p className="capitalize text-lg font-medium">Copy the campaign URL</p>
        <Input textvalue={props.renderEngineurl} disabledinput={true} />

        <div className="flex justify-end space-x-2">
          <PrimaryButton
            buttontype={"secondarybutton"}
            width={"w-24"}
            textcolor="text-black" border="border-2"
            text={"cancel"}
            OnClick={() => {
              props.setshoWCpy(false);
            }}
          />
          <PrimaryButton text={"Copy"} width={"w-24"} OnClick={props.copy} />
        </div>
      </div>
    </div>
  );
}
