export var dashboard_flow=[    
    {
        element: ".step-leftPanel-dashboard",
        title: "Useful analytics in the dashboard",
        intro: "Gain insight into the comprehensive metrics of your campaigns, views, clicks, and engagement responses.",
    },  
    {
        element: ".step-leftPanel-templateGalary",
        title: "Templates you may like",
        intro: "Options: Games, Quiz, Ecommerce, Spinwheel, Poll, Calculator, Survey.",
    },  
    {
        element: ".step-leftPanel-myWorkSpace",
        title: "My Workspace",
        intro: "Manage team members with varying access levels in the workspace, offering real-time insights into campaign metrics and customizable display options.",
    },  
    {
        element: ".step-leftPanel-allCampaign",
        title: "All Campaigns",
        intro: "Campaign features include real-time status and customizable insights on views, clicks, and responses, with options like adjusting rows per page for convenience.",
    },  
    {
        element: ".step-leftPanel-sharedWorkspace",
        title: "Shared Workspace",
        intro: "A shared workspace facilitates real-time collaboration for multiple users on a unified platform, promoting seamless teamwork and communication.",
    }, 
    {
        element: ".step-leftPanel-createCampaign",
        title: "Create a compelling campaign",
        intro: "This feature enables quick development of games, quizzes, spinwheels, product recommendations, calculators, polls, and surveys for diverse interactive experiences.",
    },  
                    

]
