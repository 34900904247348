import React, { useEffect, useState, useRef } from "react";
import { mixArray, sleep, isEmpty, isNotEmpty } from "../../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../store";

export const RearrangeProducts = ({ obj = {}, fieldID = '', StartTime = false, prview = false, setActionCount = null, GameFinsh = null, setScore = null, position = 0, setPreStartTime, gameInit, setLoadContent }) => {
  const { Fields, device } = useSelector((state) => ({ Fields: state.fields, device: state.device }));

  //   const {mainPageContent,setActionCount,GameFinsh,setLoadContent} = data

  const DisPatch = useDispatch()
  const ContainerRef = useRef(null)
  const templateData = Fields[fieldID];
  const Settings = templateData?.filedContent
  const mainPageContent = Settings.game_mainpage_json;
  const [trigger, settrigger] = useState(false)

  const LayoutRange = mainPageContent?.layputRange?.split("x");
  const noOfCards = LayoutRange[0] * LayoutRange[1];
  const [cardArrangement, setCardArrangement] = useState([]);
  const [selected, setSelect] = useState([null, null]);
  const [selectCount, setselectCount] = useState(0);
  const [isShowing, setIsShowing] = useState(true);
  const [doActivate, setDoActive] = useState("activecard")
  const [localInit, setlocalInit] = useState(false)

  const [width, setWidth] = useState('80%')
  const [height, setHeight] = useState('90%')

  useEffect(() => { setLoadContent(false); }, [])

  useEffect(() => { if (gameInit) setPreStartTime(true) }, [gameInit])

  useEffect(() => {

    if (StartTime) {

      // alert("start")
      if (mainPageContent?.pairItems.length === noOfCards) setCardArrangement([...Array(noOfCards).keys()])

      settrigger(!trigger)

      if (StartTime) gameSetup();

    }

  }, [StartTime])


  useEffect(() => {

    gameSetup();

    if (ContainerRef.current.offsetWidth > ContainerRef.current.offsetHeight) setWidth(`${ContainerRef.current.offsetHeight}px`)
    if (ContainerRef.current.offsetWidth < ContainerRef.current.offsetHeight) setHeight(`${ContainerRef.current.offsetWidth}px`)

  }, [mainPageContent, device, trigger])


  const gameSetup = async () => {
// alert(3)
    // setlocalInit(true);
    setIsShowing(true);

    if (mainPageContent?.pairItems.length !== noOfCards) return
    setCardArrangement([...Array(noOfCards).keys()])

    if (StartTime) {

      setDoActive("")
      // await sleep(400);

      setDoActive("activecard")

      // await sleep(Settings?.showcardtime * 1000);

      setDoActive("")

      await sleep(1000);

      setselectCount(0); setScore(0);

      setCardArrangement(mixArray([...Array(noOfCards).keys()]))

      setDoActive("activecard")

    }

    setIsShowing(false)

  };


  const flidcard = (e, value, index) => {
    if (isShowing) return;
    setActionCount((obj) => obj + 1);
    let temp = selected;
    if (temp[0] === index) temp = [null, null]
    else (isEmpty(temp[0])) ? temp[0] = [index] : temp[1] = [index];
    setSelect(temp); setselectCount(o => o + 1)
  };


  useEffect(() => {
    if (isNotEmpty(selected[0]) && isNotEmpty(selected[1])) {
      let cardArrangementTemp = cardArrangement;
      let temp = cardArrangementTemp[selected[0]]
      cardArrangementTemp[selected[0]] = cardArrangementTemp[selected[1]]
      cardArrangementTemp[selected[1]] = temp
      setCardArrangement(cardArrangementTemp)
      setSelect([null, null])

      let isCorrect = true, tempScore = 0; // Checking answer
      cardArrangement.map((val, inx) => { if (inx !== val) isCorrect = false; else tempScore += 1 })
      if (isCorrect) GameFinsh(true)
      setScore(tempScore)
    }
  }, [selected, selectCount])


  return (
    // h-[85%]
    <div class={`  ${device === "mobile" && ("w-[90%] h-[60%]")}  ${device === "tab" && ("w-[85%] h-[75%]")}   ${device === "desktop" && ("w-[75%] h-[85%]")} 
     m-auto  flex  flex-col `} style={{ aspectRatio: `${LayoutRange[0]}/${LayoutRange[1]}` }} id="gamepanel" ref={ContainerRef} onClick={(e) => { e.stopPropagation(); DisPatch(Actions.setActiveFieldside({ filedId: fieldID, index: position, ActiveScreen: "main" })) }} >
      <div class={((device === "mobile") ? "gap-[0.5rem] " : "gap-[1.1rem] ") + `grid min-h-[250px] min-w-[250px] w-full h-full  m-auto 
       `} id="gamepanel" ref={ContainerRef} style={{ gridTemplateColumns: `repeat(` + LayoutRange[0] + `,minmax(0,1fr)`, gridTemplateRows: `repeat(` + LayoutRange[1] + `,minmax(0,1fr)` }} >


        {

          (cardArrangement?.map((list, index) => {

            let CardObj = mainPageContent?.pairItems[list];

            if (CardObj)
              //  md:min-h-[184px]  
              //  min-h-[80px] 
              //  lg:min-h-[194px]  
              // gamecard
              //                 md:p-[5rem] sm:p-[4rem] sm:min-h-[144px]  
              //lg:p-[6rem] 
              return (

                <div key={index} className={`
                relative transition-all scale-100 transform ${(selected[0]?.[0] === index) ? " scale-90 " : ""}   `} data-active="0"  >

                  <div key={index} style={{ backgroundColor: mainPageContent?.filpCardBgColor || "#DCE0E4", transformStyle: "preserve-3d" }} className={`
                  relative transform
                  rounded-[16px!important] ${device === "mobile" ? "p-[1.5rem!important]" : "p-[2rem]"}  
                  newgamecard h-[100%]
                    transition-all duration-700  ${(selected[0]?.[0] === index) ? " scale-50 " : ""}   ${doActivate}`} data-active="0" onClick={(e) => flidcard(e, CardObj.pairValue, index)}  >

                    <div className={`gamecardfront    flex m-auto max-h-full  ${device === "mobile" ? "p-2" : "p-4"}  max-w-full overflow-hidden`}  >

                      <span className="flex m-auto max-h-full">

                        <img draggable={false} src={mainPageContent?.filpCardBgImage === "" ? "/asset/images/gameblock/icon.png" : mainPageContent?.filpCardBgImage} alt="test" className="noselect" />
                      
                      </span>
                    
                    </div>

                    <div className={`gamecardback w-full rounded-[16px!important] `} >

                      <span className={`gamecardinner m-auto flex  max-h-full  ${device === "mobile" ? "p-2" : "p-4"}   max-w-full overflow-hidden`}>

                        {/* <span className="gamecardinner m-auto flex  max-h-full p-[1.75rem] overflow-hidden"> */}
                        {CardObj.type === "image" ? (

                          <img draggable={false} src={CardObj?.src} alt="test" className=" noselect" />) : (
                         
                            <p className={"w-fit max-w-full max-h-full  text-center m-auto outline-none resize-none overflow-hidden capitalize text-sm sm:text-lg font-medium noselect text-ellipsis"} >{CardObj.content}  </p>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              );
          })
          )
        }
      </div>
    </div>
  );
};

