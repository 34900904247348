import { isEmpty } from "./helpers";

export const TUTORIAL_STORAGE='engageTutorial'

export function checkAndGetTutorial(){
let basicTemplate=JSON.stringify({
    "dashboard":false,
    "templateGalary":false,
    "templatePreview":false,
    "generateAiBtn":false,
    "threeScreenEditTab":false,
    "twoScreenEditTab":false,
    "calFormulaBtn":false,
    "logicJumpBtn":false,
    "filterBtn":false,
    
    "campaignEdit":{    
        "topnave":false,
        "product":false,
        "share":false,
        "design":{
            "overAll":false,
            "device":false,
            "backGroundImage":false,     
            "addNewField":false,
            "editDesignBtn":false,
        },

          
    }
}
)
    const storedData = localStorage.getItem(TUTORIAL_STORAGE)
    if (isEmpty(storedData))  localStorage.setItem(TUTORIAL_STORAGE, basicTemplate);
    else basicTemplate=localStorage.getItem(TUTORIAL_STORAGE) || {}
    
    return JSON.parse(basicTemplate);
}
