import { useEffect, useRef, useState } from "react";
import Layout from "..";
import { SettingNav } from "../../components/Setting/SettingNav";
import { Myaccount } from "../../components/Setting/pages/Myaccount";
import { Doamin } from "../../components/Setting/pages/Doamin";
import { Team } from "../../components/Setting/pages/Team";
import { Billing } from "../../components/Setting/pages/Billing";
import { ApiKey } from "../../components/Setting/pages/ApiKey";
import { Main } from "../../components/Setting/pages/Domain/Main";
import { useSelector } from "react-redux";
import { AppsumoBilling } from "../../components/Setting/pages/appsumoBilling";
import { BundleBilling } from "../../components/Setting/pages/BundleBilling";
 

const slide = [
  {
    name: "My Account",
    src: "#myaccount",
    svg: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 5C13.66 5 15 6.34 15 8C15 9.66 13.66 11 12 11C10.34 11 9 9.66 9 8C9 6.34 10.34 5 12 5ZM12 19.2C9.5 19.2 7.29 17.92 6 15.98C6.03 13.99 10 12.9 12 12.9C13.99 12.9 17.97 13.99 18 15.98C16.71 17.92 14.5 19.2 12 19.2Z"
          fill="currentColor"
        ></path>
      </svg>
    ),
  },
  {
    name: "Domain",
    src: "#domain",

    svg: (
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 512 512"
        class="w-5 h-5"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"></path>
      </svg>
    ),
  },
  {
    name: "Team",
    src: "#team",

    svg: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 9C11.3261 9 12.5979 9.52678 13.5355 10.4645C14.4732 11.4021 15 12.6739 15 14V20H5V14C5 12.6739 5.52678 11.4021 6.46447 10.4645C7.40215 9.52678 8.67392 9 10 9ZM3.288 12.006C3.12886 12.5428 3.03485 13.0968 3.008 13.656L3 14V20H9.13169e-08V15.5C-0.000196807 14.6376 0.318028 13.8054 0.893635 13.1632C1.46924 12.521 2.2617 12.1139 3.119 12.02L3.289 12.006H3.288ZM16.712 12.006C17.6019 12.0602 18.4376 12.452 19.0486 13.1012C19.6596 13.7505 19.9999 14.6084 20 15.5V20H17V14C17 13.307 16.9 12.638 16.712 12.006ZM3.5 6C4.16304 6 4.79893 6.26339 5.26777 6.73223C5.73661 7.20107 6 7.83696 6 8.5C6 9.16304 5.73661 9.79893 5.26777 10.2678C4.79893 10.7366 4.16304 11 3.5 11C2.83696 11 2.20107 10.7366 1.73223 10.2678C1.26339 9.79893 1 9.16304 1 8.5C1 7.83696 1.26339 7.20107 1.73223 6.73223C2.20107 6.26339 2.83696 6 3.5 6V6ZM16.5 6C17.163 6 17.7989 6.26339 18.2678 6.73223C18.7366 7.20107 19 7.83696 19 8.5C19 9.16304 18.7366 9.79893 18.2678 10.2678C17.7989 10.7366 17.163 11 16.5 11C15.837 11 15.2011 10.7366 14.7322 10.2678C14.2634 9.79893 14 9.16304 14 8.5C14 7.83696 14.2634 7.20107 14.7322 6.73223C15.2011 6.26339 15.837 6 16.5 6V6ZM10 0C11.0609 0 12.0783 0.421427 12.8284 1.17157C13.5786 1.92172 14 2.93913 14 4C14 5.06087 13.5786 6.07828 12.8284 6.82843C12.0783 7.57857 11.0609 8 10 8C8.93913 8 7.92172 7.57857 7.17157 6.82843C6.42143 6.07828 6 5.06087 6 4C6 2.93913 6.42143 1.92172 7.17157 1.17157C7.92172 0.421427 8.93913 0 10 0V0Z"
          fill="currentColor"
        ></path>
      </svg>
    ),
  },
  {
    name: "Billing",
    src: "#billing",

    svg: (
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 576 512"
        class="w-5 h-5"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M527.9 32H48.1C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48.1 48h479.8c26.6 0 48.1-21.5 48.1-48V80c0-26.5-21.5-48-48.1-48zM54.1 80h467.8c3.3 0 6 2.7 6 6v42H48.1V86c0-3.3 2.7-6 6-6zm467.8 352H54.1c-3.3 0-6-2.7-6-6V256h479.8v170c0 3.3-2.7 6-6 6zM192 332v40c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v40c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12z"></path>
      </svg>
    ),
  },
  {
    name: "Api Key",
    src: "#apikey",
    svg: (
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 576 512"
        class="w-5 h-5"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M527.9 32H48.1C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48.1 48h479.8c26.6 0 48.1-21.5 48.1-48V80c0-26.5-21.5-48-48.1-48zM54.1 80h467.8c3.3 0 6 2.7 6 6v42H48.1V86c0-3.3 2.7-6 6-6zm467.8 352H54.1c-3.3 0-6-2.7-6-6V256h479.8v170c0 3.3-2.7 6-6 6zM192 332v40c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12zm192 0v40c0 6.6-5.4 12-12 12H236c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12z"></path>
      </svg>
    ),
  },
];

export const SettingIndex = () => {

  const { userinfo } = useSelector(state => ({userinfo:state.userPlan}))

  const gateway  = userinfo?.gateway ?? "local"
  const plan  = userinfo?.plan ?? "free"

useEffect(()=>{
  const searchParams = new URLSearchParams(window.location.search);
  const target = searchParams.get('target');

 handleMenuItemClick(String(target));
              setActiveSection(String(target));
},[])
  
  const [activeSection, setActiveSection] = useState("myaccount");
  const scrollContainerRef = useRef(null);

  const handleScroll = () => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    const scrollPos = scrollContainer.scrollTop;
    const sections = ["myaccount", "domain", "team", "billing", "apikey"];

    for (const section of sections) {
      const element = document.getElementById(section);
      if (element) {
        const elementPos = element.offsetTop - 100;
        const elementHeight = element.offsetHeight - 100;

        if (scrollPos >= elementPos && scrollPos < elementPos + elementHeight) {
          setActiveSection(section);
        }
      }
    }
  };

  useEffect(() => {
    // var scrollContainer= scrollContainerRef.current;
    //  if (!scrollContainer) return;
    // scrollContainer.addEventListener("scroll", handleScroll);
    // return () => {
    //   scrollContainer.removeEventListener("scroll", handleScroll);
    // };
  }, []);

  const handleMenuItemClick = (section) => {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setActiveSection(section);
    }
  };

  return (
    <Layout sidebarView={true} padding=" flex flex-col" bgcolor={"bg-[#F5F5F5]"}>
      <div
        className={`flex w-full  justify-between bg-white py-2 border-b-2 top-0 `}
      >
        {slide.map((i, k) => (
          <div
            onClick={() => {
              handleMenuItemClick(String(i?.src).split("#")[1]);
              setActiveSection(String(i?.src).split("#")[1]);
            }}
            key={k}
            className={` mx-auto  px-2 flex space-x-3 w-[190px] pb-3 cursor-pointer py-4 ${
              String(i?.src).split("#")[1] === activeSection
                ? "border-primary text-primary border-b-2"
                : ""
            }`}
          >
            <div className="flex mx-auto space-x-3">
              {i?.svg}
              <div>{i["name"]}</div>
            </div>
          </div>
        ))}
      </div>
      
      <div   ref={scrollContainerRef } onScroll={handleScroll} className='flex  w-full h-full my-auto no-scrollbar px-20 py-8 overflow-y-auto list-industry shadow-md rounded-lg  '>
      
      <div  className="flex flex-col h-fit  w-full space-y-4 py-4  "
      >


        <div id="myaccount" className=" relative w-full mx-auto ">
          <Myaccount />
        </div>
        <div id="domain" className=" relative w-full mx-auto ">
          <Main />
        </div>
        <div id="team" className=" relative w-full mx-auto ">
          <Team />
        </div>
        { ( ((gateway !== "Bundle") && (gateway !== "appsumo")) || ((gateway === "appsumo")&&(plan === 'free'))|| (gateway === "stripe") ) && <div id="billing" className=" relative w-full mx-auto ">
          <Billing />
        </div> }
        { ((gateway === "appsumo")&&(plan !== 'free')) && <div id="billing" className=" relative w-full mx-auto ">
          <AppsumoBilling />
        </div> }
        { ((gateway === "Bundle")) && <div id="billing" className=" relative w-full mx-auto ">
          <BundleBilling />
        </div> }
        <div id="apikey" className=" relative w-full mx-auto ">
          <ApiKey />
        </div>
      </div>
      </div>
    </Layout>
  );
};