import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditorTooltip } from "../Editor/common/editortooltip";
import { isEmpty } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { Actions } from "../../store";

export const ButtonBlock = ({ fieldID = '', position ,priview=false }) => {

    const { Fields, campaignSettings } = useSelector((state)=>({ Fields:state.fields, campaignSettings:state.campaignSettings }));

    const DisPatch = useDispatch();

    const { fontSize } = campaignSettings;

   
    const ButtonSize = {
        "small": "text-[12px]",
        "medium": "text-sm",
        "large": "text-lg"
    }

    const ButtonContent = Fields[fieldID]; 

    const filedContent = ButtonContent?.filedContent

    const Navigate = useNavigate()

    return (

        <div id={"gameFrameBorder"+fieldID}  className={`relative flex flex-col py-4 group `} onClick={()=>{DisPatch(Actions.setActiveFieldside({filedId:fieldID,index:position,}))}}>

            {!priview &&<EditorTooltip position={position}/>}

            <button className={`flex mx-auto font-medium ${ ButtonSize[fontSize] } W-fit py-3 px-4 border-2 `} 

            onClick={() => window.location.href = filedContent?.RedirUrl === "" ? "https://gozen.io" : filedContent?.RedirUrl }
            
            style={{
                background:`${filedContent?.BgColor}`,
                borderColor:`${filedContent?.BorderColor}`,
                borderRadius:`${filedContent?.Radius}`,
                color:`${filedContent?.TextColor}`
            }}

             >
                
                { filedContent?.text  ?? "Visit" }

            </button>
        </div>

    )
}