import toast from 'react-hot-toast';
import axios from '../axios';
import { get, isObject } from 'lodash';

const setheader = () => {
    return {
        headers:{
            "x-shared-project": true
        }
    }
}

export const getSharedprojectforUser = () => {
    
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.get('/shared/project/all');
            
            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response.data.message ?? "Can't get the Site list, please Contact Support")
            reject(err);
        }
    })
}


export const inviteMemberFormSharedWS = (payload) => {
    
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.post('/engage/team/admin/user/invite',payload,setheader());
            
            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response.data.message ?? "Can't get the Site list, please Contact Support",)
            reject(err);
        }
    })
}

export const updateMemberFormSharedWS = (payload) => {
    
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.put('/engage/team/admin/user/update',payload,setheader());
            
            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response.data.message ?? "Can't get the Site list, please Contact Support")
            reject(err);
        }
    })
}

export const deleteMemberFormSharedWS = (payload) => {
    
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.post('/engage/team/admin/user/delete',payload,setheader());
            
            if(res.status == 200) resolve(res.data);
            
        }catch(err){
            toast.error(err.response.data.message ?? "Can't get the Site list, please Contact Support")
            reject(err);
        }
    })
}