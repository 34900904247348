import { useEffect, useRef } from 'react';
import { useState } from 'react'
import { listOfIcon } from '../content/iconList';

export const FilterComponent = ({ SortByName, SortByDate, SortByLive, SortByPaused }) => {

    const [bool, setBool] = useState(false)


    const data = [
        {
            name: "Last updated",
            fun: SortByDate,
        },
        {
            name: "A-Z",
            fun: SortByName
        },
        {
            name: "Live",
            fun: SortByLive
        },
        {
            name: "Paused",
            fun: SortByPaused
        }
    ]

    const menuREf = useRef(null);
    
    useEffect(()=>{
        let handleAction = (event) => {

            if (menuREf.current && !menuREf.current?.contains(event.target)) {
                setBool(false);
            }
        };
    
        document.addEventListener("mousedown", handleAction);
    },[])

    return (
        <button className={`relative flex w-32 border-1 items-center px-4 rounded-lg ${bool ? "" :""}`} onClick={() => setBool(!bool)}>

            <p className='w-full flex space-x-4 justify-between'>

                <span className="flex my-auto text-sm" >Sort</span>

                <span className="flex my-auto" >{listOfIcon('downarrow2',14,14,'#06152D')}</span>

            </p>

            <div className={bool ? 'w-[150px] z-50 absolute top-[20px] right-8 bg-white shadow-bgshadow rounded-lg ' : 'hidden'} ref={menuREf} >

                <ul className='flex flex-col   w-full  py-2'>

                    {
                        data.map((i, k) => <li key={k} className='text-left px-4 py-2 hover:bg-hv-blue' onClick={i.fun}>{i.name}</li>)
                    }

                </ul>

            </div>
        </button>
    )
}
