import React from 'react'
import PropTypes from "prop-types";
import { AiOutlineInfoCircle } from 'react-icons/ai'

const InfoText = function ({ text, textColor = "text-primary-bold2", margin = "", textSize = "xs", ...props }) {
    return (
        <div className={`${margin} flex flex-row items-center justify-center`}>
            <AiOutlineInfoCircle className="w-4 h-4 mr-2 text-gray-600" />
            <span className={`font-normal ${textColor} text-${textSize}`}>{text}</span>
            {props.children}
        </div>
    )
}



InfoText.prototype = {
    text: PropTypes.string.isRequired,
    textColor: PropTypes.string,
    margin: PropTypes.string,
    textSize: PropTypes.string,

}

export { InfoText }