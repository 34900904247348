import React, { useEffect, useState } from "react";
import PrimaryButton from "../../Button1/primarybutton";
import CheckBox from "../../input/checkbox";
import SwicthInput from "../../input/swicth";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../store";
import InputRadiocheck from "../../input/radiocheck";
import { InputLabel } from "../../Texts";
import InputWithLabel from "../../input/inputWithLabel";
import { LeadFormEditor } from "./leadform";
import { ColorPixerInput } from "../../input/colorpixerInput";
import { Imageinput } from "../../input/imageinput";
import { MediaPopup } from "../../popup/Mediapopup";
import DefaultDropMenu from "../../input/droplist";
import TextareaInput from "../../input/textarea";
import { isNotEmpty } from "../../../utils/helpers";
import { listOfIcon } from "../../../content/iconList";
import { ChooseLayout } from "../../popup/chooseLayout";

export const CommonTemplateSetting = ({ category, fieldId, type, setEditTemplate = null, setEditTemplateField = null }) => {

    const triggerAction = () => {
        setEditTemplate(true)
        setEditTemplateField(fieldId)
    }

    const { fields, createTemplate, plan } = useSelector((state) => ({ fields: state.fields, createTemplate: state.createTemplate, plan: state.userPlan.plan }));

    const isDev = process.env.REACT_APP_ENV == "development";

    const templateData = fields[fieldId]?.filedContent;
    const [ShowMedia, setShowMidia] = useState(false);
    const [ShowTempMedia, setShowTempMedia] = useState(false);
    const [ShowTempLayout, setShowTempLayout] = useState(false);
    const [mediaSrcType, setMediaSrcType] = useState('image');
    const [mediafieldType, setMediafieldType] = useState('backgroundSrc');

    const LeadFormjson = fields[fieldId]?.filedContent?.game_leadForm_json

    const DisPatch = useDispatch();
    const TemplateData = fields[fieldId];

    const triggerMediaFile = (type = 'image', fieldtype = "backgroundSrc") => {
        setShowMidia(true);
        setMediaSrcType(type);
        setMediafieldType(fieldtype)
    }


    const content = (e, type, filedId, Checkstate = false) => {

        let stateCheck = false;

        const IsCheckBox = e.target.type == 'checkbox' ? true : false;

        if (IsCheckBox) stateCheck = !Checkstate ? true : false

        const SetValue = IsCheckBox ? stateCheck : e.target.value

        let setObj = {
            key: filedId,
            fieldname: type,
            value: SetValue
        }

        DisPatch(Actions.setFieldContentChange(setObj))
        DisPatch(Actions.setUpdateCampaign())

    }
    const TempContent = (value, type) => {
        let setObj = {
            key: fieldId,
            fieldname: type,
            value: value
        }

        DisPatch(Actions.setFieldTempCC(setObj))

    }

    const Industry = ['food_industry', "ecommerce", "software", "construction", "beauty&cosmetics", "photography", "logistics&transportation", "medical", "human_resources", "banking&finance", "gaming", "e-learning"]

    const DirectCC = (value, type) => {

        if (type === "background") value = { key: "color", value: value }

        if (type === "backgroundSrc") value = { key: "src", value: value }

        let setObj = {

            key: fieldId,

            fieldname: (type === "background" || type === "backgroundSrc") ? "background" : type,

            value: value
        }

        DisPatch(Actions.setFieldContentChange(setObj))

        if (ShowMedia) setShowMidia(false)

    }

    const EditIcon = (color = 'white') => listOfIcon('edit',18,18,color);



    const DefaultContentChange = (value, type, filedId) => {

        let setObj = {
            key: filedId,
            fieldname: type,
            value: value
        }

        DisPatch(Actions.setFieldContentChange(setObj))
        // DisPatch(Actions.setUpdateCampaign())
    }

    const [toggleButton, settoggleButton] = useState(templateData?.background?.type == "color" ? false : true)

    const ChangeQuestiontype = (type) => {

        settoggleButton(type == "image" ? true : false)

        DefaultContentChange({ key: "type", value: type }, "background", fieldId)

    }

    useEffect(()=>{ 
        LeadFormjson?.field?.map((list, index) => {
        if(!list?.questionId)     DisPatch(
            Actions.selAutoQuestionUpdate({
              fieldId,
              FieldInd: index
            })
          );
        })
     })

    const TimerType = ["Default", "CountDown"]

    return (

        <div className={`w-full translate-display relative font-sans space-y-4 duration-700 block my-auto `}>

            {ShowMedia && <MediaPopup cancel={setShowMidia} srctype={mediaSrcType} fieldType={mediafieldType} OnSelect={DirectCC} />}

            {ShowTempMedia && <MediaPopup cancel={setShowTempMedia} srctype={mediaSrcType} fieldType={'image_src'} OnSelect={TempContent} />}

            { ShowTempLayout && <ChooseLayout cancel={setShowTempLayout} /> }


            {/*  Game Templte Editor Option on Dev Env */}

            {createTemplate &&

                <div className="w-full space-y-4">

                    <DefaultDropMenu label={'Industry'} list={Industry} fieldType={"Industry"} onchange={TempContent} />

                    <InputWithLabel label={"Game Type"} labelTextsize={'text-sm'} disabledinput={true} name={""} textvalue={TemplateData?.type} />

                    <InputWithLabel label={"Template name"} labelTextsize={'text-sm'} name={""} textvalue={TemplateData?.name} onchange={(e) => TempContent(e.target.value, "name")} />

                    <Imageinput src={TemplateData?.image_src} chanaeAction={() => { setShowTempMedia(true); setMediaSrcType('image') }} />

                    <TextareaInput label={"Description"} textvalue={TemplateData?.description} onchange={(e) => TempContent(e.target.value, "description")} />

                </div>
            }

            {
                fields[fieldId]?.category == "games" || fields[fieldId]?.category == "spinWheel" ?

                    // Game & SpinWheel Template Block Elements

                    <div className="w-full space-y-5 ">


                        {fields[fieldId]?.category !== "spinWheel" ? <SwicthInput label="Show Timer" parentClass="flex-row-reverse justify-between mt-3" onchange={(e) => DefaultContentChange(e, 'timerstatus', fieldId)} status={templateData?.timerstatus} /> : null}


                        {templateData?.timerstatus &&

                            <div className=" w-full space-y-4">

                                <label className="capitalize" >Timer type</label>

                                <div className="flex w-full 0">

                                    <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={(templateData?.timerType !== 'Default') ? 'text-gray-500' : 'text-primary'} border={(templateData?.timerType !== 'Default') ? 'border-2 border-gray-500' : 'border-2 border-primary'} height="h-14" text={'CountUp'} rounded={'rounded-none'} OnClick={() => DefaultContentChange('Default', 'timerType', fieldId)} />

                                    <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={(templateData?.timerType == 'Default') ? 'text-gray-500' : 'text-primary'} border={(templateData?.timerType == 'Default') ? 'border-2 border-gray-500' : 'border-2 border-primary'} height="h-14" text={'CountDown'} rounded='rounded-none' OnClick={() => DefaultContentChange('CountDown', 'timerType', fieldId)} />

                                </div>

                            </div>

                        }

                        {templateData?.timerstatus && templateData?.timerType !== 'Default' && <InputWithLabel type={'number'} onchange={(e) => content(e, 'timeLimet', fieldId, templateData?.timerstatus)} label="Time Limit in second" spaceY={'flex space-x-4'} width="w-24" labelTextWeight="flex my-2 h-auto" textvalue={templateData?.timeLimet} />}

                        <SwicthInput label="BackGround Music" parentClass="flex-row-reverse justify-between mt-3" onchange={(e) => DefaultContentChange(e, 'backgroundMusic', fieldId)} status={templateData?.backgroundMusic ?? true} />

                        {(templateData?.backgroundMusic ?? true) && <Imageinput src={templateData?.backgroundMusicSrc} type="audio" label="BGM" chanaeAction={() => triggerMediaFile('audio', 'backgroundMusicSrc')} />}

                        {fields[fieldId]?.category !== "spinWheel" && (TemplateData?.type === "rearrange") ? <CheckBox label="Show cards before the game start" isChecked={templateData?.showcardBeforestart} onchange={(e) => content(e, 'showcardBeforestart', fieldId, templateData?.showcardBeforestart)} textvalue={templateData?.showcardBeforestart} /> : null}

                        {templateData?.showcardBeforestart && <InputWithLabel type={'number'} label="Timer" onchange={(e) => content(e, 'showcardtime', fieldId, templateData?.timerstatus)} spaceY={'flex space-x-4'} width="w-24" labelTextWeight="flex my-2 h-auto" textvalue={templateData?.showcardtime} />}

                        {fields[fieldId]?.category !== "spinWheel" && <SwicthInput label="Show lead form popup" parentClass="flex-row-reverse justify-between mt-3" onchange={(e) => DefaultContentChange(e, 'leadfrom', fieldId)} status={templateData?.leadfrom} />}

                        {templateData?.leadfrom && <LeadFormEditor fieldId={fieldId} />}


                        <div className="w-full space-y-4">



                            <div className="step-design-backGroundImage w-full space-y-4">

                                <div className=" w-full space-y-4">

                                    <label className="capitalize" >Background type</label>

                                    <div className="flex w-full 0">

                                        <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={toggleButton ? 'text-gray-500' : 'text-primary'} border={toggleButton ? 'border-2 border-gray-500' : 'border-2 border-primary'} height="h-14" text={'Color'} rounded={'rounded-none'} OnClick={() => ChangeQuestiontype('color')} />
                                        <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={!toggleButton ? 'text-gray-500' : 'text-primary'} border={!toggleButton ? 'border-2 border-gray-500' : 'border-2 border-primary'} height="h-14" text={'image'} rounded='rounded-none' OnClick={() => ChangeQuestiontype('image')} />
                                    
                                    </div>
                                
                                </div>

                                <div>

                                    {toggleButton ? <Imageinput src={templateData?.background?.src} deleteSrc={() => DirectCC({ key: "src", value: "" }, 'backgroundSrc')} chanaeAction={() => triggerMediaFile('image', 'backgroundSrc')} label="Background Image" /> : <ColorPixerInput label={"Background color"} Onchange={DirectCC} fieldType="background" colorHex={templateData?.background?.color ?? "#7ED321"} labelTextsize={'text-sm my-auto'} fieldId={fieldId} />}
                                
                                </div>

                            </div>
                          
                        </div>

                        <PrimaryButton text={'Edit the Template'} classNames={' step-design-editDesignBtn'} width="w-full" OnClick={triggerAction} icon={EditIcon('white')} />

                    </div> :

                    // Form Realted Template Block Elements

                    <div className="w-full space-y-4">


                        {fields[fieldId]?.category == "quizzes" &&

                            <SwicthInput label="Show Timer" parentClass="flex-row-reverse justify-between mt-3" onchange={(e) => DefaultContentChange(e, 'timerstatus', fieldId)} status={templateData?.timerstatus} />

                        }

                        {(templateData?.timerstatus && fields[fieldId]?.category == "quizzes") &&

                            <div className=" w-full space-y-4">

                                <label className="capitalize" >Timer type</label>

                                <div className="flex w-full 0">

                                    <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={(templateData?.timerType !== 'Default') ? 'text-gray-500' : 'text-primary'} border={(templateData?.timerType !== 'Default') ? 'border-2 border-gray-500' : 'border-2 border-primary'} height="h-14" text={'CountUp'} rounded={'rounded-none'} OnClick={() => DefaultContentChange('Default', 'timerType', fieldId)} />
                                    <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={(templateData?.timerType == 'Default') ? 'text-gray-500' : 'text-primary'} border={(templateData?.timerType == 'Default') ? 'border-2 border-gray-500' : 'border-2 border-primary'} height="h-14" text={'CountDown'} rounded='rounded-none' OnClick={() => DefaultContentChange('CountDown', 'timerType', fieldId)} />
                                
                                </div>

                            </div>

                        }

                        {templateData?.timerstatus && templateData?.timerType !== 'Default' && fields[fieldId]?.category == "quizzes" && <InputWithLabel type={'number'} onchange={(e) => content(e, 'timeLimet', fieldId, templateData?.timerstatus)} label="Time Limit in seconds" spaceY={'flex space-x-4'} width="w-24" labelTextWeight="flex my-2 h-auto" textvalue={templateData?.timeLimet} />}

                        <div className="step-design-backGroundImage w-full space-y-4 mb-4">

                            <div className="w-full space-y-2 ">

                                <label className="capitalize" >Background type</label>

                                <div className="flex w-full 0">

                                    <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={toggleButton ? 'text-gray-500' : 'text-primary'} border={toggleButton ? 'border-2 border-gray-500' : 'border-2 border-primary'} height="h-14" text={'Color'} rounded={'rounded-none'} OnClick={() => ChangeQuestiontype('color')} />
                                    <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={!toggleButton ? 'text-gray-500' : 'text-primary'} border={!toggleButton ? 'border-2 border-gray-500' : 'border-2 border-primary'} height="h-14" text={'image'} rounded='rounded-none' OnClick={() => ChangeQuestiontype('image')} />
                               
                                </div>

                            </div>

                            <div> {toggleButton ? <Imageinput src={templateData?.background?.src} deleteSrc={() => DirectCC({ key: "src", value: "" }, 'backgroundSrc')} chanaeAction={() => triggerMediaFile('image', 'backgroundSrc')} /> : <ColorPixerInput label={"Background color"} Onchange={DirectCC} fieldType="background" colorHex={templateData?.background?.color ?? "#7ED321"} labelTextsize={'text-sm my-auto'} fieldId={fieldId} />}</div>
                        
                        </div>

                        <span className="w-full space-y-4 ">

                            <ColorPixerInput label={"primary color"} Onchange={DirectCC} fieldType="primaryColor" colorHex={templateData?.primaryColor ?? "#FFFFF"} labelTextsize={'text-sm my-auto'} fieldId={fieldId} />
                            
                            <ColorPixerInput label={"secondary color"} Onchange={DirectCC} fieldType="secondaryColor" colorHex={templateData?.secondaryColor ?? "#000"} labelTextsize={'text-sm my-auto'} fieldId={fieldId} />
                        
                        </span>

                        <SwicthInput label={'Show Process bar'} parentClass="flex-row-reverse w-full justify-between" defaultOPtion={true} onchange={(state) => DefaultContentChange(state, 'processbar', fieldId)} status={templateData?.brandLabel ?? true} labelTextsize={'text-sm'} />

                        <PrimaryButton text={'Edit the Template'} classNames={'step-design-editDesignBtn'} width="w-full" OnClick={triggerAction} icon={EditIcon('white')} />

                        { isDev && <PrimaryButton text={'Edit Template Layout'} classNames={'step-design-editDesignBtn'} buttontype="secondarybutton" width="w-full" OnClick={()=>setShowTempLayout(true)} icon={EditIcon('#2563EB')} />}

                    </div>
            }

            {isNotEmpty(plan) && plan !== "free" && <SwicthInput label={'Show GoZen Brand Label'} parentClass="flex-row-reverse w-full justify-between" defaultOPtion={true} onchange={(state) => DefaultContentChange(state, 'brandLabel', fieldId)} status={templateData?.brandLabel ?? true} labelTextsize={'text-sm'} />}

            {/* <hr />
            <label className="pt-2" >Redirect Options</label>
            <SwicthInput label={'Hide'} labelTextsize={'text-sm'} /> */}
            {/* 
            {
                <>
                    <Imageinput />
                    <ColorPixerInput label={"Background color"} labelTextsize={'text-sm my-auto'} fieldId={fieldId} />
                </>
            } */}

        </div>

    )
}