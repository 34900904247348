import { HtmlHTMLAttributes, useEffect, useRef, useState } from "react";

// export type scriptWithDataAttributes = HtmlHTMLAttributes<HTMLScriptElement> & Record<`data-${string}`, string>;

// export interface useScriptOptions {
//     removeOnUnmount?: boolean;
//     attributes: scriptWithDataAttributes;
// }

export function useScript(src, options) {
    const [status, setStatus] = useState("loading");

    const optionsRef = useRef(options);

    useEffect(() => {
        let script = document.querySelector(`script[src="${src}"]`);

        const domStatus = script?.getAttribute("data-status");

        if (domStatus) return setStatus(domStatus);

        if (script !== null) return setStatus("unknown");

        script = document.createElement("script");

        script.src = src;

        script.defer = true;

        script.setAttribute("data-status", "loading");

        Object.entries(options.attributes ?? {}).forEach(([key, value]) => {
            script.setAttribute(key, value);
        });

        document.body.appendChild(script);

        const handleScriptLoad = () => {
            script.setAttribute("data-status", "ready");

            setStatus("ready");

            removeEventListeners();
        };

        const handleScriptError = () => {
            script.setAttribute("data-status", "error");

            setStatus("error");

            removeEventListeners();
        };

        const removeEventListeners = () => {
            script.removeEventListener("load", handleScriptLoad);

            script.removeEventListener("error", handleScriptError);
        };

        script.addEventListener("load", handleScriptLoad);

        script.addEventListener("error", handleScriptError);

        const removeOnUnmount = optionsRef.current.removeOnUnmount;

        return () => {
            if (removeOnUnmount !== true) return;

            script.remove();

            removeEventListeners();
        };
    }, [src]);

    return status;
}
