import { useDispatch, useSelector } from "react-redux"
import PrimaryButton from "../../../Button1/primarybutton"
import { MediaPopup } from "../../../popup/Mediapopup"
import { useState } from "react"
import { Actions } from "../../../../store"

export const ProfilePicture = ({ }) => {

    const [ show , setShow ] = useState(false)

    const { userDetail } = useSelector(state => ({userDetail:state?.userDetail}))

    const Dispatch = useDispatch()

    const ChangeProfile = (src,type) => {

        Dispatch(Actions?.setuserDelailsUpDate({type:'profile',value:src}))

    }

    return (<>
        {show&&<MediaPopup cancel={setShow} fieldType="profile" OnSelect={ChangeProfile} />}
        <div className="w-full h-full flex justify-center py-4   mr-3 p-2 ">

            <div className="flex w-full space-x-4 ">
                <div
                    className="p-sm rounded-full overflow-hidden"

                >
                    <img
                        className={`rounded-full w-[128px] h-[128px] flex-shrink-0 object-cover object-center`}
                        alt="User"
                        src={userDetail?.profile}
                        onError={() => console.log("error")}

                    />
                </div>


                <div className="my-auto" >
                    <PrimaryButton buttontype="secondarybutton" text={'Change Profie'} OnClick={()=>setShow(true)} />
                    <p className="break-words mt-3 text-center text-sm font-normal">
                        Allowed *.jpeg, *jpg, *png max size of 2 MB
                    </p>
                </div>
            </div>

        </div>
        </>
    )
}