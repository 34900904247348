import React, { useState } from "react";
import DefaultDropMenu from "../../../input/droplist";
import PrimaryButton from "../../../Button1/primarybutton";
import { Imageinput } from "../../../input/imageinput";
import { ColorPixerInput } from "../../../input/colorpixerInput";
import InputWithLabel from "../../../input/inputWithLabel";
import { useDispatch } from "react-redux";
import { Actions } from "../../../../store";
import { MediaPopup } from "../../../popup/Mediapopup";


export const FindFlowSettings = ({ field, fieldId }) => {

    const dropmenuList = ['4x3', '4x4', '5x2', '5x4', '6x5', '6x4']
    const GameLevel = ['easy', 'normal', 'medium', 'hard']
 

    const toolTip = [ 
        {
            type: "delete",
            icon: (<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.7506 5.04785C15.7356 5.04785 15.7131 5.04785 15.6906 5.04785C11.7231 4.65035 7.76318 4.50035 3.84071 4.89785L2.31072 5.04785C1.99573 5.07785 1.71823 4.85285 1.68823 4.53785C1.65823 4.22285 1.88323 3.95285 2.19073 3.92285L3.72071 3.77285C7.71068 3.36785 11.7531 3.52535 15.8031 3.92285C16.1106 3.95285 16.3356 4.23035 16.3056 4.53785C16.2831 4.83035 16.0356 5.04785 15.7506 5.04785Z" fill="#586474" />
                <path d="M6.37555 4.29C6.34555 4.29 6.31555 4.29 6.27805 4.2825C5.97805 4.23 5.76806 3.9375 5.82056 3.6375L5.98555 2.655C6.10555 1.935 6.27055 0.9375 8.01804 0.9375H9.98302C11.738 0.9375 11.903 1.9725 12.0155 2.6625L12.1805 3.6375C12.233 3.945 12.023 4.2375 11.723 4.2825C11.4155 4.335 11.123 4.125 11.078 3.825L10.913 2.85C10.808 2.1975 10.7855 2.07 9.99052 2.07H8.02554C7.23054 2.07 7.21554 2.175 7.10305 2.8425L6.93055 3.8175C6.88555 4.095 6.64555 4.29 6.37555 4.29Z" fill="#586474" />
                <path d="M11.4077 17.0627H6.59276C3.97528 17.0627 3.87029 15.6152 3.78779 14.4452L3.30029 6.89268C3.27779 6.58518 3.51779 6.31518 3.82529 6.29268C4.14028 6.27768 4.40278 6.51018 4.42528 6.81768L4.91278 14.3702C4.99528 15.5102 5.02527 15.9377 6.59276 15.9377H11.4077C12.9827 15.9377 13.0127 15.5102 13.0877 14.3702L13.5752 6.81768C13.5977 6.51018 13.8677 6.27768 14.1752 6.29268C14.4827 6.31518 14.7227 6.57768 14.7002 6.89268L14.2127 14.4452C14.1302 15.6152 14.0252 17.0627 11.4077 17.0627Z" fill="#586474" />
                <path d="M10.2455 12.9375H7.74804C7.44054 12.9375 7.18555 12.6825 7.18555 12.375C7.18555 12.0675 7.44054 11.8125 7.74804 11.8125H10.2455C10.553 11.8125 10.808 12.0675 10.808 12.375C10.808 12.6825 10.553 12.9375 10.2455 12.9375Z" fill="#586474" />
                <path d="M10.875 9.9375H7.125C6.8175 9.9375 6.5625 9.6825 6.5625 9.375C6.5625 9.0675 6.8175 8.8125 7.125 8.8125H10.875C11.1825 8.8125 11.4375 9.0675 11.4375 9.375C11.4375 9.6825 11.1825 9.9375 10.875 9.9375Z" fill="#586474" />
            </svg>
            )
        },
    ]


    const findIndex = dropmenuList.findIndex(list => list === field?.layoutrange) //field?.layputRange
    const [toggleButton, settoggleButton] = useState(false)
    const [ShowMedia, setShowMidia] = useState(false);
    const Dispatch = useDispatch()

    const DirectContentChange = (value, type) => {

        const setContent = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_mainpage_json',
            child: type,
            value: value
        }
        Dispatch(Actions.setFieldTemplateContentChange(setContent))
        if (ShowMedia) setShowMidia(false)

    }

    const DirectGameContentChange = (value, type, index, edge) => {

        const setContent = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_mainpage_json',
            child: type,
            QueIndex: index,
            edge: edge,
            value: value
        }
        Dispatch(Actions.setFieldGameFindFlowCC(setContent))
        if (ShowMedia) setShowMidia(false)

    }

    
    const AddGameLevel= () => {  
        const setContent = {
            key:fieldId,
            node:'game_mainpage_json',
            child:'gameStage',
            value: {  "flowSpeed": "normal",   "flowCount": 3  },
        }
        Dispatch(Actions.setFindFlowGameLevelAdd(setContent))
    }

    const changetype = () => {

        settoggleButton(toggleButton ? false : true)
        DirectContentChange(toggleButton ? 'image' : 'color', 'cardtype')

    }

    const GameLevelReArrange = (type,Index) => {
        const setContent = {
            key: fieldId,
            node: 'game_mainpage_json',
            child: 'gameStage',
            position: Index,
            type: type
        }

        Dispatch(Actions.setFindFlowRA(setContent))
    }


    return (
        <div className="w-full space-y-4 ">
            {ShowMedia && <MediaPopup cancel={setShowMidia} fieldType={'imageSrc'} OnSelect={DirectContentChange} />}

            <DefaultDropMenu label={'choose layout :'} list={dropmenuList} active={findIndex < 0 ? 0 : findIndex} fieldType={'layoutrange'} onchange={DirectContentChange} />
            <div className="w-full space-y-2">
                <label className="capitalize" >Template Type</label>
                <div className="flex w-full 0">
                    <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={toggleButton ? 'text-gray-500' : 'text-primary'} border={toggleButton ? 'border-2 border-gray-500' : 'border-2 border-primary'} rounded="rounded-l-2" height="h-14" text={'Image'} OnClick={changetype} />
                    <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={!toggleButton ? 'text-gray-500' : 'text-primary'} border={!toggleButton ? 'border-2 border-gray-500' : 'border-2 border-primary'} height="h-14" text={'color'} rounded='rounded-none' OnClick={changetype} />
                </div>
            </div>
            {
                !toggleButton ?
                    <Imageinput label="Target Icon" src={field?.imageSrc} chanaeAction={() => setShowMidia(true)} />
                    :
                    <ColorPixerInput label='Card-Background' fieldType={"color"} fieldId={fieldId} colorHex={field?.color ?? "#7ED321"} Onchange={DirectContentChange} />

            }
            {
                field?.gameStage?.map((list, index) => {
                    const FindSpeed = GameLevel.findIndex(listObj => listObj?.toLowerCase() === list?.flowSpeed?.toLowerCase()) 
                    return(
                    <div key={index} className={`w-full relative flex  flex-col space-y-2 border-2  h-fit delay-400 duration-700 top-0 p-4 rounded-xl hover:shadow-md hover:-top-0 hover:border-primary`}  >
                       
                       <div className="flex justify-between">
                    <p className="text-base font-medium" >Game level - {index + 1}</p>
                    <div className="flex space-x-2">
                        {
                            toolTip.map((list, index) => {
                                return (
                                    <p key={index} className="p-2 rounded-md border-1 hover:bg-gray-200 cursor-pointer" onClick={() => GameLevelReArrange(list.type,index)} >{list.icon}</p>
                                )
                            })
                        }
                    </div>
                </div>
                        
                        <InputWithLabel label="Flow count" textvalue={list?.flowCount} onchange={(e) => DirectGameContentChange(e.target.value, "gameStage", index, "flowCount")} />
                        <DefaultDropMenu list={GameLevel} label="Flow Speed" active={FindSpeed < 0 ? 0 : FindSpeed} fieldType={'gameStage'} IndexValue={index} edge={'flowSpeed'} onchange={DirectGameContentChange} />

                    </div>)
                })
            }

            <PrimaryButton OnClick={()=>{AddGameLevel()}} text={'Add Game Level'} width="w-full" height="h-12" backgroundColor="bg-primary-lightblue" border="border-1 border-primary" textcolor="text-primary" />
        </div>
    )
}