import React, {  useRef, useState } from "react";
import PrimaryButton from "../Button1/primarybutton";
import Input from "../input/input";
import SearchBar from "../input/searchbar";
import { CgSpinner } from "react-icons/cg";
import { useParams } from "react-router-dom";


export const SimpleMove = ({
    title = '',
    defaultVaule = '',
    onProfile = null,
    seletedProject,
    setSeletedProject = null,
    defaultSrc,
    loadingLive=false,
    projectData = [],
    changeDefaultVaule = null,
    moveCmp=null,
    className = "",
    setshoWMov=null
}) => {

  const Params = useParams();

    const menuREf = useRef(null);

    const [show, setShow] = useState(false)

    const [name, setname] = useState("")

  
    const projectsdata = projectData.filter((a) => String(a.project_name).toLowerCase().includes(name))


    return (
        <div className="fixed top-0 left-0 flex w-full h-full bg-backgroundShadow" style={{ zIndex: '75' }}>
            <div className={`p-5 w-[450px] rounded-xl bg-white m-auto space-y-4 ${className} `} ref={menuREf} >

                <p className="capitalize text-lg font-medium">{title}</p>

                {
                    !show ? <div class="h-full flex flex-col space-y-3" >
                        <SearchBar width={"w-full"} height="h-[50px]" inputwidth={"w-full "} iconposition="left" border="border-2 px-4" setName={setname} />
                        <hr className="px-4" />

                        <div className="flex flex-col w-full h-full max-h-[250px] overflow-y-auto list-industry px-4" >

                            {projectsdata?.length > 0 ?
                                projectsdata?.map((list, index) => {

                                  if(Params?.project_id===list?.project_id) return null;
                                    return (
                                        <>
                                            <p className={` ${(seletedProject !== list?.project_id) ? "" : "text-primary"} py-2 flex justify-between cursor-pointer`} onClick={()=>setSeletedProject(list?.project_id)} >
                                                <span>{list?.project_name}</span>
                                                {(seletedProject === list?.project_id) ? <span>
                                                    <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z" fill="#2563EB" />
                                                    </svg>

                                                </span> :
                                                    <span>
                                                        <svg width="20" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#586474" />
                                                        </svg>

                                                    </span>}
                                            </p>
                                            <hr />
                                        </>
                                    )
                                })
                                :
                                <div className="flex flex-col space-y-2 justify-center" >
                                    <p className="text-center py-2  capitalize" >No data</p>
                                    <hr />

                                </div>
                            }
                        </div>
                    </div> :
                        <>
                            <div className="flex flex-col space-y-3" >
                                <label className="capitalize font-medium" >Campaign name</label>
                                <Input textvalue={defaultVaule} onchange={(e) => changeDefaultVaule(e.target.value)} />
                            </div>
                            <div className="flex flex-col space-y-3" >
                                <label className="capitalize font-medium" >Project icon</label>
                                <div className="w-full h-[175px] relative group hover:shadow-md" >

                                    <img src={(defaultSrc && defaultSrc !== "") ? defaultSrc : 'https://gz-engage-assets.s3.amazonaws.com/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/c7a655060994272bcf9a99a5afde2eaf.jpg'} className="w-full h-full object-fill rounded-lg" alt="" />

                                    <div className="absolute transition-all duration-400 ease-in-out top-0 flex flex-col justify-center left-0 h-full w-full rounded-lg bg-black/60 opacity-0 group-hover:opacity-100" style={{ zIndex: "150" }} >

                                        <PrimaryButton id="change-profile" text={"change profile"} buttontype={'defaultbutton'} backgroundColor={"bg-white"} textcolor={"text-black"} padding={"py-6"} height={"h-14"} width={"w-1/2"} margin={"mx-auto mb-2"} OnClick={() => onProfile(true)} />

                                    </div>
                                </div>

                            </div>

                        </>
                }

                {<div className="flex justify-end space-x-2">
                    <PrimaryButton buttontype="secondarybutton" width="w-24" textcolor="text-black" border="border-2" text={'Cancel'} OnClick={() => {setshoWMov(false); setSeletedProject(null); }} />

                    <PrimaryButton  icon={
                        loadingLive ? <CgSpinner className="w-5 h-5 animate-spin text-white"  /> : null
                    } text={"Move"} disabled={loadingLive} width={'w-24'} OnClick={moveCmp} />
                </div>}
            </div>
        </div>
    )
}