import React, { useState } from 'react'
import { ProfilePicture } from './MyAccount/ProfilePicture'
import { EditDetails } from './MyAccount/EditDetails'
import PrimaryButton from '../../Button1/primarybutton'
import { useSelector } from 'react-redux'
import { updateUser } from '../../../actions/user'
import toast from 'react-hot-toast'
import { CgSpinner } from 'react-icons/cg'
// import 
// import InputWithLabel from '../../input/inputWithLabel'

export const Myaccount = () => {

  const { userDetail } = useSelector(state => ({userDetail:state?.userDetail}))

  const [loading,setLoadding] =  useState(false)


  const changeData = async () => {
    const { first_name, last_name, profile} = userDetail
    let payload = {
      first_name,
      last_name,
      engage_user_profile:profile
    }
    setLoadding(true)
    const updateuser = await updateUser(payload)
    if(updateuser?.success) toast.success('User Detail updated')
    else toast.else('can\'t update User Detail ')
    setLoadding(false)
  }

  return (<>
    <div className='w-full h-full m-0 bg-white flex flex-col py-6 px-8 rounded-xl space-x-2' id='myaccount'>
      <div className='flex space-x-4 w-full px-2  rounded-xl'>
        <span>
          <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 5C13.66 5 15 6.34 15 8C15 9.66 13.66 11 12 11C10.34 11 9 9.66 9 8C9 6.34 10.34 5 12 5ZM12 19.2C9.5 19.2 7.29 17.92 6 15.98C6.03 13.99 10 12.9 12 12.9C13.99 12.9 17.97 13.99 18 15.98C16.71 17.92 14.5 19.2 12 19.2Z" fill="currentColor"></path></svg>
        </span>
        <h3 className='text-lg font-semibold flex justify-center items-center '>MY Account </h3>
      </div>
      <hr />
      <ProfilePicture />
      <EditDetails />
      <hr />
      <div className='flex w-full justify-end'>
        <PrimaryButton text={'save Change'} icon={
            loading ? <CgSpinner className="w-5 h-5 animate-spin text-white" /> : null
        } OnClick={changeData} />
      </div>
    </div>
  </>
  )
}
