import React, { useEffect, useRef, useState } from "react";
import PrimaryButton from "../Button1/primarybutton";
import Input from "../input/input";
import { Clickouter } from "../../utils/clickouter";
import { CgSpinner } from "react-icons/cg";
import InputWithLabel from "../input/inputWithLabel";
import DefaultDropMenu from "../input/droplist";
import { useSelector } from "react-redux";
import SwicthInput from "../input/swicth";
import { inviteEngageTeamMember } from "../../actions/teamMember";
import toast from "react-hot-toast";


export const InviteTeamMember = ({
    title = 'Invite Team Member',
    forwardAction = null,
    backwardAction = null,
    Email,
    forwardActiontext = 'Done',
    className = "",
}) => {

    const { WorkSpace } = useSelector(state => ({ WorkSpace: state?.projects }))

    const UserRole = ['admin', 'editor', 'view only',]

    const [activeRole, setActiveRole] = useState(0);

    const [loadingLive, setLoadingLive] = useState(false);

    const [Premission, setPremission] = useState([])

    let PremissionRole = []

    const [full_name, setFullname] = useState("")


    const ManagePermission = ( role, project_id ) => {

        let projectObj = [...Premission]

        const allReadyExist = Premission?.findIndex(list => list?.project_id === project_id)

        if (allReadyExist > 0 ) {

            projectObj[allReadyExist].role = role

            setPremission(projectObj)

        }

    }


    const AccessChange = (index, state) => {

        let projectObj = [...Premission]

        const { project_name, project_id } = WorkSpace[index]

        const allReadyExist = Premission?.findIndex(list => list?.project_id === project_id)

        if (allReadyExist < 0 && state) {
            // console.log(PremissionRole)

            if ((Premission.length + 1) == WorkSpace?.length) setActiveRole(0)

            setPremission(obj => [...obj, { project_name, project_id, role:PremissionRole[index] }])

        }
        else if (allReadyExist > -1 && !state) {

            projectObj.splice(allReadyExist, 1)
            setPremission(projectObj)

        }

    }
    const InviteAction = async () => {

        try {
            setLoadingLive(true)

            const InviteTeamMember = await inviteEngageTeamMember({ email: Email, full_name, engage_permissions: { role:'admin', workspace: Premission } })
    
            if (InviteTeamMember?.success) toast.success('Team Member Successfully Invited!')
            else toast.error('Can\'t invite the team memeber!')
            setLoadingLive(false)
        }catch(err){
            toast.error('Can\'t invite the team memeber!')
            setLoadingLive(false)
        }



    }

    const menuREf = useRef(null);

    useEffect(() => {

        let handleAction = (event) => {

            if (menuREf.current && !menuREf.current?.contains(event.target)) {
                backwardAction(false);
            }
        };

        document.addEventListener("mousedown", handleAction);

    }, [])

    return (
        <div className="fixed top-0 left-0 flex w-full h-full bg-backgroundShadow" style={{ zIndex: '55' }}>

            <div className={`relative w-[550px] h-fit m-auto rounded-lg bg-white space-y-4 ${className} `} style={{ zIndex:'56'}} ref={menuREf} >

                <div className="w-full flex flex-col space-y-2 p-3" style={{ zIndex:'60'}} >

                    <div className="w-full space-y-4 px-4 overflow-hidden" style={{ zIndex:'65'}} >
                        <h4 className="font-medium text-[16px]" >Manage Premission</h4>
                        <div className="flex w-full  flex-col space-y-3 " >
                            <div className="w-full  flex justify-between">
                                <p className="capitalize text-sm" >Workspace</p>
                                <div className="flex space-x-2" >
                                    <p className="capitalize text-sm w-[150px] text-center" >role</p>
                                    <p className="capitalize text-sm text-center w-[60px]" >Access</p>
                                </div>
                            </div>
                            <hr />
                            <div className="flex flex-col space-y-4 max-h-[650px] overflow-y-auto list-industry" style={{ zIndex:"9999" }} >
                                {
                                    WorkSpace?.map((list, index) => {

                                        const isExist = Premission?.findIndex(listobj => list?.project_id === listobj?.project_id)

                                        const listRole = list?.role ?? 'admin';

                                        if( PremissionRole.length < WorkSpace?.length )PremissionRole.push(listRole);

                                        return (
                                            <div className="w-full  flex justify-between" >
                                                <p className="capitalize w-[150px] text-ellipsis whitespace-nowrap" >{list?.project_name}</p>
                                                <div className="flex space-x-2"  >
                                                    <DefaultDropMenu labelTextWeight="hidden" spaceY="" width="w-[140px]" list={UserRole} action={0} SelectIndex={true} onchange={(ind)=>{ PremissionRole[index] = UserRole[ind] ; ManagePermission(UserRole[ind],list?.project_id) } } />
                                                    <SwicthInput labelTextWeight='hidden' margin="my-auto" defaultOPtion={true} onchange={(val) => AccessChange(index, val)} status={false} label="" />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <hr />
                        </div>
                    </div>

                    <div className="flex  justify-end space-x-2" style={{ zIndex:'0'}}>
                        <PrimaryButton buttontype={'secondarybutton'} disabled={loadingLive} width={'w-24'} text={'cancel'} OnClick={() => backwardAction(false)} />
                        <PrimaryButton icon={
                            loadingLive ? <CgSpinner className="w-5 h-5 animate-spin text-white" /> : null
                        } text={"invite"} disabled={loadingLive} width={'w-24'} OnClick={InviteAction} />
                    </div>
                </div>

            </div>
        </div>
    )
}