import React from "react";
import InputWithLabel from "../../input/inputWithLabel";

export const ShowInput = ({ data, giveKey = {}, updateCompain = null }) => {
  return (
    <div className="flex flex-col space-y-4 ">
      {data.map((i, k) => (
        <InputWithLabel
          Placeholder={i?.name}
          label={i?.name}
          onchange={(e) => giveKey[String(i?.type)] = e.target.value}
        />
      ))}
      <div className="mx-auto w-full flex justify-center">
        <button
          onClick={updateCompain}
          className="bg-primary rounded-lg w-1/3  py-3 text-white"
        >
          Connect
        </button>
      </div>
    </div>
  );
};
