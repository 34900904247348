import auth0 from "auth0-js";
import _axios from "../axios";
import { get } from 'lodash';

class Auth0Client {
    constructor () {
        this.auth0 = new auth0.WebAuth({
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
            clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
            redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            responseType: "token id_token",
            scope: "openid profile email"
        });
        this.realm = "Username-Password-Authentication";
    }

    getAuth0 = () => {
        return this.auth0;
    }

    removeCache = () => {
        localStorage.clear();
        sessionStorage.clear();
    }

    login = (user) => {
        this.removeCache();
        this.auth0.authorize({ mode: "login" })
    }



    logout = () => {
        this.removeCache();

        this.auth0.logout({
            redirectUri: process.env.REACT_APP_AUTH0_LOGOUT_URI,
            clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
        });

    }

    handleAuthentication =  ( prop ) => {
      return new Promise((resolve, reject) => {
        try {
            this.auth0.parseHash( async (err,authResult) => {
               
                  if (!authResult) resolve(err);
  
                  if (authResult?.accessToken) { 

                    
                    let { accessToken, idToken, idTokenPayload } = authResult;

                    // console.log(accessToken)
                    let { email, name } = idTokenPayload;

                      _axios.post(`${_axios.defaults.baseURL}/user/login`, { email, name },
                      
                        { headers: { Authorization: `Bearer ${accessToken}`,"x-gz-platform":"engage"} }

                      ).then(res => {
                        if(res?.data?.sucesss) {
                          let logData = res?.data?.data
                          if(!logData?.email_verified ) { alert('Your not verified, please check your mail');this.logout() }
                        
                        }
                        this.setSession(authResult);
                        resolve(true);
                      }).catch(err => {
                        if (!err.response || err.response.status > 500) {
                          this.setSession(authResult);
                         } else if (err.response.status == 422) {
                          alert("You already seem to have an account with this email");
                          // this.logout();
                        }
                      })
                 }
            });
        } catch (e) {  reject(e) }
      })
    }

    setSession = authResult => {
        let expiresAt = JSON.stringify(
          authResult.expiresIn * 1000 + new Date().getTime()
        );
        localStorage.setItem("access_token", authResult.accessToken);
        localStorage.setItem("id_token", authResult.idToken);
        localStorage.setItem("expires_at", expiresAt);
        localStorage.setItem("avatar", authResult.idTokenPayload.picture);

        this.doAnyRedirect()
      };

      doAnyRedirect = () => {
        let redirect_url = sessionStorage.getItem("redirect_url");

        if (redirect_url) {
          sessionStorage.removeItem("redirect_url");
          window.location.href = redirect_url
        }
        else {
          window.location.href = "/";
        }
      }

      // isAuthenticated = () => {
      //   let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
      //   if (new Date().getTime() < expiresAt) {
      //     return true
      //   }
      //   else {
      //     sessionStorage.setItem("redirect_url", window.location.href);
      //     return false;
      //   }
      // };

      handleUnAuthentication() {
        let lastVisit = window.location.pathname;
        localStorage.setItem('lastVisit', lastVisit);

        // swal({
        //   title: "Failed!",
        //   text: 'Session timed out.',
        //   icon: "warning",
        // })
        //   .then(() => {
        //     Swal2.close()
        //     this.login();
        //   });
      }

      getAccessToken() {
        const accessToken = localStorage.getItem("access_token");
        if (!accessToken) {
            return;
        }
        return accessToken;
    }

    headers = async () => {
        return { Authorization: `Bearer ${await this.getAccessToken()}` };
      };

};

const Auth = new Auth0Client();

export default Auth;
