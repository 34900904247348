import { useEffect, useRef, useState } from "react"
import { Chart } from "react-google-charts"
import DefaultDropMenu from "../../../../input/droplist"
import { LoadingSpinner } from "../../../../Loading"



export const RenderedCountryChart = ({ data = {} }) => {

    const [ActiveType, setActiveType] = useState(0)
    const [GraphValue, setGraphValue] = useState([["Country", "Popularity"]])
    const [Datatype, setDatatype] = useState([ 'resp','views', 'click' ])
    const [LoadState,setLoadState] = useState(false)

    useEffect (()=>{
        if(Object.keys(data).length !== 0 ){
            let arr = []
            setLoadState(true)
            setGraphValue([["Country", "Popularity"]])
            setDatatype(Object.keys(data))
            const DataTypeValue = Object.values(data) ?? [] 
            Object.keys(DataTypeValue[0]).map((list,index)=>{
                setGraphValue(obj => [...obj,[list,DataTypeValue[0][list]]])
            })
            // setGraphValue(obj => [...obj,arr])
            // setLoadState(false)

            
        }
    },[data])

    const OnchangeType = (index,type) => {
        setLoadState(true)
        setGraphValue([["Country", "Popularity"]])
        let Setindex = Datatype.indexOf(String(index).toLocaleLowerCase())
        setActiveType(Setindex)
        const DataTypeValue = Object.values(data) ?? [{tablet:0,desktop:0,mobile:0}] 
        Object.keys(DataTypeValue[Setindex]).map((list,index)=>{
        setGraphValue(obj => [...obj,[list,DataTypeValue[Setindex][list]]])

        })

        
    }

    const datatype = [
        ["Country", "Popularity"],
        ["Germany", 200],
        ["United States", 300],
        ["Brazil", 400],
        ["Canada", 500],
        ["France", 600],
        ["RU", 700],
        ["IN", 700]
    ]
    const ref = useRef(null)
    const [width, setWidth] = useState(1000)
    const [height, setHeight] = useState(500)

    useEffect(() => {
        setLoadState(false)
        let mainBlock = document.getElementById('mapblock').style
        setHeight(ref.current.clientHeight)
        setWidth(ref.current.offsetWidth)
    }, [GraphValue])
    return (
        <div className="w-full flex flex-col p-3 rounded-lg relative shadow-md border h-[90%] min-h-[800px] space-y-4" id="mapblock">

            <div className='flex w-full justify-between' >
                <p className="z-30 text-lg font-medium py-4 px-5">
                    Response by countries
                </p>
                <DefaultDropMenu list={Datatype} active={ActiveType} onchange={OnchangeType} labelTextWeight='hidden' />
            </div>


            <div ref={ref} className="w-full relative h-[80%] min-h-[600px]">

                {
                    !LoadState ? 
                    <Chart
                    options={{
                        colors: ["blue"],
                        legend: "none",
                        animation: {
                            startup: true,
                            easing: "linear",
                            duration: 1500,
                        },
                        keepAspectRatio: window.innerWidth < 1400 ? true : false,
                        height: height,//window.innerWidth < 1400 ? 600 : 800,
                        width: width,//window.innerWidth < 1400 ? 1200 : 1400,
                    }}
                    style={{
                        padding: 0,
                        margin: 0,
                        marginTop: 20
                    }}
                    chartType="GeoChart"
                    legendToggle={false}
                    data={GraphValue}
                    width={"100%"}
                    // height={'100%'}
                    height={window.innerWidth < 1400 ? "620px" : "850px"}
                />
                :<LoadingSpinner />
                }

            </div>
            {/* : 
                <div className={`
                    w-full flex items-center justify-center text-sm font-normal
                    ${window.innerWidth < 1400 ? "h-[620px]" : "h-[850px]"}
                `}>
                    no responses yet...
                </div> */}


        </div>
    )
}