import React from 'react'

export const SettingNav = ({ data, active, setActive, prantClasss = "" }) => {
  return <div className={`flex w-full  justify-between ${prantClasss} border-b-2`}>

    {
      data.map((i, k) => (
        <div key={k}
          className={` mx-auto  px-2 flex space-x-3 w-[190px] pb-3 cursor-pointer py-4 ${i?.name === active ? "border-primary text-primary border-b-2" : ""}`}
          onClick={() => setActive(k)} ><div className='flex mx-auto space-x-3'>{i?.svg}<div>{i["name"]}</div></div>
        </div>
      ))
    }
  </div>
}
