export const AppsumoFeatures = () => {
    return({
        "gozenengage_tier1":[
            "All features above included",
            "1 site",
            "15,000 views per month",
            "1 user",
            "10,000 subscribers",
            "100 AI questions per month"
        ],
        "gozenengage_tier2":[
            "All features above included",
            "3 sites",
            "30,000 views per month",
            "2 users",
            "20,000 subscribers",
            "200 AI questions per month",
            "Remove GoZen Branding",
        ],
        "gozenengage_tier3":[
            "All features above included",
            "10 sites",
            "100,000 views per month",
            "4 users",
            "unlimited subscribers",
            "500 AI questions per month",
            "Remove GoZen Branding",
        ],
        "gozenengage_tier4":[
            "All features above included",
            "20 sites",
            "250,000 views per month",
            "6 users",
            "unlimited subscribers",
            "1000 AI questions per month",
            "Remove GoZen Branding",
        ],
        "gozenengage_tier5":[
            "All features above included",
            "unlimited sites",
            "500,000 views per month",
            "10 users",
            "unlimited subscribers",
            "2000 AI questions per month",
            "Remove GoZen Branding",
        ]
    })
}