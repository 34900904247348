import  { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../../Button1/primarybutton";
import { Actions } from "../../../../store";

export const ShowManualProducts = ({fieldId="",trigger=null, filteredProduct=""}) => {

  // const { fieldId } = useSelector((state) => state.EditorFields[0]);

  const dispatch = useDispatch()

  const { fields } = useSelector((state) => ({
    fields: state.fields,
    fieldsOrder: state.fieldsOrder,
    EcommerceFieldID: state.EcommerceFieldID,
  }));

  const AddProduct = (index) => {
    let productId = manualList[index].id
    const IsAlreadtExist = productSL.findIndex(list => list.id == productId )
    if(IsAlreadtExist < 0) {
      dispatch(Actions.setAddProductSelectList({fieldId:fieldId,productData:manualList[index]}));
      dispatch(Actions.setUpdateCampaign())
    }
  }

  const templateData = fields[fieldId];
  const Settings = templateData?.filedContent;
  const manualList = Settings?.game_mainpage_json?.ManualList ?? [];
  const productSL = Settings?.game_resultcreen_json?.productSelectList ?? [];

  const [open, setOpen] = useState(false)

  useEffect(()=>{
    if(filteredProduct==="Manual Products"){
      setOpen(true);
    } else {
      setOpen(false);
    }
  },[filteredProduct])

 


    
  return (
    <div  className="w-full flex flex-col border-b-1 py-2" id="Manual_Products">
      <p className="flex w-full justify-between p-2 px-4 cursor-pointer" onClick={() => {setOpen(!open);trigger(false)}}>
        <span className="font-bold capitalize">Manual Products</span>
        <div className="flex space-x-6">
            <span>
              <span className="text-primary text-lg">0</span>/{[...manualList].length}
            </span>
            <span className="flex items-center" >
              {open ? (
                <svg
                  width="18"
                  height="10"
                  viewBox="0 0 18 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.875 9.50001C16.7272 9.49998 16.5809 9.47082 16.4443 9.4142C16.3078 9.35758 16.1838 9.2746 16.0793 9.17001L10.326 3.41676C10.1519 3.2426 9.94521 3.10445 9.71769 3.01019C9.49017 2.91593 9.24632 2.86741 9.00005 2.86741C8.75378 2.86741 8.50992 2.91593 8.2824 3.01019C8.05488 3.10445 7.84816 3.2426 7.67405 3.41676L1.92755 9.16327C1.71537 9.36819 1.43119 9.48159 1.13622 9.47902C0.841249 9.47646 0.559085 9.35814 0.350501 9.14956C0.141917 8.94098 0.0236018 8.65881 0.0210386 8.36384C0.0184754 8.06887 0.131869 7.78469 0.336797 7.57251L6.0833 1.82601C6.85737 1.05338 7.90637 0.619446 9.00005 0.619446C10.0937 0.619446 11.1427 1.05338 11.9168 1.82601L17.6708 7.58001C17.828 7.73739 17.935 7.93783 17.9783 8.15599C18.0216 8.37416 17.9992 8.60027 17.9141 8.80575C17.8289 9.01122 17.6848 9.18684 17.4998 9.31041C17.3149 9.43399 17.0975 9.49997 16.875 9.50001Z"
                    fill="#586474"
                  />
                </svg>
              ) : (
                <svg width="19" height="10" viewBox="0 0 19 10" fill="none">
                  <path
                    d="M1.13235 0.559233C1.28008 0.559035 1.4264 0.588093 1.56285 0.644733C1.6993 0.701372 1.82318 0.78447 1.92735 0.889233L7.6821 6.64323C7.85621 6.81739 8.06293 6.95555 8.29045 7.04981C8.51797 7.14407 8.76183 7.19258 9.0081 7.19258C9.25437 7.19258 9.49822 7.14407 9.72574 7.04981C9.95326 6.95555 10.16 6.81739 10.3341 6.64323L16.0798 0.896733C16.1836 0.789284 16.3078 0.703579 16.445 0.644619C16.5823 0.585658 16.7299 0.554624 16.8793 0.553326C17.0286 0.552028 17.1768 0.580492 17.315 0.637058C17.4533 0.693624 17.5789 0.777159 17.6845 0.882788C17.7902 0.988418 17.8737 1.11403 17.9303 1.25228C17.9868 1.39054 18.0153 1.53868 18.014 1.68806C18.0127 1.83744 17.9817 1.98506 17.9227 2.12231C17.8638 2.25957 17.778 2.3837 17.6706 2.48748L11.9248 8.23398C11.1508 9.00661 10.1018 9.44055 9.0081 9.44055C7.91441 9.44055 6.86542 9.00661 6.09135 8.23398L0.336596 2.47998C0.179159 2.32265 0.0719315 2.12215 0.0284809 1.90385C-0.0149697 1.68555 0.00730993 1.45927 0.0925005 1.25364C0.177691 1.04802 0.321964 0.872276 0.507063 0.748667C0.692162 0.625057 0.909768 0.559132 1.13235 0.559233Z"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#586474"
                  />
                </svg>
              )}
            </span>
          </div>
      </p>
      <div className={` grid-cols-3 w-full py-3 px-4 gap-4 ${
        open ?  "grid" : "hidden"
      } `}>
        {manualList.map((list,Index) => {
          return (
            <div key={Index} className=" space-y-3 rounded-xl p-4 bg-white border-1 cursor-pointer " onClick={()=>{
               AddProduct  (Index) 

            }} >
              <div className="h-48 flex rounded-xl border-1">
                <div className="flex m-auto max-h-full">
                  <img src={list?.productImageSrc} alt="productimage" className="max-w-full max-h-full object-cover " />
                </div>
                {/* <img src={list?.productImageSrc} alt="prodect" className="max-w-full max-h-full object-cove "/> */}
              </div>
              <p className="text-black text-base">{list?.productName}</p>
              <p className="text-black text-lg font-bold">{list?.price}</p>
              <PrimaryButton text={list?.buttonText} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
