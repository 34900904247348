import Axios from "../axios";

export class CampaignResponseEvent{

    constructor(prop) {
        this.category = prop.category || ''
        this.score = prop.score || 0
        this.site_id = prop.site_id || "f1e37fea-4c21-4fa8-8ee1-61f9290eacb4"
        this.answerlist = prop.answerlist || []
        this.queObj = prop.queObj || []
        this.payload = prop.payload || {}
        this.email = prop.email || []
        this.ConditionState = true
        this.additional_info = prop.additional_info || {}
        this.pollData = prop.pollData || []
        this.campaignId = prop.campaignId ||"f20da4d1-fac5-4004-9702-abaec509c219"
        this.setEmptyStrArr()
    }
    setheader(){
        return {
            headers:{
                "x-ps-id":this.site_id
            }
        }
    }
    isEmptyObj(obj){
        return Object.keys(obj).length > 0
    }
    setEmptyStrArr(){
        this.queObj.map((list)=>this.answerlist.push(''))
    }
    Ruleopertator(leftval='',optionType,rightval){

        if(optionType === "equal to") return (leftval == rightval) ? true : false 
        if(optionType === "not equal to") return (leftval != rightval) ? false : true   
        if(optionType === "contains") return (String(leftval).indexOf(rightval) > -1) ? true : false 
        if(optionType === "does not contains") return (String(leftval).indexOf(rightval) > -1) ? false : true   
    }
    Resultopertator(leftval,optionType,rightval){
        if(optionType === "equal to") return leftval == rightval ? true : false 
        if(optionType === "graterthen") return leftval > rightval ? true : false   
        if(optionType === "lessthen") return leftval < rightval ? true : false 
        // if(optionType === "does not contains") return (String(leftval).indexOf(rightval) > -1) ? false : true   
    }
    pollRes (index,Innerindex) {
        let PollRES =this.pollData
        PollRES[index] =  PollRES[index].total + 1 
        PollRES[index].option[Innerindex] = PollRES[index].option[Innerindex]+1 
        return PollRES
    }
    
    CheckUserResp(userAns="",queObj,Index,Rules={},  ){

        this.answerlist[Index] = userAns

        this.payload[queObj?.question] = userAns

        if (this.category == "poll" || this.category == "quizzes") {

            

            if(queObj?.optionType == "multioption"|| queObj?.optionType == "yesorno"){

                this.payload[queObj?.question] = userAns;

                this.score = (String(queObj?.answer).toLocaleLowerCase() === String(userAns).toLocaleLowerCase()) ? this.score+1 : this.score;

                

            }
            if(queObj?.optionType == "email") {

                this.email = userAns;

                this.score = this.score;

            }

            let nextAction = this.applyRules( Rules, this.score );

            return ({ score: this.score, nextAction })
        }

        let nextAction = this.applyRules( Rules, this.score );

        return ({ score: this.score, nextAction })


    }

    applyRules( Rules, score = 0 ) {

        if(Object.keys(Rules).length > 0) {

            let conditions = Rules?.conditions ? Object.keys(Rules?.conditions) : []

            let conditionsVal = Rules?.conditions ?Object.values(Rules?.conditions) :[]
            
            let ConditionState = false;



            if(conditions.length > 0) {
                
                conditionsVal.map((list,index) => {
                    let leftVal = ( list?.Que == "score" ) ? score : this.payload[list?.Que]  ;
            
                    let rightVal = ("score" == list?.Que ) ? parseInt( list?.value ) : list?.value;

                    if(index !== 0)  {
                        ConditionState = conditionsVal[index-1].condition == "and" ? ConditionState && this.Ruleopertator(leftVal,list?.opertator,rightVal) : ConditionState || this.Ruleopertator(leftVal,list?.opertator,rightVal)
                    }
                    else {

                        ConditionState = this.Ruleopertator( leftVal, list?.opertator , rightVal)
                    }
                })
            }
            if(ConditionState) {

                if(Rules?.action?.type === "Redirect") return window.location.href = Rules?.action?.value ? Rules?.action?.value : "/"
                if(Rules?.action?.type !== "Redirect") return Rules?.action?.value ? Rules?.action?.value : "default"
            }

            return Rules?.DefaultAction ?? "default"
        }

        return "default"

    }

    resultScreen(score,Resultobj){
        let FindResult = Resultobj.findIndex(list => {
            return this.Resultopertator(score,list?.score?.opertator,list?.score?.value)
        })
        return FindResult > -1 ? FindResult: 0 ; 
    }

    

    subcripe(payload,recaptcha_token) {
        payload['customer'] = '';
        payload['campaign_id'] = this.campaignId;
        payload['site_id'] = this.site_id;
        payload['accepts_marketing'] = '1';
        payload['additional_info'] = JSON.stringify(this.additional_info) ;
        payload["deviceInfo"]= {
            "userAgent": "Mozilla/5.0 (Linux; Android 6.0; Nexus 5 Build/MRA58N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/103.0.0.0 Mobile Safari/537.36",
            "country": "IN",
            "ip": "2401:4900:4de2:2857:ba36:98ed:8afa:add8",
            "origin": "localhost:3000"
        }

        return new Promise (async (resolve,reject)=>{
            try{
    
                const res = await Axios.post(`/client/user/subscribe` , payload, this.setheader());

                if(res.status === 200) resolve(res.data);
    
            }catch(err){
                reject(err);
            }
        })
    } 
}