import React from 'react'
import PropTypes from "prop-types";

const Title = function ({ text, textColor = "text-primary-bold", margin = "", lineHeight = "", textSize = "text-18px", fontStyle = "font-medium", className }) {

    if (("" + textSize).indexOf('text') < 0) textSize = "text-" + textSize;

    return (
        <h3 className={`${margin} ${fontStyle} ${textSize} ${textColor} ${lineHeight} ${className}`}>{text}</h3>
    )
}



Title.prototype = {
    text: PropTypes.string.isRequired,
    textColor: PropTypes.string,
    margin: PropTypes.string,
    textSize: PropTypes.string
}

export { Title }