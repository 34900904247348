import React from "react"
import { Imageinput } from "../../input/imageinput"
import TextareaInput from "../../input/textarea"
import InputWithLabel from "../../input/inputWithLabel"
import { MediaPopup } from "../../popup/Mediapopup"
import { useDispatch } from "react-redux"
import { Actions } from "../../../store"
import PrimaryButton from "../../Button1/primarybutton"
import { useState } from "react"
import Input from "../../input/input"

export const ResultInnerBlock = ({ field = {}, SelcetIndex = 0, resultIndex = 0, fieldId = '', setActiveSubMenuStage = null, formula = [] }) => {
    const Dispatch = useDispatch();

    const [ShowMedia, setShowMidia] = useState(false);

    const CalResult = field?.results[resultIndex]?.Calresults[SelcetIndex]

    const ContentChange = (e, type, Checkstate = false) => {

        let stateCheck = false;

        const IsCheckBox = e.target.type == 'checkbox' ? true : false;

        if (IsCheckBox) stateCheck = !Checkstate ? true : false

        const SetValue = IsCheckBox ? stateCheck : e.target.value

        const setContent = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_resultcreen_json',
            child: "results",
            resultIndex: resultIndex,
            edge: "Calresults",
            CalIndex: SelcetIndex,
            Calkey: type,
            value: SetValue
        }

        Dispatch(Actions.setFieldTemplateREsulCaltCC(setContent))

    }

    const DirectContentChange = (value, type) => {
        const setContent = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_resultcreen_json',
            child: "results",
            resultIndex: resultIndex,
            edge: "Calresults",
            CalIndex: SelcetIndex,
            Calkey: type,
            value: value
        }

        Dispatch(Actions.setFieldTemplateREsulCaltCC(setContent))

        // const setContent = {
        //     isCommonSetting: false,
        //     key: fieldId,
        //     node: 'game_resultcreen_json',
        //     child: type,
        //     value: value
        // }
        // Dispatch(Actions.setFieldTemplateContentChange(setContent))
        if (ShowMedia) setShowMidia(false)

    }
    return (

        <div className="w-full space-y-4 ">

            {ShowMedia && <MediaPopup cancel={setShowMidia} fieldType={'imagesrc'} OnSelect={DirectContentChange} />}

            <div className="w-full space-y-4 ">

                <InputWithLabel label="Header" textvalue={CalResult?.title} onchange={(e) => ContentChange(e, 'title')} viewiconState={true} labelTextWeight="flex justify-between" eyeStatus={field?.titleStatus} />

                <TextareaInput label="Description" textvalue={CalResult?.description} onchange={(e) => ContentChange(e, 'description')} />

                <Imageinput label="Cover image" src={CalResult?.imagesrc} chanaeAction={() => setShowMidia(true)} deleteSrc={() => DirectContentChange('', 'imagesrc')} />
                <InputWithLabel label="Calculation result" textvalue={CalResult?.finalResult ?? `{Final_Calculation}`} onchange={(e) => ContentChange(e, 'finalResult')} viewiconState={true} labelTextWeight="flex justify-between" />

                <InputWithLabel label="button" textvalue={CalResult?.button} onchange={(e) => ContentChange(e, 'button')} viewiconState={true} labelTextWeight="flex justify-between" eyeStatus={field?.titleStatus} />
                <InputWithLabel label="Button Link" textvalue={CalResult?.buttonlink} onchange={(e) => ContentChange(e, 'buttonlink')} viewiconState={true} labelTextWeight="flex justify-between" eyeStatus={field?.titleStatus} />

                {
                    (formula[SelcetIndex]) ? <div className="w-full space-y-3">
                        <label className="text-base capitalize " >Formula</label>
                        <div className="w-full border-2 flex justify-between rounded-md px-4 py-2 bg-primary-lightblue border-primary text-primary">
                            <p className="w-[200px] my-auto text-ellipsis whitespace-nowrap overflow-hidden uppercase" >{formula[SelcetIndex]}</p>
                            <span className="p-2 border-2 flex my-auto rounded-md bg-primary-lightblue border-primary cursor-pointer" onClick={() => setActiveSubMenuStage(1)}>
                                <svg width="17" height="17" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 15.46V18.5C0 18.78 0.22 19 0.5 19H3.54C3.67 19 3.8 18.95 3.89 18.85L14.81 7.94L11.06 4.19L0.15 15.1C0.0500001 15.2 0 15.32 0 15.46ZM17.71 5.04C18.1 4.65 18.1 4.02 17.71 3.63L15.37 1.29C14.98 0.899998 14.35 0.899998 13.96 1.29L12.13 3.12L15.88 6.87L17.71 5.04Z" fill="#2563EB" />
                                </svg>
                            </span>
                        </div>
                    </div>
                        : <PrimaryButton text={'Add Formula'} width="w-full" height="h-12" backgroundColor="bg-primary-lightblue" border="border-1 border-primary" textcolor="text-primary" OnClick={() => setActiveSubMenuStage(1)} />

                }


            </div>

        </div>
    )
}