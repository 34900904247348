import React, { useEffect, useState } from 'react'
import { SettingNav } from '../../SettingNav'
import { Summary } from './Summary'
import { Plans } from './Plans'
import { Usage } from './Usage'
import { ShowSettingPage } from '../../ShowSettingPage'
import { ShowBillingPage } from './ShowBillingPages'
import { getUserPLan } from '../../../../actions/Billing'
import toast from 'react-hot-toast'
import { LoadingSpinner } from '../../../Loading'
import { useDispatch, useSelector } from 'react-redux'
import { Actions } from '../../../../store'

const data = [
  {
    name: "Summary",
    commponet: <Summary />
  },
  {
    name: "Plans",
    commponet: <Plans />
  },
  {
    name: "Usage",
    commponet: <Usage />
  }
]



export const Main = (state = true) => {
  // const [nav, setNav] = useState("Summary")
  const [active, setActive] = useState("Summary")

  const [loading,setloading] = useState(false); 

  const [userBillingDetails,SetUserBillingDetails] = useState({})

  const { userPlan } = useSelector(state => ({ userPlan: state?.userPlan }))

  const Dispatch = useDispatch()

  useEffect( () => {fetchData();setActive('Summary')} , [userPlan])
  
  const fetchData = async () => {
    setloading(true)
    const BillingDetail = await getUserPLan();
    if (BillingDetail?.success) {
      Dispatch(Actions.setPlanCalculation(BillingDetail.data))
      SetUserBillingDetails(BillingDetail.data)
    }

    else toast.error(BillingDetail?.message)
    setloading(false)

  }

  return (<div className='relative flex flex-col w-full h-full ' >

    <SettingNav data={data} prantClasss='w-1/3' active={active} setActive={(ind)=>setActive(data[ind]?.name)} />

    <div className='flex flex-col w-full h-full overflow-y-auto'>

     { !loading ? <ShowBillingPage userBillingDetails={userBillingDetails} setActive={setActive} state={active} /> : <LoadingSpinner /> }

    </div>

  </div>)


}
