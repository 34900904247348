import { CardElement, PaymentElement,useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import PrimaryButton from "../../../Button1/primarybutton";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { createUserPLan, updateUserPLan } from "../../../../actions/Billing";
import { Actions } from "../../../../store";

export const PaymentCard = ({ cardInfo = {} ,checkoutPlanDetail={} }) => {


    const { userPlan, planCal } = useSelector(state => ({ userPlan: state?.userPlan , planCal:state?.planCalculation }))
    const { changePlan, changetype } = planCal 
    const [userPlanDiscount, setUserPlanDiscount] = useState(planCal?.redeem_coupon?.discount_attempts ?? {})

    const DisPatch = useDispatch()

    const elem = useElements()
    const stripe = useStripe()

    const [isProcess,SetProcess] = useState(false) 

    const OnProcessCard = async (e) => {
        try {
            e.preventDefault()
            const cardEle = elem?.getElement("card")
            const newToken = await stripe?.createToken(cardEle)
            if(!newToken && newToken?.error ) return toast.error(newToken?.error?.messade)  
            if(newToken && userPlan !== 'free') { CreateUSerPlan(newToken?.token?.id); return }
            else return
        } catch (err) {
            toast.error('Invaild Card')
        }

    }

    const CreateUSerPlan = async (token)=>{
        SetProcess(true)
        try {
            if( changePlan ) {
                if(!token) {SetProcess(false);return toast.error('Can\'t Create plan')}
                const CreatePlan = await createUserPLan( changePlan, changetype , token , userPlanDiscount?.code ?? '')
                if(CreatePlan?.success) {
                    toast.success('your plan successfully to '+ changePlan)
                    DisPatch(Actions?.PlaUpdateTrigger({plan:changePlan,type:changetype}))
                }
                else toast.error(CreatePlan?.error?.message  )
                return SetProcess(false);
            }
            
            else toast.error( ' your change plan not specified. ' )

        }catch(err) {
            SetProcess(false)
            toast.error('Can\'t upGrade The Plan ' )
        }
        SetProcess(false)
    }

    const UpdateUserPlan = async () => {
        SetProcess(true)

        try {
            if( changePlan ) {
                const CreatePlan = await updateUserPLan( changePlan, changetype, userPlanDiscount?.code ?? '' )
                if(CreatePlan?.status) {
                    toast.success('your plan successfully to '+ changePlan)
                    DisPatch(Actions?.PlaUpdateTrigger({plan:changePlan,type:changetype}))

                }
                else toast.error(CreatePlan?.error?.message  )
                return SetProcess(false);
            }
            
            else toast.error( ' your change plan not specified.! ' )

        }catch(err) {
            toast.error('Can\'t upGrade The Plan ' )
        }
        SetProcess(false)

    }


    return (

        <>

            {userPlan?.plan !== 'free' ?

                <div className="flex flex-col space-y-3" >
                    <p className="text-lg text-center font-medium capitalize"> 
                        Your about to change your plan to {checkoutPlanDetail?.changePlan}
                    </p>
                    {/* <div className="border border-black p-2.5 py-4 rounded-md flex justify-between font-normal" >
                        <p className="flex space-x-4" > <span className="flex my-auto" > {cardInfo?.brand}</span><span className="flex my-auto" >**** **** **** { cardInfo?.last4 }</span> </p>
                        <p>  {cardInfo.exp_month} / {cardInfo.exp_year}</p>
                    </div> */}
                    <div className="flex flex-col space-y-2 w-full">
                        <PrimaryButton width="w-full" textsize="text-lg" padding_y="py-2" height="h-auto" text={'conform'} disabled={isProcess} OnClick={() => UpdateUserPlan()} />
                        <PrimaryButton width="w-full" textsize="text-lg" border="border-1" height="h-auto" disabled={isProcess} style={{ color: "black", background: "white" }} text={'Cancel'} />
                    </div>
                </div>

                :

                <form className="flex flex-col space-y-3" >
                    <PaymentElement />
                    {/* <CardElement className="border border-black p-2.5 py-4 rounded-md text-lg font-normal" /> */}
                    <div className="flex space-x-2">
                        <PrimaryButton width="w-24" textsize="text-lg" text={'pay -test'} disabled={isProcess} OnClick={(e) => OnProcessCard(e)} />
                        <PrimaryButton width="w-24" disabled={isProcess} style={{ color: "black", background: "white" }} text={'Cancel'} />
                    </div>

                </form>

            }

        </>


    )
} 