import { type } from "@testing-library/user-event/dist/type";
import React, { useEffect, useRef } from "react";
import toast from 'react-hot-toast'

export const CustomDomainRecord = ({
    backwardAction = null,
    position = 'fixed',
    className = '',
    Domain = 'test.com'

}) => {

    const content = [
        {
            type: "A",
            name: '@',
            value: "76.76.21.21"
        },
        {
            type: "CNAME",
            name: 'WWW',
            value: "cname.vercel-dns.com."
        }
    ]
    const copy = ( val ) => {   
        navigator.clipboard.writeText(val);
        toast.success("Copied!");

    }

    const menuREf = useRef(null);

    useEffect(() => {
        let handleAction = (event) => {

            if (menuREf.current && !menuREf.current?.contains(event.target)) {
                backwardAction(false);
            }
        };

        document.addEventListener("mousedown", handleAction);
    }, [])

    return (
        <div className={`${position} top-0 left-0 flex w-full h-full bg-backgroundShadow`} style={{ zIndex: '55' }}>

            <div className={`p-4 w-[450px] rounded-lg bg-white m-auto space-y-2 ${className} `} ref={menuREf} >

                <div className="flex justify-between border-b-1 pb-2" >
                    <h3 className="text-16px font-medium ">Add custom Domain</h3>
                    <span className="flex my-auto" onClick={()=>backwardAction(false)} >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.3 5.71C17.91 5.32 17.28 5.32 16.89 5.71L12 10.59L7.10997 5.7C6.71997 5.31 6.08997 5.31 5.69997 5.7C5.30997 6.09 5.30997 6.72 5.69997 7.11L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.11C18.68 6.73 18.68 6.09 18.3 5.71Z" fill="#586474" />
                        </svg>
                    </span>
                </div>

                <div className=" flex flex-col space-y-2" >

                    {
                        content.map((list, index) => {

                            const keyValue = Object.keys(list);

                            return (

                                <div className="flex flex-col space-y-2" >

                                    <h3 className="font-medium" >{(index == 1 ? `www.${Domain}` : Domain)}</h3>

                                    <p className="text-12px" >Set the following record on your DNS provider to continue:</p>

                                    <div className="flex flex-col space-y-2 p-2 px-4 rounded-md bg-black text-white" >

                                        <div className="flex space-x-5">

                                            {
                                                keyValue.map((obj, ind) => {
                                                    return (
                                                        <p className={`flex flex-col space-y-2 ${ind == 2 ? 'w-full' : ""}`} >
                                                            <span className={`capitalize ${ind == 2 ? 'w-full' : ""}`} >{obj}</span>
                                                            <span className={`flex justify-between ${ind == 2 ? 'w-full' : "w-auto"}`} >{list[obj]}
                                                                {<span className={`${ind == 2 ? '' : "hidden"} cursor-pointer`} onClick={()=>copy(list[obj])} >
                                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M11.1 22.75H6.9C2.99 22.75 1.25 21.01 1.25 17.1V12.9C1.25 8.99 2.99 7.25 6.9 7.25H11.1C15.01 7.25 16.75 8.99 16.75 12.9V17.1C16.75 21.01 15.01 22.75 11.1 22.75ZM6.9 8.75C3.8 8.75 2.75 9.8 2.75 12.9V17.1C2.75 20.2 3.8 21.25 6.9 21.25H11.1C14.2 21.25 15.25 20.2 15.25 17.1V12.9C15.25 9.8 14.2 8.75 11.1 8.75H6.9Z" fill="#ffffff" />
                                                                        <path d="M17.1 16.75H16C15.59 16.75 15.25 16.41 15.25 16V12.9C15.25 9.8 14.2 8.75 11.1 8.75H8C7.59 8.75 7.25 8.41 7.25 8V6.9C7.25 2.99 8.99 1.25 12.9 1.25H17.1C21.01 1.25 22.75 2.99 22.75 6.9V11.1C22.75 15.01 21.01 16.75 17.1 16.75ZM16.75 15.25H17.1C20.2 15.25 21.25 14.2 21.25 11.1V6.9C21.25 3.8 20.2 2.75 17.1 2.75H12.9C9.8 2.75 8.75 3.8 8.75 6.9V7.25H11.1C15.01 7.25 16.75 8.99 16.75 12.9V15.25Z" fill="#ffffff" />
                                                                    </svg>

                                                                </span>}
                                                            </span>
                                                        </p>
                                                    )
                                                })
                                            }


                                        </div>

                                    </div>

                                </div>

                            )
                        })
                    }

                </div>
            </div>
        </div>
    )
}