import React, { useEffect, useState } from "react";
import { AppsumoFeatures } from "../../../../content/appsumoFeature";
import { useDispatch, useSelector } from "react-redux";
import { Usage } from "./Usage";
import { getUserPLan } from "../../../../actions/Billing";
import { Actions } from "../../../../store";
import toast from 'react-hot-toast'

export const AppsumoBillingFeature = () => {

    const appsumoFeature = AppsumoFeatures()

    const { userinfo } = useSelector(state => ({ userinfo: state.userPlan }))
    const [ loading, setloading ] =  useState()
    const [ feature, setFeature ] =  useState({})

    const Dispatch = useDispatch()

    useEffect( () => {fetchData();} , [])
    
    const fetchData = async () => {
      setloading(true)
      const BillingDetail = await getUserPLan();
      if (BillingDetail?.success) {
        setFeature(BillingDetail.data?.usage)
        Dispatch(Actions.setPlanCalculation(BillingDetail.data))

      }
  
      else toast.error(BillingDetail?.message)
      setloading(false)
  
    }

    const gateway = userinfo?.gateway ?? "local"
    const appsumoplan = userinfo?.appsumo_plan ?? "gozenengage_tier1"

    return (
        <div className="flex  space-x-5 py-4" >
            <div className="flex flex-col space-y-4" >
            <div className="flex  space-x-2">
                <h4 className="font-medium" >Plan:</h4>
                <h4 className="capitalize text-primary ml-4 my-auto" > {appsumoplan} </h4>
            </div>
            <div className="flex flex-col space-y-2" >
                <h4 className="font-medium">Features:</h4>
                <div className="flex flex-col space-y-2 ml-4" >

                    {
                        appsumoFeature[appsumoplan]?.map(list => {
                            return (
                                <p className="flex space-x-2 " >
                                    <span>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.00033 0.666016C4.40033 0.666016 0.666992 4.39935 0.666992 8.99935C0.666992 13.5993 4.40033 17.3327 9.00033 17.3327C13.6003 17.3327 17.3337 13.5993 17.3337 8.99935C17.3337 4.39935 13.6003 0.666016 9.00033 0.666016ZM6.74199 12.5744L3.75033 9.58268C3.42533 9.25768 3.42533 8.73268 3.75033 8.40768C4.07533 8.08268 4.60033 8.08268 4.92533 8.40768L7.33366 10.8077L13.067 5.07435C13.392 4.74935 13.917 4.74935 14.242 5.07435C14.567 5.39935 14.567 5.92435 14.242 6.24935L7.91699 12.5744C7.60033 12.8994 7.06699 12.8994 6.74199 12.5744Z" fill="#49D57A"></path></svg>
                                    </span>
                                    <span>
                                        {list}
                                    </span>
                            </p>
                            )
                        })
                    }
                </div>
            </div>
            </div>

            <Usage featureUsage={feature} gateway={"appsumo"} plan={appsumoplan} />
        </div>
    )

}