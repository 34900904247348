import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from ".";
import { LastModified } from "../utils/dateconvetor";
import { useSelectionAction } from "./customstate/context";
import SearchBar from "../components/input/searchbar";
import { FilterComponent } from "../components/FilterComponent";
import { LoadingSpinner } from "../components/Loading";
import PrimaryButton from "../components/Button1/primarybutton";
import { createProject, deleteProject, getProjects, updateProject } from "../actions/projects";
import toast from "react-hot-toast";
import { SubMenu } from "../components/submenu";
import { ProjectCreate } from "../components/popup/projectCreate";
import { MediaPopup } from "../components/popup/Mediapopup";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../store";
import { WorkSpaceActiveUser } from "../components/ActiveUser";



const ProjectList = () => {
    const { completedTutorial  } = useSelector((state) => ({ completedTutorial: state.completedTutorial }))

    const {sidebarstate,setSidebarState} = useSelectionAction();
    setSidebarState(2)

    const [projects, setProjects] = useState([])
    const [loading, setLoading] = useState(false)
    const [showMedia, setShoeMedia] = useState(false)
    const [trigger, settrigger] = useState('false')
    const [createProjectstate, setcreateProject] = useState(false)
    const [onChangePN, setOnchangePN] = useState('My Project')
    const [onChangeSrc, setOnchangeSrc] = useState('')
    const [shoeEditer, setshoeEditer] = useState(false)
    const [name, setName] = useState("")
    const [seletedProject, setSeletedProject] = useState(0)
    const [ShowloadingSpin, setShowloadingSpin] = useState(false);


    const hotname = process.env.REACT_APP_ENV == "development" ? 'http://loacalhost:3000/' : 'https://app.dev.engage.gozen.io/'

    const Navigate = useNavigate()
    const DisPatch = useDispatch()

    useEffect(() => {

        let projectData = projects[seletedProject]

        setOnchangePN(projectData?.project_name ?? "")
        setOnchangeSrc(projectData?.project_img_src ?? "")

    }, [seletedProject])

    useEffect(() => {
        fetchData()

    }, [])


    const fetchData = async () => {
        setLoading(true)
        const ProjectList = await getProjects()
        if (ProjectList?.status) {
            setProjects(ProjectList?.data ?? [])

            let projectData = ProjectList?.data[seletedProject]

            setOnchangePN(projectData?.project_name ?? "")
            setOnchangeSrc(projectData?.project_img_src ?? "")


            DisPatch(Actions?.setProjects(ProjectList?.data ?? []))
            setLoading(false)
        }
        else {
            toast.error('Can\'t retrive the your Project')
            setLoading(false)
        }

    }

    const SortByName = () => {
        const data = [...projects].sort((a, b) => b.project_name < a.project_name ? 1 : -1)
        setProjects(data)

    }

    const SortByDate = () => {
        const data = [...projects].sort((a, b) => a.createdAt < b.created_at ? 1 : -1)
        setProjects(data)

    }

    const projectsdata = projects.filter((a) => String(a.project_name).toLowerCase().includes(name))

    const mediaOnSelect = (src, type) => {
        setOnchangeSrc(src)
    }

    const createProjectFun = async () => {

        setShowloadingSpin(true)

        const createProjectDetail = await createProject(onChangePN, onChangeSrc)
        if (createProjectDetail?.status) toast.success(`Workspace was created!`)
        else toast('Something went wrong!')
        fetchData()
        setcreateProject(false)
        setShowloadingSpin(false)
    }

    const updateProjectFun = async () => {
        setShowloadingSpin(true)
        let payload = { project_id: projects[seletedProject]?.project_id, project_name: onChangePN, project_img_src: onChangeSrc }

        const createProjectDetail = await updateProject(payload)
        if (createProjectDetail?.status) toast.success(`Workspace was updated!`)
        else toast('Something went wrong!')
        fetchData()
        setshoeEditer(false)
        setShowloadingSpin(false)
    }

    const DeleteCampaign = async () => {

        const project_id = projects[seletedProject]?.project_id

        const Deldata = await deleteProject(project_id)

        if (Deldata?.status) toast.success(`Workspace "${projects[seletedProject]?.project_name}" deleted!`)
        else toast('Something went wrong!')
        fetchData()

    }
    const SubMenuEvent = [
        {
            name: "open",
            event: () => Navigate(`/workspace/${projects[seletedProject]?.project_id}`),
        },
        {
            name: "edit",
            event: () => {
                setshoeEditer(true)
                let projectData = projects[seletedProject]
                setOnchangePN(projectData?.project_name ?? "")
                setOnchangeSrc(projectData?.project_img_src ?? "")
            
            },
        },
        {
            name: "delete",
            event: () => DeleteCampaign(),
        },
    ]

    return (
        <>

            {showMedia && <MediaPopup OnSelect={mediaOnSelect} cancel={(val) => { setShoeMedia(false); (trigger == 'create') ? setcreateProject(true) : setshoeEditer(true) }} fieldType="src" />}
            <Layout isactive={0}>


                <div className={"flex flex-col w-full h-full"} >

                    {shoeEditer && <ProjectCreate loadingLive={ShowloadingSpin} project_id={projects[seletedProject].project_id} forwardAction={updateProjectFun} defaultSrc={onChangeSrc} onProfile={(val) => { setShoeMedia(val); settrigger('update') }} changeDefaultVaule={setOnchangePN} defaultVaule={onChangePN} projectData={projects[seletedProject]} title="Edit workspace" backwardAction={(val) => { setshoeEditer(val) }} />}
                    {createProjectstate && <ProjectCreate loadingLive={ShowloadingSpin} title="Create workspace" defaultSrc={onChangeSrc} onProfile={(val) => { setShoeMedia(val); settrigger('create') }} changeDefaultVaule={setOnchangePN} defaultVaule={onChangePN} backwardAction={(val) => { setcreateProject(val) }} forwardAction={createProjectFun} />}

                    <div className="flex flex-col h-auto ">
                        <div className="flex space-x-2 justify-between" >
                            <h2 className="pb-5  font-bold text-22px text-primary-bold  capitalize my-atuo">Workspace</h2>
                            <div className="flex  py-2 sticky top-0 z-50 justify-end space-x-2">
                                <span className="flex flex-row relative step-myWorkSpace-filter">
                                <FilterComponent SortByDate={SortByDate} SortByName={SortByName} />
                                <SearchBar iconposition="left" border="border-2" setName={setName} />
                                </span>
                                <PrimaryButton text={'Create Workspace'} OnClick={() => { setcreateProject(true); setOnchangeSrc(""); setOnchangePN("My Workspace") }} textsize='text-14px step-myWorkSpace-crtWrkSpcBtn' />
                            </div>
                        </div>

                    </div>

                    <div className={`relative  w-full grid ${(loading || projectsdata.length == 0) ? "grid-cols-1" : "grid-cols-3"} gap-6 h-full py-4 px-4 overflow-y-auto list-industry`}>

                        {

                            loading ? <LoadingSpinner text={""} /> : projectsdata.length > 0 ?

                                <>
                                    {
                                        projectsdata?.map((list, index) => {
                                            return (
                                                <div className="w-full h-fit flex flex-col p-3 border-1 hover:shadow-bgshadow projectpostcontainer rounded-lg bg-white space-y-2" onClick={() => Navigate(`/workspace/${list?.project_id}`)} >
                                                    <div className="projectpostthumbnail ">
                                                        <img src={list?.project_img_src ?? 'https://gz-engage-assets.s3.amazonaws.com/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/c7a655060994272bcf9a99a5afde2eaf.jpg'} className="projectpostthumbnailimg" alt="" />
                                                    </div>
                                                    <div className="w-full flex justify-between" >

                                                        <div className="flex flex-col " >

                                                            <p className="text-[16px] font-medium" >{list?.project_name}</p>
                                                            <p className="text-sm" >{LastModified(list?.created_at)}</p>
                                                        </div>

                                                        <WorkSpaceActiveUser  accessible_users={list?.member_info ?? []} />
                                                        <SubMenu data={SubMenuEvent} id={index} setSeletedProject={setSeletedProject} />
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }
                                </>
                                :   <div className="flex flex-col space-y-2 h-full w-full justify-center">
                                <img src="/asset/images/dashboardempty.png" alt="" className="mx-auto h-[250px]" />
                                <PrimaryButton text={'Create Workspace'} width="w-fit px-4" margin="mx-auto" OnClick={() => {setcreateProject(true);  setOnchangeSrc(""); setOnchangePN("My Workspace") }} />
                            </div> 
                        }
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default ProjectList