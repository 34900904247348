import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title,  Tooltip, Legend, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import DefaultDropMenu from '../../../../input/droplist';

export const HorBarChart = ({data =[]}) => {

    ChartJS.register( CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend );


    const [ActiveType, setActiveType] = useState(0)
    const [GraphValue,setGraphValue] = useState([])
    const [labels,setLable] = useState([]);
    const [Datatype, setDatatype] = useState(['views', 'click', 'resp']);

    useEffect ( ()=>{
        if(data?.length !== 0 ){
            setLable([]);setGraphValue([]);
            let arr = data.sort((val1,val2) => val2[Datatype[0]]-val1[Datatype[0]])
            arr.map(list => {
                setLable(obj => [...obj,list?.domain])
                setGraphValue(obj => [...obj,list[Datatype[0]]])
            })
        }

    },[data] )

    const OnchangeType = (index,type) => {
        setLable([]);setGraphValue([]);
        let Setindex = Datatype.indexOf(String(index).toLocaleLowerCase())
        setActiveType(Setindex)
        let arr = data.sort((val1,val2) => val2[Datatype[Setindex]]-val1[Datatype[Setindex]])
        arr.map(list => {
            setLable(obj => [...obj,list?.domain])
            setGraphValue(obj => [...obj,list[Datatype[Setindex]]])
        })
    }



    const datalist = {
        labels,
        datasets: [
            {
                label: 'Event',
                data: GraphValue,
                borderColor: ["#2563EB", "#FDC500", "#8AC926"],
                backgroundColor: ["rgba(37, 99, 235,0.7)", "gba(253, 197, 0,0.7)", "gba(138, 201, 38,0.7)"],
            },
        ],
    };

    return (
        <div className="h-full w-full p-3 rounded-lg bg-white relative shadow-lg border">

            <div className='flex w-full ' >
                <p className="z-30 text-lg my-auto font-medium py-4 px-5">
                    Top 3 response site based on 
                </p>
                <DefaultDropMenu list={Datatype} onchange={OnchangeType} active={ActiveType} labelTextWeight='hidden' />
            </div>
            <div className="w-full py-2 h-[80%] mt-1">
                <Bar options={{
                       responsive: true,
                       maintainAspectRatio: false,
                       spanGaps: true,
                       plugins: {
                           legend: {
                               display: false
                           },
                       },
                       indexAxis: 'y',
                       elements: {
                           bar: {
                               borderWidth: 2,
                           },
                       },
                       scales:{
                           x: {
                               grid: {
                                   display: true,
                                   color:'rgba(0,0,0,0.03)'
                               },

                           },
                           y: {
                               grid: {
                                   display: false
                               },

                           },
                       }
                }} data={datalist} />
            </div>
        </div>
    )
}


