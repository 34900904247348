import React, { useEffect, useRef } from "react";
import TextareaInput from "../../input/textarea";
import { toast } from "react-hot-toast";
import { getRenderCode } from "../../../content/scriptCode";

export const IframePopup = ({
  campaign_id = "",
  backwardAction = null,
  inlineIframeSize = ["100%", "450px"]
}) => {
  let renderUrl = getRenderCode(campaign_id)

  let scriptcode = `<iframe src="${renderUrl}" width="${inlineIframeSize[0]}" height="${inlineIframeSize[1]}" style="border:none !important; background:white !important;"></iframe>`


  const copy = (type) => {
    navigator.clipboard.writeText(scriptcode);
    toast.success("Copied!");
    backwardAction(false)
  }


  const modalRef = useRef();

  useEffect(() => {
    const handleAction = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        backwardAction(false);
      }
    };

    document.addEventListener("mousedown", handleAction);

    return () => {
      document.removeEventListener("mousedown", handleAction);
    };
  }, [backwardAction]);

  return (
    <div className="animate-fadeIn fixed top-0 left-0 backgroundShadow w-full h-full flex justify-center duration-300" style={{ background: "rgb(0, 0 ,0, 0.15)", zIndex: "11" }}>
      <div
        ref={modalRef}
        className="p-8 w-[600px] h-[230px] rounded-xl flex justify-center items-center bg-white shadow-md m-auto z-[20]"
      >
        <div className="flex flex-col space-y-2 duration-500 w-full h-full justify-center">
          <div className="w-full flex justify-between items-center">
            <div className="text-[14px] font-bold ml-1">Get your Iframe code</div>
            <div>
              <svg onClick={() => { backwardAction(false) }} className="cursor-pointer" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" rx="6" fill="#ebebeb" />
                <g clip-path="url(#clip0_4226_10182)">
                  <path d="M23.6749 16.3308C23.4474 16.1033 23.0799 16.1033 22.8524 16.3308L19.9999 19.1774L17.1474 16.3249C16.9199 16.0974 16.5524 16.0974 16.3249 16.3249C16.0974 16.5524 16.0974 16.9199 16.3249 17.1474L19.1774 19.9999L16.3249 22.8524C16.0974 23.0799 16.0974 23.4474 16.3249 23.6749C16.5524 23.9024 16.9199 23.9024 17.1474 23.6749L19.9999 20.8224L22.8524 23.6749C23.0799 23.9024 23.4474 23.9024 23.6749 23.6749C23.9024 23.4474 23.9024 23.0799 23.6749 22.8524L20.8224 19.9999L23.6749 17.1474C23.8966 16.9258 23.8966 16.5524 23.6749 16.3308Z" fill="#586474" />
                </g>
                <defs>
                  <clipPath id="clip0_4226_10182">
                    <rect width="14" height="14" fill="white" transform="translate(13 13)" />
                  </clipPath>
                </defs>
              </svg>

            </div>
          </div>
          <div className="flex justify-between space-x-2">
            <div className="relative w-full h-full">
              <TextareaInput
                spellCheck={"false"}
                textvalue={scriptcode}
                labelTextsize="hidden"
                CustomClass="h-[125px] w-[100%] text-[#06152D] text-[14px]"
                bgColor="bg-[#F9FAFB]"
              />
              <button
                onClick={copy}
                className="absolute left-[12px] bottom-[12px] text-12px bg-white rounded-md my-auto text-primary border-1 border-primary font-semibold py-2 px-3"
              >
                Copy Embed Code
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
} 