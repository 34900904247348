import React, { useState } from 'react'
import Axios from '../../axios'

export const MoveCampaign = (campaignID,fromProjectID,toProjectID) => {

    return new Promise (  (resolve,reject)=> {
        let obj={
            "campaign":{
              "campaign_id":campaignID,
              "preview_project_id":fromProjectID,
              "moveTo_project_id":toProjectID
            }
          }
        Axios.post( `campaign/moveto`,obj).then(res => 
            resolve(res['data'])
            ).catch(err =>reject(err))
    } )

}
