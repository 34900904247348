import React, { useEffect, useState } from "react";
import InputWithLabel from "../input/inputWithLabel";
import { useDispatch, useSelector } from "react-redux";
import { DefaultContent } from "../../content/EditorSetting";
import { Actions } from "../../store";
import toast from "react-hot-toast"



export const DefaultMessageEditor = ({ close = null }) => {

    const { campaignSettings } = useSelector(state => ({ campaignSettings: state?.campaignSettings }))

    const Dispatch = useDispatch();

    const { defaultContent } = campaignSettings

    const [active, setactive] = useState(false);

    useEffect(() => {
        setTimeout(() => setactive(true), 10)
    }, [])

    const closeButtom = () => {
        setactive(false)
        setTimeout(() => { close(false); }, 450);
    };

    const SettingsCC = ( type, value, maxlength ) => {

        if( maxlength < value?.length ) return toast.error(`The maximum Char length is only ${maxlength} for this field.`)

        Dispatch(Actions.campaignSettingsDCCC({ type, value }))

    }

    return (

        <div className="fixed top-[75px] bottom-0 left-0  flex w-full h-full  bg-[#00000040] justify-end" style={{ zIndex: '200' }}>

            <div className={`relative transform transition-all duration-700 delay-50 ${active ? "w-[50%]" : "w-[0%]"} top-[3px]  h-full flex flex-col   rounded-l-lg bg-white  space-y-2 shadow-md `}>

                <div className="flex h-auto w-full justify-between p-3 px-4 border-b-1">

                    <p className="text-lg font-medium" >Default Message/Content</p>

                    <span className="cursor-pointer" onClick={ () => closeButtom() } >

                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.3 5.71C17.91 5.32 17.28 5.32 16.89 5.71L12 10.59L7.10997 5.7C6.71997 5.31 6.08997 5.31 5.69997 5.7C5.30997 6.09 5.30997 6.72 5.69997 7.11L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.11C18.68 6.73 18.68 6.09 18.3 5.71Z" fill="#586474" />
                        </svg>

                    </span>

                </div>


                <div className="w-full h-full space-y-4 overflow-y-auto px-4 pb-32" >

                    {
                        DefaultContent.map( list => {

                            const val = defaultContent[list?.type] ? defaultContent[list?.type] : list?.Default_Content

                            return (

                               <InputWithLabel label={ list?.Label } labelTextWeight={"font-medium"} textvalue={ val } onchange={ (e) => SettingsCC( list?.type, e.target.value, list?.maxLength ) } /> 

                            )

                        } )
                    }

                </div>

            </div>

        </div>
    )

}