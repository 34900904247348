import React, { useEffect, useState } from "react"; 
import PrimaryButton from "../../../Button1/primarybutton";
import { useDispatch } from "react-redux";
import { Actions } from "../../../../store";
import { PersonalizationQue } from "../../../personalizationQue";

export const PersonalizationSettings = ({ field, fieldId,templateType }) => {

    const DisPatch = useDispatch()


    const AddQues = () => {

        const setContent = {

            key:fieldId,
            node:'game_mainpage_json',
            child:'questions',
            value:{
                "questionType": "Default",
                "questionId": Math.random().toString(16).slice(8) + Date.now().toString(36),
                "question": "Is 10 a prime number?",
                "image": "https://gz-engage-assets.s3.amazonaws.com/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/36b801342287596bdcccd69060a59154.jpg",
                "optionType": "yerorno",
                "options": [
                    {
                        "option":"option A",
                        "results":[]
                    },
                    {
                        "option":"option B",
                        "results":[]
                    },
                    {
                        "option":"option c",
                        "results":[]
                    },
                    {
                        "option":"option D",
                        "results":[]
                    }]
            } 
        }

        DisPatch(Actions.setQuizQuestionAdd(setContent))
    }

    const addQuestionId = ( childInd ) => {

        let payload = {
  
          key:fieldId,
          node:'game_mainpage_json',
          child:'questions',
          childInd
  
        }
  
        DisPatch(Actions.questionIdAdd( payload ))
  
      }

   
    return(
        <div className="w-full space-y-4 ">


            {
                field?.questions?.map((list,index) =>{

                    if(!list?.questionId) addQuestionId( index )
                    return(
                        <PersonalizationQue 
                            Index={index}
                            templateType={templateType}
                            answer={list?.answer} 
                            question={list?.question ?? list?.quistion}
                            fieldId={fieldId} 
                            imgSrc={list?.image} 
                            optionType={list?.optionType} 
                            options={list?.options}  
                            answerDescription={list?.answerDescription} 
                        />

                    )
                })
            }

            <PrimaryButton text={'Add Question'} width="w-full" height="h-12" backgroundColor="bg-primary-lightblue" border="border-1 border-primary" textcolor="text-primary" OnClick={AddQues} />

        </div>

    )
    
}