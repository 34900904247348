import React from 'react'
import PropTypes from "prop-types";

const DefaultText = function ({ text, id = "", textColor = "text-primary-bold", margin = "", lineHeight = "", fontStyle = "font-normal", textSize = "text-14px", className = "", ...props }) {

    return (
        <>
            <span id={id} className={`${fontStyle} ${textColor} ${margin} ${textSize} ${className}  ${lineHeight}`}>{text}</span>
            {props.children}
        </>
    )
}



DefaultText.prototype = {
    text: PropTypes.string.isRequired,
    textColor: PropTypes.string,
    margin: PropTypes.string,
    textSize: PropTypes.string,

}

export { DefaultText }