import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../Button1/primarybutton";
import { ThankYouPage } from "../thankyou";
import { WelComePage } from "../welcompage";
import { LeadFormBlock } from "../LeadForm";
import { FindPairV2 } from "./findpairv2";
import { FindPathTemp } from "./findPath";
import { FindFlowTemp } from "./findFlow";
import { SlideSwapTimp } from "./swapimage";
import { ImageDragAndDrop } from "./imageDrop";
import { CharDragAndDrop } from "./chardrop";
import { MatchingPair } from "./matchPair";
import { RearrangeProducts } from "./rearrange";
import { QuizGamesTemplate } from "./quizs";
import { FindWordsTemp } from "./findWord";
import { ConnectionTemp } from "./connetion";
import { FindOddOneTemp } from "./findOddOne";
import { Actions } from "../../../store";
import { CountDownTimer } from "../CountDownTimer";
import { CountUpTimer } from "../CountUpTimer";
import { isNotEmpty } from "../../../utils/helpers";
import { EditorTooltip } from "../../Editor/common/editortooltip";
import { LetsPlayBanner } from "../LetPlaybanner";
import { TimesUpBanner } from "../timesupBanner";
import { ReadyBannerImg } from "../ReadyImgbanner";
import { PlayBannerImg } from "../Playbanner";
import { GameMenuBar } from "./gameMenu";
import { Personalization } from "./Personalization";


export const TemlpateRC = ({ obj = {}, fieldID = '', position, prview = false, EditTemp = false, setTriggerField = null, toShowTips = false }) => {

  const { Fields, device } = useSelector((state) => ({ Fields: state.fields, device: state.device }));

  const DisPatch = useDispatch();

  const templateData = Fields[fieldID];
  const templateType = templateData?.type
  const Settings = templateData?.filedContent
  const startScreenContent = Settings.game_startscreen_json;
  const mainPageContent = Settings.game_mainpage_json;
  const resultScreenContent = Settings.game_resultcreen_json;
  const leadFormScreenContent = Settings?.game_leadForm_json;

  const [LoadContent, setLoadContent] = useState(true);
  const [score, setScore] = useState(0);
  const [ActionCount, setActionCount] = useState(0);
  const [gamePlayTime, setGamePlayTime] = useState([0, 0]);
  const [gamePlaySeconds, setGamePlaySeconds] = useState(0)
  const [globalKey, setGKey] = useState(0)
  const BrandLabel = Settings?.brandLabel ?? true



  // Screens To show
  const [resultSce, setresultSce] = useState(false);
  const [startSce, setstartSce] = useState(true);
  const [startSceBanner, setstartSceBaneer] = useState(false);
  const [TimesUpBannerSec, setTimesUpBaneer] = useState(false);
  const [LeadSce, setLeadSce] = useState(false);

  // Timer and starting and finishing the games
  const [second, setSecond] = useState(0);
  const [min, setMin] = useState(0);
  const [preTimer, setPreTimer] = useState(false);

  const [StartTime, setStartTime] = useState(false);
  const [connectionStartTime, setConnntionStartTime] = useState(false);
  const [PreStartTime, setPreStartTime] = useState(false);
  const [gameInit, setGameInit] = useState(false);
  const [GameFinsh, setGameFinsh] = useState(true);
  const [GameShffleImg, setGameShffle] = useState(false);
  const [connectionTimeOut, setConnectionTimeOut] = useState(false);

  const [ userAns, SetUserAns ] = useState([]);


  // Calculating Total playing seconds while Game finish
  useEffect(() => {

    if (gamePlayTime?.length > 0 && isNotEmpty(gamePlayTime[0]) && isNotEmpty(gamePlayTime[1]) && gamePlayTime[0] !== 0 && gamePlayTime[1] !== 0) {

      let timeDiff = Math.abs(gamePlayTime[0] - gamePlayTime[1]);
      timeDiff = parseFloat((timeDiff / 1000).toFixed(2))
      timeDiff = Math.round(timeDiff)
      setGamePlaySeconds(timeDiff)
      
    }
  }, [gamePlayTime])


  const GameStart = () => {

    if (!LoadContent) {

      setGameInit(true)
      setGameFinsh(false)
      setActionCount(0)
      setScore(0);
      // setStartTime(true);
      setConnntionStartTime(true);
      setstartSce(false)
      setresultSce(false)
      setMin(0)
      setSecond(0)
      setGKey(o => o + 1)

    }
  }


  useEffect(() => {

    if (gameInit === true) {

      if ((templateType === "puzzle-shuffleimage" && Settings?.game_mainpage_json?.screenMode === 'single') || templateType === "rearrange") {

        setPreTimer(true);

        // setStartTime(false)

      } else {

        setPreTimer(false);
        setStartTime(true);
        setGamePlayTime([Date.now(), 0])

      }
    }
    else { setPreTimer(false) }
  }, [gameInit])


  useEffect(() => {
    if (gameInit && GameFinsh) {

      setConnectionTimeOut(false)
      setConnntionStartTime(false)
      setStartTime(false)
      setresultSce(false);
      setGameInit(false);
      setLoadContent(false)
      if (Settings?.leadfrom) setLeadSce(true)
      else setresultSce(true)
      setGamePlayTime(obj => [obj[0], Date.now()]);

    }
  }, [GameFinsh])

  const PreTimeFinish = () => {
    setSecond(0); setMin(0); setActionCount(0);
    setPreStartTime(false)
    // setStartTime(true);

    if ((templateType === 'puzzle-shuffleimage' && mainPageContent?.screenMode === 'single') || (templateType === 'rearrange')) {

      setGameShffle(true);

      setTimeout(() => {

        setStartTime(true);
        setPreTimer(false)

      }, 2000)

    }

    else {

      setStartTime(true);
      setPreTimer(false)

    }

    setGamePlayTime([Date.now(), 0])
  }


  // Styles based on games
  let heightType = "h-full";

  if (templateType === "findOddOne" || templateType === "spinWheel") heightType = "min-h-full max-h-fit"

  if ((templateType === 'puzzle-shuffleimage' && mainPageContent?.screenMode === 'double') || templateType === 'puzzle-word') heightType = "min-h-full"

  let paddingType = (templateType === "spinWheel" ? "unset" : "");

  let marginType = (templateType === "spinWheel" ? "unset" : "");

  if (templateType === "rearrange") heightType += " overflow-auto divScroll"


  // Component data passing
  let data = { fieldID: fieldID, StartTime: StartTime, setActionCount: setActionCount, GameFinsh: setGameFinsh, setScore: setScore, score: score, ActionCount: ActionCount, setLoadContent: setLoadContent, setPreStartTime: setPreStartTime, setPreTimer, gameInit, SetUserAns }

  var timerData = { actionCount: ActionCount, timeCount: (Settings?.timeLimet), Callback: () => { Settings?.timerType === "CountDown" ? setTimesUpBaneer(true) : setGameFinsh(true) }, StartTime: StartTime, setMin: setMin, setSecond: setSecond }

  var connectiontimerData = { actionCount: ActionCount, timeCount: (Settings?.timeLimet), Callback: () => { Settings?.timerType === "CountDown" ? setTimesUpBaneer(true) : setTimesUpBaneer(true) }, StartTime: connectionStartTime, setMin: setMin, setSecond: setSecond }

  var preTimerData = { timeCount: (Settings?.showcardtime || 3), Callback: PreTimeFinish, StartTime: ((PreStartTime === true && preTimer === true && LoadContent === false)), setMin: setMin, setSecond: setSecond }

  const TimerNextAction = () => {

    if (templateType !== 'connection') setGameFinsh(true);


    else {

      setConnntionStartTime(false);

      setConnectionTimeOut(true);

    }

  }

  return (

    // Game Frame 

    <div id={"gameFrameBorder" + fieldID} className={`w-full overflow-hidden relative flex flex-col group p-[2rem] ${heightType}`} style={{ backgroundSize: "cover", padding: paddingType, margin: marginType, background: Settings?.background?.type === "image" ? `url(${Settings?.background?.src ?? "/asset/images/quizzes/background.png"}) 0% 0% / 100% 100% no-repeat` : Settings?.background?.color ?? "#FFFFFF" }} onClick={() => { DisPatch(Actions.setActiveFieldside({ filedId: fieldID, index: position, })) }} >

     
      {/* Tooltip - you can see this when you hover Game template */}

      {toShowTips && <EditorTooltip position={position} setTriggerField={setTriggerField} />}


      {/* Game Timer Banner  */}

      {preTimer && <CountDownTimer {...preTimerData} preTimer={preTimer} />}

      {Settings.timerstatus && (

        preTimer ? <></> : (

          Settings?.timerType === "CountDown" ?

            <CountDownTimer key={StartTime} {...(templateType !== 'connection') ? timerData : connectiontimerData} /> :

            <CountUpTimer {...timerData} />

        ))}

      {/* Game Menu Bar - Left side on Game Frame */}

      {(Settings?.backgroundMusic ?? true) && <GameMenuBar isGameStart={startSce} Src={Settings?.backgroundMusicSrc} />}


      {/* Game Start Screen or Welcome Screen  */}

      {startSce && <WelComePage position={position} fieldID={fieldID} WelComePageObJ={startScreenContent} bannerAction={setstartSceBaneer} StartAction={() => { setstartSce(false); }} />}


      {/* Banners */}

      {

        (startSceBanner && (templateType !== 'puzzle-shuffleimage' && mainPageContent?.screenMode !== 'single') && (templateType !== 'rearrange')) ?

          <LetsPlayBanner bannerAction={setstartSceBaneer} StartAction={() => { GameStart() }} />

          :

          startSceBanner && <ReadyBannerImg bannerAction={setstartSceBaneer} StartAction={() => { GameStart() }} />

      }

      {
        GameShffleImg && <PlayBannerImg bannerAction={setGameShffle} StartAction={() => true} />
      }


      {/* Game Frame Main Section it Render Game Main Screen Based on Template Type */}

      {

        (templateType === 'findpair' && mainPageContent?.pairType !== 'match') ? <FindPairV2 {...data} /> :

        (templateType === 'findpair' && mainPageContent?.pairType === 'match') ? <MatchingPair {...data} /> :

        templateType === 'findpath' ? <FindPathTemp {...data} /> :

        templateType === 'personalize-quiz' ? <Personalization {...data} /> :

        templateType === 'findOddOne' ? <FindOddOneTemp {...data} /> :

        templateType === 'findFlow' ? <FindFlowTemp {...data} /> :

        templateType === 'rearrange' ? <RearrangeProducts {...data} /> :

        templateType === 'Q&A' ? <QuizGamesTemplate {...data} /> :

        templateType === 'findWord' ? <FindWordsTemp {...data} /> :

        templateType === 'connection' ? <ConnectionTemp {...data} TimeOut={connectionTimeOut} ConnntionStartTimeFun={setConnntionStartTime} ConnectionTimeOutFun={setConnectionTimeOut} startTimeFun={() => { setConnectionTimeOut(false); setConnntionStartTime(true); }} pauseTimer={() => true} /> :

        (templateType === 'puzzle-shuffleimage' && mainPageContent?.screenMode === 'single') ? <SlideSwapTimp {...data} GameShffleImg={GameShffleImg} /> :

        (templateType === 'puzzle-shuffleimage' && mainPageContent?.screenMode === 'double') ? <ImageDragAndDrop {...data} /> :

        (templateType === 'puzzle-word' && mainPageContent?.screenMode === 'double') ? <CharDragAndDrop key={globalKey} {...data} /> :

        null

      }


      {/* GoZen Brand Label */}

      {
        BrandLabel &&

        <div className="absolute bottom-0 left-0 rounded-t-lg bg-white justify-center py-1 w-full border-1" >

          <p className="text-sm text-center m-0 text-black font-semibold " >Powered by GoZen Engage</p>

        </div>
      }


      {/* Game Lead From Pop */}

      {LeadSce && <LeadFormBlock device={device} submit={() => { setLeadSce(false); setresultSce(true) }} Fields={leadFormScreenContent} />}


      {/* Banner */}

      {TimesUpBannerSec && <TimesUpBanner ResultSrc={TimerNextAction} bannerAction={setTimesUpBaneer} />}

      
      {/*  Result Screen  */}

      {resultSce && <ThankYouPage gamePlaySeconds={second + (min * 60)} templateType={templateType} timerstatus={Settings.timerstatus} userAns={userAns} position={position} DIVID={fieldID} score={score} ActionCount={ActionCount} ThankYouPageObj={resultScreenContent.results} restartAction={() => { setresultSce(false); setstartSceBaneer(true) }} timing={`${min < 10 ? `0${min}` : second} : ${second < 10 ? `0${second}` : second}`} />} {/* && matchedPair.length === noOfCards / 2 */}

    </div>

  )
}