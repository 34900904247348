import React, { useState } from "react";
import PrimaryButton from "../../Button1/primarybutton";
import toast from 'react-hot-toast'; 
import { getScriptCode } from "../../../content/scriptCode";

export const ScriptcodeInit = ({
    forWardAction = null
}) => {

    let scriptcode =  `<script id="engage_script" async="true" src="${getScriptCode()}" type="text/javascript"></script>`
    
    const [activeState, setActive] = useState(0)

    const onSubmet = () => {
        forWardAction(3)
    }

    const copy = (index) => {

        const cp =  (index === 0) ? scriptcode :"null"
        navigator.clipboard.writeText(cp);
        toast.success("Copied!")
    }
    const platformContent = [
        {
            icon: (
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_4567_30475)">
                        <path d="M36 0H0V36H36V0Z" fill="#F7DF1E" />
                        <path d="M24.183 28.1251C24.9081 29.3091 25.8516 30.1794 27.5201 30.1794C28.9219 30.1794 29.8173 29.4788 29.8173 28.5108C29.8173 27.3508 28.8973 26.94 27.3544 26.2651L26.5087 25.9023C24.0676 24.8623 22.4459 23.5594 22.4459 20.8051C22.4459 18.268 24.379 16.3365 27.4001 16.3365C29.551 16.3365 31.0973 17.0851 32.2116 19.0451L29.5773 20.7365C28.9973 19.6965 28.3716 19.2868 27.4001 19.2868C26.4093 19.2868 25.7813 19.9154 25.7813 20.7365C25.7813 21.7514 26.4099 22.1623 27.8613 22.7908L28.707 23.1531C31.5813 24.3857 33.2041 25.6423 33.2041 28.4674C33.2041 31.5131 30.8116 33.1817 27.5984 33.1817C24.4567 33.1817 22.427 31.6845 21.4339 29.7223L24.183 28.1251ZM12.2327 28.4183C12.7641 29.3611 13.2476 30.1583 14.4099 30.1583C15.5213 30.1583 16.2224 29.7234 16.2224 28.0325V16.5297H19.6053V28.0783C19.6053 31.5811 17.5516 33.1754 14.5539 33.1754C11.8453 33.1754 10.2767 31.7737 9.479 30.0854L12.2327 28.4183Z" fill="black" />
                    </g>
                    <defs>
                        <clipPath id="clip0_4567_30475">
                            <rect width="36" height="36" rx="4" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

            ),
            title: "javaScript",
            description: "Just copy and paste the following embed code in you site",
            link: null
        },
        {
            icon: (
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_4574_30557)">
                        <path d="M2.54868 18.0001C2.54868 24.1123 6.08693 29.3945 11.2177 31.8977L3.88039 11.7156C3.02693 13.6361 2.54868 15.7614 2.54868 18.0001ZM28.3141 17.2208C28.3141 15.3124 27.6313 13.9907 27.0457 12.962C26.266 11.6901 25.5351 10.6129 25.5351 9.34088C25.5351 7.92138 26.6075 6.60002 28.118 6.60002C28.1862 6.60002 28.2509 6.60855 28.3174 6.61236C25.5808 4.09541 21.9348 2.55868 17.9303 2.55868C12.5567 2.55868 7.82898 5.32656 5.07863 9.51894C5.43951 9.52981 5.77961 9.53745 6.06849 9.53745C7.67737 9.53745 10.1678 9.34146 10.1678 9.34146C10.997 9.29239 11.0947 10.515 10.2664 10.6135C10.2664 10.6135 9.43317 10.7119 8.50595 10.7607L14.1073 27.487L17.4735 17.3519L15.077 10.7601C14.2487 10.7113 13.464 10.6129 13.464 10.6129C12.6351 10.5641 12.7323 9.29181 13.5612 9.34088C13.5612 9.34088 16.1014 9.53686 17.6128 9.53686C19.2214 9.53686 21.7121 9.34088 21.7121 9.34088C22.5419 9.29181 22.6393 10.5144 21.8107 10.6129C21.8107 10.6129 20.9757 10.7113 20.0502 10.7601L25.6089 27.3597L27.1431 22.2127C27.8081 20.0769 28.3141 18.5428 28.3141 17.2208ZM18.2002 19.3508L13.5852 32.8138C14.9631 33.2205 16.4204 33.4429 17.9303 33.4429C19.7216 33.4429 21.4393 33.1321 23.0382 32.5676C22.997 32.5015 22.9595 32.4313 22.9288 32.3549L18.2002 19.3508ZM31.4271 10.5914C31.4933 11.0833 31.5307 11.6113 31.5307 12.1793C31.5307 13.7463 31.2392 15.5078 30.3612 17.7103L25.6627 31.3479C30.2356 28.6708 33.3114 23.6971 33.3114 18.0004C33.3117 15.3156 32.6286 12.791 31.4271 10.5914ZM17.9303 0C8.0438 0 0 8.07476 0 18.0001C0 27.9265 8.0438 36.001 17.9303 36.001C27.8166 36.001 35.8616 27.9265 35.8616 18.0001C35.8613 8.07476 27.8166 0 17.9303 0ZM17.9303 35.1759C8.49717 35.1759 0.822146 27.4708 0.822146 18.0001C0.822146 8.5299 8.49688 0.82537 17.9303 0.82537C27.3632 0.82537 35.0377 8.5299 35.0377 18.0001C35.0377 27.4708 27.3632 35.1759 17.9303 35.1759Z" fill="#00749A" />
                    </g>
                    <defs>
                        <clipPath id="clip0_4574_30557">
                            <rect width="36" height="36" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

            ),
            title: "Wordpress",
            description: "Download Engage Plugin",
            link: "https://wordpress.org/plugins/gozen-engage/"
        },
    ]

    return (

        <section className="flex flex-col w-full h-full"  >

            <div className="flex flex-col mx-auto justify-center my-auto space-y-5">

                <h3 className="text-xl font-semibold text-center" >Choose your Platform</h3>

                <div className="flex justify-center space-x-4" >
                    {
                        platformContent?.map((list, index) => {

                            return (

                                <p key={index} className={` flex space-x-2 transform duration-500 cursor-pointer hover:shadow-bgshadow border-1 ${(activeState == index) ? "border-primary" : ""} rounded-xl px-5 py-4`} onClick={()=>{
                                    // if(index==0) 
                                    return setActive(index)
                                    // return toast.error("currently not available")
                                    }}>

                                    <span className="flex my-auto" >{list?.icon}</span>
                                    <span className="flex my-auto font-semibold">{list?.title}</span>

                                </p>
                            )
                        })
                    }
                </div>

                <div className="flex justify-center py-3 px-6 rounded-lg " style={{ background: "rgba(37, 99, 235, 0.10)" }}>
                    <p className={`capitalize ${ platformContent[activeState]?.link ? "text-primary underline cursor-pointer" : "" } `} onClick={ () => { if( platformContent[activeState]?.link ) window.location.href = platformContent[activeState]?.link } } >{platformContent[activeState]?.description}</p>
                </div>

                { (activeState !== 1) &&<div className="flex flex-col py-3 px-6 w-full max-w-[750px] space-y-3 border rounded-lg mx-auto"  >
                    <p className="flex font-medium">{scriptcode}</p>
                    <PrimaryButton text={"Copy Code"} OnClick={()=>copy(0)} backgroundColor="bg-white" textcolor="text-primary" classNames={'border-1 border-primary px-4'} width="w-fit" icon={(
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.24984 18.9584H5.74984C2.4915 18.9584 1.0415 17.5084 1.0415 14.25V10.75C1.0415 7.49169 2.4915 6.04169 5.74984 6.04169H9.24984C12.5082 6.04169 13.9582 7.49169 13.9582 10.75V14.25C13.9582 17.5084 12.5082 18.9584 9.24984 18.9584ZM5.74984 7.29169C3.1665 7.29169 2.2915 8.16669 2.2915 10.75V14.25C2.2915 16.8334 3.1665 17.7084 5.74984 17.7084H9.24984C11.8332 17.7084 12.7082 16.8334 12.7082 14.25V10.75C12.7082 8.16669 11.8332 7.29169 9.24984 7.29169H5.74984Z" fill="#2563EB" />
                            <path d="M14.2498 13.9584H13.3332C12.9915 13.9584 12.7082 13.675 12.7082 13.3334V10.75C12.7082 8.16669 11.8332 7.29169 9.24984 7.29169H6.6665C6.32484 7.29169 6.0415 7.00835 6.0415 6.66669V5.75002C6.0415 2.49169 7.4915 1.04169 10.7498 1.04169H14.2498C17.5082 1.04169 18.9582 2.49169 18.9582 5.75002V9.25002C18.9582 12.5084 17.5082 13.9584 14.2498 13.9584ZM13.9582 12.7084H14.2498C16.8332 12.7084 17.7082 11.8334 17.7082 9.25002V5.75002C17.7082 3.16669 16.8332 2.29169 14.2498 2.29169H10.7498C8.1665 2.29169 7.2915 3.16669 7.2915 5.75002V6.04169H9.24984C12.5082 6.04169 13.9582 7.49169 13.9582 10.75V12.7084Z" fill="#2563EB" />
                        </svg>

                    )} />
                </div> }

                <a href="https://docs.gozen.io/gozen-engage-knowledge-base/" title="guidelink" className="text-primary font-semibold flex mx-auto underline" >Installation Guide</a>

                { (activeState == 1) &&

                <div className="flex flex-col space-y-4">

                    <p className="text-xl font-semibold text-center" >Get your API Key</p>
                    <p className="" >1. You can get your plugin key and secret after you finish this setup.</p>
                    <p className="" >2. After finishing this setup, go to the settings page, click the API key menu, and get the key and secret for your Engage plugin..</p>

                    {/* <div className='flex flex-row-reverse border border-[#DCE0E4] w-fit mx-auto rounded-lg' >
                        <PrimaryButton text={"Copy Code"} OnClick={()=>copy(1)} classNames={'border-1 border-primary px-4 '} rounded="rounded-r-lg" height="h-full" width="w-fit" icon={(
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.3337 10.75V14.25C13.3337 17.1666 12.167 18.3333 9.25033 18.3333H5.75033C2.83366 18.3333 1.66699 17.1666 1.66699 14.25V10.75C1.66699 7.83329 2.83366 6.66663 5.75033 6.66663H9.25033C12.167 6.66663 13.3337 7.83329 13.3337 10.75Z" fill="white" />
                                <path d="M14.2498 1.66663H10.7498C8.24635 1.66663 7.03822 2.53228 6.74575 4.61571C6.66834 5.16721 7.1298 5.62496 7.68671 5.62496H9.2498C12.7498 5.62496 14.3748 7.24996 14.3748 10.75V12.3131C14.3748 12.87 14.8326 13.3314 15.3841 13.254C17.4675 12.9615 18.3331 11.7534 18.3331 9.24996V5.74996C18.3331 2.83329 17.1665 1.66663 14.2498 1.66663Z" fill="white" />
                            </svg>


                        )} />
                        <input type='text' className='flex py-3 px-4 text-right rounded-l-lg min-w-[350px]' placeholder={'example.com'} value={"50afs68bskjhf-67gj-94hdjkfbahfocgk66"} />
                    </div> */}

                </div> }

                <PrimaryButton text={"continue"} OnClick={onSubmet} />

            </div>

        </section>

    )
}