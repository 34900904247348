import React from "react";

export const EditorIcons = () => {
    return {
        rightArrow: (<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.57539 11.35C5.33789 11.35 5.10039 11.2625 4.91289 11.075L0.500391 6.66245C0.137891 6.29995 0.137891 5.69995 0.500391 5.33745L4.91289 0.924951C5.27539 0.562451 5.87539 0.562451 6.23789 0.924951C6.60039 1.28745 6.60039 1.88745 6.23789 2.24995L2.48789 5.99995L6.23789 9.74995C6.60039 10.1125 6.60039 10.7125 6.23789 11.075C6.06289 11.2625 5.82539 11.35 5.57539 11.35Z" fill="#2563EB" />
        </svg>),
        delete: (<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 12.25C1.5 13.075 2.175 13.75 3 13.75H9C9.825 13.75 10.5 13.075 10.5 12.25V3.25H1.5V12.25ZM3.8775 7.4425C3.585 7.15 3.585 6.6775 3.8775 6.385C4.17 6.0925 4.6425 6.0925 4.935 6.385L6 7.4425L7.0575 6.385C7.35 6.0925 7.8225 6.0925 8.115 6.385C8.4075 6.6775 8.4075 7.15 8.115 7.4425L7.0575 8.5L8.115 9.5575C8.4075 9.85 8.4075 10.3225 8.115 10.615C7.8225 10.9075 7.35 10.9075 7.0575 10.615L6 9.5575L4.9425 10.615C4.65 10.9075 4.1775 10.9075 3.885 10.615C3.5925 10.3225 3.5925 9.85 3.885 9.5575L4.9425 8.5L3.8775 7.4425ZM10.5 1H8.625L8.0925 0.4675C7.9575 0.3325 7.7625 0.25 7.5675 0.25H4.4325C4.2375 0.25 4.0425 0.3325 3.9075 0.4675L3.375 1H1.5C1.0875 1 0.75 1.3375 0.75 1.75C0.75 2.1625 1.0875 2.5 1.5 2.5H10.5C10.9125 2.5 11.25 2.1625 11.25 1.75C11.25 1.3375 10.9125 1 10.5 1Z" fill="#2563EB" />
        </svg>
        ),
        plus: (<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0003 6.83342H6.83366V11.0001C6.83366 11.4584 6.45866 11.8334 6.00033 11.8334C5.54199 11.8334 5.16699 11.4584 5.16699 11.0001V6.83342H1.00033C0.541992 6.83342 0.166992 6.45842 0.166992 6.00008C0.166992 5.54175 0.541992 5.16675 1.00033 5.16675H5.16699V1.00008C5.16699 0.541748 5.54199 0.166748 6.00033 0.166748C6.45866 0.166748 6.83366 0.541748 6.83366 1.00008V5.16675H11.0003C11.4587 5.16675 11.8337 5.54175 11.8337 6.00008C11.8337 6.45842 11.4587 6.83342 11.0003 6.83342Z" fill="#2563EB" />
        </svg>
        ),
        DragImage:(
            <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M77.7607 65.8983L39.9661 59.2054C39.6942 59.1574 39.5197 58.8536 39.5774 58.5271L46.2075 20.7644C46.2652 20.4375 46.5321 20.212 46.8035 20.2605L84.5981 26.9529C84.8704 27.0014 85.0435 27.3043 84.9863 27.6316L78.3563 65.3944C78.299 65.7204 78.0326 65.9468 77.7607 65.8983Z" stroke="#98989A" strokeWidth="0.797198" strokeLinecap="round" strokeLinejoin="round" stroke-dasharray="4.78 4.78" />
            <path fillRule="evenodd" clipRule="evenodd" d="M69.3557 70.2077L18.6202 79.1912C18.2559 79.256 17.897 78.9531 17.82 78.5148L8.91997 27.8226C8.84287 27.3834 9.07686 26.9764 9.44113 26.9121L60.1766 17.9277C60.5418 17.8629 60.8989 18.1654 60.976 18.6046L69.8764 69.2972C69.953 69.735 69.7208 70.143 69.3557 70.2073V70.2077Z" fill="white" stroke="#98989A" strokeWidth="0.797198" strokeLinecap="round" strokeLinejoin="round" stroke-dasharray="4.78 4.78" />
            <path fillRule="evenodd" clipRule="evenodd" d="M44.0669 42.4346C43.2486 42.0919 42.0291 42.1073 41.127 42.7393C40.0648 43.4833 39.7208 44.9489 40.2497 46.3624C40.8177 47.8838 42.3709 48.5892 43.7301 48.0748C45.1458 47.5397 45.8351 45.9119 45.465 44.3064C45.2756 43.4855 44.8085 42.7443 44.0674 42.4346" fill="#2563EB" />
            <path fillRule="evenodd" clipRule="evenodd" d="M52.4529 55.5874C50.7792 53.6014 48.9338 51.7681 46.9383 50.1089C46.6362 49.8576 46.1917 49.9821 45.9798 50.2791L43.144 54.2513C42.9908 54.4659 42.1238 56.0624 41.8551 56.0565C41.5476 56.0502 40.2141 54.3523 39.9977 54.1186L33.3789 46.9621C32.9912 46.5428 32.4403 46.6723 32.2076 47.1695C30.4309 50.9773 29.8164 55.2732 29.0094 59.5741L25.8008 41.3001C25.8008 41.3001 42.1527 38.3413 49.4716 37.0214C50.5364 43.3584 51.8037 49.1961 52.4529 55.587V55.5874ZM54.69 57.4415C54.6945 57.3931 52.3727 42.8427 51.1406 35.6133C51.0792 35.2565 50.838 35.0745 50.5748 35.0383C50.4427 34.964 50.2884 34.9399 50.1401 34.9703L25.7579 39.3668C25.7273 39.3718 25.7011 39.3826 25.6732 39.3917L24.2936 39.6362C23.8644 39.7118 23.7075 40.0971 23.7625 40.4503C23.7585 40.5128 23.762 40.5756 23.7729 40.6373L27.702 63.0161C27.7556 63.3217 28.27 63.1945 28.5662 63.1429C34.1395 62.1699 45.4695 60.337 54.4732 58.569C54.9118 58.4114 54.6914 57.4429 54.69 57.4415Z" fill="#2563EB" />
            <path fillRule="evenodd" clipRule="evenodd" d="M69.8438 17.6197C71.813 16.8342 71.7007 12.2 71.4816 11.6997C70.6823 12.3417 69.8438 17.6197 69.8438 17.6197Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M73.5243 15.3942C72.9589 13.9725 68.565 13.7851 68.2864 13.9798C68.0083 14.1744 72.2795 15.7148 73.5243 15.3942Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M4.5 66.7994C6.11084 68.1817 10.1391 65.9183 10.4777 65.4904C9.53901 65.0806 4.5 66.7989 4.5 66.7989" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.1783 69.0283C9.16654 67.8629 7.28023 63.8721 6.97907 63.7164C6.67792 63.5602 7.31495 68.0725 8.1783 69.0283Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M76.1455 77.5803C78.2644 77.5613 79.8239 73.1975 79.7991 72.6523C78.8235 72.962 76.1455 77.5803 76.1455 77.5803Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M80.3774 76.8369C80.3612 75.306 76.3302 73.5393 76.0006 73.6204C75.6715 73.701 79.1015 76.6852 80.3774 76.8369Z" fill="white" />
        </svg>
        ),
        noImage:(
            <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M77.7607 65.8983L39.9661 59.2054C39.6942 59.1574 39.5197 58.8536 39.5774 58.5271L46.2075 20.7644C46.2652 20.4375 46.5321 20.212 46.8035 20.2605L84.5981 26.9529C84.8704 27.0014 85.0435 27.3043 84.9863 27.6316L78.3563 65.3944C78.299 65.7204 78.0326 65.9468 77.7607 65.8983Z" stroke="#98989A" strokeWidth="0.797198" strokeLinecap="round" strokeLinejoin="round" stroke-dasharray="4.78 4.78" />
            <path fillRule="evenodd" clipRule="evenodd" d="M69.3557 70.2077L18.6202 79.1912C18.2559 79.256 17.897 78.9531 17.82 78.5148L8.91997 27.8226C8.84287 27.3834 9.07686 26.9764 9.44113 26.9121L60.1766 17.9277C60.5418 17.8629 60.8989 18.1654 60.976 18.6046L69.8764 69.2972C69.953 69.735 69.7208 70.143 69.3557 70.2073V70.2077Z" fill="white" stroke="#98989A" strokeWidth="0.797198" strokeLinecap="round" strokeLinejoin="round" stroke-dasharray="4.78 4.78" />
            <path fillRule="evenodd" clipRule="evenodd" d="M44.0669 42.4346C43.2486 42.0919 42.0291 42.1073 41.127 42.7393C40.0648 43.4833 39.7208 44.9489 40.2497 46.3624C40.8177 47.8838 42.3709 48.5892 43.7301 48.0748C45.1458 47.5397 45.8351 45.9119 45.465 44.3064C45.2756 43.4855 44.8085 42.7443 44.0674 42.4346" fill="#2563EB" />
            <path fillRule="evenodd" clipRule="evenodd" d="M52.4529 55.5874C50.7792 53.6014 48.9338 51.7681 46.9383 50.1089C46.6362 49.8576 46.1917 49.9821 45.9798 50.2791L43.144 54.2513C42.9908 54.4659 42.1238 56.0624 41.8551 56.0565C41.5476 56.0502 40.2141 54.3523 39.9977 54.1186L33.3789 46.9621C32.9912 46.5428 32.4403 46.6723 32.2076 47.1695C30.4309 50.9773 29.8164 55.2732 29.0094 59.5741L25.8008 41.3001C25.8008 41.3001 42.1527 38.3413 49.4716 37.0214C50.5364 43.3584 51.8037 49.1961 52.4529 55.587V55.5874ZM54.69 57.4415C54.6945 57.3931 52.3727 42.8427 51.1406 35.6133C51.0792 35.2565 50.838 35.0745 50.5748 35.0383C50.4427 34.964 50.2884 34.9399 50.1401 34.9703L25.7579 39.3668C25.7273 39.3718 25.7011 39.3826 25.6732 39.3917L24.2936 39.6362C23.8644 39.7118 23.7075 40.0971 23.7625 40.4503C23.7585 40.5128 23.762 40.5756 23.7729 40.6373L27.702 63.0161C27.7556 63.3217 28.27 63.1945 28.5662 63.1429C34.1395 62.1699 45.4695 60.337 54.4732 58.569C54.9118 58.4114 54.6914 57.4429 54.69 57.4415Z" fill="#2563EB" />
            <path fillRule="evenodd" clipRule="evenodd" d="M69.8438 17.6197C71.813 16.8342 71.7007 12.2 71.4816 11.6997C70.6823 12.3417 69.8438 17.6197 69.8438 17.6197Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M73.5243 15.3942C72.9589 13.9725 68.565 13.7851 68.2864 13.9798C68.0083 14.1744 72.2795 15.7148 73.5243 15.3942Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M4.5 66.7994C6.11084 68.1817 10.1391 65.9183 10.4777 65.4904C9.53901 65.0806 4.5 66.7989 4.5 66.7989" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.1783 69.0283C9.16654 67.8629 7.28023 63.8721 6.97907 63.7164C6.67792 63.5602 7.31495 68.0725 8.1783 69.0283Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M76.1455 77.5803C78.2644 77.5613 79.8239 73.1975 79.7991 72.6523C78.8235 72.962 76.1455 77.5803 76.1455 77.5803Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M80.3774 76.8369C80.3612 75.306 76.3302 73.5393 76.0006 73.6204C75.6715 73.701 79.1015 76.6852 80.3774 76.8369Z" fill="white" />
        </svg>
            ),

    }
}