import toast from 'react-hot-toast';
import axios from '../axios';
import { get, isObject } from 'lodash';
import Axios from 'axios'

export const Registeruser = (payload) =>  {

    return new Promise (async (resolve,reject)=>{
        

        axios.post(`/register`,payload,{headers: {platform:"engage"}}).then (resdata => {
            resolve(resdata.data);

        }).catch(err => {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })

    
    })

}



export const sendResetpasswordMail = ( email, type = 'resetpassword' ) =>  {

    return new Promise (async (resolve,reject)=>{

        axios.post(`/email/reset`, { email, type }, { headers: { platform:"engage" }})
        .then ( async response => {
            resolve (response.data)
        })
        .catch( err => {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err.response.data)
        } )

    })

}

export const ActivateAppsumoAccount = ( password, token ) =>  {

    return new Promise (async (resolve,reject)=>{

        axios.put(`/appsumo/engage/reset_password`, { password }, {headers: { platform:"engage","access-token":token }})
        .then ( async response => {
            resolve (response.data)
        })
        .catch( err => {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err.response.data)
        } )

    })

}

export const verifyResetPasswordEmail = ( password, token ) =>  {

    return new Promise (async (resolve,reject)=>{

        axios.post(`/email/reset/verify/${token}`, { password }, {headers: {platform:"engage"}})
        .then ( async response => {
            resolve (response.data)
        })
        .catch( err => {

            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err.response.data)
        } )

    })

}

export const EmailTokenVerify = (token) =>  {

    return new Promise (async (resolve,reject)=>{

        axios.post(`/email/verify/${token}`,{headers: {platform:"engage"}})
        .then ( async response => {
            resolve (response.data)
        })
        .catch( err => {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err.response.data)
        } )

    })

}

export const CampaignTriggerEvent = (token) =>  {
    const payload = {
        "campaign_id": "a05d4d45-cc8d-432d-aef7-b0b2ccd4935f",
        "site_id": "a842e21c-d164-48ea-b4b8-d061d9e4b1ee",
        "domain":"127.0.0.1"
    }

    return new Promise (async (resolve,reject)=>{

        Axios.post(`http://localhost:8080/v1/user/campaign/trigger`,payload,{headers: {"X-Gz-Platform":"engage", "X-Ps-Id":"bf78b0c4-eb58-4c2b-8b84-d1258fd71a12"}})
        .then ( async response => {
            resolve (response.data)
        })
        .catch( err => {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err.response.data)
        } )

    })

}

export const getUser = () => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.get('/user/me');
            
            

            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            
            reject(err);
        }
    })
}





export const complete_apptour = () => {
    return new Promise (async (resolve,reject)=>{
        try{


        const updateUserDetail = await updateUser({ engage_apptour: "true" });

            // const res = await axios.post('/user/me');
            // if(res.status == 200)
             resolve(true);
            
    
        }catch(err){
            resolve(false)             
        }
    })
}

export const assetuploadFile = (file,type='image') => {
    return new Promise(async (resolve, reject) => {
        try {

            const res = await axios.post(`/editor/assert/upload?type=${type}`, file);


            

            if (res.status == 200) resolve(res.data);


        } catch (err) {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")

            
            reject(err);
        }
    })
}

export const uploadFile = (file,campaign_id,primary_id) => {

    return new Promise(async (resolve, reject) => {

        try {

            let header={headers:{
                "x-ps-id":primary_id
            }}

            const res = await axios.post(`/assert/file/upload?type=image&campaign_id=${campaign_id}`, file,header);


            

            if (res.status == 200) resolve(res.data);


        } catch (err) {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")

            
            reject(err);
        }
    })
}

export const getAssetFile = () => {
    return new Promise(async (resolve, reject) => {
        try {

            const res = await axios.get('/editor/getAllAssets');


            

            if (res.status == 200) resolve(res.data);


        } catch (err) {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")

            
            reject(err);
        }
    })
}
export const getDashboardInfo = () => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.get('/user/dashboard');
            

            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")

            
            reject(err);
        }
    })
}

export const updateUser = (payload) => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.post('/user/me',payload);
            
            

            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")

            
            reject(err);
        }
    })
}

export const updateUserNotify = () => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.post('/user/notify/viewed');
            
            

            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")

            
            reject(err);
        }
    })
}

export const templatelist = (industry,category) => {
    return new Promise (async (resolve,reject)=>{
        try{
            let url = `/templates/list?industry=${industry}&category=${category}`
            const res = await axios.get(url);
            
            if(res.status == 200) resolve(res.data);
            
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")

            
            reject(err);
        }
    })
}

export const Createcampaign = (industry,category,template_id) => {
    return new Promise (async (resolve,reject)=>{
        try{
            let url = `/campaign/create`;
            let payload = {
                industry:industry,
                category:category,
                template_id:template_id,
            };

            const res = await axios.post(url,payload);
            
            
            if(res.status == 200) resolve(res.data);
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something when to wrong, please Contacrt Support")

            
            reject(err);
        }
    })
}