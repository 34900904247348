
import React from "react";

const Input = ({
    id= "",
    name ="",
    type="text",
    Placeholder ="Enter",
    fontweight = "",
    margin = "mb-3",
    padding = "px-14px",
    textvalue =null,
    disabledinput = false,
    width = "w-full",
    onchange = null,
    round ='rounded-8px',
    ClassName = '',
    textColor = 'text-black',
    background = 'bg-white',
    bordercolor ='border-secondar',
    border="border",
    textsize="text-14px",
    min=0,
    max=999999999999999,
    required=false,
    maxLen=9999999999,
    style={},
    ...prop
}) => {
     return (
         <>
            <input style={{...style}} type={type} min={min} max={max} className={`gz-basic-input h-10 block ${padding} ${ClassName} ${textsize} appearance-none ${background} ${textColor} outline-none placeholder-gray-600 ${border} ${round} ${width}  leading-5 placeholder-opacity-40 ${margin} ${bordercolor} `} maxLength={maxLen} required={required} id={id} name={name} placeholder={Placeholder} disabled={disabledinput} onChange = {onchange} defaultValue ={textvalue} {...prop}/>
         </>
     )
}

export default Input;