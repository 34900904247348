import React, { useState } from "react";
import InputWithLabel from "./input/inputWithLabel";
import { Imageinput } from "./input/imageinput";
import InputRadiocheck from "./input/radiocheck";
import PrimaryButton from "./Button1/primarybutton";
import Input from "./input/input";
import { useDispatch } from "react-redux";
import { Actions } from "../store";
import DropMenu from "./input/dropmenu";
import DefaultDropMenu from "./input/droplist";
import { MediaPopup } from "./popup/Mediapopup";
import SwicthInput from "./input/swicth";
import TextareaInput from "./input/textarea";
import { TooltipData } from "../content/toolTip";
import { listOfIcon } from "../content/iconList";

export const QuizContainerGame = ({
    Index,
    fieldId = "",
    question = "",
    optionType = "yerorno",
    options = ["Options", "Options", "Options", "Options"],
    imgSrc = "",
    answer = "",
    answerDescription = "",
    required = false,

}) => {

    const DisPatch = useDispatch()



    const dropmenuList = optionType === "yerorno" ? ['Yes', 'No'] : options

    const findIndex = dropmenuList.findIndex(resData => resData?.toLowerCase() === answer?.toLowerCase())

    const DefaultCC = (value, FID = fieldId, eage, optionIndex = 0) => {
        const setContent = {
            key: FID,
            node: 'game_mainpage_json',
            child: 'questions',
            edge: eage,
            QueIndex: Index,
            optionIndex: optionIndex,
            value: value
        }
        DisPatch(Actions.setQuizQuestionCC(setContent))
    }

    const DirectContentChange = (value, type, optionIndex = 0) => {


        const setContent = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_mainpage_json',
            child: "questions",
            edge: "image",
            QueIndex: Index,
            optionIndex: optionIndex,
            value: value
        }
        DisPatch(Actions.setQuizQuestionCC(setContent))

        if (ShowMedia) setShowMidia(false)

    }

    const QuesRangechange = (type) => {

        const setContent = {
            key: fieldId,
            node: 'game_mainpage_json',
            child: 'questions',
            position: Index,
            type: type
        }

        DisPatch(Actions.setQuizQuestionRA(setContent))
    }



    const updateAnswer = (value) => {
        const setContent = {
            key: fieldId,
            node: 'game_mainpage_json',
            child: 'questions',
            edge: 'answer',
            position: Index,
            value: value
        }

        DisPatch(Actions.setQuizAns(setContent))
    }



    const DeleteIcon = listOfIcon('delete', 20, 20, 'red');

    const toolTip = TooltipData()

    const [toggleButton, settoggleButton] = useState(optionType === "yerorno" ? false : true)
    const [ShowMedia, setShowMidia] = useState(false);


    const ChangeQuestiontype = () => {
        settoggleButton(toggleButton ? false : true)
        DefaultCC(toggleButton ? "yerorno" : "multiChoice", fieldId, 'optionType')
    }

    const addOption = (remove=false,optionIndex=0) => {
        const setContent = {
            key: fieldId,
            node: 'game_mainpage_json',
            child: 'questions',
            edge: 'options',
            QueIndex: Index,
            optionIndex: optionIndex,
            value:'option',
            remove:remove
        }
        DisPatch(Actions.setAddOption(setContent))
    }

    return (
        <>
            {ShowMedia && <MediaPopup cancel={setShowMidia} fieldType={'image'} OnSelect={DirectContentChange} />}

            <div key={Index} className={`w-full relative flex  flex-col space-y-2 border-2  h-fit delay-400 duration-700 top-0 p-4 rounded-xl hover:shadow-md hover:-top-0 hover:border-primary`}  >

                <div className="flex justify-between">
                    <p className="text-base font-medium" >Question - {Index + 1}</p>
                    <div className="flex space-x-2">
                        {
                            toolTip.map((list, index) => {
                                return (
                                    <p key={index} className="p-2 rounded-md border-1 hover:bg-gray-200 cursor-pointer" onClick={() => QuesRangechange(list.type)} >{list.icon}</p>
                                )
                            })
                        }
                    </div>
                </div>


                {/* Question Editor */}
                <div className="flex flex-col space-y-3">

                    {/* Question */}
                    <InputWithLabel label="Question" textvalue={question} onchange={(e) => DefaultCC(e.target.value, fieldId, 'question')} />

                    {/* Question Image */}
                    <Imageinput label="Cover image" src={imgSrc} chanaeAction={() => setShowMidia(true)} deleteSrc={() => DefaultCC('', fieldId, 'image')} />

                    {/* Question type */}
                    <div className="w-full space-y-2">
                        <label className="capitalize" >Answer type</label>
                        <div className="flex w-full 0">
                            <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={toggleButton ? 'text-gray-500' : 'text-primary'} border={toggleButton ? 'border-2 border-gray-500' : 'border-2 border-primary'} rounded="rounded-l-2" height="h-14" text={'Yes/No'} OnClick={ChangeQuestiontype} />
                            <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={!toggleButton ? 'text-gray-500' : 'text-primary'} border={!toggleButton ? 'border-2 border-gray-500' : 'border-2 border-primary'} height="h-14" text={'Multi choose'} rounded='rounded-none' OnClick={ChangeQuestiontype} />
                        </div>
                    </div>

                    {/* Question option */}
                    <div className="space-y-3">

                        {optionType !== "yerorno" && <>
                            <label className="capitalize" >Answer options</label>


                            {
                                options.map((list, index) => {

                                    return (

                                        <div key={index} className="w-full flex space-x-2">

                                            <Input margin={''} textvalue={list} onchange={(e) => DefaultCC(e.target.value, fieldId, 'options', index)} />

                                            <span className="p-2 rounded-md border-1 cursor-pointer" onClick={()=>addOption( true, index )}>{DeleteIcon}</span>

                                        </div>
                                    )

                                })
                            }


                        </>
                        }
                    </div>

                    < PrimaryButton buttontype="secondarybutton" text={'Add option'}  OnClick={()=>addOption()} />

                    {/* Question Answer If type multi choise */}
                    <DefaultDropMenu label={'Select The Answer For The Option'} list={dropmenuList} active={findIndex < 0 ? 0 : findIndex} fieldType={'answer'} onchange={updateAnswer} />

                    <TextareaInput label="Answer Description" textvalue={answerDescription} onchange={(e) => DefaultCC(e.target.value, fieldId, 'answerDescription')} />
                    {/* <SwicthInput  label={"Required"} labelTextsize={'text-sm'} fieldType={'required'}  defaultOPtion={true} status={required} onchange={(e) => DefaultCC(e, fieldId, 'required')}   /> */}

                </div>
            </div>
        </>
    )
}