import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../input/input";
import { Actions } from "../../../store";
import CheckBox from "../../input/checkbox";
import InputWithLabel from "../../input/inputWithLabel";
import InputRadiocheck from "../../input/radiocheck";
import SwicthInput from "../../input/swicth";
import TextareaInput from "../../input/textarea";
import UploadFile from "../../input/upload";
import { CommonTemplateSetting } from "./commonTemplateSetting";
import { ColorPixerInput } from "../../input/colorpixerInput";
import DropDownWithSearch from "../../input/dropDownWithSearch";
import { AllFount } from "../../../content/allFount";
import { isNotEmpty } from "../../../utils/helpers";
import DropMenu from "../../input/dropmenu";
import { useSelectionAction } from "../../../pages/customstate/context";
import FontInput from "../../input/fontInput";
import { Imageinput } from "../../input/imageinput";
import { MediaPopup } from "../../popup/Mediapopup";
import { EditorTooltipST } from "./editortooltipSidetap";
import { listOfIcon } from "../../../content/iconList";

const ListContainer = ({ title, active, filed, setEditTemplate = null, setEditTemplateField = null }) => {

    const { setEditerState } = useSelectionAction();

    const [isActive, setActivelist] = useState(0);
    const [ hoverTemp, sethoverTemp ] = useState(false);
    const [color, setcolor] = useState('#544f6f')
    const [currentMediatype, setcurrentMediatype] = useState('image')

    const { fieldid, ActiveFieldSide } = useSelector((state) => ({ fieldid: state.fields, ActiveFieldSide: state.ActiveFieldSide }))

    useEffect(() => {
        let id = `EditorContentBlock${ActiveFieldSide.filedId}`
        if (ActiveFieldSide.filedId !== "") {
            let elem = document.getElementById(id)
            setActivelist(ActiveFieldSide.index)
            if (elem) document.getElementById(id).scrollIntoView()
        }

    }, [ActiveFieldSide])

    const DisPatch = useDispatch();

    // useEffect(()=>{
    // },[isActive])


    const content = (e, type, filedId, Checkstate = false) => {
        try {
            let stateCheck = false;

            const IsCheckBox = isNotEmpty(e) && e?.target?.type === 'checkbox' ? true : false;

            if (IsCheckBox) stateCheck = !Checkstate ? true : false

            var SetValue = "";
            if (type === "FontFamily") SetValue = e;
            else SetValue = IsCheckBox ? stateCheck : e?.target?.value

            // if(type==="EmbedCode") SetValue=JSON.stringify({html:SetValue})
            // console.log(SetValue,"SetValue")

            let setObj = {
                key: filedId,
                fieldname: type,
                value: SetValue
            }

            DisPatch(Actions.setFieldContentChange(setObj))
        } catch (e) {
        }

    }

    const Colorchage = (color, type, filedId) => {
        let setObj = {
            key: filedId === '' ? ShowMediaFieldId : filedId,
            fieldname: type,
            value: color
        }
        setcolor(color)

        DisPatch(Actions.setFieldContentChange(setObj))


    }
    const hoverToolTip = useRef(null);

    useEffect(() => {
        let handleAction = (event) => {

            if (hoverToolTip.current && !hoverToolTip.current?.contains(event.target)) {
                // alert("test-out")
                sethoverTemp(false)
            }

        };

        document.addEventListener("mousedown", handleAction);
    }, [])

    const [ShowMedia, setShowMedia] = useState(false)
    const [ShowMediaFieldId, setShowMediaFieldId] = useState('');

    const EditIcon = listOfIcon('edit2',12,12,'#000000')
    return (
        <>
            {ShowMedia && <MediaPopup cancel={setShowMedia} fieldType={currentMediatype === 'image' ? 'imageSrc' : "audioSrc"} filedIdstate={true} fieldId={ShowMediaFieldId} OnSelect={Colorchage} />}

            {filed.map((list, index) => {

                const Dataset = fieldid[list?.fieldId]?.filedContent
                if (list?.templatetype !== 'template') return (

                    <div className={`w-full flex ${(isActive === index) ? "border-1 border-primary h-fit delay-400 duration-700" : "border-1 duration-800"} flex-col  p-5 rounded-xl  cursor-pointer `} key={index} id={"EditorContentBlock" + list?.fieldId} onClick={() => {
                        setActivelist(index)
                        DisPatch(Actions.setActiveField({ filedId: list?.fieldId, index: index, }))

                        if (filed[index]?.type === "ecommerce") {
                            DisPatch(Actions.setisEcommerceFields(true))
                            DisPatch(Actions.setEcommerceFieldID(list?.fieldId))
                            // setEditerState("product");setEcommerceFieldID
                        } else {
                            setEditerState("design");
                            DisPatch(Actions.setisEcommerceFields(false))
                        }
                    }} >
                        <div className="w-full h-fit flex justify-between group min-h-[36px]">
                        <p className={`capitalize flex space-x-2 mix-w-[200px] font-medium ${(isActive === index) ? "text-primary" : ""} text-ellipsis overflow-hidden whitespace-nowrap`}>
                                    { ( (isActive === index) && hoverTemp) ? <input type='text' className="w-full border-2 overflow-hidden rounded-md px-2" defaultValue={ fieldid[list?.fieldId]?.filedContent?.filedName ?? list?.type ?? "" } ref={hoverToolTip} onChange={(e)=>content(e,'filedName',list?.fieldId)} />
                                    :<span className="flex my-auto w-fit max-w-[150px] text-ellipsis">{fieldid[list?.fieldId]?.filedContent?.filedName ?? list?.type }</span> }
                                    <span className={` ${(isActive === index) ? "flex" : "hidden"} bg-white w-full group-hover:flex my-auto px-2`} onClick={()=>sethoverTemp(true)} >{EditIcon}</span>
                                </p>

                            <div className="flex space-x-4" >
                                <EditorTooltipST position={index} show={(isActive === index)} />
                                <span className={`my-auto ${(isActive === index) ? "rotate-180 delay-400 duration-700" : "duration-1000"}`}>
                                    { listOfIcon('uparrow2',12,6,(isActive == index) ? "#2563EB" : "#06152D") }
                                 
                                </span>
                            </div>
                        </div>
                        <div className={` translate-display font-sans space-y-4 duration-700 ${(isActive == index) ? "block" : "hidden"} my-auto mt-2`}>

                            {
                                list?.fieldProps?.map((obj, Ind) => {


                                    return (

                                        obj?.inputType === 'text' ? <InputWithLabel key={Ind} label={obj?.label} labelTextsize={'text-sm'} name={obj?.type} textvalue={Dataset[obj?.type]} onchange={(e) => content(e, obj?.type, list?.fieldId)} /> :

                                        obj?.inputType === 'dropDown' && obj?.type === "FontFamily" ? <FontInput activeVal={Dataset?.FontFamily} list={AllFount} key={Ind} label={obj?.label} labelTextsize={'text-sm'} name={obj?.type} textvalue={Dataset[obj?.type]} onchange={(e) => content(e, obj?.type, list?.fieldId)} /> :

                                        obj?.inputType === 'dropDown' ? <DropMenu key={Ind} label={obj?.label} labelTextsize={'text-sm'} name={obj?.type} textvalue={Dataset[obj?.type]} onchange={(e) => content(e, obj?.type, list?.fieldId)} /> :

                                        obj?.inputType === 'color' ? <ColorPixerInput key={Ind} label={obj?.label} labelTextsize={'text-sm my-auto'} fieldType={obj?.type} fieldId={list?.fieldId} Onchange={Colorchage} colorHex={Dataset[obj?.type]} /> :

                                        obj?.inputType === 'swicth' ? <SwicthInput key={Ind} label={obj?.label} labelTextsize={'text-sm'} fieldType={obj?.type} fieldId={list?.fieldId} status={Dataset[obj?.type]} onchange={Colorchage} /> :

                                        obj?.inputType === 'textarea' ? <TextareaInput key={Ind} label={obj?.label} labelTextsize={'text-sm'} name={obj?.type} textvalue={Dataset[obj?.type]} onchange={(e) => content(e, obj?.type, list?.fieldId)} /> :

                                        obj?.inputType === 'Upload' ? <Imageinput src={Dataset[obj?.type]} type={obj?.type === "audioSrc" ? "audio" : "image"} chanaeAction={() => { setShowMedia(true); setcurrentMediatype(obj?.type === "audioSrc" ? "audio" : "image"); setShowMediaFieldId(list?.fieldId) }} /> :

                                        obj?.inputType === 'radio' ? <InputRadiocheck key={Ind} listarray={['Left', 'center', 'Right']} textValue={Dataset[obj?.type]} fieldType={obj?.type} fieldId={list?.fieldId} onchange={Colorchage} display='space-x-4' label={obj?.label} labelTextsize={'text-sm'} /> :

                                        obj?.inputType === 'checkBox' ? <CheckBox key={Ind} label={obj?.label} labelTextsize={'text-sm'} isChecked={Dataset[obj?.type]} onchange={(e) => content(e, obj?.type, list?.fieldId, Dataset[obj?.type])} /> :

                                        obj?.inputType === 'number' ? <InputWithLabel key={Ind} label={obj?.label} type="number" labelTextsize={'text-sm'} onchange={(e) => content(e, obj?.type, list?.fieldId)} textvalue={Dataset[obj?.type]} /> :

                                        null
                                    )
                                })

                            }

                        </div>
                    </div>

                )
                if (list?.templatetype === 'template') return (

                    <div className="w-full">

                        <div className={`w-full flex ${(isActive === index) ? "border-1 border-primary h-fit delay-400 duration-700" : "border-1 duration-800"} flex-col  p-5 rounded-xl  cursor-pointer min-h-[66px]`} key={index} id={"EditorContentBlock" + list?.fieldId} onClick={() => {
                            
                            setActivelist(index)
                            DisPatch(Actions.setActiveField({ filedId: list?.fieldId, index: index, }))

                            if (filed[index]?.type === "ecommerce") {
                                DisPatch(Actions.setisEcommerceFields(true))
                                DisPatch(Actions.setEcommerceFieldID(list?.fieldId))

                                // setEditerState("product");
                            } else {
                                setEditerState("design");
                                DisPatch(Actions.setisEcommerceFields(false))
                            }

                        }} >

                            <div className="w-full h-fit flex justify-between group min-h-[36px]" >

                                <p className={`capitalize flex space-x-2 mix-w-[200px] font-medium ${(isActive === index) ? "text-primary" : ""} text-ellipsis overflow-hidden whitespace-nowrap`}>
                                    { ( (isActive === index) && hoverTemp) ? <input type='text' className="w-full border-2 overflow-hidden rounded-md px-2" defaultValue={ fieldid[list?.fieldId]?.filedContent?.filedName ?? list?.type ?? "" } ref={hoverToolTip} onChange={(e)=>content(e,'filedName',list?.fieldId)} />
                                    :<span className="flex my-auto w-fit max-w-[150px] text-ellipsis">{fieldid[list?.fieldId]?.filedContent?.filedName ?? list?.type }</span> }
                                    <span className={` ${(isActive === index) ? "flex" : "hidden"} bg-white w-full group-hover:flex my-auto px-2`} onClick={()=>sethoverTemp(true)} >{EditIcon}</span>
                                </p>
                                <div className="flex space-x-4"  > 
                                    <EditorTooltipST position={index} show={(isActive === index)} /> 
                                    <span className={`my-auto ${(isActive === index) ? "rotate-180 delay-400 duration-700" : "duration-1000"}`}>{ listOfIcon('uparrow2',10,6,(isActive == index) ? "#2563EB" : "#06152D") } </span>
                                </div>
                            </div>
                            <div className={`w-full translate-display font-sans space-y-4 duration-700 ${(isActive === index) ? "block" : "hidden"} my-auto mt-2`}>

                                {

                                    <CommonTemplateSetting key={index} category={list?.category} fieldId={list?.fieldId} type={list?.type} setEditTemplate={setEditTemplate} setEditTemplateField={setEditTemplateField} />

                                }

                            </div>
                        </div>
                    </div>
                )
            })}
        </>
    )
}

export default ListContainer;
