import React, { useEffect, useState } from "react";

const SwicthInput = ({
    id= "",
    name ="",
    fieldType = '',
    fieldId='',
    type="text",
    label='Label Name',
    labelTextsize = '',
    labelTextWeight = '',
    Placeholder ="",
    fontweight = "",
    margin = "mb-3",
    padding = "",
    textvalue =null,
    disabledinput = false,
    width = "w-full",
    onchange = null,
    status=false,
    defaultOPtion = false,
    parentClass='space-x-2',
    ...prop
}) => {

    useEffect(()=> { setTriggerSwicth(status) } ,[status])

    const [triggerSwicth,setTriggerSwicth] = useState(status);

    const Trigger = (state) => {
        setTriggerSwicth(state);
        defaultOPtion  ? onchange(state) :onchange(state,fieldType,fieldId);
    }

     return (
         <div className={`relative flex  ${parentClass}`}>
            <div className={`w-14 transition-all duration-500  rounded-[8px] h-fit px-1.5 py-[0.4rem] cursor-pointer ${triggerSwicth ? 'bg-primary': 'bg-slate-400' } `} onClick={()=>Trigger(triggerSwicth?false:true)}>
                <div className={`relative transition-all duration-500 ${triggerSwicth ? 'left-6': 'left-0' } w-5 h-5 rounded-[7px] bg-white`}></div>
            </div>
            <label className={` ${labelTextsize} ${labelTextWeight} my-auto capitalize`}>{label}</label>
            {/* <input type={type} className={`hidden gz-basic-input h-10 block ${padding} text-14px text-black appearance-none bg-white  focus:border-primary  text-primary-gray  placeholder-gray-600 border rounded-8px ${width} px-14px leading-5 placeholder-opacity-40 ${margin} border-secondar`} id={id} name={name} placeholder={Placeholder} disabled={disabledinput} hidden /> */}
         </div>
     )
}

export default SwicthInput;