import React, { useState } from 'react'
import PrimaryButton from '../../../Button1/primarybutton'
import { useSelector } from 'react-redux'
import { split } from 'lodash'
import { deleteTeamMemberData } from '../../../../actions/teamMember'
import toast from 'react-hot-toast'
import { CgSpinner } from 'react-icons/cg'

// const data = [
//     {
//         img: localStorage.getItem('avatar'),
//         name: "Jhon",
//         email: "example@gmail.com",
//         type: "owner",
//     }
// ]


export const ShowTeamList = ({ setLoadding = null, setUserSerialId = null, setShowUpdate = null }) => {

    const { userDetail, teamUsers } = useSelector(state => ({ userDetail: state?.userDetail, teamUsers: state?.teamUsers }))

    const [loadingSpin, setloadingSpin] = useState(false);

    const [ activeId ,setactiveId ] = useState('');

    let data = [
        {
            img: userDetail?.profile,
            first_name: userDetail?.first_name,
            email: userDetail?.email,
            engage_user_role: userDetail?.engage_user_role,
        }
        , ...teamUsers
    ]

    const DeleteTeamMember = async (id,ind) => {

        setactiveId(id);

        data?.slice(ind,1)

        if (!id) return toast.error('user is missing')

        setloadingSpin(true)

        const DeleteEvent = await deleteTeamMemberData({ id })

        setloadingSpin(false)


    }


    return (
        data.map((i, k) => {

            const permission = (i?.engage_user_role === 'admin') ? "admin" : JSON.parse(i?.engage_permissions ?? "{}")

            return (
                <div key={k} className='rounded-md px-4 py-2  bg-white flex items-center justify-between '>

                    <div className='flex items-center'>
                        <img src={i?.img ?? "/asset/images/Default-User.png"} alt="avter" className='w-16 h-16 rounded-lg border-1' />
                        <div class="ml-3">
                            <p class="text-base font-medium"> {(!i?.first_name || i?.first_name == '') ? split(i?.email, '@')[0] : i?.first_name} </p>
                            <p class="text-sm font-normal text-font_secondary"> {i.email} </p>
                        </div>

                    </div>

                    <div className='flex justify-end w-full items-center '>
                        <div className='space-x-4 flex items-center'>
                            <div className='  px-2 rounded-md h-full capitalize '>{(i?.engage_user_role === 'admin') ? 'owner' : 'member'}</div>
                            <button
                                className={` flex items-center px-3 space-x-2 border-1 border-primary py-2 text-primary rounded-lg text-sm font-normal ${(i?.engage_user_role === 'admin') ? "opacity-40 cursor-not-allowed" : "opacity-100 "}`}
                                // onClick={() => console.log("")}
                                disabled={(i?.engage_user_role === 'admin')} onClick={() => { setUserSerialId(i?.id); setShowUpdate(true) }} >
                                <span className='flex my-auto'>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.75 16.5H2.25C1.9425 16.5 1.6875 16.245 1.6875 15.9375C1.6875 15.63 1.9425 15.375 2.25 15.375H15.75C16.0575 15.375 16.3125 15.63 16.3125 15.9375C16.3125 16.245 16.0575 16.5 15.75 16.5Z" fill="#2563EB" />
                                        <path d="M14.2649 2.60926C12.8099 1.15426 11.3849 1.11676 9.89243 2.60926L8.98493 3.51676C8.90993 3.59176 8.87993 3.71176 8.90993 3.81676C9.47993 5.80426 11.0699 7.39426 13.0574 7.96426C13.0874 7.97176 13.1174 7.97926 13.1474 7.97926C13.2299 7.97926 13.3049 7.94926 13.3649 7.88926L14.2649 6.98176C15.0074 6.24676 15.3674 5.53426 15.3674 4.81426C15.3749 4.07176 15.0149 3.35176 14.2649 2.60926Z" fill="#2563EB" />
                                        <path d="M11.7075 8.64711C11.49 8.54211 11.28 8.43711 11.0775 8.31711C10.9125 8.21961 10.755 8.11461 10.5975 8.00211C10.47 7.91961 10.32 7.79961 10.1775 7.67961C10.1625 7.67211 10.11 7.62711 10.05 7.56711C9.8025 7.35711 9.525 7.08711 9.2775 6.78711C9.255 6.77211 9.2175 6.71961 9.165 6.65211C9.09 6.56211 8.9625 6.41211 8.85 6.23961C8.76 6.12711 8.65499 5.96211 8.5575 5.79711C8.4375 5.59461 8.3325 5.39211 8.2275 5.18211C8.08983 4.88711 7.70264 4.79947 7.47244 5.02966L3.255 9.24711C3.1575 9.34461 3.06749 9.53211 3.04499 9.65961L2.63999 12.5321C2.56499 13.0421 2.7075 13.5221 3.0225 13.8446C3.2925 14.1071 3.66749 14.2496 4.07249 14.2496C4.16249 14.2496 4.2525 14.2421 4.3425 14.2271L7.2225 13.8221C7.3575 13.7996 7.545 13.7096 7.635 13.6121L11.8594 9.3877C12.085 9.16211 12.0002 8.77396 11.7075 8.64711Z" fill="#2563EB" />
                                    </svg>

                                </span>
                                {/* <MdEdit className="mx-2" />  */}
                                <span className='flex my-auto'>

                                    Manage Permission
                                </span>

                            </button>

                            <button
                                className={`
                                    flex items-center p-2  text-red-500 white border-1 rounded-lg hover:border-red-500  text-sm font-normal
                                    ${(i?.engage_user_role === 'admin') ? "opacity-40 cursor-not-allowed" : "opacity-100 "}    
                                `}
                                // onClick={() => console.log("")}
                                disabled={(i?.engage_user_role === 'admin')}

                                onClick={() => DeleteTeamMember(i?.id,k)}

                            >
                                <div>

                                </div>
                                {/* <MdDelete color={"red"} className="w-4 h-5 mx-2" /> */}
                                <span className='w-fit flex my-auto'> {
                                    (loadingSpin && (activeId == i?.id) ) ? <CgSpinner className="w-5 h-5 animate-spin text-red-500" /> :
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.75 5.04687C15.735 5.04687 15.7125 5.04687 15.69 5.04687C11.7225 4.64937 7.7625 4.49937 3.84 4.89687L2.31 5.04687C1.995 5.07687 1.7175 4.85187 1.6875 4.53687C1.6575 4.22187 1.8825 3.95187 2.19 3.92187L3.72 3.77187C7.71 3.36687 11.7525 3.52437 15.8025 3.92187C16.11 3.95187 16.335 4.22937 16.305 4.53687C16.2825 4.82937 16.035 5.04687 15.75 5.04687Z" fill="#DC2626" />
                                            <path d="M6.37507 4.29C6.34507 4.29 6.31507 4.29 6.27757 4.2825C5.97757 4.23 5.76757 3.9375 5.82007 3.6375L5.98507 2.655C6.10507 1.935 6.27007 0.9375 8.01757 0.9375H9.98257C11.7376 0.9375 11.9026 1.9725 12.0151 2.6625L12.1801 3.6375C12.2326 3.945 12.0226 4.2375 11.7226 4.2825C11.4151 4.335 11.1226 4.125 11.0776 3.825L10.9126 2.85C10.8076 2.1975 10.7851 2.07 9.99007 2.07H8.02507C7.23007 2.07 7.21507 2.175 7.10257 2.8425L6.93007 3.8175C6.88507 4.095 6.64507 4.29 6.37507 4.29Z" fill="#DC2626" />
                                            <path d="M11.4075 17.0617H6.59255C3.97505 17.0617 3.87005 15.6142 3.78755 14.4442L3.30005 6.8917C3.27755 6.5842 3.51755 6.3142 3.82505 6.2917C4.14005 6.2767 4.40255 6.5092 4.42505 6.8167L4.91255 14.3692C4.99505 15.5092 5.02505 15.9367 6.59255 15.9367H11.4075C12.9825 15.9367 13.0125 15.5092 13.0875 14.3692L13.575 6.8167C13.5975 6.5092 13.8675 6.2767 14.175 6.2917C14.4825 6.3142 14.7225 6.5767 14.7 6.8917L14.2125 14.4442C14.13 15.6142 14.025 17.0617 11.4075 17.0617Z" fill="#DC2626" />
                                            <path d="M10.2451 12.9375H7.74756C7.44006 12.9375 7.18506 12.6825 7.18506 12.375C7.18506 12.0675 7.44006 11.8125 7.74756 11.8125H10.2451C10.5526 11.8125 10.8076 12.0675 10.8076 12.375C10.8076 12.6825 10.5526 12.9375 10.2451 12.9375Z" fill="#DC2626" />
                                            <path d="M10.875 9.9375H7.125C6.8175 9.9375 6.5625 9.6825 6.5625 9.375C6.5625 9.0675 6.8175 8.8125 7.125 8.8125H10.875C11.1825 8.8125 11.4375 9.0675 11.4375 9.375C11.4375 9.6825 11.1825 9.9375 10.875 9.9375Z" fill="#DC2626" />
                                        </svg>

                                }
                                </span>


                            </button>

                        </div>
                    </div>
                </div>)
        }
        )
    )
}
