import React from "react";
import PrimaryButton from "./Button1/primarybutton";
import { listOfIcon } from "../content/iconList";

export const ConnectionImage = ({ Index, imagSrc = '',onClickEVent=null,deleteEvent=null, showDel=true }) => {

    const placeholderImage ='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAADCklEQVR4nO3dv25TMRQG8CuBAAFiR2JHYgCmAo+EBF0RE5F4gbas7SN0YYh4A0CoKPNdMpQ08TknU5HaTkYWqdQliX3jHP+53yedpUnj5Ffr2Ily3aZBEARBEATJLtba26mfQ/Vh5gEzfxuPx/dSP5fake2igK2ADGxFZGDHXviYebgC2tWwbdu7UQfuY6y1d4jo6xps9OwYAbZigK0Y14s9enaf6i8R/SCi3ejrFLB5GfpIRJ4Am1Vm+KjTzLbW3sLM5iBsY8z7YGgiOmbmp8Bmb2gi+h4MzcznzDwTkWcb7rNtj+q8C/T1LwOb/bE3gQY260EDm/WgXZ1VukBeMPOeiOxMp9MHrkTkFTPvM/Ol9oyuEpuITmez2fNlr8cY88LdJwV0TdgXq5BvYvvO7NjQtWDvBXgcpIIuHltEdnw9ROR1Suii99nGmIe+Hu6+qaGLxTYB0CLyKAfoItuIFNY6Ssbe9/Ugoi85QZeGfem2bussjDEviegqN+iisInodBX2AvmP7+NpQxeFzf/fjBy4PuwWSFfz+fyNaxe+MzkltKuTVd9AbfPCjlIpoA/dhzPrxmkrw9aGHoSM1VaErQZNRJ+CB2vyfVOTJXRX5Jqwm9BoI9eC3YRmmz152z2biCYi8tHtj6+3a24/7H622HYWCR0VOQL24WQyud8sibuNmY9Kg94K8gbYg0hXM+QF3Sik9cf+HPrY28AuFnrb389m5g+ALhC76BldEnYV0CVgVwOdO3ZV0DljVwedK3aV0DliVwudG3bV0DlhVw+dC3YvoBWwAa2BDWglbEArYQNaCRvQStiAVsIGtBI2oJWwk0FnXLNU19T0Ddqmwu4jtE2B3fVglNRQtjDs8INRiOhnBkg2Uqlc5uGOZ+sCvZsBkC0JW0TeBUMvBh5lAGQLwf7t2lDTJe7QPGCzD/IJET3uhHzzr+zOc3NHjVW0QJ7FmNlE9IuZ33aeyUiDg281g1OGFQNsxXj27CH+T43CzI51VRrSLMcGsgI2kBWwgawQLHwIgiAIgjRZ5h+eI5cjs48/GwAAAABJRU5ErkJggg=='
    
    const onImageError = (e) => { e.target.src = placeholderImage };

    const deleteIcon = listOfIcon('delete',18,18,'#586474');
    
    const toolTip = [

        {
            type: "delete",
            icon: deleteIcon
        },
    ]

    return (
        <>
            <div  key={Index} className="w-full flex justify-between border-1 p-2  rounded-lg" >
                <div className="flex space-x-4 cursor-pointer w-full" onClick={onClickEVent}>
                    <div className="w-10 h-10 rounded-md border-1">
                        <img src={imagSrc?imagSrc:placeholderImage} onError={onImageError} alt='' className="w-full h-full object-cover rounded-md" />
                    </div>
                    <p className="my-auto capitalize text-sm">image - 1</p>
                </div>
                {showDel &&
                <div className="flex space-x-2">
                    {
                        toolTip.map((list, index) => {
                            return (
                                <p key={index}  className="p-2 my-auto h-fit rounded-md border-1 hover:bg-gray-200 cursor-pointer" onClick={ deleteEvent }  >{list.icon}</p>
                            )
                        })
                    }
                </div>
                }
            </div>
        </>

    )
}