import React, { useEffect, useState } from "react";
import PrimaryButton from "../Button1/primarybutton";
import { ValidateIntegration } from "./integrationValidation";
import { IntegrationFieldSelection } from "./IntegrationFiledSelection";
import toast from 'react-hot-toast';
import Axios from "../../axios";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../store";
import { CreateOauthList, CreateOauthToken, createIntegrationv2, getOauthURL } from "../../actions/integrations";
import { LoadingSpinner } from "../Loading";

export const SelectEdIntegration = ({
    selectobj={},
    setShow=null,
    closed=null
}) => {

    const Dispatch = useDispatch();

    const { camp, integrationOnj, oauthStatus, stateValue } = useSelector((state => ({ camp:state?.Campaign_info,integrationOnj:state?.Campaign_integration, oauthStatus:state?.oauthIntegrationStatus, stateValue:state })))

    const [intergationStage, setintergationStage] = useState(0)

    const [ CredentialsData, setCredentials ] = useState({});

    const [ listObj, setListObj ] = useState([]);

    const [ tagObj, setTagObj ] = useState([]);

    const [ workspaceObj, setWorkspaceObj ] = useState([]);

    const [ ActiveSec, setActiveSec ] = useState(0);

    const [ isAddlist, setIsAddList ] = useState( false );

    const [ creating, setcreating ] = useState( false );

    const [ loadinfContent, setLoadingContent ] = useState( 'Loading....' )

    const [ Loading, SetLoading ] = useState(false)

    useEffect( () => {

        const findmailer = integrationOnj?.findIndex( obj => obj?.mailer == selectobj?.mailer )

        if( findmailer < 0 &&( selectobj?.type == 'oauth') ){

            getURl()

        }

        if(findmailer > -1){
           
             setActiveSec(1);
             setIsAddList(true);

        }

    },[ ] )

    useEffect(()=> {

        if( integrationOnj?.length > 0 && selectobj.type == "oauth" ) forwardAction(1)
    } ,[integrationOnj])

    

    useEffect(() => {

        if (!creating) {

            window.addEventListener("storage", () => {

                if ( (localStorage.getItem("googleSheet_code" ) !== null) && (localStorage.getItem("googleSheet_code")?.length > 0)) {

                    createOauthIntegrationList(stateValue, { code: localStorage.getItem("googleSheet_code"), mailer: selectobj?.mailer })

                    localStorage.removeItem("googleSheet_code");
                    setActiveSec(1);


                }
            });
        }
    }, [])

 
    const getURl = async () => {

        try{

            const getURl = await getOauthURL( "", selectobj?.mailer );
            onAuth(getURl)

        }catch(err){
            toast.error('Integration Failed')
        }

    }


    const createOauthIntegrationList = async ( state, action )=>{

        try{

            setcreating( true )

            const { mailer, code } = action;

            if( !code ) return;

            let Props = Object.values(state.fields).map( (fields) => {

                let isThisGameType = ( fields.category == 'games' || fields.category == 'spinWheel' );

                const questionType = ( fields.type == 'calculater') ? 'questionsList' : 'questions';

                let fieldsprop = isThisGameType ? fields.filedContent?.game_leadForm_json?.field.map( list =>  list.Label ) : fields.filedContent?.game_mainpage_json[questionType].map( list => list.question )

                return ({ name: fields.type, fields:fieldsprop })

            } )


            if( mailer == 'google_sheet') {

                let payload = {
                    
                    sheetName:state.Campaign_info.name,
                    sheetProps:Props
                }

                setLoadingContent('connecting a Google Sheet...ß')

                const respResult = await CreateOauthToken( state.Campaign_info.Campaign_id, mailer, code );

                const { refresh_token, access_token } = respResult;

                setLoadingContent('Creating a Google Sheet.....');
                
                const resp =  await CreateOauthList( state.Campaign_info.Campaign_id, mailer, { refresh_token, access_token, ...payload } );

                let credentials = {
                    refresh_token,
                    access_token,
                    spreadSheetId:resp?.spreadSheetId,
                    sheetName:resp?.sheetName
                }

                const lists = resp?.sheetId.map( list => list?.sheetId );

                Dispatch( Actions.addOauthIntegration( { mailer:selectobj?.mailer, lists, credentials } ) );

                setLoadingContent('Please wait as Google Sheet is added to your list...')

            }


            setcreating( false )

            setLoadingContent('Loading')

        }catch( Err ){


            toast.error( "Failed" )

        }

    }

    const onAuth = ( authUrl ) => {
        const width = 600;
        const height = 600;
        const left = window.innerWidth / 2 - width / 2;
        const top = window.innerHeight / 2 - height / 2;

        const newWin = window.open(
            authUrl,
            "google oauth",
            `
            width=${width}, 
            height=${height}, 
            top=${top}, 
            left=${left}
        `
        );

        newWin?.focus();
    };


    const forwardAction = async (ind) =>{

        const ObjLenght = Object.keys(CredentialsData);

        const SelectedObjKey = Object.keys(selectobj?.input_credentials)

        if( ind == 0 ) {

            SetLoading(true)

            SelectedObjKey.findIndex(list => {
                if( CredentialsData[list] ) return list;
            });

            if( SelectedObjKey > 1 ) return toast.error('please Fill Required Fields.')

            return Axios.post('/integration/v2/validate',{ mailer:selectobj?.mailer, credentials:CredentialsData}).then(Res =>{

            setListObj(Res.data.list);

            setTagObj(Res.data.field);

            setWorkspaceObj(Res.data.workspace);

            setActiveSec((ind+1));

            let payload = {mailer:selectobj?.mailer,...Res.data?.data,}
            

            Dispatch(Actions.setIntegrationmailerObj(payload))

            Dispatch(Actions.addCampaignIntegratio({mailer:selectobj?.mailer,credentials:CredentialsData}))

            SetLoading(false)
            
                        
            }).catch(Err => {
                SetLoading(false)
            
                toast.error('Invaild credentials')
            })

        }


        if(ind == 1) {

            SetLoading(true)
            
            const payload = integrationOnj[0];

            const create = await createIntegrationv2( camp?.Campaign_id, payload);

            if( create?.success ) toast?.success('integration added to list');

            else toast?.error( 'Failed to add' );

            setTimeout( () => {

                SetLoading(false)

                setShow(false)
    
                return closed()
                
            }, 2000 )



        }


        setActiveSec((ind+1))
    }

    const BackWardAction = (ind) =>{
        if(ind == 0) return setShow(false);

        if( isAddlist && ( ind == 1 ) ) return setShow(false);
        setActiveSec((ind-1))
    }


    return (

        <div className="absolute top-0 left-0 flex w-full h-full bg-backgroundShadow border-t-1" >

            <div className=" w-full h-full flex flex-col bg-white m-auto overflow-hidden">

                { ( selectobj?.type !== 'oauth') && <ValidateIntegration select={selectobj} credentials={CredentialsData} Loading={Loading} ActiveSec={ActiveSec} setActiveSec={setActiveSec} setCredentials={setCredentials} workspaceObj={workspaceObj} ListObj={listObj} TagObj={tagObj} /> }

                {  (  selectobj?.type == 'oauth' ) && <>
                
                    <LoadingSpinner text={loadinfContent} />
                
                </>  }

                <div className="w-full h-fit flex mt-auto" >

                    <div className="w-full flex flex-col " >
  
                        <div className="w-full flex justify-end space-x-2 border-t-1 py-3 px-10">

                            <PrimaryButton text={"Cancel"}  textcolor="#586474" backgroundColor="#fff" OnClick={()=>BackWardAction(ActiveSec)} disabled={Loading}/>

                            <PrimaryButton text={"Next"} OnClick={()=>forwardAction(ActiveSec)} disabled={Loading} />

                        </div>

                    </div>

                </div>

            </div>

        </div>
    )

}