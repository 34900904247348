import React, { useEffect, useRef } from "react";
import PrimaryButton from "../Button1/primarybutton";
import Input from "../input/input";
import { Clickouter } from "../../utils/clickouter";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { Actions } from "../../store";


export const LiveCamp = ({
    title = '',
    reftype=null,
    defaultVaule = '',
    type='',
    changeDefaultVaule = null,
    forwardAction = null,
    backwardAction = null,
    className="", 
}) => {
    const {CampaignDetail,DomainList, campaign} = useSelector((state) => ({CampaignDetail:state.Campaign_info,DomainList:state.domain, campaign:state.Campaign_info}))

    const renderEngineurl = process.env.REACT_APP_ENV === "development" ? `http://localhost:3400/${CampaignDetail.Campaign_id}` : process.env.REACT_APP_RENDER_ENGINE_URL+CampaignDetail.Campaign_id;
    const Dispatch = useDispatch()
    const menuREf = useRef(null);
    
    useEffect(()=>{
        let handleAction = (event) => {

            if (menuREf.current && !menuREf.current?.contains(event.target)) {
                Dispatch(Actions.setCampaignUpdate(cppayload))
                // alert("test-out")
                // backwardAction();
            }
        };
    
        document.addEventListener("mousedown", handleAction);
    },[])
    const cppayload ={
        type:'CPstatus',
        value: false 
    }
    const copy = () => {   
        navigator.clipboard.writeText(renderEngineurl);
        toast.success("Copied!");
        Dispatch(Actions.setCampaignUpdate(cppayload))

    }

    return (
        <div className="fixed top-0 left-0 flex w-full h-full bg-backgroundShadow"  style={{ zIndex: '55' }}>
            <div className={`p-4 w-[350px] rounded-lg bg-white m-auto space-y-4 ${className} `} ref={menuREf} >

                <p className="capitalize text-lg font-medium">{title}</p>
                <Input textvalue={renderEngineurl} disabledinput={true}  />

                <div className="flex justify-end space-x-2">
                    <PrimaryButton buttontype={'secondarybutton'} width={'w-24'} text={'cancel'} OnClick={()=>{Dispatch(Actions.setCampaignUpdate(cppayload))}} />
                    <PrimaryButton text={'Copy'} width={'w-24'} OnClick={copy} />
                </div>

            </div>
        </div>
    )
}