import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Layout from ".";
import { LastModified } from "../utils/dateconvetor";
import SearchBar from "../components/input/searchbar";
import { FilterComponent } from "../components/FilterComponent";
import { categoryData } from "../content/category";
import { Crudopration } from "../components/Crudopration";
import { DeleteCampaign } from "../components/dashboard/DeleteProject";
import { LoadingSpinner } from "../components/Loading";
import PrimaryButton from "../components/Button1/primarybutton";
import DefaultDropMenu from "../components/input/droplist";
import {  getProject, getProjects } from "../actions/projects";
import { SimpleCopy } from "../components/popup/SimpleCopy";
import { SimpleDelete } from "../components/popup/SimpleDelete";
import { SimpleMove } from "../components/popup/SimpleMove";
import { isEmpty, isEqual, isNotEmpty } from "../utils/helpers";
import {MoveCampaign} from "../components/dashboard/MoveCampaign"
import { WorkspaceMemberList } from "../components/popup/WorkspaceMemberList";




const InnerProject = ({ SharedWS= false }) => {

    const param = useParams()
    const navigate = useNavigate()
    const [campaignCount, setCampaignCount] = useState(0);
    const [views, setviews] = useState(0);
    const [Respcount, setRespcount] = useState(0);
    const [campaigns, setCampaigns] = useState([]);
    const [Loading, setLoading] = useState(false)
    const CAteDAta = categoryData()
    const dropmenuList=[10,25,50,100]
    const [activeRowPerPage,setActiveRowPerPage]=useState(0)
    const [pageIndex,setPageIndex]=useState([1,10]) 
    const [rowPerPage,setRowPerPage]=useState(10)
    const [tableData,setTableData]=useState([])
    const [totalpage,setTatalpage] = useState(0)
    const [name, setName] = useState("")

    const[lastJsonObj, setLastObj] = useState({})
    const[ProjectInfo, setProjectInfo] = useState({})
    const [loadingText, setloadingText]= useState("Fetching Your Data")
    const [showCpy, setshoWCpy] = useState(false)
    const [showDel, setshoWDel]= useState(false)
    const [showMov, setshoWMov]= useState(false)

    const [showMedia, setShoeMedia] = useState(false)
    const [ShowloadingSpin, setShowloadingSpin] = useState(false);
    const [projects, setProjects] = useState([])
    const [onChangePN, setOnchangePN] = useState('My Project')
    const [seletedProject, setSeletedProject] = useState(null)
    const [show, setShow] = useState(false)

    const [teamList, setTeam] = useState([])
    const [projectName, setprojectName] = useState("")





    useEffect(() => { fetchData() }, [])

    const fetchData = async () => {
        setLoading(true)
        const ProjectList = await getProjects( SharedWS )
        if (ProjectList?.status) {
            let temp=(ProjectList?.data ?? [])
            setProjects(temp)

            let  tempTeam=[]
            if(isNotEmpty(param?.project_id) && temp?.length > 0){
                temp?.map((val)=>{

                        if(isEqual(val?.project_id,param?.project_id)){
                            tempTeam = val?.member_info
                            setprojectName(val?.project_name)
                            return null;
                        }
                })
            }          
            setTeam(tempTeam)
            setLoading(false)
        }
        else {
            toast.error('Can\'t retrive the your Project')
            setLoading(false)
        }

    }



    const [popNum, setPopNum] = useState()
    const [loadingForDelet, setLoadingForDelte] = useState(false)





    const Navigate = useNavigate()

    const SortByName = () => {
        const data = [...campaigns].sort((a, b) => b.name < a.name ? 1 : -1)
        setCampaigns(data)

    }

    const SortByDate = () => {
        const data = [...campaigns].sort((a, b) => a.createdAt < b.created_at ? 1 : -1)
        setCampaigns(data)

    }

    const SortByLive = () => {
        const data = [...campaigns].sort((a) => a.status === "active" ? -1 : 1)
        setCampaigns(data)
    }

    const SortByPaused = () => {
        const data = [...campaigns].sort((a) => a.status === "paused" ? -1 : 1)
        setCampaigns(data)
    }




    useEffect(() => { loadDashboard() }, [loadingForDelet])

    useEffect(()=>{
        let a = window.addEventListener("click", function(e){
            const dotPlace = e?.target?.getAttribute("dotPlace")
        if(dotPlace!=="MainDot"){
            setPopNum(null)
        }
        });
        return(()=>{
            window.removeEventListener("click",a)
        })
        },[])
    

        function setDropDown(index){
            setLastObj(data[index])
            if(popNum===index)setPopNum(null)
            else setPopNum(index)        
        }


    const prev = () =>{ if(pageIndex[0]-rowPerPage>0 )  setPageIndex([pageIndex[0]-rowPerPage,pageIndex[1]-rowPerPage]) }

    const next = () =>{
        if( pageIndex[1]+rowPerPage < (data?.length+rowPerPage))  setPageIndex([pageIndex[0]+rowPerPage,pageIndex[1]+rowPerPage])
    }



    const LoadMore =(index) =>{
        setRowPerPage(dropmenuList[index]) // 10

        let pagecount = 0

        if((data.length % dropmenuList[index]) < dropmenuList[index] ) pagecount +=1
        let sum = Math.floor(data.length / dropmenuList[index])
        pagecount += sum
        setTatalpage(pagecount)
        // const findIndex = dropmenuList.findIndex(i => i === state)
        setActiveRowPerPage(index) 
        setPageIndex([1,dropmenuList[index]])    
    }

    const data = campaigns.filter((a) => String(a.name).toLowerCase().includes(name))
    // console.log(data,"data")
    const CURDdata = [
        {
            name: "Publish",
            icon:"" 
        },
        {
            name: "Pause",
            icon:"" 
        },
        {
            name: "Copy Link",
            icon:"" 
        },

        {
            name: "Move",
            icon:"" 
        },
        {
            name: "Delete",
            icon:"" 
        },
        {
            name: "Edit",
            icon:"" 
        }
    ]


    const renderEngineurl = process.env.REACT_APP_ENV === "development" ? `http://localhost:3400/${lastJsonObj?.campaign_id}` : process.env.REACT_APP_RENDER_ENGINE_URL+lastJsonObj?.campaign_id;


    const copy = () => {   
        navigator.clipboard.writeText(renderEngineurl);
        toast.success("Copied!");
        setshoWCpy(false)
    }

    const deleteCamp = async () =>{
        setshoWDel(false)
        setLoadingForDelte(true)
        await DeleteCampaign(lastJsonObj?.campaign_id)
        await loadDashboard()
        setLoadingForDelte(false)
    }

    const moveCmp = async () =>{
        setLoadingForDelte(true)

        if(isEmpty(seletedProject) || isEmpty(lastJsonObj?.campaign_id) || isEmpty(param?.project_id)) {
            toast.error( isEmpty(seletedProject) ? "Kindly select the workspace!" :"Something went wrong!");
            setSeletedProject(null);
            setLoadingForDelte(false)
            return;
        }
        setshoWMov(false)


        let res= await MoveCampaign( lastJsonObj?.campaign_id,  param?.project_id, seletedProject )
      
        // console.log(lastJsonObj?.campaign_id ," => camp to workspace = " , seletedProject, param?.project_id)

        setshoWMov(false)
        setSeletedProject(null)
        setLastObj({})
        await loadDashboard()
        setLoadingForDelte(false)

        if(res["success"]===true) toast.success("Campaign was moved!");
        else  toast.error("Something went wrong!");
    }


    const ShowData = ({ loading }) => {

        if (loading) return <LoadingSpinner text={loadingText} />

        return data.length > 0 ? data.map((list, index) => {
            if((pageIndex[0]-1 <= index) && (index < pageIndex[1]+1))return (
                <div key={index} className="transition-all relative bottom-0 hover:border-primary hover:bottom-1 cursor-pointer duration-500 hover:shadow-card-sm  rounded-16px border border-border-light pl-12px pr-32px py-12px flex flex-row mt-12px animate-fadeIn h-3/4=5" style={{ boxShadow: '0px 10px 40px 0px rgba(11, 13, 63, 0.04)' }} >

                    <div className="flex-1 flex justify-between" onClick={() => SharedWS ? Navigate(`/shared-workspace/campaign/edit/${param?.project_id}/${list?.campaign_id}`) : Navigate(`/campaign/edit/${list?.campaign_id}`)}>
                        <div className="flex w-1/2">
                            <div className="overflow-hidden flex w-60px h-60px">
                                <img alt="Icon" className="rounded-8px max-h-full object-cover m-auto" src={ CAteDAta[list?.category]?.src ?? "/asset/images/template/gallery/1.png"} />
                            </div>
                            <div className="flex flex-col items-left ml-4 w-full">
                                <span className="text-primary-bold mb-6px text-16px font-medium hover:underline"  >{list.name}</span>
                                <span className="text-primary-gray font-normal text-14px">{LastModified(list.created_at)}</span>
                            </div>
                        </div>
                        <div className="flex flex-row justify-between items-center w-1/2">
                            <div className="flex w-fit space-x-1">
                                <span className={`w-2 h-2 border-1 rounded-2xl my-auto ${(list.status === 'paused') ? 'bg-yellow-400' : 'bg-green-600 '}`}></span>
                                <span className="min-w-[60px]">{list.status === 'active' ? 'Live' : list.status}</span>
                            </div>
                            {recentcontent('views', list?.view_count ?? 0)}
                            {recentcontent('clicks', list?.click_count  ?? 0)}
                            {recentcontent('responses', list?.subscribe_count ?? 0)}
                        </div>
                    </div>
                    {/* <div className="flex items-center justify-center  align-middle m-auto pl-4 relative"onClick={() => setPopNum(index)}> */}
                    <div className="flex items-center justify-center  align-middle m-auto pl-4 relative" onClick={() =>  setDropDown(index)} dotPlace={"MainDot"}>

                        <div className="my-auto flex justify-center items-center" dotPlace={"MainDot"}>
                            <div className="text-[30px] rotate-90 hover:bg-gray-100 w-[40px] h-[40px] rounded-full flex pb-4 items-center justify-center noselect" dotPlace={"MainDot"}>...</div>
                        </div>

                        <Crudopration SharedWS={SharedWS} projects={projects} setshoWMov={setshoWMov} setshoWDel={setshoWDel} setshoWCpy={setshoWCpy}  setloadingText={setloadingText} loadDashboard={loadDashboard} setLoading={setLoading}  setPopNum={setPopNum} status={list?.status}  show={popNum === index} camId={list?.campaign_id} setLoadingForDelte={setLoadingForDelte} data={CURDdata} index={index} />
                    
                    </div>
                </div>

            )
        }) : <></>
    }



    const loadDashboard = async () => {
        setLoading(true)
        // const DashboardDetail = await getDashboardInfo();
        const DashboardDetail = await getProject(param?.project_id,SharedWS);
        //  console.log({DashboardDetail})
        setCampaignCount(DashboardDetail.data?.Eventcount?.total_popups ?? 0)
        setCampaigns(DashboardDetail.data?.Campaigns  ?? [])
        setviews(DashboardDetail.data?.Eventcount?.total_views ?? 0)
        setRespcount(DashboardDetail.data?.Eventcount?.total_subscribe ?? 0)
        setProjectInfo(DashboardDetail.data?.workspaceInfo ?? {})

        setTeam(DashboardDetail.data?.member_info)
        setLoading(false)
    }


    const recentcontent = (title, count) => {
        return (
            <div className="flex flex-col items-center w-80px">
                <span className="text-primary-bold text-15px font-bold leading-22px mb-6px" >{count}</span>
                <span className="leading-16px text-14px font-normal text-primary-gray">{title}</span>
            </div>
        )
    }

    return (
        <>
        
            { show && <WorkspaceMemberList teamList={teamList} fetchData={fetchData} isSharedWS={SharedWS} setShow={setShow} projectId={param?.project_id} projectName={projectName} /> }

            <Layout isactive={0}>


                <div className={loadingForDelet ? "w-full h-full" : "w-full h-full"} >
                    <div className="flex h-auto justify-between  py-2 ">
                        <div className="flex space-x-2 pb-5" >
                            <div className="flex my-auto">
                            <span onClick={() => { navigate(-1) }} className={`flex transform duration-700 my-aut0 p-2 px-3 rounded-md my-auto cursor-pointer ${pageIndex[0] <= 1 ? "" : ""} hover:bg-gray-200`}><svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.90969 0.710469C6.51969 0.320469 5.88969 0.320469 5.49969 0.710469L0.909687 5.30047C0.519688 5.69047 0.519688 6.32047 0.909687 6.71047L5.49969 11.3005C5.88969 11.6905 6.51969 11.6905 6.90969 11.3005C7.29969 10.9105 7.29969 10.2805 6.90969 9.89047L3.02969 6.00047L6.90969 2.12047C7.28969 1.73047 7.28969 1.09047 6.90969 0.710469Z" fill="#586474" /></svg></span>

                            </div>
                         <img src={ProjectInfo?.project_img_src ?? "https://gz-engage-assets.s3.amazonaws.com/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/c7a655060994272bcf9a99a5afde2eaf.jpg"} className="w-[42px] h-[42px] my-auto rounded-full object-cover" alt="Workspace" title="Workspace" ></img> 
                        <h2 className=" my-auto font-bold text-22px text-primary-bold  capitalize"> {ProjectInfo?.project_name ?? "My Workspace"} </h2>
                        </div>
                        <div className="flex flex-row items-center">



                        <div className="flex flex-row mr-3  items-center border-2 p-1   w-[70px] justify-center shadow-sm h-[40px] rounded-[8px] border-[#EDEFF1]">   <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_4276_3386)">
                        <path d="M6.75 10.3125C4.995 10.3125 1.5 11.19 1.5 12.9375V14.25H12V12.9375C12 11.19 8.505 10.3125 6.75 10.3125ZM3.255 12.75C3.885 12.315 5.4075 11.8125 6.75 11.8125C8.0925 11.8125 9.615 12.315 10.245 12.75H3.255ZM6.75 9C8.1975 9 9.375 7.8225 9.375 6.375C9.375 4.9275 8.1975 3.75 6.75 3.75C5.3025 3.75 4.125 4.9275 4.125 6.375C4.125 7.8225 5.3025 9 6.75 9ZM6.75 5.25C7.3725 5.25 7.875 5.7525 7.875 6.375C7.875 6.9975 7.3725 7.5 6.75 7.5C6.1275 7.5 5.625 6.9975 5.625 6.375C5.625 5.7525 6.1275 5.25 6.75 5.25ZM12.03 10.3575C12.9 10.9875 13.5 11.8275 13.5 12.9375V14.25H16.5V12.9375C16.5 11.4225 13.875 10.56 12.03 10.3575ZM11.25 9C12.6975 9 13.875 7.8225 13.875 6.375C13.875 4.9275 12.6975 3.75 11.25 3.75C10.845 3.75 10.47 3.8475 10.125 4.0125C10.5975 4.68 10.875 5.4975 10.875 6.375C10.875 7.2525 10.5975 8.07 10.125 8.7375C10.47 8.9025 10.845 9 11.25 9Z" fill="#06152D"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_4276_3386">
                        <rect width="18" height="18" fill="white"/>
                        </clipPath>
                        </defs>
                        </svg>
                        <div className="text-[14px] mt-[4px] ml-[4px]">{teamList?.length}</div></div>



                        <PrimaryButton width="w-[113px]"   style={{ background: "white",color:'#2562ea',borderColor:'#2562ea' }} classNames="mr-2" text={"Invite"} margin="my-auto" OnClick={()=>{setShow(true)}} icon={
                               <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <g clip-path="url(#clip0_4276_865)">
                               <path d="M11.25 9C12.9075 9 14.25 7.6575 14.25 6C14.25 4.3425 12.9075 3 11.25 3C9.5925 3 8.25 4.3425 8.25 6C8.25 7.6575 9.5925 9 11.25 9ZM4.5 7.5V6C4.5 5.5875 4.1625 5.25 3.75 5.25C3.3375 5.25 3 5.5875 3 6V7.5H1.5C1.0875 7.5 0.75 7.8375 0.75 8.25C0.75 8.6625 1.0875 9 1.5 9H3V10.5C3 10.9125 3.3375 11.25 3.75 11.25C4.1625 11.25 4.5 10.9125 4.5 10.5V9H6C6.4125 9 6.75 8.6625 6.75 8.25C6.75 7.8375 6.4125 7.5 6 7.5H4.5ZM11.25 10.5C9.2475 10.5 5.25 11.505 5.25 13.5V14.25C5.25 14.6625 5.5875 15 6 15H16.5C16.9125 15 17.25 14.6625 17.25 14.25V13.5C17.25 11.505 13.2525 10.5 11.25 10.5Z" fill="#2563EB"/>
                               </g>
                               <defs>
                               <clipPath id="clip0_4276_865">
                               <rect width="18" height="18" fill="white"/>
                               </clipPath>
                               </defs>
                               </svg>
                               
                            } />



                        </div>
                    </div>
                    {/* <h2 className="mb-4px font-bold text-22px text-primary-bold  capitalize">Recent Activities</h2>
                    <div className="flex w-full justify-end ">
                        <SearchBar iconposition="left" border="" />
                    </div> */}                            {
                        data.length === 0 && !Loading ?
                        <div className="w-full h-full flex flex-col justify-center  space-y-4" >
                        <div className=" flex flex-col max-h-[360px] max-w-[300px] ma mx-auto" >
                            <img src="/asset/images/emptycampaign.png" alt="Empty_campaign" className="relative h-auto max-h-[360px]" />
                        </div>
                        <p className="mx-auto flex" >Create your Campaign</p>
                        <PrimaryButton text={'Create New Campaign'} width="w-fit px-2 mx-auto" OnClick={() => Navigate('/template')} />
                    </div> :
                    <div className={`relative p-4 py-6 px-6 bg-white rounded-xl shadow-bgshadow  ${data.length > 0 ? "h-[83%]" : "h-[83%]"} `} >
                        <div className="flex bg-white py-2 sticky top-0 z-50 justify-between">
                            <SearchBar iconposition="left" border="border-2" setName={setName} />
                            <FilterComponent SortByDate={SortByDate} SortByName={SortByName} SortByLive={SortByLive} SortByPaused={SortByPaused} />

                        </div>
                        <div className="relative w-full h-[85%]  ">
                            <div className="h-[85%] overflow-y-auto list-industry">

 
                            <ShowData loading={Loading} />

                            </div>
                            <hr className="mb-0 mt-3"/>
                            <div className="stat bottom-0">
                                <div className="flex justify-end space-x-3 px-4 py-4">
                                    <div className="flex space-x-2">

                                        <p className="my-auto noselect">Rows per page :</p>
                                        <DefaultDropMenu labelTextWeight="hidden" SelectIndex={true} list={dropmenuList} active={activeRowPerPage < 0 ? 0 : activeRowPerPage} mainclass="my-auto w-20" onchange={LoadMore} />
                                    </div>
                                    <div className="flex"><p className="my-auto noselect">{pageIndex[0]}-{pageIndex[1]} of {totalpage}</p></div>

                                    <div className="flex my-auto space-x-4">
                                        <span onClick={() => { prev() }} className={`p-2 px-3 rounded-md my-auto cursor-pointer ${pageIndex[0] <= 1 ? "bg-gray-100" : ""} hover:bg-gray-200`}><svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.90969 0.710469C6.51969 0.320469 5.88969 0.320469 5.49969 0.710469L0.909687 5.30047C0.519688 5.69047 0.519688 6.32047 0.909687 6.71047L5.49969 11.3005C5.88969 11.6905 6.51969 11.6905 6.90969 11.3005C7.29969 10.9105 7.29969 10.2805 6.90969 9.89047L3.02969 6.00047L6.90969 2.12047C7.28969 1.73047 7.28969 1.09047 6.90969 0.710469Z" fill="#586474" /></svg></span>
                                        <span onClick={() => { next() }} className={`p-2 my-auto px-3 rounded-md cursor-pointer ${(pageIndex[1] > (tableData?.length + 1)) ? "bg-gray-100" : ""} hover:bg-gray-200`}><svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.29055 9.88047L5.17055 6.00047L1.29055 2.12047C0.900547 1.73047 0.900547 1.10047 1.29055 0.710469C1.68055 0.320469 2.31055 0.320469 2.70055 0.710469L7.29055 5.30047C7.68055 5.69047 7.68055 6.32047 7.29055 6.71047L2.70055 11.3005C2.31055 11.6905 1.68055 11.6905 1.29055 11.3005C0.910547 10.9105 0.900547 10.2705 1.29055 9.88047Z" fill="#586474" /></svg></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
}
                </div>
                {showCpy  && <SimpleCopy setshoWCpy={setshoWCpy} copy={copy} renderEngineurl={renderEngineurl}></SimpleCopy> }
                {showDel && <SimpleDelete setshoWDel={setshoWDel} delete={deleteCamp} />}
                { showMov && <SimpleMove  onProfile={setShoeMedia} loadingLive={ShowloadingSpin} moveCmp={()=>moveCmp()} projectData={projects}  seletedProject={seletedProject}  changeDefaultVaule={setOnchangePN} setSeletedProject={setSeletedProject} setshoWMov={setshoWMov} title="select workspace to move" /> }


            </Layout>
        </>
    )
}

export default InnerProject