import React, { useEffect } from "react";
import { useState } from "react";
import {  useSelector } from "react-redux";
import { SideTapFields } from "../allFields";
import { EditerMainBlock } from "./common/editermain";
import { EditerSideTap } from "./common/editerSidetap";
import { LoadingSpinner } from "../Loading";
import { Tutorial } from "../Tutorial";
import { useSelectionAction } from "../../pages/customstate/context";

export const DesignBlock = ({ campaignId = '', fileds = [],allFileds=[],commonFileds=[],loading=false,editName=null }) => {


    const { EditorFields } = useSelector((state) => ({ EditorFields: state.EditorFields }))
    const { completedTutorial, engage_apptour  } = useSelector((state) => ({ completedTutorial: state.completedTutorial, engage_apptour : state.engage_apptour }))

    const [triggerField, setTriggerField] = useState(false);
    const [startTutorial, setStartTutorial] = useState(false)
    const {  ShowPageMainContent } = useSelectionAction();
    


    useEffect(()=>{
        setTimeout(()=>{ setStartTutorial((!completedTutorial?.campaignEdit?.overAll) && engage_apptour && ShowPageMainContent) },100)
        return(()=>{
            setStartTutorial(false)
        })
    },[engage_apptour])

    return (

        loading ? <LoadingSpinner /> :

        <section className={`flex w-full h-full`}>
            
            {startTutorial && <Tutorial  page="campaignEdit"/>}


            {triggerField && <SideTapFields fileds={allFileds} cancel={() => setTriggerField(false)} />}

            <div className="w-9/12 h-full border-r-1 overflow-y-scroll list-industry">

                <EditerMainBlock editName={editName} setTriggerField={setTriggerField} />

            </div>

            <EditerSideTap EditorFields={EditorFields} commonFileds={commonFileds} AddFieldaction={() => setTriggerField(true)} />

        </section>
    )
}