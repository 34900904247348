export var templatePreview_flow=[  

        {
            element: ".step-btn-useThisTemplate",
            title: "Use this template",
            intro: "Choosing 'Use this template' brings up a new screen where you can enter the name of the project and change the project icon",
        }, 
        {
            element: ".step-btn-changeTemplate",
            title: "Change Template",
            intro: "Selecting 'Change Template' navigates you back to the templates.",
        }, 
    ]