import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { EmailTokenVerify } from '../actions/user';
import { toast } from 'react-hot-toast';
import { LoadingSpinner } from '../components/Loading';


export const Emailverification = () => {
    const UseParam = useParams();

    const nevigator = useNavigate()

    useEffect ( () => {
        if(UseParam?.token)EmailTokenVerification(UseParam.token)
    },[UseParam])

    const EmailTokenVerification = async (token) => {
        const Resp = await  EmailTokenVerify(token)

        if(Resp.status) {toast.success(Resp.data);nevigator('/login')}
        else toast.error(Resp.data)
    }
    return (
        <section className="flex flex-col w-screen h-screen bg-primary-lightblue">
            <LoadingSpinner/>
        </section>

    )
}