import React from 'react'
import { BillingLayout } from './BillingLayout'


export const CardInformation = ({ CardInfo }) => {

  return (
    <BillingLayout className="px-6 py-5 w-1/2 h-fit relative space-y-3 flex flex-col border-1  ">

      <p className="text-sm font-medium mt-3"> Card Information </p>

      <div className="absolute top-10 right-10">



      </div>

      <div className="h-fit flex flex-col justify-between space-y-8">

        <div className="text-xl mt-10 flex">
          ****  ****  ****  {CardInfo?.last4}
        </div>


        <div className="flex items-center justify-between mb-2     ">

          <div className="text-sm font-normal capitalize h-full ">
            {CardInfo?.brand}
          </div>

          <div className="flex items-center justify-center space-x-2">

            <p className="text-xs"> VALID UPTO </p>

            <p className="text-sm">
              {/* {`${card.expMonth} / ${card.expYear}`}  */}
              {CardInfo?.exp_month}/ {CardInfo?.exp_year}
            </p>

          </div>

        </div>

      </div>

    </BillingLayout>
  )
}
