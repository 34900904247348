import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditorTooltip } from "../Editor/common/editortooltip";   
import { StringToHtml } from "../StringToHtml";
import { Actions } from "../../store";

export const EmbedBlock = ({ fieldID = '', position ,priview=false }) => {

    const { Fields } = useSelector((state)=>({Fields:state.fields}));
    const DisPatch = useDispatch()

    const titleContent = Fields[fieldID];   

    return (
        <div id={"gameFrameBorder"+fieldID}  className={`relative flex flex-col py-4 group `} onClick={()=>{DisPatch(Actions.setActiveFieldside({filedId:fieldID,index:position,}))}}>
            {!priview &&<EditorTooltip position={position}/>}
           
            <StringToHtml str={titleContent?.filedContent?.EmbedCode}> </StringToHtml>      
           
        </div>

    )
}