import toast from 'react-hot-toast';
import axios from '../axios';
import { get, isObject } from 'lodash';

const setheader = () => {
    return {
        headers:{
            "x-shared-project": true
        }
    }
}

export const templateList = (industry,category,lastTemp) => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.post(`/list_category_industry`,{industry:industry,category:category,lastTemp});
            
            if(res.status == 200) resolve(res.data);
            
            else {

                reject(res?.data?.message ?? ( res?.message || "Something  when to wrong, please Contact Support"));
            }

        }catch(err){
            toast.error(err.response?.data?.message ?? "Something  when to wrong, please Contact Support")

            reject(err);
        }
    })
}

export const createcCampaign = (payload,SharedWS = false) => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = !SharedWS ? await axios.post('/campaign/create',payload) : await axios.post('/campaign/create',payload,setheader()) ;
            

            if(res.status == 200)  resolve(res.data)

            else {

                reject(res?.data?.message ?? ( res?.message || "Something  when to wrong, please Contact Support"));
            }
            
    
        }catch(err){
            toast.error(err.response?.data?.message ?? "Something  when to wrong, please Contact Support")
            reject(err);
        }
    })
}

export const  updateCampaign = (payload,campaign_id,SharedWS = false) => {
 
    return new Promise (async (resolve,reject)=>{
        try{

            const res = !SharedWS ? await axios.post(`/campaign/update?campaign_id=${campaign_id}`,payload) : await axios.post(`/campaign/update?campaign_id=${campaign_id}`,payload,setheader()) ;
            

            if(res.status === 200) resolve(res.data);

            else {

                reject(res?.data?.message ?? ( res?.message || "Something  when to wrong, please Contact Support"));
            }
             
            
        }catch(err){

            toast.error(err.response?.data?.message ?? ( err?.response?.message || "Something  when to wrong, please Contact Support"))

            reject(err);
        }
    })
}

export const createTemplate = (payload) => {
 
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.post(`/development/engage/template`,payload);
            

            if(res.status === 200) {resolve(res.data) ;toast.success("Template Created.")}

            else {

                reject(res?.data?.message ?? ( res?.message || "Something  when to wrong, please Contact Support"));
            }
            
    
        }catch(err){
            toast.error(err.response?.data?.message ?? "Something  when to wrong, please Contact Support")

            reject(err);
        }
    })
}

export const  validateproduct = (payload) => {
 
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.post(`/editor/ecommmerce/product`,payload);
            

            if(res.status === 200) resolve(res.data)

            else {

                reject(res?.data?.message ?? ( res?.message || "Something  when to wrong, please Contact Support"));
            }
            
    
        }catch(err){
            toast.error(err.response?.data?.message ?? "Something  when to wrong, please Contact Support")

            reject(err);
        }
    })
}

export const getproduct = (campaign_id,fieldId,type) => {
 
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.get(`/editor/ecommmerce/product?campaign_id=${campaign_id}&type=${type}&fieldId=${fieldId}`);
            

            if(res.status === 200) resolve(res.data)

            else {

                reject(res?.data?.message ?? ( res?.message || "Something  when to wrong, please Contact Support"));
            }
            
    
        }catch(err){
            toast.error(err.response?.data?.message ?? "Something  when to wrong, please Contact Support")

            reject(err);
        }
    })
}

export const liveOrPauseCampaign = (payload,campaign_id,state,SharedWS = false) => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = !SharedWS ? await axios.put(`/campaign/${state}?campaign_id=${campaign_id}`,payload) : await axios.put(`/campaign/${state}?campaign_id=${campaign_id}`,payload,setheader());
            

            if(res.status == 200) resolve(res.data)

            else {

                reject(res?.data?.message ?? ( res?.message || "Something  when to wrong, please Contact Support"));
            }
            
    
        }catch(err){
            toast.error(err.response?.data?.message ?? "Something  when to wrong, please Contact Support")
            
            reject(err);
        }
    })
}

export const getCampaign = (campaign_id,project_id,SharedWS = false) => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = !SharedWS ? await axios.get(`/campaign?campaign_id=${campaign_id}`) : await axios.get(`/campaign?campaign_id=${campaign_id}&project_id=${project_id}`,setheader()) ;
            if(res.status == 200) resolve(res.data)
            else {

                reject(res?.data?.message ?? ( res?.message || "Something  when to wrong, please Contact Support"));
            }
            
    
        }catch(err){
            toast.error(err.response?.data?.message ?? "Something  when to wrong, please Contact Support")
            reject(err);
        }
    })
}

export const getAllCampaign = () => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.get(`/campaigns`);
            if(res.status === 200) resolve(res.data)
            else {

                reject(res?.data?.message ?? ( res?.message || "Something  when to wrong, please Contact Support"));
            }
            
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err);
        }
    })
}


export const getCreateTemp = (template_id) => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.get(`/development/engage/template?template_id=${template_id}`);
            if(res.status == 200) resolve(res.data)
            else {

                reject(res?.data?.message ?? ( res?.message || "Something  when to wrong, please Contact Support"));
            }
            
    
        }catch(err){
            toast.error(err.response?.data?.message ?? "Something  when to wrong, please Contact Support")

            reject(err);
        }
    })
}

export const getTemplate = (template_id) => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.get(`/template/${template_id}`);
            
            if(res.status == 200) resolve(res.data)
            else {

                reject(res?.data?.message ?? ( res?.message || "Something  when to wrong, please Contact Support"));
            }
            
    
        }catch(err){
            toast.error(err.response?.data?.message ?? "Something  when to wrong, please Contact Support")

           
            reject(err);
        }
    })
}

export const campaignAnalytics = (campaign_id,month,year,type,SharedWS = false) =>{

    return new Promise (async (resolve,reject)=>{
        try{

            const res = !SharedWS ? await axios.get(`/campaignanalyticslist?campaign_id=${campaign_id}&month=${month}&year=${year}&type=${type}`) : await axios.get(`/campaignanalyticslist?campaign_id=${campaign_id}&month=${month}&year=${year}&type=${type}`,setheader());
            
            if(res.status == 200) resolve(res.data)
            else {

                reject(res?.data?.message ?? ( res?.message || "Something  when to wrong, please Contact Support"));
            }
            
    
        }catch(err){
           
            reject(err);
        }
    })
}

export const campaignResponse = (campaign_id,month,year,type,SharedWS = false) =>{

    return new Promise (async (resolve,reject)=>{
        try{

            const res = !SharedWS ? await axios.get(`/campaignresponse?campaign_id=${campaign_id}`) : await axios.get(`/campaignresponse?campaign_id=${campaign_id}`,setheader()) ;
            
            if(res.status == 200) resolve(res.data)
            else {

                reject(res?.data?.message ?? ( res?.message || "Something  when to wrong, please Contact Support"));
            }
            
    
        }catch(err){
           
            reject(err);
        }
    })
}

export const getFields = (campaign_id) => {
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.post(`/campaign/editor/fields`);
            
            if(res.status == 200) resolve(res.data)

            else {

                reject(res?.data?.message ?? ( res?.message || "Something  when to wrong, please Contact Support"));
            }
            
    
        }catch(err){
            toast.error(err.response?.data?.message ?? "Something  when to wrong, please Contact Support")
            reject(err);
        }
    })
}