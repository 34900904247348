export var generateAiBtn_flow=[
  {
    element: ".step-tempGal-generateAiBtn",
    title: "AI Generator",
    intro: "Generate using AI involves leveraging artificial intelligence to create content or solutions, automating the creative process for efficiency and innovation.",
    scrollToElement:false,
    options:{
      scrollToElement:false
    }
} 
]

export var templateGalary_flow=[    
    {
        element: ".step-chooseIndustry",
        title: "Industry Identifier",
        intro: "Pick your field from the options provided. Food, ecommerce, software, construction, beauty and cosmetics, photography, logistics and transportation, medical, human resource, banking and finance, gaming and E-Learning.",
    },   {
        element: ".step-icons-games",
        title: "A choose of Games",
        intro: " Find path, find word, Q/A, rearrange, find odd one, word puzzle, find pair,shuffle image, find flow, connection.",
    }, 
      {
        element: ".step-icons-quizzes",
        title: "Quizzes",
        intro: "Explore the quiz features, set questions, customise results, and define scoring rules to create an engaging and interactive experience.",
    }, 
      {
        element: ".step-icons-spinwheel",
        title: "Spinwheel",
        intro: "A gamified feature for interactive experiences, allowing users to spin a wheel for random outcomes, adding an element of fun and surprise to engagements.",
    }, 
      {
        element: ".step-icons-ecommerce",
        title: "Ecommerce",
        intro: "E-commerce product recommendation suggests personalized items to users, enhancing their shopping experience and increasing sales.",
    }, 
      {
        element: ".step-icons-poll",
        title: "Poll",
        intro: "Poll feature allows users to quickly gather opinions by creating and sharing custom surveys.",
    }, 
    {
      element: ".step-icons-calculator",
      title: "Calculator",
      intro: "Enables numerical computations and problem-solving through a user-friendly interface.",
  }, 
  {
    element: ".step-icons-survays",
    title: "Surveys",
    intro: "The survey feature enables users to gather valuable feedback through customizable questionnaires.",
}, 
 
{
    element: ".step-template-first",
    title: "Template Overview",
    intro: "To get a sneak peek of the template, click on '<b>View Template</b>'. If you find it suitable, just opt for '<b>Use this template</b>' when you're ready to proceed.",
}, 

]