import React from "react";
import LogHeader from "../components/header/logheader";
import HeroImage from "../components/register/leftcontainer";
import { SendEmailVerificationToken } from "../components/register/resetEmail";

const ResetEmailPage = () => {
    
    return (
        <>
            <section className="flex flex-col w-screen h-screen bg-primary-lightblue">
                <LogHeader text={'Login'} width={"w-28"}
                
                />
                <div className="flex w-full h-full">
                    <HeroImage />
                    <SendEmailVerificationToken />
                </div>
            </section>
        </>
    )
}

export default ResetEmailPage;