import React from 'react'
import PropTypes from "prop-types";


const BorderedButton = function ({ id,
    text,
    classNames,
    onClick = null,
    height = "40px",
    margin = "",
    disabled,
    position = "",
    style = {},
    type = "button",
    icon = null,
    width = "",
    borderWidth = 1,
    fontWeight = "font-normal",
    theme = "primary",
    textSize = "text-14px ",
    ...props
}) {

    const isPrimary = theme === 'primary'
    const isPrimaryBordered = theme === 'primary-bordered'
    const isSecondary = theme === 'secondary'

    return (
        <button
            title={text}
            type={type}
            id={id}
            disabled={disabled}
            style={{ ...style, }}
            onClick={(e) => onClick && onClick(e)}
            className={`${icon ? "flex flex-row items-center justify-center" : ""} 
            ${isPrimary ? "text-primary bg-primary bg-opacity-10 hover:bg-opacity-20 " :
                    isPrimaryBordered && "text-primary bg-white border-primary"} 
            ${isSecondary && "text-gray-600 border-border-light bg-opacity-10 " +
                `${disabled ? " " : " hover:opacity-90 hover:border-gray-400  "}`
                } rounded-8px border-${borderWidth}  ${fontWeight} ${textSize}
            h-${height}  ${width ? width + " " : " px-32px "} ${margin} overflow-hidden truncate`}
        >
            {icon && icon}
            {text}
        </button>
    )
}


export default BorderedButton ;
