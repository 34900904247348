import React from "react";


export const WorkSpaceActiveUser = ({accessible_users=[]}) => {

    let usercount = accessible_users?.length

    return (
        // <div className={`relative w-fit h-[50px] flex my-auto }`}>
            
        <div className={`relative w-auto   }`}>
        <div className="relative w-fit h-[50px] min-w-[50px]">

            {accessible_users?.map((list, index) => {
                if (index < 4) {
                    return  (<img src={list?.engage_user_profile ?? '/asset/images/Default-User.png'} className={`absolute w-[40px] h-[40px] object-cover ${(index == 3) ? "left-[60px] xlh:left-[75px]" : (index == 2) ? "left-[40px] xlh:left-[50px]" : (index == 1) ? "left-[20px] xlh:left-[25px]" : ""} border-4 rounded-full border-white top-2`} style={{ zIndex: `${(index == 3) ? "20" : (index == 2) ? "30" : (index == 1) ? "40" : "50"}` }} alt='' />)
                }
            })}
        </div>

        {/* temb member List */}
        {(accessible_users?.length > 4) &&  <p className="absolute left-[120px] xlh:left-[140px] top-[14px] xlh:top-[17px] text-sm xlh:text-base">
            +{usercount-3} more
        </p> }
    </div>
    // </div>
    )
}