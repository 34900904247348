import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditorTooltip } from "../Editor/common/editortooltip";
import { isEmpty } from "../../utils/helpers";
import { Actions } from "../../store";

export const ImagerenderBlock = ({ fieldID = '', position ,priview=false }) => {

    const { Fields } = useSelector((state)=>({Fields:state.fields}));
    const DisPatch = useDispatch()

    const titleContent = Fields[fieldID]; 

    return (
        <div id={"gameFrameBorder"+fieldID}  className={`relative  flex flex-col py-4 group `} onClick={()=>{DisPatch(Actions.setActiveFieldside({filedId:fieldID,index:position,}))}}>
            {!priview &&<EditorTooltip position={position}/>}

            <div className="flex w-fit h-auto max-w-full mx-auto py-4">
                <img src={isEmpty(titleContent?.filedContent?.imageSrc) ? "/asset/images/templateCollect/Match_the_Animal_Names.png" : titleContent?.filedContent?.imageSrc} className={'w-auto h-auto object-cover'} />
            </div>
        </div>

    )
}