export const element = (name) => { return document.getElementById(name); }
export const getClip = (image, x, y, width, height) => {
    let canvas = document.createElement('canvas'),
        temp_ctx = canvas.getContext('2d');
    canvas.width = width; canvas.height = height;
    temp_ctx.drawImage(image, x, y, width, height, 0, 0, width, height);
    return canvas.toDataURL('image/png');
}

export const getMatrixClip = async (url, rows, cols, DELTA_WIDTH, DELTA_HEIGHT) => {
    return new Promise(function (resolve, reject) {
        var image = new Image(), base64 = [], posX = 0, posY = 0;
        image.onload = (async () => {
            for (let i = 0; i < (rows); i++) {
                posX = 0;
                for (let j = 0; j < (cols); j++) {
                    base64.push(getClip(image, posX, posY, DELTA_WIDTH, DELTA_HEIGHT))
                    posX += DELTA_WIDTH;
                } posY += DELTA_HEIGHT;
            }
            resolve(base64);
        }); image.src = url
    })
}

export const getClientWidth = (elm) => { return element(elm).clientWidth; }

export const getClientHeight = (elm) => { return element(elm).clientHeight; }

export const getOffset = (el) => { // its working for the div
    const rect = el.getBoundingClientRect();
    return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
    };
}

// export const getOffset2 = ( el ) =>{ // its working for entire body
//     var _x = 0;
//     var _y = 0;
//     while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
//         _x += el.offsetLeft - el.scrollLeft;
//         _y += el.offsetTop - el.scrollTop;
//         el = el.offsetParent;
//     }
//     return { top: _y, left: _x };
// }

export const getSquareXY = (width, height, DELTA_WIDTH, DELTA_HEIGHT, x, y) => {
    let col = 0, row = 0, num = 0, maxRow = Math.round(height / DELTA_HEIGHT), maxCol = Math.round(width / DELTA_WIDTH), k = false;
    for (let i = 0; i < width; i += DELTA_WIDTH) {
        if (i > x && x < (i + DELTA_WIDTH)) break;
        else col += 1;
    }
    for (let i = 0; i < height; i += DELTA_HEIGHT) {
        if (i > y && y < (i + DELTA_HEIGHT)) break;
        else row += 1;
    }
    row -= 1; col -= 1; if (row < 0) row = 0; if (col < 0) col = 0
    //console.log(maxRow, maxCol)
    for (let i = 0; i < maxRow; i++) {
        for (let j = 0; j < maxCol; j++) {
            if (i === row && j === col) { k = true; break; } else {
                num += 1;
            }
        }
        if (k) break;
    }
    return {
        row, col, num,
        startPosX: col * DELTA_WIDTH,
        startPosY: row * DELTA_HEIGHT
    }
}

export const clearSvg = (id) => {
    let children = element(id)?.children;
    if (children === null || children === "" || children === undefined) return;
    for (let i = 0; i < children.length;) {
        let el = children[i];
        if (el.tagName !== 'defs') {
            el.remove();
        } else (i++);
    }
}

