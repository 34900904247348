 import React from "react";

const SearchBar= ({

    width = "w-60",
    height = "h-40px",
    borderRadius = "rounded-xl", 
    text = "",
    Placeholder = "Search",
    fontsize = "text-sm",
    inputwidth = "w-4/5",
    margin = '',
    backgroundcolor ="",
    border = '',
    iconposition = 'left',
    setName

}) => {

    const icon = () => {
        if(iconposition === 'left'){
            return ( 
                <>
                    <div className="my-auto mx-auto ">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.8067 12.86L11.54 10.6C12.2713 9.6683 12.6681 8.51777 12.6667 7.33333C12.6667 6.2785 12.3539 5.24736 11.7678 4.37029C11.1818 3.49323 10.3489 2.80965 9.37431 2.40598C8.39977 2.00231 7.32742 1.89669 6.29285 2.10248C5.25829 2.30827 4.30798 2.81622 3.5621 3.5621C2.81622 4.30798 2.30827 5.25829 2.10248 6.29285C1.89669 7.32742 2.00231 8.39977 2.40598 9.37431C2.80965 10.3489 3.49323 11.1818 4.37029 11.7678C5.24736 12.3539 6.2785 12.6667 7.33333 12.6667C8.51777 12.6681 9.6683 12.2713 10.6 11.54L12.86 13.8067C12.922 13.8692 12.9957 13.9187 13.077 13.9526C13.1582 13.9864 13.2453 14.0039 13.3333 14.0039C13.4213 14.0039 13.5085 13.9864 13.5897 13.9526C13.671 13.9187 13.7447 13.8692 13.8067 13.8067C13.8692 13.7447 13.9187 13.671 13.9526 13.5897C13.9864 13.5085 14.0039 13.4213 14.0039 13.3333C14.0039 13.2453 13.9864 13.1582 13.9526 13.077C13.9187 12.9957 13.8692 12.922 13.8067 12.86ZM3.33334 7.33333C3.33334 6.54221 3.56793 5.76885 4.00746 5.11105C4.44698 4.45326 5.0717 3.94057 5.8026 3.63782C6.53351 3.33507 7.33777 3.25585 8.1137 3.41019C8.88962 3.56454 9.60235 3.9455 10.1618 4.50491C10.7212 5.06432 11.1021 5.77705 11.2565 6.55297C11.4108 7.3289 11.3316 8.13316 11.0289 8.86407C10.7261 9.59497 10.2134 10.2197 9.55562 10.6592C8.89782 11.0987 8.12446 11.3333 7.33333 11.3333C6.27247 11.3333 5.25505 10.9119 4.50491 10.1618C3.75476 9.41162 3.33334 8.3942 3.33334 7.33333Z" fill="#586474"/>
                        </svg>
                    </div>
                    <input className={`${fontsize}  font-normal outline-none ${inputwidth} mx-2 ${backgroundcolor} `} placeholder={`${Placeholder}`} required={false} onChange={(e) => (setName(e.target.value))} />
                </>
             )
        }
        else{
            return ( 
                <>

                    <input className={`${fontsize}  outline-none  font-normal ${inputwidth} mx-2 ${backgroundcolor} `} placeholder={`${Placeholder}`} />
                    <div className="my-auto mx-auto  ">
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.71 16.29L14.31 12.9C15.407 11.5025 16.0022 9.77666 16 8C16 6.41775 15.5308 4.87103 14.6518 3.55544C13.7727 2.23985 12.5233 1.21447 11.0615 0.608967C9.59966 0.00346625 7.99113 -0.15496 6.43928 0.153721C4.88743 0.462403 3.46197 1.22433 2.34315 2.34315C1.22433 3.46197 0.462403 4.88743 0.153721 6.43928C-0.15496 7.99113 0.00346625 9.59966 0.608967 11.0615C1.21447 12.5233 2.23985 13.7727 3.55544 14.6518C4.87103 15.5308 6.41775 16 8 16C9.77666 16.0022 11.5025 15.407 12.9 14.31L16.29 17.71C16.383 17.8037 16.4936 17.8781 16.6154 17.9289C16.7373 17.9797 16.868 18.0058 17 18.0058C17.132 18.0058 17.2627 17.9797 17.3846 17.9289C17.5064 17.8781 17.617 17.8037 17.71 17.71C17.8037 17.617 17.8781 17.5064 17.9289 17.3846C17.9797 17.2627 18.0058 17.132 18.0058 17C18.0058 16.868 17.9797 16.7373 17.9289 16.6154C17.8781 16.4936 17.8037 16.383 17.71 16.29V16.29ZM2 8C2 6.81331 2.3519 5.65328 3.01119 4.66658C3.67047 3.67989 4.60755 2.91085 5.7039 2.45673C6.80026 2.0026 8.00666 1.88378 9.17054 2.11529C10.3344 2.3468 11.4035 2.91825 12.2426 3.75736C13.0818 4.59648 13.6532 5.66557 13.8847 6.82946C14.1162 7.99335 13.9974 9.19975 13.5433 10.2961C13.0892 11.3925 12.3201 12.3295 11.3334 12.9888C10.3467 13.6481 9.18669 14 8 14C6.4087 14 4.88258 13.3679 3.75736 12.2426C2.63214 11.1174 2 9.5913 2 8Z" fill="#586474"/>
                    </svg>

                    </div>
                </>
             )
        }

    }

    return (
        <>
        
        <div className={`${width} ${height} ${margin} ${backgroundcolor} ${borderRadius} px-2 `}>
            <div className={`flex h-full ${border} ${borderRadius} `}>

            

            {icon()}
            </div>
        </div>

        </>
    )


} 

export default SearchBar;