import React, { useEffect, useRef } from "react";
import PrimaryButton from "../Button1/primarybutton";
import Input from "../input/input";
import { Clickouter } from "../../utils/clickouter";
import { CgSpinner } from "react-icons/cg";


export const SCDPopupCreate = ({
    title = '',
    reftype=null,
    defaultVaule = '',
    type='',
    Placeholder='Enter',
    loadingLive=false,
    changeDefaultVaule = null,
    forwardAction = null,
    backwardAction = null,
    position='absolute',
    forwardActiontext = 'Done',
    className="", 
}) => {

    const menuREf = useRef(null);
    
    useEffect(()=>{
        let handleAction = (event) => {

            if (menuREf.current && !menuREf.current?.contains(event.target)) {
                backwardAction(false);
            }
        };
    
        document.addEventListener("mousedown", handleAction);
    },[])

    return (
        <div className={`${position} top-0 left-0 flex w-full h-full bg-backgroundShadow`}  style={{ zIndex: '55' }}>
            <div className={`p-4 w-[450px] rounded-lg bg-white m-auto space-y-4 ${className} `} ref={menuREf} >

                <p className="capitalize text-lg font-medium">{title}</p>
                <div className="flex" >
                <Input textvalue={defaultVaule} ClassName="border-r-0 rounded-r-none"  onchange={(e)=>changeDefaultVaule(e,type)} Placeholder={Placeholder} />
                <div className="flex w-fit min-w-[150px] min px-2 py-1.5 h-fit bg-gray-200 border-2 font-medium rounded-md rounded-l-none" >.engage-gozen.io</div>
                </div>

                <div className="flex justify-end space-x-2">
                    <PrimaryButton buttontype={'secondarybutton'} disabled={loadingLive} width={'w-24'} text={'cancel'} OnClick={()=>backwardAction(false)} />
                    <PrimaryButton icon={
                        loadingLive ? <CgSpinner className="w-5 h-5 animate-spin text-white"  /> : null
                    } text={forwardActiontext} disabled={loadingLive} width={'w-24'} OnClick={forwardAction} />
                </div>

            </div>
        </div>
    )
}