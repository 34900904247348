import toast from 'react-hot-toast';
import axios from '../axios';
import { get, isObject } from 'lodash';

const setheader = (val) => {
    return {
        headers:{
            "x-shared-project": val
        }
    }
}

export const getProjects = ( SharedWS = false ) =>  {

    return new Promise (async (resolve,reject)=>{

        axios.get(`/project/all` ).then (resdata => {
            resolve(resdata.data);
        }).catch(err => {
            toast.error(err?.response?.data?.message?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })
         
    })

}

export const getProject = ( project_id, SharedWS = false ) =>  {

    return new Promise (async (resolve,reject)=>{

        axios.get(`/campaigns/${project_id}`,setheader(SharedWS) ).then (resdata => {
            resolve(resdata.data);
        }).catch(err => {
            toast.error(err?.response?.data?.message ?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })
         
    })

}

export const createProject = (project_name,project_img_src) =>  {

    return new Promise (async (resolve,reject)=>{

        axios.post(`/project/create`, { project_name, project_img_src } ).then (resdata => {
            resolve(resdata.data);
        }).catch(err => {
            toast.error(err?.response?.data?.message?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })
         
    })

}

export const updateProject = (payload) =>  {

    return new Promise (async (resolve,reject)=>{

        axios.post(`/project/update`, payload ).then (resdata => {
            resolve(resdata.data);
        }).catch(err => {
            toast.error(err?.response?.data?.message?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })
         
    })

}

export const deleteProject = (campaign_id) =>  {

    return new Promise (async (resolve,reject)=>{

        axios.delete(`/project/${campaign_id}` ).then (resdata => {
            resolve(resdata.data);
        }).catch(err => {
            toast.error(err?.response?.data?.message?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })
         
    })

}

export const inviteMemberToProject = (payload) =>  {

    return new Promise (async (resolve,reject)=>{

        axios.post(`/project/member/invite`, payload ).then (resdata => {
            resolve(resdata.data);
        }).catch(err => {
            toast.error(err?.response?.data?.message?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })
         
    })

}


export const updateMemberToProject = (payload) =>  {

    return new Promise (async (resolve,reject)=>{

        axios.put(`/project/member/update`, payload ).then (resdata => {
            resolve(resdata.data);
        }).catch(err => {
            toast.error(err?.response?.data?.message?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })
         
    })

}


export const deleteMemberToProject = (payload) =>  {

    return new Promise (async (resolve,reject)=>{

        axios.post(`/project/member/delete`, payload ).then (resdata => {
            resolve(resdata.data);
        }).catch(err => {
            toast.error(err?.response?.data?.message?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })
         
    })

}
