import PrimaryButton from "../Button1/primarybutton";

export function SimpleDelete(props) {
  return (
    <div
      className="fixed top-0 left-0 flex w-full h-full bg-backgroundShadow"
      style={{
        zIndex: "55",
      }}
    >
      <div className={`p-4 w-[350px] rounded-lg bg-white m-auto space-y-4  `}>
        <p className="capitalize text-lg font-medium mb-1">Alert</p>
        Do you want to delete the campaign?

        <div className="flex justify-end space-x-2">
          
                            <PrimaryButton buttontype="secondarybutton" width="w-24" textcolor="text-black" border="border-2" text={'Cancel'} OnClick={()=>{props.setshoWDel(false);}} />
                  <PrimaryButton text={'Delete'}  width={"w-24"} style={{ background: "white",color:'red',borderColor:'red' }} OnClick={props.delete} />

            </div>
      </div>
    </div>
  );
}
