import React, { useEffect, useState } from "react";
import { Imageinput } from "../../../input/imageinput";
import InputWithLabel from "../../../input/inputWithLabel";
import { PairCardBlock } from "../../../paircard";
import DropMenu from "../../../input/dropmenu";
import { useDispatch } from "react-redux";
import { Actions } from "../../../../store";
import { MediaPopup } from "../../../popup/Mediapopup";
import { CardBlock } from "../../../cardblock";
import { ColorPixerInput } from "../../../input/colorpixerInput";
import DefaultDropMenu from "../../../input/droplist";


export const RearrageSettings = ({ field, fieldId }) => {

    const Dispatch = useDispatch();

    const [pairValue, setpairValue] = useState('');
    const [selectcard, setselectcard] = useState(0);
    const [triggerBut, settriggerBut] = useState('');
    const [Fieldname, setFieldname] = useState('filpCardBgImage');
    const [btnTextColor,setBtnTextColor] = useState(field?.filpCardBgColor);




    let listOfObj = {};

    let keyArray = field?.pairItems?.map((list) => {

        if (!listOfObj[list.pairValue]) listOfObj[list.pairValue] = [list];

        else listOfObj[list.pairValue] = [...listOfObj[list.pairValue], list]

    })

    const dropmenuList = ['2x2','3x3', '4x3', '4x4', '5x2', '5x4', '6x5']
    const findIndex = dropmenuList.findIndex(list => list == field?.layputRange)


    const ContentChange = (e, type, Checkstate = false) => {
        let stateCheck = false;

        const IsCheckBox = e.target.type == 'checkbox' ? true : false;

        if (IsCheckBox) stateCheck = !Checkstate ? true : false

        const SetValue = IsCheckBox ? stateCheck : e.target.value

        const setContent = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_mainpage_json',
            child: type,
            value: SetValue
        }

        Dispatch(Actions.setFieldTemplateContentChange(setContent))

    }

    const TriggerMedia = (pairval,index,value,type) => {
        if (type) {
            setShowMidia(true)
            setpairValue(pairval)
            setselectcard(index)
            setFieldname('image')
        }
    }

    const setValuecontent = (value='',type = 'image',indexcard=selectcard,pair=pairValue) => {
        
        const setContent = {
            isCommonSetting:false,
            key:fieldId,
            node:'game_mainpage_json',
            child:'pairItems', 
            type:type,
            pairIndex:indexcard,
            pairvalue:pair,
            value:value
        } 

        Dispatch(Actions.setRearragepairContentChange(setContent))
        setpairValue('')
        setselectcard(0)
    }

    const DirectContentChange = (value, type) => {


        const setContent = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_mainpage_json',
            child: type,
            value: value
        }
        Dispatch(Actions.setFieldTemplateContentChange(setContent))
        Dispatch(Actions.setFieldTemplatePairChange(setContent))
        if (ShowMedia) setShowMidia(false)
        if(type==="filpCardBgColor") setBtnTextColor(value)

    }

    const [ShowMedia, setShowMidia] = useState(false);



    return (
        <div className="w-full space-y-4 ">

            {(ShowMedia && Fieldname !=='image') && <MediaPopup cancel={setShowMidia} fieldType={Fieldname} OnSelect={DirectContentChange} />}
            {(ShowMedia && Fieldname ==='image') && <MediaPopup cancel={setShowMidia} fieldType={Fieldname} OnSelect={setValuecontent} />}

            <DefaultDropMenu label={'choose layout :'} list={dropmenuList} active={findIndex < 0 ? 0 : findIndex} fieldType={'layputRange'} onchange={DirectContentChange} />

            <Imageinput label="card Background" src={field?.filpCardBgImage} chanaeAction={()=>setShowMidia(true)} />
            
            <ColorPixerInput labelTextsize="font-medium"  label='Card Color'  mainclass="w-full" fieldType={"filpCardBgColor"} colorHex={btnTextColor}  Onchange={DirectContentChange}/> 


            <div className="w-full space-y-4" >

                <div  className={`w-full relative flex  flex-col space-y-2 border-2  h-fit delay-400 duration-700 top-0 p-4 rounded-xl hover:shadow-md hover:-top-0 hover:border-primary`}  >

                    <p className="text-base font-medium" >Pair</p>

                    <div className="grid grid-cols-2 gap-3">

                        {
                            field?.pairItems?.map((card, cardindex) => {

                                return (

                                    <CardBlock pairval={`${card?.pairValue}`} obj={card} inDex={cardindex} IsRearrage={true} toggletype = {setValuecontent} setvalue={settriggerBut} OnClick={TriggerMedia} />

                                )
                            })
                        }

                    </div>

                </div>

            </div>

        </div>
    )
}