import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../Button1/primarybutton";
import { MainPageSettings } from "./mainpageSetting/findpair";
import { ResultScreen } from "./thankyoupage";
import { StartScreen } from "./welcomepage";
import { MainSettingSlipter } from "./mainpageSetting";
import { Actions } from "../../../store";
import { FormulaData } from "./formula";
import { ResultInnerBlock } from "./resultBlock";
import { toast } from "react-hot-toast";
import { SpinResultScreen } from "./thankyoupageSpinwheel";
import { Tutorial } from "../../Tutorial";
import { useSelectionAction } from "../../../pages/customstate/context";
import { listOfIcon } from "../../../content/iconList";

export const EditTemplateSidetap = ({ cancel = null, templateFiledId = '', EditTemplateFieldScr = 0 }) => {

    const { filedList } = useSelector((state) => ({ filedList: state.fields[templateFiledId] }));
    const { completedTutorial, engage_apptour } = useSelector((state) => ({ completedTutorial: state.completedTutorial, engage_apptour: state.engage_apptour }));


    var tapMenu = ((filedList?.type === "spinWheel") ? ['Wheel Design', 'Result Settings'] : ['Start Screen', 'Questions', 'Result']);
    var tapMenuTutorialClass = ((filedList?.type === "spinWheel") ? ['step-edit-questionTab', 'step-edit-wheelSeg'] : ['step-edit-startTab', 'step-edit-questionTab', 'step-edit-thankTab']);


    const [activeMenu, setActiveMenu] = useState(EditTemplateFieldScr)
    const [activeSubMenu, setActiveSubMenu] = useState(false)
    const [activeSubMenuStage, setActiveSubMenuStage] = useState(0)
    const [startTutorial, setStartTutorial] = useState(false)
    const [tutorialPage, setTutorialPage] = useState("threeScreenEditTab")

    const dispatch = useDispatch()

    useEffect(() => { setActiveMenu(EditTemplateFieldScr) }, [EditTemplateFieldScr])



    const TempType = filedList?.type
    const Settings = filedList?.filedContent
    const startScreenContent = Settings?.game_startscreen_json;
    const mainPageContent = Settings?.game_mainpage_json;
    const resultScreenContent = Settings?.game_resultcreen_json;

    const [stageResultpage, setstageResultpage] = useState(0)
    const [stageResult, setstageResult] = useState(0)
    const { ShowPageMainContent } = useSelectionAction();


    // Check the Intro Js data
    useEffect(() => {
        let threeScreenEditTab = (!completedTutorial?.campaignEdit?.design?.threeScreenEditTab)
        let twoScreenEditTab = (!completedTutorial?.campaignEdit?.design?.twoScreenEditTab)
        let toShow = (TempType === "spinWheel") ? twoScreenEditTab : threeScreenEditTab
        let pageType = (TempType === "spinWheel") ? "twoScreenEditTab" : "threeScreenEditTab"

        setTimeout(() => {
            setStartTutorial((!activeSubMenu) && toShow && engage_apptour && ShowPageMainContent)
            setTutorialPage(pageType)

        }, 100)

        return (() => {
            setStartTutorial(false)
        })
    }, [engage_apptour])



    const SaveClick = () => {
        dispatch(Actions.setUpdateCampaign())
        toast.success('Campaign Saved')
    }


    const formulaData = resultScreenContent?.formuls ?? [""]
    const [IsVaild, setIsvaild] = useState(true)
    const [message, setMessage] = useState("")
    const [formuladata, setFormulaData] = useState(formulaData[stageResult])
    const ResultBlockClick = (rerultIndex, SelectedIndex) => {

        setActiveSubMenu(true)
        setActiveSubMenuStage(0)
        setstageResultpage(rerultIndex)
        setstageResult(SelectedIndex)
        setFormulaData(formulaData[SelectedIndex])
    }
    const addFormula = () => {
        let add = false
        if (resultScreenContent?.formuls.length < stageResult) add = true
        const setContent = {

            isCalAdd: add,
            key: templateFiledId,
            node: 'game_resultcreen_json',
            child: "formuls",
            resultIndex: stageResult,
            value: formuladata

        }
        if (IsVaild) { dispatch(Actions.setFormulaDetail(setContent)); toast.success('Formula Updated') }
        else toast.error(message)

    }

    const setactiveScreen = (index) => {
        let setContent = {
            screen: index == 2 ? "result" : index == 1 ? "main" : "start",
            index: 0
        }
        setActiveMenu(index)
        dispatch(Actions.setActiveQuestions(setContent))
    }

    const closeIcon = listOfIcon('close',26,26,'#586474');

    return (

        <div className="absolute flex flex-col bottom-0 top-0 bg-white left-0 w-full h-full" style={{ zIndex: '10' }}>

            {startTutorial && <Tutorial page={tutorialPage} />}

            <div className="flex py-2 px-4 border-b-2 justify-between ">

                <p className="my-auto font-medium text-lg">Editor Template</p>

                <PrimaryButton width="w-fit" backgroundColor="bg-white" border="border-none" OnClick={cancel} ring={'focus:outline-none'} icon={closeIcon} />

            </div>

            <div className="flex" >
                {
                    tapMenu.map((list, index) => {
                        return (
                            <p key={index} className={`${tapMenuTutorialClass[index]} w-full transition-all delay-50 font-medium duration-500 text-center py-4 cursor-pointer border-b-2 ${activeMenu == index ? 'text-primary border-primary' : ''} `} onClick={() => setactiveScreen(index)}>{list}</p>
                        )
                    })
                }
            </div>

            <div className="w-full h-full px-6 py-4 bottom-0 overflow-auto list-industry">
                {
                    !activeSubMenu ?
                        <div className="w-full">

                            {TempType === "spinWheel" ?
                                <>{
                                    activeMenu === 0 ? <MainSettingSlipter templateType={TempType} fieldId={templateFiledId} field={mainPageContent} /> :
                                    activeMenu === 1 ? <SpinResultScreen templateType={TempType} fieldId={templateFiledId} field={mainPageContent} /> :
                                    null
                                    } 
                                </> :
                                <>{
                                    activeMenu === 0 ? <StartScreen fieldId={templateFiledId} field={startScreenContent} category={filedList?.category} /> :
                                    activeMenu === 1 ? <MainSettingSlipter templateType={TempType} fieldId={templateFiledId} field={mainPageContent} /> :
                                    activeMenu === 2 ? <ResultScreen fieldId={templateFiledId} field={resultScreenContent} setActiveSubMenu={ResultBlockClick} templatetype={TempType} category={filedList?.category} /> :
                                    null
                                    } 
                                </>
                            }
                        </div> :
                        <div className="w-full">
                            {
                                activeSubMenuStage === 0 ? <ResultInnerBlock fieldId={templateFiledId} field={resultScreenContent} SelcetIndex={stageResult} resultIndex={stageResultpage} formula={resultScreenContent?.formuls ?? []} setActiveSubMenuStage={setActiveSubMenuStage} /> :
                                    activeSubMenuStage === 1 ? <FormulaData message={message} formuladata={formuladata} IsVaild={IsVaild} setFormulaData={setFormulaData} setIsvaild={setIsvaild} setMessage={setMessage} fieldId={templateFiledId} formula={resultScreenContent?.formuls ?? [""]} stageResult={stageResult} question={mainPageContent?.questionsList} /> :
                                        null
                            }

                        </div>
                }

            </div>

            <div className="py-3 px-2 w-full flex justify-start space-x-2 border-t-2" >
                {
                    !activeSubMenu ?
                        <>
                            <PrimaryButton buttontype="" text={'Save'} width="w-24" OnClick={SaveClick} />
                            <PrimaryButton buttontype="secondarybutton" width="w-24" textcolor="text-black" border="border-2" text={'Cancel'} OnClick={cancel} />
                        </> :
                        <>

                            {
                                activeSubMenuStage == 0 ? <PrimaryButton buttontype="" text={'Save'} width="w-24" OnClick={SaveClick} /> :
                                    activeSubMenuStage == 1 ? <PrimaryButton buttontype="" text={'AddFormula'} width="w-32" OnClick={addFormula} /> :
                                        null
                            }
                            <PrimaryButton buttontype="secondarybutton" width="w-24" textcolor="text-black" border="border-2" text={'Back'} OnClick={() => {
                                activeSubMenuStage == 0 ?
                                    setActiveSubMenu(false) : setActiveSubMenuStage(0)

                            }} />
                        </>
                }

            </div>

        </div>
    )
}