import React, { useEffect } from "react";
import Input from "../input/input";
import toast from "react-hot-toast"

export const DateOptionBlock = ({
    primaryColor = "#ffffff",
    userAction = null,
    val="01/01/2000",
    question
}) => {

    const DataArr = val.split("/")

    const DateChangeEvent = (value, question, DateType) => {

        let strValLen = String(value).length;

        if( (strValLen > 2) && ( DateType == "day" ) ) return true;
        if( (strValLen > 2) && ( DateType == "mon" ) ) return true;
        if( (strValLen > 4) && ( DateType == "year" ) ) return true;
    
        let dd, mon, year;

        if (DateType == "day") dd = (value > 0 && value <= 31) ? value : toast.error("invalid Date")
        if (DateType == "mon") mon = (value > 0 && value <= 12) ? value : toast.error("invalid Date")
        if (DateType == "year") year = (value > 1947 && value <= 2100) ? value : toast.error("invalid Date")

        userAction(`${dd}/${mon}/${year}`, question)
    }

    return (
        <div className="flex space-x-1">
            <div className="flex " >
                <div className="flex flex-col w-8 space-y-1 px-1" style={{ color: primaryColor ?? "white" }} >
                    <Input style={{ color: primaryColor ?? "white" }} maxLen={2} width="w-6" margin={'mx-auto'} min={1} max={31} padding="p-0 pl-1" type={"number"} textsize={"text-[16px]"} textvalue={DataArr[0]} ClassName="h-full" border=" placeholder-white" round="" textColor={'text-white'} Placeholder="DD" background='bg-transparent' onchange={(e) => DateChangeEvent(e.target.value, question, "day")} />
                    <hr className="transition-all w-6 border-white" style={{ borderColor: primaryColor ?? "white" }} />
                </div>
                <span style={{ color: primaryColor ?? "white" }} >/</span>
            </div>
            <div className="flex " >
                <div className="flex flex-col w-8 space-y-1 px-1" style={{ color: primaryColor ?? "white" }} >
                    <Input width="w-6" style={{ color: primaryColor ?? "white" }} maxLen={2} margin={'mx-auto'} min={1} max={12} padding="p-0 " type={"number"} textsize={"text-[16px]"} textvalue={DataArr[1]} ClassName="h-full" border=" placeholder-white" round="" textColor={'text-white'} Placeholder="MM" background='bg-transparent' onchange={(e) => DateChangeEvent(e.target.value, question, "mon")} />
                    <hr className="transition-all w-6 border-white" style={{ borderColor: primaryColor ?? "white" }} />
                </div>
                <span style={{ color: primaryColor ?? "white" }} >/</span>
            </div>
            <div className="flex space-x-2" >
                <div className="flex flex-col w-10 space-y-1 px-1" style={{ color: primaryColor ?? "white" }} >
                    <Input width="w-10" style={{ color: primaryColor ?? "white" }} maxLen={4} margin={'mx-auto'} min={1950} max={2100} padding="p-0 " type={"number"} textsize={"text-[16px]"} textvalue={DataArr[2]} ClassName="h-full" border=" placeholder-white" round="" textColor={'text-white'} Placeholder="YYYY" background='bg-transparent' onchange={(e) => DateChangeEvent(e.target.value, question, "year")}  />
                    <hr className="transition-all w-9 border-white" style={{ borderColor: primaryColor ?? "white" }} />
                </div>
                {/* <span style={{ color: primaryColor ?? "white" }} >/</span> */}
            </div>
        </div>
    )

}