import React from 'react'
import PropTypes from "prop-types";

const Description = function ({ text, width = "", textColor = "text-primary-gray", lineHeight = "", margin = "", textSize = "text-14px", className = "", ...props }) {
    return (
        <span className={`font-normal ${margin} ${width} ${textColor} ${textSize} ${className} ${lineHeight}`}>{text}</span>
    )
}



Description.prototype = {
    text: PropTypes.string.isRequired,
    textColor: PropTypes.string,
    margin: PropTypes.string,
    textSize: PropTypes.string,

}

export { Description }