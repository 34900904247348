import React from "react";
import { useContext } from "react";
import { createContext } from "react";
import { useState } from "react";

const Editercontent = createContext();
const Editerdispatch = createContext(undefined);

const EditerProvider = ({children}) => {

    const [ editerstate, setEditerState ] = useState("design");

    const [ sidebartrigger = false, setSidebarTrigger ] = useState(Boolean);

    const [ LoadContent = false, setLoadContent ] = useState(Boolean);

    const [ sidebarstate , setSidebarState ] = useState(0);

    const [ ShowPageMainContent , setShowPageMainContent ] = useState (false)

    const [ setupObject , setsetupObject ] = useState({industry:"",category:""});

    const [ setupStatus = false , setsetupStatus ] = useState(Boolean);

    const [ ErrorHandler , setErrorHandler ] = useState(Object);

    return (

        <Editercontent.Provider value={{

            editerstate,setEditerState,
            sidebartrigger,setSidebarTrigger,
            sidebarstate,setSidebarState,
            ErrorHandler,setErrorHandler,
            setupObject,setsetupObject,
            setupStatus,setsetupStatus,
            LoadContent,setLoadContent,
            ShowPageMainContent,setShowPageMainContent,
            

        }}>
            
            <Editerdispatch.Provider  value={{}} >

                {children}

            </Editerdispatch.Provider >
        
        </Editercontent.Provider >
    )
}

const useSelectionAction  = () => useContext(Editercontent);

export  {EditerProvider,useSelectionAction};