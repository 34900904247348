import React from "react";
import { useState } from "react";


const Embedpostion = ({ActiveInd=0,setActive=null,isSideWheel=false}) => {

    const embed = [
        {
            postion:"Inline Embed",
            icon:(<svg width="80" height="54" viewBox="0 0 80 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.4" width="54" height="3" rx="1.5" fill={`${(ActiveInd == 0)?"#2563EB":"#DCE0E4"}`} />
            <rect opacity="0.4" y="51" width="32" height="3" rx="1.5" fill={`${(ActiveInd == 0)?"#2563EB":"#DCE0E4"}`} />
            <rect y="8" width="80" height="38" rx="4" fill={`${(ActiveInd == 0)?"#2563EB":"#DCE0E4"}`} />
            </svg>
            ),

        },
        {
            postion:"Full-page",
            icon:(<svg width="104" height="60" viewBox="0 0 104 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="104" height="60" rx="4" fill={`${(ActiveInd == 1)?"#2563EB":"#DCE0E4"}`} />
            </svg>
            ),

        },
        {
            postion:"Popup",
            icon:(<svg width="80" height="44" viewBox="0 0 80 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="80" height="44" rx="4" fill={`${(ActiveInd == 2)?"#2563EB":"#DCE0E4"}`} />
            </svg>
            ),
        },
        {
            postion:"Side Panel",
            icon:(<svg width="45" height="60" viewBox="0 0 45 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="45" height="60" rx="4" fill={`${(ActiveInd == 3)?"#2563EB":"#DCE0E4"}`} />
            </svg>
            ),
        },
        {
            postion:"Popover",
            icon:(<svg width="40" height="60" viewBox="0 0 40 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="52" rx="4" fill={`${(ActiveInd == 4)?"#2563EB":"#DCE0E4"}`} />
            <rect x="35" y="55" width="5" height="5" rx="2.5" fill={`${(ActiveInd == 4)?"#2563EB":"#DCE0E4"}`} />
            </svg>
            ),
        },
        {
            postion:"Side Tab",
            icon:(<svg width="45" height="60" viewBox="0 0 45 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="7" width="38" height="60" rx="4" fill={`${(ActiveInd == 5)?"#2563EB":"#DCE0E4"}`} />
            <rect y="22" width="4" height="16" rx="2" fill={`${(ActiveInd == 5)?"#2563EB":"#DCE0E4"}`} />
            </svg>
            ),
        },

    ]

    return (
        <>
        <div className="grid grid-cols-3 xl:gap-2 2xl:gap-6  ">
            {
               isSideWheel ? 
               <>
                  {/* <div className={`flex flex-col ${(ActiveInd == 5 ) ?"text-primary":" text-primary-gray" }`}>
                             <div className={`flex space-x-3 h-24 ${(5 == 4 || 5 == 5 || 5 == 3 )?"justify-end":"justify-center"} p-2 rounded-md border-2 ${(ActiveInd == 5 ) ?"border-primary text-primary":" text-primary-gray" } cursor-pointer `} onClick={ ()=> setActive(5)}>
                                     <span className={`${(5 == 4)?"justify-end":""}  my-auto`}>
                                         {embed[5].icon}
                                     </span>
                             </div>
                             <span className={`my-auto font-medium mt-2 mx-auto text-md`}>
                                 {embed[5].postion}
                             </span>
                         </div> */}
                </>
               
               :
               <>
               { embed.map((pos,index)=> {
                    return ( 
                        <div className={`flex flex-col ${(ActiveInd == index ) ?"text-primary":" text-primary-gray" }`}>
                            <div className={`flex space-x-3 h-24 ${(index == 4 || index == 5 || index == 3 )?"justify-end":"justify-center"} p-2 rounded-md border-2 ${(ActiveInd == index ) ?"border-primary text-primary":" text-primary-gray" } cursor-pointer `} onClick={ ()=> setActive(index)}>
                                    <span className={`${(index == 4)?"justify-end":""}  my-auto`}>
                                        {pos.icon}
                                    </span>
                            </div>
                            <span className={`my-auto font-medium mt-2 mx-auto text-md`}>
                                {pos.postion}
                            </span>
                        </div> 
                    );
                })}
                </>
            }
            
        </div>
        </>
    )
}

export default Embedpostion;