import React, { useEffect, useState } from "react";

import PrimaryButton from "../../Button1/primarybutton";
import DefaultDropMenu from "../../input/droplist";
import axios from "axios";
import { MediaPopup } from "../../popup/Mediapopup";


import { AllFount } from "../../../content/allFount";
import { ButtonWithLabel } from "../../Button1/ButtonWithLabel";
import { listOfIcon } from "../../../content/iconList";

export const EditerSetting = ({ cancel = null, templateFiledId = '' }) => {

    const [ font, setFont ] = useState("")
    const [ fontSize, setFountSize ] = useState("sm")
    const [ showPop, setShowPop ] = useState(false);

    const clsseIcon = listOfIcon('close',26,26,'#586474');


    return (

        <div className="absolute flex flex-col bottom-0 top-0 bg-white left-0 w-full h-full" style={{ zIndex: '10' }}>


            {showPop && <MediaPopup cancel={() => setShowPop(false)} />}
            <div className="py-3 px-2 w-full flex justify-between space-x-2 border-t-2 relative" >
                <p className="my-auto font-medium text-lg">Settings</p>
                <PrimaryButton width="w-fit" backgroundColor="bg-white" border="border-none" OnClick={cancel} ring={'focus:outline-none'} icon={clsseIcon} />

            </div>

            <div className="w-[90%] mx-auto flex flex-col space-y-4">

                <DefaultDropMenu list={AllFount} mainclass="flex justify-between" labelTextsize="my-auto" label="Font" width="w-full" labelTextWeight="w-fit pr-4" customClass="w-/12" />
                <div onClick={() => setShowPop(true)}>
                    <ButtonWithLabel lableText="Background Image" text={[ "Add" ]} textcolor="text-[#fff]" />
                </div>
                <ButtonWithLabel lableText="Fount Size" text={[ "sm", "md", "lg" ]} width="w-[50px] p-1 mx-1" textcolor="text-[#000]" backgroundColor="" border="" fontStyle="text-[10px] border-none" shadow="none" setState={(data) => setFountSize(data)} state={fontSize} />
            </div>

        </div>
    )
}