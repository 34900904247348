import React, { useState } from 'react'
import Axios from '../../axios'

export const DeleteCampaign = (campaignID) => {

    return new Promise (  (resolve,reject)=> {
        Axios.delete( `campaign/delete?campaign_id=${campaignID}`).then(res => 
            resolve(res['data'])
            ).catch(err =>reject(err))
    } )

}
