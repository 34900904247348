import React, { useEffect, useState } from "react";
import PrimaryButton from "../Button1/primarybutton";
import Input from "../input/input";
import InputRadiocheck from "../input/radiocheck";
import Embedpostion from "../templatepreview/embedpostion";
import PopupDevice from "../templatepreview/popupdevice";
import { Title } from "../Texts";
import { EditerMainBlock } from "./common/editermain";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { AnalyticsSection } from "./common/result/analytics/analytics";
import { campaignAnalytics } from "../../actions/templates";
import { ResponseTable } from "./common/result/response/response";
import { CampaignEvents } from "../../actions/campainEvent";
import { Actions } from "../../store";
import { listOfIcon } from "../../content/iconList";

export const ResultBlock = () => {



    const topMenu = ['Analytics','Responses']

    const [CurrentMenu,setMenu] = useState(0)

    const Navigate = useNavigate();

    const leftarrow = listOfIcon('leftarrow',14,14,'#586474')


    return (
        <section className={`flex flex-col w-full h-full mx-auto overflow-y-hidden`}>
            {/*preview path*/}
                <div className=" w-full h-fit flex justify-between px-4 border-b-1">

                    <p className=" flex my-auto h-fit font-medium  space-x-4 ml-2 ">

                        <span className="py-2 px-3 my-auto h-fit border-2 rounded-md cursor-pointer" onClick={()=>Navigate(-1)}>{leftarrow}</span>
                        
                        <p className="flex space-x-4">
                            {
                                topMenu.map((list,index) => {
                                   return( <span className={` transition-all duration-400 ${CurrentMenu == index ? 'text-primary border-primary border-b-2' :'border-b-1' } py-4 cursor-pointer my-auto `} key={index} onClick={()=>setMenu(index)} >{list}</span>)
                                })
                            }

                        </p>
                    </p>
                </div>

                <div className="w-full mx-auto flex h-full  ">
                    { CurrentMenu === 0 && <AnalyticsSection isActive={CurrentMenu === 0} />}
                    { CurrentMenu === 1 &&  <ResponseTable />}
                </div>
        </section>
    )
}