import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../../Button1/primarybutton'
import { PopupCreate } from '../../../popup/popupcreate'
import { ShowTeamList } from './ShowTeamList'
import { getAllTeamMember } from '../../../../actions/teamMember'
import { useDispatch } from 'react-redux'
import { Actions } from '../../../../store'
import toast from 'react-hot-toast'
import { LoadingSpinner } from '../../../Loading'
import { InviteTeamMember } from '../../../popup/inviteTeamMember'
import { getProjects } from '../../../../actions/projects'
import { UpdateTeamMemberSec } from '../../../popup/updateTeamMember'
import Input from '../../../input/input'

export const TeamMembers = () => {

  const [show, setShow] = useState(false)
  const [showUpdate, setShowUpdate] = useState(false)
  const [loading, setLoadding] = useState(false)
  const [UserSerialId, setUserSerialId] = useState('')
  const [Email, setEmail] = useState("")

  const Dispatch = useDispatch()

  useEffect(() => {
    FetchTeamUser()
  }, [])

  const FetchTeamUser = async () => {
    setLoadding(true)
    const GetTeam = await getAllTeamMember()
    const workspace = await getProjects()


    if (GetTeam?.success) {
      Dispatch(Actions?.setTeamUsers(GetTeam?.data?.users ?? []))
      Dispatch(Actions?.setProjects(workspace?.data ?? []))

    }

    else toast('Can\'t Get Team Members')
    setLoadding(false)

  }


  return (
    <>

      <div className='relative w-full h-full flex flex-col space-y-4 py-6 px-8 rounded-xl bg-white' id='team' >
        {
          loading ? <LoadingSpinner />
            :

            <>

              {show && <InviteTeamMember Email={Email} backwardAction={() => setShow(false)} />}

              {showUpdate && <UpdateTeamMemberSec id={UserSerialId} backwardAction={() => setShowUpdate(false)} />}

              {<>
                <div className='flex space-x-4 w-full px-2  rounded-xl'>
                  <span>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 9C11.3261 9 12.5979 9.52678 13.5355 10.4645C14.4732 11.4021 15 12.6739 15 14V20H5V14C5 12.6739 5.52678 11.4021 6.46447 10.4645C7.40215 9.52678 8.67392 9 10 9ZM3.288 12.006C3.12886 12.5428 3.03485 13.0968 3.008 13.656L3 14V20H9.13169e-08V15.5C-0.000196807 14.6376 0.318028 13.8054 0.893635 13.1632C1.46924 12.521 2.2617 12.1139 3.119 12.02L3.289 12.006H3.288ZM16.712 12.006C17.6019 12.0602 18.4376 12.452 19.0486 13.1012C19.6596 13.7505 19.9999 14.6084 20 15.5V20H17V14C17 13.307 16.9 12.638 16.712 12.006ZM3.5 6C4.16304 6 4.79893 6.26339 5.26777 6.73223C5.73661 7.20107 6 7.83696 6 8.5C6 9.16304 5.73661 9.79893 5.26777 10.2678C4.79893 10.7366 4.16304 11 3.5 11C2.83696 11 2.20107 10.7366 1.73223 10.2678C1.26339 9.79893 1 9.16304 1 8.5C1 7.83696 1.26339 7.20107 1.73223 6.73223C2.20107 6.26339 2.83696 6 3.5 6V6ZM16.5 6C17.163 6 17.7989 6.26339 18.2678 6.73223C18.7366 7.20107 19 7.83696 19 8.5C19 9.16304 18.7366 9.79893 18.2678 10.2678C17.7989 10.7366 17.163 11 16.5 11C15.837 11 15.2011 10.7366 14.7322 10.2678C14.2634 9.79893 14 9.16304 14 8.5C14 7.83696 14.2634 7.20107 14.7322 6.73223C15.2011 6.26339 15.837 6 16.5 6V6ZM10 0C11.0609 0 12.0783 0.421427 12.8284 1.17157C13.5786 1.92172 14 2.93913 14 4C14 5.06087 13.5786 6.07828 12.8284 6.82843C12.0783 7.57857 11.0609 8 10 8C8.93913 8 7.92172 7.57857 7.17157 6.82843C6.42143 6.07828 6 5.06087 6 4C6 2.93913 6.42143 1.92172 7.17157 1.17157C7.92172 0.421427 8.93913 0 10 0V0Z" fill="currentColor"></path></svg>
                  </span>
                  <h3 className='text-lg font-semibold flex justify-center items-center '>Team </h3>
                </div>
                <hr />
                <div className='p-3 rounded-lg border-1 flex justify-between' >
                  <Input bordercolor='border-white' margin='my-auto' te Placeholder='Enter Email' onchange={(e) => setEmail(e.target.value)} />
                  <PrimaryButton text='Send invite' width='w-[150px]' padding_y='py-3' height='h-auto' OnClick={() => setShow(true)} />
                </div>
                <div className='overflow-y-auto list-industry pb-20' >
                  <h3 className='text-lg font-semibold flex items-center '>Team member</h3>
                  <ShowTeamList setLoadding={setLoadding} setUserSerialId={setUserSerialId} setShowUpdate={setShowUpdate} />
                </div>
              </>
              }
            </>
        }
      </div>
    </>
  )
}
