import React, { useEffect, useRef } from "react";
import TextareaInput from "../../input/textarea";
import { toast } from "react-hot-toast";
import { getScriptCode } from "../../../content/scriptCode";
import { listOfIcon } from "../../../content/iconList";

export const ScriptCode = ({ showScriptCode = false, setshowScriptCode = null }) => {
  let scriptcode = `<script id="engage_script" async="true" src="${ getScriptCode() }" type="text/javascript"></script>` 
  
  const copy = () => {
    navigator.clipboard.writeText(scriptcode);
    toast.success("Copied!");
    setshowScriptCode(false);
  };

  const modalRef = useRef();

  useEffect(() => {
    const handleAction = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Clicked outside the modal
        setshowScriptCode(false);
      }
    };

    document.addEventListener("mousedown", handleAction);

    return () => {
      document.removeEventListener("mousedown", handleAction);
    };
  }, [setshowScriptCode]);

  return (
    <div className="animate-fadeIn fixed top-0 left-0 backgroundShadow w-full h-full flex justify-center duration-300" style={{ background: "rgb(0, 0 ,0, 0.15)", zIndex: "11" }}>
      <div
        ref={modalRef}
        className="p-8 w-[600px] h-[250px] rounded-xl flex justify-center items-center bg-white shadow-md m-auto z-[20]"
      >
        <div className="flex flex-col space-y-2 duration-500 w-full h-full justify-center">
            <div className="w-full flex justify-between items-center"> 
          <div className="text-[14px] font-bold ml-1">Paste this code on the header tag (Only one per page)</div>
          <div className="flex border-1 rounded-lg p-2" onClick={()=>{setshowScriptCode(false)}} >
            { listOfIcon('close',20,20,'#586474') }


          </div>
          </div>
          <div className="flex justify-between space-x-2">
            <div className="relative w-full h-full">
              <TextareaInput
                spellCheck={"false"}
                textvalue={scriptcode}
                labelTextsize="hidden"
                CustomClass="h-[135px] w-[100%] text-[#06152D] text-[14px]"
                bgColor="bg-[#F9FAFB]"
              />
              <button
                onClick={copy}
                className="absolute left-[12px] bottom-[12px] text-12px bg-white rounded-md my-auto text-primary border-1 border-primary font-semibold py-2 px-3"
              >
                Copy Embed Code
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
