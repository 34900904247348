import React, { useState } from "react";
import PrimaryButton from "../../../Button1/primarybutton";
import toast from "react-hot-toast";
import { planCalculation } from "../../../../actions/Billing";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../../store";

export const OrderSummary = ({ checkoutPlanDetail, setloading = null }) => {


    const  { planCal } =  useSelector(state => ({planCal:state?.planCalculation}))
    const Dispatch = useDispatch()

    const [userPlanDetail, setUserPlanDetail] = useState(planCal?.proration ?? {})
    const [userPlanDiscount, setUserPlanDiscount] = useState(planCal?.redeem_coupon?.discount_attempts ?? {})


    const ListItem = userPlanDetail?.items

    const { changePlan, changetype } = planCal

    const [couponCode, onChangeCode] = useState('')


    const applyCouponCode = async () => {
        setloading(true)
        try {

            if (couponCode == "") { setloading(false); return toast.error("invaild Coupon Code") }

            const GetChangePlanDetail = await planCalculation(changePlan, changetype, couponCode)

            if (GetChangePlanDetail?.success) {
                Dispatch(Actions.setPlanCalculation({ ...GetChangePlanDetail?.data, changePlan, changetype }))
                if(!GetChangePlanDetail?.data?.redeem_coupon)  { setloading(false); return toast.error("invaild Coupon Code") }

            }
            else toast.error("invaild Coupon Code")
        } catch (err) {
            toast.error("invaild Coupon Code")
        }
        setloading(false)

    }


    return (
        <div className="w-full h-fiyt py-4 px-6 flex flex-col rounded-lg shadow-bgshadow border-1" >
            <div>
                <h3 className="font-medium" >Order Summary <small className="text-base" > (ALL amounts are in USD)</small></h3>
            </div>
            <hr />
            <div className="w-full flex flex-col space-y-3 px-2" >
                {
                    ListItem && ListItem?.map((list, index) => {
                        return (

                            <p className="w-full flex justify-between capitalize" key={index} ><span>{list?.description}</span><span>$ {(list?.amount).toFixed(2)}</span></p>
                        )
                    })

                }
            </div>
            <hr />
            <div className="w-full flex flex-col justify-end space-y-3 px-2" >
                <p className="flex space-x-5 justify-end capitalize"  ><span>Sub Total :</span><span className="w-[100px] flex justify-end" >$ {(userPlanDetail?.subTotal).toFixed(2)}</span></p>
                <p className="flex space-x-5 justify-end capitalize"  ><span>Discount :</span><span className="w-[100px] flex justify-end" >$ {(userPlanDetail?.discount).toFixed(2)}</span></p>
            </div>
            <hr />
            <div className="w-full flex flex-col justify-end space-y-3 px-2" >
                <p className="flex space-x-5 justify-end capitalize"  ><span>Total :</span><span className="w-[100px] flex justify-end" >$ {(userPlanDetail?.total).toFixed(2)}</span></p>
                <p className="flex space-x-5 justify-end capitalize"  ><span>Balance / Credits adjusted :</span><span className="w-[100px] flex justify-end" >$ {(userPlanDetail?.appliedBalance).toFixed(2)}</span></p>
            </div>
            <hr />
            <div className="w-full flex flex-col justify-end space-y-3 px-2" >
                {userPlanDetail?.appliedBalance > 0 && <p className="flex space-x-2 text-sm font-medium justify-end capitalize"  >
                    <span className="flex my-auto" >
                        <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM11.25 8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V13C12.75 13.41 12.41 13.75 12 13.75C11.59 13.75 11.25 13.41 11.25 13V8ZM12.92 16.38C12.87 16.51 12.8 16.61 12.71 16.71C12.61 16.8 12.5 16.87 12.38 16.92C12.26 16.97 12.13 17 12 17C11.87 17 11.74 16.97 11.62 16.92C11.5 16.87 11.39 16.8 11.29 16.71C11.2 16.61 11.13 16.51 11.08 16.38C11.03 16.26 11 16.13 11 16C11 15.87 11.03 15.74 11.08 15.62C11.13 15.5 11.2 15.39 11.29 15.29C11.39 15.2 11.5 15.13 11.62 15.08C11.86 14.98 12.14 14.98 12.38 15.08C12.5 15.13 12.61 15.2 12.71 15.29C12.8 15.39 12.87 15.5 12.92 15.62C12.97 15.74 13 15.87 13 16C13 16.13 12.97 16.26 12.92 16.38Z" fill="#30fc03" />
                        </svg>
                    </span>
                    <span className="flex my-auto">
                        The balance ${(userPlanDetail?.appliedBalance).toFixed(2)} will be credited back to your stripe account.
                    </span>
                </p>}
                <p className="flex space-x-5 justify-end capitalize"  ><span>Net Total :</span><span className="w-[100px] flex justify-end" >$ {(userPlanDetail?.amountDue > 0 ? userPlanDetail?.amountDue : 0).toFixed(2)}</span></p>
            </div>
            <hr />
            <div className="w-full flex  justify-end space-x-3 px-2" >
                {
                    userPlanDiscount?.status === "applied" ?
                        <p className="flex space-x-2" >
                            <span>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="#C80815" />
                                    <path d="M13.0601 12L15.3601 9.69998C15.6501 9.40998 15.6501 8.92999 15.3601 8.63999C15.0701 8.34999 14.5901 8.34999 14.3001 8.63999L12.0001 10.94L9.70011 8.63999C9.41011 8.34999 8.93011 8.34999 8.64011 8.63999C8.35011 8.92999 8.35011 9.40998 8.64011 9.69998L10.9401 12L8.64011 14.3C8.35011 14.59 8.35011 15.07 8.64011 15.36C8.79011 15.51 8.98011 15.58 9.17011 15.58C9.36011 15.58 9.55011 15.51 9.70011 15.36L12.0001 13.06L14.3001 15.36C14.4501 15.51 14.6401 15.58 14.8301 15.58C15.0201 15.58 15.2101 15.51 15.3601 15.36C15.6501 15.07 15.6501 14.59 15.3601 14.3L13.0601 12Z" fill="white" />
                                </svg>

                            </span>
                            <span className="text-sm font-semibold" > {userPlanDiscount?.code} Applyed </span>

                        </p>
                        :
                        <>
                            <input type="text" placeholder="apply your coupon" className="w-3/5 border-[1.5px] border-primary rounded-md px-3 " onChange={(e) => onChangeCode(e.target.value)} />
                            <PrimaryButton text={'apply'} OnClick={applyCouponCode} />
                        </>
                }

            </div>
        </div>
    )

}//The balance $16 will be credited back to your stripe account.
// {
//     proration: {
//       items: [
//         {
//           amount: -66.72,
//           description: 
//             'Unused time on GoZen Engage (with 20.0% off) after 02 Sep 2023'
//         },
//         { amount: 9, description: 'GoZen Engage - starter' }
//       ],
//       subTotal: -57.72,
//       discount: 0,
//       appliedBalance: 59.14,
//       total: -59.14,
//       currency_code: 'USD',
//       amountDue: -59.14,
//       discountSave: null
//     },
//     changePlan: 'starter',
//     changetype: 'monthly'
//   }

// {
//     "success": true,
//     "message": "",
//     "data": {
//         "proration": {
//             "items": [
//                 {
//                     "amount": -24.26,
//                     "description": "Unused time on Optinly after 02 Sep 2023"
//                 },
//                 {
//                     "amount": 108,
//                     "description": "Optinly"
//                 }
//             ],
//             "subTotal": 83.74,
//             "discount": 21.6,
//             "appliedBalance": 0,
//             "total": 62.13999999999999,
//             "currency_code": "USD",
//             "amountDue": 62.13999999999999,
//             "discountSave": "20%"
//         },
//         "redeem_coupon": {
//             "discount_attempts": {
//                 "status": "applied",
//                 "message": "",
//                 "code": "OPTIN20"
//             }
//         }
//     },
//     "code": ""
// }