import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allElement, element, getImageBase64, getImageOrignalSize, isEmpty, isNotEmpty } from "../../../utils/helpers";
import "./commonStyle.css"
import { Actions } from "../../../store";
import { ConnectionGameAnswerFailed } from "./ConnectionAnswerFailed";
import { ConntectionAnserCorrect } from "./ConnectionAnswerCorrect";

export const ConnectionTemp = ({ obj = {}, fieldID = '', StartTime = null, prview = false, setActionCount = null, GameFinsh = null, setScore = null, position = 0, setLoadContent = null, startTimeFun=null, TimeOut=false, ConnectionTimeOutFun=null, ConnntionStartTimeFun = null }) => {
  const { Fields, device } = useSelector((state) => ({ Fields: state.fields, device: state.device }));

  const templateData = Fields[fieldID];
  const Settings = templateData?.filedContent
  const startScreenContent = Settings?.game_startscreen_json;
  var mainPageContent = Settings?.game_mainpage_json;
  const resultScreenContent = Settings?.game_resultcreen_json;
  const leadFormScreenContent = Settings?.game_leadForm_json;

  const [curQuizIndex, setCurQuizIndex] = useState(0);
  const [connectionList, setconnectionList] = useState([]);
  
  const [ wrongAnswer, setWrongAnswer ] = useState(false); 
  const [ correctAnswer, setCorrectAnswer ] = useState(false); 
  const [ fideout, setfideout ] = useState(false); 

  const [ActiveObj, setActiveObj] = useState({})
  const [htmlStr, sethtmlStr] = useState(null);
  const [widthPer, setwidthPer] = useState(20);
  const [trigger, settrigger] = useState(false)
  const DisPatch = useDispatch()


  useEffect(() => { setLoadContent(false); GameStart(); }, [StartTime])

  useEffect(() => { if (mainPageContent?.connectionList?.length > 0) { setCurQuizIndex(0); setActionCount(0); gameSetup(); } }, [mainPageContent, trigger]);

  useEffect(()=> { 
    if(TimeOut) setCorrectAnswer(true) 
  },[TimeOut])

  // useEffect(() => { updateImageSize(); }, [curQuizIndex])


  async function GameStart() {

    if (StartTime && mainPageContent?.connectionList?.length > 0) {
      setfideout(false);
      settrigger(!trigger);
      setCurQuizIndex(0);
      setActionCount(0);
      gameSetup();
      setconnectionList(mainPageContent?.connectionList)
      setActiveObj(mainPageContent?.connectionList[0])
      // preLoad();
    }

  }

  // async function preLoad() {
  //   let temp = mainPageContent?.connectionList, newList = []
  //   for (let i = 0; i < temp?.length; i++) {
  //     let base64 = []
  //     for (let j = 0; j < temp[i]?.imageUrl?.length; j++) {
  //       let size = await getImageOrignalSize(temp[i].imageUrl[j]);
  //       base64.push(await getImageBase64(temp[i].imageUrl[j], size[0], size[1]));
  //     }
  //     newList.push({ ...temp[i], base64 })
  //     setconnectionList(newList)
  //     //  setTimeout(()=>{updateImageSize();},25)
  //   }
  //   setTimeout(() => { updateImageSize(); }, 25)
  // }

  useEffect(() => { if (mainPageContent?.connectionList?.length !== 0 && curQuizIndex >= mainPageContent?.connectionList?.length) GameFinsh(true); }, [curQuizIndex]);

  const gameSetup = async () => { setActiveObj(mainPageContent?.connectionList[curQuizIndex]); setconnectionList(mainPageContent?.connectionList); setCurQuizIndex(0); setScore(0); };

  function listenKeyBackSpace(e) {
    if (e.code === "Backspace") {
      let inputBox = allElement(".charInput" + fieldID), inputboxno = parseInt(e.target.getAttribute("inputboxno"));
      if (inputboxno > 0) { inputBox[inputboxno - 1].focus(); inputBox[inputboxno - 1].value = ""; } else
        if (inputboxno === 0) inputBox[inputboxno].value = "";
    }
  }

  function listenKeyBoard(e, i) {
    // sethtmlStr(i)
    let allowInput = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",
      "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
      "1", "2", "3", "4", "6", "7", "8", "9", "0", "~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "-", "+", "=", "{", "}",
      "[", "]", "|", ";", ":", '"', "'", "<", ">", ",", ".", "?", "/", " "]
    let notAllowButton = ["Backspace", "AudioVolumeMute", "AudioVolumeDown", "AudioVolumeUp", "NumLock", "ScrollLock", "MetaLeft",
      "Insert", "Escape", "F1", "F2", "F3", "F4", "F5", "F6", "F7", "F8", "F9", "F10", "F11", "F12", "Undo", "Backquote", "Tab", "CapsLock", "ShiftLeft",
      "ShiftRight", "AltLeft", "AltRight", "ControlRight", "ArrowRight", "Delete", "ArrowLeft", "ControlLeft", "MetaLeft", "ArrowUp", "ArrowDown", "PageUp", "PageDown", "Home", "End"];

    let inputBox = allElement(".charInput" + fieldID), inputboxno = parseInt(e.target.getAttribute("inputboxno")), userAnswer = "";

    if (isNotEmpty(e?.code)) { //Hardware Keyboard 
      if (e.code === "Delete") inputBox[inputboxno].value = ""; else
        if (e.code === "ArrowLeft" && inputboxno > 0) inputBox[inputboxno - 1].focus(); else
          if (e.code === "ArrowRight" && (inputboxno + 1) < connectionList[curQuizIndex]?.answer.length) inputBox[inputboxno + 1].focus(); else
            if (!notAllowButton.includes(e.code) && (inputboxno + 1) < connectionList[curQuizIndex]?.answer.length) { inputBox[inputboxno].value = e.key; inputBox[inputboxno + 1].focus(); }
    } else { // Touch Keyboard
      let elm = e?.target
      if (!notAllowButton.includes(elm?.value)) {
        if (elm?.value?.length > 1) { elm.value = elm?.value[elm?.value?.length - 1]; inputBox[inputboxno + 1]?.focus(); } else
          if (allowInput.includes(elm?.value) && (inputboxno + 1) < connectionList[curQuizIndex]?.answer.length) { inputBox[inputboxno].value = elm?.value; inputBox[inputboxno + 1]?.focus(); }
          else { elm.value = ""; }
      }
    }

    //Check Answer
    inputBox.forEach(function (boxValue) { userAnswer += boxValue.value; });
    if (userAnswer.length === connectionList[curQuizIndex]?.answer.length) checkAnswer(userAnswer);
  }

  const checkAnswer = async (userAnswer) => {

    if (isNotEmpty(userAnswer) && userAnswer.toLowerCase() !== (connectionList[curQuizIndex]?.answer).toLowerCase()) {
      setWrongAnswer(true)
      return;
    }

    if (isNotEmpty(userAnswer) && userAnswer.toLowerCase() === (connectionList[curQuizIndex]?.answer).toLowerCase()) {
      setCorrectAnswer(true);
      ConnntionStartTimeFun(false);
      ConnectionTimeOutFun(false)
      setScore(obj => obj + 1);
      return;
    }


  }

  const TriggerNextContent = () =>{

    setCorrectAnswer();

    // element("gamePanel" + fieldID).style.display = "none";

    setfideout(true)

    setTimeout(() => {
      
    allElement(".charInput" + fieldID)[0].focus();

    setCurQuizIndex(curQuizIndex + 1);

    if (mainPageContent?.connectionList.length > curQuizIndex + 1) setActiveObj(mainPageContent?.connectionList[curQuizIndex + 1])

    setActionCount(obj => obj + 1)

    allElement(".charInput" + fieldID).forEach(function (boxValue) { boxValue.value = ""; });

    // element("gamePanel" + fieldID).style.display = "block";

    allElement(".charInput" + fieldID)[0].focus();

  }, 700);

    setTimeout( () => {

      if( (curQuizIndex+1) < mainPageContent?.connectionList?.length) {

        setfideout(false);

        startTimeFun();

      }


    }, 1000 );

    

  }

  let inputBoxes = [], key = 0;
  for (var i = 0; i < connectionList[curQuizIndex]?.answer.length; i++) {
    inputBoxes.push(<input type="text" className={`${((device === "mobile") ? "rounded-[6px]  w-[2rem] h-[2.3rem] text-[32px]" : "w-[3.2rem] h-[3.5rem] rounded-[10px] text-[42px]") + ` charInput` + fieldID + ` charInputStyle  `} uppercase font-bold p-1 mt-2`} key={"charInput_" + i}
      maxLength={1} max={1} inputboxno={i} onKeyUp={(e) => listenKeyBoard(e, i)} onKeyDown={(e) => listenKeyBackSpace(e)} />);
  }


  // function updateImageSize() {
  //   let len = connectionList[curQuizIndex]?.imageUrl?.length;
  //   // if(isEmpty(len)) {setwidthPer(50);    return;}
  //   let temp = 100 / (len);
  //   if (len >= 5) temp = 20
  //   else if (len === 1) temp = 35;
  //   else if (len === 2) temp = 43;
  //   if (isNotEmpty(temp) && (!isNaN(temp))) setwidthPer(temp -= 1);
  // }

  return (
    <>

    { wrongAnswer && <ConnectionGameAnswerFailed WrongAnswerFun={setWrongAnswer}  wrongAns ={wrongAnswer} connectionObj = {ActiveObj} /> }

    { correctAnswer && <ConntectionAnserCorrect correctAnswerFun={ TriggerNextContent } connectionObj = { ActiveObj } /> }

    <div className={`transform transition-all duration-500 w-[80%] h-[85%] m-auto ${ fideout ? "opacity-0" :"opacity-100" } flex  `} id={"gamePanel" + fieldID} onClick={(e) => { e.stopPropagation(); DisPatch(Actions.setActiveFieldside({ filedId: fieldID, index: position, ActiveScreen: "main" })) }} >
      
      <div className="w-full h-full m-auto flex">
        <div className="w-full py-2 flex flex-col space-y-5 m-auto h-fit">
          <p className={((device === "mobile") ? "" : "text-[24px]") + ` flex flex-row font-bold text-center justify-center text-white pb-1`}>
            {(ActiveObj?.question == "") ? "Find the Word By connectiong the image" : ActiveObj?.question  }</p>
          <div className={``}>
            <div className={`flex flex-wrap justify-center  gap-5  connectionDivParent`}>
              {
                ActiveObj?.imageUrl?.map((url, inx) => {
                  let ImageLength = ActiveObj?.imageUrl?.length
                  // let base64 = connectionList[curQuizIndex]?.base64[inx]
                  // url = isEmpty(base64) ? url : base64
                  return (
                    <div className={` rounded-xl overflow-hidden ${ImageLength < 3 ? ImageLength < 2 ? "w-full " : "w-2/5 max-h-[200px]" : "w-[30%] max-w-[220px] max-h-[150px]"}  flex flex-row  h-full  justify-center  max-w-[500px] `} key={inx}>
                      <div className={`relative object-cover w-full h-full flex rounded-xl bg-white ${ImageLength < 3 ? ImageLength < 2 ? "" : "min-h-[200px]" : "min-h-[150px]"} overflow-hidden`}  >

                        <img draggable={false} alt={'Connection-img'} src={url} className={`relative bg-white flex m-auto object-cover h-auto rounded-xl  ${ImageLength < 3 ? ImageLength < 2 ? "" : " max-h-[200px]" : " max-h-[150px]"}`} style={{ border: `${ImageLength < 3 ? ImageLength < 2 ? "0.85rem" : "0.75rem" : "5px"}  solid white` }} />
                      </div>
                    </div>
                  )
                })

              }

            </div>
          </div>
          <center><div className=" flex-row justify-center space-x-1 mt-[0.6rem] sm:mt-[1rem] inline-block text-center"> {inputBoxes}  </div> </center>
        </div>
      </div>
    </div>
    </>
  );
};
