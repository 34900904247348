import React, { Children, useEffect, useRef, useState } from 'react'
import { listOfIcon } from '../../content/iconList';

export const MultiSelectInput = ({ Children, onClickTap = null, optionList=[], selectOption =[], onClickOption = null }) => {


    const [ ShowMenu, setSHowMenu ] = useState(false);

    const emptyCircle = listOfIcon('emptycircle',20,20,'#DCE0E4');

    const selectedcirlefill = listOfIcon('selectedcirlefill',20,20,'#2563EB');

    const menuref  = useRef();

    const OnClickTap = () => {

        setSHowMenu(true);

        if( onClickTap ) onClickTap();

    }

    const OnClickOption = ( ind ) =>{

        // setSHowMenu(false);

        onClickOption(ind);

    }

    useEffect(()=>{
        let handleAction = (event) => {
  
            if (menuref.current && !menuref.current?.contains(event.target)) {
                // alert("test-out")
                setSHowMenu(false);
            }
        };
    
        document.addEventListener("mousedown", handleAction);
    },[])

    return (
        <div className='' >


            <p className="p-2 rounded-md border-1 cursor-pointer" onClick={OnClickTap} >

                {Children}

            </p>


            { ShowMenu && <div className="absolute right-14 p-2 border-1 rounded-lg bg-white top-3 z-10 min-w-[175px] space-y-2" ref={menuref} >

                {
                    optionList.map( ( titleList, index ) => {

                        const isExist = selectOption.findIndex( list => list == titleList );

                        const isExistBoolean = ( isExist > -1 ) ;

                        return (
                            <p className="w-full justify-between flex hover:bg-bluish-gray cursor-pointer p-1"  onClick={ () => OnClickOption(index) } >

                                <span className="my-auto" >{titleList}</span>
                                
                                <span className="my-auto" > { isExistBoolean ? selectedcirlefill : emptyCircle } </span>

                            </p>
                        )
                    })
                }

            </div>}

        </div>
    )
}