import React, { useEffect, useState } from "react";
import CheckBox from "../../input/checkbox";
import { Imageinput } from "../../input/imageinput";
import InputWithLabel from "../../input/inputWithLabel";
import TextareaInput from "../../input/textarea";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../store";
import { MediaPopup } from "../../popup/Mediapopup";
import Input from "../../input/input";
import DefaultDropMenu from "../../input/droplist";
import PrimaryButton from "../../Button1/primarybutton";
import { ColorPixerInput } from "../../input/colorpixerInput";
import { ProductFilter } from "../../popup/productFilterpop";
import { isEmpty } from "../../../utils/helpers";
import { Tutorial } from "../../Tutorial";
import { useSelectionAction } from "../../../pages/customstate/context";
import SwicthInput from "../../input/swicth";
import { listOfIcon } from "../../../content/iconList";

export const ResultScreen = ({ field, fieldId, templatetype = "", setActiveSubMenu = null, category }) => {

    const { filedList } = useSelector((state) => ({ filedList: state.fields[fieldId] }))
    const { completedTutorial, engage_apptour } = useSelector((state) => ({ completedTutorial: state.completedTutorial, engage_apptour: state.engage_apptour }))
    const { ShowPageMainContent } = useSelectionAction();


    const TempType = filedList?.type
    const Settings = filedList?.filedContent


    const ecommerceQuestionList = templatetype === 'ecommerce' ? Settings?.game_mainpage_json?.questions : []

    const option = [
        'equal to',
        'graterthen',
        'lessthen',
    ]
    const optionContent = ['Equal to', 'greater than', 'less than']

    const Dispatch = useDispatch();

    const [ShowMedia, setShowMidia] = useState(false);
    const [ButtonRedir, setButtonRedir] = useState(false);
    const [showMediaInx, setMediaInx] = useState(0)
    const [curFieldType, setcurFieldType] = useState("imageUrl")
    const [filterInx, setFiiterInx] = useState(0)


    let CalData = {
        button: 'visit',
        description: 'description',
        imagesrc: '/asset/images/emptyimage.png',
        title: 'title',
        buttonlink: 'https://gozen.io/'
    }

    const ContentChange = (e, type, Checkstate = false) => {

        let stateCheck = false;

        const IsCheckBox = e.target.type == 'checkbox' ? true : false;

        if (IsCheckBox) stateCheck = !Checkstate ? true : false

        const SetValue = IsCheckBox ? stateCheck : e.target.value

        const setContent = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_resultcreen_json',
            child: type,
            value: SetValue
        }

        Dispatch(Actions.setFieldTemplateContentChange(setContent))

    }

    const ResultCC = (value, type, ResultIndex, isCalAdd = false, calIndex = 0, remove = false) => {

        if (type === "scoreOper" || type === "scoreValue") {
            let operator = field?.results[ResultIndex]?.score?.opertator
            let scoreVal = field?.results[ResultIndex]?.score?.value

            if (type === "scoreOper") value = { "opertator": value, "value": scoreVal }
            else if (type === "scoreValue") value = { "opertator": operator, "value": value }
            isCalAdd = false
            type = "score"
        }
        setMediaInx(false)

        const setContent = {
            isCommonSetting: false,
            isCalAdd: isCalAdd,
            key: fieldId,
            node: 'game_resultcreen_json',
            child: "results",
            resultIndex: ResultIndex,
            edge: type,
            value: value

        }
        Dispatch(Actions.setFieldTemplateREsultCC(setContent))
    }

    const ChangeCouponType = (type, index) => {
        if (isEmpty(type) || isEmpty(index)) return;
        const setContent = {
            isCalAdd: false,
            isCaldelete: false,
            key: fieldId,
            node: 'game_resultcreen_json',
            child: "results",
            resultIndex: index,
            edge: "couponBgType",
            value: type

        }
        Dispatch(Actions.setFieldTemplateREsultCC(setContent))
    }

    const addResult = () => {



        let obj = {
            "displayView": true,
            "timerStauts": false,
            "ScoreStauts": true,
            "buttonDisplayView": false,
            "reStart": true,
            "restartbutton": "Restart",
            "reDirUrl": "https://gozen.io/",
            "imageStatus": false,
            "imageUrl": "",
            "titleContent": "Thank you!",
            "descriptionContent": "Sorry, Better Luck Next Time!",
            "buttonColor": "#ffffff",
            "couponStauts": false,
            "couponBgType": "color",
            "couponBgImage": "https://gz-engage-assets.s3.amazonaws.com/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/9288f5c06385de9b9a6e45841f0da03c.png",
            "couponScratchImage": "https://gz-engage-assets.s3.amazonaws.com/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/ceea686268c4bd7460d07bcacd310e0d.png",
            "couponBgColor": "#f5f5dc",
            "couponCode": "",
            "couponBorderColor": "#e6e69a",
            "couponTitle": "",
            "couponDescription": "",
            "buttonBgcolor": "#50d71e",
            "buttontextL": "Finish",
            "buttonType": "primary",
            "score": {
                "opertator": "equal to",
                "value": 0
            }
        }

        let calObj = {
            "ScoreStauts": false,
            "descriptionContent": '',
            "buttonColor": '#fff',
            "displayView": true,
            "reStart": true,
            "timerStauts": false,
            "imageStatus": true,
            "buttonDisplayView": false,
            "buttontextL": '',
            "buttonType": 'primary',
            "reDirUrl": '',
            "imageUrl": '',
            "buttonBgcolor": '#00000',
            "Calresults": [
                {
                    "button": 'vist',
                    "description": '$ 10',
                    "imagesrc": '/asset/images/template/gallery/3.png',
                    "title": 'discount calculator',
                    "buttonlink": 'https://gozen.io/partner/'
                }
            ],
            "titleContent": ' CPM Calculator Results.',
            "couponStauts": false,
            "restartbutton": 'Restart',
            "imagesrc": ''
        }


        if (templatetype === "calculater") obj = calObj

        const setContent = {
            key: fieldId,
            node: 'game_resultcreen_json',
            child: "results",
            value: obj,
            process: "add"


        }

        Dispatch(Actions.addThankYouResult(setContent))
    }

    const DirectContentChange = (value, type) => {


        const setContent = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_resultcreen_json',
            child: type,
            value: value
        }
        Dispatch(Actions.setFieldTemplateContentChange(setContent))
        if (ShowMedia) setShowMidia(false)

    }

    const ClickAction = (ClickAction, Resultindex, index) => {
        if (ClickAction == "edit") {
            setActiveSubMenu(Resultindex, index)
        }
        if (ClickAction == "delete") {
            const setContent = {
                isCommonSetting: false,
                isCaldelete: true,
                key: fieldId,
                node: 'game_resultcreen_json',
                child: "results",
                resultIndex: Resultindex,
                edge: "Calresults",
                value: index

            }
            Dispatch(Actions.setFieldTemplateREsultCC(setContent))
        }


    }

    const deleteIcon = listOfIcon('delete',18,18,'red');

    const editIcon = listOfIcon('edit',18,18,'#586474');


    const toolTip = [
        {
            type: "edit",
            icon: editIcon,
        },
        {
            type: "delete",
            icon: deleteIcon
        },
    ];



    const [showPopup, setshowPop] = useState(false)

    function deleteEvent(index) {
        const setContent = {
            key: fieldId,
            node: 'game_resultcreen_json',
            child: "results",
            value: index,
            process: "delete"

        }

        Dispatch(Actions.addThankYouResult(setContent))

    }
    function showFilterEditor(inx) {
        setshowPop(true);
        setFiiterInx(inx)
    }
    return (

        <div className=" relative w-full space-y-4 ">


            {

                field?.results.map((list, index) => {

                    let couponBgColor = (list?.couponBgColor ?? "#f5f5dc")

                    let toggleButton = (list?.couponBgType === "color" ? false : true)

                    let activeSateInd = option.findIndex(resData => resData === list?.score?.opertator)

                    return (
                        <>
                            {showPopup && <ProductFilter questionlist={ecommerceQuestionList} filedId={fieldId} cancel={setshowPop} ResultINdex={filterInx} />}

                            {ShowMedia && showMediaInx === index && <MediaPopup cancel={setShowMidia} fieldType={curFieldType} innerArr={true} ineerIndex={index} OnSelect={ResultCC} />}

                            <div key={index} className={`w-full relative flex  flex-col space-y-2 border-2  h-fit delay-400 duration-700 top-0 p-4 rounded-xl hover:shadow-md hover:-top-0 hover:border-primary`}  >
                                <div className="flex justify-between">

                                    <p className="text-base font-medium" >Result Page - {index + 1}</p>

                                    <div className="flex space-x-2">
 
                                        <p className="p-2 rounded-md border-1 border-red-500 hover:bg-gray-200 cursor-pointer" onClick={() => { deleteEvent(index) }}  >{deleteIcon}</p>

                                    </div>

                                </div>

                                {(templatetype === "ecommerce") &&
                                    <>
                                        {(!completedTutorial?.filterBtn) && engage_apptour && ShowPageMainContent && <Tutorial page="filterBtn" />}
                                        <PrimaryButton textsize='text-14px step-edit-filterBtn' text={'Filter Product'} OnClick={() => showFilterEditor(index)} />
                                    </>
                                }

                                <div className="w-full space-y-4">

                                    <InputWithLabel label="Header" textvalue={list?.titleContent} onchange={(e) => ResultCC(e.target.value, 'titleContent', index)}  labelTextWeight="flex justify-between" eyeStatus={list?.titleStatus} />

                                    <TextareaInput label="Description" textvalue={list?.descriptionContent} onchange={(e) => ResultCC(e.target.value, 'descriptionContent', index)} />

                                    <Imageinput label="Cover image" src={list?.imageUrl} chanaeAction={() => { setcurFieldType("imageUrl"); setShowMidia(true); setMediaInx(index) }} deleteSrc={() => ResultCC('', 'imageUrl', index)} />

                                    <SwicthInput label={"Coupon Option"} width="w-full" parentClass={'w-full flex-row-reverse justify-between'} status={list?.couponStauts} onchange={(val) => ResultCC(val , 'couponStauts', index) } />

                                    {
                                        list?.couponStauts && <div className="w-full space-y-4 ">


                                            <InputWithLabel label="Coupon Title" textvalue={list?.couponTitle} onchange={(e) => ResultCC(e.target.value, 'couponTitle', index)} viewiconState={true} labelTextWeight="flex justify-between" />
                                            <InputWithLabel label="Coupon Description" textvalue={list?.couponDescription} onchange={(e) => ResultCC(e.target.value, 'couponDescription', index)} viewiconState={true} labelTextWeight="flex justify-between" />
                                            <InputWithLabel label="Coupon Code" textvalue={list?.couponCode} onchange={(e) => ResultCC(e.target.value, 'couponCode', index)} viewiconState={true} labelTextWeight="flex justify-between" />



                                            <div className="w-full space-y-4">
                                                <label className="capitalize" >Coupon Background type</label>
                                                <div className="flex w-full 0">
                                                    <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={toggleButton ? 'text-gray-500' : 'text-primary'} border={toggleButton ? 'border-2 border-gray-500' : 'border-2 border-primary'} height="h-14" text={'Color'} rounded={'rounded-none'} OnClick={() => ChangeCouponType('color', index)} />
                                                    <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={!toggleButton ? 'text-gray-500' : 'text-primary'} border={!toggleButton ? 'border-2 border-gray-500' : 'border-2 border-primary'} height="h-14" text={'Image'} rounded='rounded-none' OnClick={() => ChangeCouponType('image', index)} />
                                                </div>
                                            </div>

                                            {toggleButton ? <Imageinput label="Background Image" src={list?.couponBgImage} chanaeAction={() => { setcurFieldType("couponBgImage"); setShowMidia(true); setMediaInx(index) }} deleteSrc={() => ResultCC('', 'couponBgImage', index)} /> :
                                                <ColorPixerInput label={"Background Color"} Onchange={ResultCC} fieldType="couponBgColor" fieldId={index} colorHex={couponBgColor} labelTextWeight="flex justify-between" />}




                                            <Imageinput label="Scratch Image" src={list?.couponScratchImage} chanaeAction={() => { setcurFieldType("couponScratchImage"); setShowMidia(true); setMediaInx(index) }} deleteSrc={() => ResultCC('', 'couponScratchImage', index)} />


                                        </div>
                                    }

                                    <div className=" w-full space-y-4">
                                        <label className="capitalize" >Button Action type</label>
                                        <div className="flex w-full 0">
                                            <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={!list?.reStart ? 'text-gray-500' : 'text-primary'} border={!list?.reStart ? 'border-2 border-gray-500' : 'border-2 border-primary'} height="h-14" text={'Restart'} rounded={'rounded-none'} OnClick={() => ResultCC(true, 'reStart', index)} />
                                            <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={list?.reStart ? 'text-gray-500' : 'text-primary'} border={list?.reStart ? 'border-2 border-gray-500' : 'border-2 border-primary'} height="h-14" text={'Redirect'} rounded='rounded-none' OnClick={() => ResultCC(false, 'reStart', index)} />
                                        </div>
                                    </div>


                                    <InputWithLabel label="Button text" textvalue={list?.restartbutton} onchange={(e) => ResultCC(e.target.value, "restartbutton", index)} />

                                    <InputWithLabel label="Button Redirect url" textvalue={list?.reDirUrl} onchange={(e) => ResultCC(e.target.value, "reDirUrl", index)} />

                                    {
                                        (templatetype === "calculater") && <div className="step-edit-calFormulaBtn w-full">
                                            {(!completedTutorial?.calFormulaBtn) && engage_apptour && ShowPageMainContent && <Tutorial page="calFormulaBtn" />}
                                            <div className="w-full flex justify-between">
                                                <p className="flex my-auto ">Result</p>
                                                <PrimaryButton text="Add" backgroundColor="bg-primary-lightblue" border="border-1 border-primary" textcolor="text-primary" OnClick={() => ResultCC(CalData, "Calresults", index, true)} />
                                            </div>
                                            <div>
                                                {
                                                    list?.Calresults.map((obj, ind) => {
                                                        return (
                                                            <div className="w-full space-y-2 py-2 rounded-lg">
                                                                <div className="w-full flex justify-between border-1 p-2 cursor-pointer rounded-lg" >
                                                                    <div className="flex space-x-4">
                                                                        <div className="w-10 h-10 rounded-md border-1">
                                                                            <img src={obj?.imagesrc == "" ? "/asset/images/emptyimage.png" : obj?.imagesrc} alt='' className="w-full h-full object-cover rounded-md" />
                                                                        </div>
                                                                        <p className="my-auto capitalize text-sm">Result - {ind}</p>
                                                                    </div>
                                                                    <div className="flex space-x-2">
                                                                        {
                                                                            toolTip.map((list, i) => {
                                                                                return (
                                                                                    <p key={i} className="p-2 my-auto h-fit rounded-md border-1 hover:bg-gray-200 cursor-pointer" onClick={() => ClickAction(list.type, index, ind)}  >{list.icon}</p>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>

                                        </div>
                                    }

                                    {list?.score && <div className="flex flex-col space-y-4">
                                        <label>Rule</label>
                                        <div className="w-full flex space-x-2">
                                            {/* <Input margin={''}  Placeholder="key"  textvalue={list.key}  /> */}
                                            <p className="flex w-40 text-16px text-black appearance-none bg-white placeholder-gray-600 border rounded-8px px-14px leading-5 placeholder-opacity-40 "><span className="flex m-auto">{TempType === "findpath" ? "Time" : "Score"}</span></p>
                                            {/* <DefaultDropMenu list={optionContent} labelTextWeight="hidden" width="w-28"  active={activeSateInd < 0 ? 0 : activeSateInd} spaceY="" mainclass="my-auto" edge={"opertator"} IndexValue={index} fieldType={'scoreOper'}  onchange={ResultCC} /> */}
                                            <DefaultDropMenu list={optionContent} labelTextWeight="hidden" width="w-32" active={activeSateInd < 0 ? 0 : activeSateInd} spaceY="" mainclass="my-auto" edge={"opertator"} IndexValue={index} fieldType={'scoreOper'} SelectIndex={true} onchange={(i) => ResultCC(option[i], 'scoreOper', index)} />
                                            <Input margin={''} type="number" textvalue={list.score.value} onchange={(e) => ResultCC(e.target.value, 'scoreValue', index)} />
                                        </div>
                                    </div>}

                                </div>
                            </div>
                        </>

                    )
                })
            }

            {templatetype !== "poll" && <PrimaryButton text={'Add Result'} width="w-full" height="h-12" backgroundColor="bg-primary-lightblue" border="border-1 border-primary" textcolor="text-primary" OnClick={() => addResult()} />}


        </div>
    )
}