import { element, isEmpty } from "./helpers";

export const PartyPaperInit = (data) => {
    var canvas = "", ctx;
    const confettiColors = ["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548"];
    const confettiShapes = ["circle", "square", "triangle", "heart"];
    
    class Confetti {
        constructor() {
            let min, add;
            if (canvas.width <= 400) { min = 10; add = 5; }
            else if (canvas.width <= 700) { min = 15; add = 7 }
            else { min = 20; add = 10 }
            this.color = confettiColors[Math.floor(Math.random() * confettiColors.length)];
            this.shape = confettiShapes[Math.floor(Math.random() * confettiShapes.length)];
            this.size = Math.random() * min + add;
            this.x = Math.random() * canvas.width;
            this.y = -this.size;
            this.velocityX = Math.random() * 4 - 2;
            this.velocityY = Math.random() * 2 + 2;
            this.rotation = Math.random() * 360;
            this.rotationSpeed = Math.random() * 4 - 2;
        }
        update() {
            this.x += this.velocityX;
            this.y += this.velocityY;
            this.rotation += this.rotationSpeed;
            if (this.x > canvas.width + this.size) {
                this.x = -this.size;
            } else if (this.x < -this.size) {
                this.x = canvas.width + this.size;
            }
            if (this.y > canvas.height + this.size) {
                this.y = -this.size;
            }
        }
        draw() {
            ctx.save();
            ctx.fillStyle = this.color;
            ctx.translate(this.x + this.size / 2, this.y + this.size / 2);
            ctx.rotate((this.rotation * Math.PI) / 180);
            // eslint-disable-next-line default-case
            switch (this.shape) {
                case "circle":
                    ctx.beginPath();
                    ctx.arc(0, 0, this.size / 2, 0, 2 * Math.PI);
                    ctx.fill();
                    break;
                case "square":
                    ctx.fillRect(-this.size / 2, -this.size / 2, this.size, this.size);
                    break;
                case "triangle":
                    ctx.beginPath();
                    ctx.moveTo(0, -this.size / 2);
                    ctx.lineTo(this.size / 2, this.size / 2);
                    ctx.lineTo(-this.size / 2, this.size / 2);
                    ctx.fill();
                    break;
                case "heart":
                    ctx.beginPath();
                    ctx.moveTo(0, -this.size / 4);
                    ctx.quadraticCurveTo(this.size / 2, -this.size / 2, 0, this.size / 2);
                    ctx.quadraticCurveTo(-this.size / 2, -this.size / 2, 0, -this.size / 4);
                    ctx.fill();
                    break;
            }
            ctx.restore();
        }
    }

    var confettis = [], pause = false;
    const maxConfettis = 500;

    function createConfetti(amount) {
        for (let i = 0; i < amount; i++) {
            const confetti = new Confetti();
            confettis.push(confetti);
        }
    }

    function animateConfetti() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        for (let i = 0; i < confettis.length; i++) {
            const confetti = confettis[i];
            confetti.update();
            // remove older confetti if the number of confettis is greater than maxConfettis
            while (confettis.length > maxConfettis) confettis.shift();
            // remove confetti that has gone off screen
            if (confetti.y > canvas.height + confetti.size) {
                confettis.splice(i, 1);
                i--;
                continue;
            }
            confetti.draw(ctx);
        }
        if (pause === true) {
            confettis = []
            canvas = ""
            return;
        }
        requestAnimationFrame(animateConfetti);
    }

    const addConfetti = () => {
        setTimeout(() => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            createConfetti(Math.floor(Math.random() * 50) + 50);
        }, 500);
    };

    const clearConfetti = () => {
        confettis.splice(0, confettis.length);
        pause = true
        animateConfetti();
    };

    window.addEventListener(
        "resize",
        () => {
            if(pause) return
            canvas.width = window.innerWidth-50;
            canvas.height = window.innerHeight-50;
        },
        false
    );

    const { canvasId = "", width = 400, height = 400, count = 50, clearOn = 6000 } = data
    if (isEmpty(canvasId)) return -1;
    canvas = element(canvasId);
    ctx = canvas.getContext("2d");
    canvas.width = width; canvas.height = height
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    createConfetti(count);
    pause = false
    animateConfetti();
    setTimeout(() => { clearConfetti() }, clearOn)
}