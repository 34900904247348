import React, { useEffect, useState } from "react";
import DefaultDropMenu from "../../input/droplist";
import PrimaryButton from "../../Button1/primarybutton";
import InputWithLabel from "../../input/inputWithLabel";
import TextareaInput from "../../input/textarea";
import { Imageinput } from "../../input/imageinput";
import { useDispatch, useSelector } from "react-redux";
import { GetWoocommerce } from "../ProductsIntrgation/woocommerce/GetWoocommerce";
import { SelectList } from "../ProductsIntrgation/SelectList";
import { ShowManualProducts } from "../ProductsIntrgation/ManualProducts/ShowManualProducts";
import { Actions } from "../../../store";
import { MediaPopup } from "../../popup/Mediapopup";
import { isEmpty } from "../../../utils/helpers";

export const ProductMainBlock = ({ SetStage = null, fieldId = "", campaign_ID = "" }) => {

    const Dispatch = useDispatch()
    const { fields, Campaign_info } = useSelector((state) => ({ fields: state.fields, fieldsOrder: state.fieldsOrder, EcommerceFieldID: state.EcommerceFieldID, Campaign_info: state.Campaign_info }))

    const [filterList, setfilterList]= useState([])
    const [filterIndex, setFilterIndex] = useState(0)

    useEffect(()=>{
        let a=Campaign_info?.additional_info ?? {}
        let b=a[fieldId]?.product_integration ?? []
        let c=["Select Products", "Manual Products"]
        b.map((obj, key)=>{
            c.push(obj)
        })
        setfilterList(c)
    },[])

    const templateData = fields[fieldId];
    const Settings = templateData?.filedContent
    const manualList = Settings?.game_mainpage_json?.ManualList ?? [];
    const productSL = Settings?.game_resultcreen_json?.productSelectList ?? [];
    const Add_info = Campaign_info?.additional_info[fieldId]
    const ProDuctInte = Add_info?.product_integration ?? []

    const [IsActive, setisActive] = useState(0)
    const [ShowPop, setShowPop] = useState(false)
    const [selectedProduct,setselectedProduct] = useState(true)

    const [open, setOpen] = useState("")

    useEffect(()=>{setselectedProduct(true)},[IsActive])

    const testData = {
        productName: "",
        description: "",
        productImageSrc: "",
        sku: "",
        price: "",
        tag: "",
        buttonText: "Buy Now",
        buttonLink: "",
        buttonColor: "",
        backgroundColor: ""
    }

    const ChangeProductContent = (value='',type,productIndex) => {

        let Data ={
                key: fieldId,
                node: 'game_resultcreen_json',
                child: "productSelectList",
                productIndex: productIndex,
                edge:type,
                value: value
        }
        Dispatch(Actions.setProductCC(Data))
    }

    const DirectContentChange = (value, type) => {


        let Data ={
            key: fieldId,
            node: 'game_resultcreen_json',
            child: "productSelectList",
            productIndex: IsActive,
            edge:type,
            value: value
    }
        Dispatch(Actions.setProductCC(Data))
        if (ShowPop) setShowPop(false)

    }

    const removeFilter = () => {
        Dispatch(Actions.setRemoveSelectList({ IsActive, fieldId }));
    
        Dispatch(Actions.setUpdateCampaign());
      };

      function scrollToProducts(e){
        if(isEmpty(e)) return;
        setFilterIndex(filterList.findIndex(resData => resData === e))
        let id=""

        if(e==="Select Products"){
            id="Select_Products"
        } else if(e==="Manual Products"){
            id="Manual_Products"
        } else if(e==="woocommerce"){
            id="woocommerce_Products"
        } else if(e==="shopify"){
            id="shopify_Products"
        }

        setTimeout(()=>{
            let elem = document.getElementById(id)
            if(elem) document.getElementById(id).scrollIntoView()
        },500)

      }
    return (
        <section className={`flex w-full h-full mx-auto`}>
            {ShowPop && <MediaPopup cancel={setShowPop} fieldType={'productImageSrc'} OnSelect={DirectContentChange} /> }
            <div className="w-9/12 flex flex-col h-full border-r-1 overflow-hidden list-industry bg-[#F9FAFB]">
                <div className="flex w-full justify-end px-3 py-2">
                    <p className="flex my-auto mr-auto font-bold text-xl capitalize" >Products</p>
                    <div className="flex space-x-3 my-auto">
                        <DefaultDropMenu list={filterList} active={filterIndex} onchange={scrollToProducts} labelTextWeight="hidden" /> 
                        <PrimaryButton text={"Add Product"} margin="step-product-addProductBtn mt-auto" OnClick={() => SetStage(0)} />
                    </div>
                </div>
                <div className="h-full overflow-y-auto list-industry scroll-smooth duration-200">
                    <SelectList IsActive={IsActive} fieldId={fieldId} setisActive={setisActive} trigger={setselectedProduct}/>
                    <ShowManualProducts filteredProduct={filterList[filterIndex]} fieldId={fieldId} trigger={setselectedProduct} />
                    {
                         ProDuctInte.map(list => {
                            return (<GetWoocommerce  filteredProduct={filterList[filterIndex]} fieldId={fieldId} campaign_ID={campaign_ID} intergation={list} />
                            )
                        })
                    }
                </div>
            </div>
            <div className="relative flex flex-col w-2/5   ">

            {!selectedProduct&& <div className="absolute w-full h-full flex justify-center top-0 bottom-0 bg-[rgba(0,0,0,0.2)] " style={{zIndex:'100'}} >
                   <p className="flex my-auto p-3 rounded-md bg-white" >Can't Edit Product</p>
                </div>}

                {
                    productSL.map((list, index) => {
                        if (IsActive === index) return (
                            <div key={index} className={`relative flex flex-col w-full py-6 px-6 pb-20  space-y-4 overflow-y-auto list-industry `}  >
                               
                                <div className={`flex flex-col w-full space-y-4  ${index===0 ? "step-product-editProductBasic" : ""}`}>
                                <InputWithLabel label={"Name"} textvalue={productSL[IsActive]?.productName} onchange={(e)=>ChangeProductContent(e.target.value,'productName',index)} />
                                <TextareaInput label={"Description"} textvalue={productSL[IsActive]?.description} onchange={(e)=>ChangeProductContent(e.target.value,'description',index)} />
                                <Imageinput label="Image" src={productSL[IsActive]?.productImageSrc} chanaeAction={()=>setShowPop(true)} />
                                <InputWithLabel label={"Add Tag"} textvalue={productSL[IsActive]?.tag} onchange={(e)=>ChangeProductContent(e.target.value,'tag',index)} />
                                </div>

                                <div className={`flex flex-col w-full space-y-4  ${index===0 ? "step-product-editProductSKU" : ""} `}>
                                <InputWithLabel label={"SKU"} textvalue={productSL[IsActive]?.sku} onchange={(e)=>ChangeProductContent(e.target.value,'sku',index)}/>
                                </div>

                                <InputWithLabel label={"Price"} textvalue={productSL[IsActive]?.price} onchange={(e)=>ChangeProductContent(e.target.value,'price',index)} />
                                
                                <div className={`flex flex-col w-full space-y-4  ${index===0 ? "step-product-editProductBtn" : ""} `}>
                                <InputWithLabel label={"Button"} textvalue={productSL[IsActive]?.buttonText} onchange={(e)=>ChangeProductContent(e.target.value,'buttonText',index)} />
                                <InputWithLabel label={"Button Link"} textvalue={productSL[IsActive]?.buttonLink} onchange={(e)=>ChangeProductContent(e.target.value,'buttonLink',index)} />
                                </div>
                            </div>
                        )
                    })
                }
                <div className="absolute bottom-0 bg-white left-0 py-3 px-2 w-full flex justify-end space-x-2 border-t-2" >

                    <PrimaryButton buttontype="secondarybutton" backgroundColor="bg-[#d62020]"  width="w-24" textcolor="text-[#d62020]"  border="border-2 border-[#d62020]" OnClick={removeFilter} text={'Delete'} />
                    <PrimaryButton buttontype="" text={'Save'} width="w-24" OnClick={()=> Dispatch(Actions.setUpdateCampaign()) } />

                </div>
            </div>

        </section>
    )
}