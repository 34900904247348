import React, { Component, useEffect, useState } from "react";
import Layout from ".";
import PrimaryButton from "../components/Button1/primarybutton";
import { Description, Title } from "../components/Texts";
import GameBlock from "../components/templatepreview/gamecontainer";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { createcCampaign, getTemplate } from "../actions/templates";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../store";
import { LoadingSpinner } from "../components/Loading";
import HandleIcon from "../components/templatepreview/handleicon";
import { element, isNotEmpty } from "../utils/helpers";
import Iframe from "react-iframe";
import { MediaPopup } from "../components/popup/Mediapopup";
import { ProjectListAndCreate } from "../components/popup/projectlistandCreate";
import { PopupCreate } from "../components/popup/popupcreate";
import { createProject } from "../actions/projects";
import toast from "react-hot-toast";
import { Tutorial } from "../components/Tutorial";
import { useSelectionAction } from "./customstate/context";

const TemplatePreview = () => {


    const Navigate = useNavigate()
    const Dispatch = useDispatch()
    useEffect(()=>{  Dispatch(Actions.setChangeDevice("desktop"))},[])
    const CampaignDetail = useParams() //template_id

    const { completedTutorial, engage_apptour  } = useSelector((state) => ({ completedTutorial: state.completedTutorial, engage_apptour:state.engage_apptour }))
    const { projects } = useSelector(state => ({projects:state?.projects}))
    const [seletedProject, setSeletedProject] = useState(0)
    const {  ShowPageMainContent } = useSelectionAction();


    const [softload, setSoftload] = useState(false);
    const [activeIndustry, setActiveIndustry] = useState('food_industry');
    const [templateData, setTmplateData] = useState(null)
    const [decivestate, setActiveDevice] = useState('desktop')
    const [renderCnt, setRenderCnt] = useState(0)
    const [ShowprojectPop, setShowprojectPop] = useState(false);
    const [showCampPop, setCampPop] = useState(false)
    const [showMedia, setShoeMedia] = useState(false)
    const [ShowloadingSpin, setShowloadingSpin] = useState(false);
    const { userInfo } = useSelector(state => ({ userInfo: state.userDetail }))
    const [ onChangeSrc , setOnchangeSrc ] = useState('')
    const [trigger, settrigger] = useState('create')
    const [ campaignName, setcampaignName ] = useState('My Campaign')
    const [ onChangePN , setOnchangePN ] = useState('My Project')
    const [isMyWS,setisMyWS] = useState(true)
    const { sharedProject } = useSelector(state => ({sharedProject:state?.sharedProject}))
    const [startTutorial, setStartTutorial] = useState(false)

    


    useEffect(() => {       
         setTimeout(()=>{ setStartTutorial((!completedTutorial?.templatePreview) && engage_apptour && ShowPageMainContent) },100)
         return(()=>{
            setStartTutorial(false)
        })
        }, [engage_apptour])

    useEffect(() => {       
        let tmpElm = element("engageLoadDiv")
        if (isNotEmpty(tmpElm)) tmpElm.style.display = "flex"
        setRenderCnt(o => o + 1);
        setData();
    }, [])

    useEffect(() => {
        let tmpElm = element("engageLoadDiv")
        if (isNotEmpty(tmpElm)) tmpElm.style.display = "flex"
        setRenderCnt(o => o + 1);
    }, [decivestate])

    function goToTemplate() {
        // const alltemplateUrl = process.env.REACT_APP_ENV === "development" ? `http://localhost:3400/template` : process.env.REACT_APP_RENDER_ENGINE_URL + "template";
        Navigate(-1)
    }

    async function setData() {
        setSoftload(true)
        let temp = await getTemplate(CampaignDetail.template_id)
        setTmplateData(temp?.data)
        setSoftload(false)
    }


    const changeTempalte = () => {
        Navigate(-1);
    }

    const createProjectFun = async () => {

        setShowloadingSpin(true)

        const createProjectDetail =  await createProject( onChangePN ,onChangeSrc )
        if(createProjectDetail?.status){
            
            let data= [...projects, {project_id:createProjectDetail?.data?.project_id,project_name:onChangePN,project_img_src:onChangeSrc} ]
            setSeletedProject(data.length - 1)
            Dispatch(Actions.setProjects(data))
            toast.success(`project  created`)
            setCampPop(true)
            setShowprojectPop(false)
        }
        else toast.error('Something went wrong!')

        setShowloadingSpin(false)


    }

    const useTempalte = async () => {

        const ActiveProject = isMyWS ? projects : sharedProject 

        if(ActiveProject.length < seletedProject ) return toast.error('Project not selected!')

        setShowloadingSpin(true)
        setSoftload(true)
        let templateFieldId = templateData?.order[0]

        setCampPop(false)
        setSoftload(true)

        const payload = {
            "campaign": {
                "title": campaignName,
                "type": "inline_popup",
                "category": templateData?.fileds?.[templateFieldId]?.category,
                "project_id":ActiveProject[seletedProject]?.project_id,
                "intent": "",
                "industry": activeIndustry,
                "templateId": CampaignDetail.template_id
            }
        }
        var data;
        try{
            data = await createcCampaign( payload,!isMyWS );
        } catch(e){
            // console.log(e)
        }

        if (data?.success) isMyWS ? Navigate ( `/campaign/edit/${data.data.campaignId}`) : Navigate ( `/shared-workspace/campaign/edit/${ActiveProject[seletedProject]?.project_id}/${data.data.campaignId}`)
        else {
            setSoftload(false)
        }
        setShowloadingSpin(false)

    }

    const hideLoader = () => {
        setTimeout(() => {
            let tempelm = element("engageLoadDiv")
            if (isNotEmpty(tempelm)) tempelm.style.display = "none"
        }, 1500)

    }

    const mediaOnSelect = (src,type) => {
        setOnchangeSrc(src)
    }

    const previewUrl = process.env.REACT_APP_ENV === "development" ? `http://localhost:3400/template-preview/${CampaignDetail.template_id}` : process.env.REACT_APP_RENDER_ENGINE_URL + "template-preview/" + CampaignDetail.template_id;

    let description = templateData?.fileds?.[templateData?.order[0]]?.description;
    return (

        <>
         {startTutorial && <Tutorial page="templatePreview"/> }

            {
                softload ? <LoadingSpinner height="h-screen" opacity={'opacity-100'} /> :


                    <Layout padding="" isactive={1} activeSidetap={true} >

                        {showMedia && <MediaPopup OnSelect={mediaOnSelect} cancel={(val) => { setShoeMedia(false); (trigger == 'create') ? setShowprojectPop(true) : setShoeMedia(false) }} fieldType="src" />}

                        {ShowprojectPop && <ProjectListAndCreate setisMyWS={setisMyWS} isMyWS={isMyWS} sharedProject={sharedProject} onProfile={setShoeMedia} loadingLive={ShowloadingSpin} forwardAction={() => { setCampPop(true); setShowprojectPop(false) }} projectData={projects} createProjectFun={createProjectFun} seletedProject={seletedProject} backwardAction={setShowprojectPop} changeDefaultVaule={setOnchangePN} setSeletedProject={setSeletedProject} title="select your workspace folder" />}
                        {showCampPop && <PopupCreate title="Campaign name" loadingLive={ShowloadingSpin} backwardAction={setCampPop} changeDefaultVaule={(e, t) => setcampaignName(e.target.value)} defaultVaule={campaignName} forwardAction={useTempalte} />}



                        <section className="flex w-full h-full">

                            <div className="w-9/12 h-full border-r-1 overflow-hidden list-industry">
                                <div className="flex flex-col w-full border-1 h-full ">
                                    <div className="flex justify-between py-3 px-4 border-b-1">
                                        <div className="text-[20px] ">
                                            <p className="my-auto font-normal flex space-x-2 ml-2 ">
                                                <span className="py-2 px-3 border-2 rounded-md cursor-pointer" onClick={() => goToTemplate()}>
                                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5.58251 10.9782C5.69335 10.9782 5.80418 10.9373 5.89168 10.8498C6.06085 10.6807 6.06085 10.4007 5.89168 10.2315L2.66001 6.99982L5.89168 3.76816C6.06085 3.59899 6.06085 3.31899 5.89168 3.14982C5.72251 2.98066 5.44251 2.98066 5.27335 3.14982L1.73251 6.69066C1.56335 6.85982 1.56335 7.13982 1.73251 7.30899L5.27335 10.8498C5.36085 10.9373 5.47168 10.9782 5.58251 10.9782Z" fill="#586474" />
                                                        <path d="M2.141 7.4375H11.9585C12.1977 7.4375 12.396 7.23917 12.396 7C12.396 6.76083 12.1977 6.5625 11.9585 6.5625H2.141C1.90183 6.5625 1.7035 6.76083 1.7035 7C1.7035 7.23917 1.90183 7.4375 2.141 7.4375Z" fill="#586474" />
                                                    </svg>

                                                </span>
                                                <span className="my-auto " >Preview</span>

                                            </p>


                                        </div>
                                        <div><HandleIcon  activeState={setActiveDevice} /></div>
                                    </div>
                                    <div className={`h-full transition-all duration-500 bg-[#f9fcff] mx-auto ${decivestate === 'mobile' ? 'w-[460px]' : decivestate == 'tab' ? 'w-[770px]' : 'w-full'}`}>
                                        <div class="engageLoadingDiv" style={{ position: "relative" }} id={`engageLoadDiv`}><div class="engageLoading"></div><div style={{ maxWidth: "265px", width: "60%" }} ><img src="https://gz-engage-assets.s3.amazonaws.com/v1/public/basic/GoZenEngage.png" alt="Engage Logo" /></div> </div>

                                        <Iframe key={decivestate + renderCnt} src={previewUrl} height="100%" width="100%" title="Preview" onLoad={hideLoader}></Iframe>


                                    </div>
                                </div>

                            </div>

                            <div className="flex flex-col w-2/5 space-y-8 my-6">
 
                                <div className="w-4-5 mx-10 flex flex-col ">
                                    <Title text={templateData?.name} textSize="text-xl" fontStyle="font-bold" margin="mb-3" />
                                    <Description text={(isNotEmpty(description) ? description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, ")} margin="mb-3" textSize="text-md" />
                                    <PrimaryButton text={"Use This Template"} padding_y="" height="h-50px step-btn-useThisTemplate" rounded="rounded-xl" margin="mt-4" OnClick={ () => setShowprojectPop(true) } />
                                    <PrimaryButton text={"Change Template"} padding_y="" buttontype="secondarybutton" height="h-50px step-btn-changeTemplate" rounded="rounded-xl" fontStyle="font-bold" ring="" margin="mt-4" OnClick={changeTempalte} />

                                    {(userInfo.email === "viswa@gozen.io") && <>
                                        <PrimaryButton text={"Create Template"} padding_y="" buttontype="secondarybutton" height="h-50px" rounded="rounded-xl" fontStyle="font-bold" ring="" margin="mt-4" OnClick={() => Navigate(`/template/create/${CampaignDetail.template_id}`)} />
                                        <PrimaryButton text={"generate Template"} padding_y="" buttontype="secondarybutton" height="h-50px" rounded="rounded-xl" fontStyle="font-bold" ring="" margin="mt-4" OnClick={() => Navigate(`/template/create/generate/${CampaignDetail.template_id}`)} />
                                    </>}
                                </div>
                            </div>

                        </section>
                    </Layout>
            }

        </>
    )

    // }
}

export default TemplatePreview