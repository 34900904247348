export const DefaultContent = [

    {
        "Label":"Back Button",
        "type":'backButtonContent',
        "Default_Content":"previous",
        "maxLength": 20
    },
    {
        "Label":"Next Button",
        "type":'nextButtonContent',
        "Default_Content":"Next",
        "maxLength": 20
    },
    {
        "Label":"Next Question Button",
        "type":'nextQuestionButtonContent',
        "Default_Content":"Next Question",
        "maxLength": 30
    },
    {
        "Label":"Score",
        "type":'scoreContent',
        "Default_Content":"Score",
        "maxLength": 20
    },
    {
        "Label":"Timer",
        "type":'TimerContent',
        "Default_Content":"Timer",
        "maxLength": 20
    },
    {
        "Label":"Number of Action",
        "type":'NOAContent',
        "Default_Content":"No of action",
        "maxLength": 50
    },
    {
        "Label":"Correct Answer",
        "type":'correctAnswer',
        "Default_Content":"invaild number",
        "maxLength": 30
    },
    {
        "Label":"Next Question",
        "type":'nextQuestionButtonContent',
        "Default_Content":"Next Question",
        "maxLength": 30
    },   
    {
        "Label":"Total Vote",
        "type":'totalVote',
        "Default_Content":"Total Vote",
        "maxLength": 50
    },    
    {
        "Label":"vote Count",
        "type":'voteCount',
        "Default_Content":"Vote Count",
        "maxLength": 30
    },
    {
        "Label":"Questions",
        "type":'questionsBlockcontent',
        "Default_Content":"Quesions",
        "maxLength": 20
    },
    {
        "Label":"Anwered",
        "type":'answeredBlockcontent',
        "Default_Content":"Answered",
        "maxLength": 20
    },
    {
        "Label":"this Field is Requied",
        "type":'fieldRequied',
        "Default_Content":"this Field is Requied",
        "maxLength": 50
    },
    {
        "Label":"invaild number",
        "type":'invaildNumber',
        "Default_Content":"invaild number",
        "maxLength": 50
    },
    {
        "Label":"invaild Email",
        "type":'invaildEmail',
        "Default_Content":"invaild Email",
        "maxLength": 50
    },

]




