import toast from "react-hot-toast";
import react , { useRef,useEffect } from 'react'
import { inviteEngageTeamMember } from "../../actions/teamMember";
import PrimaryButton from "../Button1/primarybutton";
import DefaultDropMenu from "../input/droplist";
import Input from "../input/input";
import { useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { isEmpty } from "../../utils/helpers";
import { deleteMemberToProject, inviteMemberToProject, updateMemberToProject } from "../../actions/projects";
import { deleteMemberFormSharedWS, inviteMemberFormSharedWS, updateMemberFormSharedWS } from "../../actions/sharedproject";

export const WorkspaceMemberList = ({ teamList = [], fetchData = null, setShow = null, projectId = "", projectName = "", isSharedWS = false }) => {

    const UserRole = ['admin', 'editor', 'view only']

    const [spinIt, setSpinIt] = useState(false)
    const [delspinIt, setdelSpinIt] = useState(false)
    const [delspinItINd, setdelSpinItINd] = useState()
    const [emailId, setEmail] = useState("")
    const [inviteRole, setInviteRoll] = useState("admin")

    var activeSateInd = UserRole.findIndex(resData => resData === inviteRole)

    function ChangePermission(value) { setInviteRoll(value) }


    async function sendInvite() {

        try {


            if (isEmpty(emailId)) {
                toast.error('Kindly enter the email id!')
                return
            }

            let payload = {
                project_id: projectId,
                email: emailId,
                role: inviteRole,
                user_id: "",
            }

            delete payload.user_id

            setSpinIt(true)

            let InviteTeamMember = isSharedWS ? await inviteMemberFormSharedWS(payload) : await inviteMemberToProject(payload)

            if (InviteTeamMember?.status) {
                await fetchData()
                toast.success('Team Member Successfully Invited!');
                setEmail("")
            }

            // else toast.error('Can\'t invite the team memeber!')

            setSpinIt(false)

        } catch (err) {
            setSpinIt(false)

        }
    }

    const updateMember = async (userInfo,Role) => {

        try {
            // setSpinIt(true)

            let payload = {
                project_id: projectId,
                email: userInfo?.email,
                role: Role,
                user_id: userInfo?.user_id,
            }
    
            const UpdateMem = isSharedWS ? await updateMemberFormSharedWS(payload) : await updateMemberToProject(payload)
            

            if(UpdateMem?.status) {
                toast.success('Role Upadated!');
                setdelSpinIt(false)
                setdelSpinItINd()
            }

        }catch(Err) {
            setSpinIt(false)

        }

    }



    const DeleteMember = async (userInfo) => {

        try {
            setdelSpinIt(true)

            let payload = {
                project_id: projectId,
                email: userInfo?.email,
                role: userInfo?.role,
                user_id: userInfo?.user_id,
            }
    
            const DelMem = isSharedWS ? await deleteMemberFormSharedWS(payload) : await deleteMemberToProject(payload)

            if(DelMem?.status) {
                toast.success('Role Upadated!');
                setdelSpinIt(false)
                setdelSpinItINd()

            }

        }catch(Err) {
            setdelSpinIt(false)
            setdelSpinItINd()

        }

    }

    const menuREf = useRef(null)
    useEffect(() => {

        let handleAction = (event) => {

            if (menuREf.current && !menuREf.current?.contains(event.target)) {
                setShow(false);
            }
        };

        document.addEventListener("mousedown", handleAction);

    }, [])


    return (
        <div className="w-full h-full bg-backgroundShadow flex justify-center items-center fixed z-[55] duration-300">

            <div className="w-full flex flex-col my-auto max-w-[590px] h-fit max-h-[480px] bg-white rounded-2xl shadow-lg overflow-auto p-4 px-5 space-y-2" ref={menuREf} >

                <div className=" w-full h-[50px] flex justify-between items-center">
                    <div className=" text-[#06152D] font-medium font-sans text-[22px] noselect">Invite Members</div>
                    <div onClick={() => { setShow(false) }} className=" w-[35px] h-[35px] cursor-pointer rounded-md bg-slate-100 hover:bg-slate-200 flex justify-center items-center" ><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_4293_10058)">
                            <path d="M10.6749 3.33076C10.4474 3.10326 10.0799 3.10326 9.85242 3.33076L6.99992 6.17742L4.14742 3.32492C3.91992 3.09742 3.55242 3.09742 3.32492 3.32492C3.09742 3.55242 3.09742 3.91992 3.32492 4.14742L6.17742 6.99992L3.32492 9.85242C3.09742 10.0799 3.09742 10.4474 3.32492 10.6749C3.55242 10.9024 3.91992 10.9024 4.14742 10.6749L6.99992 7.82242L9.85242 10.6749C10.0799 10.9024 10.4474 10.9024 10.6749 10.6749C10.9024 10.4474 10.9024 10.0799 10.6749 9.85242L7.82242 6.99992L10.6749 4.14742C10.8966 3.92576 10.8966 3.55242 10.6749 3.33076Z" fill="#586474" />
                        </g>
                        <defs>
                            <clipPath id="clip0_4293_10058">
                                <rect width="14" height="14" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    </div>
                </div>

                <div className="w-full flex flex-row items-center justify-center p-2 rounded-lg border-1" >
                    <div className="w-[60%]">
                        <Input value={emailId} type="email" margin='my-auto' bordercolor='border-white' Placeholder="Enter the email address" onchange={(e) => {
                            setEmail(e.target.value)

                        }} /></div>

                    <div className="w-[20%] min-w-[120px] ml-2 ">
                        <DefaultDropMenu active={activeSateInd} labelTextWeight="hidden" spaceY="" width="w-full caret-transparent" list={UserRole} onchange={ChangePermission} /></div>

                    <div className="w-[20%] ml-2">
                        <PrimaryButton icon={
                            spinIt ? <CgSpinner className=" min-w-[20px] h-5 animate-spin text-white" /> : null
                        } text={'Invite'} height="h-[90%] w-full"
                            border="border-1 border-primary" OnClick={() => { sendInvite() }}
                            disabled={spinIt} />

                    </div>

                </div>



                <div className="w-full space-y-4 px-2 overflow-hidden" >
                    <div className="flex w-full flex-col space-y-3 mt-3 " >
                        <div className="w-full  flex justify-between">
                            <p className="capitalize text-sm noselect text-[#586474]" >Workspace</p>
                            <div className="flex space-x-2" >
                                <p className="capitalize text-sm w-[150px] text-center noselect text-[#586474]" >Role</p>
                                <p className="capitalize text-sm text-center w-20 noselect text-[#586474]" >Action</p>
                            </div>
                        </div>
                        <hr />
                        <div className="flex flex-col space-y-4 mah-h-[50px] overflow-y-auto list-industry" >


                            {teamList.map((obj, inx) => {

                                let name = (obj?.email)?.split("@")

                                return (
                                    <div className="w-full  flex justify-between px-2" key={inx}>

                                        <div className="w-full flex flex-row overflow-hidden">

                                            <div className="aspect-square h-full  rounded-[50%] border-none">
                                                <img src={obj?.engage_user_profile ?? "/asset/images/Default-User.png"} alt="User" draggable="false" className='w-16 h-16 rounded-lg border-1' />

                                            </div>

                                            <div className="ml-3 w-full my-auto">
                                                <div className="text-[13px] font-bold text-[#06152D] mb-[2px] capitalize">{name[0]}</div>
                                                <div className="text-[13px] text-[#06152D]">{obj?.email}</div>
                                            </div>

                                        </div>

                                        <div className="flex space-x-2 my-auto"  >

                                            <DefaultDropMenu labelTextWeight="hidden" spaceY="" width="w-[120px] mr-2 caret-transparent" list={UserRole} active={UserRole.findIndex(l => l == obj?.role )} SelectIndex={true} onchange={(ind)=> updateMember(obj,UserRole[ind])} />
                                            <PrimaryButton text={ (delspinItINd !== inx) ?'Remove':""} width={"w-20"} icon={ (delspinIt && (delspinItINd == inx)) ? <CgSpinner className=" min-w-[20px] h-5 animate-spin text-red-300" /> : null} style={{ background: "white", color: 'red', borderColor: 'red' }} OnClick={()=>{setdelSpinItINd(inx);DeleteMember(obj)}} />

                                        </div>
                                    </div>
                                )

                            })
                            }



 



                        </div>

                    </div>
                </div>
            </div>





        </div>);
};
