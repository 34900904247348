import { scriptWithDataAttributes, useScript, useScriptOptions } from "./useScript";




export const DeepBot= ({ chatbotId, email, plan, userId, ...props }) => {

    console.log("test---------->");
    const definedAttributes = Object.fromEntries(
        Object.entries({ email, plan, "user-id": userId, "chatbot-id": chatbotId })
            .filter(([, value]) => value !== undefined)
            .map(([key, value]) => [`data-${key}`, value])
    );

    const transformedProps = Object.fromEntries(
        Object.entries(props)
            .filter(([, value]) => value !== undefined)
            .map(([key, value]) => [`data-${key}`, value])
    );

    const attributes = {
        ...transformedProps,
        ...definedAttributes,
        id: "__deepBotSdk__",
    };

    useScript("https://widget.app.deepbot.gozen.io/embedded.js", { attributes });

    if (!chatbotId) throw new Error("chatbotId is required.");

    return null;
};

// const defaultScriptOptions = {
//     attributes: {
//         id: "__deepBotSdk__",
//     },
// };

export default DeepBot;
