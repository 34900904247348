import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from ".";
import { getDashboardInfo } from "../actions/user";
import { LastModified } from "../utils/dateconvetor";
import SearchBar from "../components/input/searchbar";
import { FilterComponent } from "../components/FilterComponent";
import { categoryData } from "../content/category";
import { Crudopration } from "../components/Crudopration";
import { LoadingSpinner } from "../components/Loading";
import PrimaryButton from "../components/Button1/primarybutton";
import DefaultDropMenu from "../components/input/droplist";
import toast from "react-hot-toast";
import { SimpleCopy } from "../components/popup/SimpleCopy";
import { SimpleDelete } from "../components/popup/SimpleDelete";
import { DeleteCampaign } from "../components/dashboard/DeleteProject";
import { FaArrowCircleRight, FaBeer, FaCopy, FaTrash } from "react-icons/fa";
import { getAllCampaign } from "../actions/templates";
import { useSelectionAction } from "./customstate/context";




const CampaignList = () => {

    const {sidebarstate,setSidebarState} = useSelectionAction();
    setSidebarState(3)

    const [campaignCount, setCampaignCount] = useState(0);
    const [views, setviews] = useState(0);
    const [Respcount, setRespcount] = useState(0);
    const [campaigns, setCampaigns] = useState([]);
    const [Loading, setLoading] = useState(false)
    const CAteDAta = categoryData()
    const dropmenuList=[10,25,50,100]
    const [activeRowPerPage,setActiveRowPerPage]=useState(0)
    const [pageIndex,setPageIndex]=useState([1,10]) 
    const [rowPerPage,setRowPerPage]=useState(10)
    const [tableData,setTableData]=useState([])
    const [totalpage,setTatalpage] = useState(0)
    const [name, setName] = useState("")
    const [loadingText, setloadingText]= useState("Fetching Your Data")
    const [showCpy, setshoWCpy] = useState(false)
    const [showDel, setshoWDel]= useState(false)
    const [popNum, setPopNum] = useState()
    const[lastJsonObj, setLastObj] = useState({})


    const [loadingForDelet, setLoadingForDelte] = useState(false)


    function setDropDown(index){
        setLastObj(data[index])
        if(popNum===index)setPopNum(null)
        else setPopNum(index)        
    }

    useEffect(()=>{
    let a = window.addEventListener("click", function(e){
        const dotPlace = e?.target?.getAttribute("dotPlace")
    if(dotPlace!=="MainDot"){
        setPopNum(null)
    }
    });
    return(()=>{
        window.removeEventListener("click",a)
    })
    },[])



    const copy = () => {   
        navigator.clipboard.writeText(renderEngineurl);
        toast.success("Copied!");
        setshoWCpy(false)

    }

    const deleteCamp = async () =>{
        setshoWDel(false)
        setLoading(true)
        await DeleteCampaign(lastJsonObj?.campaign_id)
        await loadDashboard()
        setLoading(false)
    }

    const Navigate = useNavigate()

    const SortByName = () => {
        const data = [...campaigns].sort((a, b) => b.name < a.name ? 1 : -1)
        setCampaigns(data)

    }

    const SortByDate = () => {
        const data = [...campaigns].sort((a, b) => a.createdAt < b.created_at ? 1 : -1)
        setCampaigns(data)

    }

    const SortByLive = () => {
        const data = [...campaigns].sort((a) => a.status === "active" ? -1 : 1)
        setCampaigns(data)
    }

    const SortByPaused = () => {
        const data = [...campaigns].sort((a) => a.status === "paused" ? -1 : 1)
        setCampaigns(data)
    }




    useEffect(() => {
        loadDashboard()
    }, [loadingForDelet])
    const prev = () =>{ if(pageIndex[0]-rowPerPage>0 )  setPageIndex([pageIndex[0]-rowPerPage,pageIndex[1]-rowPerPage]) }

    const next = () =>{
        if( pageIndex[1]+rowPerPage < (data?.length+rowPerPage))  setPageIndex([pageIndex[0]+rowPerPage,pageIndex[1]+rowPerPage])
    }



    const LoadMore =(index) =>{
        setRowPerPage(dropmenuList[index]) // 10

        let pagecount = 0

        if((data.length % dropmenuList[index]) < dropmenuList[index] ) pagecount +=1
        let sum = Math.floor(data.length / dropmenuList[index])
        pagecount += sum
        setTatalpage(pagecount)
        // const findIndex = dropmenuList.findIndex(i => i === state)
        setActiveRowPerPage(index) 
        setPageIndex([1,dropmenuList[index]])    
    }

    const data = campaigns.filter((a) => String(a.name).toLowerCase().includes(name?.toLocaleLowerCase()))

    const renderEngineurl = process.env.REACT_APP_ENV === "development" ? `http://localhost:3400/${lastJsonObj?.campaign_id}` : process.env.REACT_APP_RENDER_ENGINE_URL+lastJsonObj?.campaign_id;

    const CURDdata = [
        {
            name: "Publish",
            icon:"" 
        },
        {
            name: "Pause",
            icon:"" 
        },
        {
            name: "Copy Link",
            icon:"" 
        },
        {
            name: "Delete",
            icon:"" 
        },
        {
            name: "Edit",
            icon:"" 
        }
    ]


    const ShowData = ({ loading }) => { 

        if (loading) return <LoadingSpinner text={loadingText} />

        return data.length > 0 ? data.map((list, index) => {
            // console.log(list)
            if((pageIndex[0]-1 <= index) && (index < pageIndex[1]+1))return (
                <div key={index} className="transition-all relative bottom-0 hover:border-primary hover:bottom-1 cursor-pointer duration-500 hover:shadow-card-sm  rounded-16px border border-border-light pl-12px pr-32px py-12px flex flex-row mt-12px animate-fadeIn h-3/4=5" style={{ boxShadow: '0px 10px 40px 0px rgba(11, 13, 63, 0.04)' }} >

                    <div className="flex-1 flex justify-between" onClick={() => Navigate(`/campaign/edit/${list?.campaign_id}`)}>
                        <div className="flex w-1/2">
                            <div className="overflow-hidden flex w-60px h-60px">
                                <img alt="icon" className="rounded-8px max-h-full object-cover m-auto" src={CAteDAta[list?.category]?.src ?? "/asset/images/template/gallery/1.png"} />
                            </div>
                            <div className="flex flex-col items-left ml-4 w-full">
                                <span className="text-primary-bold mb-6px text-16px font-medium hover:underline"  >{list.name}</span>
                                <span className="text-primary-gray font-normal text-14px">{LastModified(list.created_at)}</span>
                            </div>
                        </div>
                        <div className="flex flex-row justify-between items-center w-1/2">
                            <div className="flex w-fit space-x-1">
                                <span className={`w-2 h-2 border-1 rounded-2xl my-auto ${(list.status === 'paused') ? 'bg-yellow-400' : 'bg-green-600 '}`}></span>
                                <span className="min-w-[60px]">{list.status === 'active' ? 'Live' : list.status}</span>
                            </div>
                            {recentcontent('views', list?.view_count ?? 0)}
                            {recentcontent('clicks', list?.click_count ?? 0)}
                            {recentcontent('responses', list?.subscribe_count ?? 0)}
                        </div>
                    </div>
                    {/* <div className="flex items-center justify-center  align-middle m-auto pl-4 relative"onClick={() => setPopNum(index)}> */}
                    <div className="flex items-center justify-center  align-middle m-auto pl-4 relative" onClick={() => setDropDown(index)} dotPlace={"MainDot"}>

                        <div className="my-auto flex justify-center items-center" dotPlace={"MainDot"}>
                            <div className="text-[30px] rotate-90 hover:bg-gray-100 w-[40px] h-[40px] rounded-full flex pb-4 items-center justify-center noselect" dotPlace={"MainDot"}>...</div>
                        </div>

                        <Crudopration setshoWDel={setshoWDel} setshoWCpy={setshoWCpy}  setloadingText={setloadingText} loadDashboard={loadDashboard} setLoading={setLoading}  setPopNum={setPopNum} status={list?.status}  show={popNum === index} camId={list?.campaign_id} setLoadingForDelte={setLoadingForDelte} data={CURDdata} index={index} />
                    
                    </div>
                </div>


            )
        }) : <></>
    }



    const loadDashboard = async () => {
        setLoading(true)
        const DashboardDetail = await getAllCampaign();
        // const DashboardDetail = await getProject(param?.project_id);
        setCampaignCount(DashboardDetail.data?.Eventcount?.total_popups??0)
        setCampaigns(DashboardDetail.data ?? [])
        setviews(DashboardDetail.data?.Eventcount?.total_views??0)
        setRespcount(DashboardDetail.data?.Eventcount?.total_subscribe??0)
        setLoading(false)
    }





    const recentcontent = (title, count) => {
        return (

            <div className="flex flex-col items-center w-80px">
                <span className="text-primary-bold text-15px font-bold leading-22px mb-6px" >{count}</span>
                <span className="leading-16px text-14px font-normal text-primary-gray">{title}</span>
            </div>
        )
    }

    return (
        <>
            <Layout isactive={0}>


                <div  className={loadingForDelet ? "w-full h-full" : "w-full h-full"} >
                    <div className="flex h-auto justify-between  py-2 ">
                        <h2 className="pb-5  font-bold text-22px text-primary-bold  capitalize">Campaigns</h2>
                    </div>
                    {/* <h2 className="mb-4px font-bold text-22px text-primary-bold  capitalize">Recent Activities</h2>
                    <div className="flex w-full justify-end ">
                        <SearchBar iconposition="left" border="" />
                    </div> */}                            {
                        data.length === 0 && !Loading ?
                        <div className="w-full h-full flex flex-col justify-center  space-y-4" >
                        <div className=" flex flex-col max-h-[360px] max-w-[300px] ma mx-auto" >
                            <img src="/asset/images/emptycampaign.png" alt="Empty_campaign" className="relative h-auto max-h-[360px]" />
                        </div>
                        <p className="mx-auto flex" >Create your Campaign</p>
                        <PrimaryButton text={'Create New Campaign'} width="w-fit px-2 mx-auto" OnClick={() => Navigate('/template')} />
                    </div> :
                    <div className={`relative p-4 py-6 px-6 bg-white rounded-xl shadow-bgshadow  ${data.length > 0 ? "h-[83%]" : "h-[83%]"} `} >
                        <div className="flex bg-white py-2 sticky top-0 z-50 justify-between">
                            <SearchBar iconposition="left" border="border-2" setName={setName} />
                            <FilterComponent SortByDate={SortByDate} SortByName={SortByName} SortByLive={SortByLive} SortByPaused={SortByPaused} />

                        </div>
                        <div className="relative w-full h-[85%]  " >
                            <div className="h-[85%] overflow-y-auto list-industry">

 
                            <ShowData loading={Loading} />

                            </div>
                            <hr className="mb-0 mt-3"/>
                            <div className="stat bottom-0">
                                <div className="flex justify-end space-x-3 px-4 py-4">
                                    <div className="flex space-x-2">

                                        <p className="my-auto noselect">Rows per page :</p>
                                        <DefaultDropMenu labelTextWeight="hidden" SelectIndex={true} list={dropmenuList} active={activeRowPerPage < 0 ? 0 : activeRowPerPage} mainclass="my-auto w-20" onchange={LoadMore} />
                                    </div>
                                    <div className="flex"><p className="my-auto noselect">{pageIndex[0]}-{pageIndex[1]} of {totalpage}</p></div>

                                    <div className="flex my-auto space-x-4">
                                        <span onClick={() => { prev() }} className={`p-2 px-3 rounded-md my-auto cursor-pointer ${pageIndex[0] <= 1 ? "bg-gray-100" : ""} hover:bg-gray-200`}><svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.90969 0.710469C6.51969 0.320469 5.88969 0.320469 5.49969 0.710469L0.909687 5.30047C0.519688 5.69047 0.519688 6.32047 0.909687 6.71047L5.49969 11.3005C5.88969 11.6905 6.51969 11.6905 6.90969 11.3005C7.29969 10.9105 7.29969 10.2805 6.90969 9.89047L3.02969 6.00047L6.90969 2.12047C7.28969 1.73047 7.28969 1.09047 6.90969 0.710469Z" fill="#586474" /></svg></span>
                                        <span onClick={() => { next() }} className={`p-2 my-auto px-3 rounded-md cursor-pointer ${(pageIndex[1] > (tableData?.length + 1)) ? "bg-gray-100" : ""} hover:bg-gray-200`}><svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.29055 9.88047L5.17055 6.00047L1.29055 2.12047C0.900547 1.73047 0.900547 1.10047 1.29055 0.710469C1.68055 0.320469 2.31055 0.320469 2.70055 0.710469L7.29055 5.30047C7.68055 5.69047 7.68055 6.32047 7.29055 6.71047L2.70055 11.3005C2.31055 11.6905 1.68055 11.6905 1.29055 11.3005C0.910547 10.9105 0.900547 10.2705 1.29055 9.88047Z" fill="#586474" /></svg></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
}
                </div>

            {showCpy  && <SimpleCopy setshoWCpy={setshoWCpy} copy={copy} renderEngineurl={renderEngineurl}></SimpleCopy> }
            {showDel && <SimpleDelete setshoWDel={setshoWDel} delete={deleteCamp} />}
            </Layout>
        </>
    )
}

export default CampaignList