import React from "react";

const TextareaInput = ({
    id= "",
    name ="",
    CustomClass='',
    label='Label name',
    labelTextsize = '',
    labelTextWeight = '',
    type="text",
    Placeholder ="",
    fontweight = "",
    margin = "mb-3",
    spaceY = 'space-y-2',
    padding = "py-2",
    textvalue =null,
    disabledinput = false,
    width = "w-full",
    onchange = null,
    bgColor="bg-white",
    height="h-32",
    textsize="text-14px",
    lableColor= "#000000",
    spellcheck="true",
    border = "border border-secondar",
    ...prop
}) => {
     return (
         <div className={`${spaceY}`}>
            <label className={` ${labelTextsize} ${labelTextWeight} capitalize`} style={{color:lableColor}}>{label}</label>
            <textarea  className={`gz-basic-input ${CustomClass} ${height} block ${padding} ${textsize} text-black appearance-none  ${bgColor} outline-none  placeholder-gray-600 ${border} rounded-8px ${width} px-14px leading-5 placeholder-opacity-40 ${margin} resize-none `} id={id} name={name} placeholder={Placeholder} disabled={disabledinput} onChange = {onchange} value={textvalue} defaultValue ={textvalue} spellcheck={spellcheck}/>
         </div>
     )
}

export default TextareaInput;

