import React, { useEffect, useState } from "react";
import PrimaryButton from "../Button1/primarybutton";
import Input from "../input/input";
import Embedpostion from "../templatepreview/embedpostion";
import { Title } from "../Texts";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { EmbedPopup } from "./common/EmbedPop";
import { ColorPixerInput } from "../input/colorpixerInput";
import InputWithLabel from "../input/inputWithLabel";
import DefaultDropMenu from "../input/droplist";
import { SharePreview } from "./SharePreview";
import HandleIcon from "../templatepreview/handleicon";
import { useNavigate } from "react-router-dom";
import { isEmpty, uniqueArray } from "../../utils/helpers";
import { getUserSites } from "../../actions/site";
import { LoadingSpinner } from "../Loading";
import { Actions } from "../../store";
import { RulesSettings } from "./common/RulesSettings";
import { Tutorial } from "../Tutorial";
import { useSelectionAction } from "../../pages/customstate/context";
import { IframePopup } from "./common/IframePopup";
import { listOfIcon } from "../../content/iconList";
 

export const ShareBlock = () => {


    const { completedTutorial, engage_apptour, CD_details } = useSelector((state) => ({ completedTutorial: state.completedTutorial, engage_apptour: state.engage_apptour, CD_details: state.customDomain }))

    const [startTutorial, setStartTutorial] = useState(false);
    const { ShowPageMainContent } = useSelectionAction();
    const [listCD, setlistCD] = useState([])

    const [inlineIframeSize, setinlineIframeSize] = useState(["100%" , "450px"])
    const [showIframe, setShowIframe] = useState(false)

    const Navigate = useNavigate();
    const Dispatch = useDispatch()

    useEffect(() => {
        setTimeout(() => { setStartTutorial((!completedTutorial?.campaignEdit?.share) && engage_apptour && ShowPageMainContent) }, 100)
        return (() => {
            setStartTutorial(false)
        })
    }, [engage_apptour])


    useEffect(() => {

        Dispatch(Actions.setChangeDevice("desktop"));

        const LinkTocopyList = CD_details?.map((list) => {

            if ((list?.verify_status == 'true' && list?.type == 'custom') || (list?.type == 'sub')) return `https://${list?.type == 'sub' ? `${list?.Domain}.engage-gozen.com` : list?.Domain}/${CampaignDetail.Campaign_id}`
        })

        setlistCD(LinkTocopyList)
    }, [])

    const { Order, field } = useSelector((state) => ({ Order: state.fieldsOrder, field: state.fields }))


    const { CampaignDetail } = useSelector((state) => ({ CampaignDetail: state.Campaign_info }))
    const Camp_rule = CampaignDetail?.rule ?? {}

    useEffect(() => {

        const obj = Object.keys(Camp_rule)

        if ((!CampaignDetail?.rule) || (obj?.length <= 0)) Dispatch(Actions.setDefaultRuletoCamp())

    }, [CampaignDetail])

    const renderEngineurl = process.env.REACT_APP_ENV === "development" ? `http://localhost:3400/${CampaignDetail.Campaign_id}` : process.env.REACT_APP_RENDER_ENGINE_URL + CampaignDetail.Campaign_id;

    const previewUrl = process.env.REACT_APP_ENV === "development" ? `http://localhost:3400/preview/${CampaignDetail.Campaign_id}` : process.env.REACT_APP_RENDER_ENGINE_URL + "preview/" + CampaignDetail.Campaign_id;
    const [ActiveInd, setActive] = useState(0);
    const [ShowPopup, setShowPopup] = useState(false)
    const [btnText, setBtnText] = useState("Launch")
    const [btnBgColor, setBtnBgColor] = useState("#0063EB")
    const [btnTextColor, setBtnTextColor] = useState("#FFFFFF")
    const [popupSize, setPopupSize] = useState("Large")
    const [Side, setSide] = useState("Right")
    const [runPreviewCount, setRunPreviewCount] = useState(0)
    const [toShow, settoShow] = useState(true)
    const [decivestate, setActiveDevice] = useState('desktop')
    const [isSideWheel, setIsSideWheel] = useState(false)
    const [Loading, setLoading] = useState(false);
    const [LinkCopy, setLinkCopy] = useState(0);

    const [DomainTable, setDomainTable] = useState([])
    const [DomainList, setListDoamin] = useState(["Select Domain"])
    const [domName, setDomName] = useState("")
    const [findIndex, setfindIndex] = useState(0)
    const [showTargetSetting, setTargetSetting] = useState(false)

    const [inlineEmbedSize, setinlineEmbedSize] = useState(["100%", "450px"])
    const [sidePanelSize, setsidePanelSize] = useState(["500px", "600px"])





    const DisplayRuleStateCC = (type, value) => {

        Dispatch(Actions?.SetDRStateChange({ type, value }))

    }


    const embed = ["Inline Embed", "Full-page", "Popup", "Side Panel", "Popover", "Side Tab"]
    const embedVal = ["inlineEmbed", "fullPage", "popup", "sidePanel", "popover", "sideTab"]
    const sizeList = ["Large", "Medium", "Small"]
    const sideList = ["Right", "Left"]
    const SideInx = sideList.findIndex(list => list === Side)
    const popupSelectIndex = sizeList.findIndex(list => list === popupSize)

    useEffect(() => { FetchSiteData() }, [])

    const FetchSiteData = async () => {
        setLoading(true)
        const SiteResp = await getUserSites();
        setDomainTable([{}, ...SiteResp?.data])
        let temp = ["Select Domain"]; SiteResp.data?.map((obj) => { temp.push(obj?.domain) })
        if (SiteResp?.data?.length <= 0) temp.push("Add Domain")
        setListDoamin(temp)
        setLoading(false)
    }

    useEffect(() => {
        settoShow(true)
        setRunPreviewCount(obj => obj + 1)
    }, [ActiveInd, popupSize, Side, btnText])


    useEffect(() => {
        setTimeout(() => {
            settoShow(true)
            setRunPreviewCount(obj => obj + 1)
        }, 200)
    }, [decivestate])


    const copy = () => {
        const Arrlist = [renderEngineurl, ...listCD]
        navigator.clipboard.writeText(Arrlist[LinkCopy]);
        toast.success("Copied!")
    }


    const generateCode = () => {
        if (isEmpty(DomainList[findIndex]) || DomainList[findIndex] === "Select Domain") {
            toast.error("Kindly select the domain!")
        } else {
            setShowPopup(true)

        }
    }

    const updateSelectedDomain = (val, type) => {

        if (val === "Add Domain") Navigate('/settings?target=domain')

        else {

            setDomName(val)
            setfindIndex(DomainList.findIndex(list => list === val))

        }

    }

    const leftarrow = listOfIcon('leftarrow',14,14,'#586474');
    const desktopIcon = listOfIcon('desktop',18,18,Camp_rule?.onDesktop ? "white" : "#586474");
    const mobileIcon = listOfIcon('mobile',18,18,Camp_rule?.onMobile ? "white" : "#586474");
    const tabletIcon = listOfIcon('tablet',18,18,Camp_rule?.onTablet ? "white" : "#586474");
 

    return (
        <section className={`flex w-full h-full mx-auto`}>
            {startTutorial && <Tutorial page="share" />}

            {Loading && <div className='w-full absolute h-full bg-[rgba(250,250,250,0.3)]'>
                <LoadingSpinner text={'Please Wait...'} />
            </div>}

            {ShowPopup &&
                <EmbedPopup sidePanelSize={sidePanelSize} inlineEmbedSize={inlineEmbedSize} backwardAction={setShowPopup} CampaignId={CampaignDetail?.Campaign_id} siteId={DomainTable[findIndex]?.site_id} type={embedVal[ActiveInd]}
                    btnText={btnText} btnBgColor={btnBgColor} btnTextColor={btnTextColor} popupSize={popupSize.toLowerCase()} Side={Side.toLowerCase()} />
            }
            {
                showIframe && <IframePopup   campaign_id={CampaignDetail?.Campaign_id}
                backwardAction = {setShowIframe}
                inlineIframeSize={inlineIframeSize}
                />
            }

            {showTargetSetting &&
                <RulesSettings setTargetSetting={setTargetSetting} />

            }

            {/*preview path*/}
            <div className="w-9/12 h-full border-r-1 overflow-hidden list-industry">

                <div className="flex flex-col w-full border-1 h-full ">

                    <div className="flex justify-between py-3 px-4 border-b-1">

                        <div className="text-[20px] ">
                            <p className="my-auto font-normal flex space-x-2 ml-2 ">

                                <span className="py-2 px-3 border-2 rounded-md cursor-pointer" onClick={() => Navigate(-1)}>{leftarrow}</span>

                                <span className="my-auto " >Preview</span>

                            </p>


                        </div>

                        <div><HandleIcon activeState={setActiveDevice} /></div>
                    </div>
                    <div className={`h-full transition-all duration-500 bg-[#f9fcff] mx-auto overflow-hidden ${decivestate === 'mobile' ? 'w-[460px]' : decivestate == 'tab' ? 'w-[770px]' : 'w-full'}`}>

                        {CampaignDetail.status === "active" ?
                            <SharePreview btnText={btnText} btnBgColor={btnBgColor} btnTextColor={btnTextColor}
                                popupSize={popupSize.toLowerCase()} toShow={toShow} settoShow={settoShow} decivestate={decivestate} CampaignId={CampaignDetail?.Campaign_id} RenderUrl={previewUrl} dataType={embedVal[ActiveInd]} runPreviewCount={runPreviewCount} Side={Side.toLowerCase()} />
                            :
                            <div class="flex h-full"><div class="m-auto">  Please publish your campaign to use embed code.  </div></div>
                        }


                    </div>
                </div>

            </div>

            {/*sidebar*/}
            <div className="relative flex flex-col w-2/5 space-y-4 py-6 px-6  overflow-y-auto list-industry">

                <div className="step-share-linkToShare w-full ">
                    <Title text="Link to share" margin="mb-3" />
                    {/* <Input textvalue={renderEngineurl} disabledinput={true} id={'engageRne312n231'} padding={"py-2"} /> */}
                    <DefaultDropMenu iscapitalize="" list={[renderEngineurl, ...listCD]} active={LinkCopy} labelTextWeight="hidden" SelectIndex={true} onchange={ (ind) => setLinkCopy(ind) } />
                    <PrimaryButton text={"Copy Link"} width="w-full" height="h-50px" margin="mt-2" rounded="rounded-xl" OnClick={copy} disabled={CampaignDetail.status == "active" ? false : true} />
                </div>

                <div className="w-full h-fit space-y-4 mt-4">
                    <label className="  font-medium  capitalize">Which devices will this popup appear</label>
                    <div className="w-full flex flex-row noselect">

                        <div className={`w-[30%] rounded-md flex items-center max-w-[120px] justify-center  p-5 shadow-sm cursor-pointer ${Camp_rule?.onDesktop ? "bg-primary border-primary text-white" : "bg-white text-[#586474] border-[lightgray]"}  border-1  h-[30px] font-normal text-[14px] mr-2`} onClick={() => DisplayRuleStateCC('onDesktop', !Camp_rule?.onDesktop)} >
                            <div>{desktopIcon}</div><div className="ml-1">Desktop</div></div>

                        <div className={`w-[30%] rounded-md flex items-center max-w-[120px] justify-center  p-5 shadow-sm cursor-pointer ${Camp_rule?.onMobile ? "bg-primary border-primary text-white" : "bg-white text-[#586474] border-[lightgray]"}  border-1  h-[30px] font-normal text-[14px] mr-2`} onClick={() => DisplayRuleStateCC('onMobile', !Camp_rule?.onMobile)} >
                            <div>{mobileIcon}</div>
                            <div className="ml-1">Mobile</div></div>

                        <div className={`w-[30%] rounded-md flex items-center max-w-[120px] justify-center  p-5 shadow-sm cursor-pointer ${Camp_rule?.onTablet ? "bg-primary border-primary text-white" : "bg-white text-[#586474] border-[lightgray]"}  border-1 h-[30px] font-normal text-[14px]`} onClick={() => DisplayRuleStateCC('onTablet', !Camp_rule?.onTablet)}>
                            <div>{tabletIcon}</div>
                            <div className="ml-1">Tablet</div></div>

                    </div>

                </div>
                
                <div className="step-share-targetRules">
                    <Title text="Target Rules" margin="mb-2" />
                    <PrimaryButton text={"Edit display & targeting rules"} width="w-full" height="h-50px" rounded="rounded-xl" OnClick={() => { setTargetSetting(true) }} />
                </div>

                <div className="step-share-position w-full space-y-4">
                    {isSideWheel ? <Title text="Get the spinwheel code" /> : <Title text="Position" />}
                    <Embedpostion setActive={setActive} ActiveInd={ActiveInd} isSideWheel={isSideWheel} />
                    {(embed[ActiveInd] === "Popup") && <DefaultDropMenu active={popupSelectIndex < 0 ? 0 : popupSelectIndex} label={"Popup Size"} mainclass="flex space-x-4 justify-between items-center" labelTextWeight="font-medium" list={sizeList}
                        textValue={popupSize} onchange={(e) => { setPopupSize(e); }} />
                    }

                    {
                        embed[ActiveInd] === "Inline Embed" && <>
                            <InputWithLabel labelTextsize="font-medium" label="Embed width" mainclass="flex justify-between items-center" width="w-[40%]" textvalue={inlineEmbedSize[0]} onchange={(e) => { setinlineEmbedSize(o => [e?.target?.value, o[1]]) }} />
                            <InputWithLabel labelTextsize="font-medium" label="Embed Height" mainclass="flex justify-between items-center" width="w-[40%]" textvalue={inlineEmbedSize[1]} onchange={(e) => { setinlineEmbedSize(o => [o[0], e?.target?.value]) }} />
                        </>
                    }

                    {
                        embed[ActiveInd] === "Side Panel" && <>
                            <InputWithLabel labelTextsize="font-medium" label="Side Panel width" mainclass="flex justify-between items-center" width="w-[40%]" textvalue={sidePanelSize[0]} onchange={(e) => { setsidePanelSize(o => [e?.target?.value, o[1]]) }} />
                            <InputWithLabel labelTextsize="font-medium" label="Side Panel Height" mainclass="flex justify-between items-center" width="w-[40%]" textvalue={sidePanelSize[1]} onchange={(e) => { setsidePanelSize(o => [o[0], e?.target?.value]) }} />
                        </>
                    }

                    {(embed[ActiveInd] === "Popover" || embed[ActiveInd] === "Side Tab") && (<>
                        <DefaultDropMenu active={SideInx < 0 ? 0 : SideInx} label={"Side"} mainclass="flex space-x-4 justify-between items-center" labelTextWeight="font-medium" list={sideList}
                            textValue={Side} onchange={(e) => { setSide(e); }} />
                        <InputWithLabel labelTextsize="font-medium" label="Button text" mainclass="flex justify-between items-center" width="w-[40%]" textvalue={btnText} onchange={(e) => { setBtnText(e?.target?.value) }} />
                        <ColorPixerInput labelTextsize="font-medium" label='Button text color' mainclass="w-full justify-between" colorHex={btnTextColor} Onchange={(e) => { setBtnTextColor(e) }} />
                        <ColorPixerInput labelTextsize="font-medium" label='Button color' mainclass="w-full justify-between" colorHex={btnBgColor} Onchange={(e) => { setBtnBgColor(e) }} />
                        {/* {(embed[ActiveInd]==="Side Tab" ) && 
         <DefaultDropMenu label={'Slide direction'} mainclass="flex space-x-4 justify-between items-center" labelTextWeight="font-medium" list={["Right","Left"]} 
                 textValue={slideDriection} onchange={(e)=>{setSlideDirection(e);}}  />      
        } */}

                    </>)
                    }

                </div>



                <div className="step-share-domainAndGetCode w-full space-y-4">



                    {/* <DropMenu label="Domain" labelTextsize="font-medium" textvalue={DomainList[findIndex]}  list={DomainList} active = {findIndex < 0 ? 0 : findIndex} fieldType="none"  onchange={updateSelectedDomain} /> */}

                    <DefaultDropMenu iscapitalize="" childclass={"w-full"} label={'Domain'} mainclass="flex  mb-1  flex-col  " labelTextWeight="my-auto noselect  font-medium" list={DomainList} onchange={updateSelectedDomain} />


                    <PrimaryButton text={"Get Code"} width="w-full" height="h-50px" rounded="rounded-xl" OnClick={generateCode} />

                    
                        <InputWithLabel labelTextsize="font-medium" label="Iframe width" mainclass="flex justify-between items-center" width="w-[40%]" textvalue={inlineIframeSize[0]} onchange={(e) => { setinlineIframeSize( o => [e?.target?.value,o[1]]) }} />
                        <InputWithLabel labelTextsize="font-medium" label="Iframe Height" mainclass="flex justify-between items-center" width="w-[40%]" textvalue={inlineIframeSize[1]} onchange={(e) => { setinlineIframeSize( o => [o[0],e?.target?.value]) }} />
                        <PrimaryButton text={'Get Iframe'} width="w-full" height="h-12" backgroundColor="bg-primary-lightblue" border="border-1 border-primary" textcolor="text-primary" OnClick={()=>setShowIframe(true)}/>


                </div>


            </div>
        </section>
    )
}