import React, { useEffect, useRef, useState } from 'react'


export const SubMenu = ({  index, data, id, setSeletedProject = null }) => {


    const [ show, setShow ] = useState(false)



    const menuREf = useRef(null);

    useEffect(()=>{
        let handleAction = (event) => {

            if (menuREf.current && !menuREf.current?.contains(event.target)) {
                // alert("test-out")
                setShow(false);
            }
        };

        document.addEventListener("mousedown", handleAction);
    },[])

    // useEffect(Clickouter(menuref, setHello), [index])

    return (
        <div className="flex items-center justify-center  align-middle my-auto pl-4 relative" onClick={(e) => {e.stopPropagation();setShow(true);setSeletedProject(id)}}>

            <div className="my-auto flex justify-center items-center">
                <div className="text-[30px] rotate-90 hover:bg-gray-100 w-[40px] h-[40px] rounded-full flex pb-4 items-center justify-center noselect" >...</div>
            </div>

            <div className={`${show ? "flex" : "hidden"} flex-col  absolute bg-white border-1  rounded-lg top-4 right-5 z-20 capitalize noselect  `} ref={menuREf}>
                {
                    data.map((i, k) => <span
                        key={k}
                        className=' hover:bg-gray-100 px-6 py-2 rounded-lg'
                        onClick={ i?.event }
                    >
                        {i["name"]}
                    </span>
                    )
                }
            </div>

        </div>

    )
}
