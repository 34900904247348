import axios from "axios";
import React from "react";
import { LoadingSpinner } from "../components/Loading";

const Main = () => {


    const token = localStorage.getItem('access_token');
    
    if(!token?.length)  window.location.href='/login' ;

    else window.location.href='/dashboard';

    return (
        <section className="flex flex-col w-screen h-screen bg-primary-lightblue">

            <LoadingSpinner/>
        </section>

    );

}

export default Main;