import React, { useState } from "react";
import InputWithLabel from "./input/inputWithLabel";
import { Imageinput } from "./input/imageinput";
import InputRadiocheck from "./input/radiocheck";
import PrimaryButton from "./Button1/primarybutton";
import Input from "./input/input";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../store";
import DropMenu from "./input/dropmenu";
import DefaultDropMenu from "./input/droplist";
import { MediaPopup } from "./popup/Mediapopup";
import SwicthInput from "./input/swicth";
import { TooltipData } from "../content/toolTip";
import { listOfIcon } from "../content/iconList";
import { LogicJumpRule } from "./rule";

export const QuizContainer = ({
    Index,
    templateType="",
    fieldId = "",
    isAcitve=0,
    question = "",
    optionType = "yesorno",
    options = [],
    jumpToLeft = [],
    imgSrc = "",
    setQctiveQue=null,
    answer = "",
    required=false,
    rules = {},

}) => {

    const  {campaign} =useSelector(state =>({campaign:state.Campaign_info}));


    const [ShowCondeition, SetShowCondeition] = useState('')
    const ConditionalOperator = ['equal to', 'not equal to', 'contains', 'Does not contains']
    const Conditions = rules?.conditions ? Object.values(rules?.conditions) : [];
    const ConditionsKey = rules?.conditions ? Object.keys(rules?.conditions) : [];

    const DisPatch = useDispatch();
    const dropmenuListType = (templateType == 'poll') ? [ 'email', 'multioption', 'yesorno', "rating", "starrating", 'text' ] : [ 'email', 'multioption', 'yesorno', 'text' ]
   
    const findIndexans = options.findIndex(resData => resData === answer)
    const dropmenuList = optionType === "yesorno" ? ['Yes', 'No'] : options
    const [activeSateInd, setactiveStateInd] = useState(dropmenuListType.findIndex(resData => resData === String(optionType).toLocaleLowerCase()))
    const [activeSateAnsInd, setactiveStateAnsInd] = useState(options.findIndex(resData => String(resData).toLocaleLowerCase() === String(answer).toLocaleLowerCase()))

    const DefaultCC = (value, FID = fieldId, eage, optionIndex = 0) => {
  
        const setContent = {
            key: FID,
            node: 'game_mainpage_json',
            child: 'questions',
            edge: eage,
            QueIndex: Index,
            optionIndex: optionIndex,
            value: value,
            activeSateAnsInd
        }
        DisPatch(Actions.setQuizQuestionCC(setContent))
    }

    const DirectContentChange = (value, type, optionIndex = 0) => {


        const setContent = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_mainpage_json',
            child: "questions",
            edge: "image",
            QueIndex: Index,
            optionIndex: optionIndex,
            value: value
        }
        DisPatch(Actions.setQuizQuestionCC(setContent))

        if (ShowMedia) setShowMidia(false)

    }

    const QuesRangechange = (type) => {

        const setContent = {
            key: fieldId,
            node: 'game_mainpage_json',
            child: 'questions',
            position: Index,
            type: type
        }

        DisPatch(Actions.setQuizQuestionRA(setContent))
    }

    const RulesOption = (rulesKey, ConditionKeys = '', value) => {
        const setContent = {
            key: fieldId,
            node: 'game_mainpage_json',
            QueIndex: Index,
            child: 'questions',
            edge: 'rules',
            rulesKey: rulesKey,
            ConditionKeys: ConditionKeys,
            value: value
        }
        DisPatch(Actions.setRuleAddOptionCC(setContent))
    }

    const RuleConditionAdd = (rulesKey, ConditionKeys = '', value) => {

        const setContent = {
            key: fieldId,
            node: 'game_mainpage_json',
            QueIndex: Index,
            child: 'questions',
            edge: 'rules',
            rulesKey: rulesKey,
            ConditionKeys: ConditionKeys,
            value: value

        }

        DisPatch(Actions.setRuleAddOption(setContent))
    }

    const RemoveRCForQuestion = ( rulesKey, connection_id, pair_id = "" ) => {

        const payload = {
            key: fieldId,
            node: 'game_mainpage_json',
            QueIndex: Index,
            child: 'questions',
            edge: 'rules',
            rulesKey: rulesKey,
            connection_id,
            pair_id
        }
        DisPatch(Actions.setRemoveRuleCondistion(payload))

    }


    const toolTip = TooltipData();

    const [toggleButton, settoggleButton] = useState(optionType === "yesorno" ? false : true)
    const [ShowMedia, setShowMidia] = useState(false);


    const ChangeQuestiontype = () => {
        settoggleButton(toggleButton ? false : true)
        DefaultCC(toggleButton ? "yesorno" : "multiChoice", fieldId, 'optionType')
    }

    const setactiveScreen = (ind) => {

        let setContent = {
            screen: "main",
            index:ind
        }
        setQctiveQue(ind)
        DisPatch(Actions.setActiveQuestions(setContent))
    }

    // const OpintinChange = () => {

    // } 

    const AnsOption = ['Yes', "No"]

    const Test = (state, id) => {

        const findIndex = dropmenuListType.findIndex(resData => resData == state)

        setactiveStateInd(findIndex)
        let tst = String(state).toLocaleLowerCase()

        if(templateType==="poll"){
        
            let Payload = {type:"additional_info",value:{}}
            let Data ;
            if(String(optionType).toLocaleLowerCase() === "multioption") Data = [0,0,0,0] 
            else if(String(optionType).toLocaleLowerCase() === "rating") Data = [ 0,0,0,0,0,0,0,0,0,0 ] 
            else if(String(optionType).toLocaleLowerCase() === "starrating") Data = [0,0,0,0,0] 
            else Data= [0,0]
          
             DisPatch(Actions.setCampaignAdditional_info({
                isTypechange:true,
                key:fieldId,
                node:"pollResp",
                pollindex:Index,
                value:Data

            }))

        }

        DefaultCC(tst, fieldId, id)



    }

    const Testans = (state, id) => {

        const findIndex = dropmenuList.findIndex(resData => resData == state)

        setactiveStateAnsInd(findIndex)
        let tst = String(state).toLocaleLowerCase()

        if(templateType==="poll"){
        
            let Payload = {type:"additional_info",value:{}}
            let Data ;
            if(String(optionType).toLocaleLowerCase() === "multioption") Data = [0,0,0,0] 
            else Data= [0,0]
          
             DisPatch(Actions.setCampaignAdditional_info({
                isTypechange:true,
                key:fieldId,
                node:"pollResp",
                pollindex:Index,
                value:Data

            }))

        }

        DefaultCC(tst, fieldId, id)



    }

    const addOption = (remove=false,optionIndex=0) => {
        const setContent = {
            key: fieldId,
            node: 'game_mainpage_json',
            child: 'questions',
            edge: 'options',
            QueIndex: Index,
            optionIndex: optionIndex,
            value: 'option',
            remove:remove
        }
        DisPatch(Actions.setAddOption(setContent))
    }

    const deleteIcon = listOfIcon('delete',20,20,'red');

    return (
        <>
            {ShowMedia && <MediaPopup cancel={setShowMidia} fieldType={'image'} OnSelect={DirectContentChange} />}

            <div key={Index} className={`w-full relative flex  flex-col space-y-2 border-2  h-fit delay-400 duration-700 top-0 p-4 rounded-xl hover:shadow-md hover:-top-0 ${isAcitve === Index ?"border-primary":"cursor-pointer"}`} onClick={()=>setactiveScreen(Index)} >

                <div className="flex justify-between">
                    <p className="text-base font-medium" >Question - {Index + 1}</p>
                    <div className="flex space-x-2">
                        {
                            toolTip.map((list, index) => {
                                return (
                                    <p key={index} className="p-2 rounded-md border-1 hover:bg-gray-200 cursor-pointer" onClick={() => QuesRangechange(list.type)} >{list.icon}</p>
                                )
                            })
                        }
                    </div>
                </div>


                {/* Question Editor */}
                { (isAcitve === Index)&&  <div className="flex flex-col space-y-3">

                    {/* Question */}
                    <InputWithLabel label="Question" textvalue={question} onchange={(e) => DefaultCC(e.target.value, fieldId, 'question')} />

                    {/* Question Image */}
                    <Imageinput label="Cover image" src={imgSrc} chanaeAction={() => setShowMidia(true)} deleteSrc={()=>DefaultCC('', fieldId, 'image')} />

                    <DefaultDropMenu label={'Answer Type'} mainclass="flex space-x-4" labelTextWeight="my-auto" list={dropmenuListType} active={activeSateInd < 0 ? 0 : activeSateInd} onchange={Test} fieldType={'optionType'} />

                    {/* Question option */}
                    {(String(optionType).toLocaleLowerCase() === "yesorno" || String(optionType).toLocaleLowerCase() === "multioption") && <div className="space-y-3">

                        {String(optionType).toLocaleLowerCase() !== "yesorno" && <label className="capitalize" >Answer options </label>}

                        {
                            String(optionType).toLocaleLowerCase() !== "yesorno" &&

                            options.map((list, index) => {

                                return (

                                    <div key={index} className="w-full flex space-x-2">

                                        <Input margin={''}  textvalue={list} onchange={(e) => DefaultCC(e.target.value, fieldId, 'options', index)} />

                                        <span className="p-2 rounded-md border-1 cursor-pointer" onClick={()=>addOption(true,index)} >{ deleteIcon } </span>

                                    </div>
                                )
                            })


                        }
                    </div>}
                    
                    <PrimaryButton text={'Add Option'} width="w-28" height="h-10" backgroundColor="bg-primary-lightblue" border="border-1 border-primary" textcolor="text-primary" OnClick={()=>addOption()} />

                    {/* Question Answer If type multi choise */}
                    {(templateType!=="poll" && String(optionType).toLocaleLowerCase()!=="email" ) && <DefaultDropMenu label={'Select the answer for the option'} list={dropmenuList} active={activeSateAnsInd < 0 ? 0 : activeSateAnsInd} fieldType={'answer'} onchange={Testans} />}
                    
                    <SwicthInput  label={"Required"} labelTextsize={'text-sm'} fieldType={'required'}  defaultOPtion={true} status={required} onchange={(e) => DefaultCC(e, fieldId, 'required')}   />
                    
                    <PrimaryButton  textsize='text-14px step-edit-logicJumpBtn'  text='Logic Jump' OnClick={() => ShowCondeition === '' ? SetShowCondeition(`${Index}`) : SetShowCondeition(``)} />

                        {(ShowCondeition === `${Index}`)&&<LogicJumpRule 
                        showBlock={ShowCondeition === `${Index}`? true : false} 
                        ConditionalOperator={ConditionalOperator} 
                        Conditions={Conditions} 
                        ConditionsKey={ConditionsKey} 
                        RuleConditionAdd={RuleConditionAdd} 
                        RulesOption={RulesOption} 
                        jumpToLeft={jumpToLeft} 
                        RemoveCondition={RemoveRCForQuestion}
                        queINdex= {Index} rules={rules} 
                        />}

                </div>}
            </div>
        </>
    )
}