export const BundleFeatureList = () => {
    return({
        "free":[
            '50 views per month',
            "1 site",
            "Unlimited Campaign",
            "1 workspace",
            "File upload upto 100MB",
            "unlimited Subcribers",
            "interactive",
            "Quizzes",
            "Spin wheel",
            "Games",
            "survey",
            "5 ai questions/month",
        ],
        "basic":[
            '250 views per month',
            "1 site",
            "$9 for Every Additional Site",
            "Unlimited Campaign",
            "3 workspace",
            "File upload upto 1GB",
            "unlimited Subcribers",
            "interactive",
            "Quizzes",
            "Spin wheel",
            "Games",
            "Ecommerce",
            "survey",
            "1 user",
            "10 ai questions/month",
            "logic jump",
            "Remove the  Gozen Branding",
            "integration",
        ],
        "essential":[
            '1000 views per month',
            "1 site",
            "$9 for Every Additional Site",
            "Unlimited Campaign",
            "5 workspace",
            "File upload upto 2GB",
            "unlimited Subcribers",
            "interactive",
            "Quizzes",
            "Spin wheel",
            "Games",
            "Ecommerce",
            "poll",
            "survey",
            "3 user",
            "50 ai questions/month",
            "logic jump",
            "Remove the  Gozen Branding",
            "integration",
        ],
        "professional":[
            '5000 views per month',
            "3 site",
            "$9 for Every Additional Site",
            "Unlimited Campaign",
            "8 workspace",
            "File upload upto 3GB",
            "unlimited Subcribers",
            "interactive",
            "Quizzes",
            "Spin wheel",
            "Games",
            "Ecommerce",
            "poll",
            "calculator",
            "survey",
            "5 user",
            "100 ai questions/month",
            "logic jump",
            "Remove the  Gozen Branding",
            "integration",
        ],
        "Enterprise":[
            '10000 views per month',
            "5 site",
            "$9 for Every Additional Site",
            "Unlimited Campaign",
            "10 workspace",
            "File upload upto 5GB",
            "unlimited Subcribers",
            "interactive",
            "Quizzes",
            "Spin wheel",
            "Games",
            "Ecommerce",
            "poll",
            "calculator",
            "survey",
            "10 user",
            "500 ai questions/month",
            "logic jump",
            "Remove the  Gozen Branding",
            "integration",
        ],

    })
}