import React from "react";
import { useEffect } from "react";
import Auth from "../Auth0";
import { LoadingSpinner } from "../components/Loading";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast'

export const LoginSucsess = () => {

    const navigate = useNavigate()
    

    const onloginsuccess =async ()=>{
        try{
            const res = await Auth.handleAuthentication();
            
            if(res.error == 'unauthorized') {
                Auth.logout();
            }
            else if (res.error === "access_denied") {
                // Auth.logout();
                alert('Email not verified yet!.');
                navigate('/email/verification')
            }

            if(!res.error) navigate('/')

        

            
        }catch(err){
            Auth.logout()
        }
    }

    onloginsuccess();

    // return null;
    return (
        <section className="flex flex-col w-screen h-screen bg-primary-lightblue">

            <LoadingSpinner/>
        </section>

    )
}