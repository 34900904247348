import React from "react";
import toast from "react-hot-toast";
import PrimaryButton from "./Button1/primarybutton";
import { listOfIcon } from "../content/iconList";


export const CopyClipContent = ({
    constent = "",
    className = "",
    labelName = "LabelName",
    labelClass = "font-medium",
    mainClass = "flex-col space-y-3",
    Prop,
}) => {

    const copy = () => {
        navigator.clipboard.writeText(constent);
        toast.success("Copied!")
    }

    const CopyClip = listOfIcon('copyclip',20,20,'white')

    return (
        <div className={`flex  ${mainClass}`} >

            <label className={`capitalize ${labelClass}`} >{labelName}</label>

            <div  className={` ${className} flex  border border-[#DCE0E4] w-fit rounded-lg  `}  >

                <input type='text' className='flex py-3 px-4  rounded-l-lg min-w-[350px] h-full ' placeholder={'example.com'} value={constent} />

                <PrimaryButton text={"Copy Code"} OnClick={copy} classNames={'border-1 border-primary px-4  py-3'} rounded="rounded-r-lg" height="h-full" width="w-fit" icon={CopyClip} />

            </div>

        </div>

    )
}