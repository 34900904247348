import React, { useState, useImperativeHandle, useEffect } from "react";
import { mixArray, element, sleep, isEmpty, getImageBase64, hide } from "../../../utils/helpers";
import { getMatrixClip } from "../../../utils/canvas";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../store";

export const SlideSwapTimp = ({ obj = {}, fieldID = '', StartTime = false, GameShffleImg = false, setPreTimer = null, prview = false, setActionCount = null, GameFinsh = null, setScore = null, position = 0, setPreStartTime, gameInit, setLoadContent }) => {

  const { Fields, device } = useSelector((state) => ({ Fields: state.fields, device: state.device }));

  const DisPatch = useDispatch()
  const templateData = Fields[fieldID];
  const Settings = templateData?.filedContent
  const mainPageContent = Settings.game_mainpage_json;
  const [trigger, settrigger] = useState(false)
  const [localInit, setlocalInit] = useState(false);
  const [ switchcontent, SetSwithContent ] = useState(false)
  const [ Refreshcontent, SetRefreshContent ] = useState(false)

  const [url, setUrl] = useState("");
  const [DELTA_WIDTH, setDELTA_WIDTH] = useState(100)
  const [DELTA_HEIGHT, setDELTA_HEIGHT] = useState(100)
  
  let Payload = {};


  const MATRIX = mainPageContent?.layoutrange?.split("x"), rows = isEmpty(MATRIX) ? 0 : parseInt(MATRIX[0]), cols = isEmpty(MATRIX) ? 0 : parseInt(MATRIX[1]);
  var CDmove = 0, answerArray = [], orderArray = [], cardA = "", selected = false;



  useEffect(() => { SetGameFrame(); }, [StartTime]);

  useEffect(()=>{ if(GameShffleImg) loadBase64() },[GameShffleImg])


  useEffect(() => {


    setTimeout(async() => {

    if ((element(fieldID)?.innerHTML !== "") && StartTime) {

      await loadBase64()

      SetRefreshContent(true);

      SetSwithContent(true);

    }

  }, 450);

  }, [mainPageContent]);


  useEffect(() => {

    setTimeout(async() => {

      TriggerGameSetup();

      await loadBase64()

      SetRefreshContent(true);

      SetSwithContent(true);

    }, 450);

  }, [device])

  
  useEffect(() => { setLoadContent(false) }, []);
  
  useEffect( () => { 

    if( !switchcontent || Refreshcontent ) {

      setTimeout( () =>  generatePuzzle() , (Refreshcontent ? 10 : 100) )

      SetRefreshContent(false);

    } 
    
  }, [switchcontent, Refreshcontent]);

  
  const TriggerGameSetup = () => {
    
    setPreStartTime(true)

    gameSetup();

  }

  const SetGameFrame = async () =>{


    if (StartTime) {


      SetSwithContent(false)

      TriggerGameSetup();

      console.log("pay1",Payload);


    }

    else {

      element(fieldID).innerHTML="";

      SetSwithContent(true);
      
    }
  }



  const loadBase64 = async () => {

    let width = element(fieldID).clientWidth, height = element(fieldID).clientHeight;

    let tempDELTA_WIDTH = width / cols, tempDELTA_HEIGHT = (height / rows);

    let tempUrl = (isEmpty(mainPageContent?.image_src)) ? "" : await getImageBase64(mainPageContent?.image_src, width, height);

    setDELTA_HEIGHT(tempDELTA_HEIGHT); setDELTA_WIDTH(tempDELTA_WIDTH); setUrl(tempUrl);

  }


  const gameSetup = () => {

    setPreTimer(false); setlocalInit(false); setScore(0); setActionCount(0); 

  };

  // const delayClear = async (data) => { await sleep(data); let gameFrame = element(fieldID); gameFrame.style = `background-image:none`; gameFrame.innerHTML = ''; }

  const setShadow = (dragImage) => {
    element(dragImage).style["zIndex"] = "10";
    element(dragImage).style["border"] = "1px SOLID lightgray"; element(dragImage).style["transform"] = "scale(1.2)"; element(dragImage).className = "transition-all duration-500 shadow-xl noselect p-1 rounded-xl";
  }
  const removeShadow = (dragImage) => { element(dragImage).style["zIndex"] = "0"; element(dragImage).style["border"] = "none"; element(dragImage).style["transform"] = "scale(1)"; element(dragImage).className = "transition-all duration-500 shadow-none noselect p-1 rounded-xl"; }

  

  const generatePuzzle = async () => {

    if (!gameInit) return;

    var posX = 0, posY = 0, base64 = [];

    for (var z = 0; z < (rows * cols); z++) { orderArray.push(z); answerArray.push(z) }; orderArray = mixArray(orderArray); z = 0;

    base64 = await getMatrixClip(url, rows, cols, DELTA_WIDTH, DELTA_HEIGHT);

    console.log(base64)

    let image = new Image(); 

    image.onload = (async () => {

      z = 0; posY = 0;

      if(element(fieldID).innerHTML != "") element(fieldID).innerHTML = "";

      for (let i = 0; i < rows; i++) {

        posX = 0;

        for (let j = 0; j < cols; j++) {

          setImage(posX, posY, base64[orderArray[z]], DELTA_WIDTH, DELTA_HEIGHT, orderArray[z])

          posX += DELTA_WIDTH; z += 1;

        }

        posY += DELTA_HEIGHT;

      }

    }); 

    image.src = url;
  }


  const setImage = (posX, posY, image, DELTA_WIDTH, DELTA_HEIGHT, order) => {

    let imageObj = new Image();

    imageObj.onload = function () {

      imageObj.setAttribute("id", "dragImage" + "_" + order);
      imageObj.setAttribute("class", "transition-all duration-500 noselect p-1 rounded-xl");
      imageObj.setAttribute("draggable", false);
      imageObj.setAttribute("val", order);
      imageObj.style = ` position:absolute;width:` + DELTA_WIDTH + `px;height:` + DELTA_HEIGHT + `px;top:` + posY + `px;left:` + posX + `px;background-image:url(` + imageObj + `);`
      imageObj.onclick = function (e) { setAction("dragImage" + "_" + order) };
      element(fieldID).appendChild(imageObj)
    }; imageObj.src = image;
  }


  const setAction = (dragImage) => {
    if (dragImage === cardA) {
      removeShadow(cardA); cardA = ""; selected = false;
    } else {
      if (selected) { swapImage(cardA, dragImage); removeShadow(cardA); cardA = ""; selected = false; }
      else { setShadow(dragImage); cardA = dragImage; selected = true; }
    }
  }


  const swapImage = async (cardA, cardB) => {

    CDmove += 1; setActionCount(CDmove);
    let cardAElm = element(cardA), cardBElm = element(cardB)
    let valB = cardBElm.getAttribute("val"), valA = cardAElm.getAttribute("val"); cardBElm.setAttribute("val", valA); cardAElm.setAttribute("val", valB)
    let topA = cardAElm.style.top, leftA = cardAElm.style.left, topB = cardBElm.style.top, leftB = cardBElm.style.left;
    cardAElm.style.setProperty("top", topB, ""); cardAElm.style.setProperty("left", leftB, "");
    cardBElm.style.setProperty("top", topA, ""); cardBElm.style.setProperty("left", leftA, "");

    var isCorrect = true, tempScore = 0
    orderArray.map((val, inx) => {
      let idVal = element("dragImage" + "_" + inx).getAttribute("val")


      if (parseInt(idVal) !== parseInt(val)) {
        isCorrect = false;
      }
      if (parseInt(idVal) === parseInt(val)) {
        tempScore += 1;
      }
    })
    setScore(tempScore)
    if (isCorrect) GameFinsh(true)
  }


  return (

    <div className="w-[90%] h-[90%] flex m-auto overflow-auto" id={"gamePanel" + fieldID} onClick={(e) => { e.stopPropagation(); DisPatch(Actions.setActiveFieldside({ filedId: fieldID, index: position, ActiveScreen: "main" })) }}>
      
      {mainPageContent?.timerstatus}


      <div className={`${device === "mobile" ? "w-[100%]" : "w-[80%]"}   max-h-[100%] max-w-[800px] min-w-[250px] min-h-[250px] aspect-square transition-all duration-500  flex m-auto rounded-md relative`} id={fieldID} >

      { switchcontent && <img src={mainPageContent?.image_src} alt="" title="" className={` transition-all duration-1000 ${GameShffleImg ? "opacity-0" : "opacity-100"} w-full h-full rounded-lg`}/> }

      </div>

    </div>
  );
}; 
