import React, { useState } from 'react'
import { Summary } from './Summary'
import { Plans } from './Plans'
import { Example } from './Example'
import   { Usage }  from './Usage'




export const ShowBillingPage = ({ state, userBillingDetails={}, setActive }) => {

  

  switch (state) {
    case "Summary":
      return <Summary userBillingDetails={userBillingDetails} setActive={setActive} />

    case "Plans":
      return <Plans userBillingDetails={userBillingDetails} />

    case "Usage":
      return (<Usage featureUsage={userBillingDetails?.usage ?? {}} />)
  }
  

}
