import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import React, { useState } from "react";
import { PaymentCard } from "./Card";
import { OrderSummary } from "./OrderSummary";
import { LoadingSpinner } from "../../../Loading";

export const CheckoutPage = ({ cardInfo = {}, setCheckout = null, checkoutPlanDetail = {} }) => {

    const StripePublicKey = process.env.REACT_APP_STRIPE_API_KEY
 ?? 'pk_test_51K3RJ2GfKU7Z5ICwxyeF22Vjh8XDcv1rdx2uz5qw2v1fbW421DW2RfprjrvE1Zo0jFJnhQ6Qoq6RfXVKpjX3pU6P008CfAshph'

    const stripeKey = loadStripe(StripePublicKey)

    const [loading, setloading] = useState(false)


    return (
        <div className="flex flex-col w-full h-full px-8 py-4 space-y-4">
            {
                loading ? <LoadingSpinner className="w-full h-full" />
                    :
                    <>
                        <div className="w-3/5 mx-auto" >
                            <OrderSummary setloading={setloading} checkoutPlanDetail={checkoutPlanDetail} />
                        </div>
                        <div className="w-3/5 mx-auto" >
                            <Elements stripe={stripeKey} >
                                <PaymentCard cardInfo={cardInfo} checkoutPlanDetail={checkoutPlanDetail} />
                            </Elements>
                        </div>
                    </>
            }
        </div>
    )

}