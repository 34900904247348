import React, { useEffect, useState } from "react"
import PrimaryButton from "../Button1/primarybutton";
import DefaultDropMenu from "../input/droplist";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../store";
import toast from "react-hot-toast";

export const IntegrationTagEditor = ({
    ActiveIndex,
    selectedList = '',
    setTargetSetting = null,
    fieldsObj = [],
    list_obj = {},
    mailer

}) => {

    const Dispatch = useDispatch()

    const { fields, orderslist, integrationOnj } = useSelector((state => ({ fields: state?.fields, orderslist: state?.fieldsOrder, integrationOnj: state?.Campaign_integration })));

    const getobj = integrationOnj?.find(list => list?.mailer == mailer);

    const getlistofFields = getobj?.integration_list[selectedList];

    const [active, setactive] = useState(false);

    useEffect(() => {
        setTimeout(() => setactive(true), 10)
    }, [])

    const trigger = ( tagObj, fieldId, type, queInd ) => {

        const payload = {
            selectedList,
            ...tagObj,
            fieldId,
            type,
            mailer,
            queInd
        }

        Dispatch( Actions?.onTagconfig( payload ) )
   
    }

    const onCancel = () => {
        setactive(false);
        setTimeout( () => setTargetSetting(false),[800] ) 
    }

    return (

        <div className="fixed bottom-0 left-0  flex w-full h-full bg-[#00000040] justify-end" style={{ zIndex: '23' }}>

            <div className={`transform duration-700 delay-50 ${active ? "w-[50%]" : "w-[0%]"}  pt-[80px] h-full flex flex-col   rounded-l-lg bg-white  space-y-4 shadow-md `}>

                <div className=" w-full flex justify-between items-center border-b-1 p-4 px-6">

                    <div className=" text-[#06152D] font-medium font-sans text-[20px]  capitalize">Field and tag configuration.</div>

                    <div className="flex space-x-2" >

                        <PrimaryButton text={'Cancel'} width="w-24" buttontype="secondarybutton" OnClick={ onCancel } />

                        <PrimaryButton text={'Save'} width="w-24" OnClick={()=> { toast.success("saved");onCancel() }} />

                    </div>

                </div>

                <div className="flex flex-col space-y-3 px-6 overflow-y-auto pb-20" >

                    {
                        getlistofFields?.selectedFields?.map(list => {

                            const field_name = fields[list]?.filedContent?.filedName || fields[list]?.type;
            
                            const question_list =  fields[list]?.filedContent?.game_mainpage_json?.questions?.map( obj => obj?.question ) || fields[list]?.filedContent?.game_mainpage_json?.questionsList?.map( obj => obj?.question );
                            
                            const question_list_games =  fields[list]?.filedContent?.game_leadForm_json?.field?.map( obj => obj?.Label );

                            const final_list = ( ( fields[list]?.category == "games" ) || ( fields[list]?.category == "spinWheel" ) ) ? question_list_games : question_list;

                            const tagconfig = getlistofFields?.selectedFieldsTagconfig[list];

                            return (
                                <>
                                    <div className="" >
                                        <h5 className="font-medium capitalize text-[20px] py-4 pt-3" >{` choose the Question Field From ${ field_name }`}</h5>

                                        {
                                            fieldsObj?.map(tagObj => {

                                                let activeInd = 0;

                                                if( (tagObj?.list_id) && ( tagObj?.list_id != selectedList ) )  return null ; 

                                                if (tagconfig[tagObj?.field_id]) {

                                                    const CategoryType = ( fields[list]?.category == 'calculater' ) ? "questionsList" : "questions";


                                                    const question_list_find =  fields[list]?.filedContent?.game_mainpage_json[CategoryType]?.findIndex( obj => obj?.questionId == tagconfig[tagObj?.field_id].connection_id );
                                                    const question_list_games_find =  fields[list]?.filedContent?.game_leadForm_json?.field?.findIndex( obj => obj?.questionId  == tagconfig[tagObj?.field_id].connection_id );
                                                    console.log(question_list_games_find, fields[list]?.filedContent?.game_leadForm_json.field)

                                                    activeInd = ( ( fields[list]?.category == "games" ) || ( fields[list]?.category == "spinWheel" ) ) ? question_list_games_find + 1 : question_list_find + 1
                                                }


                                                return (
                                                    <div className="flex space-x-4 w-full px-8" >
                                                        <label className="my-auto min-w-[200px]" >{tagObj?.name}</label>
                                                        <DefaultDropMenu labelTextWeight="hidden" active={activeInd}  mainclass={'w-full relative'} list={["Choose Question or Form Field",...final_list]} SelectIndex={true} onchange={(ind) => trigger( tagObj, list, fields[list]?.category, (ind-1) )} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <hr />
                                </>
                            )

                        })
                    }

                </div>

            </div>

        </div>
    )

}