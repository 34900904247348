import React, { useEffect, useRef, useState } from "react";
import PrimaryButton from "../Button1/primarybutton";
import Input from "../input/input";
import { Clickouter } from "../../utils/clickouter";
import { CgSpinner } from "react-icons/cg";
import InputWithLabel from "../input/inputWithLabel";
import DefaultDropMenu from "../input/droplist";
import { useSelector } from "react-redux";
import SwicthInput from "../input/swicth";
import { getTeamMemberData, inviteEngageTeamMember, updateTeamMemberDAta } from "../../actions/teamMember";
import toast from "react-hot-toast";
import { LoadingSpinner } from "../Loading";


export const UpdateTeamMemberSec = ({
    title = 'update Team Member',
    id,
    forwardAction = null,
    backwardAction = null,
    forwardActiontext = 'Update',
    className = "",
}) => {

    const { WorkSpace } = useSelector(state => ({ WorkSpace: state?.projects }))

    let engage_permissions = ["admin", "editor", "viewAndliveCampaign"]

    const UserRole = ['admin', 'editor', 'view only',]


    const [activeRole, setActiveRole] = useState(0);

    const [loadingLive, setLoadingLive] = useState(false);

    const [loading, setLoading] = useState(false);

    const [Premission, setPremission] = useState([])

    const [full_name, setFullname] = useState("")

    const [Email, setEmail] = useState("")
    const [PremissionRole, setPremissionRole] = useState([])


    useEffect(() => {
        FetchUserData()
    }, [id])

    const FetchUserData = async () => {

        setLoading(true)

        const getTeamMemeber = await getTeamMemberData({ id })

        if (getTeamMemeber?.success) {

            const getEngage_permissions = JSON.parse(getTeamMemeber?.data?.permissions)

            setFullname(getTeamMemeber?.data?.full_name)

            setEmail(getTeamMemeber?.data?.email)

            const getINdex = engage_permissions.findIndex(list => list == getEngage_permissions?.role)

            if (getINdex > -1) setActiveRole(getINdex)


            setPremission(getEngage_permissions?.workspace)

            WorkSpace?.map( (list, index) => {
                // console.log("test")

                const isExist = getEngage_permissions?.workspace?.findIndex(listobj => list?.project_id === listobj?.project_id)

                const listRole = getEngage_permissions?.workspace[isExist]?.role ?? 'admin';

                setPremissionRole(obj => [...obj,listRole] );
            })


        }

        else toast.error('Can\'t retrive The Team Member Data.')

        setLoading(false)

    }

    // useEffect(() => {
    //     if (activeRole == 0) {

    //         const AllPremistion = WorkSpace.map(list => ({ project_name: list?.project_name, project_id: list?.project_id }))
    //         setPremission(AllPremistion)

    //     }
    // }, [activeRole, WorkSpace])

    const ManagePermission = (role, project_id,index) => {

        let projectObj = [...Premission]
        let projectObjrole = [...PremissionRole]

        projectObjrole[index] = role 
        
        const allReadyExist = Premission?.findIndex(list => list?.project_id === project_id)

        if (allReadyExist > -1) {

            projectObj[allReadyExist].role = role
            setPremission(projectObj)

        }

        // setPremissionRole(projectObjrole)


    }

    const AccessChange = (index, state) => {

        let projectObj = [...Premission]

        // console.log(projectObj)

        const { project_name, project_id } = WorkSpace[index]

        const allReadyExist = Premission?.findIndex(list => list?.project_id === project_id)

        if (allReadyExist < 0 && state) {

            if ((Premission.length + 1) == WorkSpace?.length) setActiveRole(0)

            setPremission(obj => [...obj, { project_name, project_id, role: PremissionRole[index] }])

        }
        else if (allReadyExist > -1 && !state) {

            projectObj.splice(allReadyExist, 1)
            setPremission(projectObj)

        }

    }

    const UpdateAction = async () => {


        setLoadingLive(true)

        let engage_permissions = ["admin", "editor", "viewAndliveCampaign"]

        const InviteTeamMember = await updateTeamMemberDAta({ email: Email, full_name, engage_permissions: { role: engage_permissions[activeRole], workspace: Premission }, id })

        if (InviteTeamMember?.success) toast.success('Team Member Successfully updated')

        setLoadingLive(false)

    }

    const menuREf = useRef(null);

    useEffect(() => {

        let handleAction = (event) => {

            if (menuREf.current && !menuREf.current?.contains(event.target)) {
                backwardAction(false);
            }
        };

        document.addEventListener("mousedown", handleAction);

    }, [])

    return (

        <div className="fixed top-0 left-0 flex w-full h-full bg-backgroundShadow" style={{ zIndex: '55' }}>

            <div className={`relative w-[550px] h-fit min-h-[200px] flex justify-center m-auto rounded-lg bg-white space-y-4   ${className} `} ref={menuREf} >
                {
                    loading ? <LoadingSpinner className="my-auto" /> :

                        <div className="w-full flex flex-col space-y-2 p-3" >

                            <div className="w-full space-y-4 px-4" >
                                <h4 className="font-medium text-[16px]" >Manage Premission</h4>
                                <div className="flex w-full  flex-col space-y-3 " >
                                    <div className="w-full  flex justify-between">
                                        <p className="capitalize text-sm" >Workspace</p>
                                        <div className="flex space-x-2" >
                                            <p className="capitalize text-sm w-[150px] text-center" >role</p>
                                            <p className="capitalize text-sm text-center w-[60px]" >Access</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="flex flex-col space-y-4 max-h-[650px] overflow-y-auto list-industry" style={{ zIndex:"9999" }} >
                                        {
                                            WorkSpace?.map( (list, index) => {

                                                const isExist = Premission?.findIndex(listobj => list?.project_id === listobj?.project_id)

                                                const listRole = Premission[isExist]?.role ?? 'admin';

                                                const CurRole = UserRole?.findIndex(listObj => listRole === listObj)

                                                return (
                                                    <div className="w-full  flex justify-between">
                                                        <p className="capitalize w-[150px] text-ellipsis whitespace-nowrap" >{list?.project_name}</p>
                                                        <div className="flex space-x-2"  >
                                                            <DefaultDropMenu labelTextWeight="hidden" spaceY="" width="w-[140px]" list={UserRole} active={ CurRole } SelectIndex={true} onchange={(ind) => {  ManagePermission(UserRole[ind], list?.project_id, index) }} />
                                                            <SwicthInput labelTextWeight='hidden' margin="my-auto" defaultOPtion={true} onchange={(val) => AccessChange(index, val)} status={(isExist > -1) ? true : false} label="" />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <hr />
                                </div>
                            </div>

                            <div className="flex  justify-end space-x-2">
                                <PrimaryButton buttontype={'secondarybutton'} disabled={loadingLive} width={'w-24'} text={'cancel'} OnClick={() => backwardAction(false)} />
                                <PrimaryButton icon={
                                    loadingLive ? <CgSpinner className="w-5 h-5 animate-spin text-white" /> : null
                                } text={"Update"} disabled={loadingLive} width={'w-24'} OnClick={UpdateAction} />
                            </div>
                        </div>
                }
            </div>
        </div>

    )
}