import React from "react";
import Auth from "../Auth0";
import DefaultHeader from "../components/header/defaultheader";
import LoadContentpop from "../components/load";
import { LoadingSpinner } from "../components/Loading";
export const Logout = () => {
    Auth.logout()
    return (
        <div className="h-screen w-screen overflow-hidden">
            <DefaultHeader Createcamp={false} />
            <div className="flex absolute bottom-0 top-[77px] left-0 right-0 ">

            <LoadingSpinner/>

            </div>
        </div>
    );
} 