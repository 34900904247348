import { isEmpty, isEqual } from "../../utils/helpers";
import { dashboard_flow } from "./dashboard";
import { addNewField_flow, backGroundImage_flow, device_flow, editDesignBtn_flow } from "./design";
import { calFormulaBtn_flow, filterBtn_flow, logicJumpBtn_flow, threeScreenEditTab_flow, twoScreenEditTab_flow } from "./editScreen";
import { product_flow } from "./product";
import { share_flow } from "./share";
import { generateAiBtn_flow, templateGalary_flow } from "./templateGalary";
import { templatePreview_flow } from "./templatePreview";
import { topnave_flow } from "./topnave";


export function getTutorialFlow(name=""){
    if(isEmpty(name)) return [];

    if(name==="dashboard") return dashboard_flow || []
    if(name==="product") return product_flow || []
    if(name==="share") return share_flow || []
    if(name==="templateGalary") return templateGalary_flow || []
    if(name==="templatePreview") return templatePreview_flow || []
    if(name==="topnave") return topnave_flow || []

    if(name==="device") return device_flow || []
    if(name==="backGroundImage") return backGroundImage_flow || []
    if(name==="addNewField") return addNewField_flow || []
    if(name==="editDesignBtn") return editDesignBtn_flow || []
    if(name ==="generateAiBtn") return generateAiBtn_flow || []


    if(name ==="threeScreenEditTab") return threeScreenEditTab_flow || []
    if(name ==="twoScreenEditTab") return twoScreenEditTab_flow || []
    if(name === "calFormulaBtn") return calFormulaBtn_flow || []
    if(name === "logicJumpBtn") return logicJumpBtn_flow || []
    if(name === "filterBtn") return filterBtn_flow || []
 
}

export function isTopNave(name){
    if(isEqual(name, ".step-tab-product") || isEqual(name, ".step-tab-design") || isEqual(name, ".step-tab-integration") ||
    isEqual(name, ".step-tab-result") ||isEqual(name, ".step-tab-share") ||
    isEqual(name, ".step-tab-previewBtn") ||isEqual(name, ".step-tab-publishBtn") ) return true
     else return false
}