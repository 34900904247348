import React, { useState, useEffect, useRef } from "react";
import { element } from "../../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../store";
import { EditorTooltip } from "../../Editor/common/editortooltip";

export const SpinWheelTemp = ({ obj = {}, fieldID = '', StartTime = false, prview = false, setActionCount = null, GameFinsh = null ,position=0, toShowTips=false, setTriggerField=null}) => {

    const { Fields, device } = useSelector((state) => ({ Fields: state.fields, device: state.device }));

    const DisPatch = useDispatch()
    const templateData = Fields[fieldID];
    const Settings = templateData?.filedContent
    const mainPageContent = Settings.game_mainpage_json; 
    const BrandLabel = Settings?.brandLabel ?? true
    const [spinTrigger, setSpinTrigger] = useState(0) 
    const  [LoadContent, setLoadContent] = useState(false)
    const frameRef = useRef(null)

    useEffect(() => {gameSetup()  }, [])
    useEffect(()=>{ gameSetup()},[mainPageContent,spinTrigger]) 

    useEffect(()=>{
       element(fieldID).style.opacity=0;
      setTimeout(()=>{ gameSetup(); },350) 
    },[device])
    
    const gameSetup = async () =>{ 
      setLoadContent(true)
      setWith()
      // postMessage()
    } 
      
    function setWith(){
      element(fieldID).style.opacity=0;
      let panelWidth=element(fieldID).clientWidth;
      element(fieldID).height=panelWidth+"px";
      frameRef.current.width=panelWidth+"px";
      frameRef.current.height=(panelWidth+10)+"px";  
      postMessage();
      element(fieldID).style.opacity=1;      

    }

    const iframeLoaded =()=>{
      setWith()
      setLoadContent(false) 
    }

    const postMessage = ()=>{
      // element("gamePanel"+fieldID).style.display="none"; 
      //  element(fieldID).style.opacity=0;
      setTimeout(()=>{
      var frame=element("spinIframe"+fieldID);
      if(frame  !=null){
        // frame.contentWindow.Settings = mainPageContent;
        // frame.contentWindow.fieldID = fieldID;
        frameRef.current.contentWindow.Settings= mainPageContent;
        frameRef.current.contentWindow.fieldID = fieldID;
        setTimeout(()=>{
          frameRef.current.contentWindow.postMessage("initSpinTheWheel", "*");
          //  element(fieldID).style.opacity=1;
        },100)
      }
      },100) 
    }

    return (
      <div id={`gameFrameBorder${fieldID}`} className="w-full h-full relative">
      <span className="relative flex group duration-300  " id={"gamePanel"+fieldID} onClick={(e)=>{e.stopPropagation();DisPatch(Actions.setActiveFieldside({filedId:fieldID,index:position,ActiveScreen:"main"}))}} style={{ display:"block", background: Settings?.background?.type === "image" ? `url(${ Settings?.background?.src ??"/asset/images/quizzes/background.png"}) 0% 0% / 100% 100% no-repeat` : Settings?.background?.color ?? "#FFFFFF"}}>
      {toShowTips && <EditorTooltip position={position} setTriggerField={setTriggerField} /> }
        
        <center>
          <div className="  duration-300  w-full  lg:w-[90%]  xl:w-[65%]  inner-frame  relative "  id={fieldID}> 
             <center> <iframe className=" duration-300" onLoad={iframeLoaded} title="Spin Wheel" key={fieldID+device+StartTime+spinTrigger} id={"spinIframe" + fieldID} ref={frameRef}  src={(mainPageContent?.spinWheelType==="sideTab"? "/asset/game/SpinWheel/sideTab.html" : "/asset/game/SpinWheel/default.html")}></iframe>  </center>
          </div>
        </center>
        { BrandLabel &&
        <div className="w-full  flex  right-[3px] " >
            <div className="relative mx-auto w-[100%] px-1 sm:px-2 flex flex-wrap justify-end " >
                <div className="flex w-fit flex-col space-y-2  mb-5 bg-white rounded-lg py-1 sm:py-2 h-fit px-2 sm:px-4 my-auto shadow-sm">
                    <p className="flex flex-row space-x-1 sm:space-x-2 text-[12px] leading-[17px] sm:text-[14px] sm:leading-[20px] m-auto"><span className="my-auto">Powered By</span> <span className="font-bold my-auto text-base">GoZen</span></p>
                </div>
            </div>
        </div> 
          }
        
    </span>
    </div>
    ); 
}; 
