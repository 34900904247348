import React, { useEffect, useRef, useState } from "react";
import { Clickouter } from "../../utils/clickouter";
import { filterFromArray, getDivId, isNotEmpty } from "../../utils/helpers";
import { isEmpty } from "lodash";
import WebFont from 'webfontloader'
import { useCallback } from "react";

const FontInput = ({
  id = "",
  mainclass = "",
  active = 0,
  activeVal = "ABeeZee",
  fieldType = "",
  list = ["left", "right"],
  name = "",
  label = "Label name",
  labelTextsize = "",
  labelTextWeight = "",
  type = "text",
  Placeholder = "",
  fontweight = "",
  margin = "mb-0",
  spaceY = "space-y-2",
  padding = "",
  textvalue = null,
  disabledinput = false,
  width = "w-full",
  onchange = null,
  height = "h-10",
  iconposition = "left",
  ...prop
}) => {


  if (isEmpty(activeVal)) activeVal = "ABeeZee"
  //   const [selectoption, setSelectoption] = useState(list[active]);
  const [selectoption, setSelectoption] = useState(activeVal);

  const [DropMenuState, setDropMenuState] = useState(false);
  const [searchVal, setSearchVal] = useState("");

  const [loadedFont, setLoadedFont] = useState([])
  const [uiArray, setUiArray] = useState(list.slice(0, 25))
  const [toLoad, setToLoad] = useState(list.slice(0, 25))
  const [serInx, setSerInx] = useState(25)
  const [DIVID, setDivid] = useState(getDivId())
  const observer = useRef()
  const observer2 = useRef()



  const loadMore = useCallback((elements) => {

    if (observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver((elements) => {

      if (elements[0].isIntersecting && uiArray?.length < 1600)
        setTimeout(() => setSerInx(o => o + 25), 0)

    })

    if (elements) observer.current.observe(elements)

  }, [])



  const clearLoad = useCallback((elements) => {

    observer2.current = new IntersectionObserver((elements) => {
      setTimeout(() => setSerInx(25), 0)
    })
    if (elements) observer2.current.observe(elements)
  }, [])







  useEffect(() => {
    let temp, toLoadTemp = [];
    if (isNotEmpty(searchVal)) {

      temp = filterFromArray(searchVal, list)
      temp = temp.slice(0, serInx)
      setUiArray(temp)

      temp?.map((val) => {
        if (!loadedFont.includes(val)) toLoadTemp.push(val)
      })
      setToLoad(toLoadTemp)
    } else {

      temp = list.slice(0, serInx)
      temp?.map((val) => {
        if (!loadedFont.includes(val)) toLoadTemp.push(val)
      })
      setUiArray(temp)
    }


  }, [searchVal, serInx])

  useEffect(() => {
    let temp = loadedFont.concat(toLoad)
    setLoadedFont(temp)
    if (toLoad?.length > 0) WebFont.load({ google: { families: toLoad } })
  }, [toLoad])

  const triggerClick = (state) => {
    setSelectoption(state);
    setDropMenuState(false);
    onchange(state, fieldType);
    setSearchVal("")
  };


  const menuref = useRef();
  useEffect(() => {
    let handleAction = (event) => {

      if (menuref.current && !menuref.current?.contains(event.target)) {
        // alert("test-out")
        setDropMenuState(false);
      }
    };

    document.addEventListener("mousedown", handleAction);
  }, [])

  return (
    <div className={`${spaceY} ${mainclass}`}>
      <label className={` ${labelTextsize} ${labelTextWeight} capitalize`}> {label} </label>
      <div className="relative">
        <div class={` flex justify-end items-center relative  ${width}`}>
          <input
            autoComplete="off" style={{ fontFamily: selectoption, caretColor: "transparent" }} className={`gz-basic-input ${height} block ${padding} text-14px text-black appearance-none bg-white ${DropMenuState ? "rounded-b-none" : ""}  focus:border-primary  placeholder-gray-600 border rounded-8px ${width} px-14px leading-5 placeholder-opacity-40 ${margin} cursor-pointer border-secondar`}
            id={id}
            name={name}
            onClick={() => setDropMenuState(true)}
            placeholder={Placeholder}
            disabled={disabledinput}
            value={selectoption}
          />

          <svg className="absolute mr-2 w-10" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2097_16399)">
              <path d="M7.98417 10.7345L10.3583 13.1087C10.7158 13.4662 11.2933 13.4662 11.6508 13.1087L14.025 10.7345C14.6025 10.157 14.19 9.16699 13.3742 9.16699H8.62584C7.81001 9.16699 7.40667 10.157 7.98417 10.7345Z" fill="#586474" />
            </g>
            <defs>
              <clipPath id="clip0_2097_16399">
                <rect width="19" height="19" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        {/* <>
<div className="my-auto mx-auto ">
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.8067 12.86L11.54 10.6C12.2713 9.6683 12.6681 8.51777 12.6667 7.33333C12.6667 6.2785 12.3539 5.24736 11.7678 4.37029C11.1818 3.49323 10.3489 2.80965 9.37431 2.40598C8.39977 2.00231 7.32742 1.89669 6.29285 2.10248C5.25829 2.30827 4.30798 2.81622 3.5621 3.5621C2.81622 4.30798 2.30827 5.25829 2.10248 6.29285C1.89669 7.32742 2.00231 8.39977 2.40598 9.37431C2.80965 10.3489 3.49323 11.1818 4.37029 11.7678C5.24736 12.3539 6.2785 12.6667 7.33333 12.6667C8.51777 12.6681 9.6683 12.2713 10.6 11.54L12.86 13.8067C12.922 13.8692 12.9957 13.9187 13.077 13.9526C13.1582 13.9864 13.2453 14.0039 13.3333 14.0039C13.4213 14.0039 13.5085 13.9864 13.5897 13.9526C13.671 13.9187 13.7447 13.8692 13.8067 13.8067C13.8692 13.7447 13.9187 13.671 13.9526 13.5897C13.9864 13.5085 14.0039 13.4213 14.0039 13.3333C14.0039 13.2453 13.9864 13.1582 13.9526 13.077C13.9187 12.9957 13.8692 12.922 13.8067 12.86ZM3.33334 7.33333C3.33334 6.54221 3.56793 5.76885 4.00746 5.11105C4.44698 4.45326 5.0717 3.94057 5.8026 3.63782C6.53351 3.33507 7.33777 3.25585 8.1137 3.41019C8.88962 3.56454 9.60235 3.9455 10.1618 4.50491C10.7212 5.06432 11.1021 5.77705 11.2565 6.55297C11.4108 7.3289 11.3316 8.13316 11.0289 8.86407C10.7261 9.59497 10.2134 10.2197 9.55562 10.6592C8.89782 11.0987 8.12446 11.3333 7.33333 11.3333C6.27247 11.3333 5.25505 10.9119 4.50491 10.1618C3.75476 9.41162 3.33334 8.3942 3.33334 7.33333Z" fill="#586474"/>
</svg>
</div>
<input type={type} className={`gz-basic-input ${height} block ${padding} text-14px text-black appearance-none bg-white ${DropMenuState?'rounded-b-none':''}  focus:border-primary  placeholder-gray-600 border rounded-8px ${width} px-14px leading-5 placeholder-opacity-40 ${margin} cursor-pointer border-secondar`} id={id} name={name} onClick={() => setDropMenuState(true)} placeholder={Placeholder} disabled={disabledinput}  value={selectoption} />
</>    */}
        {DropMenuState && (
          <div className="  w-full border-1 top-0 rounded-b-md border-t-0  " ref={menuref}   >
            <center>
              <div class={`  flex justify-end items-center relative  w-[98.5%] mt-[5px] ml-[-4px]`}  >
                <input
                  type="text"
                  className={`gz-basic-input ${height} block ${padding} text-14px text-black appearance-none bg-white  focus:border-primary  placeholder-gray-600 border rounded-8px w-[98.5%] px-14px leading-5 placeholder-opacity-40  border-secondar`}
                  placeholder={"Type here to search..."}
                  value={searchVal}
                  onChange={(e) => {
                    setSearchVal(e?.target?.value);
                  }}
                />
                <svg className="absolute mr-2 w-10" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" ><path d="M13.8067 12.86L11.54 10.6C12.2713 9.6683 12.6681 8.51777 12.6667 7.33333C12.6667 6.2785 12.3539 5.24736 11.7678 4.37029C11.1818 3.49323 10.3489 2.80965 9.37431 2.40598C8.39977 2.00231 7.32742 1.89669 6.29285 2.10248C5.25829 2.30827 4.30798 2.81622 3.5621 3.5621C2.81622 4.30798 2.30827 5.25829 2.10248 6.29285C1.89669 7.32742 2.00231 8.39977 2.40598 9.37431C2.80965 10.3489 3.49323 11.1818 4.37029 11.7678C5.24736 12.3539 6.2785 12.6667 7.33333 12.6667C8.51777 12.6681 9.6683 12.2713 10.6 11.54L12.86 13.8067C12.922 13.8692 12.9957 13.9187 13.077 13.9526C13.1582 13.9864 13.2453 14.0039 13.3333 14.0039C13.4213 14.0039 13.5085 13.9864 13.5897 13.9526C13.671 13.9187 13.7447 13.8692 13.8067 13.8067C13.8692 13.7447 13.9187 13.671 13.9526 13.5897C13.9864 13.5085 14.0039 13.4213 14.0039 13.3333C14.0039 13.2453 13.9864 13.1582 13.9526 13.077C13.9187 12.9957 13.8692 12.922 13.8067 12.86ZM3.33334 7.33333C3.33334 6.54221 3.56793 5.76885 4.00746 5.11105C4.44698 4.45326 5.0717 3.94057 5.8026 3.63782C6.53351 3.33507 7.33777 3.25585 8.1137 3.41019C8.88962 3.56454 9.60235 3.9455 10.1618 4.50491C10.7212 5.06432 11.1021 5.77705 11.2565 6.55297C11.4108 7.3289 11.3316 8.13316 11.0289 8.86407C10.7261 9.59497 10.2134 10.2197 9.55562 10.6592C8.89782 11.0987 8.12446 11.3333 7.33333 11.3333C6.27247 11.3333 5.25505 10.9119 4.50491 10.1618C3.75476 9.41162 3.33334 8.3942 3.33334 7.33333Z" fill="#586474" /> </svg>
              </div>
            </center>
            <div className="overflow-y-auto overflow-x-auto divScroll max-h-[200px]" id={"OBS" + DIVID}>
              {uiArray.map((val, index) => {
                if (index === uiArray?.length - 6) {

                  return (<p
                    style={{ fontFamily: val }}
                    ref={loadMore}

                    key={index} className=" noselect w-full px-4 py-3 hover:bg-gray-100 cursor-pointer" onClick={() => triggerClick(val)} >
                    {val} </p>);
                } else if (index === 4) {
                  return (<p
                    style={{ fontFamily: val }}

                    ref={clearLoad}

                    key={index} className=" noselect w-full px-4 py-3 hover:bg-gray-100 cursor-pointer" onClick={() => triggerClick(val)} >
                    {val} </p>);

                } else {
                  return (<p
                    style={{ fontFamily: val }}

                    key={index} className=" noselect w-full px-4 py-3 hover:bg-gray-100 cursor-pointer" onClick={() => triggerClick(val)} >
                    {val} </p>);

                }

              })}
              {uiArray?.length === 0 && (<p className=" noselect w-full px-4 py-3 hover:bg-gray-100 cursor-pointer">No results found</p>)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default FontInput;
