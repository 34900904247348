import React from 'react'
import PropTypes from "prop-types";

const TabHeading = function TabHeading({ text }) {
    return (
        <h3 className="text-lg text-gray-700 antialiased font-semibold ">{text}</h3>
    )
}


TabHeading.prototype = {
    text: PropTypes.string
}

export { TabHeading }