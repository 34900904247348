import React from 'react'
import InputWithLabel from '../input/inputWithLabel'
import PrimaryButton from '../Button1/primarybutton'
import { sendResetpasswordMail } from '../../actions/user'
import toast from 'react-hot-toast'
import { CgSpinner } from 'react-icons/cg'
import { useState } from 'react'

export const SendEmailVerificationToken =  ({ title = "Reset password", type = "resetpassword" }) => {

    const [ EmailData, setEmailData ] = useState('') 

    const [ loading, setloading ] = useState(false)

    const SendVerifiCationMail = async () => {

        setloading(true)

        const ResetEmail = await sendResetpasswordMail( EmailData, type)

        if ( ResetEmail?.status ) toast?.success(ResetEmail?.data)

        setloading(false)

    }

    return (

        <section className='w-1/2 flex h-full' >

            <div className="w-9/12 flex flex-col space-y-4 p-11 py-14 2xl:py-18 bg-white rounded-xl my-auto" >

                <h3 className='font-medium' >{title}</h3>

                <InputWithLabel Placeholder='Email' label='Email' onchange={(e)=>setEmailData(e.target.value)} />

                <PrimaryButton text={'Send a verification mail'} icon={
                        loading ? <CgSpinner className="w-5 h-5 animate-spin text-white" /> : null
                    } disabled={ loading } OnClick={ SendVerifiCationMail } />

            </div>

        </section>
    )

}