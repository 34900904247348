import React, { useEffect, useRef, useState } from "react";
import PrimaryButton from "../Button1/primarybutton";
import DefaultDropMenu from "../input/droplist";

export const ChooseLayout = ({ position = "fixed", className = "", cancel=null }) => {

    const menuREf = useRef();

    useEffect(()=>{

        let handleAction = (event) => {

            if (menuREf.current && !menuREf.current?.contains(event.target)) {
               
                cancel(false);
            }

        };
    
        document.addEventListener("mousedown", handleAction);

    },[]);

    const menuContent = ['start Screen','Main Screen','Result Screen'];

    const spaceBetween = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ];

    const StartMenu = [ 
        {
            name:"Full Page",
            type:"fullpage",
            icon:""
        },
        {
            name:"Popup",
            type:"Popup",
            icon:""
        },
        {
            name:"Side Shown",
            type:"sideshown",
            icon:""
        },
    ] ;

    const [ activeMenu, setActiveMenu ] = useState(0);

    return (
        <div className={`${position} flex bg-black bg-opacity-5 w-full h-full shadow-lg top-[77px] left-0 overflow-hidden list-industry ${className}`} style={{ zIndex: '170' }}>

            <div className="relative bg-white w-[70%] h-[70%]  flex flex-col border-1 p-3 rounded-lg m-auto mt-10" ref={menuREf} >

                <div className="w-full grid grid-cols-3" >

                    {
                        menuContent.map( ( obj, ind ) =>{

                            return( <p className={`justify-center py-2 border-b-1 text-center capitalize ${ (ind == activeMenu ) ? 'text-primary border-primary' :'' } `} onClick={()=>setActiveMenu(ind)} >{obj}</p> );

                        } )
                    }

                </div>

                <div className="w-full h-full flex  my-2" >

                    <div className="w-[23%]  space-y-2 px-2" >
                        {
                            StartMenu.map( list =>{
                                return(
                                    <p className="w-full p-2 rounded-lg border-1 text-[12px] bg-white" >
                                        <span>{list.name}</span>
                                        <span>{list.icon}</span>
                                    </p>
                                )
                            } )
                        }
                    </div>
                    <div className="w-[54%] bg-green-500" ></div>

                    <div className="w-[23%]  space-y-2 px-2" >

                        <DefaultDropMenu mainclass="flex justify-between" width={'w-20'} labelTextWeight="my-auto" spaceY="text-sm" label="blocks Space" list={spaceBetween} active={2} SelectIndex={true}  />

                        

                    </div>

                </div>

                <div className="w-full h-auto flex justify-end border-t-1 pt-2" >

                    <PrimaryButton text={'Save'} />

                </div>

            </div>

        </div>

    );

}