import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getUserPaymentSession } from "../actions/Billing";
import { LoadingSpinner } from "../components/Loading";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

export const PaymentCheckoutPage = ({ start = true }) => {



    const [ searchParams ] = useSearchParams();

    const StripePublicKey = process.env.REACT_APP_STRIPE_API_KEY;
    const stripeKey = loadStripe(StripePublicKey);
    const plan =  searchParams.get('plan') ?? null;
    const type =  searchParams.get('type') ?? null;

    const [ loading , setloading ] = useState(false);
    const [clientSecret, setClientSecret] = useState('');


    const fetchdata = async ( plan, type ) => {

        if( !loading ){

            setloading( true );

            const Resp = await getUserPaymentSession( plan, type );

            if( Resp?.status ) setClientSecret( Resp.data.client )

            setloading( false );

        }
    }

    useEffect(  () => {

        fetchdata( plan ,type )

    } ,[start]);


    return ( 
    <div className="w-full h-full overflow-y-auto py-4" >

        { loading && <LoadingSpinner text="Loading..." /> }
       
    { (clientSecret !== "")  ?
        <EmbeddedCheckoutProvider
            stripe={stripeKey}
            options={{ clientSecret }}
        >
            <EmbeddedCheckout className=" " />
        </EmbeddedCheckoutProvider>
        : null
    }
        
    </div> 
    )
    

}