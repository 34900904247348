import React, { useEffect } from "react";
import { useSearchParams,useNavigate } from "react-router-dom";
import { getUserPaymentSessionStatus } from "../actions/Billing";
import toast from 'react-hot-toast';
import { LoadingSpinner } from "../components/Loading";


export const PaymentCheckoutPageRedirect = (  ) =>{

    const [ searchParams ] = useSearchParams();

    const navigate = useNavigate()

    const plan =  searchParams.get('plan') ?? null;
    const type =  searchParams.get('type') ?? null;
    const session_id =  searchParams.get('session_id') ?? null;

    const fetchdata = async ( plan, type, session_id ) => {


            const Resp = await getUserPaymentSessionStatus( plan, type, session_id );

            if( Resp?.data.status == 'complete' ) toast.success('Payment Completed.!');
            else toast.error('Payment Failed.!');

            navigate('/settings#billing')

    }

    useEffect( () => {fetchdata( plan, type, session_id )} ,[] )

    return (
        <div className="flex w-full h-screen">

            <LoadingSpinner text={'Payment Processing...'} className="m-auto" />
        </div>
    );

}