import React, { useState } from "react";
import IndustryData from "../content/industrydata";

const ChooseInd = ({ active = 0, setIndustrys =null,setActive = null, list = [] }) => {
 
    const industryData = IndustryData();
    // const [active, setActive] = useState(0);

    const statechange = (index) => setActive(index)

    return (
        <>                                
        {list.map((industry,index) => {
            let img=industry?.src
            if(industry?.lable==="all")    img="asset/images/industry/all.png"

            return (
                <>
                    <div key={index} className={` indusrty flex shadow-card-rate  flex-col w-3/12 justify-center ${(active == index) ? "bg-primary":"bg-white"}   rounded-xl my-2 2xl:mb-6 mx-auto mr-4 px-2 py-6 cursor-pointer`} onClick = {() => {statechange(index);setIndustrys(false)}} >
                        <div className="mx-auto scale-75 min-h-[120px]">
                            <img src={`${img}`}  alt="industry icons"/>
                         </div>
                        <p className="mx-auto font-medium text-sm capitalize text-center ">{industry?.title}</p>
                    </div>
                </>
                            
            )
        })}
        </>
    )
}

export default ChooseInd;