import toast from 'react-hot-toast';
import axios from '../axios';
import { get, isObject } from 'lodash';


export const getAllTeamMember = (  ) =>  {

    return new Promise (async (resolve,reject)=>{

        axios.get(`/engage/team/all` ).then (resdata => {
            resolve(resdata.data);
        }).catch(err => {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })
         
    })

}


export const inviteEngageTeamMember = ({ email, full_name, engage_permissions }) =>  {

    if(!email) return toast.error("invalid Email Id")
    if(!engage_permissions) return toast.error("invalid engage_permissions")

    return new Promise (async (resolve,reject)=>{
        let payload = { email, full_name, engage_permissions }

        axios.post(`/engage/team/invite`, payload ).then (resdata => {
            resolve(resdata.data);
        }).catch(err => {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })
         
    })

}

export const updateTeamMemberDAta = ({ email, full_name, engage_permissions ,id }) =>  {

    if(!id) return toast.error("invalid user Id")
    if(!engage_permissions) return toast.error("invalid engage_permissions")

    return new Promise (async (resolve,reject)=>{
        let payload = { email, full_name, engage_permissions }

        axios.put(`/engage/team/user/${id}`, payload ).then (resdata => {
            resolve(resdata.data);
        }).catch(err => {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })
         
    })

}

export const getTeamMemberData = ({ id }) =>  {

    if(!id) return toast.error("invalid user Id")

    return new Promise (async (resolve,reject)=>{

        axios.get(`/engage/team/user/${id}`).then (resdata => {
            resolve(resdata.data);
        }).catch(err => {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })
         
    })

}

export const deleteTeamMemberData = ({ id }) =>  {

    if(!id) return toast.error("invalid user Id")

    return new Promise (async (resolve,reject)=>{

        axios.delete(`/engage/team/user/${id}`  ).then (resdata => {
            resolve(resdata.data);
        }).catch(err => {
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err.response.data);
        })
         
    })

}