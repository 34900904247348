// row of an Eller maze
class EllerRow {
    constructor(configObj) { 
        this.rowLength = configObj.length ? configObj.length : 8; // default length configuration
        this.isLastRow = configObj.closeMaze ? configObj.closeMaze : false; // default last row of the maze configuration
        this.previousRow = configObj.previousRow ? configObj.previousRow : null; // default previous row of the maze configuration
        this.verticalBias = configObj.verticalBias ? configObj.verticalBias : 0.5; // default vertical bias configuration, 0..1, the higher, the less vertical walls
        this.horizontalBias = configObj.horizontalBias ? configObj.horizontalBias : 0.5; // default horizontal bias configuration, 0..1, the higher, the less horizontal walls
        this.sets = Array(this.rowLength).fill(0);
        this.cells = [];
        if (this.previousRow  != null) {
            this.copyRowSets();
        }
        this.buildCells();
        this.assignRightWalls();
        this.assignBottomWalls();
    }

    // method to copy the sets from previous row
    copyRowSets() {
        for (let i = 0; i < this.rowLength; i ++) {
            if (!this.previousRow.cells[i].bottomWall) {
                this.sets[this.previousRow.cells[i].set] ++;
            }
        }
    }

    // method to build cells and assign them a set
    buildCells() {
        for (let i = 0; i < this.rowLength; i ++) {
            let set = (this.previousRow === null || this.previousRow.cells[i].bottomWall) ? -1 : this.previousRow.cells[i].set;
            this.cells.push(new Cell(set));
            this.assignUniqueSet(this.cells[i]);
        }
    }

    // method to assign right walls
    assignRightWalls() {
        for (let i = 0; i < this.rowLength; i ++) {
            this.cells[i].rightWall = (i === this.rowLength - 1) || (this.cells[i].set === this.cells[i + 1].set) || (Math.random() >= this.verticalBias && !this.isLastRow);
            if (!this.cells[i].rightWall) {
                this.mergeSets(i, i + 1);
            }
        }
    }

    // method to assign bottom walls
    assignBottomWalls() {
        for (let i = 0; i < this.rowLength; i ++) {
            this.cells[i].bottomWall = (this.sets[this.cells[i].set] !== 1 && Math.random() >= this.horizontalBias) || this.isLastRow;
            if (this.cells[i].bottomWall) {
                this.sets[this.cells[i].set] -= 1;
            }
        }
    }

    // method to assign "cell" a set, if it doesn't have one already
    assignUniqueSet(cell) {
        if (cell.set === -1) {
            for (let i = 0; i < this.rowLength; i ++) {
                if (this.sets[i] === 0) {
                    cell.set = i
                    this.sets[i] ++;
                    break;
                }
            }
        }
    }

    // method to merge two sets
    // all cells in the same set as "cellTo" are placed in the same set as "cellFrom"
    mergeSets(cellFrom, cellTo) {
        let setFrom = this.cells[cellFrom].set;
        let setTo = this.cells[cellTo].set;
        for (let i = 0; i < this.rowLength; i ++) {
            if (this.cells[i].set === setTo) {
                this.cells[i].set = setFrom;
                this.sets[setFrom] ++;
                this.sets[setTo] --;
            }
        }
    }

    // export the row in JSON format to use it in your projects
    exportToObj() {
        let row = {
            walls: []
        }
        for (let i = 0; i < this.rowLength; i ++) {
            row.walls.push({
                up: this.previousRow === null || this.previousRow.cells[i].bottomWall,
                down: this.cells[i].bottomWall,
                left: i === 0 || this.cells[i - 1].rightWall,
                right: this.cells[i].rightWall
            })
        }
        return row
    }
}

class Cell {
    constructor(set) {
        this.bottomWall = true;
        this.rightWall = true;
        this.set = set;
    }
}

export { EllerRow}
 