import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Layout from ".";
import { getDashboardInfo } from "../actions/user";
import { LastModified } from "../utils/dateconvetor";
import { useSelectionAction } from "./customstate/context";
import axios from "axios";
import SearchBar from "../components/input/searchbar";
import { FilterComponent } from "../components/FilterComponent";
import LoadContentpop from "../components/load";
import { categoryData } from "../content/category";
import { Crudopration } from "../components/Crudopration";


import { Clickouter } from "../utils/clickouter";
import { DeleteCampaign } from "../components/dashboard/DeleteProject";
import { LoadingSpinner } from "../components/Loading";
import PrimaryButton from "../components/Button1/primarybutton";
import DefaultDropMenu from "../components/input/droplist";
import { createProject, deleteProject, getProjects, updateProject } from "../actions/projects";
import toast from "react-hot-toast";
import { SubMenu } from "../components/submenu";
import { ProjectCreate } from "../components/popup/projectCreate";
import { PopupCreate } from "../components/popup/popupcreate";
import { MediaPopup } from "../components/popup/Mediapopup";
import { useDispatch } from "react-redux";
import { Actions } from "../store";
import { WorkSpaceActiveUser } from "../components/ActiveUser";
import { getSharedprojectforUser } from "../actions/sharedproject";




const Sharedproject = () => {

    const {sidebarstate,setSidebarState} = useSelectionAction();
    setSidebarState(4)

    const [projects, setProjects] = useState([])
    const [loading, setLoading] = useState(false)
    const [showMedia, setShoeMedia] = useState(false)
    const [trigger, settrigger] = useState('false')
    const [createProjectstate, setcreateProject] = useState(false)
    const [onChangePN, setOnchangePN] = useState('My Project')
    const [onChangeSrc, setOnchangeSrc] = useState('')
    const [shoeEditer, setshoeEditer] = useState(false)
    const [name, setName] = useState("")
    const [seletedProject, setSeletedProject] = useState(0)
    const [ShowloadingSpin, setShowloadingSpin] = useState(false);

    const hotname = process.env.REACT_APP_ENV == "development" ? 'http://loacalhost:3000/' : 'https://app.dev.engage.gozen.io/'

    const Navigate = useNavigate()
    const DisPatch = useDispatch()

    useEffect(() => {

        let projectData = projects[seletedProject]

        setOnchangePN(projectData?.project_name ?? "")
        setOnchangeSrc(projectData?.project_img_src ?? "")

    }, [seletedProject])

    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {
        setLoading(true)
        const ProjectList = await getSharedprojectforUser()
        if (ProjectList?.status) {
            setProjects(ProjectList?.data ?? [])
            DisPatch(Actions?.setProjects(ProjectList?.data ?? []))
            setLoading(false)
        }
        else {
            toast.error('Can\'t retrive the your Project')
            setLoading(false)
        }

    }

    const SortByName = () => {
        const data = [...projects].sort((a, b) => b.project_name < a.project_name ? 1 : -1)
        setProjects(data)

    }

    const SortByDate = () => {
        const data = [...projects].sort((a, b) => a.createdAt < b.created_at ? 1 : -1)
        setProjects(data)

    }

    const projectsdata = projects.filter((a) => String(a.project_name).toLowerCase().includes(name))

    const mediaOnSelect = (src, type) => {
        setOnchangeSrc(src)
    }



    const updateProjectFun = async () => {

        setShowloadingSpin(true)
        let payload = { project_id: projects[seletedProject]?.project_id, project_name: onChangePN, project_img_src: onChangeSrc }

        const createProjectDetail = await updateProject(payload)
        if (createProjectDetail?.status) toast.success(`workspace  update`)
        else toast('Something wnt wrong!')
        fetchData()
        setshoeEditer(false)
        setShowloadingSpin(false)
    }

    const DeleteCampaign = async () => {
         toast.error('only owner Can deleted the Workspace')
    }

    const SubMenuEvent = [
        {
            name: "open",
            event: () => Navigate(`/shared-workspace/${projects[seletedProject]?.project_id}`),
        },
        {
            name: "edit",
            event: () => setshoeEditer(true),
        },

    ]

    return (
        <>
            {showMedia && <MediaPopup OnSelect={mediaOnSelect} cancel={(val) => { setShoeMedia(false); (trigger == 'create') ? setcreateProject(true) : setshoeEditer(true) }} fieldType="src" />}
            <Layout isactive={0}>


                <div className={"flex flex-col w-full h-full"} >

                    {shoeEditer && <ProjectCreate loadingLive={ShowloadingSpin} project_id={projects[seletedProject].project_id} forwardAction={updateProjectFun} defaultSrc={onChangeSrc} onProfile={(val) => { setShoeMedia(val); settrigger('update') }} changeDefaultVaule={setOnchangePN} defaultVaule={onChangePN} projectData={projects[seletedProject]} title="Edit workspace" backwardAction={(val) => { setshoeEditer(val) }} />}
                    
                    <div className="flex flex-col h-auto ">
                        <div className="flex space-x-2 justify-between" >

                            <h2 className="pb-5  font-bold text-22px text-primary-bold  capitalize my-atuo">Shared Workspace</h2>
                            <div className="flex  py-2 sticky top-0 z-50 justify-end space-x-2">
                            <FilterComponent SortByDate={SortByDate} SortByName={SortByName} />
                            <SearchBar iconposition="left" border="border-2" setName={setName} />
                        </div>
                        </div>

                    </div>

                    <div className={`relative  w-full grid ${(loading || projectsdata.length == 0) ? "grid-cols-1" : "grid-cols-3"} gap-6 h-full py-4 px-4 overflow-y-auto list-industry`}>
                        
                        {
                            
                            loading ? <LoadingSpinner text={""} /> : projectsdata.length > 0 ?

                                <>
                                    {
                                        projectsdata?.map((list, index) => {
                                            return (
                                                <div className="w-full h-fit flex flex-col p-3 border-1 hover:shadow-bgshadow projectpostcontainer rounded-lg bg-white space-y-2" onClick={() => Navigate(`/shared-workspace/${list?.project_id}`)} >
                                                    <div className="projectpostthumbnail ">
                                                        <img src={list?.project_img_src ?? 'https://gz-engage-assets.s3.amazonaws.com/v1/users/assets/f1e37fea-4c21-4fa8-8ee1-61f9290eacb4/image/c7a655060994272bcf9a99a5afde2eaf.jpg'} className="projectpostthumbnailimg" alt="" />
                                                    </div>
                                                    <div className="w-full flex flex-row  " >

                                                        <div className="w-full flex flex-col " >

                                                            <p className="text-[16px] font-medium" >{list?.project_name}</p>
                                                            <p className="text-sm" >{LastModified(list?.created_at)}</p>
                                                        </div>
                                                        <div className="w-full flex justify-center">
                                                        <WorkSpaceActiveUser accessible_users={list?.member_info ?? []} />
                                                        {/* <SubMenu data={SubMenuEvent} id={index} setSeletedProject={setSeletedProject} /> */}
                                                   
                                                        </div>
                                                      
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }
                                </>
                                : <div className="flex flex-col space-y-2 h-full w-full justify-center">
                                <img src="/asset/images/dashboardempty.png" alt="" className="mx-auto h-[250px]" />
                            </div>
                        }
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Sharedproject