import React from 'react'
import { BillingLayout } from './BillingLayout'
import { useSelector } from 'react-redux'


const planfeature = {
  free: {
    views: 200,
    Subscribers_limits: 'unlimited',
    fileUploadLimit:"100MB"

  },
  starter: {
    views: 5000,
    Subscribers_limits: 'unlimited',
    fileUploadLimit:"1GB"

  },
  premium: {
    views: 10000,
    Subscribers_limits: 'unlimited',
    fileUploadLimit:"2GB"


  },
  professional: {
    views: 25000,
    Subscribers_limits: 'unlimited',
    fileUploadLimit:"5GB"

  },
  advanced: {
    views: 9999999,
    Subscribers_limits: 'unlimited',
    fileUploadLimit:"10GB"

  },
}

const Bundleplanfeature = {
  free: {
    views: 50,
    Subscribers_limits: 'unlimited',
    fileUploadLimit:"100MB"

  },
  basic: {
    views: 2500,
    Subscribers_limits: 'unlimited',
    fileUploadLimit:"1GB"

  },
  essential: {
    views: 5000,
    Subscribers_limits: 'unlimited',
    fileUploadLimit:"2GB"


  },
  professional: {
    views: 7500,
    Subscribers_limits: 'unlimited',
    fileUploadLimit:"5GB"

  },
  enterprise: {
    views: 10000,
    Subscribers_limits: 25000,
    fileUploadLimit:"10GB"

  },
}

const appsumoPlanfeature = {
  gozenengage_tier1: {
    views: 15000,
    Subscribers_limits: 10000,
    fileUploadLimit:"100MB"

  },
  gozenengage_tier2: {
    views: 30000,
    Subscribers_limits: 20000,
    fileUploadLimit:"1GB"

  },
  gozenengage_tier3: {
    views: 100000,
    Subscribers_limits: 'unlimited',
    fileUploadLimit:"2GB"


  },
  gozenengage_tier4: {
    views: 250000,
    Subscribers_limits: 'unlimited',
    fileUploadLimit:"5GB"

  },
  gozenengage_tier5: {
    views: 500000,
    Subscribers_limits: 'unlimited',
    fileUploadLimit:"10GB"

  },
}

const Datacal = (data) => {
  
  let ReturnData = '';

    if (1024 > data) ReturnData = (data).toFixed(2) + "KB"
    if ((1048576 > data) && (data > 1024)) ReturnData = (data/1024).toFixed(2) + "MB"
    if ((1048576 <= data)) ReturnData = (data/1048576).toFixed(2) + "GB"
      
   return ReturnData
}


const featuresLimit = (featureUsage, plan, gateway = 'local') => {

    const Chooseplan = ( gateway == 'appsumo' ) ? appsumoPlanfeature : (gateway == 'Bundle') ? Bundleplanfeature : planfeature



    return [
    {
      name: "Campaigns",
      usagePer: ((featureUsage?.Campaign_count ?? 0) / (featureUsage?.campaign_limit ?? 0)) * 100,
      usage: featureUsage?.Campaign_count ?? 0,
      total: featureUsage?.campaign_limit ?? 'unlimited'
    },
    {
      name: "Responses",
      usagePer: ((featureUsage?.total_resp ?? 0) / (Chooseplan[plan]?.Subscribers_limits ?? 0)) * 100,
      usage: featureUsage?.total_resp ?? 0,
      total: Chooseplan[plan]?.Subscribers_limits ?? 0
    },
    {
      name: "views",
      usagePer: ((featureUsage?.total_views ?? 0) / ((Chooseplan[plan]?.views ?? 0)+featureUsage?.monthly_views_addon_quantity)) * 100,
      usage: featureUsage?.total_views ?? 0,
      total: (Chooseplan[plan]?.views ?? 0)+featureUsage?.monthly_views_addon_quantity
    },
    {
      name: "Team Members",
      usagePer: ((featureUsage?.usage ?? 0) / (featureUsage?.usage ?? 0)) * 100,
      usage: featureUsage?.usage?.team_members_limit ?? 0,
      total: featureUsage?.team_members_limit ?? "0"
    },
    {
      name: "File upload",
      usagePer: ((featureUsage?.fileUpload_usage ?? 0) / (featureUsage?.fileUploadLimit ?? 0)) * 100,
      usage:Datacal (featureUsage?.fileUpload_usage ?? 0),
      total:  Chooseplan[plan]?.fileUploadLimit ?? "100MB"
    },
    {
      name: "No of Ai generated questions",
      usagePer: ((featureUsage?.question_generate_usage ?? 0) / (featureUsage?.question_generate_limit ?? 0))*100 ,
      usage: featureUsage?.question_generate_usage ?? 0,
      total: featureUsage?.question_generate_limit ?? 0
    }

  ]
}
export const Usage = ({ featureUsage = {}, gateway = 'local', plan = "free" }) => {

  const { userPlan } = useSelector(state => ({ userPlan: state?.userPlan }))

  const choosePlan = (gateway == 'appsumo') ? plan : userPlan?.plan;

  const FeatureList = featuresLimit(featureUsage, choosePlan, gateway)
 

  return (
    <div className='w-full -h-full flex flex-col px-4 py-2'>

      <div className='flex flex-col space-y-2' >
        <h3 className='font-semibold text-lg text-black' >Your Plan Usage</h3>
        <p className='text-sm' >Current Plan : <span className='text-base font-medium' >{ choosePlan }</span></p>
      </div>
      <div className='w-full grid grid-cols-3 gap-5 py-4' >

        {
          FeatureList?.map((feature) => {
            return (

                <div className="relative w-full flex flex-col border-1 space-y-4 w-[300px] bg-white rounded-lg py-3 px-4" style={{ background: "white" }} >
                  <p className="capitalize w-full flex justify-between font-medium"><span>{feature?.name}</span><span>{Math.round( feature?.total == 'unlimited' ? 0 : feature?.usagePer)}%</span></p>
                  {feature?.total !== 'unlimited' && <div className={`w-full h-2 bg-[rgba(0,0,0,0.3)] rounded-full  grid justify-items-start`} style={{ background: "#00000040" }} >
                    <div className={`h-2 w transition-all duration-500 bg-black rounded-full `} style={{ background: "black", width: `${ (feature?.usagePer)}%` }} ></div>
                  </div>}
                  <p className="capitalize w-full flex font-medium">{feature?.usage} of {feature?.total}</p>
                </div>
           

            )
          })
        }

      </div>

    </div>
  )
}

// Array(6) [
//   { name: 'Campaigns', usage: 0, total: 0 },
//   { name: 'Responses', usage: 0, total: 25000 },
//   { name: 'views', usage: 0, total: 10000 },
//   { name: 'Team Members', usage: 0, total: 0 },
//   { name: 'File upload', usage: 0, total: 0 },
//   { name: 'No of Ai generated questions', usage: 0, total: 0 }
// ]

// Array(6) [
//   { name: 'Campaigns', usage: 4, total: 'unlimited' },
//   { name: 'Responses', usage: 0, total: 25000 },
//   { name: 'views', usage: 0, total: 10000 },
//   { name: 'Team Members', usage: 0, total: 0 },
//   { name: 'File upload', usage: 0, total: 10485760 },
//   { name: 'No of Ai generated questions', usage: 15, total: 250 }
// ]

// {
//   Campaign_count: 4,
//   total_views: null,
//   total_resp: null,
//   sites_limit: 5,
//   question_generate_limit: 250,
//   question_generate_usage: 15,
//   fileUploadLimit: 10485760,
//   fileUpload_usage: 0,
//   team_members_limit: 0,
//   campaign_limit: 'unlimited'
// }