import React, { useEffect, useState } from "react";
import PrimaryButton from "../../../../Button1/primarybutton";
import DefaultDropMenu from "../../../../input/droplist";
import { campaignResponse } from "../../../../../actions/templates";
import { getUserSites } from "../../../../../actions/site";
import { useSelector } from "react-redux";
import { arrayCaptilize, isEmpty, isNotEmpty, removeValFrmArray } from "../../../../../utils/helpers";
import { CSVLink } from "react-csv";
import { toast } from "react-hot-toast";
import { LoadingSpinner } from "../../../../Loading";

export const ResponseTable = () => {
    const { CampaignDetail } = useSelector((state) => ({ CampaignDetail: state.Campaign_info }))
    const [titles, setTitles] = useState([])
    const [fetchTable, setFetchTable] = useState([])
    const [tableData, setTableData] = useState([])
    const [primaryKey, setPrimaryKey] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [isNoData, setNoData] = useState(true)
    const [rowPerPage, setRowPerPage] = useState(10)
    const [activeRowPerPage, setActiveRowPerPage] = useState(0)
    const [pageIndex, setPageIndex] = useState([1, 10])
    const [checkedArray, setCheckedArray] = useState([])
    // const [searchText,setSearchText]=useState("")
    const [siteIdList, setSiteIdList] = useState(["All Sites"])
    const [siteNameList, setSiteNameList] = useState(["All Sites"])
    const [siteIndex, setSiteIndex] = useState(0)
    const dropmenuList = [10, 25, 50, 100]

    useEffect(() => { fetchResult(CampaignDetail.Campaign_id) }, [])

    const fetchResult = async (camp_id, mon = 1, year = 2023, type = 'monthly') => {
        setLoading(true)
        const ResDate = await campaignResponse(camp_id)
        var SiteResp = await getUserSites()
        SiteResp = SiteResp?.data;

        setTimeout(() => { setLoading(false) }, 100)

        if (ResDate.data?.length > 0) {

            setFetchTable(ResDate.data)

            let tempSiteArray = ["All Sites"]
            let tempSiteNameArray = ["All Sites"]

            ResDate.data?.map((val, inx) => {
                if (!tempSiteArray.includes(val["site_id"])) {
                    let tempInx = SiteResp.findIndex(x => x.site_id === val["site_id"]);
                    let tempDomain = SiteResp[tempInx]?.domain

                    if (isNotEmpty(tempDomain)) {
                        tempSiteArray.push(val["site_id"])
                        tempSiteNameArray.push(tempDomain)
                    }
                }
            })



            setSiteIdList(tempSiteArray)
            setSiteNameList(tempSiteNameArray)



            setFilterSite("All Sites")
        }

    }



    const LoadMore = (state, id) => {
        setRowPerPage(state)
        const findIndex = dropmenuList.findIndex(i => i === state)
        setActiveRowPerPage(findIndex)
        setPageIndex([1, state])
    }

    const prev = () => { if (pageIndex[0] - rowPerPage > 0) setPageIndex([pageIndex[0] - rowPerPage, pageIndex[1] - rowPerPage]) }

    const next = () => {
        if (pageIndex[1] + rowPerPage < (tableData?.length + rowPerPage)) setPageIndex([pageIndex[0] + rowPerPage, pageIndex[1] + rowPerPage])
    }

    function toggle(source) {
        if (source.checked) setCheckedArray([...Array(tableData?.length).keys()])
        else setCheckedArray([])
    }

    function uncheckChild(source, val) {
        let temp = checkedArray
        if (temp.includes(val)) temp = removeValFrmArray(temp, val)
        else temp.push(val)
        setCheckedArray(temp)
    }


    function deleteAll() {
        checkedArray?.map((val, inx) => {
            // console.log(val, primaryKey[val])
        })
    }



    function setFilterSite(domain) {
        if (isEmpty(domain)) return
        let tempInx = siteNameList.indexOf(domain), siteId = siteIdList[tempInx]
        setSiteIndex(siteIdList.indexOf(siteId))
    }


    useEffect(() => { filterSite(siteIdList[siteIndex]) }, [siteIndex, siteIdList])

    function filterSite(siteId) {
        if (isEmpty(siteId)) return
        setNoData(true); setCheckedArray([]); setPrimaryKey([]); setTableData([]);

        let titleArray = [];

        fetchTable?.map((val, inx) => {
            if (siteId === "All Sites" || siteId === val["site_id"]) {
                Object.keys(val)?.map((newTitle) => {
                    if (!titleArray.includes(newTitle) && isNotEmpty(val[newTitle]) && val[newTitle]!=="-" ) titleArray.push(newTitle)
                })
            }
        })

        let forDeletion = ["site_id", "campaign_id", "accepts_marketing", "created_at", "is_deleted", "date", "recaptcha_token"]
        titleArray = titleArray.filter(item => !forDeletion.includes(item?.toLowerCase()))

        let tempTableData = [], tempPrimaryKey = []

        fetchTable?.map((val, inx) => {
            if (siteId === "All Sites" || siteId === val["site_id"]) {
                let tempRow = []

                titleArray?.map((objName) => { tempRow.push(isEmpty(val[objName]) ? "-" : val[objName]) })
                tempTableData.push(tempRow.map(String))

                tempPrimaryKey.push([isEmpty(val["site_id"]) ? "-" : val["site_id"], isEmpty(val["campaign_id"]) ? "-" : val["campaign_id"], isEmpty(val["email"]) ? "-" : val["email"]])
            }
        })


        setTableData(tempTableData.reverse())
        setPrimaryKey(tempPrimaryKey.reverse())

        titleArray = arrayCaptilize(titleArray)
        setTitles(titleArray)
        if (tempTableData?.length > 0) setNoData(false)
        setPageIndex([1, (tempTableData?.length > rowPerPage) ? rowPerPage : tempTableData?.length])
        //  setLoading(false)


    }


    return (
        <>
            <section className="w-full space-y-6 flex flex-col pb-20 pt-4 h-[90%] overflow-y-auto px-16">
                {isLoading ? <LoadingSpinner text="Loading" /> : <>
                    {isNoData ? <div className="w-full h-full mt-5  flex justify-center"><div className="flex items-center"> <div className="text-lg noselect">No data found</div> </div></div> : <>
                        <div className="w-full justify-between  flex">
                            <div>

                                {/* <InputWithLabel margin="auto" mainclassName={"flex flex-row items-center gap-[10px]"} label="Search" textvalue={searchText} Placeholder="Type here to search" onchange={(e) =>{setSearchText(e?.target?.value)}} /> */}

                                <DefaultDropMenu label=" " labelTextsize="font-medium" width="w-[400px]" list={siteNameList} active={siteIndex} onchange={setFilterSite} />
                            </div>
                            <div>
                                {/* delete button */}
                                {checkedArray?.length > 0 && <PrimaryButton text={'Delete'} backgroundColor={"bg-gray-mount"} OnClick={() => { deleteAll() }} />} &ensp;

                                {/* download button */}
                                <CSVLink data={[titles, ...tableData]} filename={"Response.csv"} className="btn btn-primary" target="_blank" onClick={() => { toast.success("Export started successfully!") }}>
                                    <PrimaryButton text={'Export All'} />
                                </CSVLink>
                            </div>
                        </div>

                        <div className="w-full shadow-menu-base rounded-md">
                            <div className="relative overflow-x-auto sm:rounded-lg">
                                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="p-4 ">
                                                <div className="flex items-center">
                                                    <input type="checkbox" checked={(tableData?.length === checkedArray?.length && checkedArray?.length > 0) ? true : false} className="cursor-pointer hidden" onClick={(e) => { toggle(e?.target) }} />
                                                    S.No
                                                </div>
                                            </th>
                                            {titles?.map((val, inx) => { return <th key={inx} scope="col" className="capitalize px-6 py-3 min-w-[200px] max-w-fit" >{val}</th> })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tableData?.map((val, row) => {
                                                if (row >= (pageIndex[0] - 1) && (row <= pageIndex[1] - 1)) {
                                                    return <tr key={row} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                        <td className="w-4 p-4 "> <div className="flex items-center">
                                                            {row+1}
                                                            <input type="checkbox" onChange={(e) => { uncheckChild(e?.target, row) }} className="cursor-pointer hidden" checked={(checkedArray.includes(row)) ? true : false} />
                                                        </div></td>
                                                        {titles?.map((objName, col) => { return <td key={objName + "_" + val[col]} className="px-6 py-4">{val[col]}</td> })}
                                                    </tr>
                                                } else return
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>

                            <div className="flex justify-end space-x-3 px-4 py-4">
                                <div className="flex space-x-2">

                                    <p className="my-auto noselect">Rows per page :</p>
                                    <DefaultDropMenu labelTextWeight="hidden" list={dropmenuList} active={activeRowPerPage < 0 ? 0 : activeRowPerPage} mainclassName="my-auto w-20" onchange={LoadMore} />
                                </div>
                                <div className="flex"><p className="my-auto noselect">{pageIndex[0]}-{(pageIndex[1] > (tableData?.length + 1) ? (tableData?.length + 1) : pageIndex[1])} of {tableData?.length}</p></div>

                                <div className="flex my-auto space-x-4">
                                    <span onClick={() => { prev() }} className={`p-2 px-3 rounded-md my-auto cursor-pointer ${pageIndex[0] <= 1 ? "bg-gray-100" : ""} hover:bg-gray-200`}><svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.90969 0.710469C6.51969 0.320469 5.88969 0.320469 5.49969 0.710469L0.909687 5.30047C0.519688 5.69047 0.519688 6.32047 0.909687 6.71047L5.49969 11.3005C5.88969 11.6905 6.51969 11.6905 6.90969 11.3005C7.29969 10.9105 7.29969 10.2805 6.90969 9.89047L3.02969 6.00047L6.90969 2.12047C7.28969 1.73047 7.28969 1.09047 6.90969 0.710469Z" fill="#586474" /></svg></span>
                                    <span onClick={() => { next() }} className={`p-2 my-auto px-3 rounded-md cursor-pointer ${(pageIndex[1] > (tableData?.length + 1)) ? "bg-gray-100" : ""} hover:bg-gray-200`}><svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.29055 9.88047L5.17055 6.00047L1.29055 2.12047C0.900547 1.73047 0.900547 1.10047 1.29055 0.710469C1.68055 0.320469 2.31055 0.320469 2.70055 0.710469L7.29055 5.30047C7.68055 5.69047 7.68055 6.32047 7.29055 6.71047L2.70055 11.3005C2.31055 11.6905 1.68055 11.6905 1.29055 11.3005C0.910547 10.9105 0.900547 10.2705 1.29055 9.88047Z" fill="#586474" /></svg></span>
                                </div>
                            </div></div>
                    </>
                    }</>}
            </section></>
    )
}
