import React, { useState } from "react";
import { useEffect,useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Clickouter } from "../../utils/clickouter";
import { ScriptCode } from "../Editor/common/ScriptCode";

export const ProfileIcon = ({ userProfilePic = '', username = "", email = "" ,firstname = '',lastname = ""}) => {

    const Navigate = useNavigate();

    const menuRef = useRef();

    const [show, setShow] = useState(false)
    const [showScriptCode, setshowScriptCode] = useState(false)
    
    // useEffect(Clickouter(menuRef,setShow),[])
    const menuREf = useRef(null);
    
    useEffect(()=>{
        let handleAction = (event) => {

            if (menuREf.current && !menuREf.current?.contains(event.target)) {
                // alert("test-out")
                setShow(false);
            }
        };
    
        document.addEventListener("mousedown", handleAction);
    },[])
    
    const onImageError = (e) => { e.target.src = '/asset/images/Default-User.png'  }

    return (
        <div className="text-gray-800 flex items-center  space-x-4 " data-for="user-options" data-tip='user-options'  >
           
            <button className="w-fit" onClick={setShow}>
                <img alt="User"
                    src={userProfilePic === '' ? '/asset/images/Default-User.png' : userProfilePic}
                    onError={onImageError} 
                    className="cursor-pointer w-10 h-10 rounded-full flex-shrink-0 object-cover object-center" />
            </button>
            <div className={`absolute w-[280px] top-10 right-5 ${show ? "":"invisible"}  border-1 border-border-light bg-white  h-auto rounded-lg shadow-lg  `} ref={menuREf} style={{ zIndex: "100" }}>


                <div className={`w-full ${email ? "" : "hidden"} space-y-2 flex p-4 hover:bg-slate-100 flex-col`}>

                    <p className="w-full text-base"><span>{email}</span></p>

                    <p className="w-full text-sm">{!firstname ? <span>{email}</span> : <><span>{firstname}</span> <span>{lastname}</span></>}</p>

                </div>

                <hr className="m-0" />

                <p className="hover:bg-gray-200 cursor-pointer text-base w-full px-4 py-2.5 space-x-2 flex capitalize" onClick={() => Navigate('/settings')}>
                    <span className="my-auto">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_2362_12946)">
                                <path d="M16.2501 9.99996C16.2501 9.80829 16.2418 9.62496 16.2251 9.43329L17.7751 8.25829C18.1084 8.00829 18.2001 7.54163 17.9918 7.17496L16.4334 4.48329C16.2251 4.11663 15.7751 3.96663 15.3918 4.13329L13.6001 4.89163C13.2918 4.67496 12.9668 4.48329 12.6251 4.32496L12.3834 2.39996C12.3334 1.98329 11.9751 1.66663 11.5584 1.66663H8.45009C8.02509 1.66663 7.66675 1.98329 7.61675 2.39996L7.37509 4.32496C7.03342 4.48329 6.70842 4.67496 6.40009 4.89163L4.60842 4.13329C4.22509 3.96663 3.77509 4.11663 3.56675 4.48329L2.00842 7.18329C1.80009 7.54996 1.89175 8.00829 2.22509 8.26663L3.77509 9.44163C3.75842 9.62496 3.75009 9.80829 3.75009 9.99996C3.75009 10.1916 3.75842 10.375 3.77509 10.5666L2.22509 11.7416C1.89175 11.9916 1.80009 12.4583 2.00842 12.825L3.56675 15.5166C3.77509 15.8833 4.22509 16.0333 4.60842 15.8666L6.40009 15.1083C6.70842 15.325 7.03342 15.5166 7.37509 15.675L7.61675 17.6C7.66675 18.0166 8.02509 18.3333 8.44175 18.3333H11.5501C11.9668 18.3333 12.3251 18.0166 12.3751 17.6L12.6168 15.675C12.9584 15.5166 13.2834 15.325 13.5918 15.1083L15.3834 15.8666C15.7668 16.0333 16.2168 15.8833 16.4251 15.5166L17.9834 12.825C18.1918 12.4583 18.1001 12 17.7668 11.7416L16.2168 10.5666C16.2418 10.375 16.2501 10.1916 16.2501 9.99996ZM10.0334 12.9166C8.42509 12.9166 7.11675 11.6083 7.11675 9.99996C7.11675 8.39163 8.42509 7.08329 10.0334 7.08329C11.6418 7.08329 12.9501 8.39163 12.9501 9.99996C12.9501 11.6083 11.6418 12.9166 10.0334 12.9166Z" fill="#586474" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2362_12946">
                                    <rect width="20" height="20" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </span>
                    <span className="my-auto">Settings</span>

                </p>

                <p className="hover:bg-gray-200 cursor-pointer text-base w-full px-4 py-2.5 space-x-2 flex capitalize" onClick={() =>{setshowScriptCode(true);setShow(false)}}>
                    <span className="my-auto">
                      <svg width={"20"} height={"20"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.25 4.5H6.75C4.68225 4.5 3 6.18225 3 8.25V15.75C3 17.8178 4.68225 19.5 6.75 19.5H17.25C19.3177 19.5 21 17.8178 21 15.75V8.25C21 6.18225 19.3177 4.5 17.25 4.5ZM8.46975 12.5303L10.407 14.4683C10.7003 14.7615 10.7003 15.2355 10.407 15.5288C10.2608 15.675 10.0687 15.7485 9.87675 15.7485C9.68475 15.7485 9.49275 15.675 9.3465 15.5288L7.40925 13.5908C6.984 13.1663 6.75 12.6008 6.75 12C6.75 11.3993 6.984 10.8338 7.40925 10.4085L9.3465 8.47125C9.63975 8.178 10.1138 8.178 10.407 8.47125C10.7003 8.7645 10.7003 9.2385 10.407 9.53175L8.46975 11.469C8.328 11.6108 8.25 11.799 8.25 11.9993C8.25 12.1995 8.328 12.3885 8.46975 12.5303ZM16.5908 13.5915L14.6535 15.5295C14.5073 15.6758 14.3153 15.7493 14.1233 15.7493C13.9313 15.7493 13.7392 15.6758 13.593 15.5295C13.2997 15.2363 13.2997 14.7623 13.593 14.469L15.5303 12.531C15.672 12.3893 15.75 12.201 15.75 12.0008C15.75 11.8005 15.672 11.6123 15.5303 11.4705L13.593 9.5325C13.2997 9.23925 13.2997 8.76525 13.593 8.472C13.8862 8.17875 14.3603 8.17875 14.6535 8.472L16.5908 10.4093C17.016 10.8338 17.25 11.3993 17.25 12C17.25 12.6008 17.016 13.167 16.5908 13.5915Z" fill="#586474"/>
</svg>
                    </span>
                    <span className="my-auto">Install Code</span>

                </p>


                <p className="hover:bg-gray-200 cursor-pointer text-base w-full px-4 py-2.5 space-x-2 flex capitalize" onClick={()=>{window.open("https://docs.gozen.io/gozen-engage-knowledge-base/","_blank")}}>
                <span className="my-auto">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.16675 14H9.83342V12.3333H8.16675V14ZM9.00008 0.666626C4.40008 0.666626 0.666748 4.39996 0.666748 8.99996C0.666748 13.6 4.40008 17.3333 9.00008 17.3333C13.6001 17.3333 17.3334 13.6 17.3334 8.99996C17.3334 4.39996 13.6001 0.666626 9.00008 0.666626ZM9.00008 15.6666C5.32508 15.6666 2.33341 12.675 2.33341 8.99996C2.33341 5.32496 5.32508 2.33329 9.00008 2.33329C12.6751 2.33329 15.6667 5.32496 15.6667 8.99996C15.6667 12.675 12.6751 15.6666 9.00008 15.6666ZM9.00008 3.99996C7.15841 3.99996 5.66675 5.49163 5.66675 7.33329H7.33342C7.33342 6.41663 8.08342 5.66663 9.00008 5.66663C9.91675 5.66663 10.6667 6.41663 10.6667 7.33329C10.6667 8.99996 8.16675 8.79163 8.16675 11.5H9.83342C9.83342 9.62496 12.3334 9.41663 12.3334 7.33329C12.3334 5.49163 10.8417 3.99996 9.00008 3.99996Z" fill="#586474"/>
                            </svg>
                    </span>
                    <span className="my-auto">Docs</span>
                </p>
                <p className="hover:bg-gray-200 cursor-pointer text-base w-full px-4 py-2.5 space-x-2 flex capitalize" onClick={() => Navigate('/logout')}>
                    <span className="my-auto"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_2380_7791)">
                            <path d="M4.16667 4.16667H9.16667C9.625 4.16667 10 3.79167 10 3.33333C10 2.875 9.625 2.5 9.16667 2.5H4.16667C3.25 2.5 2.5 3.25 2.5 4.16667V15.8333C2.5 16.75 3.25 17.5 4.16667 17.5H9.16667C9.625 17.5 10 17.125 10 16.6667C10 16.2083 9.625 15.8333 9.16667 15.8333H4.16667V4.16667Z" fill="#586474" />
                            <path d="M17.2083 9.70836L14.8833 7.38336C14.6167 7.11669 14.1667 7.30003 14.1667 7.67503V9.16669H8.33333C7.875 9.16669 7.5 9.54169 7.5 10C7.5 10.4584 7.875 10.8334 8.33333 10.8334H14.1667V12.325C14.1667 12.7 14.6167 12.8834 14.875 12.6167L17.2 10.2917C17.3667 10.1334 17.3667 9.86669 17.2083 9.70836Z" fill="#586474" />
                        </g>
                        <defs>
                            <clipPath id="clip0_2380_7791">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    </span>
                    <span className="my-auto">Logout</span></p>

            </div>
            {
        showScriptCode &&  <ScriptCode showScriptCode={showScriptCode} setshowScriptCode={setshowScriptCode} />
      }
        </div>
    )
}