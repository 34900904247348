import React from "react";
import { useDispatch } from "react-redux";
import { EditorIcons } from "../../../content/editoricon";
import { Actions } from "../../../store";
import { listOfIcon } from "../../../content/iconList";
 


export const EditorTooltipST = ({ position, setTriggerField = null, show = false }) => {


    const upIcon = listOfIcon('uparrow',18,18,'#586474');

    const downIcon = listOfIcon('downarrow',18,18,'#586474');

    const deleteIcon = listOfIcon('delete',18,18,'#F14B4B');

     const toolTip = [
        {
            type: "up",
            icon: upIcon
        },
        {
            type: "down",
            icon: downIcon
        },
        {
            type: "delete",
            icon: deleteIcon
        },
    ]

    const Dispatch = useDispatch()

    const triggerAction = (type) => {
        if(type==="create"){
            setTriggerField(true)
        }else Dispatch(Actions.setFieldRearrange({position:position,type:type}))
    }

    return (
        <p className={` ${ show ? "flex" : "hidden" }  transform duration-500 group-hover:flex  space-x-2 `}>
        {
            toolTip.map(listtt => <span className={`  flex  my-auto border-1 rounded-md py-2 px-2 ${listtt?.type == 'delete' ? "border-red-500 " : ""} `} onClick={()=>triggerAction(listtt?.type)} >{listtt?.icon}</span>)
        }
        </p>
    )
}