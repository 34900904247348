import toast from 'react-hot-toast';
import axios from '../axios';
import { get, isObject } from 'lodash';




export const  CreateGenerateForm = (payload) => {
    
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.post(`/generate/form`,payload);
            
            if(res.status === 200) resolve(res.data);
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err);
        }
    })
}

export const  CreateGenerateFormTEmp = (payload,template_id) => {
    
    return new Promise (async (resolve,reject)=>{
        try{

            const res = await axios.post(`/generate/form/template`,{...payload,template_id:template_id});
            
            if(res.status === 200) resolve(res.data);
    
        }catch(err){
            toast.error(err.response.data.message ?? "Something  when to wrong, please Contact Support")
            reject(err);
        }
    })
}