import React, { useEffect, useRef, useState } from "react";

export const GameMenuBar = ({ isGameStart = true, Src = null }) => {

    const musicIcon = (
        <svg width="28" height="28" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="50" height="50" rx="25" fill="#2563EB" />
            <rect x="0.5" y="0.5" width="49" height="49" rx="24.5" stroke="white" stroke-opacity="0.84" />
            <g clip-path="url(#clip0_635_71)">
                <path d="M30.2293 24.5H31.271C31.4091 24.5 31.5416 24.4451 31.6393 24.3474C31.737 24.2498 31.7918 24.1173 31.7918 23.9792V23.4583C31.7918 20.0729 29.896 18.25 28.2293 16.7083C26.9064 15.4479 25.7397 14.3542 25.5627 12.5417C25.5548 12.408 25.4968 12.2823 25.4002 12.1896C25.3037 12.0969 25.1757 12.0441 25.0418 12.0417H24.0002C23.862 12.0417 23.7296 12.0965 23.6319 12.1942C23.5342 12.2919 23.4793 12.4244 23.4793 12.5625V29.7083C23.4793 29.9846 23.3696 30.2495 23.1742 30.4449C22.9789 30.6402 22.7139 30.75 22.4377 30.75H21.3752C19.0731 30.75 17.2085 32.1562 17.2085 33.875C17.2085 35.5937 19.0731 37 21.3752 37C23.6772 37 25.5418 35.5937 25.5418 33.875V23.7396C25.9481 24.1875 26.3856 24.5937 26.8022 24.9896C28.3647 26.4687 29.7085 27.7396 29.7085 30.1979C29.7085 30.336 29.7634 30.4685 29.861 30.5662C29.9587 30.6639 30.0912 30.7187 30.2293 30.7187H31.271C31.4091 30.7187 31.5416 30.6639 31.6393 30.5662C31.737 30.4685 31.7918 30.336 31.7918 30.1979C31.7918 26.8125 29.896 24.9896 28.2293 23.4479C26.7918 22.1146 25.5418 20.9375 25.5418 18.8437V16.9792C25.9481 17.4167 26.3856 17.8333 26.8022 18.2187C28.3647 19.6979 29.7085 20.9792 29.7085 23.4583V23.9792C29.7085 24.1173 29.7634 24.2498 29.861 24.3474C29.9587 24.4451 30.0912 24.5 30.2293 24.5Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_635_71">
                    <rect width="15" height="25" fill="white" transform="translate(17 12)" />
                </clipPath>
            </defs>
        </svg>
    )

    const musicMuteIcon = (
        <svg width="28" height="28" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="49" height="49" rx="24.5" fill="#2563EB" stroke="white" />
            <g clip-path="url(#clip0_636_80)">
                <path d="M30.2293 24.5H31.271C31.4091 24.5 31.5416 24.4451 31.6393 24.3474C31.737 24.2498 31.7918 24.1173 31.7918 23.9792V23.4583C31.7918 20.0729 29.896 18.25 28.2293 16.7083C26.9064 15.4479 25.7397 14.3542 25.5627 12.5417C25.5548 12.408 25.4968 12.2823 25.4002 12.1896C25.3037 12.0969 25.1757 12.0441 25.0418 12.0417H24.0002C23.862 12.0417 23.7296 12.0965 23.6319 12.1942C23.5342 12.2919 23.4793 12.4244 23.4793 12.5625V29.7083C23.4793 29.9846 23.3696 30.2495 23.1742 30.4449C22.9789 30.6402 22.7139 30.75 22.4377 30.75H21.3752C19.0731 30.75 17.2085 32.1562 17.2085 33.875C17.2085 35.5937 19.0731 37 21.3752 37C23.6772 37 25.5418 35.5937 25.5418 33.875V23.7396C25.9481 24.1875 26.3856 24.5937 26.8022 24.9896C28.3647 26.4687 29.7085 27.7396 29.7085 30.1979C29.7085 30.336 29.7634 30.4685 29.861 30.5662C29.9587 30.6639 30.0912 30.7187 30.2293 30.7187H31.271C31.4091 30.7187 31.5416 30.6639 31.6393 30.5662C31.737 30.4685 31.7918 30.336 31.7918 30.1979C31.7918 26.8125 29.896 24.9896 28.2293 23.4479C26.7918 22.1146 25.5418 20.9375 25.5418 18.8437V16.9792C25.9481 17.4167 26.3856 17.8333 26.8022 18.2187C28.3647 19.6979 29.7085 20.9792 29.7085 23.4583V23.9792C29.7085 24.1173 29.7634 24.2498 29.861 24.3474C29.9587 24.4451 30.0912 24.5 30.2293 24.5Z" fill="white" />
            </g>
            <line x1="8.52122" y1="43.9005" x2="40.6606" y2="5.59825" stroke="white" stroke-width="1.25" />
            <defs>
                <clipPath id="clip0_636_80">
                    <rect width="15" height="25" fill="white" transform="translate(17 12)" />
                </clipPath>
            </defs>
        </svg>

    )

    const [ audioSource, setAudioSource ] = useState(Src ?? "https://asserts.engage.gozen.io/v1/public/bgm-3.mp3");

    const audioRef = useRef(null);

    const [ Audiobut, setAudioBut ] = useState(true);

    useEffect( () => setAudioSource(Src ?? "https://asserts.engage.gozen.io/v1/public/bgm-3.mp3"),[Src] )


    useEffect(() => {

        if (audioRef.current) {
            if (Audiobut) audioRef.current?.play();
            else audioRef.current?.pause();

        }

    }, [Audiobut])


    useEffect( () => {

        if(audioRef.current) audioRef.current.pause();

        audioRef.current.loop=true;

        audioRef.current.volume = 0.50;

    } ,[]);


    useEffect(()=>{
        
        if( !isGameStart ) {

            const AudioElement = document.querySelectorAll('.AudioContainer');
    
            AudioElement.forEach( obj => {
    
                obj.addEventListener( "play" , e => e.pause() )
    
    
            } )

            audioRef.current.play();
        }
    },[isGameStart])

    return (
        <section className={` absolute top-[20%] left-2 p-2 rounded-lg bg-[#ffffff60] border-1 `} style={{ zIndex: "15" }} >

            <audio src={audioSource} ref={audioRef} className="AudioContainer " ></audio>

            <div className={ ` transform  transition-all duration-300 scale-100 hover:scale-110  hover:shadow-2xl cursor-pointer rounded-full shadow-[#ffffff]` } onClick={()=>setAudioBut(!Audiobut)} >{Audiobut ? musicIcon : musicMuteIcon}</div>

        </section>
    )

}