import React from 'react'
import { BillingLayout } from './BillingLayout'
import { CurrentPlan } from './CurrentPlan'
import {CardInformation} from './CardInformation'
import { Invoice } from './Invoice'
import {PaymentHistory} from './PaymentHistory'

export const Summary = ({ userBillingDetails = {}, setActive }) => {
  
  return (
    <div className='relative flex w-full h-full p-4 space-x-4 '>

      <div className='flex flex-col space-y-4  w-full  '>
        <div className='flex flex-row space-x-4 w-full '>
          <CurrentPlan planInfo={userBillingDetails?.plan ?? {} }  setActive={setActive} />
         { (userBillingDetails?.card && userBillingDetails?.plan?.plan !== 'free') &&  <CardInformation CardInfo={userBillingDetails?.card} /> }
        </div>
        <PaymentHistory  invoices={userBillingDetails?.invoices ?? {}} />
      </div>
      <Invoice upComingInvoice ={userBillingDetails?.upComingInvoice ?? {}} />

    </div>
  )
}
