import React, { useState } from "react";
import DefaultDropMenu from "../../../input/droplist";
import { Imageinput } from "../../../input/imageinput";
import { MediaPopup } from "../../../popup/Mediapopup";
import { useDispatch } from "react-redux";
import { Actions } from "../../../../store";
import { ColorPixerInput } from "../../../input/colorpixerInput";


export const FindPathSetting = ({ field, fieldId }) => {

    const GameLevel = ['Easy','normal','Medium','Hard'] 
    const findIndex = GameLevel.findIndex(list => String(list).toLocaleLowerCase() === String(field?.gameLevel).toLocaleLowerCase())

    const [ShowMedia, setShowMidia] = useState(false);
    const [ShowMediaType, setShowMidiatype] = useState(0);
    const [strokeColor,setstrokeColor] = useState(field?.strokeColor || "#FFFFFF")  

    const Dispatch = useDispatch()
    const imageSrcType= ["pointericon","targeticon"]

    const DirectContentChange = (value, type) => {
        
        if(type==="strokeColor") setstrokeColor(value)

        const setContent = {
            isCommonSetting: false,
            key: fieldId,
            node: 'game_mainpage_json',
            child: type,
            value: String(value).toLocaleLowerCase()
        }
        Dispatch(Actions.setFieldTemplateContentChange(setContent))
        if(ShowMedia) setShowMidia(false)

    }


    return (
        <div className="w-full space-y-4 ">
        {ShowMedia && <MediaPopup cancel={setShowMidia} fieldType={imageSrcType[ShowMediaType]} OnSelect={DirectContentChange} />}

            <DefaultDropMenu list={GameLevel} active={findIndex} label="Game Level" fieldType={'gameLevel'} onchange={DirectContentChange} />
            <ColorPixerInput label={"Stroke Color"} Onchange={DirectContentChange} fieldType="strokeColor" colorHex={strokeColor} labelTextsize={'text-sm my-auto'}  />
            <Imageinput label="Pointer Icon" src={field?.pointericon} chanaeAction={() => {setShowMidia(true);setShowMidiatype(0)}} />
            <Imageinput label="Target Icon" src={field?.targeticon} chanaeAction={() => {setShowMidia(true);setShowMidiatype(1)}} />

            
        </div>
    )
}