import React, { Component } from "react";
import Header from "../../components/header/header";
import Choose from "../../components/choosecatefgory";

const IndustryCategory = () => {

    const token = localStorage.getItem('access_token');
    
    if(!token?.length) window.location.href='/login' ;

    else return(
            <>
             <section className="w-screen h-screen">
                <div className="flex flex-col w-full">
                    <Header />
                    <div className="w-full mx-auto top-[77px] py-10 left-0 right-0 bottom-0 overflow-scroll overflow-x-hidden">
                        <div className="w-4/5 mx-auto h-full my-6">
                            <h2 className="mb-4px font-bold text-22px text-primary-bold  capitalize">Choose Game Category</h2>
                            <div className="w-full flex flex-wrap bg-primary-lightblue p-6 rounded-xl mt-12 ">
                                <Choose />
                            </div>
                        </div>
                        <div className="w-4/5 mx-auto my-6">
                        <button className="bg-primary-lightblue flex flex-row items-center justify-center rounded-8px w-34  border border-border-light shadow-sm text-xl font-medium px-26px  focus:outline-none h-12  sm:text-sm">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.08 1.99329C10.7534 1.66662 10.2267 1.66662 9.90004 1.99329L4.36004 7.53329C4.10004 7.79329 4.10004 8.21329 4.36004 8.47329L9.90004 14.0133C10.2267 14.34 10.7534 14.34 11.08 14.0133C11.4067 13.6866 11.4067 13.16 11.08 12.8333L6.25337 7.99996L11.0867 3.16662C11.4067 2.84662 11.4067 2.31329 11.08 1.99329Z" fill="#06152D"/>
                            </svg>

                            <span className="mx-2 ">Back</span>
                        </button>
                        </div>
                    </div>
                </div>
             </section>
            </>
        )
    
}

export default IndustryCategory;