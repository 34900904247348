import React, { useState } from 'react'
import InputWithLabel from '../input/inputWithLabel'
import PrimaryButton from '../Button1/primarybutton'
import { sendResetpasswordMail, verifyResetPasswordEmail } from '../../actions/user'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { CgSpinner } from 'react-icons/cg'
import { useNavigate } from "react-router-dom";
import { REGX } from '../../content/regx'
import { PasswordValid } from './PasswordValid'

export const ResetpasswordVerify = () => {

    const UseParam = useParams();

    const navigate = useNavigate()

    const [ password, setPassword ] = useState('')

    const [ loading, setloading ] = useState(false);

    const [passwordCheckList, setPasswordCheckList] = useState({
        charNumberValid: false,
        lowercaseValid: false,
        numberValid: false,
        specialCharValid: false,
        uppercaseValid: false
    })

    const SendVerifiCationMail = async () => {

        setloading(true)

        const ResetEmail = await verifyResetPasswordEmail( password, UseParam?.token )

        if ( ResetEmail?.status ) toast?.success("Success")

        navigate("/login")
    
        setloading(false)

    }

    const onPasswordChange = (val) => { 
        setPassword(val)
        setPasswordCheckList({
            uppercaseValid: REGX.upperCase.test(val) ? true : false,
            lowercaseValid: REGX.lowerCase.test(val) ? true : false,
            charNumberValid: val.length >= 8 ? true : false,
            numberValid: REGX.number.test(val) ? true : false,
            specialCharValid: REGX.specialCharacters.test(val) ? true : false,
        })
    } 

    return (
        <section className='w-1/2 flex h-full' >

            <div className="w-9/12 flex flex-col space-y-4 p-11 py-14 2xl:py-18 bg-white rounded-xl my-auto" >

                <h3 className='font-medium' >Reset password</h3>

                <InputWithLabel Placeholder='password' label='password' type='password' onchange={(e)=>onPasswordChange(e.target.value)} />

                { password.length>0 && <PasswordValid {...passwordCheckList} ></PasswordValid> }

                <PrimaryButton text={'Done'} icon={
                    
                        loading ? <CgSpinner className="w-5 h-5 animate-spin text-white" /> : null

                    } disabled={ loading } OnClick={()=>SendVerifiCationMail()} />

            </div>

        </section>
    )

}