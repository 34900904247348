import React, { useState,  useEffect, useRef } from "react";
import { mixArray, element,  isEmpty,getImageBase64, isNotEmpty, isEqual } from "../../../utils/helpers";
import {  getMatrixClip } from "../../../utils/canvas";
import { useDispatch, useSelector } from "react-redux";


export const ImageDragAndDrop = ({ obj = {}, fieldID = '', StartTime = false, prview = false, setActionCount = null, GameFinsh = null, setScore=null ,position=0 , ActionCount,setLoadContent=null }) => {

    const { Fields, device } = useSelector((state) => ({ Fields: state.fields, device: state.device }));

    const templateData = Fields[fieldID];
    const Settings = templateData?.filedContent
    const mainPageContent = Settings?.game_mainpage_json;
    const [trigger, settrigger] = useState(false)
    const DisPatch = useDispatch()

    const LeftRef = useRef(null)
    const [boxSize, setBoxSize] = useState([])
    const [dragObj, setDragObj] = useState([])
    const [isLoaded, setLoaded] = useState(false)
    

    const BOX = mainPageContent?.layoutrange.split("x"), rows = parseInt(BOX[0]); const cols = parseInt(BOX[1]);  
 
    const [LeftBase64, setLeftBase64] = useState(new Array(rows*cols).fill(null))
    const [RightBase64, setRightBase64] = useState(new Array(rows*cols).fill(null))
 
    const [iconSize, setIconSize] = useState([100,100])

    useEffect(()=>{  gameSetUp(false) },[]) 
    useEffect(()=>{  gameSetUp(true) },[ mainPageContent, trigger, isLoaded]) 
    useEffect(() => {
      if (StartTime) {
          settrigger(!trigger)
          gameSetUp(false);
      }
  }, [StartTime, isLoaded])

  useEffect(() => { setTimeout(() => {  gameSetUp(false); }, 450) }, [device,isLoaded])

    useEffect(()=>{ 
   let IsCorrect=true, newScore=0
   
   LeftBase64?.map((obj,inx)=>{
    if(isEmpty(obj)){ IsCorrect = false; }
    else{
    if(!isEqual(inx,obj?.order)) { IsCorrect = false; } 
    if( isEqual(inx,obj?.order)) { newScore+=1 }  
    }
    })
    setScore(newScore)
    if(IsCorrect){ GameFinsh(true); setLoaded(false)}

   
     },[ActionCount]) 
   
   
   
   function clearStyle(icon){
     if(isEmpty(icon)) return
     icon.remove()
     setDragObj([])
     }
   
   const gameSetUp = (isContentChanged=false) => {
    if(isLoaded  && isContentChanged!==true) return;
    else setLoaded(true)
    setScore(0)
    setRightBase64(new Array(rows*cols).fill(null))
    setLeftBase64(new Array(rows*cols).fill(null))

    element("gamePanel_"+fieldID).style.opacity=0.02
     let tempWidth = LeftRef.current.clientWidth,
       tempHeight = LeftRef.current.clientHeight,
       tempSize = tempHeight;
     if (tempHeight > tempWidth) tempSize = tempWidth;
     setBoxSize([tempSize, tempSize]);
     setTimeout(() => {
      //  element("gamePanel_" + fieldID).style.width = "fit-content";
    
   element("gamePanel_" + fieldID).style.height = "fit-content";
   
     }, 1000);
     loadBase64(tempSize, isContentChanged);
   };
    
   
   async function loadBase64(localSize, isContentChanged=false) {
      if(isLoaded && isContentChanged!==true) return
      else setLoaded(true)
     let DELTA_WIDTH = localSize / cols,
       DELTA_HEIGHT = localSize / rows;
     setIconSize([DELTA_WIDTH, DELTA_HEIGHT]);
     let url = await getImageBase64(mainPageContent?.image_src, localSize, localSize),
       temp = await getMatrixClip(url, rows, cols, DELTA_WIDTH, DELTA_HEIGHT);
     let newArr = []
     temp?.map((obj, inx) => {
       let newObj = {
         base64: obj,
         order: inx
       };
       newArr.push(newObj);
     });
   
     setRightBase64(mixArray(newArr))
     setLeftBase64(new Array(rows*cols).fill(null))
   
     element("gamePanel_"+fieldID).style.opacity=1
   
     setLoadContent(false);
   }
    
   
   const onDown = function(event, inx,side) {
   
   var icon=element(inx+"_dragImg_"+side+"_"+fieldID)
   if(isEmpty(icon)) return

   
   let singleObj=(side==="Right")? RightBase64[inx] : LeftBase64[inx]
   setDragObj([ singleObj, inx+"_dragImg_"+side+"_"+fieldID])
   
     let shiftX = event.clientX - icon?.getBoundingClientRect().left;
     let shiftY = event.clientY - icon?.getBoundingClientRect().top;
   
     icon.style.position = 'absolute';
     icon.style.zIndex = 10;
      document.body.append(icon);
   
     moveAt( event.pageX, event.pageY);
   
   
     function moveAt(pageX, pageY) {
       icon.style.left = pageX - shiftX + 'px';
       icon.style.top = pageY - shiftY + 'px';
   
     }
   
    
     function onMouseMove(movEvent) {
       moveAt(movEvent.pageX, movEvent.pageY);
     }
     function onTouchMove(movEvent) {
       moveAt(movEvent.changedTouches[0].pageX, movEvent.changedTouches[0].pageY);
     }
     
   
     document.addEventListener('mousemove', onMouseMove);
     document.addEventListener('touchmove', onTouchMove);
   
   
     icon.onmouseup = function(movEvent) {
       document.removeEventListener('mousemove', onMouseMove);
       clearStyle(icon)
       icon.hidden = true;
       let elemBelow = document.elementFromPoint(movEvent.clientX, movEvent.clientY);
       icon.hidden = false;
       dropCard(elemBelow)
     };
   
     icon.ontouchend = function(movEvent) {
       document.removeEventListener('touchmove', onTouchMove);
       clearStyle(icon)
       icon.hidden = true;
       let elemBelow = document.elementFromPoint( movEvent.changedTouches[0].clientX, movEvent.changedTouches[0].clientY );
       icon.hidden = false;
       dropCard(elemBelow)
     };
   
   
     function dropCard(elemBelow){
       if ((!elemBelow)|| isEmpty(elemBelow?.id)) return;
       
       let targetElm=(elemBelow?.id).split("_")

       if(targetElm?.length===1) return
       let NewSide=targetElm[2], NewInx=parseInt(targetElm[0]), NewElmDIVID=targetElm[3]

       if( isEmpty(NewSide) || isEmpty(NewInx) || isEmpty(NewElmDIVID) || (NewElmDIVID)!==fieldID    ) return
    
     
    
   
       let localLeft=LeftBase64, localRight=RightBase64
    
       if(NewSide===side){
   if(NewSide==="Left"){
     if(isNotEmpty(localLeft[NewInx])) return
     setActionCount(o => o+1)
     localLeft[NewInx]=localLeft[inx]
     localLeft[inx]=null
   }
   if(NewSide==="Right"){
     if(isNotEmpty(localRight[NewInx])) return
     setActionCount(o => o+1)
     localRight[NewInx]=localRight[inx]
     localRight[inx]=null
   }
       }
       else{
   
       if(NewSide==="Left") {
       if(isNotEmpty(localLeft[NewInx])) return
       setActionCount(o => o+1)
        localLeft[NewInx]=localRight[inx]
        localRight[inx]=null
       }
   
       if(NewSide==="Right") {
         if(isNotEmpty(localRight[NewInx])) return
         setActionCount(o => o+1)
        localRight[NewInx]=localLeft[inx]
        localLeft[inx]=null
       }}
       
       setLeftBase64(localLeft)
       setRightBase64(localRight)
       
     }
     
   
   
   };
   
   const ondragstart = function() {
     return false;
   };





    return (
        <div draggable={false} className={`flex  ${device==="mobile" ? "flex-col" : "flex-row"}    h-[98vh] items-center relative w-full m-auto noselect`}   id={"gamePanel_"+fieldID}  style={{touchAction: "none"}} 
         >
      
      <div draggable={false}  className={`${device==="mobile"? "w-[100%]" : "w-[50%]" } ${device==="mobile" ? "p-2" : "p-3"}  h-full flex ${device==="mobile"? "justify-center" : "justify-end"} items-center max-w-[100%] overflow-hidden noselect`}> 
          <div  draggable={false} className={`w-full h-full bg-white rounded-md ${device==="mobile" ? "p-1" : "p-2"}  noselect`} style={{width:boxSize[0]+"px", height:boxSize[1]+"px"}} ref={LeftRef}>
          <div draggable={false} id={"DNDLeft"+fieldID} className="grid grid-cols-4  h-full noselect"   style={{  gridTemplateColumns: `repeat(` + cols + `,minmax(0,1fr)`,    gridTemplateRows: `repeat(` + rows + `,minmax(0,1fr)`,  }}  >
          {
            LeftBase64?.map((obj,inx)=>{
              let id=inx+"_dragImg_Left_"+fieldID, par_id=inx+"_dragPar_Left_"+fieldID
      
            return <div  key={inx+(dragObj[1] || 0)} id={par_id}   className={` flex justify-center items-center border-1 rounded-sm shadow-sm border-gray-200 noselect `}
           >
            {
             (isNotEmpty(obj))    &&   (dragObj?.length>0 ? dragObj[1]!==id : true) &&
              <img onDragStart={ondragstart} onPointerDown ={(e)=>onDown(e,inx,"Left")}  id={id}  src={obj?.base64} alt="Puzzle"  className=" w-full h-full shadow-md rounded-md  cursor-pointer noselect " draggable={false} style={{maxWidth:iconSize[0]+"px", maxHeight:iconSize[1]+"px"}}
             />
            }
            </div>
            })
          }
      
        </div>
        </div>
      </div>
       <div  draggable={false} className={`${device==="mobile" ? "w-[100%]" : "w-[50%]"}   ${device==="mobile" ? "p-2" : "p-3"}  h-full flex ${device==="mobile" ? "justify-center" : "justify-start"}  items-center noselect`}> 
          <div draggable={false} className={`w-full h-full bg-white rounded-md  ${device==="mobile" ? "p-1" : "p-2"}   noselect`} style={{width:boxSize[0]+"px", height:boxSize[1]+"px"}}>
          <div  draggable={false} id={"DNDRight_"+fieldID}  className={`grid grid-cols-4 ${device==="mobile" ? "gap-1" : "gap-2"}  h-full noselect`}     style={{  gridTemplateColumns: `repeat(` + cols + `,minmax(0,1fr)`,    gridTemplateRows: `repeat(` + rows + `,minmax(0,1fr)`,  }} >
            {
              RightBase64?.map((obj,inx)=>{
      let id=inx+"_dragImg_Right_"+fieldID, par_id=inx+"_dragPar_Right_"+fieldID
      
              return <div key={inx+(dragObj[1] || 0)} id={par_id} className=" flex justify-center items-center noselect   "   draggable={false} 
              >
              {
                (isNotEmpty(obj))  &&  (dragObj?.length>0 ? dragObj[1]!==id : true) &&
                <img onDragStart={ondragstart} onPointerDown ={(e)=>onDown(e,inx,"Right")}   id={id}  src={obj?.base64} alt="Puzzle"  className="  w-full h-full shadow-md rounded-md cursor-pointer noselect " draggable={false} style={{maxWidth:iconSize[0]+"px", maxHeight:iconSize[1]+"px"}}
                />
                
            }</div>
            })
            }
          </div>
          </div>
       </div>
       </div>
        );
};

