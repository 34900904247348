import React from 'react'
import InputWithLabel from '../../../input/inputWithLabel'
import { useDispatch, useSelector } from 'react-redux'
import { Actions } from '../../../../store'


const inputFiled = [
    {
       name: "First Name",
       disable: false 
    }, 
    {
        name: "Email",
        disable: true
    }, 
    {
        name: "Last Name",
        disable: false 
    }
]



export const EditDetails = () => {

    const { userDetail } = useSelector(state => ({userDetail:state?.userDetail}))

    const Dispatch = useDispatch()
  return (
    <div className=' w-full h-full flex flex-col flex-wrap  space-y-4 p-4'>
            <InputWithLabel 
            name='Email'
            id='Email'
            label='Email'
            disabledinput={true}
            textvalue={userDetail?.email}
            className='bg-gray-100'

            /> 
            <InputWithLabel
            name='First Name'
            id='First Name'
            label='First Name'
            onchange={(e)=>Dispatch(Actions.setuserDelailsUpDate({type:"first_name",value:e.target.value}))}
            textvalue={userDetail?.first_name }

            /> 
            <InputWithLabel 
            name='Last Name'
            id='Last Name'
            label='Last Name'
            onchange={(e)=>Dispatch(Actions.setuserDelailsUpDate({type:"last_name",value:e.target.value}))}
            textvalue={userDetail?.last_name}
            /> 

    </div>
  )
}
