import React, { useState } from "react";
import DefaultDropMenu from "../../input/droplist";
import PrimaryButton from "../../Button1/primarybutton";
import { Imageinput } from "../../input/imageinput";
import { ColorPixerInput } from "../../input/colorpixerInput";
import { AllFount } from "../../../content/allFount";
import FontInput from "../../input/fontInput";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../store";
import { MediaPopup } from "../../popup/Mediapopup";


export const EditorSetiting = ({

    ContentEditor = null

}) => {

    const { campaignSettings } = useSelector(state => ({ campaignSettings: state?.campaignSettings }))

    const Dispatch = useDispatch();

    const { fontSize, fontFamily, spaceBetweenTheBlock, backgroundType, image, color, letsStartImg, timesupImg, readyImg, PlayImg } = campaignSettings

    const rightarow = ">";

    const fontSizeList = ['small', 'medium', 'large'];

    const spaceBetween = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ];

    const ActiveFontSize = fontSizeList?.findIndex(list => list == fontSize);

    const ActiveFontFamily = AllFount?.findIndex(list => list == fontFamily);

    const [ShowMedia, setShowMedia] = useState(false);

    const [FieldType, setFielsType] = useState('');

    const SettingsCC = (type, value) => {

        Dispatch(Actions.campaignSettingsCC({ type, value }))

    }

    const triggerMediaFile = ( type, fieldtype ) =>{

        setShowMedia(true);

        setFielsType(fieldtype)

    }

    return (

        <div className="w-full h-full ">

            {ShowMedia && <MediaPopup cancel={setShowMedia} fieldType={FieldType} filedIdstate={true} fieldId={'ShowMediaFieldId'} OnSelect={(val, type) => SettingsCC(type, val)} />}

            <section className="w-full space-y-4 h-ful" >

                <div className="flex  justify-between" >

                    <lalel className="flex my-auto" > Default Message/Content </lalel>

                    <span className=" py-[4px] px-[12px] border-1 rounded-lg cursor-pointer" onClick={() => ContentEditor(true)} >

                        {rightarow}

                    </span>

                </div>


                <DefaultDropMenu mainclass="flex justify-between" labelTextWeight="my-auto"  label="Space Between the blocks" list={spaceBetween} active={spaceBetweenTheBlock ?? 0} SelectIndex={true} onchange={(ind) => SettingsCC("spaceBetweenTheBlock", ind )} />
                
                <DefaultDropMenu label="Font Size" mainclass="flex justify-between" list={fontSizeList} active={ActiveFontSize} SelectIndex={true} onchange={(ind) => SettingsCC("fontSize", fontSizeList[ind])} />

                <FontInput label="Font Family" mainclass="flex justify-between" list={AllFount} activeVal={fontFamily} active={ActiveFontFamily} onchange={(val, type) => SettingsCC(type, val)} fieldType="fontFamily" />

                <div className=" w-full space-y-4">

                    <label className="capitalize" >Parent Background Type</label>

                    <div className="flex w-full 0">

                        <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={(backgroundType !== 'image') ? 'text-gray-500' : 'text-primary'} border={(backgroundType !== 'image') ? 'border-2 border-gray-500 border-r-0' : 'border-2 border-primary '} height="h-14" text={'Image'} rounded={'rounded-none'} OnClick={() => SettingsCC('backgroundType', 'image')} />

                        <PrimaryButton buttontype="secondarybutton" width="w-full" textsize={'text-base'} ring='focus:outline-none' textcolor={(backgroundType == 'image') ? 'text-gray-500' : 'text-primary'} border={(backgroundType == 'image') ? 'border-2 border-gray-500 border-l-0' : 'border-2 border-primary '} height="h-14" text={'Color'} rounded='rounded-none' OnClick={() => SettingsCC('backgroundType', 'color')} />

                    </div>

                </div>

                {
                    (backgroundType == 'image') ?

                        <Imageinput src={image} deleteSrc={() => SettingsCC('image', '')} chanaeAction={() => triggerMediaFile('image','image')} label="Background Image" /> :

                        <ColorPixerInput label={"Background color"} fieldType="color" colorHex={color ?? "#7ED321"} labelTextsize={'text-sm my-auto'} Onchange={(val, type, id) => SettingsCC(type, val)} />
                }

                <div className="space-y-4">

                <label className="capitalize" >Game Banner Images</label>

                <Imageinput showDel={false} src={letsStartImg ?? "/asset/lets-start.png"}  chanaeAction={() => triggerMediaFile('image','letsStartImg')}  label="Let's Start Banner" /> 

                <Imageinput showDel={false} src={readyImg ?? "/asset/ready.png"}  chanaeAction={() => triggerMediaFile('image','readyImg')}  label="Ready Banner Image" /> 

                <Imageinput showDel={false} src={PlayImg ?? '/asset/play.png'}  chanaeAction={() => triggerMediaFile('image','PlayImg')}  label="Play Banner Image" /> 

                <Imageinput showDel={false} src={timesupImg ?? "/asset/times-up.png"}  chanaeAction={() => triggerMediaFile('image','timesupImg')}  label="TimesUp Banner Image" /> 

                </div>

            </section>

        </div>

    )

}