export const LastModified = (TimeStamp) => {

    const Convertdate = new Date(TimeStamp);

    const Mon = Convertdate.getMonth();

    const day = Convertdate.getDate();

    const year = Convertdate.getFullYear();

    return `Created at ${(day < 10)? `0${day}`:day} ${(Mon+1 < 10) ? `0${Mon+1}`:Mon+1} ${year}`;
    
}

export const setDateTime = () => {

    const Convertdate = new Date();

    const Mon = Convertdate.getMonth();

    const day = Convertdate.getDate();

    const year = Convertdate.getFullYear();

    const Hr = Convertdate.getHours();

    const Min = Convertdate.getMinutes();


    return ` - ${(day < 10)? `0${day}`:day} - ${(Mon+1 < 10) ? `0${Mon+1}`:Mon+1} - ${year} / ${(Hr < 10)? `0${Hr}`:Hr} : ${(Min < 10)? `0${Min}`:Min}`;
    
}

export const DefaultDateTime = (TimeStamp) => {

    const Convertdate = new Date(TimeStamp);

    const Mon = Convertdate.getMonth();

    const day = Convertdate.getDate();

    const year = Convertdate.getFullYear();

    const Hr = Convertdate.getHours();

    const Min = Convertdate.getMinutes();


    return `  ${(day < 10)? `0${day}`:day} / ${(Mon+1 < 10) ? `0${Mon+1}`:Mon+1} / ${year} `;
    
}