import React, { useEffect, useState } from "react";
import PrimaryButton from "../Button1/primarybutton";
import { useDispatch, useSelector } from "react-redux";


export const AnswerPopup = ({ questionObj, forwardAction= null, scoreVal=0, AnswerPayload ={  } , totalQuestion=5 }) => {

    const { campaignSettings } = useSelector( state => ({ campaignSettings: state.campaignSettings }) );

    const { nextQuestionButtonContent, scoreContent, correctAnswer } = campaignSettings?.defaultContent;

    const { question, quistion, image, answer, answerDescription } = questionObj;

    const [ activeAnimation, setactiveAnimation ] =  useState( false );

    const [ isthisCorrectanswer, setisthisCorrectanswer ] =  useState( false );

    const [ right, setright ] =  useState( 'right-[100%] bg-[#0000000]' );

    useEffect( () => {

        setisthisCorrectanswer( String(AnswerPayload[question]).toLowerCase() == String( answer ).toLowerCase() )

        setTimeout( () => {setright('right-[0%] bg-[#00000040]')} ,500 )

        setTimeout( () => setactiveAnimation(true) ,1000 )

    },[] )

    const nextQuestion = () => {

        setright('right-[-100%] bg-[#00000000]')

        setTimeout( () => forwardAction()  ,500 )

    }

    const test = true

    return (
        <section className={`w-full transform transition-all duration-500 h-full flex absolute top-0  bottom-0 z-10 p-10 py-5 ${ right }`} >

            <div className="w-4/5 flex flex-col m-auto max-w-[640px] p-5 rounded-xl bg-white space-y-4" >

                <div className={`border-1 flex justify-between ${ isthisCorrectanswer ? " bg-[#05c27020] border-[#05c270]" : " border-red-500 bg-red-50 " }  py-2 px-4 rounded-lg`} >
                    <div>{ AnswerPayload[question] }</div>
                    <span>{scoreContent??"score"}: <span>{scoreVal}</span> / {totalQuestion}</span>
                </div>


                <div className="border-1 flex rounded-lg h-[240px] w- overflow-hidden object-cover relative" >

                    <img src={(image !== "") ? image : "/asset/images/emptyimage.png"} alt="QABanner image" title="" className="object-cover w-fit h-full flex m-auto" />

                    <div className={`absolute  py-0 transform transition-all duration-900 ${ activeAnimation ? "py-2 h-[130px] bg-[#00000080]" : " h-[0px] bg-[#000000] " } bottom-0 w-full  bg-[#00000090] overflow-hidden px-4 text-white border-t-1 rounded-t-lg`} >
                        <p className="font-medium" >{correctAnswer ?? "Correct Answer is"} : <span>{answer}</span></p>
                        <p className="text-13px" >{answerDescription}</p>
                    </div>
                </div>
                <p className="font-medium" >{ question ?? quistion }</p>

                {/* <div>

                    <p className="border-1 py-2 px-4 rounded-lg bg-[#2563EB20]" >Correct Answer is : <span>{answer}</span></p>

                    <p>{question}</p>

                </div> */}

                <PrimaryButton text={ nextQuestionButtonContent ?? 'Next Question' } OnClick={nextQuestion} />

            </div>

        </section>
    )

}